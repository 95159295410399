import { DoNotAssociateThirdPartyIdProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Typography';
import { Toggle } from '../../Toggle';
import { useTheme } from '@morf/theming';

const DoNotAssociateThirdPartyId: FC<DoNotAssociateThirdPartyIdProps> = ({
  isReadOnly,
  doNotAssociateThirdPartyId,
  onDoNotAssociateThirdPartyIdChange,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='do-not-associate-third-party-id'
      direction='row'
      height='auto'
    >
      <Flexbox
        alignItems='flex-start'
        direction='column'
        justifyContent='flex-start'
        gap={0}
      >
        <Text tag='p2'>Do not associate with profile</Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Select what should happen.
        </Text>
      </Flexbox>

      <Toggle
        key='do-not-associate-third-party-id'
        defaultOption={doNotAssociateThirdPartyId ? 'on' : 'off'}
        onChange={(value) => onDoNotAssociateThirdPartyIdChange(value === 'on')}
        options={['off', 'on']}
        readOnly={isReadOnly}
      />
    </Flexbox>
  );
};

export const MemoizedDoNotAssociateThirdPartyId = memo(
  DoNotAssociateThirdPartyId
);
