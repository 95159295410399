import { FC } from 'react';
import { ButtonProps } from './types';
import { StyledButton } from './Button.css';

export const Button: FC<ButtonProps> = ({
  children,
  borderRadius = 0.5,
  variant = 'secondary',
  size = 'base',
  shape = 'rectangular',
  type = 'button',
  name,
  width,
  height,
  isDisabled,
  isActive,
  onClick,
  onPointerDown,
  ...props
}) => {
  return (
    <StyledButton
      borderRadius={borderRadius}
      variant={variant}
      name={name}
      data-testid={name}
      type={type}
      size={size}
      shape={shape}
      width={width}
      height={height}
      isActive={isActive}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={onClick}
      onPointerDown={onPointerDown}
      {...props}
    >
      {children}
    </StyledButton>
  );
};
