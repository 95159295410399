import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { memo } from 'react';

const RequiredIndicator = () => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='required-indicator'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      backgroundColor={theme.colors.support.red.lightest}
      borderRadius={0.25}
      p={0.25}
      gap={0.25}
    >
      <Icon
        name='alert'
        size={1}
        strokeWidth={1.75}
        stroke={theme.colors.ui.dark}
      />
      <Text tag='p3'>Required</Text>
    </Flexbox>
  );
};

export const MemoizedRequiredIndicator = memo(RequiredIndicator);
