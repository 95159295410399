export const Google = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.55173 24.8193L16.5197 11.1782C17.5319 11.7741 22.6356 14.5986 23.4599 15.1357L15.4919 28.7777C14.6204 29.9288 7.44773 26.5664 8.55173 24.8185V24.8193Z'
        fill='#FBBC04'
      />
      <path
        d='M31.445 24.8189L23.477 11.1786C22.363 9.32485 19.9639 8.65119 17.9934 9.74609C16.0229 10.841 15.4229 13.1996 16.5368 15.1362L24.5048 28.7781C25.6188 30.6311 28.0179 31.3047 29.9884 30.2098C31.8728 29.1149 32.5589 26.6727 31.445 24.8206V24.8189Z'
        fill='#4285F4'
      />
      <path
        d='M11.9997 30.7371C14.2087 30.7371 15.9994 28.9908 15.9994 26.8367C15.9994 24.6826 14.2087 22.9363 11.9997 22.9363C9.79074 22.9363 8 24.6826 8 26.8367C8 28.9908 9.79074 30.7371 11.9997 30.7371Z'
        fill='#34A853'
      />
    </svg>
  );
};
