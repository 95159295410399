import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { useRouter } from 'next/router';

export const NotFound: FC = () => {
  const theme = useTheme();
  const router = useRouter();

  const handleRetryClick = () => {
    router.push('/');
  };

  return (
    <Flexbox
      data-testid='not-found'
      direction='column'
      justifyContent='center'
      alignItems='center'
      p={2}
    >
      <Icon name='warning' size={4} stroke={theme.colors.text.muted} />
      <Text tag='h1'>Oops! We couldn't find that page.</Text>
      <Text tag='p1'>
        The page you are looking for may not exist or is temporarily
        unavailable.
      </Text>
      <Container pt={2} height='auto' width='auto'>
        <Button onClick={handleRetryClick} variant='primary' size='base'>
          <Text tag='h3' color='inherit'>
            Go Home
          </Text>
        </Button>
      </Container>
    </Flexbox>
  );
};
