import { AddEdgeButton } from './AddEdgeButton';
import { AddEdgeProps } from './types';
import { EdgeLabelRenderer, BaseEdge, getSmoothStepPath } from 'reactflow';
import { EdgeType } from '../types';
import { FC, memo } from 'react';
import { getLayoutedElements } from '../../getLayoutedElements';
import { newNodeData } from './AddEdgeModal/types';
import { nodesWithMultipleEdges } from '../../nodesWithMultipleEdges';
import { useWorkflow } from '../../../../../apps/admin/components/context/workflow/useWorkflow';
import { v4 as uuidv4 } from 'uuid';

const AddEdge: FC<AddEdgeProps> = ({
  id,
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}) => {
  const {
    nodes,
    edges,
    setEdges,
    setNodes,
    setSelectedNode,
    destinationActions,
    fetchActions,
  } = useWorkflow();

  const branchNodes = nodesWithMultipleEdges(nodes, edges);
  const isBranchEdge = branchNodes.includes(source);

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 1,
    borderRadius: 25,
  });

  const handleAddEdgeButtonClick = (
    newNodeData: newNodeData,
    aboveBranchButton: boolean
  ) => {
    const newNodeId = uuidv4();

    const newNode = {
      id: newNodeId,
      type: newNodeData.type,
      data: {
        value: {
          name: newNodeData.name,
          integrationIconName: newNodeData.integrationIconName,
          configuration: newNodeData.configuration,
          isRequired: true,
          ...data?.value,
        },
      },
      position: { x: 0, y: 0 },
      selectable: true,
    };

    setSelectedNode(newNode);

    const sourceToNewNodeEdge = {
      id: `${source}-${newNodeId}`,
      source,
      target: newNodeId,
      type: EdgeType.AddEdge,
    };

    const newNodeToTargetEdges = aboveBranchButton
      ? edges
          .filter((edge) => edge.source === source)
          .map((edge) => ({
            id: `${newNodeId}-${edge.target}`,
            source: newNodeId,
            target: edge.target,
            type: EdgeType.AddEdge,
          }))
      : [
          {
            id: `${newNodeId}-${target}`,
            source: newNodeId,
            target,
            type: EdgeType.AddEdge,
          },
        ];

    const remainingEdges = aboveBranchButton
      ? edges.filter((edge) => edge.source !== source)
      : edges.filter((edge) => edge.id !== id);

    const updatedNodes = [...nodes, newNode];
    const updatedEdges = newNodeData.isBranchNode
      ? [...edges, sourceToNewNodeEdge]
      : [...remainingEdges, sourceToNewNodeEdge, ...newNodeToTargetEdges];

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        {isBranchEdge && (
          <AddEdgeButton
            labelX={sourceX}
            labelY={sourceY + 15}
            destinationActions={destinationActions}
            fetchActions={fetchActions}
            name='add-above-branch-node'
            onClick={(newNodeData) =>
              handleAddEdgeButtonClick(newNodeData, true)
            }
          />
        )}

        <AddEdgeButton
          labelX={isBranchEdge ? targetX : (sourceX + targetX) / 2}
          labelY={isBranchEdge ? targetY - 30 : (sourceY + targetY) / 2}
          destinationActions={destinationActions}
          fetchActions={fetchActions}
          name='add-node'
          onClick={(newNodeData) =>
            handleAddEdgeButtonClick(newNodeData, false)
          }
        />
      </EdgeLabelRenderer>
    </>
  );
};

export const MemoizedAddEdge = memo(AddEdge);
