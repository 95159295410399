import { styled } from '@morf/theming';
import { Container, ContainerProps } from '../../../Container';

export const StyledLabelContainer = styled(Container)<
  ContainerProps & { labelX: number; labelY: number }
>`
  position: absolute;
  width: auto;
  height: auto;
  background-color: ${(props) => props.theme.colors.ui.body};
  padding: 0.5rem;
  transform: translate(-50%, -50%)
    translate(${(props) => props.labelX}px, ${(props) => props.labelY}px);
`;
