export const sortKeys = (keyA: string, keyB: string): number => {
  const numA = Number(keyA);
  const numB = Number(keyB);

  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB;
  } else {
    return keyA.localeCompare(keyB);
  }
};
