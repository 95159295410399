import _ from 'lodash';
import { Dropdown } from '../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { OperatorProps } from './types';
import { Text } from '../../Typography';
import { operatorWidth } from '../constants';

const Operator: FC<OperatorProps> = ({
  operator,
  operatorOptions,
  onOperatorChange,
  readOnly,
}) => {
  return (
    <Flexbox
      data-testid='operator'
      justifyContent='center'
      alignItems='center'
      borderType='border'
      minWidth={operatorWidth}
      maxWidth={operatorWidth}
      width={operatorWidth}
    >
      {_.isEqual(operatorOptions.length, 1) ? (
        <Text tag='p2'>{operatorOptions[0].label}</Text>
      ) : (
        <Dropdown
          defaultOptions={[operator]}
          options={operatorOptions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            onOperatorChange && onOperatorChange(selectedOption);
          }}
          height='2.25rem'
          borderType='none'
          iconName='chevron'
          iconSize={1}
          readOnly={readOnly}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedOperator = memo(Operator);
