export const CodeBracket = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5'
    />
  );
};

export const CurlyBrackets = () => {
  return (
    <>
      <path d='M15.5 19.5H16.5C17.6046 19.5 18.5 18.6046 18.5 17.5V14.0704C18.5 13.4017 18.8342 12.7772 19.3906 12.4063L20 12' />
      <path d='M15.5 4.5H16.5C17.6046 4.5 18.5 5.39543 18.5 6.5V9.92963C18.5 10.5983 18.8342 11.2228 19.3906 11.5937L20 12' />
      <path d='M9.5 19.5H8.5C7.39543 19.5 6.5 18.6046 6.5 17.5V14.0704C6.5 13.4017 6.1658 12.7772 5.6094 12.4063L5 12' />
      <path d='M9.5 4.5H8.5C7.39543 4.5 6.5 5.39543 6.5 6.5V9.92963C6.5 10.5983 6.1658 11.2228 5.6094 11.5937L5 12' />
    </>
  );
};
