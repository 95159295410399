import { useTheme } from '@morf/theming';
import { Avatar } from '../Avatar';
import { CopyToClipboard } from '../CopyToClipboard';
import { FlexItem, Flexbox } from '../Flexbox';
import { Tooltip } from '../Tooltip';
import { Text } from '../Typography';
import { Icon } from '../Icon';
import { FC } from 'react';
import { LinkedAppProps } from './types';
import { SkeletonLoadable } from '../Skeleton';

export const LinkedApp: FC<LinkedAppProps> = ({
  name,
  imageUrl,
  id,
  link,
  isLoading,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      gap={1}
    >
      <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
        <Avatar size='sm' src={imageUrl} alt={name} />
      </SkeletonLoadable>

      <FlexItem flexGrow={1}>
        <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
          <Text tag='p2'>{name}</Text>
        </SkeletonLoadable>
      </FlexItem>

      <CopyToClipboard
        label='Copy ID to clipboard'
        text={id}
        tooltipDirection='left'
        tooltipWidth='7rem'
      />

      {link && (
        <Tooltip
          tooltipText='Visit linked account'
          tooltipDirection='left'
          tooltipWidth='7rem'
        >
          <a href={link} target='_blank'>
            <Icon
              name='link-out'
              stroke={theme.colors.main.primary.darker}
              size={1.25}
              cursor='pointer'
            />
          </a>
        </Tooltip>
      )}
    </Flexbox>
  );
};
