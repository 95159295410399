import moment from 'moment';

type getEnrichedDatesProps = {
  startDate: string;
  selectedDate: string;
  dates: string[];
  highlightedDates: string[];
};

export const getEnrichedDates = ({
  startDate,
  selectedDate,
  dates,
  highlightedDates,
}: getEnrichedDatesProps) => {
  return dates.map((date) => {
    return {
      date,
      isCurrentMonth: moment(startDate).month() == moment(date).month(),
      isAvailable: highlightedDates?.some((d) =>
        moment(d).isSame(moment(date))
      ),
      isSelectedDate: moment(date)
        .startOf('day')
        .isSame(moment(selectedDate).startOf('day')),
      isSelectedWeek: moment(date)
        .startOf('day')
        .isSame(moment(selectedDate).startOf('day'), 'week'),
    };
  });
};
