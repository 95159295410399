import { createCustomTimeUrlParam } from '../Filters/TimeFilter/TimeDropdown/CustomTimeRange/createCustomTimeUrlParam';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

export const createExecutionLink = (
  organizationId: string | undefined,
  workflowExecutionSummary: workflow_monitoring.v2.WorkflowExecutionSummary
): string | undefined => {
  if (!organizationId) return undefined;

  const executionTime = createCustomTimeUrlParam(
    workflowExecutionSummary.executionStartTime ||
      workflowExecutionSummary.triggeredAtTime
  );
  const executionURL = new URL(
    window.location.origin +
      `/organizations/${organizationId}/monitoring/workflows?workflowExecutionId=${workflowExecutionSummary.workflowExecutionId}`
  );
  executionURL.searchParams.set('time', executionTime);
  return executionURL.toString();
};
