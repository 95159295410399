import { Condition } from '../../../Conditional/Condition';
import { Container } from '../../../Container';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Operator } from '../../../Conditional/Operator';
import { ParameterVariant } from '../../../ParameterVariant';
import { ProfileLookupProps } from './types';
import { SelectValuePairCategory } from '../../../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/types';
import { SelectValuePairModal } from '../../../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal';
import { convertToKebabCase } from '../../../Helpers/convertToKebabCase';
import { convertToTitleCase } from '../../../Helpers/convertToTitleCase';
import { fieldWidth } from '../../../Conditional/constants';
import { google } from '@morf/proto/cel_values_ts_proto';
import { thirdparties } from '@morf/proto/thirdparties_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const ProfileLookup: FC<ProfileLookupProps> = ({
  eventPayloadFieldSchemas,
  index,
  isReadOnly,
  lookup,
  onLookupFieldChange,
  onLookupIndexChange,
}) => {
  const theme = useTheme();
  const [isEventPayloadField, setIsEventPayloadField] = useState(false);
  const [isIdentifierField, setIsIdentifierField] = useState(false);

  const condition = index === 0 ? 'if' : 'or';
  const conditionAsOption = {
    label: condition,
    value: condition,
  };

  const selectedEventPayloadField =
    new workflow_parameters.v1.DestinationActionParameterSource({
      eventPayloadFieldLookup:
        lookup?.index?.id?.idFieldLookup ||
        lookup?.index?.email?.uniqueIndexFieldLookup ||
        lookup?.index?.phone?.uniqueIndexFieldLookup,
    });

  const celExpressionStr =
    selectedEventPayloadField.eventPayloadFieldLookup?.celExpressionStr;

  const handleEventPayloadFieldOpen = () => {
    if (!isReadOnly) {
      setIsEventPayloadField(true);
    }
  };

  const handleEventPayloadFieldSave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    const updatedSource = updatedSources[0];
    const celExpressionStr =
      updatedSource.eventPayloadFieldLookup?.celExpressionStr;
    celExpressionStr && onLookupFieldChange(celExpressionStr, index);
  };

  const handleEventPayloadFieldClose = () => setIsEventPayloadField(false);

  const operatorAsOption = {
    label: 'matches with',
    value: 'matches_with',
  };

  let selectedIdentifierField = null;
  let selectedIdentifierName;
  let selectedIdentifierIntegrationIconName;

  if (lookup?.index?.id && lookup?.index?.id?.thirdPartyIdType) {
    selectedIdentifierField =
      new workflow_parameters.v1.DestinationActionParameterSource({
        profileIdentifier: lookup?.index?.id?.thirdPartyIdType,
      });

    selectedIdentifierName = `${convertToTitleCase(
      thirdparties.v1.ThirdPartyIdType[
        selectedIdentifierField.profileIdentifier
      ]
    )} ID`;

    selectedIdentifierIntegrationIconName = `${convertToKebabCase(
      thirdparties.v1.ThirdPartyIdType[
        selectedIdentifierField.profileIdentifier
      ]
    )}`;
  } else if (lookup?.index?.email) {
    selectedIdentifierField =
      new workflow_parameters.v1.DestinationActionParameterSource({
        constantValue: new google.api.expr.v1alpha1.Value({
          stringValue: 'email_address',
        }),
      });
    selectedIdentifierName = selectedIdentifierField.constantValue?.stringValue;
  } else if (lookup?.index?.phone) {
    selectedIdentifierField =
      new workflow_parameters.v1.DestinationActionParameterSource({
        constantValue: new google.api.expr.v1alpha1.Value({
          stringValue: 'phone_number',
        }),
      });
    selectedIdentifierName = selectedIdentifierField.constantValue?.stringValue;
  }

  const handleIdentifierFieldOpen = () => {
    if (!isReadOnly) {
      setIsIdentifierField(true);
    }
  };

  const handleIdentifierFieldSave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    const updatedSource = updatedSources[0];
    const identifier =
      updatedSource.constantValue?.stringValue ||
      updatedSource.profileIdentifier?.toString();
    identifier && onLookupIndexChange(identifier, index);
  };

  const handleIdentifierFieldClose = () => setIsIdentifierField(false);

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      borderType='border'
      data-testid='conditional'
      borderColor={theme.colors.ui.divider}
      backgroundColor={theme.colors.ui.card}
      borderRadius={0.5}
      height='2.25rem'
      gap={0}
      {...(!isReadOnly && { maxWidth: 'calc(100% - 2.75rem)' })}
    >
      <Condition
        condition={conditionAsOption}
        conditionOptions={[conditionAsOption]}
        readOnly={isReadOnly}
      />
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
        maxWidth={fieldWidth}
        cursor={isReadOnly ? 'not-allowed' : 'pointer'}
        onClick={handleEventPayloadFieldOpen}
        p={0.25}
        gap={0.25}
      >
        <ParameterVariant
          id={`${index}`}
          isReadOnly={isReadOnly}
          label={celExpressionStr || 'required'}
          type={celExpressionStr ? 'payload' : 'required'}
          wordWrap='normal'
        />
        <Container height='auto' width='auto'>
          <Icon
            name='chevron-down'
            stroke={theme.colors.ui.dark}
            strokeWidth={1.75}
            size={1}
          />
        </Container>
      </Flexbox>
      <Operator
        operator={operatorAsOption}
        operatorOptions={[operatorAsOption]}
        readOnly={isReadOnly}
      />
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
        maxWidth={fieldWidth}
        cursor={isReadOnly ? 'not-allowed' : 'pointer'}
        onClick={handleIdentifierFieldOpen}
        p={0.25}
        gap={0.25}
      >
        <ParameterVariant
          id={`${index}`}
          integrationIconName={selectedIdentifierIntegrationIconName}
          isReadOnly={isReadOnly}
          label={selectedIdentifierName || 'required'}
          type={selectedIdentifierName ? 'identifier' : 'required'}
          wordWrap='normal'
        />

        <Container height='auto' width='auto'>
          <Icon
            name='chevron-down'
            stroke={theme.colors.ui.dark}
            strokeWidth={1.75}
            size={1}
          />
        </Container>
      </Flexbox>

      {isEventPayloadField && (
        <SelectValuePairModal
          eventPayloadFieldSchemas={eventPayloadFieldSchemas}
          onClick={handleEventPayloadFieldSave}
          onClose={handleEventPayloadFieldClose}
          onCreateCustomProperty={() => console.log('onCreateCustomProperty')}
          selectedSource={selectedEventPayloadField}
          visibleCategories={[SelectValuePairCategory.PayloadField]}
        />
      )}

      {isIdentifierField && (
        <SelectValuePairModal
          includeEmail
          includePhone
          onClick={handleIdentifierFieldSave}
          onClose={handleIdentifierFieldClose}
          onCreateCustomProperty={() => console.log('onCreateCustomProperty')}
          selectedSource={selectedIdentifierField}
          visibleCategories={[SelectValuePairCategory.Identifiers]}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedProfileLookup = memo(ProfileLookup);
