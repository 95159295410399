import { useTheme } from '@morf/theming';
import { Button } from '../../Button';
import { Flexbox } from '../../Flexbox';
import { ParameterVariant } from '../../ParameterVariant';
import { Text } from '../../Typography';
import { ViewPropertyMappingModalProps } from './types';

export const ViewPropertyMappingModal: React.FC<
  ViewPropertyMappingModalProps
> = ({ currentProperty, onEdit }) => {
  const theme = useTheme();
  return (
    <Flexbox
      direction='column'
      p={1.5}
      backgroundColor={theme.colors.ui.card}
      width='26.25rem'
      borderRadius={1}
    >
      <Flexbox direction='column' gap={0.25}>
        <Flexbox alignItems='center'>
          <Text tag='h4'>Mapped to:</Text>
          <Button variant='tertiary' onClick={onEdit}>
            <Text tag='h4' color='inherit'>
              Edit
            </Text>
          </Button>
        </Flexbox>
        <ParameterVariant
          id={currentProperty.id}
          type={currentProperty.type}
          label={currentProperty.label}
        />
      </Flexbox>
    </Flexbox>
  );
};
