import { fetchactions as freshdeskfetchactions } from '@morf/proto/fetchactions/freshdesk/v1_ts_proto';
import { fetchactions as healthiefetchactions } from '@morf/proto/fetchactions/healthie/v1_ts_proto';
import { fetchactions as hubspotfetchactions } from '@morf/proto/fetchactions/hubspot/v1_ts_proto';
import { fetchactions as intercomfetchactions } from '@morf/proto/fetchactions/intercom/v1_ts_proto';

import { createActionMap } from '../DestinationActionNode/constants';

export const allActions = {
  ...createActionMap(healthiefetchactions.healthie.v1),
  ...createActionMap(freshdeskfetchactions.freshdesk.v1),
  ...createActionMap(hubspotfetchactions.hubspot.v1),
  ...createActionMap(intercomfetchactions.intercom.v1),
};
