import { Dropdown } from '../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { ProfileLookupNoProfileFoundProps } from './types';
import { Text } from '../../Typography';
import { capitalizeFirstLetter } from '../../Helpers/capitalizeFirstLetter';
import { formatEnumToOptions } from '../../Helpers/formatEnumToOptions';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { RequiredIndicator } from '../../RequiredIndicator';

const ProfileLookupNoProfileFound: FC<ProfileLookupNoProfileFoundProps> = ({
  isReadOnly,
  noProfileFoundAction,
  onNoProfileFoundActionChange,
}) => {
  const theme = useTheme();

  const noProfileFoundActions = formatEnumToOptions(
    profiles.v1.NoProfileFoundAction
  ).map((action) => ({
    label: capitalizeFirstLetter(
      action.label.replace('No profile found action', '').trim()
    ),
    value: action.value,
  }));

  const selectedNoProfileFoundActionAsOption = noProfileFoundActions.filter(
    (action) => action.value === noProfileFoundAction?.toString()
  );

  return (
    <Flexbox
      data-testid='profile-lookup-no-profile-found'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
      pb={1.25}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='p2'>If no profile found</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select the workflow action.
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={selectedNoProfileFoundActionAsOption}
          options={noProfileFoundActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onNoProfileFoundActionChange(selectedOptionNumber);
          }}
          height='2.25rem'
          readOnly={isReadOnly}
          clearable
        />
      </Flexbox>
      {!noProfileFoundAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedProfileLookupNoProfileFound = memo(
  ProfileLookupNoProfileFound
);
