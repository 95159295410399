export const ActiveCampaign = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.6373 19.9437L12.0163 29.6534C11.3389 30.105 11 30.8387 11 31.5726V34L28.726 22.3711C29.5162 21.8066 30.0244 20.9032 30.0244 19.9437C30.0244 18.984 29.5728 18.0806 28.726 17.5161L11 6V8.25798C11 9.04848 11.3952 9.78219 12.0163 10.1774L26.6373 19.9437Z'
        fill='#004CFF'
      />
      <path
        d='M17.8308 20.7339C18.621 21.2418 19.6373 21.2418 20.4276 20.7339L21.6694 19.8871L12.4113 13.621C11.8468 13.2258 11 13.621 11 14.3547V16.2176L15.7984 19.4355L17.8308 20.7339Z'
        fill='#004CFF'
      />
    </svg>
  );
};
