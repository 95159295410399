import { styled } from '@morf/theming';
import ReactFlow from 'reactflow';
import {
  StyledCustomEdge,
  StyledCustomHandle,
} from './CustomEdge/CustomEdge.css';
import { StyledCustomNode } from './CustomNode/CustomNode.css';

export const StyledWorkflow = styled(ReactFlow)`
  background-color: ${(props) => props.theme.colors.ui.body};

  ${StyledCustomNode}
  ${StyledCustomHandle}
  ${StyledCustomEdge}

  .react-flow__attribution {
    display: none;
  }

  .react-flow__controls{
    border-radius: ${(props) => props.theme.borderRadius[0.5]};
  }
 
  }
`;
