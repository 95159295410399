import Cookies from 'js-cookie';
import { Container } from '@morf/ui/Container';
import { Divider } from '@morf/ui/Divider';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '@morf/ui/Flexbox';
import { Icon } from '@morf/ui/Icon';
import { NavigationItems } from './NavigationItems';
import { NavigationRailProps } from './types';
import { OrganizationMenu } from './OrganizationMenu';
import { STYTCH_SESSION_JWT } from '@morf/constants';
import { SpotlightSearchIcon } from './SpotlightSearchIcon';
import { Tooltip } from '@morf/ui/Tooltip';
import { UserMenu } from './UserMenu';
import { clearAllCookies } from './clearAllCookies';
import { clearLocalStorage } from './clearLocalStorage';
import { copyStytchSessionJWT as handleCopyStytchSessionJWT } from './copyStytchSessionJWT';
import { revokeStytchSession } from '../../../../lib/revokeStytchSession';
import { useClickOutside } from '@morf/ui/Hooks/useClickOutside';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';

const NavigationRail: FC<NavigationRailProps> = ({
  organizationId,
  organizationPermissions,
  setShowSpotlightSearch,
  userImage,
}) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const theme = useTheme();

  const organizationMenuRef = useRef<HTMLDivElement>(null);

  const [organizationMenu, setOrganizationMenu] = useState(false);

  useClickOutside(organizationMenuRef, () => {
    setOrganizationMenu(false);
  });

  const { mutateAsync: onRevokeStytchSession } = useMutation(
    async (session_jwt: string) => {
      return await revokeStytchSession({
        session_jwt,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        router.push('/login');
      },
    }
  );

  const handleToggleOrganizationMenu = () =>
    setOrganizationMenu(!organizationMenu);
  const handleCloseOrganizationMenu = () => setOrganizationMenu(false);

  const handleLogout = () => {
    const session_jwt = Cookies.get(STYTCH_SESSION_JWT);
    if (session_jwt) {
      onRevokeStytchSession(session_jwt);
      clearAllCookies();
      clearLocalStorage();
    }
  };

  const handleSelectOrganization = (organizationId: string) => {
    router.push({
      pathname: `/organizations/${organizationId}`,
    });
  };

  return (
    <Flexbox
      data-testid='navigation-rail'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      borderType='borderRight'
      width='auto'
      gap={1.5}
      px={0.75}
      py={1.5}
    >
      <Icon name='morf-logo' size={2.25} viewBox='0 0 170 162' />

      <Tooltip
        tooltipText='Manage or switch Organization'
        tooltipDirection='right'
        isActive={!organizationMenu}
      >
        <Container ref={organizationMenuRef} cursor='pointer'>
          <Icon
            name='menuWorkspace'
            stroke='none'
            viewBox='0 0 36 36'
            size={2.25}
            onClick={handleToggleOrganizationMenu}
          />
          {organizationMenu && (
            <OrganizationMenu
              onClose={handleCloseOrganizationMenu}
              onSelectOrganization={handleSelectOrganization}
              organizationId={organizationId}
              organizationPermissions={organizationPermissions}
            />
          )}
        </Container>
      </Tooltip>

      <Divider />

      <NavigationItems organizationId={organizationId} />
      <Flexbox justifyContent='flex-end' alignItems='center' width='auto'>
        <Flexbox
          direction='column'
          justifyContent='flex-end'
          alignItems='center'
        >
          <SpotlightSearchIcon
            setShowSpotlightSearch={setShowSpotlightSearch}
          />
          <UserMenu
            onLogout={handleLogout}
            onCopyStytchSessionJWT={handleCopyStytchSessionJWT}
            userImage={userImage}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedNavigationRail = memo(NavigationRail);
