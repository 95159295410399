import { FC } from 'react';
import { Badge } from '../../Badge';
import { ObjectStatus as ObjectStatusType, ObjectStatusProps } from './types';

export const getBadgeType = (status: ObjectStatusType) => {
  switch (status) {
    case 'New Mapping':
      return 'warning';
    case 'Active':
      return 'success';
    case 'Pending':
      return 'inactive';
    default:
      return 'inactive';
  }
};

export const ObjectStatus: FC<ObjectStatusProps> = ({ status }) => {
  const badgeType = getBadgeType(status);
  return <Badge label={status} type={badgeType} />;
};
