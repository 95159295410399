import { Container } from '../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { Text } from '../../../Typography';
import { WorkflowExecutionTooltipProps } from './types';
import { convertToKebabCase } from '../../../Helpers/convertToKebabCase';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';

const WorkflowExecutionTooltipName: FC<
  Pick<WorkflowExecutionTooltipProps, 'applicationType' | 'workflowName'>
> = ({ applicationType, workflowName }) => (
  <Flexbox
    data-testid='workflow-execution-name'
    direction='row'
    justifyContent='flex-start'
    alignItems='center'
    height='auto'
    gap={0.25}
  >
    <Container height='auto' width='auto'>
      <IntegrationIcon
        name={convertToKebabCase(
          source_application.v2.SourceApplication.Application[applicationType]
        )}
        size={1}
      />
    </Container>
    <Text tag='p3'>{workflowName}</Text>
  </Flexbox>
);

export const MemoizedWorkflowExecutionTooltipName = memo(
  WorkflowExecutionTooltipName
);
