export const Workspace = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z'
    />
  );
};

export const SolidWorkspace = () => {
  return (
    <>
      <path
        fillRule='evenodd'
        d='M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z'
        clipRule='evenodd'
      />
      <path d='M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z' />
    </>
  );
};

export const MenuWorkspace = () => {
  return (
    <>
      <svg
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='36' height='36' rx='18' fill='#7FA09C' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.996 22.5221C17.651 22.5221 17.371 22.2421 17.371 21.8971V19.783C17.371 19.438 17.651 19.158 17.996 19.158C18.341 19.158 18.621 19.438 18.621 19.783V21.8971C18.621 22.2421 18.341 22.5221 17.996 22.5221Z'
          fill='white'
        />
        <mask
          id='mask0_968_23291'
          maskUnits='userSpaceOnUse'
          x='9'
          y='11'
          width='18'
          height='10'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.66687 11.8167H26.325V20.4092H9.66687V11.8167Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_968_23291)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.9169 17.4942C12.8969 18.57 15.3935 19.1592 17.9919 19.1592C20.5952 19.1592 23.0944 18.57 25.0752 17.4942V14.9925C25.0752 13.93 24.216 13.0667 23.1585 13.0667H12.8419C11.7802 13.0667 10.9169 13.9267 10.9169 14.9842V17.4942ZM17.9919 20.4092C15.0377 20.4092 12.1902 19.6941 9.97604 18.3975C9.78437 18.2858 9.66687 18.0808 9.66687 17.8583V14.9842C9.66687 13.2375 11.091 11.8167 12.8419 11.8167H23.1585C24.9052 11.8167 26.3252 13.2408 26.3252 14.9925V17.8583C26.3252 18.0808 26.2069 18.2858 26.016 18.3975C23.8019 19.6941 20.9519 20.4092 17.9919 20.4092Z'
            fill='white'
          />
        </g>
        <mask
          id='mask1_968_23291'
          maskUnits='userSpaceOnUse'
          x='14'
          y='9'
          width='8'
          height='5'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.4543 9.66675H21.5377V13.0633H14.4543V9.66675Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_968_23291)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.9127 13.0634C20.5677 13.0634 20.2877 12.7834 20.2877 12.4384V12.1334C20.2877 11.4626 19.7418 10.9167 19.071 10.9167H16.921C16.2502 10.9167 15.7043 11.4626 15.7043 12.1334V12.4384C15.7043 12.7834 15.4243 13.0634 15.0793 13.0634C14.7343 13.0634 14.4543 12.7834 14.4543 12.4384V12.1334C14.4543 10.7734 15.561 9.66675 16.921 9.66675H19.071C20.431 9.66675 21.5377 10.7734 21.5377 12.1334V12.4384C21.5377 12.7834 21.2577 13.0634 20.9127 13.0634Z'
            fill='white'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.8291 26.1168H13.1625C11.4333 26.1168 9.97747 24.766 9.84664 23.041L9.6883 20.9502C9.66247 20.606 9.9208 20.3052 10.265 20.2793C10.6108 20.2668 10.9091 20.511 10.9358 20.856L11.0933 22.946C11.175 24.0227 12.0833 24.8668 13.1625 24.8668H22.8291C23.9083 24.8668 24.8175 24.0227 24.8983 22.946L25.0566 20.856C25.0833 20.511 25.3891 20.266 25.7275 20.2793C26.0716 20.3052 26.3291 20.606 26.3033 20.9502L26.145 23.041C26.0141 24.766 24.5583 26.1168 22.8291 26.1168Z'
          fill='white'
        />
      </svg>
    </>
  );
};
