import { BarChartProps } from './types';
import { CustomTick } from '../CustomTick';
import { memo } from 'react';
import { useTheme } from '@morf/theming';
import {
  Bar,
  BarChart as BarChartRechart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const Y_AXIS_OFFSET = 30;

const BarChart = <T,>({
  barSize,
  data,
  dataKey,
  xAxisDataKey,
  yAxisDataKey,
}: BarChartProps<T>) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChartRechart
        data={data}
        margin={{ top: 10, right: 0, left: Y_AXIS_OFFSET, bottom: 0 }}
      >
        <CartesianGrid
          stroke={theme.colors.ui.divider}
          strokeWidth={1.5}
          vertical={false}
        />
        <XAxis
          dataKey={xAxisDataKey}
          axisLine={false}
          stroke={theme.colors.text.muted}
          strokeWidth={1.5}
          tick={(props) => <CustomTick {...props} />}
          tickLine={{ stroke: theme.colors.ui.divider }}
        />
        <YAxis
          dataKey={yAxisDataKey}
          axisLine={false}
          dx={-Y_AXIS_OFFSET}
          mirror
          stroke={theme.colors.text.muted}
          strokeWidth={1.5}
          tick={(props) => <CustomTick {...props} />}
          tickLine={false}
        />
        <Bar
          barSize={barSize}
          dataKey={dataKey}
          fill={theme.colors.main.primary.darker}
        />
      </BarChartRechart>
    </ResponsiveContainer>
  );
};

export const MemoizedBarChart = memo(BarChart);
