import { CustomNodeLabelProps } from './types';
import { Flexbox } from '../../../Flexbox';
import { css, styled } from '@morf/theming';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

const Default = css(
  ({ theme }) => `
      background-color: ${theme.colors.ui.card};
      `
);

const Inactive = css(
  ({ theme }) => `
    background-color:  ${theme.colors.ui.card};
    opacity: 0.5;
    `
);

const Processing = css(
  ({ theme }) => `
    background-color:  ${theme.colors.ui.body};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.ui.dark};
    `
);

const Executed = css(
  ({ theme }) => `
    background-color:  ${theme.colors.support.green.lightest};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.support.green.darkest};
    `
);

const Error = css(
  ({ theme }) => `
    background-color: ${theme.colors.support.red.lightest};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.support.red.darkest};
    `
);

const Warning = css(
  ({ theme }) => `
    background-color:  ${theme.colors.support.yellow.lightest};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.support.yellow.darkest};
    `
);

const Waiting = css(
  ({ theme }) => `
    background-color: #F3EBFF;
    outline: ${theme.borderWidth['0.0625']} solid #9747FF;
    `
);

const Terminated = css(
  ({ theme }) => `
    background-color:  ${theme.colors.ui.body};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.ui.dark};
    `
);

const Retrying = css(
  ({ theme }) => `
    background-color:  ${theme.colors.ui.body};
    outline: ${theme.borderWidth['0.0625']} solid ${theme.colors.ui.dark};
    `
);

const handleStatus = (status: CustomNodeLabelProps['status']) => {
  switch (status) {
    case workflow_monitoring.v2.ActionExecutionAttempts.Status
      .UNSPECIFIED_STATUS:
      return Inactive;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.PROCESSING:
      return Processing;

    case 'progress':
      return Processing;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.EXECUTED:
      return Executed;

    case 'success':
      return Executed;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.ERROR:
      return Error;

    case 'error':
      return Error;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.WARNING:
      return Warning;

    case 'warning':
      return Warning;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.WAITING:
      return Waiting;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.TERMINATED:
      return Terminated;

    case 'filter':
      return Terminated;

    case workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING:
      return Retrying;

    case 'retry':
      return Retrying;

    case 'inactive':
      return Inactive;

    default:
      return Default;
  }
};

export const StyledCustomNodeLabel = styled(Flexbox)<{
  status: CustomNodeLabelProps['status'];
  isSelected: boolean;
}>`
  ${(props) => handleStatus(props.status)};
  ${(props) =>
    props.isSelected &&
    `
      outline: ${props.theme.borderWidth['0.0625']} solid
        ${props.theme.colors.main.primary.darker};
    `};

  &:hover {
    outline: ${(props) => props.theme.borderWidth['0.0625']} solid
      ${(props) => props.theme.colors.main.primary.darker};
  }
`;
