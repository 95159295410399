import { FC, memo } from 'react';
import { SidebarProps } from './types';
import { WrapperModal } from '../WrapperModal';

const Sidebar: FC<SidebarProps> = ({
  children,
  isOpen,
  onClose,
  width,
  ...props
}) => {
  const backdrop = {
    backgroundColor: 'transparent',
    left: 'auto',
    right: '0rem',
    width: width,
  };
  return (
    <WrapperModal
      isOpen={isOpen}
      width={width}
      borderRadius={0}
      backdrop={backdrop}
      onClose={onClose}
      height='100%'
      top='0'
      right='0'
      left='auto'
      bottom='auto'
      {...props}
    >
      {children}
    </WrapperModal>
  );
};

export const MemoizedSidebar = memo(Sidebar);
