import { useTheme } from '@morf/theming';
import { Flexbox } from '../../Flexbox';
import { Text, TooltipText } from '../../Typography';
import React, { memo, useEffect, useRef, useState } from 'react';
import { DropdownInputIcon } from './DropdownInputIcon';
import { DropdownInputProps } from './types';
import { DropdownInputMultiSelect } from './DropdownInputMultiSelect';

const DropdownInput: React.FC<DropdownInputProps> = ({
  placeholder = 'Select option',
  selectedOptions,
  showOptions,
  setShowOptions,
  multiSelect,
  onChange,
  iconName,
  iconSize,
  readOnly,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const handleInputClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current]);

  return (
    <Flexbox
      data-testid='dropdown-input'
      alignItems='center'
      onClick={handleInputClick}
      containerRef={containerRef}
      cursor='pointer'
      gap={0.5}
      p={0.5}
    >
      {!selectedOptions.length ? (
        <Text tag='p2' color={theme.colors.text.muted}>
          {placeholder}
        </Text>
      ) : (
        <Flexbox justifyContent='center' alignItems='center'>
          {multiSelect ? (
            <DropdownInputMultiSelect
              selectedOptions={selectedOptions}
              containerWidth={containerWidth}
              onChange={onChange}
              readOnly={readOnly}
            />
          ) : (
            <TooltipText tag='p2' whiteSpace='nowrap' tooltipDirection='bottom'>
              {selectedOptions[0].label}
            </TooltipText>
          )}
        </Flexbox>
      )}

      <DropdownInputIcon
        showOptions={showOptions}
        iconName={iconName}
        iconSize={iconSize}
      />
    </Flexbox>
  );
};

export const MemoizedDropdownInput = memo(DropdownInput);
