import _ from 'lodash';
import { Container } from '../../Container';
import { EventRowProps } from './types';
import { EventStatus } from './EventStatus';
import { EventSubRow } from './EventSubRow';
import { EventToolip } from './EventTooltip';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { IntegrationIcon } from '../../IntegrationIcon';
import { Text } from '../../Typography';
import { WrapperModal } from '../../WrapperModal';
import { convertTimestampToMoment } from '../../Filters';
import { convertToKebabCase } from '../../Helpers/convertToKebabCase';
import { defaultDateTimeFormatWithSeconds } from '../../Filters/TimeFilter/constants';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { useHover } from '../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';
import {
  FC,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const EventRow: FC<EventRowProps> = ({
  eventTime,
  eventType,
  hasWorkflowExecutions,
  id,
  isRowSelected,
  onRowClick,
  onRowHover,
  sourceApplication,
  workflowExecutions,
  ...props
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const handleRowMouseEnter = () => {
    handleMouseEnter();
    onRowHover();
  };

  const [isExpanded, setIsExpanded] = useState(isRowSelected);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const backgroundColor = isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;

  const borderColor = isRowSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;

  const carrotIcon = isExpanded ? 'carrot-down' : 'carrot-right';

  const nameTag = isRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    eventTime &&
    convertTimestampToMoment(eventTime).format(
      defaultDateTimeFormatWithSeconds
    );

  const handleRowClick = () => {
    onRowClick();
    setIsExpanded(true);
    updateUrlParam({ eventId: id });
  };

  const handleCarrotIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isExpanded) {
      handleRowClick();
    } else {
      setIsExpanded(false);
    }
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, handleShowTooltip]);

  useEffect(() => {
    setIsExpanded(isRowSelected);
  }, [isRowSelected]);

  return (
    <>
      <Flexbox
        key={id}
        containerRef={containerRef}
        data-testid='event-row'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={0.25}
        borderType='border'
        cursor='pointer'
        width='16.5rem'
        onMouseEnter={handleRowMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleRowClick}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          p={0.5}
          gap={0.25}
        >
          <Container width='auto' height='auto'>
            {sourceApplication && (
              <IntegrationIcon
                name={convertToKebabCase(
                  source_application.v2.SourceApplication.Application[
                    sourceApplication
                  ]
                )}
                size={1.25}
              />
            )}
          </Container>

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0.25}
          >
            <Container width='11.5rem' height='auto'>
              <Text tag={nameTag} whiteSpace='nowrap'>
                {eventType}
              </Text>
            </Container>

            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>
          </Flexbox>

          <EventStatus variant='icon' />

          <Flexbox justifyContent='center' alignItems='center'>
            {hasWorkflowExecutions && (
              <Container
                height='auto'
                width='auto'
                onClick={handleCarrotIconClick}
              >
                <Icon
                  name={carrotIcon}
                  size={0.75}
                  fill={theme.colors.ui.dark}
                  stroke='none'
                />
              </Container>
            )}
          </Flexbox>
        </Flexbox>

        {hasWorkflowExecutions && isExpanded && (
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            onMouseEnter={handleMouseLeave}
            gap={0}
          >
            {workflowExecutions.map((e, i) => (
              <EventSubRow
                key={i}
                {...e}
                isLastSubRow={i === workflowExecutions.length - 1}
              />
            ))}
          </Flexbox>
        )}
      </Flexbox>

      {showTooltip && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <EventToolip
            id={id}
            eventType={eventType}
            eventTime={eventTime}
            sourceApplication={sourceApplication}
            hasWorkflowExecutions={hasWorkflowExecutions}
            {...props}
          />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedEventRow = memo(EventRow);
