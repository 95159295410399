export const Conditional = () => {
  return (
    <>
      <g clipPath='url(#clip0_906_291)'>
        <path
          d='M9.99264 11.6642L7.16421 8.83579L4.33579 11.6642L7.16421 14.4926L9.99264 11.6642ZM9.99264 11.6642L11.8358 11.6642C12.9404 11.6642 13.8358 10.7688 13.8358 9.66421V8.25002L13.8358 7.83582C13.8358 6.17896 15.179 4.83582 16.8358 4.83582L20.8358 4.83582M9.99264 11.6642L11.8358 11.6642C12.9404 11.6642 13.8358 12.5596 13.8358 13.6642L13.8358 14.3358C13.8358 16.5449 15.6266 18.3358 17.8358 18.3358L20.8358 18.3358M20.8358 4.83582L18.8358 2.83582M20.8358 4.83582L18.8358 6.83582M20.8358 18.3358L18.8358 16.3358M20.8358 18.3358L18.8358 20.3358'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_906_291'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </>
  );
};
