import { FC } from 'react';
import { IntegrationIconMapping } from './IntergrationIconMapping';
import { IntergrationIconProps } from './types';
import { Flexbox } from '../Flexbox';

export const IntegrationIcon: FC<IntergrationIconProps> = ({
  name,
  size = 1.5,
}) => {
  const integrationIcons = IntegrationIconMapping();
  const iconSize = `${size}rem`;

  return (
    <Flexbox
      id={name}
      data-testid={name}
      width={iconSize}
      height={iconSize}
      justifyContent='center'
      alignItems='center'
    >
      <svg id={name} fill='none' stroke='none' viewBox='0 0 40 40'>
        {integrationIcons[name]}
      </svg>
    </Flexbox>
  );
};
