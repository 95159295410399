import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import _ from 'lodash';

export const validateLookupNode = (
  lookupNode: profiles.v1.LookupConfiguration
): boolean => {
  const lookupsSequence = lookupNode.lookupsSequence;

  return _.every(lookupsSequence, (lookup) => {
    const hasFieldNotPresentAction = lookup.fieldNotPresentAction;
    const idIndex = lookup.index?.id;
    const emailIndex = lookup.index?.email;
    const phoneIndex = lookup.index?.phone;

    const hasValidIdIndex =
      idIndex && idIndex.idFieldLookup && idIndex.thirdPartyIdType;

    const hasValidEmailIndex =
      emailIndex &&
      emailIndex.uniqueIndexFieldLookup &&
      emailIndex.multipleProfilesAction;

    const hasValidPhoneIndex =
      phoneIndex &&
      phoneIndex.uniqueIndexFieldLookup &&
      phoneIndex.multipleProfilesAction;

    return (
      hasFieldNotPresentAction &&
      (hasValidIdIndex || hasValidEmailIndex || hasValidPhoneIndex)
    );
  });
};
