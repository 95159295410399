import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { ProfileTypeInfoCellProps } from './types';
import { Container } from '../Container';
import { Icon } from '../Icon';

const ProfileTypeInfoCell: FC<ProfileTypeInfoCellProps> = ({
  fullName,
  id,
  type,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='profile-type-info-cell'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      gap={0.5}
    >
      <Container width='auto' height='auto'>
        <Icon name={`${type}-type`} stroke={theme.colors.ui.dark} />
      </Container>
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        width='auto'
        gap={0}
      >
        {fullName && fullName !== 'n/a' && (
          <Text tag='h5' whiteSpace='nowrap'>
            {fullName}
          </Text>
        )}
        <Text tag='p2' whiteSpace='nowrap'>
          {id}
        </Text>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedProfileTypeInfoCell = memo(ProfileTypeInfoCell);
