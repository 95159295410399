import React, { memo, useRef, useState } from 'react';
import { ExtendedColumnDef } from '../../../types';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { ModalPosition, TableCellProps } from './types';
import { RowData, flexRender } from '@tanstack/react-table';
import { SkeletonLoadable } from '../../../../Skeleton';
import { StyledCell } from './TableCell.css';
import { WrapperModal } from '../../../../WrapperModal';
import { toggleModal } from './toggleModal';
import { useHover } from '../../../../Hooks/useHover';
import { useTheme } from '@morf/theming';

const Cell: <RowData>(props: TableCellProps<RowData>) => JSX.Element = ({
  cell,
  cellHeight,
  id,
  isExpanded,
  isFirstColumnCell,
  isFirstRow,
  isLastColumnCell,
  isLoading,
  onRowRemove,
  onSubRowAdd,
  onSubRowRemove,
  parentCellHeight,
  subCellHeight,
  ...props
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const isSubCell = !!cell.row.getParentRow();
  const isParentCell = !!cell.row.originalSubRows?.length;
  const columnDef = cell.column.columnDef as ExtendedColumnDef<RowData>;
  const cellModal = columnDef.cellModal;
  const minWidth = `${cell.column.getSize() / 16}rem`;

  const cellContentRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState<ModalPosition>({
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledCell
      key={cell.id}
      borderType={
        isParentCell
          ? columnDef.parentCellBorderType
          : isSubCell
          ? columnDef.subCellBorderType
          : columnDef.cellBorderType
      }
      cellPt={
        isParentCell
          ? columnDef.parentCellPt
          : isSubCell
          ? columnDef.subCellPt
          : columnDef.cellPt
      }
      cellPb={
        isParentCell
          ? columnDef.parentCellPb
          : isSubCell
          ? columnDef.subCellPb
          : columnDef.cellPb
      }
      cellPl={
        isParentCell
          ? columnDef.parentCellPl
          : isSubCell
          ? columnDef.subCellPl
          : columnDef.cellPl
      }
      cellPr={
        isParentCell
          ? columnDef.parentCellPr
          : isSubCell
          ? columnDef.subCellPr
          : columnDef.cellPr
      }
      isFirstRow={isFirstRow}
      {...props}
    >
      <Flexbox
        justifyContent='flex-start'
        alignItems='center'
        containerRef={cellContentRef}
        data-testid={`cell-${cell.id}`}
        zIndex={0}
        minWidth={minWidth}
        height={
          isParentCell
            ? parentCellHeight
            : isSubCell
            ? subCellHeight
            : cellHeight
        }
        onClick={(e) => {
          if (cellModal) {
            e.stopPropagation();
            toggleModal(
              cellContentRef,
              isModalOpen,
              setIsModalOpen,
              setModalPosition
            );
          }
        }}
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
            <Flexbox gap={0.25} justifyContent='flex-start' alignItems='center'>
              {isParentCell && isFirstColumnCell && (
                <Flexbox
                  width='1rem'
                  justifyContent='flex-start'
                  alignItems='center'
                  onClick={(e) => {
                    e.stopPropagation();
                    cell.row.toggleExpanded();
                  }}
                >
                  <Icon
                    name={isExpanded ? 'carrot-down' : 'carrot-right'}
                    fill={theme.colors.ui.dark}
                    stroke='none'
                    size={1}
                    cursor='pointer'
                  />
                </Flexbox>
              )}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Flexbox>
          </SkeletonLoadable>

          {onSubRowAdd && isLastColumnCell && isParentCell && (
            <Flexbox
              data-testid='add-sub-row'
              cursor='pointer'
              width='auto'
              height='auto'
              onClick={(e) => {
                e.stopPropagation();
                onSubRowAdd(parseInt(cell.row.id));
              }}
            >
              <Icon
                name='plus-circle'
                size={1.25}
                strokeWidth={1.75}
                stroke={theme.colors.main.primary.darker}
              />
            </Flexbox>
          )}

          {isHovered && onRowRemove && isLastColumnCell && !isSubCell && (
            <Flexbox
              data-testid='remove-row'
              cursor='pointer'
              width='auto'
              height='auto'
              onClick={(e) => {
                e.stopPropagation();
                onRowRemove(parseInt(cell.row.id));
              }}
            >
              <Icon
                name='minus-circle'
                size={1.25}
                strokeWidth={1.75}
                stroke={theme.colors.support.red.darkest}
              />
            </Flexbox>
          )}

          {isHovered && onSubRowRemove && isLastColumnCell && isSubCell && (
            <Flexbox
              data-testid='remove-sub-row'
              cursor='pointer'
              width='auto'
              height='auto'
              onClick={(e) => {
                e.stopPropagation();
                const [rowId, subRowId] = String(cell.row.id)
                  .split('.')
                  .map(Number);
                onSubRowRemove(rowId, subRowId);
              }}
            >
              <Icon
                name='minus-circle'
                size={1.25}
                strokeWidth={1.75}
                stroke={theme.colors.support.red.darkest}
              />
            </Flexbox>
          )}
        </Flexbox>

        {isModalOpen && cellModal && (
          <WrapperModal
            top={modalPosition.top}
            left={modalPosition.left}
            bottom={modalPosition.bottom}
            backgroundColor='transparent'
            isOpen={isModalOpen}
            onClose={closeModal}
          >
            {React.cloneElement(cellModal, {
              cell: cell.getValue(),
              onClose: closeModal,
            })}
          </WrapperModal>
        )}
      </Flexbox>
    </StyledCell>
  );
};

export const TableCell = memo(Cell) as typeof Cell;
