import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { CustomNode } from '../types';

export type ProfileLookupNodeProps = CustomNode<
  profiles.v1.LookupConfiguration | null | undefined
>;

export type ProfileLookupProps = {
  index: number;
} & ProfileLookupNodeProps;

export enum ProfileLookupNodeType {
  ActionNode = 'actionNode',
  BranchNode = 'branchNode',
  ErrorNode = 'errorNode',
  FieldNode = 'fieldNode',
  RetryNode = 'retryNode',
  ReturnWorkflowNode = 'returnWorkflowNode',
  TerminateNode = 'terminateNode',
}
