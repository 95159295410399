export const Formsort = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.2998 8.85376H17.6014C17.1338 8.85376 16.7548 9.23276 16.7548 9.70033V14.7797C16.7548 15.2473 17.1338 15.6263 17.6014 15.6263H30.2998C30.7674 15.6263 31.1464 15.2473 31.1464 14.7797V9.70033C31.1464 9.23276 30.7674 8.85376 30.2998 8.85376Z'
        fill='#091A4F'
      />
      <path
        d='M19.8589 24.6562H11.9577C10.0876 24.6562 8.57143 26.1725 8.57143 28.0425C8.57143 29.9126 10.0876 31.4287 11.9577 31.4287H19.8589C21.729 31.4287 23.2452 29.9126 23.2452 28.0425C23.2452 26.1725 21.729 24.6562 19.8589 24.6562Z'
        fill='#EF4423'
      />
      <path
        d='M17.6014 16.7549C17.1338 16.7549 16.7548 17.1338 16.7548 17.6014V22.6808C16.7548 23.1484 17.1338 23.5273 17.6014 23.5273H30.2998C30.7674 23.5273 31.1464 23.1484 31.1464 22.6808V17.6014C31.1464 17.1338 30.7674 16.7549 30.2998 16.7549H17.6014Z'
        fill='#FFD229'
      />
      <path
        d='M8.57143 12.24C8.57143 14.2661 10.2137 15.9084 12.2399 15.9084C14.266 15.9084 15.9083 14.2661 15.9083 12.24C15.9083 10.2139 14.266 8.57153 12.2399 8.57153C10.2137 8.57153 8.57143 10.2139 8.57143 12.24Z'
        fill='#EF4423'
      />
      <path
        d='M14.7795 16.7549H9.70016C9.23258 16.7549 8.85358 17.1338 8.85358 17.6014V22.6808C8.85358 23.1484 9.23258 23.5273 9.70016 23.5273H14.7795C15.2471 23.5273 15.6261 23.1484 15.6261 22.6808V17.6014C15.6261 17.1338 15.2471 16.7549 14.7795 16.7549Z'
        fill='#091A4F'
      />
    </svg>
  );
};
