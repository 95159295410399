import { FC, useState } from 'react';
import { Tooltip } from '../Tooltip';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { CopyToClipboardProps } from './types';

export const CopyToClipboard: FC<CopyToClipboardProps> = ({
  iconName = 'copy',
  iconViewBox = '0 0 20 20',
  iconFill,
  iconStroke = 'none',
  iconSize = 1,
  text,
  label = 'Copy to clipboard',
  tooltipText,
  ...props
}) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      new Error((error as Error).message);
    }
  };

  return (
    <Tooltip tooltipText={copied ? 'Copied' : label} {...props}>
      <Icon
        name={iconName}
        viewBox={iconViewBox}
        fill={iconFill || theme.colors.main.primary.darker}
        stroke={iconStroke}
        size={iconSize}
        cursor='pointer'
        onClick={copyToClipboard}
      />
    </Tooltip>
  );
};
