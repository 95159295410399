import { styled } from '@morf/theming';
import { ContainerProps } from '../../../Container';
import { borderStyles } from '../../../Container/Container.css';

export const StyledHeadCell = styled.th<{
  borderType: ContainerProps['borderType'];
  headCellPl: ContainerProps['pl'];
  headCellPr: ContainerProps['pr'];
}>`
  top: 0;
  z-index: var(--table-header-cell-index);

  ${borderStyles}
  padding-left: ${(props) => `${props.headCellPl}rem`};
  padding-right: ${(props) => `${props.headCellPr}rem`};
`;
