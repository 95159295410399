import { styled } from '@morf/theming';
import { FlexItemProps, FlexboxProps } from './types';
import { Container } from '../Container';

export const StyledFlexbox = styled(Container)<FlexboxProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => (props.gap ? `${props.gap}rem` : '0')};
`;

export const FlexItem = styled.div<FlexItemProps>`
  flex-grow: ${(props) => props.flexGrow || 0};
  flex-basis: ${(props) => props.flexBasis || 'auto'};
  align-self: ${(props) => props.alignSelf || 'auto'};
  order: ${(props) => props.flexOrder};
  text-align: ${(props) => props.alignSelf};
  width: ${(props) => props.width || 'auto'};
`;
