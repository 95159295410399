import moment from 'moment';

export const checkIsValid = (dateString: string): boolean => {
  const dateRegex = /^202\d{1}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dateString)) {
    return false;
  }
  const date = moment(dateString, 'YYYY-MM-DD', true);
  return date.isValid();
};
