import { FC, Fragment } from 'react';
import { FlexItem, Flexbox } from '../../Flexbox';
import { Badge } from '../../Badge';
import { Text } from '../../Typography';
import { Icon } from '../../Icon';
import { useTheme } from '@morf/theming';
import { Avatar } from '../../Avatar';
import { Container } from '../../Container';
import { StepStatusProps } from './types';
import { capitalizeFirstLetter } from '../../Helpers/capitalizeFirstLetter';

export const StepStatus: FC<StepStatusProps> = ({
  name,
  imageUrl,
  type,
  subStatus,
}) => {
  const theme = useTheme();

  return (
    <Fragment>
      {subStatus ? (
        <Flexbox
          direction='column'
          shadow='sm'
          backgroundColor={theme.colors.ui.card}
          borderRadius={1}
          gap={0}
        >
          <Container p={1.5}>
            <Text tag='h3'>{name}</Text>
          </Container>
          {subStatus.map((subStatus, index) => (
            <Flexbox
              key={index}
              borderType='borderTop'
              alignItems='center'
              p={1.5}
            >
              <Icon name='details' stroke={theme.colors.text.muted} />
              <FlexItem flexGrow={1}>
                <Text>{subStatus.name}</Text>
              </FlexItem>
              <Badge
                type={subStatus.type}
                label={capitalizeFirstLetter(subStatus.type)}
              />
            </Flexbox>
          ))}
        </Flexbox>
      ) : (
        <Flexbox
          justifyContent='flex-start'
          alignItems='center'
          backgroundColor={theme.colors.ui.card}
          shadow='sm'
          borderRadius={1}
          p={1.5}
          gap={1}
        >
          {imageUrl && <Avatar src={imageUrl} alt={name} />}
          <FlexItem flexGrow={1}>
            <Text tag='h4' align='left'>
              {name}
            </Text>
          </FlexItem>
          {type && <Badge type={type} label={capitalizeFirstLetter(type)} />}
        </Flexbox>
      )}
    </Fragment>
  );
};
