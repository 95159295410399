import { FC, useState } from 'react';
import moment from 'moment';
import { TimeslotProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { StyledTimeslot } from './Timeslots.css';
import { Button } from '../Button';
import { useTheme } from '@morf/theming';

export const Timeslot: FC<TimeslotProps> = ({
  slot,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
}) => {
  const theme = useTheme();
  const [isConfirming, setIsConfirming] = useState(false);
  const isSelected =
    selectedSlot.id === slot.id &&
    moment(selectedSlot.datetime).isSame(slot.datetime);

  const handleSelectSlot = () => {
    onSelectSlot(slot);
  };

  const handleConfirmSlot = async () => {
    setIsConfirming(true);

    try {
      await new Promise(() => onConfirmSlot(slot));
      setIsConfirming(false);
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  return (
    <Flexbox gap={0.25} data-testid={slot.id + ' ' + slot.datetime}>
      <StyledTimeslot
        data-testid='timeslot'
        key={slot.id + slot.datetime}
        isActive={isSelected}
        onClick={handleSelectSlot}
        shape='square'
      >
        <Text tag='h5' color='inherit'>
          {moment(slot.datetime).format('h:mm A')}
        </Text>
      </StyledTimeslot>
      {isSelected && (
        <Button
          data-testid='confirm'
          variant='primary'
          onClick={handleConfirmSlot}
          isDisabled={isConfirming}
          borderRadius={theme.timePicker.borderRadius}
          shape='square'
          width='100%'
        >
          <Text tag='h5' color='inherit'>
            {isConfirming ? 'Confirming...' : 'Confirm'}
          </Text>
        </Button>
      )}
    </Flexbox>
  );
};
