import { Dropdown } from '../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { OptionalParameterMissingProps } from './types';
import { RequiredIndicator } from '../../../RequiredIndicator';
import { Text } from '../../../Typography';
import { formatEnumToOptions } from '../../../Helpers/formatEnumToOptions';
import { labelMap } from './constants';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const OptionalParameterMissing: FC<OptionalParameterMissingProps> = ({
  isReadOnly,
  onOptionalParameterMissingChange,
  optionalParameterMissingAction,
}) => {
  const theme = useTheme();

  const optionalParameterMissingActions = formatEnumToOptions(
    workflow_parameters.v1.ParameterMissingPolicy
      .OptionalParameterMissingBehavior
  ).map((option) =>
    labelMap[option.label]
      ? { ...option, label: labelMap[option.label] }
      : option
  );

  const optionalParameterMissingActionAsOption =
    optionalParameterMissingActions.filter(
      (fieldNotPresentAction) =>
        fieldNotPresentAction.value ===
        optionalParameterMissingAction?.toString()
    );

  return (
    <Flexbox
      data-testid='optional-parameter-missing'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.25}
    >
      <Flexbox direction='row' height='auto'>
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={0}
        >
          <Text tag='p2'>Optional parameters not present on the payload</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select what should happen.
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={optionalParameterMissingActionAsOption}
          options={optionalParameterMissingActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onOptionalParameterMissingChange(selectedOptionNumber);
          }}
          readOnly={isReadOnly}
          clearable
          height='2.25rem'
        />
      </Flexbox>
      {!optionalParameterMissingAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedOptionalParameterMissing = memo(OptionalParameterMissing);
