import { google } from '@morf/proto/struct_ts_proto';
import { formatGoogleStruct } from './formatGoogleStruct';

export const formatIdStruct = (
  id: string | undefined,
  struct: google.protobuf.Struct | null | undefined
): string => {
  const fields: { [key: string]: google.protobuf.Value } = {};

  if (id !== undefined) {
    fields.id = new google.protobuf.Value({ stringValue: id });
  }

  if (struct != null) {
    fields.payload = new google.protobuf.Value({ structValue: struct });
  }

  return JSON.stringify(formatGoogleStruct({ fields }));
};
