import { createCustomTimeUrlParam } from '../Filters/TimeFilter/TimeDropdown/CustomTimeRange/createCustomTimeUrlParam';
import { event_payload } from '@morf/proto/event_payload_v2_ts_proto';

export const createEventLink = (
  organizationId: string | undefined,
  eventSnapshot: event_payload.v2.EventSnapshot | null | undefined
): string | undefined => {
  if (
    !eventSnapshot ||
    !eventSnapshot.id ||
    !eventSnapshot.eventTime ||
    !organizationId
  )
    return undefined;

  const eventTime = createCustomTimeUrlParam(eventSnapshot.eventTime);
  const eventURL = new URL(
    window.location.origin +
      `/organizations/${organizationId}/monitoring/events/?eventId=${eventSnapshot.id}`
  );
  eventURL.searchParams.set('time', eventTime);
  return eventURL.toString();
};
