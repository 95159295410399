import { FC, forwardRef, Ref } from 'react';
import { StyledTextarea } from './Textarea.css';
import { TextareaProps } from './types';

export const Textarea: FC<TextareaProps> = forwardRef(
  ({ name, ...props }, ref: Ref<HTMLTextAreaElement>) => {
    return (
      <StyledTextarea data-testid={name} name={name} ref={ref} {...props} />
    );
  }
);
