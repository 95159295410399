import { Handle, Position } from 'reactflow';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { useTheme } from '@morf/theming';
import { Text } from '../../../../Typography';
import { ProfileLookupNodeProps } from '../types';
import { nodeWidth } from '../../../constants';

const BranchNode: FC<ProfileLookupNodeProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <Flexbox
        direction='row'
        data-testid='branch-node'
        backgroundColor={theme.colors.ui.body}
        justifyContent='center'
        alignItems='center'
        borderType='none'
        height='1rem'
        width={nodeWidth}
      >
        <Flexbox width='auto' maxWidth='9.5rem' alignItems='center'>
          <Text tag='p3' whiteSpace='nowrap' color={theme.colors.text.muted}>
            {data.value.name}
          </Text>
        </Flexbox>
      </Flexbox>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedBranchNode = memo(BranchNode);
