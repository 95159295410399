import { Container } from '../../../Container';
import { CustomNodeLabelProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { StyledCustomNodeLabel } from './CustomNodeLabel.css';
import { Text } from '../../../Typography';
import { nodeHeight, nodeWidth } from '../../constants';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

const CustomNodeLabel: FC<CustomNodeLabelProps> = ({
  iconName,
  id,
  integrationIconName,
  isRequired,
  name,
  status = 'default',
  title,
}) => {
  const theme = useTheme();
  const { selectedNode } = useWorkflow();
  const isSelected = selectedNode?.id === id;

  const iconMapping: Record<
    NonNullable<CustomNodeLabelProps['status']>,
    { iconName: string; stroke: string }
  > = {
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.UNSPECIFIED_STATUS]:
      { iconName: 'alert', stroke: theme.colors.support.red.darkest },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.PROCESSING]: {
      iconName: 'circle-circle',
      stroke: theme.colors.ui.dark,
    },
    progress: {
      iconName: 'clock',
      stroke: theme.colors.ui.dark,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.EXECUTED]: {
      iconName: 'check-circle',
      stroke: theme.colors.support.green.darkest,
    },
    success: {
      iconName: 'check-circle',
      stroke: theme.colors.support.green.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.ERROR]: {
      iconName: 'alert',
      stroke: theme.colors.support.red.darkest,
    },
    error: {
      iconName: 'alert',
      stroke: theme.colors.support.red.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WARNING]: {
      iconName: 'alert',
      stroke: theme.colors.support.yellow.darkest,
    },
    warning: {
      iconName: 'alert',
      stroke: theme.colors.support.yellow.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WAITING]: {
      iconName: 'circle-circle',
      stroke: '#9747FF',
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.TERMINATED]: {
      iconName: 'x-circle',
      stroke: theme.colors.ui.dark,
    },
    filter: {
      iconName: 'x-circle',
      stroke: theme.colors.ui.dark,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING]: {
      iconName: 'refresh-circle',
      stroke: theme.colors.ui.dark,
    },
    retry: {
      iconName: 'refresh-circle',
      stroke: theme.colors.ui.dark,
    },
    inactive: {
      iconName: '',
      stroke: '',
    },
    default: {
      iconName: isRequired ? 'alert' : '',
      stroke: theme.colors.support.red.darkest,
    },
  };

  return (
    <StyledCustomNodeLabel
      data-testid='custom-node-label'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      position='relative'
      status={status}
      isSelected={isSelected}
      borderRadius={0.5}
      gap={0.25}
      p={0.5}
      shadow='base'
      width={nodeWidth}
      height={nodeHeight}
    >
      {status &&
        iconMapping[status] &&
        iconMapping[status].iconName &&
        iconMapping[status].stroke && (
          <Container
            position='absolute'
            height='auto'
            width='auto'
            right='-0.5rem'
            top='-0.5rem'
          >
            <Icon
              name={iconMapping[status].iconName}
              stroke={iconMapping[status].stroke}
              fill={theme.colors.ui.card}
              size={1.25}
            />
          </Container>
        )}

      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={0.25}
      >
        {iconName && !integrationIconName && (
          <Icon name={iconName} stroke={theme.colors.text.muted} size={1.25} />
        )}
        {integrationIconName && <IntegrationIcon name={integrationIconName} />}

        <Text tag='p3' color={theme.colors.text.muted}>
          {title}
        </Text>
      </Flexbox>
      <Text tag='p2'>{name}</Text>
    </StyledCustomNodeLabel>
  );
};

export const MemoizedCustomNodeLabel = memo(CustomNodeLabel);
