import { css, styled } from '@morf/theming';
import { TimelineRowStatusProps } from './types';

const typeStyles = {
  retry: css(
    ({ theme }) => `
    background-color: ${theme.colors.text.muted};
  `
  ),
  filter: css(
    ({ theme }) => `
    background-color: ${theme.colors.text.muted};
  `
  ),
  progress: css(
    ({ theme }) => `
    background-color: ${theme.colors.text.muted};
  `
  ),
  error: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.red.darkest};
  `
  ),
  warning: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.yellow.darkest};
  `
  ),
  success: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.green.darkest};
  `
  ),
};

export const StyledTimelineRowStatus = styled.div<{
  type: TimelineRowStatusProps['type'];
}>`
  ${(props) => typeStyles[props.type]};
  width: 0.25rem;
  height: inherit;
  border-radius: ${(props) => props.theme.borderRadius[0.25]};
`;
