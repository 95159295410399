import { google } from '@morf/proto/struct_ts_proto';
import { hasField } from './hasField';
import { formatGoogleStruct } from './formatGoogleStruct';

export type FormattedGoogleProtobufValue =
  | google.protobuf.Value
  | string
  | boolean
  | number
  | null
  | { [key: string]: FormattedGoogleProtobufValue }
  | FormattedGoogleProtobufValue[];

export const formatGoogleProtobufValue = (
  value: google.protobuf.Value | undefined | null
): FormattedGoogleProtobufValue => {
  if (!value) {
    return 'n/a';
  }
  if (hasField(value, 'boolValue')) {
    return value.boolValue;
  }

  if (hasField(value, 'listValue')) {
    return (value.listValue as google.protobuf.ListValue).values.map((value) =>
      formatGoogleProtobufValue(value)
    );
  }

  if (hasField(value, 'nullValue')) {
    return null;
  }

  if (hasField(value, 'numberValue')) {
    return value.numberValue;
  }

  if (hasField(value, 'stringValue')) {
    return value.stringValue;
  }

  if (hasField(value, 'structValue')) {
    return formatGoogleStruct(value.structValue);
  }

  return value;
};
