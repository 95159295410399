import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Identifier } from './Identifier';
import { IdentifiersProps } from './types';

const Identifiers: FC<IdentifiersProps> = ({ identifiers, onClick }) => {
  return (
    <Flexbox
      data-testid='identifiers'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='scroll'
      gap={0}
    >
      {identifiers.map((item) => (
        <Identifier {...item} onClick={onClick} />
      ))}
    </Flexbox>
  );
};

export const MemoizedIdentifiers = memo(Identifiers);
