import { Dropdown } from '../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { ProfileLookupFieldNotPresentProps } from './types';
import { RequiredIndicator } from '../../../RequiredIndicator';
import { Text } from '../../../Typography';
import { capitalizeFirstLetter } from '../../../Helpers/capitalizeFirstLetter';
import { formatEnumToOptions } from '../../../Helpers/formatEnumToOptions';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useTheme } from '@morf/theming';

const ProfileLookupFieldNotPresent: FC<ProfileLookupFieldNotPresentProps> = ({
  isReadOnly,
  lookup,
  onFieldNotPresentActionChange,
}) => {
  const theme = useTheme();
  const fieldNotPresentAction = lookup.fieldNotPresentAction;
  const fieldName =
    lookup?.index?.id?.idFieldLookup?.celExpressionStr ||
    lookup?.index?.email?.uniqueIndexFieldLookup?.celExpressionStr ||
    lookup?.index?.phone?.uniqueIndexFieldLookup?.celExpressionStr;

  const fieldNotPresentActions = formatEnumToOptions(
    profiles.v1.FieldNotPresentAction
  ).map((action) => ({
    label: capitalizeFirstLetter(
      action.label.replace('Field not present action', '').trim()
    ),
    value: action.value,
  }));

  const selectedFieldNotPresentActionAsOption = fieldNotPresentActions.filter(
    (action) => action.value === lookup.fieldNotPresentAction?.toString()
  );

  return (
    <Flexbox
      data-testid='profile-lookup-field-not-present'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='p2' whiteSpace='normal' wordWrap='break-word'>
            {fieldName || 'Field'} not present on payload
          </Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            What should happen if the field is not present?
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={selectedFieldNotPresentActionAsOption}
          options={fieldNotPresentActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onFieldNotPresentActionChange(selectedOptionNumber);
          }}
          clearable
          height='2.25rem'
          readOnly={isReadOnly}
        />
      </Flexbox>
      {!fieldNotPresentAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedProfileLookupFieldNotPresent = memo(
  ProfileLookupFieldNotPresent
);
