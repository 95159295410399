import { setQuestionSize } from '@formsort/custom-question-api';
import { DependencyList, useEffect } from 'react';
import { useFormsortAnswers } from './useFormsortAnswers';

export const useResizeHandler = (dep: DependencyList) => {
  const { isFormsortFlow } = useFormsortAnswers();
  useEffect(() => {
    const handleResize = () => {
      const minHeight = 700;
      const height = Math.max(minHeight, document.body.offsetHeight);
      isFormsortFlow && setQuestionSize('100%', height);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, dep);
};
