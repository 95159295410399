import { Dropdown } from '../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { ProfileLookupTypeProps } from './types';
import { Text } from '../../Typography';
import { capitalizeFirstLetter } from '../../Helpers/capitalizeFirstLetter';
import { formatEnumToOptions } from '../../Helpers/formatEnumToOptions';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { RequiredIndicator } from '../../RequiredIndicator';

const ProfileLookupType: FC<ProfileLookupTypeProps> = ({
  isReadOnly,
  onLookupTypeChange,
  lookupType,
}) => {
  const theme = useTheme();

  const profilelookupTypes = formatEnumToOptions(profiles.v1.LookupType).map(
    (action) => ({
      label: capitalizeFirstLetter(
        action.label.replace('Lookup type', '').trim()
      ),
      value: action.value,
    })
  );

  const selectedProfileLookupTypeAsOption = profilelookupTypes.filter(
    (type) => type.value === lookupType?.toString()
  );

  return (
    <Flexbox
      data-testid='profile-lookup-type'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='p2'>Profile type triggers</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select which profile types trigger the workflow
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={selectedProfileLookupTypeAsOption}
          options={profilelookupTypes}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onLookupTypeChange(selectedOptionNumber);
          }}
          height='2.25rem'
          readOnly={isReadOnly}
        />
      </Flexbox>
      {!lookupType && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedProfileLookupType = memo(ProfileLookupType);
