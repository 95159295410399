import { ChangeEvent, FC, useState } from 'react';
import { Container } from '../../Container';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { SearchProps } from './types';
import { Tooltip } from '../../Tooltip';
import { memo } from 'react';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';

const Search: FC<SearchProps> = ({
  searchTooltipText,
  searchValue,
  setSearchValue,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(
    !!searchValue
  );

  const handleShowSearchInput = () => setShowSearchInput(true);

  const handleCloseSearchInput = () => {
    setSearchValue('');
    setShowSearchInput(false);
    updateUrlParam({ errorDetailLike: null });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);
    updateUrlParam({ errorDetailLike: searchValue || null });
  };

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      width='auto'
      gap={0.5}
    >
      <Tooltip
        tooltipText={searchTooltipText}
        tooltipDirection='bottom'
        arrowDirection='left'
      >
        <Container
          borderRadius={0.25}
          cursor='pointer'
          height='auto'
          hoverBackgroundColor={theme.colors.main.light.light}
          onClick={handleShowSearchInput}
          p={0.125}
          width='auto'
        >
          <Icon
            name='search'
            size={1.125}
            stroke={theme.colors.ui.dark}
            strokeWidth={2}
          />
        </Container>
      </Tooltip>

      {showSearchInput && (
        <Input
          id='search-value'
          autoFocus
          borderType='none'
          onChange={handleSearchChange}
          p={0}
          value={searchValue}
          width='15rem'
          rightElement={
            <Icon
              name='x-circle-solid'
              size={2}
              strokeWidth={0.125}
              stroke={theme.colors.ui.card}
              fill={theme.colors.ui.muted}
              cursor='pointer'
              onClick={handleCloseSearchInput}
            />
          }
        />
      )}
    </Flexbox>
  );
};

export const MemoizedSearch = memo(Search);
