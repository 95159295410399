import { FC, memo } from 'react';
import { IntegrationListProps } from './types';
import { Flexbox } from '../Flexbox';
import { IntegrationIcon } from '../IntegrationIcon';
import { useTheme } from '@morf/theming';
import { StyledIntegration } from './IntegrationList.css';

const IntegrationList: FC<IntegrationListProps> = ({ list }) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='integration-list'
      justifyContent='flex-start'
      alignItems='center'
      gap={0}
    >
      {list.map((integration, index) => {
        return (
          <StyledIntegration
            key={index}
            index={index}
            borderType='border'
            borderRadius='full'
            backgroundColor={theme.colors.ui.card}
            width='auto'
            height='auto'
          >
            <IntegrationIcon name={integration.name} size={integration.size} />
          </StyledIntegration>
        );
      })}
    </Flexbox>
  );
};

export const MemoizedIntegrationList = memo(IntegrationList);
