'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { address as address$1 } from './address_v1_ts_proto';
import { google as google$1 } from './empty_ts_proto';
import { permissions as permissions$1 } from './permissions_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.accounts = (() => {
  const accounts = {};
  accounts.v1 = (() => {
    const v1 = {};
    v1.CreateAccountRequest = (() => {
      class CreateAccountRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAccountRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.note != null &&
            Object.hasOwnProperty.call(message, 'note')
          ) {
            writer.uint32(10).string(message.note);
          }
          if (
            message.address != null &&
            Object.hasOwnProperty.call(message, 'address')
          ) {
            address$1.v1.Address.encode(
              message.address,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAccountRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.note = reader.string();
                break;
              }
              case 2: {
                message.address = address$1.v1.Address.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateAccountRequest) {
            return object;
          }
          const message = new $root.accounts.v1.CreateAccountRequest();
          if (object.note != null) {
            message.note = String(object.note);
          }
          if (object.address != null) {
            if (typeof object.address !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateAccountRequest.address: object expected, but got ' +
                  typeof object.address
              );
            }
            message.address = address$1.v1.Address.fromObject(object.address);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.address = null;
          }
          let keys;
          if (message.note != null && message.hasOwnProperty('note')) {
            object.note = message.note;
          }
          if (message.address != null && message.hasOwnProperty('address')) {
            object.address = address$1.v1.Address.toObject(
              message.address,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateAccountRequest';
        }
      }

      CreateAccountRequest.prototype.note = null;
      CreateAccountRequest.prototype.address = null;

      return CreateAccountRequest;
    })();

    v1.CreateAccountResponse = (() => {
      class CreateAccountResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAccountResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.account != null &&
            Object.hasOwnProperty.call(message, 'account')
          ) {
            $root.accounts.v1.Account.encode(
              message.account,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAccountResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.account = $root.accounts.v1.Account.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateAccountResponse) {
            return object;
          }
          const message = new $root.accounts.v1.CreateAccountResponse();
          if (object.account != null) {
            if (typeof object.account !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateAccountResponse.account: object expected, but got ' +
                  typeof object.account
              );
            }
            message.account = $root.accounts.v1.Account.fromObject(
              object.account
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.account = null;
          }
          let keys;
          if (message.account != null && message.hasOwnProperty('account')) {
            object.account = $root.accounts.v1.Account.toObject(
              message.account,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateAccountResponse';
        }
      }

      CreateAccountResponse.prototype.account = null;

      return CreateAccountResponse;
    })();

    v1.GetOrganizationRequest = (() => {
      class GetOrganizationRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrganizationRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrganizationRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.GetOrganizationRequest) {
            return object;
          }
          const message = new $root.accounts.v1.GetOrganizationRequest();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetOrganizationRequest';
        }
      }

      GetOrganizationRequest.prototype.organizationId = '';

      return GetOrganizationRequest;
    })();

    v1.GetOrganizationResponse = (() => {
      class GetOrganizationResponse {
        constructor(properties) {
          this.roles = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrganizationResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organization != null &&
            Object.hasOwnProperty.call(message, 'organization')
          ) {
            $root.accounts.v1.Organization.encode(
              message.organization,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.roles != null &&
            Object.hasOwnProperty.call(message, 'roles')
          ) {
            for (const element of message.roles) {
              $root.accounts.v1.Role.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrganizationResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organization = $root.accounts.v1.Organization.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (!message.roles || !message.roles.length) {
                  message.roles = [];
                }
                message.roles.push(
                  $root.accounts.v1.Role.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.GetOrganizationResponse) {
            return object;
          }
          const message = new $root.accounts.v1.GetOrganizationResponse();
          if (object.organization != null) {
            if (typeof object.organization !== 'object') {
              throw new TypeError(
                '.accounts.v1.GetOrganizationResponse.organization: object expected, but got ' +
                  typeof object.organization
              );
            }
            message.organization = $root.accounts.v1.Organization.fromObject(
              object.organization
            );
          }
          if (object.roles) {
            if (!Array.isArray(object.roles)) {
              throw new TypeError(
                '.accounts.v1.GetOrganizationResponse.roles: array type expected, but got ' +
                  typeof object.roles
              );
            }
            message.roles = new Array(object.roles.length);
            for (let i = 0; i < object.roles.length; ++i) {
              if (typeof object.roles[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.GetOrganizationResponse.roles: object expected, but got ' +
                    typeof object.roles[i]
                );
              }
              message.roles[i] = $root.accounts.v1.Role.fromObject(
                object.roles[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.roles = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organization = null;
          }
          let keys;
          if (
            message.organization != null &&
            message.hasOwnProperty('organization')
          ) {
            object.organization = $root.accounts.v1.Organization.toObject(
              message.organization,
              options
            );
          }
          if (message.roles && message.roles.length) {
            object.roles = new Array(message.roles.length);
            for (let i = 0; i < message.roles.length; ++i) {
              object.roles[i] = $root.accounts.v1.Role.toObject(
                message.roles[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetOrganizationResponse';
        }
      }

      GetOrganizationResponse.prototype.organization = null;
      GetOrganizationResponse.prototype.roles = $util.emptyArray;

      return GetOrganizationResponse;
    })();

    v1.CreateOrganizationRequest = (() => {
      class CreateOrganizationRequest {
        constructor(properties) {
          this.allowedMxDomains = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateOrganizationRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.allowedMxDomains != null &&
            Object.hasOwnProperty.call(message, 'allowedMxDomains')
          ) {
            for (const element of message.allowedMxDomains) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(32).int32(message.type);
          }
          if (
            message.accountId != null &&
            Object.hasOwnProperty.call(message, 'accountId')
          ) {
            writer.uint32(42).string(message.accountId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateOrganizationRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (
                  !message.allowedMxDomains ||
                  !message.allowedMxDomains.length
                ) {
                  message.allowedMxDomains = [];
                }
                message.allowedMxDomains.push(reader.string());
                break;
              }
              case 4: {
                message.type = reader.int32();
                break;
              }
              case 5: {
                message.accountId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateOrganizationRequest) {
            return object;
          }
          const message = new $root.accounts.v1.CreateOrganizationRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.allowedMxDomains) {
            if (!Array.isArray(object.allowedMxDomains)) {
              throw new TypeError(
                '.accounts.v1.CreateOrganizationRequest.allowedMxDomains: array type expected, but got ' +
                  typeof object.allowedMxDomains
              );
            }
            message.allowedMxDomains = new Array(
              object.allowedMxDomains.length
            );
            for (let i = 0; i < object.allowedMxDomains.length; ++i) {
              message.allowedMxDomains[i] = String(object.allowedMxDomains[i]);
            }
          }
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_ORGANIZATION_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'MORF_INTERNAL':
              case 1: {
                message.type = 1;
                break;
              }
              case 'CUSTOMER_SANDBOX':
              case 2: {
                message.type = 2;
                break;
              }
              case 'CUSTOMER_PRODUCTION':
              case 3: {
                message.type = 3;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          if (object.accountId != null) {
            message.accountId = String(object.accountId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.allowedMxDomains = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.type =
              options.enums === String ? 'UNSPECIFIED_ORGANIZATION_TYPE' : 0;
            object.accountId = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.allowedMxDomains && message.allowedMxDomains.length) {
            object.allowedMxDomains = new Array(
              message.allowedMxDomains.length
            );
            for (let i = 0; i < message.allowedMxDomains.length; ++i) {
              object.allowedMxDomains[i] = message.allowedMxDomains[i];
            }
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.accounts.v1.OrganizationType[message.type] === undefined
                  ? message.type
                  : $root.accounts.v1.OrganizationType[message.type]
                : message.type;
          }
          if (
            message.accountId != null &&
            message.hasOwnProperty('accountId')
          ) {
            object.accountId = message.accountId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateOrganizationRequest';
        }
      }

      CreateOrganizationRequest.prototype.name = '';
      CreateOrganizationRequest.prototype.description = null;
      CreateOrganizationRequest.prototype.allowedMxDomains = $util.emptyArray;
      CreateOrganizationRequest.prototype.type = 0;
      CreateOrganizationRequest.prototype.accountId = '';

      return CreateOrganizationRequest;
    })();

    v1.UpdateOrganizationRequest = (() => {
      class UpdateOrganizationRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateOrganizationRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.reRunBootstrap != null &&
            Object.hasOwnProperty.call(message, 'reRunBootstrap')
          ) {
            writer.uint32(16).bool(message.reRunBootstrap);
          }
          if (
            message.reRunSegmentSourceCreate != null &&
            Object.hasOwnProperty.call(message, 'reRunSegmentSourceCreate')
          ) {
            writer.uint32(24).bool(message.reRunSegmentSourceCreate);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(34).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(42).string(message.description);
          }
          if (
            message.updateAllowedMxDomains != null &&
            Object.hasOwnProperty.call(message, 'updateAllowedMxDomains')
          ) {
            $root.accounts.v1.UpdateAllowedMxDomains.encode(
              message.updateAllowedMxDomains,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(56).int32(message.type);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateOrganizationRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                message.reRunBootstrap = reader.bool();
                break;
              }
              case 3: {
                message.reRunSegmentSourceCreate = reader.bool();
                break;
              }
              case 4: {
                message.name = reader.string();
                break;
              }
              case 5: {
                message.description = reader.string();
                break;
              }
              case 6: {
                message.updateAllowedMxDomains =
                  $root.accounts.v1.UpdateAllowedMxDomains.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.type = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateOrganizationRequest) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateOrganizationRequest();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.reRunBootstrap != null) {
            message.reRunBootstrap = Boolean(object.reRunBootstrap);
          }
          if (object.reRunSegmentSourceCreate != null) {
            message.reRunSegmentSourceCreate = Boolean(
              object.reRunSegmentSourceCreate
            );
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.updateAllowedMxDomains != null) {
            if (typeof object.updateAllowedMxDomains !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateOrganizationRequest.updateAllowedMxDomains: object expected, but got ' +
                  typeof object.updateAllowedMxDomains
              );
            }
            message.updateAllowedMxDomains =
              $root.accounts.v1.UpdateAllowedMxDomains.fromObject(
                object.updateAllowedMxDomains
              );
          }
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_ORGANIZATION_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'MORF_INTERNAL':
              case 1: {
                message.type = 1;
                break;
              }
              case 'CUSTOMER_SANDBOX':
              case 2: {
                message.type = 2;
                break;
              }
              case 'CUSTOMER_PRODUCTION':
              case 3: {
                message.type = 3;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.reRunBootstrap = false;
            object.reRunSegmentSourceCreate = false;
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.reRunBootstrap != null &&
            message.hasOwnProperty('reRunBootstrap')
          ) {
            object.reRunBootstrap = message.reRunBootstrap;
          }
          if (
            message.reRunSegmentSourceCreate != null &&
            message.hasOwnProperty('reRunSegmentSourceCreate')
          ) {
            object.reRunSegmentSourceCreate = message.reRunSegmentSourceCreate;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.updateAllowedMxDomains != null &&
            message.hasOwnProperty('updateAllowedMxDomains')
          ) {
            object.updateAllowedMxDomains =
              $root.accounts.v1.UpdateAllowedMxDomains.toObject(
                message.updateAllowedMxDomains,
                options
              );
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.accounts.v1.OrganizationType[message.type] === undefined
                  ? message.type
                  : $root.accounts.v1.OrganizationType[message.type]
                : message.type;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateOrganizationRequest';
        }
      }

      UpdateOrganizationRequest.prototype.organizationId = '';
      UpdateOrganizationRequest.prototype.reRunBootstrap = false;
      UpdateOrganizationRequest.prototype.reRunSegmentSourceCreate = false;
      UpdateOrganizationRequest.prototype.name = null;
      UpdateOrganizationRequest.prototype.description = null;
      UpdateOrganizationRequest.prototype.updateAllowedMxDomains = null;
      UpdateOrganizationRequest.prototype.type = null;

      return UpdateOrganizationRequest;
    })();

    v1.UpdateAllowedMxDomains = (() => {
      class UpdateAllowedMxDomains {
        constructor(properties) {
          this.allowedMxDomains = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateAllowedMxDomains(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.allowedMxDomains != null &&
            Object.hasOwnProperty.call(message, 'allowedMxDomains')
          ) {
            for (const element of message.allowedMxDomains) {
              writer.uint32(10).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateAllowedMxDomains();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.allowedMxDomains ||
                  !message.allowedMxDomains.length
                ) {
                  message.allowedMxDomains = [];
                }
                message.allowedMxDomains.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateAllowedMxDomains) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateAllowedMxDomains();
          if (object.allowedMxDomains) {
            if (!Array.isArray(object.allowedMxDomains)) {
              throw new TypeError(
                '.accounts.v1.UpdateAllowedMxDomains.allowedMxDomains: array type expected, but got ' +
                  typeof object.allowedMxDomains
              );
            }
            message.allowedMxDomains = new Array(
              object.allowedMxDomains.length
            );
            for (let i = 0; i < object.allowedMxDomains.length; ++i) {
              message.allowedMxDomains[i] = String(object.allowedMxDomains[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.allowedMxDomains = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.allowedMxDomains && message.allowedMxDomains.length) {
            object.allowedMxDomains = new Array(
              message.allowedMxDomains.length
            );
            for (let i = 0; i < message.allowedMxDomains.length; ++i) {
              object.allowedMxDomains[i] = message.allowedMxDomains[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateAllowedMxDomains';
        }
      }

      UpdateAllowedMxDomains.prototype.allowedMxDomains = $util.emptyArray;

      return UpdateAllowedMxDomains;
    })();

    v1.UpdateOrganizationResponse = (() => {
      class UpdateOrganizationResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateOrganizationResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organization != null &&
            Object.hasOwnProperty.call(message, 'organization')
          ) {
            $root.accounts.v1.Organization.encode(
              message.organization,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateOrganizationResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organization = $root.accounts.v1.Organization.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateOrganizationResponse) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateOrganizationResponse();
          if (object.organization != null) {
            if (typeof object.organization !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateOrganizationResponse.organization: object expected, but got ' +
                  typeof object.organization
              );
            }
            message.organization = $root.accounts.v1.Organization.fromObject(
              object.organization
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organization = null;
          }
          let keys;
          if (
            message.organization != null &&
            message.hasOwnProperty('organization')
          ) {
            object.organization = $root.accounts.v1.Organization.toObject(
              message.organization,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateOrganizationResponse';
        }
      }

      UpdateOrganizationResponse.prototype.organization = null;

      return UpdateOrganizationResponse;
    })();

    v1.CreateOrganizationResponse = (() => {
      class CreateOrganizationResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateOrganizationResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organization != null &&
            Object.hasOwnProperty.call(message, 'organization')
          ) {
            $root.accounts.v1.Organization.encode(
              message.organization,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateOrganizationResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organization = $root.accounts.v1.Organization.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateOrganizationResponse) {
            return object;
          }
          const message = new $root.accounts.v1.CreateOrganizationResponse();
          if (object.organization != null) {
            if (typeof object.organization !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateOrganizationResponse.organization: object expected, but got ' +
                  typeof object.organization
              );
            }
            message.organization = $root.accounts.v1.Organization.fromObject(
              object.organization
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organization = null;
          }
          let keys;
          if (
            message.organization != null &&
            message.hasOwnProperty('organization')
          ) {
            object.organization = $root.accounts.v1.Organization.toObject(
              message.organization,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateOrganizationResponse';
        }
      }

      CreateOrganizationResponse.prototype.organization = null;

      return CreateOrganizationResponse;
    })();

    v1.ListOrganizationsRequest = (() => {
      class ListOrganizationsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListOrganizationsRequest) {
            return object;
          }
          const message = new $root.accounts.v1.ListOrganizationsRequest();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListOrganizationsRequest';
        }
      }

      return ListOrganizationsRequest;
    })();

    v1.ListOrganizationsResponse = (() => {
      class ListOrganizationsResponse {
        constructor(properties) {
          this.organizations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizations != null &&
            Object.hasOwnProperty.call(message, 'organizations')
          ) {
            for (const element of message.organizations) {
              $root.accounts.v1.Organization.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.organizations || !message.organizations.length) {
                  message.organizations = [];
                }
                message.organizations.push(
                  $root.accounts.v1.Organization.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListOrganizationsResponse) {
            return object;
          }
          const message = new $root.accounts.v1.ListOrganizationsResponse();
          if (object.organizations) {
            if (!Array.isArray(object.organizations)) {
              throw new TypeError(
                '.accounts.v1.ListOrganizationsResponse.organizations: array type expected, but got ' +
                  typeof object.organizations
              );
            }
            message.organizations = new Array(object.organizations.length);
            for (let i = 0; i < object.organizations.length; ++i) {
              if (typeof object.organizations[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.ListOrganizationsResponse.organizations: object expected, but got ' +
                    typeof object.organizations[i]
                );
              }
              message.organizations[i] =
                $root.accounts.v1.Organization.fromObject(
                  object.organizations[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.organizations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.organizations && message.organizations.length) {
            object.organizations = new Array(message.organizations.length);
            for (let i = 0; i < message.organizations.length; ++i) {
              object.organizations[i] = $root.accounts.v1.Organization.toObject(
                message.organizations[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListOrganizationsResponse';
        }
      }

      ListOrganizationsResponse.prototype.organizations = $util.emptyArray;

      return ListOrganizationsResponse;
    })();

    v1.GetOrganizationUsersRequest = (() => {
      class GetOrganizationUsersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrganizationUsersRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrganizationUsersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.GetOrganizationUsersRequest) {
            return object;
          }
          const message = new $root.accounts.v1.GetOrganizationUsersRequest();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetOrganizationUsersRequest';
        }
      }

      GetOrganizationUsersRequest.prototype.organizationId = '';

      return GetOrganizationUsersRequest;
    })();

    v1.GetOrganizationUsersResponse = (() => {
      class GetOrganizationUsersResponse {
        constructor(properties) {
          this.users = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrganizationUsersResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.users != null &&
            Object.hasOwnProperty.call(message, 'users')
          ) {
            for (const element of message.users) {
              $root.accounts.v1.User.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrganizationUsersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                if (!message.users || !message.users.length) {
                  message.users = [];
                }
                message.users.push(
                  $root.accounts.v1.User.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.accounts.v1.GetOrganizationUsersResponse
          ) {
            return object;
          }
          const message = new $root.accounts.v1.GetOrganizationUsersResponse();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.users) {
            if (!Array.isArray(object.users)) {
              throw new TypeError(
                '.accounts.v1.GetOrganizationUsersResponse.users: array type expected, but got ' +
                  typeof object.users
              );
            }
            message.users = new Array(object.users.length);
            for (let i = 0; i < object.users.length; ++i) {
              if (typeof object.users[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.GetOrganizationUsersResponse.users: object expected, but got ' +
                    typeof object.users[i]
                );
              }
              message.users[i] = $root.accounts.v1.User.fromObject(
                object.users[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.users = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.users && message.users.length) {
            object.users = new Array(message.users.length);
            for (let i = 0; i < message.users.length; ++i) {
              object.users[i] = $root.accounts.v1.User.toObject(
                message.users[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetOrganizationUsersResponse';
        }
      }

      GetOrganizationUsersResponse.prototype.organizationId = '';
      GetOrganizationUsersResponse.prototype.users = $util.emptyArray;

      return GetOrganizationUsersResponse;
    })();

    v1.OrganizationPermissions = (() => {
      class OrganizationPermissions {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrganizationPermissions(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organization != null &&
            Object.hasOwnProperty.call(message, 'organization')
          ) {
            $root.accounts.v1.Organization.encode(
              message.organization,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.role != null &&
            Object.hasOwnProperty.call(message, 'role')
          ) {
            $root.accounts.v1.Role.encode(
              message.role,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.permissionsBitset != null &&
            Object.hasOwnProperty.call(message, 'permissionsBitset')
          ) {
            writer.uint32(26).bytes(message.permissionsBitset);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrganizationPermissions();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organization = $root.accounts.v1.Organization.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.role = $root.accounts.v1.Role.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.permissionsBitset = reader.bytes();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.OrganizationPermissions) {
            return object;
          }
          const message = new $root.accounts.v1.OrganizationPermissions();
          if (object.organization != null) {
            if (typeof object.organization !== 'object') {
              throw new TypeError(
                '.accounts.v1.OrganizationPermissions.organization: object expected, but got ' +
                  typeof object.organization
              );
            }
            message.organization = $root.accounts.v1.Organization.fromObject(
              object.organization
            );
          }
          if (object.role != null) {
            if (typeof object.role !== 'object') {
              throw new TypeError(
                '.accounts.v1.OrganizationPermissions.role: object expected, but got ' +
                  typeof object.role
              );
            }
            message.role = $root.accounts.v1.Role.fromObject(object.role);
          }
          if (object.permissionsBitset != null) {
            if (typeof object.permissionsBitset === 'string') {
              $util.base64.decode(
                object.permissionsBitset,
                (message.permissionsBitset = $util.newBuffer(
                  $util.base64.length(object.permissionsBitset)
                )),
                0
              );
            } else if (object.permissionsBitset.length >= 0) {
              message.permissionsBitset = object.permissionsBitset;
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organization = null;
            object.role = null;
            if (options.bytes === String) {
              object.permissionsBitset = '';
            } else {
              object.permissionsBitset = [];
              if (options.bytes !== Array) {
                object.permissionsBitset = $util.newBuffer(
                  object.permissionsBitset
                );
              }
            }
          }
          let keys;
          if (
            message.organization != null &&
            message.hasOwnProperty('organization')
          ) {
            object.organization = $root.accounts.v1.Organization.toObject(
              message.organization,
              options
            );
          }
          if (message.role != null && message.hasOwnProperty('role')) {
            object.role = $root.accounts.v1.Role.toObject(
              message.role,
              options
            );
          }
          if (
            message.permissionsBitset != null &&
            message.hasOwnProperty('permissionsBitset')
          ) {
            object.permissionsBitset =
              options.bytes === String
                ? $util.base64.encode(
                    message.permissionsBitset,
                    0,
                    message.permissionsBitset.length
                  )
                : options.bytes === Array
                ? Array.prototype.slice.call(message.permissionsBitset)
                : message.permissionsBitset;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.OrganizationPermissions';
        }
      }

      OrganizationPermissions.prototype.organization = null;
      OrganizationPermissions.prototype.role = null;
      OrganizationPermissions.prototype.permissionsBitset = new Uint8Array();

      return OrganizationPermissions;
    })();

    v1.OrganizationRole = (() => {
      class OrganizationRole {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrganizationRole(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.roleId != null &&
            Object.hasOwnProperty.call(message, 'roleId')
          ) {
            writer.uint32(18).string(message.roleId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrganizationRole();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                message.roleId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.OrganizationRole) {
            return object;
          }
          const message = new $root.accounts.v1.OrganizationRole();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.roleId != null) {
            message.roleId = String(object.roleId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.roleId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.roleId != null && message.hasOwnProperty('roleId')) {
            object.roleId = message.roleId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.OrganizationRole';
        }
      }

      OrganizationRole.prototype.organizationId = '';
      OrganizationRole.prototype.roleId = '';

      return OrganizationRole;
    })();

    v1.GetUserRequest = (() => {
      class GetUserRequest {
        get byIdType() {
          for (const key of ['morfId', 'stytchId']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set byIdType(name) {
          for (const key of ['morfId', 'stytchId']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetUserRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.morfId != null &&
            Object.hasOwnProperty.call(message, 'morfId')
          ) {
            writer.uint32(10).string(message.morfId);
          }
          if (
            message.stytchId != null &&
            Object.hasOwnProperty.call(message, 'stytchId')
          ) {
            writer.uint32(18).string(message.stytchId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetUserRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.morfId = reader.string();
                break;
              }
              case 2: {
                message.stytchId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.GetUserRequest) {
            return object;
          }
          const message = new $root.accounts.v1.GetUserRequest();
          if (object.morfId != null) {
            message.morfId = String(object.morfId);
          }
          if (object.stytchId != null) {
            message.stytchId = String(object.stytchId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.morfId != null && message.hasOwnProperty('morfId')) {
            object.morfId = message.morfId;
            if (options.oneofs) {
              object.byIdType = 'morfId';
            }
          }
          if (message.stytchId != null && message.hasOwnProperty('stytchId')) {
            object.stytchId = message.stytchId;
            if (options.oneofs) {
              object.byIdType = 'stytchId';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetUserRequest';
        }
      }

      GetUserRequest.prototype.morfId = '';
      GetUserRequest.prototype.stytchId = '';

      return GetUserRequest;
    })();

    v1.GetUserResponse = (() => {
      class GetUserResponse {
        constructor(properties) {
          this.organizationPermissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetUserResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            $root.accounts.v1.User.encode(
              message.user,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.organizationPermissions != null &&
            Object.hasOwnProperty.call(message, 'organizationPermissions')
          ) {
            for (const element of message.organizationPermissions) {
              $root.accounts.v1.OrganizationPermissions.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.accountPermissionsBitset != null &&
            Object.hasOwnProperty.call(message, 'accountPermissionsBitset')
          ) {
            writer.uint32(26).bytes(message.accountPermissionsBitset);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetUserResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = $root.accounts.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (
                  !message.organizationPermissions ||
                  !message.organizationPermissions.length
                ) {
                  message.organizationPermissions = [];
                }
                message.organizationPermissions.push(
                  $root.accounts.v1.OrganizationPermissions.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                message.accountPermissionsBitset = reader.bytes();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.GetUserResponse) {
            return object;
          }
          const message = new $root.accounts.v1.GetUserResponse();
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.accounts.v1.GetUserResponse.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = $root.accounts.v1.User.fromObject(object.user);
          }
          if (object.organizationPermissions) {
            if (!Array.isArray(object.organizationPermissions)) {
              throw new TypeError(
                '.accounts.v1.GetUserResponse.organizationPermissions: array type expected, but got ' +
                  typeof object.organizationPermissions
              );
            }
            message.organizationPermissions = new Array(
              object.organizationPermissions.length
            );
            for (let i = 0; i < object.organizationPermissions.length; ++i) {
              if (typeof object.organizationPermissions[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.GetUserResponse.organizationPermissions: object expected, but got ' +
                    typeof object.organizationPermissions[i]
                );
              }
              message.organizationPermissions[i] =
                $root.accounts.v1.OrganizationPermissions.fromObject(
                  object.organizationPermissions[i]
                );
            }
          }
          if (object.accountPermissionsBitset != null) {
            if (typeof object.accountPermissionsBitset === 'string') {
              $util.base64.decode(
                object.accountPermissionsBitset,
                (message.accountPermissionsBitset = $util.newBuffer(
                  $util.base64.length(object.accountPermissionsBitset)
                )),
                0
              );
            } else if (object.accountPermissionsBitset.length >= 0) {
              message.accountPermissionsBitset =
                object.accountPermissionsBitset;
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.organizationPermissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = null;
            if (options.bytes === String) {
              object.accountPermissionsBitset = '';
            } else {
              object.accountPermissionsBitset = [];
              if (options.bytes !== Array) {
                object.accountPermissionsBitset = $util.newBuffer(
                  object.accountPermissionsBitset
                );
              }
            }
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = $root.accounts.v1.User.toObject(
              message.user,
              options
            );
          }
          if (
            message.organizationPermissions &&
            message.organizationPermissions.length
          ) {
            object.organizationPermissions = new Array(
              message.organizationPermissions.length
            );
            for (let i = 0; i < message.organizationPermissions.length; ++i) {
              object.organizationPermissions[i] =
                $root.accounts.v1.OrganizationPermissions.toObject(
                  message.organizationPermissions[i],
                  options
                );
            }
          }
          if (
            message.accountPermissionsBitset != null &&
            message.hasOwnProperty('accountPermissionsBitset')
          ) {
            object.accountPermissionsBitset =
              options.bytes === String
                ? $util.base64.encode(
                    message.accountPermissionsBitset,
                    0,
                    message.accountPermissionsBitset.length
                  )
                : options.bytes === Array
                ? Array.prototype.slice.call(message.accountPermissionsBitset)
                : message.accountPermissionsBitset;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.GetUserResponse';
        }
      }

      GetUserResponse.prototype.user = null;
      GetUserResponse.prototype.organizationPermissions = $util.emptyArray;
      GetUserResponse.prototype.accountPermissionsBitset = new Uint8Array();

      return GetUserResponse;
    })();

    v1.CreateUserRequest = (() => {
      class CreateUserRequest {
        constructor(properties) {
          this.organizationIds = [];
          this.accountPermissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateUserRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(10).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(18).string(message.lastName);
          }
          if (
            message.nickname != null &&
            Object.hasOwnProperty.call(message, 'nickname')
          ) {
            writer.uint32(26).string(message.nickname);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(34).string(message.email);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            $root.accounts.v1.UserType.encode(
              message.type,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.organizationIds != null &&
            Object.hasOwnProperty.call(message, 'organizationIds')
          ) {
            for (const element of message.organizationIds) {
              writer.uint32(50).string(element);
            }
          }
          if (
            message.accountPermissions != null &&
            Object.hasOwnProperty.call(message, 'accountPermissions')
          ) {
            for (const element of message.accountPermissions) {
              permissions$1.v1.Permission.encode(
                element,
                writer.uint32(58).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateUserRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.firstName = reader.string();
                break;
              }
              case 2: {
                message.lastName = reader.string();
                break;
              }
              case 3: {
                message.nickname = reader.string();
                break;
              }
              case 4: {
                message.email = reader.string();
                break;
              }
              case 5: {
                message.type = $root.accounts.v1.UserType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                if (
                  !message.organizationIds ||
                  !message.organizationIds.length
                ) {
                  message.organizationIds = [];
                }
                message.organizationIds.push(reader.string());
                break;
              }
              case 7: {
                if (
                  !message.accountPermissions ||
                  !message.accountPermissions.length
                ) {
                  message.accountPermissions = [];
                }
                message.accountPermissions.push(
                  permissions$1.v1.Permission.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateUserRequest) {
            return object;
          }
          const message = new $root.accounts.v1.CreateUserRequest();
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.nickname != null) {
            message.nickname = String(object.nickname);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateUserRequest.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = $root.accounts.v1.UserType.fromObject(object.type);
          }
          if (object.organizationIds) {
            if (!Array.isArray(object.organizationIds)) {
              throw new TypeError(
                '.accounts.v1.CreateUserRequest.organizationIds: array type expected, but got ' +
                  typeof object.organizationIds
              );
            }
            message.organizationIds = new Array(object.organizationIds.length);
            for (let i = 0; i < object.organizationIds.length; ++i) {
              message.organizationIds[i] = String(object.organizationIds[i]);
            }
          }
          if (object.accountPermissions) {
            if (!Array.isArray(object.accountPermissions)) {
              throw new TypeError(
                '.accounts.v1.CreateUserRequest.accountPermissions: array type expected, but got ' +
                  typeof object.accountPermissions
              );
            }
            message.accountPermissions = new Array(
              object.accountPermissions.length
            );
            for (let i = 0; i < object.accountPermissions.length; ++i) {
              if (typeof object.accountPermissions[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.CreateUserRequest.accountPermissions: object expected, but got ' +
                    typeof object.accountPermissions[i]
                );
              }
              message.accountPermissions[i] =
                permissions$1.v1.Permission.fromObject(
                  object.accountPermissions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.organizationIds = [];
            object.accountPermissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.firstName = '';
            object.lastName = '';
            object.email = '';
            object.type = null;
          }
          let keys;
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.nickname != null && message.hasOwnProperty('nickname')) {
            object.nickname = message.nickname;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = $root.accounts.v1.UserType.toObject(
              message.type,
              options
            );
          }
          if (message.organizationIds && message.organizationIds.length) {
            object.organizationIds = new Array(message.organizationIds.length);
            for (let i = 0; i < message.organizationIds.length; ++i) {
              object.organizationIds[i] = message.organizationIds[i];
            }
          }
          if (message.accountPermissions && message.accountPermissions.length) {
            object.accountPermissions = new Array(
              message.accountPermissions.length
            );
            for (let i = 0; i < message.accountPermissions.length; ++i) {
              object.accountPermissions[i] =
                permissions$1.v1.Permission.toObject(
                  message.accountPermissions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateUserRequest';
        }
      }

      CreateUserRequest.prototype.firstName = '';
      CreateUserRequest.prototype.lastName = '';
      CreateUserRequest.prototype.nickname = null;
      CreateUserRequest.prototype.email = '';
      CreateUserRequest.prototype.type = null;
      CreateUserRequest.prototype.organizationIds = $util.emptyArray;
      CreateUserRequest.prototype.accountPermissions = $util.emptyArray;

      return CreateUserRequest;
    })();

    v1.CreateUserResponse = (() => {
      class CreateUserResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateUserResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            $root.accounts.v1.User.encode(
              message.user,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateUserResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = $root.accounts.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateUserResponse) {
            return object;
          }
          const message = new $root.accounts.v1.CreateUserResponse();
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateUserResponse.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = $root.accounts.v1.User.fromObject(object.user);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = null;
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = $root.accounts.v1.User.toObject(
              message.user,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateUserResponse';
        }
      }

      CreateUserResponse.prototype.user = null;

      return CreateUserResponse;
    })();

    v1.DeleteUserRequest = (() => {
      class DeleteUserRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteUserRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteUserRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.DeleteUserRequest) {
            return object;
          }
          const message = new $root.accounts.v1.DeleteUserRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.DeleteUserRequest';
        }
      }

      DeleteUserRequest.prototype.id = '';

      return DeleteUserRequest;
    })();

    v1.DeleteUserResponse = (() => {
      class DeleteUserResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteUserResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            $root.accounts.v1.User.encode(
              message.user,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteUserResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = $root.accounts.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.DeleteUserResponse) {
            return object;
          }
          const message = new $root.accounts.v1.DeleteUserResponse();
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.accounts.v1.DeleteUserResponse.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = $root.accounts.v1.User.fromObject(object.user);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = null;
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = $root.accounts.v1.User.toObject(
              message.user,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.DeleteUserResponse';
        }
      }

      DeleteUserResponse.prototype.user = null;

      return DeleteUserResponse;
    })();

    v1.UpdateUserRequest = (() => {
      class UpdateUserRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateUserRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.updateOrganizations != null &&
            Object.hasOwnProperty.call(message, 'updateOrganizations')
          ) {
            $root.accounts.v1.UpdateUserOrganizations.encode(
              message.updateOrganizations,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateUserRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.updateOrganizations =
                  $root.accounts.v1.UpdateUserOrganizations.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateUserRequest) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateUserRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.updateOrganizations != null) {
            if (typeof object.updateOrganizations !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateUserRequest.updateOrganizations: object expected, but got ' +
                  typeof object.updateOrganizations
              );
            }
            message.updateOrganizations =
              $root.accounts.v1.UpdateUserOrganizations.fromObject(
                object.updateOrganizations
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.updateOrganizations != null &&
            message.hasOwnProperty('updateOrganizations')
          ) {
            object.updateOrganizations =
              $root.accounts.v1.UpdateUserOrganizations.toObject(
                message.updateOrganizations,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateUserRequest';
        }
      }

      UpdateUserRequest.prototype.id = '';
      UpdateUserRequest.prototype.updateOrganizations = null;

      return UpdateUserRequest;
    })();

    v1.UpdateUserOrganizations = (() => {
      class UpdateUserOrganizations {
        constructor(properties) {
          this.updateOrganizations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateUserOrganizations(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.updateOrganizations != null &&
            Object.hasOwnProperty.call(message, 'updateOrganizations')
          ) {
            for (const element of message.updateOrganizations) {
              $root.accounts.v1.UpdateUserOrganization.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateUserOrganizations();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.updateOrganizations ||
                  !message.updateOrganizations.length
                ) {
                  message.updateOrganizations = [];
                }
                message.updateOrganizations.push(
                  $root.accounts.v1.UpdateUserOrganization.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateUserOrganizations) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateUserOrganizations();
          if (object.updateOrganizations) {
            if (!Array.isArray(object.updateOrganizations)) {
              throw new TypeError(
                '.accounts.v1.UpdateUserOrganizations.updateOrganizations: array type expected, but got ' +
                  typeof object.updateOrganizations
              );
            }
            message.updateOrganizations = new Array(
              object.updateOrganizations.length
            );
            for (let i = 0; i < object.updateOrganizations.length; ++i) {
              if (typeof object.updateOrganizations[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.UpdateUserOrganizations.updateOrganizations: object expected, but got ' +
                    typeof object.updateOrganizations[i]
                );
              }
              message.updateOrganizations[i] =
                $root.accounts.v1.UpdateUserOrganization.fromObject(
                  object.updateOrganizations[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.updateOrganizations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.updateOrganizations &&
            message.updateOrganizations.length
          ) {
            object.updateOrganizations = new Array(
              message.updateOrganizations.length
            );
            for (let i = 0; i < message.updateOrganizations.length; ++i) {
              object.updateOrganizations[i] =
                $root.accounts.v1.UpdateUserOrganization.toObject(
                  message.updateOrganizations[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateUserOrganizations';
        }
      }

      UpdateUserOrganizations.prototype.updateOrganizations = $util.emptyArray;

      return UpdateUserOrganizations;
    })();

    v1.UpdateUserOrganization = (() => {
      class UpdateUserOrganization {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateUserOrganization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.roleId != null &&
            Object.hasOwnProperty.call(message, 'roleId')
          ) {
            writer.uint32(18).string(message.roleId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateUserOrganization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                message.roleId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateUserOrganization) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateUserOrganization();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.roleId != null) {
            message.roleId = String(object.roleId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.roleId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.roleId != null && message.hasOwnProperty('roleId')) {
            object.roleId = message.roleId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateUserOrganization';
        }
      }

      UpdateUserOrganization.prototype.organizationId = '';
      UpdateUserOrganization.prototype.roleId = '';

      return UpdateUserOrganization;
    })();

    v1.UpdateUserResponse = (() => {
      class UpdateUserResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateUserResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            $root.accounts.v1.User.encode(
              message.user,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateUserResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = $root.accounts.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateUserResponse) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateUserResponse();
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateUserResponse.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = $root.accounts.v1.User.fromObject(object.user);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = null;
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = $root.accounts.v1.User.toObject(
              message.user,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateUserResponse';
        }
      }

      UpdateUserResponse.prototype.user = null;

      return UpdateUserResponse;
    })();

    v1.CreateRoleRequest = (() => {
      class CreateRoleRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRoleRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.permissions != null &&
            Object.hasOwnProperty.call(message, 'permissions')
          ) {
            permissions$1.v1.PermissionSet.encode(
              message.permissions,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(34).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRoleRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.permissions = permissions$1.v1.PermissionSet.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateRoleRequest) {
            return object;
          }
          const message = new $root.accounts.v1.CreateRoleRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.permissions != null) {
            if (typeof object.permissions !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateRoleRequest.permissions: object expected, but got ' +
                  typeof object.permissions
              );
            }
            message.permissions = permissions$1.v1.PermissionSet.fromObject(
              object.permissions
            );
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.permissions = null;
            object.organizationId = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.permissions != null &&
            message.hasOwnProperty('permissions')
          ) {
            object.permissions = permissions$1.v1.PermissionSet.toObject(
              message.permissions,
              options
            );
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateRoleRequest';
        }
      }

      CreateRoleRequest.prototype.name = '';
      CreateRoleRequest.prototype.description = null;
      CreateRoleRequest.prototype.permissions = null;
      CreateRoleRequest.prototype.organizationId = '';

      return CreateRoleRequest;
    })();

    v1.CreateRoleResponse = (() => {
      class CreateRoleResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRoleResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.role != null &&
            Object.hasOwnProperty.call(message, 'role')
          ) {
            $root.accounts.v1.Role.encode(
              message.role,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRoleResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.role = $root.accounts.v1.Role.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.CreateRoleResponse) {
            return object;
          }
          const message = new $root.accounts.v1.CreateRoleResponse();
          if (object.role != null) {
            if (typeof object.role !== 'object') {
              throw new TypeError(
                '.accounts.v1.CreateRoleResponse.role: object expected, but got ' +
                  typeof object.role
              );
            }
            message.role = $root.accounts.v1.Role.fromObject(object.role);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.role = null;
          }
          let keys;
          if (message.role != null && message.hasOwnProperty('role')) {
            object.role = $root.accounts.v1.Role.toObject(
              message.role,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.CreateRoleResponse';
        }
      }

      CreateRoleResponse.prototype.role = null;

      return CreateRoleResponse;
    })();

    v1.UpdateRoleRequest = (() => {
      class UpdateRoleRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRoleRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(18).string(message.organizationId);
          }
          if (
            message.newName != null &&
            Object.hasOwnProperty.call(message, 'newName')
          ) {
            writer.uint32(26).string(message.newName);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(34).string(message.description);
          }
          if (
            message.permissions != null &&
            Object.hasOwnProperty.call(message, 'permissions')
          ) {
            permissions$1.v1.PermissionSet.encode(
              message.permissions,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRoleRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.organizationId = reader.string();
                break;
              }
              case 3: {
                message.newName = reader.string();
                break;
              }
              case 4: {
                message.description = reader.string();
                break;
              }
              case 5: {
                message.permissions = permissions$1.v1.PermissionSet.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateRoleRequest) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateRoleRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.newName != null) {
            message.newName = String(object.newName);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.permissions != null) {
            if (typeof object.permissions !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateRoleRequest.permissions: object expected, but got ' +
                  typeof object.permissions
              );
            }
            message.permissions = permissions$1.v1.PermissionSet.fromObject(
              object.permissions
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.organizationId = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.newName != null && message.hasOwnProperty('newName')) {
            object.newName = message.newName;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.permissions != null &&
            message.hasOwnProperty('permissions')
          ) {
            object.permissions = permissions$1.v1.PermissionSet.toObject(
              message.permissions,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateRoleRequest';
        }
      }

      UpdateRoleRequest.prototype.name = '';
      UpdateRoleRequest.prototype.organizationId = '';
      UpdateRoleRequest.prototype.newName = null;
      UpdateRoleRequest.prototype.description = null;
      UpdateRoleRequest.prototype.permissions = null;

      return UpdateRoleRequest;
    })();

    v1.UpdateRoleResponse = (() => {
      class UpdateRoleResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRoleResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.role != null &&
            Object.hasOwnProperty.call(message, 'role')
          ) {
            $root.accounts.v1.Role.encode(
              message.role,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRoleResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.role = $root.accounts.v1.Role.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UpdateRoleResponse) {
            return object;
          }
          const message = new $root.accounts.v1.UpdateRoleResponse();
          if (object.role != null) {
            if (typeof object.role !== 'object') {
              throw new TypeError(
                '.accounts.v1.UpdateRoleResponse.role: object expected, but got ' +
                  typeof object.role
              );
            }
            message.role = $root.accounts.v1.Role.fromObject(object.role);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.role = null;
          }
          let keys;
          if (message.role != null && message.hasOwnProperty('role')) {
            object.role = $root.accounts.v1.Role.toObject(
              message.role,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UpdateRoleResponse';
        }
      }

      UpdateRoleResponse.prototype.role = null;

      return UpdateRoleResponse;
    })();

    v1.ListRolesRequest = (() => {
      class ListRolesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListRolesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListRolesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListRolesRequest) {
            return object;
          }
          const message = new $root.accounts.v1.ListRolesRequest();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListRolesRequest';
        }
      }

      ListRolesRequest.prototype.organizationId = '';

      return ListRolesRequest;
    })();

    v1.ListRolesResponse = (() => {
      class ListRolesResponse {
        constructor(properties) {
          this.roles = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListRolesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.roles != null &&
            Object.hasOwnProperty.call(message, 'roles')
          ) {
            for (const element of message.roles) {
              $root.accounts.v1.Role.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListRolesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.roles || !message.roles.length) {
                  message.roles = [];
                }
                message.roles.push(
                  $root.accounts.v1.Role.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListRolesResponse) {
            return object;
          }
          const message = new $root.accounts.v1.ListRolesResponse();
          if (object.roles) {
            if (!Array.isArray(object.roles)) {
              throw new TypeError(
                '.accounts.v1.ListRolesResponse.roles: array type expected, but got ' +
                  typeof object.roles
              );
            }
            message.roles = new Array(object.roles.length);
            for (let i = 0; i < object.roles.length; ++i) {
              if (typeof object.roles[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.ListRolesResponse.roles: object expected, but got ' +
                    typeof object.roles[i]
                );
              }
              message.roles[i] = $root.accounts.v1.Role.fromObject(
                object.roles[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.roles = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.roles && message.roles.length) {
            object.roles = new Array(message.roles.length);
            for (let i = 0; i < message.roles.length; ++i) {
              object.roles[i] = $root.accounts.v1.Role.toObject(
                message.roles[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListRolesResponse';
        }
      }

      ListRolesResponse.prototype.roles = $util.emptyArray;

      return ListRolesResponse;
    })();

    v1.Account = (() => {
      class Account {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Account(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.note != null &&
            Object.hasOwnProperty.call(message, 'note')
          ) {
            writer.uint32(18).string(message.note);
          }
          if (
            message.address != null &&
            Object.hasOwnProperty.call(message, 'address')
          ) {
            address$1.v1.Address.encode(
              message.address,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Account();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.note = reader.string();
                break;
              }
              case 3: {
                message.address = address$1.v1.Address.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.Account) {
            return object;
          }
          const message = new $root.accounts.v1.Account();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.note != null) {
            message.note = String(object.note);
          }
          if (object.address != null) {
            if (typeof object.address !== 'object') {
              throw new TypeError(
                '.accounts.v1.Account.address: object expected, but got ' +
                  typeof object.address
              );
            }
            message.address = address$1.v1.Address.fromObject(object.address);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.accounts.v1.Account.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.address = null;
            object.timing = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.note != null && message.hasOwnProperty('note')) {
            object.note = message.note;
          }
          if (message.address != null && message.hasOwnProperty('address')) {
            object.address = address$1.v1.Address.toObject(
              message.address,
              options
            );
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.Account';
        }
      }

      Account.prototype.id = '';
      Account.prototype.note = null;
      Account.prototype.address = null;
      Account.prototype.timing = null;

      return Account;
    })();

    v1.Organization = (() => {
      class Organization {
        constructor(properties) {
          this.allowedMxDomains = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Organization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.allowedMxDomains != null &&
            Object.hasOwnProperty.call(message, 'allowedMxDomains')
          ) {
            for (const element of message.allowedMxDomains) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(40).int32(message.type);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.isActive != null &&
            Object.hasOwnProperty.call(message, 'isActive')
          ) {
            writer.uint32(56).bool(message.isActive);
          }
          if (
            message.accountId != null &&
            Object.hasOwnProperty.call(message, 'accountId')
          ) {
            writer.uint32(66).string(message.accountId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Organization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                if (
                  !message.allowedMxDomains ||
                  !message.allowedMxDomains.length
                ) {
                  message.allowedMxDomains = [];
                }
                message.allowedMxDomains.push(reader.string());
                break;
              }
              case 5: {
                message.type = reader.int32();
                break;
              }
              case 6: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.isActive = reader.bool();
                break;
              }
              case 8: {
                message.accountId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.Organization) {
            return object;
          }
          const message = new $root.accounts.v1.Organization();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.allowedMxDomains) {
            if (!Array.isArray(object.allowedMxDomains)) {
              throw new TypeError(
                '.accounts.v1.Organization.allowedMxDomains: array type expected, but got ' +
                  typeof object.allowedMxDomains
              );
            }
            message.allowedMxDomains = new Array(
              object.allowedMxDomains.length
            );
            for (let i = 0; i < object.allowedMxDomains.length; ++i) {
              message.allowedMxDomains[i] = String(object.allowedMxDomains[i]);
            }
          }
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_ORGANIZATION_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'MORF_INTERNAL':
              case 1: {
                message.type = 1;
                break;
              }
              case 'CUSTOMER_SANDBOX':
              case 2: {
                message.type = 2;
                break;
              }
              case 'CUSTOMER_PRODUCTION':
              case 3: {
                message.type = 3;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.accounts.v1.Organization.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.isActive != null) {
            message.isActive = Boolean(object.isActive);
          }
          if (object.accountId != null) {
            message.accountId = String(object.accountId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.allowedMxDomains = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.type =
              options.enums === String ? 'UNSPECIFIED_ORGANIZATION_TYPE' : 0;
            object.timing = null;
            object.isActive = false;
            object.accountId = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.allowedMxDomains && message.allowedMxDomains.length) {
            object.allowedMxDomains = new Array(
              message.allowedMxDomains.length
            );
            for (let i = 0; i < message.allowedMxDomains.length; ++i) {
              object.allowedMxDomains[i] = message.allowedMxDomains[i];
            }
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.accounts.v1.OrganizationType[message.type] === undefined
                  ? message.type
                  : $root.accounts.v1.OrganizationType[message.type]
                : message.type;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.isActive != null && message.hasOwnProperty('isActive')) {
            object.isActive = message.isActive;
          }
          if (
            message.accountId != null &&
            message.hasOwnProperty('accountId')
          ) {
            object.accountId = message.accountId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.Organization';
        }
      }

      Organization.prototype.id = '';
      Organization.prototype.name = '';
      Organization.prototype.description = null;
      Organization.prototype.allowedMxDomains = $util.emptyArray;
      Organization.prototype.type = 0;
      Organization.prototype.timing = null;
      Organization.prototype.isActive = false;
      Organization.prototype.accountId = '';

      return Organization;
    })();

    v1.User = (() => {
      class User {
        constructor(properties) {
          this.userOrganizations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new User(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.stytchId != null &&
            Object.hasOwnProperty.call(message, 'stytchId')
          ) {
            writer.uint32(18).string(message.stytchId);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(26).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(34).string(message.lastName);
          }
          if (
            message.nickname != null &&
            Object.hasOwnProperty.call(message, 'nickname')
          ) {
            writer.uint32(42).string(message.nickname);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(50).string(message.email);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            $root.accounts.v1.UserType.encode(
              message.type,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.isActive != null &&
            Object.hasOwnProperty.call(message, 'isActive')
          ) {
            writer.uint32(72).bool(message.isActive);
          }
          if (
            message.accountPermissions != null &&
            Object.hasOwnProperty.call(message, 'accountPermissions')
          ) {
            permissions$1.v1.PermissionSet.encode(
              message.accountPermissions,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.userOrganizations != null &&
            Object.hasOwnProperty.call(message, 'userOrganizations')
          ) {
            for (const element of message.userOrganizations) {
              $root.accounts.v1.UserOrganization.encode(
                element,
                writer.uint32(90).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new User();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.stytchId = reader.string();
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.lastName = reader.string();
                break;
              }
              case 5: {
                message.nickname = reader.string();
                break;
              }
              case 6: {
                message.email = reader.string();
                break;
              }
              case 7: {
                message.type = $root.accounts.v1.UserType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.isActive = reader.bool();
                break;
              }
              case 10: {
                message.accountPermissions =
                  permissions$1.v1.PermissionSet.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                if (
                  !message.userOrganizations ||
                  !message.userOrganizations.length
                ) {
                  message.userOrganizations = [];
                }
                message.userOrganizations.push(
                  $root.accounts.v1.UserOrganization.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.User) {
            return object;
          }
          const message = new $root.accounts.v1.User();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.stytchId != null) {
            message.stytchId = String(object.stytchId);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.nickname != null) {
            message.nickname = String(object.nickname);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.accounts.v1.User.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = $root.accounts.v1.UserType.fromObject(object.type);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.accounts.v1.User.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.isActive != null) {
            message.isActive = Boolean(object.isActive);
          }
          if (object.accountPermissions != null) {
            if (typeof object.accountPermissions !== 'object') {
              throw new TypeError(
                '.accounts.v1.User.accountPermissions: object expected, but got ' +
                  typeof object.accountPermissions
              );
            }
            message.accountPermissions =
              permissions$1.v1.PermissionSet.fromObject(
                object.accountPermissions
              );
          }
          if (object.userOrganizations) {
            if (!Array.isArray(object.userOrganizations)) {
              throw new TypeError(
                '.accounts.v1.User.userOrganizations: array type expected, but got ' +
                  typeof object.userOrganizations
              );
            }
            message.userOrganizations = new Array(
              object.userOrganizations.length
            );
            for (let i = 0; i < object.userOrganizations.length; ++i) {
              if (typeof object.userOrganizations[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.User.userOrganizations: object expected, but got ' +
                    typeof object.userOrganizations[i]
                );
              }
              message.userOrganizations[i] =
                $root.accounts.v1.UserOrganization.fromObject(
                  object.userOrganizations[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.userOrganizations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.stytchId = '';
            object.firstName = '';
            object.lastName = '';
            object.email = '';
            object.type = null;
            object.timing = null;
            object.isActive = false;
            object.accountPermissions = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.stytchId != null && message.hasOwnProperty('stytchId')) {
            object.stytchId = message.stytchId;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.nickname != null && message.hasOwnProperty('nickname')) {
            object.nickname = message.nickname;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = $root.accounts.v1.UserType.toObject(
              message.type,
              options
            );
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.isActive != null && message.hasOwnProperty('isActive')) {
            object.isActive = message.isActive;
          }
          if (
            message.accountPermissions != null &&
            message.hasOwnProperty('accountPermissions')
          ) {
            object.accountPermissions = permissions$1.v1.PermissionSet.toObject(
              message.accountPermissions,
              options
            );
          }
          if (message.userOrganizations && message.userOrganizations.length) {
            object.userOrganizations = new Array(
              message.userOrganizations.length
            );
            for (let i = 0; i < message.userOrganizations.length; ++i) {
              object.userOrganizations[i] =
                $root.accounts.v1.UserOrganization.toObject(
                  message.userOrganizations[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.User';
        }
      }

      User.prototype.id = '';
      User.prototype.stytchId = '';
      User.prototype.firstName = '';
      User.prototype.lastName = '';
      User.prototype.nickname = null;
      User.prototype.email = '';
      User.prototype.type = null;
      User.prototype.timing = null;
      User.prototype.isActive = false;
      User.prototype.accountPermissions = null;
      User.prototype.userOrganizations = $util.emptyArray;

      return User;
    })();

    v1.UserOrganization = (() => {
      class UserOrganization {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UserOrganization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.roleId != null &&
            Object.hasOwnProperty.call(message, 'roleId')
          ) {
            writer.uint32(18).string(message.roleId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UserOrganization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.roleId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UserOrganization) {
            return object;
          }
          const message = new $root.accounts.v1.UserOrganization();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.roleId != null) {
            message.roleId = String(object.roleId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.roleId = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.roleId != null && message.hasOwnProperty('roleId')) {
            object.roleId = message.roleId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UserOrganization';
        }
      }

      UserOrganization.prototype.id = '';
      UserOrganization.prototype.roleId = '';

      return UserOrganization;
    })();

    v1.UserType = (() => {
      class UserType {
        get type() {
          for (const key of ['admin', 'provider', 'morf']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ['admin', 'provider', 'morf']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UserType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.admin != null &&
            Object.hasOwnProperty.call(message, 'admin')
          ) {
            writer.uint32(8).int32(message.admin);
          }
          if (
            message.provider != null &&
            Object.hasOwnProperty.call(message, 'provider')
          ) {
            $root.accounts.v1.Provider.encode(
              message.provider,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.morf != null &&
            Object.hasOwnProperty.call(message, 'morf')
          ) {
            $root.accounts.v1.MorfInternalUser.encode(
              message.morf,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UserType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.admin = reader.int32();
                break;
              }
              case 2: {
                message.provider = $root.accounts.v1.Provider.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.morf = $root.accounts.v1.MorfInternalUser.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.UserType) {
            return object;
          }
          const message = new $root.accounts.v1.UserType();
          if (object.admin != null) {
            switch (object.admin) {
              case 'UNSPECIFIED_ADMIN_TYPE':
              case 0: {
                message.admin = 0;
                break;
              }
              case 'ACCOUNT_MANAGER':
              case 1: {
                message.admin = 1;
                break;
              }
              case 'BILLING_CONTACT':
              case 2: {
                message.admin = 2;
                break;
              }
              case 'ORG_CONTACT':
              case 4: {
                message.admin = 4;
                break;
              }
              case 'PATIENT_ADMIN':
              case 5: {
                message.admin = 5;
                break;
              }
              case 'CONCIERGE':
              case 6: {
                message.admin = 6;
                break;
              }
              default: {
                if (typeof object.admin == 'number') {
                  message.admin = object.admin;
                  break;
                }
                break;
              }
            }
          }
          if (object.provider != null) {
            if (typeof object.provider !== 'object') {
              throw new TypeError(
                '.accounts.v1.UserType.provider: object expected, but got ' +
                  typeof object.provider
              );
            }
            message.provider = $root.accounts.v1.Provider.fromObject(
              object.provider
            );
          }
          if (object.morf != null) {
            if (typeof object.morf !== 'object') {
              throw new TypeError(
                '.accounts.v1.UserType.morf: object expected, but got ' +
                  typeof object.morf
              );
            }
            message.morf = $root.accounts.v1.MorfInternalUser.fromObject(
              object.morf
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.admin != null && message.hasOwnProperty('admin')) {
            object.admin =
              options.enums === String
                ? $root.accounts.v1.AdminType[message.admin] === undefined
                  ? message.admin
                  : $root.accounts.v1.AdminType[message.admin]
                : message.admin;
            if (options.oneofs) {
              object.type = 'admin';
            }
          }
          if (message.provider != null && message.hasOwnProperty('provider')) {
            object.provider = $root.accounts.v1.Provider.toObject(
              message.provider,
              options
            );
            if (options.oneofs) {
              object.type = 'provider';
            }
          }
          if (message.morf != null && message.hasOwnProperty('morf')) {
            object.morf = $root.accounts.v1.MorfInternalUser.toObject(
              message.morf,
              options
            );
            if (options.oneofs) {
              object.type = 'morf';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.UserType';
        }
      }

      UserType.prototype.admin = 0;
      UserType.prototype.provider = null;
      UserType.prototype.morf = null;

      return UserType;
    })();

    v1.Provider = (() => {
      class Provider {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Provider(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(8).int32(message.type);
          }
          if (
            message.longDescription != null &&
            Object.hasOwnProperty.call(message, 'longDescription')
          ) {
            writer.uint32(18).string(message.longDescription);
          }
          if (
            message.shortDescription != null &&
            Object.hasOwnProperty.call(message, 'shortDescription')
          ) {
            writer.uint32(26).string(message.shortDescription);
          }
          if (
            message.imageUrl != null &&
            Object.hasOwnProperty.call(message, 'imageUrl')
          ) {
            writer.uint32(34).string(message.imageUrl);
          }
          if (
            message.title != null &&
            Object.hasOwnProperty.call(message, 'title')
          ) {
            writer.uint32(42).string(message.title);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Provider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.int32();
                break;
              }
              case 2: {
                message.longDescription = reader.string();
                break;
              }
              case 3: {
                message.shortDescription = reader.string();
                break;
              }
              case 4: {
                message.imageUrl = reader.string();
                break;
              }
              case 5: {
                message.title = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.Provider) {
            return object;
          }
          const message = new $root.accounts.v1.Provider();
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_PROVIDER_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'COACH':
              case 1: {
                message.type = 1;
                break;
              }
              case 'REGISTERED_DIETICIAN':
              case 2: {
                message.type = 2;
                break;
              }
              case 'NURSE':
              case 3: {
                message.type = 3;
                break;
              }
              case 'NURSE_PRACTITIONER':
              case 4: {
                message.type = 4;
                break;
              }
              case 'DO':
              case 5: {
                message.type = 5;
                break;
              }
              case 'MD':
              case 6: {
                message.type = 6;
                break;
              }
              case 'CONSULTANT':
              case 7: {
                message.type = 7;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          if (object.longDescription != null) {
            message.longDescription = String(object.longDescription);
          }
          if (object.shortDescription != null) {
            message.shortDescription = String(object.shortDescription);
          }
          if (object.imageUrl != null) {
            message.imageUrl = String(object.imageUrl);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type =
              options.enums === String ? 'UNSPECIFIED_PROVIDER_TYPE' : 0;
            object.longDescription = '';
            object.shortDescription = '';
            object.imageUrl = '';
            object.title = '';
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.accounts.v1.ProviderType[message.type] === undefined
                  ? message.type
                  : $root.accounts.v1.ProviderType[message.type]
                : message.type;
          }
          if (
            message.longDescription != null &&
            message.hasOwnProperty('longDescription')
          ) {
            object.longDescription = message.longDescription;
          }
          if (
            message.shortDescription != null &&
            message.hasOwnProperty('shortDescription')
          ) {
            object.shortDescription = message.shortDescription;
          }
          if (message.imageUrl != null && message.hasOwnProperty('imageUrl')) {
            object.imageUrl = message.imageUrl;
          }
          if (message.title != null && message.hasOwnProperty('title')) {
            object.title = message.title;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.Provider';
        }
      }

      Provider.prototype.type = 0;
      Provider.prototype.longDescription = '';
      Provider.prototype.shortDescription = '';
      Provider.prototype.imageUrl = '';
      Provider.prototype.title = '';

      return Provider;
    })();

    v1.MorfInternalUser = (() => {
      class MorfInternalUser {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfInternalUser(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfInternalUser();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.MorfInternalUser) {
            return object;
          }
          const message = new $root.accounts.v1.MorfInternalUser();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.MorfInternalUser';
        }
      }

      return MorfInternalUser;
    })();

    v1.Role = (() => {
      class Role {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Role(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.permissions != null &&
            Object.hasOwnProperty.call(message, 'permissions')
          ) {
            permissions$1.v1.PermissionSet.encode(
              message.permissions,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.isActive != null &&
            Object.hasOwnProperty.call(message, 'isActive')
          ) {
            writer.uint32(48).bool(message.isActive);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Role();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.permissions = permissions$1.v1.PermissionSet.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.isActive = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.Role) {
            return object;
          }
          const message = new $root.accounts.v1.Role();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.permissions != null) {
            if (typeof object.permissions !== 'object') {
              throw new TypeError(
                '.accounts.v1.Role.permissions: object expected, but got ' +
                  typeof object.permissions
              );
            }
            message.permissions = permissions$1.v1.PermissionSet.fromObject(
              object.permissions
            );
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.accounts.v1.Role.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.isActive != null) {
            message.isActive = Boolean(object.isActive);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.permissions = null;
            object.timing = null;
            object.isActive = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.permissions != null &&
            message.hasOwnProperty('permissions')
          ) {
            object.permissions = permissions$1.v1.PermissionSet.toObject(
              message.permissions,
              options
            );
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.isActive != null && message.hasOwnProperty('isActive')) {
            object.isActive = message.isActive;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.Role';
        }
      }

      Role.prototype.id = '';
      Role.prototype.name = '';
      Role.prototype.description = null;
      Role.prototype.permissions = null;
      Role.prototype.timing = null;
      Role.prototype.isActive = false;

      return Role;
    })();

    v1.ListAccountOrganizationsRequest = (() => {
      class ListAccountOrganizationsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAccountOrganizationsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAccountOrganizationsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.accounts.v1.ListAccountOrganizationsRequest
          ) {
            return object;
          }
          const message =
            new $root.accounts.v1.ListAccountOrganizationsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.accounts.v1.ListAccountOrganizationsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListAccountOrganizationsRequest';
        }
      }

      ListAccountOrganizationsRequest.prototype.stub = null;

      return ListAccountOrganizationsRequest;
    })();

    v1.ListAccountOrganizationsResponse = (() => {
      class ListAccountOrganizationsResponse {
        constructor(properties) {
          this.organizations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAccountOrganizationsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizations != null &&
            Object.hasOwnProperty.call(message, 'organizations')
          ) {
            for (const element of message.organizations) {
              $root.accounts.v1.Organization.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAccountOrganizationsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.organizations || !message.organizations.length) {
                  message.organizations = [];
                }
                message.organizations.push(
                  $root.accounts.v1.Organization.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.accounts.v1.ListAccountOrganizationsResponse
          ) {
            return object;
          }
          const message =
            new $root.accounts.v1.ListAccountOrganizationsResponse();
          if (object.organizations) {
            if (!Array.isArray(object.organizations)) {
              throw new TypeError(
                '.accounts.v1.ListAccountOrganizationsResponse.organizations: array type expected, but got ' +
                  typeof object.organizations
              );
            }
            message.organizations = new Array(object.organizations.length);
            for (let i = 0; i < object.organizations.length; ++i) {
              if (typeof object.organizations[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.ListAccountOrganizationsResponse.organizations: object expected, but got ' +
                    typeof object.organizations[i]
                );
              }
              message.organizations[i] =
                $root.accounts.v1.Organization.fromObject(
                  object.organizations[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.organizations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.organizations && message.organizations.length) {
            object.organizations = new Array(message.organizations.length);
            for (let i = 0; i < message.organizations.length; ++i) {
              object.organizations[i] = $root.accounts.v1.Organization.toObject(
                message.organizations[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListAccountOrganizationsResponse';
        }
      }

      ListAccountOrganizationsResponse.prototype.organizations =
        $util.emptyArray;

      return ListAccountOrganizationsResponse;
    })();

    v1.ListAccountUsersRequest = (() => {
      class ListAccountUsersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAccountUsersRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAccountUsersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListAccountUsersRequest) {
            return object;
          }
          const message = new $root.accounts.v1.ListAccountUsersRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.accounts.v1.ListAccountUsersRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListAccountUsersRequest';
        }
      }

      ListAccountUsersRequest.prototype.stub = null;

      return ListAccountUsersRequest;
    })();

    v1.ListAccountUsersResponse = (() => {
      class ListAccountUsersResponse {
        constructor(properties) {
          this.users = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAccountUsersResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.users != null &&
            Object.hasOwnProperty.call(message, 'users')
          ) {
            for (const element of message.users) {
              $root.accounts.v1.User.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAccountUsersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.users || !message.users.length) {
                  message.users = [];
                }
                message.users.push(
                  $root.accounts.v1.User.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.accounts.v1.ListAccountUsersResponse) {
            return object;
          }
          const message = new $root.accounts.v1.ListAccountUsersResponse();
          if (object.users) {
            if (!Array.isArray(object.users)) {
              throw new TypeError(
                '.accounts.v1.ListAccountUsersResponse.users: array type expected, but got ' +
                  typeof object.users
              );
            }
            message.users = new Array(object.users.length);
            for (let i = 0; i < object.users.length; ++i) {
              if (typeof object.users[i] !== 'object') {
                throw new TypeError(
                  '.accounts.v1.ListAccountUsersResponse.users: object expected, but got ' +
                    typeof object.users[i]
                );
              }
              message.users[i] = $root.accounts.v1.User.fromObject(
                object.users[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.users = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.users && message.users.length) {
            object.users = new Array(message.users.length);
            for (let i = 0; i < message.users.length; ++i) {
              object.users[i] = $root.accounts.v1.User.toObject(
                message.users[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'accounts.v1.ListAccountUsersResponse';
        }
      }

      ListAccountUsersResponse.prototype.users = $util.emptyArray;

      return ListAccountUsersResponse;
    })();

    v1.OrganizationType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ORGANIZATION_TYPE')] = 0;
      values[(valuesById[1] = 'MORF_INTERNAL')] = 1;
      values[(valuesById[2] = 'CUSTOMER_SANDBOX')] = 2;
      values[(valuesById[3] = 'CUSTOMER_PRODUCTION')] = 3;
      return values;
    })();

    v1.AdminType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ADMIN_TYPE')] = 0;
      values[(valuesById[1] = 'ACCOUNT_MANAGER')] = 1;
      values[(valuesById[2] = 'BILLING_CONTACT')] = 2;
      values[(valuesById[4] = 'ORG_CONTACT')] = 4;
      values[(valuesById[5] = 'PATIENT_ADMIN')] = 5;
      values[(valuesById[6] = 'CONCIERGE')] = 6;
      return values;
    })();

    v1.ProviderType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_PROVIDER_TYPE')] = 0;
      values[(valuesById[1] = 'COACH')] = 1;
      values[(valuesById[2] = 'REGISTERED_DIETICIAN')] = 2;
      values[(valuesById[3] = 'NURSE')] = 3;
      values[(valuesById[4] = 'NURSE_PRACTITIONER')] = 4;
      values[(valuesById[5] = 'DO')] = 5;
      values[(valuesById[6] = 'MD')] = 6;
      values[(valuesById[7] = 'CONSULTANT')] = 7;
      return values;
    })();

    v1.AccountsService = (() => {
      class AccountsService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new AccountsService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (AccountsService.prototype.createAccount = function createAccount(
          request,
          callback
        ) {
          return this.rpcCall(
            createAccount,
            $root.accounts.v1.CreateAccountRequest,
            $root.accounts.v1.CreateAccountResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreateAccount' }
      );

      Object.defineProperty(
        (AccountsService.prototype.createOrganization =
          function createOrganization(request, callback) {
            return this.rpcCall(
              createOrganization,
              $root.accounts.v1.CreateOrganizationRequest,
              $root.accounts.v1.CreateOrganizationResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateOrganization' }
      );

      Object.defineProperty(
        (AccountsService.prototype.updateOrganization =
          function updateOrganization(request, callback) {
            return this.rpcCall(
              updateOrganization,
              $root.accounts.v1.UpdateOrganizationRequest,
              $root.accounts.v1.UpdateOrganizationResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateOrganization' }
      );

      Object.defineProperty(
        (AccountsService.prototype.getOrganization = function getOrganization(
          request,
          callback
        ) {
          return this.rpcCall(
            getOrganization,
            $root.accounts.v1.GetOrganizationRequest,
            $root.accounts.v1.GetOrganizationResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetOrganization' }
      );

      Object.defineProperty(
        (AccountsService.prototype.getOrganizationUsers =
          function getOrganizationUsers(request, callback) {
            return this.rpcCall(
              getOrganizationUsers,
              $root.accounts.v1.GetOrganizationUsersRequest,
              $root.accounts.v1.GetOrganizationUsersResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetOrganizationUsers' }
      );

      Object.defineProperty(
        (AccountsService.prototype.listAccountUsers = function listAccountUsers(
          request,
          callback
        ) {
          return this.rpcCall(
            listAccountUsers,
            $root.accounts.v1.ListAccountUsersRequest,
            $root.accounts.v1.ListAccountUsersResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListAccountUsers' }
      );

      Object.defineProperty(
        (AccountsService.prototype.listOrganizations =
          function listOrganizations(request, callback) {
            return this.rpcCall(
              listOrganizations,
              $root.accounts.v1.ListOrganizationsRequest,
              $root.accounts.v1.ListOrganizationsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListOrganizations' }
      );

      Object.defineProperty(
        (AccountsService.prototype.getUser = function getUser(
          request,
          callback
        ) {
          return this.rpcCall(
            getUser,
            $root.accounts.v1.GetUserRequest,
            $root.accounts.v1.GetUserResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetUser' }
      );

      Object.defineProperty(
        (AccountsService.prototype.createUser = function createUser(
          request,
          callback
        ) {
          return this.rpcCall(
            createUser,
            $root.accounts.v1.CreateUserRequest,
            $root.accounts.v1.CreateUserResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreateUser' }
      );

      Object.defineProperty(
        (AccountsService.prototype.deleteUser = function deleteUser(
          request,
          callback
        ) {
          return this.rpcCall(
            deleteUser,
            $root.accounts.v1.DeleteUserRequest,
            $root.accounts.v1.DeleteUserResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'DeleteUser' }
      );

      Object.defineProperty(
        (AccountsService.prototype.updateUser = function updateUser(
          request,
          callback
        ) {
          return this.rpcCall(
            updateUser,
            $root.accounts.v1.UpdateUserRequest,
            $root.accounts.v1.UpdateUserResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpdateUser' }
      );

      Object.defineProperty(
        (AccountsService.prototype.createRole = function createRole(
          request,
          callback
        ) {
          return this.rpcCall(
            createRole,
            $root.accounts.v1.CreateRoleRequest,
            $root.accounts.v1.CreateRoleResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreateRole' }
      );

      Object.defineProperty(
        (AccountsService.prototype.updateRole = function updateRole(
          request,
          callback
        ) {
          return this.rpcCall(
            updateRole,
            $root.accounts.v1.UpdateRoleRequest,
            $root.accounts.v1.UpdateRoleResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpdateRole' }
      );

      Object.defineProperty(
        (AccountsService.prototype.listRoles = function listRoles(
          request,
          callback
        ) {
          return this.rpcCall(
            listRoles,
            $root.accounts.v1.ListRolesRequest,
            $root.accounts.v1.ListRolesResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListRoles' }
      );

      Object.defineProperty(
        (AccountsService.prototype.listAccountOrganizations =
          function listAccountOrganizations(request, callback) {
            return this.rpcCall(
              listAccountOrganizations,
              $root.accounts.v1.ListAccountOrganizationsRequest,
              $root.accounts.v1.ListAccountOrganizationsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListAccountOrganizations' }
      );

      return AccountsService;
    })();

    return v1;
  })();

  return accounts;
})();

export const accounts = $root.accounts;
