import { InputProps } from './types';
import { styled } from '@morf/theming';

export const StyledInput = styled.input<InputProps>`
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
  border: none;
  color: ${({ color, theme }) => color || theme.colors.text.body};
  cursor: ${({ readOnly }) => (readOnly ? 'not-allowed' : 'text')};
  font-family: ${({ tag, theme }) => tag && theme.fontTypes[tag].family};
  font-size: ${({ tag, theme }) => tag && theme.fontTypes[tag].size};
  font-synthesis: none;
  font-weight: ${({ tag, theme }) => tag && theme.fontTypes[tag].weight};
  letter-spacing: ${({ tag, theme }) =>
    tag && theme.fontTypes[tag].letterSpacing};
  line-height: ${({ tag, theme }) => tag && theme.fontTypes[tag].lineHeight};
  margin: 0;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  text-rendering: optimizeLegibility;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.muted};
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type='date'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;
