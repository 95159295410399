import { FC, memo } from 'react';
import { DotBadgeProps } from './types';
import { StyledDotBadge } from './DotBadge.css';
import { Text } from '../Typography';
import { Flexbox } from '../Flexbox';
import { useTheme } from '@morf/theming';

const DotBadge: FC<DotBadgeProps> = ({ label = '', type }) => {
  const theme = useTheme();
  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      width='auto'
      height='auto'
      gap={0.5}
    >
      <StyledDotBadge
        data-testid='dot-badge'
        justifyContent='center'
        alignItems='center'
        width='0.5rem'
        height='0.5rem'
        borderRadius='full'
        type={type}
      >
        <></>
      </StyledDotBadge>
      <Text tag='p2' color={theme.colors.text.body} whiteSpace='nowrap'>
        {label}
      </Text>
    </Flexbox>
  );
};

export const MemoizedDotBadge = memo(DotBadge);
