import { FC } from 'react';
import { ProviderCardProps } from './types';
import { Flexbox } from '../Flexbox';
import { Container } from '../Container';
import { Timeslots } from '../Timeslots/Timeslots';
import { useResize } from '../Hooks/useResize';
import { ProviderCardDetails } from './ProviderCardDetails';

export const ProviderCard: FC<ProviderCardProps> = ({
  id,
  name,
  imageUrl,
  shortBio,
  specialties,
  qualifications,
  detailedQualifications,
  slots,
  to,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
  onClick,
}) => {
  const responsiveSlots = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'column',
      sm: 'column',
      xs: 'column',
    },
    paddingTop: {
      xxl: 1.25,
      xl: 1.25,
      lg: 1.25,
      md: 0,
      sm: 0,
      xs: 0,
    },
    paddingBottom: {
      xxl: 1.25,
      xl: 1.25,
      lg: 1.25,
      md: 0,
      sm: 0,
      xs: 0,
    },
    columns: {
      xxl: 2,
      xl: 2,
      lg: 1,
      md: 2,
      sm: 2,
      xs: 1,
    },
  });
  return (
    <Flexbox key={id} direction={responsiveSlots.direction} height='auto'>
      <Container pt={1.25} pb={responsiveSlots.paddingBottom}>
        <ProviderCardDetails
          id={id}
          imageUrl={imageUrl}
          name={name}
          qualifications={qualifications}
          detailedQualifications={detailedQualifications}
          specialties={specialties}
          shortBio={shortBio}
          to={to}
          onClick={onClick}
        />
      </Container>
      <Container px={1.25} pb={1.25} pt={responsiveSlots.paddingTop}>
        <Timeslots
          columns={responsiveSlots.columns}
          slots={slots}
          selectedSlot={selectedSlot}
          onSelectSlot={onSelectSlot}
          onConfirmSlot={(timeslot) => {
            onConfirmSlot(timeslot);
          }}
        />
      </Container>
    </Flexbox>
  );
};
