import momentTimezone from 'moment-timezone';

export const formatTimezone = (timezone: string): string => {
  let formattedLocation = '';
  let formattedRegion = '';
  let formattedOffset = '';

  if (timezone.includes('/')) {
    const [region, location] = timezone.split('/');
    formattedLocation = location?.replace(/_/g, ' ') || '';
    formattedRegion = region.replace(/_/g, ' ');
  } else {
    formattedLocation = timezone.replace(/_/g, ' ');
  }

  if (formattedLocation && formattedRegion) {
    const offset = momentTimezone.tz(timezone).format('Z');
    formattedOffset = `GMT ${offset.slice(0, 3)}${offset.slice(3)}`;
  }

  return `${formattedLocation}, ${formattedRegion} (${formattedOffset})`;
};
