export const labelMap: { [key: string]: string } = {
  'Optional parameter missing behavior error and terminate branch':
    'Emit error and terminate branch',
  'Optional parameter missing behavior error and terminate workflow':
    'Emit error and terminate workflow',
  'Optional parameter missing behavior retry node': 'Retry',
  'Optional parameter missing behavior skip node': 'Skip',
  'Optional parameter missing behavior use null':
    'Continue without optional parameters',
};
