import { FC, memo, useEffect } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { MemoizedProfileConvertMergeEmailAddress } from './ProfileConvertMergeEmailAddress';
import { MemoizedProfileConvertMergePhoneNumber } from './ProfileConvertMergePhoneNumber';
import { ProfileConvertNodeProps } from '../types';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const ProfileConvertConfigure: FC<ProfileConvertNodeProps> = (node) => {
  const { id, data } = node;
  const { name, description, configuration, onAddNode, isRequired } =
    data.value;

  useEffect(() => {
    if (isRequired) {
      onAddNode &&
        onAddNode({
          node: new workflows.v1.CreateWorkflowNodeRequest({
            id: id,
            name: name,
            description: description,
            destinationAction: configuration,
          }),
        });
    }
  }, [isRequired]);

  return (
    <Flexbox
      data-testid='profile-convert-configure'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <MemoizedProfileConvertMergeEmailAddress {...node} />
      <MemoizedProfileConvertMergePhoneNumber {...node} />
    </Flexbox>
  );
};

export const MemoizedProfileConvertConfigure = memo(ProfileConvertConfigure);
