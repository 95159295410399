import { FC } from 'react';
import { AppointmentConfirmationProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { AppointmentHeader } from '../AppointmentHeader/AppointmentHeader';
import { AppointmentFooter } from '../AppointmentDetail/AppointmentFooter';
import { Divider } from '../Divider';

export const AppointmentConfirmation: FC<AppointmentConfirmationProps> = ({
  appointmentName,
  providerName,
  appointmentDate,
  appointmentTime,
  appointmentTimezone,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      id='appointment-confirmation'
      p={1.25}
      direction='column'
      gap={1}
      maxWidth='47.5rem'
    >
      <Flexbox direction='column' gap={0.5}>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.25}
        >
          <Container width='1.25rem' height='1.25rem'>
            <Icon name='check' stroke={theme.colors.support.green.darkest} />
          </Container>
          <Text tag='h4' color={theme.colors.support.green.darkest}>
            Confirmed
          </Text>
        </Flexbox>
        <AppointmentHeader
          appointmentName={appointmentName}
          appointmentDate={appointmentDate}
          providerName={providerName}
          appointmentTime={appointmentTime}
          appointmentTimezone={appointmentTimezone}
        />
      </Flexbox>
      <Divider />
      <AppointmentFooter />
    </Flexbox>
  );
};
