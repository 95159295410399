import { ModalPosition } from './types';

export const toggleModal = (
  cellContentRef: React.RefObject<HTMLDivElement>,
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalPosition: React.Dispatch<React.SetStateAction<ModalPosition>>
) => {
  const size = cellContentRef.current?.getBoundingClientRect();
  if (size) {
    const { top, left, height } = size;
    const viewPortHeight = window.innerHeight;
    const p = 0.5 * 16;

    if (2 * height > viewPortHeight - top) {
      setModalPosition({
        top: 'auto',
        left: `${left + p}px`,
        bottom: `${viewPortHeight - top - height + p}px`,
      });
    } else {
      setModalPosition({
        top: `${top + p}px`,
        left: `${left + p}px`,
        bottom: 'auto',
      });
    }
  }
  setIsModalOpen(!isModalOpen);
};
