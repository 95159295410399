import _ from 'lodash';
import { ActionExecutionRowProps } from './types';
import { ActionExecutionStatus } from '../../ActionExecutionStatus';
import { ActionExecutionSubRow } from './ActionExecutionSubRow';
import { ActionExecutionTooltip } from './ActionExecutionTooltip';
import { Container } from '../../Container';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { IntegrationIcon } from '../../IntegrationIcon';
import { SkeletonLoadable } from '../../Skeleton';
import { Text } from '../../Typography';
import { WrapperModal } from '../../WrapperModal';
import { convertTimestampToMoment } from '../../Filters';
import { convertToKebabCase } from '../../Helpers/convertToKebabCase';
import { defaultDateTimeFormatWithSeconds } from '../../Filters/TimeFilter/constants';
import { iconNameNodeTypeMap } from '../../Workflow/CustomNode/constants';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { useHover } from '../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';
import { workflow_destination_applications } from '@morf/proto/workflow_destination_applications_v1_ts_proto';
import {
  FC,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const ActionExecutionRow: FC<ActionExecutionRowProps> = ({
  application,
  attempts,
  isLoading,
  isRowSelected,
  name,
  nodeId,
  onRowClick,
  status,
  workflowExecutionId,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();

  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [isExpanded, setIsExpanded] = useState(isRowSelected);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const latestAttempt = _.last(attempts);

  const backgroundColor =
    isHovered && !isLoading ? theme.colors.ui.body : theme.colors.ui.card;

  const borderColor = isRowSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;

  const carrotIcon = isExpanded ? 'carrot-down' : 'carrot-right';

  const cursor = isLoading ? 'default' : 'pointer';

  const hasMultipleAttempts = attempts.length > 1;
  const hasApplication = !!application;
  const hasExpectedExecutionEnd = latestAttempt?.expectedExecutionEnd;
  const hasFilterOutcome = latestAttempt?.filterOutcome !== undefined;

  const nameTag = isRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    latestAttempt &&
    latestAttempt.executionStart &&
    convertTimestampToMoment(latestAttempt.executionStart).format(
      defaultDateTimeFormatWithSeconds
    );

  const handleRowClick = () => {
    onRowClick();
    setIsExpanded(true);
    updateUrlParam({
      workflowExecutionId: workflowExecutionId,
      nodeId: nodeId,
      attemptNumber: _.toString(attempts.length),
    });
  };

  const handleCarrotIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isExpanded) {
      handleRowClick();
    } else {
      setIsExpanded(false);
    }
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered && !isLoading) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, isLoading, handleShowTooltip]);

  useEffect(() => {
    setIsExpanded(isRowSelected);
  }, [isRowSelected]);

  return (
    <>
      <Flexbox
        key={nodeId}
        containerRef={containerRef}
        data-testid='action-execution-row'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={0.25}
        borderType='border'
        cursor={cursor}
        width='16.5rem'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleRowClick}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          p={0.5}
          gap={0.25}
        >
          <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
            <Container width='auto' height='auto'>
              {latestAttempt &&
                nodeId === 'trigger-node' &&
                latestAttempt.workflowExecutionSummary && (
                  <IntegrationIcon
                    name={convertToKebabCase(
                      source_application.v2.SourceApplication.Application[
                        latestAttempt.workflowExecutionSummary.applicationType
                      ]
                    )}
                    size={1.25}
                  />
                )}

              {latestAttempt &&
                nodeId === 'profile-lookup-node' &&
                iconNameNodeTypeMap.profileLookupNode && (
                  <Icon
                    name={iconNameNodeTypeMap.profileLookupNode}
                    stroke={theme.colors.ui.dark}
                    size={1.25}
                  />
                )}

              {latestAttempt &&
                hasFilterOutcome &&
                iconNameNodeTypeMap.filterNode && (
                  <Icon
                    name={iconNameNodeTypeMap.filterNode}
                    stroke={theme.colors.ui.dark}
                    size={1.25}
                  />
                )}

              {latestAttempt &&
                hasExpectedExecutionEnd &&
                iconNameNodeTypeMap.pauseNode && (
                  <Icon
                    name={iconNameNodeTypeMap.pauseNode}
                    stroke={theme.colors.ui.dark}
                    size={1.25}
                  />
                )}

              {latestAttempt &&
                !hasFilterOutcome &&
                !hasExpectedExecutionEnd &&
                hasApplication && (
                  <IntegrationIcon
                    name={convertToKebabCase(
                      workflow_destination_applications.v1
                        .DestinationApplication.Application[application]
                    )}
                    size={1.25}
                  />
                )}
            </Container>
          </SkeletonLoadable>

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0}
          >
            <SkeletonLoadable
              isLoading={isLoading}
              width='11.5rem'
              height='1rem'
            >
              <Container width='11.5rem' height='auto'>
                <Text tag={nameTag} whiteSpace='nowrap'>
                  {name}
                </Text>
              </Container>
            </SkeletonLoadable>

            <SkeletonLoadable
              isLoading={isLoading}
              width='11.5rem'
              height='0.5rem'
            >
              <Text
                tag='p3'
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {formattedTimestamp}
              </Text>
            </SkeletonLoadable>
          </Flexbox>
          <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
            <ActionExecutionStatus
              variant='icon'
              status={status}
              expectedExecutionEnd={latestAttempt?.expectedExecutionEnd}
              error={latestAttempt?.error}
              retryNumber={latestAttempt?.attemptNumber}
            />
          </SkeletonLoadable>

          <Flexbox justifyContent='center' alignItems='center'>
            {hasMultipleAttempts && (
              <Container
                height='auto'
                width='auto'
                onClick={handleCarrotIconClick}
              >
                <Icon
                  name={carrotIcon}
                  size={0.75}
                  fill={theme.colors.ui.dark}
                  stroke='none'
                />
              </Container>
            )}
          </Flexbox>
        </Flexbox>

        {hasMultipleAttempts && isExpanded && (
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            onMouseEnter={handleMouseLeave}
            gap={0}
          >
            {attempts.map((a, i) => (
              <ActionExecutionSubRow
                key={i}
                {...a}
                isLastSubRow={i === attempts.length - 1}
              />
            ))}
          </Flexbox>
        )}
      </Flexbox>

      {showTooltip && latestAttempt && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <ActionExecutionTooltip {...latestAttempt} />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedActionExecutionRow = memo(ActionExecutionRow);
