import { Handle, Position } from 'reactflow';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { useTheme } from '@morf/theming';
import { Icon } from '../../../../Icon';
import { Text } from '../../../../Typography';
import { nodeWidth } from '../../../constants';

const TerminateNode: FC = () => {
  const theme = useTheme();
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <Flexbox
        direction='row'
        data-testid='terminate-node'
        backgroundColor='#C09191'
        justifyContent='center'
        alignItems='center'
        borderRadius={0.5}
        height='2rem'
        width={nodeWidth}
        gap={0.25}
      >
        <Icon name='x-circle' stroke={theme.colors.text.inverse} size={1.25} />
        <Flexbox width='auto' maxWidth='9.5rem' alignItems='center'>
          <Text tag='p3' whiteSpace='nowrap' color={theme.colors.text.inverse}>
            terminate workflow
          </Text>
        </Flexbox>
      </Flexbox>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedTerminateNode = memo(TerminateNode);
