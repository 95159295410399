import { FC, memo } from 'react';
import { ProfileLookupConfiguration } from '../../../../ProfileLookupConfiguration';
import { ProfileLookupNodeProps } from '../types';
import { handleLookupNodeSave } from '../handleLookupNodeSave';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';

const ProfileLookupConfigure: FC<ProfileLookupNodeProps> = (node) => {
  const { nodes, setNodes } = useWorkflow();
  const { configuration, onUpdateProfileLookup, isReadOnly } = node.data.value;

  const handleLookupTypeChange = (updatedLookupType: number) => {
    const lookupNode = new profiles.v1.LookupConfiguration({
      ...configuration,
      lookupType: updatedLookupType,
    });

    handleLookupNodeSave(lookupNode, nodes, setNodes, onUpdateProfileLookup);
  };

  const handleLookupsSequenceUpdate = (
    lookupsSequence: profiles.v1.Lookup[]
  ) => {
    const lookupNode = new profiles.v1.LookupConfiguration({
      ...configuration,
      lookupsSequence: lookupsSequence,
    });

    handleLookupNodeSave(lookupNode, nodes, setNodes, onUpdateProfileLookup);
  };

  const handleNoProfileFoundActionChange = (
    updatedNoProfileFoundAction: number
  ) => {
    const lookupNode = new profiles.v1.LookupConfiguration({
      ...configuration,
      noProfileFoundAction: updatedNoProfileFoundAction,
    });
    handleLookupNodeSave(lookupNode, nodes, setNodes, onUpdateProfileLookup);
  };

  return (
    <ProfileLookupConfiguration
      isReadOnly={isReadOnly}
      lookupConfiguration={configuration}
      onLookupAdd={handleLookupsSequenceUpdate}
      onLookupFieldChange={handleLookupsSequenceUpdate}
      onLookupFieldNotPresentChange={handleLookupsSequenceUpdate}
      onLookupIndexChange={handleLookupsSequenceUpdate}
      onLookupMultipleProfilesFoundChange={handleLookupsSequenceUpdate}
      onLookupTypeChange={handleLookupTypeChange}
      onNoProfileFoundActionChange={handleNoProfileFoundActionChange}
      onLookupRemove={handleLookupsSequenceUpdate}
    />
  );
};

export const MemoizedProfileLookupConfigure = memo(ProfileLookupConfigure);
