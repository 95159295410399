export const CarrotUp = () => {
  return (
    <path d='M17.7567 14.3295L12.6835 8.3256C12.3166 7.89147 11.6834 7.89147 11.3165 8.3256L6.24327 14.3295C5.70183 14.9703 6.12314 16 6.92676 16H17.0732C17.8769 16 18.2982 14.9703 17.7567 14.3295Z' />
  );
};

export const CarrotDown = () => {
  return (
    <path d='M17.7567 9.67046L12.6835 15.6744C12.3166 16.1085 11.6834 16.1085 11.3165 15.6744L6.24327 9.67046C5.70183 9.02969 6.12314 8 6.92676 8H17.0732C17.8769 8 18.2982 9.02969 17.7567 9.67046Z' />
  );
};

export const CarrotRight = () => {
  return (
    <path d='M9.67046 17.7567L15.6744 12.6835C16.1085 12.3166 16.1085 11.6834 15.6744 11.3165L9.67046 6.24327C9.02969 5.70183 8 6.12314 8 6.92676L8 17.0732C8 17.8769 9.02969 18.2982 9.67046 17.7567Z' />
  );
};

export const CarrotLeft = () => {
  return (
    <path d='M14.3295 6.24327L8.3256 11.3165C7.89147 11.6834 7.89147 12.3166 8.3256 12.6835L14.3295 17.7567C14.9703 18.2982 16 17.8769 16 17.0732L16 6.92676C16 6.12314 14.9703 5.70183 14.3295 6.24327Z' />
  );
};
