import { sensitivity } from '@morf/proto/sensitivity_v1_ts_proto';

export const formatSensitivities = (
  sensitivities: sensitivity.v1.Sensitivity.Value[] | null | undefined
) => {
  if (sensitivities) {
    return sensitivities
      .map((s) => sensitivity.v1.Sensitivity.Value[s])
      .filter((kind) => !!kind)
      .sort()
      .join(', ');
  }
  return '';
};
