import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { LetteredAvatar } from '../LetteredAvatar/LetteredAvatar';
import { SkeletonLoadable } from '../Skeleton';
import { Text } from '../Typography';
import { VersionTimelineRowProps } from './types';
import { VersionTimelineRowStatus } from './VersionTimeRowStatus';
import { useTheme } from '@morf/theming';
import { useHover } from '../Hooks/useHover';

const VersionTimelineRow: FC<VersionTimelineRowProps> = ({
  isLoading,
  isSelected,
  onClick,
  type,
  updatedAt,
  updatedBy,
  version,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const backgroundColor =
    !isLoading && (isHovered || isSelected)
      ? theme.colors.ui.body
      : theme.colors.ui.card;

  return (
    <Flexbox
      data-testid='version-timeline-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      height='3.25rem'
      cursor={isLoading ? 'inherit' : 'pointer'}
      px={1}
      py={0.5}
      gap={0.5}
    >
      <SkeletonLoadable
        isLoading={isLoading}
        height='2.125rem'
        width='0.125rem'
      >
        <VersionTimelineRowStatus type={type} />
      </SkeletonLoadable>

      <Flexbox direction='column' justifyContent='flex-start' gap={0}>
        <Container>
          <SkeletonLoadable isLoading={isLoading} height='1rem' width='12.5rem'>
            <Text color={theme.colors.text.body} tag={isSelected ? 'h5' : 'p2'}>
              {updatedAt}
            </Text>
          </SkeletonLoadable>
        </Container>

        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.25}
        >
          <Container width='auto' height='auto'>
            <SkeletonLoadable isLoading={isLoading} height='1rem'>
              <Text color={theme.colors.text.muted} tag='p3'>
                V{version} Updated by
              </Text>
            </SkeletonLoadable>
          </Container>
          <SkeletonLoadable isLoading={isLoading} height='1rem' width='1rem'>
            <LetteredAvatar
              height='1rem'
              name={updatedBy}
              tag='p4'
              width='1rem'
            />
          </SkeletonLoadable>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedVersionTimelineRow = memo(VersionTimelineRow);
