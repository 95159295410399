import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { ParameterVariant } from '../ParameterVariant';
import { SourceConfigProps } from '../Workflow/types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const SourceConfig: FC<SourceConfigProps> = ({
  fieldName = 'no value',
  fieldType,
  integrationIconName,
  isReadOnly = false,
  isRequired = false,
  sensitivity,
  tagType = 'disabled',
}) => {
  const theme = useTheme();
  const cursor = isReadOnly ? 'not-allowed' : 'pointer';
  const isFieldRequiredAndEmpty = isRequired && fieldName === 'no value';
  const label = isFieldRequiredAndEmpty ? 'Required' : fieldName;
  const type = isFieldRequiredAndEmpty ? 'required' : tagType;

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      data-testid='source-config'
      cursor={cursor}
      gap={0.25}
    >
      <ParameterVariant
        id={fieldName}
        integrationIconName={integrationIconName}
        isReadOnly={isReadOnly}
        label={label}
        type={type}
      />

      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.25}
      >
        {fieldType && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {fieldType}
          </Text>
        )}

        {sensitivity && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {sensitivity}
          </Text>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedSourceConfig = memo(SourceConfig);
