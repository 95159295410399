import { FC, forwardRef } from 'react';
import { DividerProps } from './types';
import { StyledDivider, StyledDividerContent } from './Divider.css';
import { Flexbox } from '../Flexbox';
import { useTheme } from '@morf/theming';

export const Divider: FC<DividerProps> = forwardRef<
  HTMLHRElement,
  DividerProps
>(({ children }, ref) => {
  const theme = useTheme();
  return (
    <Flexbox
      ref={ref}
      data-testid={'divider'}
      alignItems='center'
      position='relative'
      justifyContent='center'
      height='1rem'
    >
      <StyledDivider />
      {children && (
        <StyledDividerContent backgroundColor={theme.colors.ui.card}>
          {children}
        </StyledDividerContent>
      )}
    </Flexbox>
  );
});
