'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.vital = (() => {
  const vital = {};
  vital.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.vital.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.vital.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_VITAL_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'VITAL_LAB_ORDER_STATUS_UPDATE':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'VITAL_LAB_ORDER_RESULTS_UPDATE':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.vital.v1.VitalEventType[message.eventTypes[i]] ===
                    undefined
                    ? message.eventTypes[i]
                    : $root.vital.v1.VitalEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Order = (() => {
      class Order {
        constructor(properties) {
          this.events = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Order(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.userId != null &&
            Object.hasOwnProperty.call(message, 'userId')
          ) {
            writer.uint32(18).string(message.userId);
          }
          if (
            message.labTest != null &&
            Object.hasOwnProperty.call(message, 'labTest')
          ) {
            $root.vital.v1.OrderLabTest.encode(
              message.labTest,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.details != null &&
            Object.hasOwnProperty.call(message, 'details')
          ) {
            $root.vital.v1.OrderDetails.encode(
              message.details,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(42).string(message.notes);
          }
          if (
            message.patientDetails != null &&
            Object.hasOwnProperty.call(message, 'patientDetails')
          ) {
            $root.vital.v1.OrderPatientDetails.encode(
              message.patientDetails,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.physician != null &&
            Object.hasOwnProperty.call(message, 'physician')
          ) {
            $root.vital.v1.OrderPhysician.encode(
              message.physician,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.isLabcorpStatOrder != null &&
            Object.hasOwnProperty.call(message, 'isLabcorpStatOrder')
          ) {
            writer.uint32(64).bool(message.isLabcorpStatOrder);
          }
          if (
            message.sampleId != null &&
            Object.hasOwnProperty.call(message, 'sampleId')
          ) {
            writer.uint32(74).string(message.sampleId);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(82).string(message.status);
          }
          if (
            message.events != null &&
            Object.hasOwnProperty.call(message, 'events')
          ) {
            for (const element of message.events) {
              $root.vital.v1.OrderEvent.encode(
                element,
                writer.uint32(90).fork()
              ).ldelim();
            }
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Order();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.userId = reader.string();
                break;
              }
              case 3: {
                message.labTest = $root.vital.v1.OrderLabTest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.details = $root.vital.v1.OrderDetails.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.notes = reader.string();
                break;
              }
              case 6: {
                message.patientDetails =
                  $root.vital.v1.OrderPatientDetails.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.physician = $root.vital.v1.OrderPhysician.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.isLabcorpStatOrder = reader.bool();
                break;
              }
              case 9: {
                message.sampleId = reader.string();
                break;
              }
              case 10: {
                message.status = reader.string();
                break;
              }
              case 11: {
                if (!message.events || !message.events.length) {
                  message.events = [];
                }
                message.events.push(
                  $root.vital.v1.OrderEvent.decode(reader, reader.uint32())
                );
                break;
              }
              case 12: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 13: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.Order) {
            return object;
          }
          const message = new $root.vital.v1.Order();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.labTest != null) {
            if (typeof object.labTest !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.labTest: object expected, but got ' +
                  typeof object.labTest
              );
            }
            message.labTest = $root.vital.v1.OrderLabTest.fromObject(
              object.labTest
            );
          }
          if (object.details != null) {
            if (typeof object.details !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.details: object expected, but got ' +
                  typeof object.details
              );
            }
            message.details = $root.vital.v1.OrderDetails.fromObject(
              object.details
            );
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.patientDetails != null) {
            if (typeof object.patientDetails !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.patientDetails: object expected, but got ' +
                  typeof object.patientDetails
              );
            }
            message.patientDetails =
              $root.vital.v1.OrderPatientDetails.fromObject(
                object.patientDetails
              );
          }
          if (object.physician != null) {
            if (typeof object.physician !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.physician: object expected, but got ' +
                  typeof object.physician
              );
            }
            message.physician = $root.vital.v1.OrderPhysician.fromObject(
              object.physician
            );
          }
          if (object.isLabcorpStatOrder != null) {
            message.isLabcorpStatOrder = Boolean(object.isLabcorpStatOrder);
          }
          if (object.sampleId != null) {
            message.sampleId = String(object.sampleId);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.events) {
            if (!Array.isArray(object.events)) {
              throw new TypeError(
                '.vital.v1.Order.events: array type expected, but got ' +
                  typeof object.events
              );
            }
            message.events = new Array(object.events.length);
            for (let i = 0; i < object.events.length; ++i) {
              if (typeof object.events[i] !== 'object') {
                throw new TypeError(
                  '.vital.v1.Order.events: object expected, but got ' +
                    typeof object.events[i]
                );
              }
              message.events[i] = $root.vital.v1.OrderEvent.fromObject(
                object.events[i]
              );
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.vital.v1.Order.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.events = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.userId = '';
            object.labTest = null;
            object.details = null;
            object.isLabcorpStatOrder = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.userId != null && message.hasOwnProperty('userId')) {
            object.userId = message.userId;
          }
          if (message.labTest != null && message.hasOwnProperty('labTest')) {
            object.labTest = $root.vital.v1.OrderLabTest.toObject(
              message.labTest,
              options
            );
          }
          if (message.details != null && message.hasOwnProperty('details')) {
            object.details = $root.vital.v1.OrderDetails.toObject(
              message.details,
              options
            );
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (
            message.patientDetails != null &&
            message.hasOwnProperty('patientDetails')
          ) {
            object.patientDetails = $root.vital.v1.OrderPatientDetails.toObject(
              message.patientDetails,
              options
            );
          }
          if (
            message.physician != null &&
            message.hasOwnProperty('physician')
          ) {
            object.physician = $root.vital.v1.OrderPhysician.toObject(
              message.physician,
              options
            );
          }
          if (
            message.isLabcorpStatOrder != null &&
            message.hasOwnProperty('isLabcorpStatOrder')
          ) {
            object.isLabcorpStatOrder = message.isLabcorpStatOrder;
          }
          if (message.sampleId != null && message.hasOwnProperty('sampleId')) {
            object.sampleId = message.sampleId;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (message.events && message.events.length) {
            object.events = new Array(message.events.length);
            for (let i = 0; i < message.events.length; ++i) {
              object.events[i] = $root.vital.v1.OrderEvent.toObject(
                message.events[i],
                options
              );
            }
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.Order';
        }
      }

      Order.prototype.id = '';
      Order.prototype.userId = '';
      Order.prototype.labTest = null;
      Order.prototype.details = null;
      Order.prototype.notes = null;
      Order.prototype.patientDetails = null;
      Order.prototype.physician = null;
      Order.prototype.isLabcorpStatOrder = false;
      Order.prototype.sampleId = null;
      Order.prototype.status = null;
      Order.prototype.events = $util.emptyArray;
      Order.prototype.createdAt = null;
      Order.prototype.updatedAt = null;

      return Order;
    })();

    v1.OrderLabTest = (() => {
      class OrderLabTest {
        constructor(properties) {
          this.markers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderLabTest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.slug != null &&
            Object.hasOwnProperty.call(message, 'slug')
          ) {
            writer.uint32(26).string(message.slug);
          }
          if (
            message.sampleType != null &&
            Object.hasOwnProperty.call(message, 'sampleType')
          ) {
            writer.uint32(34).string(message.sampleType);
          }
          if (
            message.isActive != null &&
            Object.hasOwnProperty.call(message, 'isActive')
          ) {
            writer.uint32(40).bool(message.isActive);
          }
          if (
            message.isDelegated != null &&
            Object.hasOwnProperty.call(message, 'isDelegated')
          ) {
            writer.uint32(48).bool(message.isDelegated);
          }
          if (
            message.lab != null &&
            Object.hasOwnProperty.call(message, 'lab')
          ) {
            $root.vital.v1.OrderLabTestLab.encode(
              message.lab,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.markers != null &&
            Object.hasOwnProperty.call(message, 'markers')
          ) {
            for (const element of message.markers) {
              $root.vital.v1.OrderLabTestMarker.encode(
                element,
                writer.uint32(66).fork()
              ).ldelim();
            }
          }
          if (
            message.method != null &&
            Object.hasOwnProperty.call(message, 'method')
          ) {
            writer.uint32(74).string(message.method);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderLabTest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.slug = reader.string();
                break;
              }
              case 4: {
                message.sampleType = reader.string();
                break;
              }
              case 5: {
                message.isActive = reader.bool();
                break;
              }
              case 6: {
                message.isDelegated = reader.bool();
                break;
              }
              case 7: {
                message.lab = $root.vital.v1.OrderLabTestLab.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                if (!message.markers || !message.markers.length) {
                  message.markers = [];
                }
                message.markers.push(
                  $root.vital.v1.OrderLabTestMarker.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 9: {
                message.method = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderLabTest) {
            return object;
          }
          const message = new $root.vital.v1.OrderLabTest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.slug != null) {
            message.slug = String(object.slug);
          }
          if (object.sampleType != null) {
            message.sampleType = String(object.sampleType);
          }
          if (object.isActive != null) {
            message.isActive = Boolean(object.isActive);
          }
          if (object.isDelegated != null) {
            message.isDelegated = Boolean(object.isDelegated);
          }
          if (object.lab != null) {
            if (typeof object.lab !== 'object') {
              throw new TypeError(
                '.vital.v1.OrderLabTest.lab: object expected, but got ' +
                  typeof object.lab
              );
            }
            message.lab = $root.vital.v1.OrderLabTestLab.fromObject(object.lab);
          }
          if (object.markers) {
            if (!Array.isArray(object.markers)) {
              throw new TypeError(
                '.vital.v1.OrderLabTest.markers: array type expected, but got ' +
                  typeof object.markers
              );
            }
            message.markers = new Array(object.markers.length);
            for (let i = 0; i < object.markers.length; ++i) {
              if (typeof object.markers[i] !== 'object') {
                throw new TypeError(
                  '.vital.v1.OrderLabTest.markers: object expected, but got ' +
                    typeof object.markers[i]
                );
              }
              message.markers[i] = $root.vital.v1.OrderLabTestMarker.fromObject(
                object.markers[i]
              );
            }
          }
          if (object.method != null) {
            message.method = String(object.method);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.markers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.slug = '';
            object.isActive = false;
            object.isDelegated = false;
            object.method = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.slug != null && message.hasOwnProperty('slug')) {
            object.slug = message.slug;
          }
          if (
            message.sampleType != null &&
            message.hasOwnProperty('sampleType')
          ) {
            object.sampleType = message.sampleType;
          }
          if (message.isActive != null && message.hasOwnProperty('isActive')) {
            object.isActive = message.isActive;
          }
          if (
            message.isDelegated != null &&
            message.hasOwnProperty('isDelegated')
          ) {
            object.isDelegated = message.isDelegated;
          }
          if (message.lab != null && message.hasOwnProperty('lab')) {
            object.lab = $root.vital.v1.OrderLabTestLab.toObject(
              message.lab,
              options
            );
          }
          if (message.markers && message.markers.length) {
            object.markers = new Array(message.markers.length);
            for (let i = 0; i < message.markers.length; ++i) {
              object.markers[i] = $root.vital.v1.OrderLabTestMarker.toObject(
                message.markers[i],
                options
              );
            }
          }
          if (message.method != null && message.hasOwnProperty('method')) {
            object.method = message.method;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderLabTest';
        }
      }

      OrderLabTest.prototype.id = '';
      OrderLabTest.prototype.name = '';
      OrderLabTest.prototype.slug = '';
      OrderLabTest.prototype.sampleType = null;
      OrderLabTest.prototype.isActive = false;
      OrderLabTest.prototype.isDelegated = false;
      OrderLabTest.prototype.lab = null;
      OrderLabTest.prototype.markers = $util.emptyArray;
      OrderLabTest.prototype.method = '';

      return OrderLabTest;
    })();

    v1.OrderLabTestLab = (() => {
      class OrderLabTestLab {
        constructor(properties) {
          this.sampleTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderLabTestLab(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(8).int64(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.slug != null &&
            Object.hasOwnProperty.call(message, 'slug')
          ) {
            writer.uint32(26).string(message.slug);
          }
          if (
            message.sampleTypes != null &&
            Object.hasOwnProperty.call(message, 'sampleTypes')
          ) {
            for (const element of message.sampleTypes) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.firstLineAddress != null &&
            Object.hasOwnProperty.call(message, 'firstLineAddress')
          ) {
            writer.uint32(42).string(message.firstLineAddress);
          }
          if (
            message.city != null &&
            Object.hasOwnProperty.call(message, 'city')
          ) {
            writer.uint32(50).string(message.city);
          }
          if (
            message.zipcode != null &&
            Object.hasOwnProperty.call(message, 'zipcode')
          ) {
            writer.uint32(58).string(message.zipcode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderLabTestLab();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.int64();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.slug = reader.string();
                break;
              }
              case 4: {
                if (!message.sampleTypes || !message.sampleTypes.length) {
                  message.sampleTypes = [];
                }
                message.sampleTypes.push(reader.string());
                break;
              }
              case 5: {
                message.firstLineAddress = reader.string();
                break;
              }
              case 6: {
                message.city = reader.string();
                break;
              }
              case 7: {
                message.zipcode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderLabTestLab) {
            return object;
          }
          const message = new $root.vital.v1.OrderLabTestLab();
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.slug != null) {
            message.slug = String(object.slug);
          }
          if (object.sampleTypes) {
            if (!Array.isArray(object.sampleTypes)) {
              throw new TypeError(
                '.vital.v1.OrderLabTestLab.sampleTypes: array type expected, but got ' +
                  typeof object.sampleTypes
              );
            }
            message.sampleTypes = new Array(object.sampleTypes.length);
            for (let i = 0; i < object.sampleTypes.length; ++i) {
              message.sampleTypes[i] = String(object.sampleTypes[i]);
            }
          }
          if (object.firstLineAddress != null) {
            message.firstLineAddress = String(object.firstLineAddress);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.zipcode != null) {
            message.zipcode = String(object.zipcode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sampleTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.name = '';
            object.slug = '';
            object.firstLineAddress = '';
            object.city = '';
            object.zipcode = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.slug != null && message.hasOwnProperty('slug')) {
            object.slug = message.slug;
          }
          if (message.sampleTypes && message.sampleTypes.length) {
            object.sampleTypes = new Array(message.sampleTypes.length);
            for (let i = 0; i < message.sampleTypes.length; ++i) {
              object.sampleTypes[i] = message.sampleTypes[i];
            }
          }
          if (
            message.firstLineAddress != null &&
            message.hasOwnProperty('firstLineAddress')
          ) {
            object.firstLineAddress = message.firstLineAddress;
          }
          if (message.city != null && message.hasOwnProperty('city')) {
            object.city = message.city;
          }
          if (message.zipcode != null && message.hasOwnProperty('zipcode')) {
            object.zipcode = message.zipcode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderLabTestLab';
        }
      }

      OrderLabTestLab.prototype.id = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      OrderLabTestLab.prototype.name = '';
      OrderLabTestLab.prototype.slug = '';
      OrderLabTestLab.prototype.sampleTypes = $util.emptyArray;
      OrderLabTestLab.prototype.firstLineAddress = '';
      OrderLabTestLab.prototype.city = '';
      OrderLabTestLab.prototype.zipcode = '';

      return OrderLabTestLab;
    })();

    v1.OrderLabTestMarker = (() => {
      class OrderLabTestMarker {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderLabTestMarker(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(8).int64(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.slug != null &&
            Object.hasOwnProperty.call(message, 'slug')
          ) {
            writer.uint32(26).string(message.slug);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(34).string(message.type);
          }
          if (
            message.unit != null &&
            Object.hasOwnProperty.call(message, 'unit')
          ) {
            writer.uint32(42).string(message.unit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderLabTestMarker();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.int64();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.slug = reader.string();
                break;
              }
              case 4: {
                message.type = reader.string();
                break;
              }
              case 5: {
                message.unit = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderLabTestMarker) {
            return object;
          }
          const message = new $root.vital.v1.OrderLabTestMarker();
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.slug != null) {
            message.slug = String(object.slug);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.unit != null) {
            message.unit = String(object.unit);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.name = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.slug != null && message.hasOwnProperty('slug')) {
            object.slug = message.slug;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (message.unit != null && message.hasOwnProperty('unit')) {
            object.unit = message.unit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderLabTestMarker';
        }
      }

      OrderLabTestMarker.prototype.id = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      OrderLabTestMarker.prototype.name = '';
      OrderLabTestMarker.prototype.slug = null;
      OrderLabTestMarker.prototype.type = null;
      OrderLabTestMarker.prototype.unit = null;

      return OrderLabTestMarker;
    })();

    v1.OrderDetails = (() => {
      class OrderDetails {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderDetails(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(18).string(message.type);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderDetails();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.type = reader.string();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderDetails) {
            return object;
          }
          const message = new $root.vital.v1.OrderDetails();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.vital.v1.OrderDetails.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.vital.v1.OrderDetails.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.type = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderDetails';
        }
      }

      OrderDetails.prototype.id = '';
      OrderDetails.prototype.type = '';
      OrderDetails.prototype.createdAt = null;
      OrderDetails.prototype.updatedAt = null;

      return OrderDetails;
    })();

    v1.OrderPatientDetails = (() => {
      class OrderPatientDetails {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderPatientDetails(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(10).string(message.email);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(18).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(26).string(message.lastName);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(34).string(message.phoneNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderPatientDetails();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.email = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderPatientDetails) {
            return object;
          }
          const message = new $root.vital.v1.OrderPatientDetails();
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderPatientDetails';
        }
      }

      OrderPatientDetails.prototype.email = null;
      OrderPatientDetails.prototype.firstName = null;
      OrderPatientDetails.prototype.lastName = null;
      OrderPatientDetails.prototype.phoneNumber = null;

      return OrderPatientDetails;
    })();

    v1.OrderPhysician = (() => {
      class OrderPhysician {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderPhysician(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(10).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(18).string(message.lastName);
          }
          if (
            message.npi != null &&
            Object.hasOwnProperty.call(message, 'npi')
          ) {
            writer.uint32(26).string(message.npi);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderPhysician();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.firstName = reader.string();
                break;
              }
              case 2: {
                message.lastName = reader.string();
                break;
              }
              case 3: {
                message.npi = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderPhysician) {
            return object;
          }
          const message = new $root.vital.v1.OrderPhysician();
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.npi != null) {
            message.npi = String(object.npi);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.firstName = '';
            object.lastName = '';
            object.npi = '';
          }
          let keys;
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.npi != null && message.hasOwnProperty('npi')) {
            object.npi = message.npi;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderPhysician';
        }
      }

      OrderPhysician.prototype.firstName = '';
      OrderPhysician.prototype.lastName = '';
      OrderPhysician.prototype.npi = '';

      return OrderPhysician;
    })();

    v1.OrderEvent = (() => {
      class OrderEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(8).int64(message.id);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(26).string(message.status);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.int64();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.status = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.OrderEvent) {
            return object;
          }
          const message = new $root.vital.v1.OrderEvent();
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.vital.v1.OrderEvent.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.createdAt = null;
            object.status = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.OrderEvent';
        }
      }

      OrderEvent.prototype.id = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      OrderEvent.prototype.createdAt = null;
      OrderEvent.prototype.status = '';

      return OrderEvent;
    })();

    v1.LabResults = (() => {
      class LabResults {
        constructor(properties) {
          this.labResults = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LabResults(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.cliaNumber != null &&
            Object.hasOwnProperty.call(message, 'cliaNumber')
          ) {
            writer.uint32(10).string(message.cliaNumber);
          }
          if (
            message.laboratory != null &&
            Object.hasOwnProperty.call(message, 'laboratory')
          ) {
            writer.uint32(18).string(message.laboratory);
          }
          if (
            message.dateCollected != null &&
            Object.hasOwnProperty.call(message, 'dateCollected')
          ) {
            timing$1.v1.Timestamp.encode(
              message.dateCollected,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.dateReceived != null &&
            Object.hasOwnProperty.call(message, 'dateReceived')
          ) {
            timing$1.v1.Timestamp.encode(
              message.dateReceived,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.dateReported != null &&
            Object.hasOwnProperty.call(message, 'dateReported')
          ) {
            timing$1.v1.Timestamp.encode(
              message.dateReported,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.interpretation != null &&
            Object.hasOwnProperty.call(message, 'interpretation')
          ) {
            writer.uint32(50).string(message.interpretation);
          }
          if (
            message.patient != null &&
            Object.hasOwnProperty.call(message, 'patient')
          ) {
            writer.uint32(58).string(message.patient);
          }
          if (
            message.provider != null &&
            Object.hasOwnProperty.call(message, 'provider')
          ) {
            writer.uint32(66).string(message.provider);
          }
          if (
            message.specimenNumber != null &&
            Object.hasOwnProperty.call(message, 'specimenNumber')
          ) {
            writer.uint32(74).string(message.specimenNumber);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(82).string(message.status);
          }
          if (
            message.labResults != null &&
            Object.hasOwnProperty.call(message, 'labResults')
          ) {
            for (const element of message.labResults) {
              $root.vital.v1.LabResult.encode(
                element,
                writer.uint32(90).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LabResults();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.cliaNumber = reader.string();
                break;
              }
              case 2: {
                message.laboratory = reader.string();
                break;
              }
              case 3: {
                message.dateCollected = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.dateReceived = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.dateReported = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.interpretation = reader.string();
                break;
              }
              case 7: {
                message.patient = reader.string();
                break;
              }
              case 8: {
                message.provider = reader.string();
                break;
              }
              case 9: {
                message.specimenNumber = reader.string();
                break;
              }
              case 10: {
                message.status = reader.string();
                break;
              }
              case 11: {
                if (!message.labResults || !message.labResults.length) {
                  message.labResults = [];
                }
                message.labResults.push(
                  $root.vital.v1.LabResult.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.LabResults) {
            return object;
          }
          const message = new $root.vital.v1.LabResults();
          if (object.cliaNumber != null) {
            message.cliaNumber = String(object.cliaNumber);
          }
          if (object.laboratory != null) {
            message.laboratory = String(object.laboratory);
          }
          if (object.dateCollected != null) {
            if (typeof object.dateCollected !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResults.dateCollected: object expected, but got ' +
                  typeof object.dateCollected
              );
            }
            message.dateCollected = timing$1.v1.Timestamp.fromObject(
              object.dateCollected
            );
          }
          if (object.dateReceived != null) {
            if (typeof object.dateReceived !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResults.dateReceived: object expected, but got ' +
                  typeof object.dateReceived
              );
            }
            message.dateReceived = timing$1.v1.Timestamp.fromObject(
              object.dateReceived
            );
          }
          if (object.dateReported != null) {
            if (typeof object.dateReported !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResults.dateReported: object expected, but got ' +
                  typeof object.dateReported
              );
            }
            message.dateReported = timing$1.v1.Timestamp.fromObject(
              object.dateReported
            );
          }
          if (object.interpretation != null) {
            message.interpretation = String(object.interpretation);
          }
          if (object.patient != null) {
            message.patient = String(object.patient);
          }
          if (object.provider != null) {
            message.provider = String(object.provider);
          }
          if (object.specimenNumber != null) {
            message.specimenNumber = String(object.specimenNumber);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.labResults) {
            if (!Array.isArray(object.labResults)) {
              throw new TypeError(
                '.vital.v1.LabResults.labResults: array type expected, but got ' +
                  typeof object.labResults
              );
            }
            message.labResults = new Array(object.labResults.length);
            for (let i = 0; i < object.labResults.length; ++i) {
              if (typeof object.labResults[i] !== 'object') {
                throw new TypeError(
                  '.vital.v1.LabResults.labResults: object expected, but got ' +
                    typeof object.labResults[i]
                );
              }
              message.labResults[i] = $root.vital.v1.LabResult.fromObject(
                object.labResults[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.labResults = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.dateReported = null;
            object.patient = '';
            object.specimenNumber = '';
          }
          let keys;
          if (
            message.cliaNumber != null &&
            message.hasOwnProperty('cliaNumber')
          ) {
            object.cliaNumber = message.cliaNumber;
          }
          if (
            message.laboratory != null &&
            message.hasOwnProperty('laboratory')
          ) {
            object.laboratory = message.laboratory;
          }
          if (
            message.dateCollected != null &&
            message.hasOwnProperty('dateCollected')
          ) {
            object.dateCollected = timing$1.v1.Timestamp.toObject(
              message.dateCollected,
              options
            );
          }
          if (
            message.dateReceived != null &&
            message.hasOwnProperty('dateReceived')
          ) {
            object.dateReceived = timing$1.v1.Timestamp.toObject(
              message.dateReceived,
              options
            );
          }
          if (
            message.dateReported != null &&
            message.hasOwnProperty('dateReported')
          ) {
            object.dateReported = timing$1.v1.Timestamp.toObject(
              message.dateReported,
              options
            );
          }
          if (
            message.interpretation != null &&
            message.hasOwnProperty('interpretation')
          ) {
            object.interpretation = message.interpretation;
          }
          if (message.patient != null && message.hasOwnProperty('patient')) {
            object.patient = message.patient;
          }
          if (message.provider != null && message.hasOwnProperty('provider')) {
            object.provider = message.provider;
          }
          if (
            message.specimenNumber != null &&
            message.hasOwnProperty('specimenNumber')
          ) {
            object.specimenNumber = message.specimenNumber;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (message.labResults && message.labResults.length) {
            object.labResults = new Array(message.labResults.length);
            for (let i = 0; i < message.labResults.length; ++i) {
              object.labResults[i] = $root.vital.v1.LabResult.toObject(
                message.labResults[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.LabResults';
        }
      }

      LabResults.prototype.cliaNumber = null;
      LabResults.prototype.laboratory = null;
      LabResults.prototype.dateCollected = null;
      LabResults.prototype.dateReceived = null;
      LabResults.prototype.dateReported = null;
      LabResults.prototype.interpretation = null;
      LabResults.prototype.patient = '';
      LabResults.prototype.provider = null;
      LabResults.prototype.specimenNumber = '';
      LabResults.prototype.status = null;
      LabResults.prototype.labResults = $util.emptyArray;

      return LabResults;
    })();

    v1.LabResult = (() => {
      class LabResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LabResult(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.slug != null &&
            Object.hasOwnProperty.call(message, 'slug')
          ) {
            writer.uint32(18).string(message.slug);
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(26).string(message.notes);
          }
          if (
            message.timestamp != null &&
            Object.hasOwnProperty.call(message, 'timestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timestamp,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.unit != null &&
            Object.hasOwnProperty.call(message, 'unit')
          ) {
            writer.uint32(50).string(message.unit);
          }
          if (
            message.interpretation != null &&
            Object.hasOwnProperty.call(message, 'interpretation')
          ) {
            writer.uint32(58).string(message.interpretation);
          }
          if (
            message.isBelowMinRange != null &&
            Object.hasOwnProperty.call(message, 'isBelowMinRange')
          ) {
            writer.uint32(64).bool(message.isBelowMinRange);
          }
          if (
            message.isAboveMaxRange != null &&
            Object.hasOwnProperty.call(message, 'isAboveMaxRange')
          ) {
            writer.uint32(72).bool(message.isAboveMaxRange);
          }
          if (
            message.minRangeValue != null &&
            Object.hasOwnProperty.call(message, 'minRangeValue')
          ) {
            values$1.v1.Value.encode(
              message.minRangeValue,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.maxRangeValue != null &&
            Object.hasOwnProperty.call(message, 'maxRangeValue')
          ) {
            values$1.v1.Value.encode(
              message.maxRangeValue,
              writer.uint32(90).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LabResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.slug = reader.string();
                break;
              }
              case 3: {
                message.notes = reader.string();
                break;
              }
              case 4: {
                message.timestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.unit = reader.string();
                break;
              }
              case 7: {
                message.interpretation = reader.string();
                break;
              }
              case 8: {
                message.isBelowMinRange = reader.bool();
                break;
              }
              case 9: {
                message.isAboveMaxRange = reader.bool();
                break;
              }
              case 10: {
                message.minRangeValue = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 11: {
                message.maxRangeValue = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.LabResult) {
            return object;
          }
          const message = new $root.vital.v1.LabResult();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.slug != null) {
            message.slug = String(object.slug);
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResult.timestamp: object expected, but got ' +
                  typeof object.timestamp
              );
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(
              object.timestamp
            );
          }
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResult.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.unit != null) {
            message.unit = String(object.unit);
          }
          if (object.interpretation != null) {
            message.interpretation = String(object.interpretation);
          }
          if (object.isBelowMinRange != null) {
            message.isBelowMinRange = Boolean(object.isBelowMinRange);
          }
          if (object.isAboveMaxRange != null) {
            message.isAboveMaxRange = Boolean(object.isAboveMaxRange);
          }
          if (object.minRangeValue != null) {
            if (typeof object.minRangeValue !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResult.minRangeValue: object expected, but got ' +
                  typeof object.minRangeValue
              );
            }
            message.minRangeValue = values$1.v1.Value.fromObject(
              object.minRangeValue
            );
          }
          if (object.maxRangeValue != null) {
            if (typeof object.maxRangeValue !== 'object') {
              throw new TypeError(
                '.vital.v1.LabResult.maxRangeValue: object expected, but got ' +
                  typeof object.maxRangeValue
              );
            }
            message.maxRangeValue = values$1.v1.Value.fromObject(
              object.maxRangeValue
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.value = null;
            object.isBelowMinRange = false;
            object.isAboveMaxRange = false;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.slug != null && message.hasOwnProperty('slug')) {
            object.slug = message.slug;
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (
            message.timestamp != null &&
            message.hasOwnProperty('timestamp')
          ) {
            object.timestamp = timing$1.v1.Timestamp.toObject(
              message.timestamp,
              options
            );
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (message.unit != null && message.hasOwnProperty('unit')) {
            object.unit = message.unit;
          }
          if (
            message.interpretation != null &&
            message.hasOwnProperty('interpretation')
          ) {
            object.interpretation = message.interpretation;
          }
          if (
            message.isBelowMinRange != null &&
            message.hasOwnProperty('isBelowMinRange')
          ) {
            object.isBelowMinRange = message.isBelowMinRange;
          }
          if (
            message.isAboveMaxRange != null &&
            message.hasOwnProperty('isAboveMaxRange')
          ) {
            object.isAboveMaxRange = message.isAboveMaxRange;
          }
          if (
            message.minRangeValue != null &&
            message.hasOwnProperty('minRangeValue')
          ) {
            object.minRangeValue = values$1.v1.Value.toObject(
              message.minRangeValue,
              options
            );
          }
          if (
            message.maxRangeValue != null &&
            message.hasOwnProperty('maxRangeValue')
          ) {
            object.maxRangeValue = values$1.v1.Value.toObject(
              message.maxRangeValue,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.LabResult';
        }
      }

      LabResult.prototype.name = '';
      LabResult.prototype.slug = null;
      LabResult.prototype.notes = null;
      LabResult.prototype.timestamp = null;
      LabResult.prototype.value = null;
      LabResult.prototype.unit = null;
      LabResult.prototype.interpretation = null;
      LabResult.prototype.isBelowMinRange = false;
      LabResult.prototype.isAboveMaxRange = false;
      LabResult.prototype.minRangeValue = null;
      LabResult.prototype.maxRangeValue = null;

      return LabResult;
    })();

    v1.ListOrdersRequest = (() => {
      class ListOrdersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.ListOrdersRequest) {
            return object;
          }
          const message = new $root.vital.v1.ListOrdersRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.vital.v1.ListOrdersRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'STAGING':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.vital.v1.VitalEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.vital.v1.VitalEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.ListOrdersRequest';
        }
      }

      ListOrdersRequest.prototype.page = null;
      ListOrdersRequest.prototype.environment = 0;

      return ListOrdersRequest;
    })();

    v1.ListOrdersResponse = (() => {
      class ListOrdersResponse {
        constructor(properties) {
          this.orders = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.orders != null &&
            Object.hasOwnProperty.call(message, 'orders')
          ) {
            for (const element of message.orders) {
              $root.vital.v1.Order.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.orders || !message.orders.length) {
                  message.orders = [];
                }
                message.orders.push(
                  $root.vital.v1.Order.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.ListOrdersResponse) {
            return object;
          }
          const message = new $root.vital.v1.ListOrdersResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.orders) {
            if (!Array.isArray(object.orders)) {
              throw new TypeError(
                '.vital.v1.ListOrdersResponse.orders: array type expected, but got ' +
                  typeof object.orders
              );
            }
            message.orders = new Array(object.orders.length);
            for (let i = 0; i < object.orders.length; ++i) {
              if (typeof object.orders[i] !== 'object') {
                throw new TypeError(
                  '.vital.v1.ListOrdersResponse.orders: object expected, but got ' +
                    typeof object.orders[i]
                );
              }
              message.orders[i] = $root.vital.v1.Order.fromObject(
                object.orders[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.orders = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.orders && message.orders.length) {
            object.orders = new Array(message.orders.length);
            for (let i = 0; i < message.orders.length; ++i) {
              object.orders[i] = $root.vital.v1.Order.toObject(
                message.orders[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.ListOrdersResponse';
        }
      }

      ListOrdersResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListOrdersResponse.prototype.orders = $util.emptyArray;

      return ListOrdersResponse;
    })();

    v1.GetOrderRequest = (() => {
      class GetOrderRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrderRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrderRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.GetOrderRequest) {
            return object;
          }
          const message = new $root.vital.v1.GetOrderRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'STAGING':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.vital.v1.VitalEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.vital.v1.VitalEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.GetOrderRequest';
        }
      }

      GetOrderRequest.prototype.id = '';
      GetOrderRequest.prototype.environment = 0;

      return GetOrderRequest;
    })();

    v1.GetOrderResponse = (() => {
      class GetOrderResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrderResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.order != null &&
            Object.hasOwnProperty.call(message, 'order')
          ) {
            $root.vital.v1.Order.encode(
              message.order,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrderResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.order = $root.vital.v1.Order.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.GetOrderResponse) {
            return object;
          }
          const message = new $root.vital.v1.GetOrderResponse();
          if (object.order != null) {
            if (typeof object.order !== 'object') {
              throw new TypeError(
                '.vital.v1.GetOrderResponse.order: object expected, but got ' +
                  typeof object.order
              );
            }
            message.order = $root.vital.v1.Order.fromObject(object.order);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.order = null;
          }
          let keys;
          if (message.order != null && message.hasOwnProperty('order')) {
            object.order = $root.vital.v1.Order.toObject(
              message.order,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.GetOrderResponse';
        }
      }

      GetOrderResponse.prototype.order = null;

      return GetOrderResponse;
    })();

    v1.GetLabResultsRequest = (() => {
      class GetLabResultsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetLabResultsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.orderId != null &&
            Object.hasOwnProperty.call(message, 'orderId')
          ) {
            writer.uint32(10).string(message.orderId);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetLabResultsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orderId = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.GetLabResultsRequest) {
            return object;
          }
          const message = new $root.vital.v1.GetLabResultsRequest();
          if (object.orderId != null) {
            message.orderId = String(object.orderId);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'STAGING':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orderId = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.orderId != null && message.hasOwnProperty('orderId')) {
            object.orderId = message.orderId;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.vital.v1.VitalEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.vital.v1.VitalEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.GetLabResultsRequest';
        }
      }

      GetLabResultsRequest.prototype.orderId = '';
      GetLabResultsRequest.prototype.environment = 0;

      return GetLabResultsRequest;
    })();

    v1.GetLabResultsResponse = (() => {
      class GetLabResultsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetLabResultsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.labResults != null &&
            Object.hasOwnProperty.call(message, 'labResults')
          ) {
            $root.vital.v1.LabResults.encode(
              message.labResults,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetLabResultsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labResults = $root.vital.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.vital.v1.GetLabResultsResponse) {
            return object;
          }
          const message = new $root.vital.v1.GetLabResultsResponse();
          if (object.labResults != null) {
            if (typeof object.labResults !== 'object') {
              throw new TypeError(
                '.vital.v1.GetLabResultsResponse.labResults: object expected, but got ' +
                  typeof object.labResults
              );
            }
            message.labResults = $root.vital.v1.LabResults.fromObject(
              object.labResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.labResults = null;
          }
          let keys;
          if (
            message.labResults != null &&
            message.hasOwnProperty('labResults')
          ) {
            object.labResults = $root.vital.v1.LabResults.toObject(
              message.labResults,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'vital.v1.GetLabResultsResponse';
        }
      }

      GetLabResultsResponse.prototype.labResults = null;

      return GetLabResultsResponse;
    })();

    v1.VitalEnvironment = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNDEFINED_ILLEGAL')] = 0;
      values[(valuesById[1] = 'STAGING')] = 1;
      values[(valuesById[2] = 'PRODUCTION')] = 2;
      return values;
    })();

    v1.VitalEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_VITAL_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'VITAL_LAB_ORDER_STATUS_UPDATE')] = 1;
      values[(valuesById[2] = 'VITAL_LAB_ORDER_RESULTS_UPDATE')] = 2;
      return values;
    })();

    v1.VitalService = (() => {
      class VitalService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new VitalService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(
        (VitalService.prototype.listOrders = function listOrders(
          request,
          callback
        ) {
          return this.rpcCall(
            listOrders,
            $root.vital.v1.ListOrdersRequest,
            $root.vital.v1.ListOrdersResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListOrders' }
      );

      Object.defineProperty(
        (VitalService.prototype.getOrder = function getOrder(
          request,
          callback
        ) {
          return this.rpcCall(
            getOrder,
            $root.vital.v1.GetOrderRequest,
            $root.vital.v1.GetOrderResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetOrder' }
      );

      Object.defineProperty(
        (VitalService.prototype.getLabResults = function getLabResults(
          request,
          callback
        ) {
          return this.rpcCall(
            getLabResults,
            $root.vital.v1.GetLabResultsRequest,
            $root.vital.v1.GetLabResultsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetLabResults' }
      );

      return VitalService;
    })();

    return v1;
  })();

  return vital;
})();

export const vital = $root.vital;
