import { Dropdown } from '../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { RequiredIndicator } from '../../RequiredIndicator';
import { RequiredThirdPartyIdMissingProps } from './types';
import { Text } from '../../Typography';
import { formatEnumToOptions } from '../../Helpers/formatEnumToOptions';
import { labelMap } from '../ParameterMissingPolicy/RequiredParameterMissing/constants';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const RequiredThirdPartyIdMissing: FC<RequiredThirdPartyIdMissingProps> = ({
  isReadOnly,
  onRequiredThirdPartyIdMissingChange,
  requiredThirdPartyIdMissingAction,
}) => {
  const theme = useTheme();

  const requiredThirdPartyIdMissingActions = formatEnumToOptions(
    workflow_parameters.v1.ParameterMissingPolicy
      .RequiredParameterMissingBehavior
  ).map((option) =>
    labelMap[option.label]
      ? { ...option, label: labelMap[option.label] }
      : option
  );

  const requiredThirdPartyIdMissingAsOption =
    requiredThirdPartyIdMissingActions.filter(
      (fieldNotPresentAction) =>
        fieldNotPresentAction.value ===
        requiredThirdPartyIdMissingAction?.toString()
    );

  return (
    <Flexbox
      data-testid='required-third-party-id-missing'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.25}
    >
      <Flexbox direction='row' height='auto'>
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={0}
        >
          <Text tag='p2'>If profile does not exist</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select what should happen.
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={requiredThirdPartyIdMissingAsOption}
          options={requiredThirdPartyIdMissingActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onRequiredThirdPartyIdMissingChange(selectedOptionNumber);
          }}
          readOnly={isReadOnly}
          clearable
          height='2.25rem'
        />
      </Flexbox>
      {!requiredThirdPartyIdMissingAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedRequiredThirdPartyIdMissing = memo(
  RequiredThirdPartyIdMissing
);
