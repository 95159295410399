import { Button } from '../../Button';
import { Container } from '../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { RemoveNodeModalProps } from './types';
import { Text } from '../../Typography';
import { WrapperModal } from '../../WrapperModal';
import { useTheme } from '@morf/theming';

const RemoveNodeModal: FC<RemoveNodeModalProps> = ({
  isOpen,
  onClose,
  onRemoveNode,
  type,
}) => {
  const theme = useTheme();

  const handleRemove = () => {
    onRemoveNode();
    onClose();
  };

  return (
    <WrapperModal isOpen={isOpen}>
      <Flexbox
        data-testid='remove-node-modal'
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        width='21.25rem'
        p={1.5}
      >
        <Flexbox
          direction='column'
          justifyContent='space-between'
          alignItems='center'
          gap={0.5}
        >
          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Container>
              <Text tag='h4' align='center'>
                Remove {type}?
              </Text>
            </Container>
            <Icon
              name='close'
              stroke={theme.colors.ui.dark}
              strokeWidth={1.75}
              size={1.25}
              onClick={onClose}
              cursor='pointer'
            />
          </Flexbox>
          <Text tag='p2' align='center'>
            Are you sure you want to remove this {type} from the workflow?
          </Text>
        </Flexbox>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.5}
        >
          <Button
            variant='custom'
            backgroundColor={theme.colors.ui.card}
            borderColor={theme.colors.ui.divider}
            color={theme.colors.main.primary.darker}
            onClick={onClose}
            width='100%'
          >
            <Text tag='h5' color='inherit'>
              Cancel
            </Text>
          </Button>
          <Button
            variant='custom'
            backgroundColor={theme.colors.support.red.darkest}
            borderColor={theme.colors.support.red.darkest}
            color={theme.colors.text.inverse}
            onClick={handleRemove}
            width='100%'
          >
            <Text tag='h5' color='inherit'>
              Remove
            </Text>
          </Button>
        </Flexbox>
      </Flexbox>
    </WrapperModal>
  );
};

export const MemoizedRemoveNodeModal = memo(RemoveNodeModal);
