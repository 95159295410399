import { useCallback } from 'react';
import { useRouter } from 'next/router';

export const useUpdateUrlParam = () => {
  const router = useRouter();

  const updateUrlParams = useCallback(
    (params: { [key: string]: string | string[] | null }) => {
      const currentQuery = { ...router.query };

      for (const [key, value] of Object.entries(params)) {
        if (value === null) {
          delete currentQuery[key];
        } else if (Array.isArray(value)) {
          currentQuery[key] = value;
        } else {
          currentQuery[key] = value;
        }
      }

      router.push(
        {
          pathname: router.pathname,
          query: currentQuery,
        },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  return updateUrlParams;
};
