'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.hubspot = (() => {
  const hubspot = {};
  hubspot.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.hubspot.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.hubspot.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_HUBSPOT_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'HUBSPOT_CONTACT_CREATED':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'HUBSPOT_CONTACT_MERGED':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                case 'HUBSPOT_CONTACT_DELETED':
                case 3: {
                  message.eventTypes[i] = 3;
                  break;
                }
                case 'HUBSPOT_CONTACT_RESTORED':
                case 4: {
                  message.eventTypes[i] = 4;
                  break;
                }
                case 'HUBSPOT_CONTACT_PROPERTIES_UPDATED':
                case 5: {
                  message.eventTypes[i] = 5;
                  break;
                }
                case 'HUBSPOT_CONTACT_ASSOCIATIONS_UPDATED':
                case 6: {
                  message.eventTypes[i] = 6;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.hubspot.v1.HubspotEventType[message.eventTypes[i]] ===
                    undefined
                    ? message.eventTypes[i]
                    : $root.hubspot.v1.HubspotEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Contact = (() => {
      class Contact {
        constructor(properties) {
          this.properties = {};
          this.recentlyUpdatedPropertyNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Contact(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.properties[key])
                .ldelim();
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(24).bool(message.archived);
          }
          if (
            message.archivedAt != null &&
            Object.hasOwnProperty.call(message, 'archivedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.archivedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.recentlyUpdatedPropertyNames != null &&
            Object.hasOwnProperty.call(message, 'recentlyUpdatedPropertyNames')
          ) {
            for (const element of message.recentlyUpdatedPropertyNames) {
              writer.uint32(58).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Contact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              case 3: {
                message.archived = reader.bool();
                break;
              }
              case 4: {
                message.archivedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                if (
                  !message.recentlyUpdatedPropertyNames ||
                  !message.recentlyUpdatedPropertyNames.length
                ) {
                  message.recentlyUpdatedPropertyNames = [];
                }
                message.recentlyUpdatedPropertyNames.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.Contact) {
            return object;
          }
          const message = new $root.hubspot.v1.Contact();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Contact.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              message.properties[keys[i]] = String(object.properties[keys[i]]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.archivedAt != null) {
            if (typeof object.archivedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Contact.archivedAt: object expected, but got ' +
                  typeof object.archivedAt
              );
            }
            message.archivedAt = timing$1.v1.Timestamp.fromObject(
              object.archivedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Contact.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Contact.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.recentlyUpdatedPropertyNames) {
            if (!Array.isArray(object.recentlyUpdatedPropertyNames)) {
              throw new TypeError(
                '.hubspot.v1.Contact.recentlyUpdatedPropertyNames: array type expected, but got ' +
                  typeof object.recentlyUpdatedPropertyNames
              );
            }
            message.recentlyUpdatedPropertyNames = new Array(
              object.recentlyUpdatedPropertyNames.length
            );
            for (
              let i = 0;
              i < object.recentlyUpdatedPropertyNames.length;
              ++i
            ) {
              message.recentlyUpdatedPropertyNames[i] = String(
                object.recentlyUpdatedPropertyNames[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.recentlyUpdatedPropertyNames = [];
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = message.properties[keys[i]];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.archivedAt != null &&
            message.hasOwnProperty('archivedAt')
          ) {
            object.archivedAt = timing$1.v1.Timestamp.toObject(
              message.archivedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.recentlyUpdatedPropertyNames &&
            message.recentlyUpdatedPropertyNames.length
          ) {
            object.recentlyUpdatedPropertyNames = new Array(
              message.recentlyUpdatedPropertyNames.length
            );
            for (
              let i = 0;
              i < message.recentlyUpdatedPropertyNames.length;
              ++i
            ) {
              object.recentlyUpdatedPropertyNames[i] =
                message.recentlyUpdatedPropertyNames[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.Contact';
        }
      }

      Contact.prototype.id = '';
      Contact.prototype.properties = $util.emptyObject;
      Contact.prototype.archived = false;
      Contact.prototype.archivedAt = null;
      Contact.prototype.createdAt = null;
      Contact.prototype.updatedAt = null;
      Contact.prototype.recentlyUpdatedPropertyNames = $util.emptyArray;

      return Contact;
    })();

    v1.User = (() => {
      class User {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new User(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.properties[key])
                .ldelim();
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(24).bool(message.archived);
          }
          if (
            message.archivedAt != null &&
            Object.hasOwnProperty.call(message, 'archivedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.archivedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new User();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              case 3: {
                message.archived = reader.bool();
                break;
              }
              case 4: {
                message.archivedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.User) {
            return object;
          }
          const message = new $root.hubspot.v1.User();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.hubspot.v1.User.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              message.properties[keys[i]] = String(object.properties[keys[i]]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.archivedAt != null) {
            if (typeof object.archivedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.User.archivedAt: object expected, but got ' +
                  typeof object.archivedAt
              );
            }
            message.archivedAt = timing$1.v1.Timestamp.fromObject(
              object.archivedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.User.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.User.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = message.properties[keys[i]];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.archivedAt != null &&
            message.hasOwnProperty('archivedAt')
          ) {
            object.archivedAt = timing$1.v1.Timestamp.toObject(
              message.archivedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.User';
        }
      }

      User.prototype.id = '';
      User.prototype.properties = $util.emptyObject;
      User.prototype.archived = false;
      User.prototype.archivedAt = null;
      User.prototype.createdAt = null;
      User.prototype.updatedAt = null;

      return User;
    })();

    v1.Company = (() => {
      class Company {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Company(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.properties[key])
                .ldelim();
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(24).bool(message.archived);
          }
          if (
            message.archivedAt != null &&
            Object.hasOwnProperty.call(message, 'archivedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.archivedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Company();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              case 3: {
                message.archived = reader.bool();
                break;
              }
              case 4: {
                message.archivedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.Company) {
            return object;
          }
          const message = new $root.hubspot.v1.Company();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Company.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              message.properties[keys[i]] = String(object.properties[keys[i]]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.archivedAt != null) {
            if (typeof object.archivedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Company.archivedAt: object expected, but got ' +
                  typeof object.archivedAt
              );
            }
            message.archivedAt = timing$1.v1.Timestamp.fromObject(
              object.archivedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Company.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Company.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = message.properties[keys[i]];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.archivedAt != null &&
            message.hasOwnProperty('archivedAt')
          ) {
            object.archivedAt = timing$1.v1.Timestamp.toObject(
              message.archivedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.Company';
        }
      }

      Company.prototype.id = '';
      Company.prototype.properties = $util.emptyObject;
      Company.prototype.archived = false;
      Company.prototype.archivedAt = null;
      Company.prototype.createdAt = null;
      Company.prototype.updatedAt = null;

      return Company;
    })();

    v1.Ticket = (() => {
      class Ticket {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Ticket(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.properties[key])
                .ldelim();
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(24).bool(message.archived);
          }
          if (
            message.archivedAt != null &&
            Object.hasOwnProperty.call(message, 'archivedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.archivedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Ticket();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              case 3: {
                message.archived = reader.bool();
                break;
              }
              case 4: {
                message.archivedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.Ticket) {
            return object;
          }
          const message = new $root.hubspot.v1.Ticket();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Ticket.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              message.properties[keys[i]] = String(object.properties[keys[i]]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.archivedAt != null) {
            if (typeof object.archivedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Ticket.archivedAt: object expected, but got ' +
                  typeof object.archivedAt
              );
            }
            message.archivedAt = timing$1.v1.Timestamp.fromObject(
              object.archivedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Ticket.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Ticket.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = message.properties[keys[i]];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.archivedAt != null &&
            message.hasOwnProperty('archivedAt')
          ) {
            object.archivedAt = timing$1.v1.Timestamp.toObject(
              message.archivedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.Ticket';
        }
      }

      Ticket.prototype.id = '';
      Ticket.prototype.properties = $util.emptyObject;
      Ticket.prototype.archived = false;
      Ticket.prototype.archivedAt = null;
      Ticket.prototype.createdAt = null;
      Ticket.prototype.updatedAt = null;

      return Ticket;
    })();

    v1.Note = (() => {
      class Note {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Note(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.properties[key])
                .ldelim();
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(24).bool(message.archived);
          }
          if (
            message.archivedAt != null &&
            Object.hasOwnProperty.call(message, 'archivedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.archivedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Note();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              case 3: {
                message.archived = reader.bool();
                break;
              }
              case 4: {
                message.archivedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.hubspot.v1.Note) {
            return object;
          }
          const message = new $root.hubspot.v1.Note();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Note.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              message.properties[keys[i]] = String(object.properties[keys[i]]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.archivedAt != null) {
            if (typeof object.archivedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Note.archivedAt: object expected, but got ' +
                  typeof object.archivedAt
              );
            }
            message.archivedAt = timing$1.v1.Timestamp.fromObject(
              object.archivedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Note.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.hubspot.v1.Note.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = message.properties[keys[i]];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.archivedAt != null &&
            message.hasOwnProperty('archivedAt')
          ) {
            object.archivedAt = timing$1.v1.Timestamp.toObject(
              message.archivedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'hubspot.v1.Note';
        }
      }

      Note.prototype.id = '';
      Note.prototype.properties = $util.emptyObject;
      Note.prototype.archived = false;
      Note.prototype.archivedAt = null;
      Note.prototype.createdAt = null;
      Note.prototype.updatedAt = null;

      return Note;
    })();

    v1.HubspotEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_HUBSPOT_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'HUBSPOT_CONTACT_CREATED')] = 1;
      values[(valuesById[2] = 'HUBSPOT_CONTACT_MERGED')] = 2;
      values[(valuesById[3] = 'HUBSPOT_CONTACT_DELETED')] = 3;
      values[(valuesById[4] = 'HUBSPOT_CONTACT_RESTORED')] = 4;
      values[(valuesById[5] = 'HUBSPOT_CONTACT_PROPERTIES_UPDATED')] = 5;
      values[(valuesById[6] = 'HUBSPOT_CONTACT_ASSOCIATIONS_UPDATED')] = 6;
      return values;
    })();

    return v1;
  })();

  return hubspot;
})();

export const hubspot = $root.hubspot;
