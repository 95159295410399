export const Acuity = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.154 20.7059L20.4476 11.2986C21.079 10.6594 21.9191 10.308 22.8146 10.308C23.7095 10.308 24.5496 10.6591 25.1804 11.2983L25.9045 12.0312L27.3529 10.565L26.6289 9.83206C25.6117 8.80241 24.2572 8.23535 22.814 8.23535C21.3715 8.23569 20.0166 8.80274 18.9994 9.83272L9.70589 19.2397L11.154 20.7059Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5882 14.4233L22.1329 12.9593L12.7957 22.3527C11.4845 23.6718 9.35106 23.6721 8.04018 22.3534C6.72896 21.0343 6.72896 18.8878 8.04018 17.5687L16.0973 9.4637L14.642 8L6.58518 16.105C4.47161 18.2312 4.47161 21.6912 6.58518 23.8171C7.60719 24.8452 8.9681 25.4118 10.4175 25.4118C11.8671 25.4118 13.2287 24.8449 14.2507 23.8168L23.5882 14.4233Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.8263 16.1825C31.8046 15.1544 30.4437 14.5881 28.9937 14.5881C27.544 14.5885 26.1824 15.1547 25.1604 16.1832L15.8235 25.5764L17.2782 27.0405L26.6158 17.6469C27.9273 16.3278 30.0604 16.3281 31.3713 17.6466C32.0054 18.2844 32.3546 19.1336 32.3546 20.0385C32.3546 20.9436 32.0054 21.7931 31.3713 22.431L23.3145 30.5362L24.7698 31.9999L32.8263 23.8951C34.9402 21.7688 34.9402 18.3088 32.8263 16.1825Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.2578 19.2942L18.9644 28.7015C17.659 30.0222 15.536 30.0229 14.2313 28.7019L13.5073 27.9689L12.0588 29.4351L12.7829 30.1681C13.8001 31.1977 15.1545 31.7648 16.598 31.7648C18.0405 31.7644 19.395 31.1974 20.4125 30.1674L29.7059 20.7604L28.2578 19.2942Z'
        fill='black'
      />
    </svg>
  );
};
