import _ from 'lodash';
import rpcService from '../../../../../../server/browser-server';
import { Dropdown } from '../../../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { SkeletonLoadable } from '../../../../../Skeleton';
import { Text } from '../../../../../Typography';
import { TriggerNodeProps } from '../../types';
import { convertToTitleCase } from '../../../../../Helpers/convertToTitleCase';
import { object_category } from '@morf/proto/object_category_v1_ts_proto';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const TriggerObjectCategory: FC<TriggerNodeProps> = (node) => {
  const theme = useTheme();
  const router = useRouter();
  const { organizationId } = router.query;
  const { configuration, onUpdateTrigger, isReadOnly } = node.data.value;

  const {
    data: listObjectTypeIdsResponse,
    isLoading: listObjectTypeIdsResponseIsLoading,
  } = useQuery<workflows.v1.ListObjectTypeIDsResponse>(
    [
      '/workflows.v1.WorkflowsService/ListObjectTypeIDs',
      organizationId,
      configuration?.sourceApplication,
      configuration?.objectCategory,
    ],
    async () => {
      return await rpcService.workflowsV1Service.listObjectTypeIDs({
        application: configuration!.sourceApplication,
        objectCategory: configuration!.objectCategory,
      });
    },
    {
      enabled: !!configuration,
    }
  );

  const objectCategory = configuration?.objectCategory
    ? _.toLower(
        object_category.v1.ObjectCategory.Category[
          configuration?.objectCategory
        ]
      )
    : '';

  const objectTypeIdsAsOptions =
    listObjectTypeIdsResponse?.ids?.map((option) => ({
      label: option.label,
      value: option.id,
    })) || [];

  const selectedObjectTypeIdsAsOptions = configuration?.objectTypeFilters.map(
    (objectTypeFilter) => ({
      label:
        objectTypeIdsAsOptions.find(
          (option) => option.value === objectTypeFilter
        )?.label || objectTypeFilter,
      value: objectTypeFilter,
    })
  );

  const handleNodeSave = (updatedObjectTypeFilters: string[]) => {
    onUpdateTrigger &&
      onUpdateTrigger({
        trigger: new workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger({
          triggerEventTypes: configuration?.triggerEvents,
          triggerExtraCelExpression:
            configuration?.extraFilter?.celExpressionStr,
          triggerObjectCategory: configuration?.objectCategory,
          triggerObjectTypeFilters: updatedObjectTypeFilters,
          uniquenessConflictResolution:
            configuration?.uniquenessConflictResolution,
        }),
      });
  };

  return (
    <Flexbox
      data-testid='trigger-object-category'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0}
      >
        <Text tag='p2'>{convertToTitleCase(objectCategory)} types</Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Select which {objectCategory + 's'} should trigger the workflow.
        </Text>
      </Flexbox>
      {listObjectTypeIdsResponseIsLoading ? (
        <SkeletonLoadable isLoading height='2rem' width='10rem'>
          <></>
        </SkeletonLoadable>
      ) : (
        <Dropdown
          placeholder='All'
          key={listObjectTypeIdsResponseIsLoading.toString()}
          defaultOptions={selectedObjectTypeIdsAsOptions}
          options={objectTypeIdsAsOptions}
          onChange={(selectedOptions) => {
            const selectedValues = selectedOptions.map(
              (option) => option.value
            );
            handleNodeSave(selectedValues);
          }}
          height='2.25rem'
          readOnly={isReadOnly}
          multiSelect
          searchable
          clearable
        />
      )}
    </Flexbox>
  );
};

export const MemoizedTriggerObjectCategory = memo(TriggerObjectCategory);
