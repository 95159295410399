import { Dispatch, SetStateAction } from 'react';
import { Node } from 'reactflow';
import { onUpdateNodeData } from '../../types';
import { validateNode } from './validateNode';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const handleNodeSave = (
  destinationAction: workflows.v1.WorkflowDestinationAction,
  id: string,
  nodes: Node[],
  setNodes: Dispatch<SetStateAction<Node[]>>,
  onUpdateNode?: (data: onUpdateNodeData) => void
) => {
  const updatedNodes = nodes.map((n) => {
    if (n.id === id) {
      return {
        ...n,
        data: {
          ...n.data,
          value: {
            ...n.data.value,
            configuration: destinationAction,
          },
        },
      };
    }
    return n;
  });

  setNodes(updatedNodes);
  const isValidNode = validateNode(destinationAction);

  isValidNode &&
    onUpdateNode &&
    onUpdateNode({
      nodeId: id,
      type: new workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType({
        destinationAction: destinationAction,
      }),
    });
};
