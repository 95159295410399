import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { IssueEventTimelineProps } from './types';
import { TimelineGroupHeader } from '../TimelineGroupHeader';
import { Container } from '../Container';
import { TimelineRow } from '../TimelineRow';

const IssueEventTimeline: FC<IssueEventTimelineProps> = ({
  header,
  events,
}) => {
  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      data-testid='issue-event-timeline'
      height='auto'
      gap={0}
    >
      <TimelineGroupHeader {...header} />
      {header.isExpanded && (
        <Container
          data-testid='timeline-rows'
          borderType='borderBottom'
          py={0.25}
        >
          {events.map((event, index) => (
            <TimelineRow key={index} {...event} />
          ))}
        </Container>
      )}
    </Flexbox>
  );
};

export const MemoizedIssueEventTimeline = memo(IssueEventTimeline);
