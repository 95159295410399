import { Container } from '../Container';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { SearchMessage } from '../SearchMessage';
import { SpotlightSearchProps } from './types';
import { SpotlightSearchRow } from './SpotlightSearchRow';
import { WrapperModal } from '../WrapperModal';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTheme } from '@morf/theming';

const SpotlightSearch: FC<SpotlightSearchProps> = ({
  isOpen,
  onClose,
  rows,
  searchValue,
  setSearchValue,
  setShowSpotlightSearch,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  useHotkeys('/', () => setShowSpotlightSearch(true), {
    preventDefault: true,
  });

  useHotkeys('ESC', () => setShowSpotlightSearch(false), {
    enableOnFormTags: true,
  });

  useHotkeys(
    'down',
    () => {
      setSelectedRowIndex((prevIndex) =>
        prevIndex < rows.length - 1 ? prevIndex + 1 : prevIndex
      );
    },
    {
      enableOnFormTags: true,
    }
  );

  useHotkeys(
    'up',
    () => {
      setSelectedRowIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    },
    {
      enableOnFormTags: true,
    }
  );

  useEffect(() => {
    if (
      containerRef.current &&
      selectedRowIndex >= 0 &&
      selectedRowIndex < rows.length
    ) {
      const selectedRow = containerRef.current.children[
        selectedRowIndex
      ] as HTMLElement;
      if (selectedRow) {
        selectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [selectedRowIndex]);

  return (
    <WrapperModal
      isOpen={isOpen}
      onClose={onClose}
      width='40rem'
      height='30rem'
    >
      <Flexbox
        data-testid='spotlight-search'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        backgroundColor={theme.colors.ui.card}
        borderRadius={0.5}
        gap={0}
      >
        <Flexbox
          justifyContent='center'
          alignItems='center'
          borderType='borderBottom'
          height='2.75rem'
        >
          <Input
            id='spotlight-search-input'
            name='spotlight-search-input'
            placeholder='Profile Search'
            value={searchValue}
            borderType='none'
            leftElement={
              <Icon
                name='patient-lookup'
                stroke={theme.colors.ui.dark}
                size={1.5}
              />
            }
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
            p={0.75}
          />
        </Flexbox>
        <Container height='auto' width='auto' p={0.75} pb={0.5}>
          <SearchMessage
            nrOfRows={rows.filter((row) => !row.isLoading).length}
            searchValue={searchValue}
          />
        </Container>
        <Container
          ref={containerRef}
          height='auto'
          maxHeight='25rem'
          overflowY='scroll'
        >
          {rows.map((row, index) => (
            <SpotlightSearchRow
              key={index}
              {...row}
              isSelected={selectedRowIndex === index}
              onClose={onClose}
            />
          ))}
        </Container>
      </Flexbox>
    </WrapperModal>
  );
};

export const MemoizedSpotlightSearch = memo(SpotlightSearch);
