import { styled } from '@morf/theming';
import { Flexbox } from '../Flexbox';

export const StyledSubRow = styled(Flexbox)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -1.5rem;
    left: -2.5rem;
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${(props) => props.theme.colors.ui.card};
  }
`;
