import _ from 'lodash';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { Text } from '../../../../Typography';
import { TimeOptionProps } from './types';
import { useTheme } from '@morf/theming';

const TimeOption: FC<TimeOptionProps> = ({
  isSelected,
  onClick,
  timeOption,
}) => {
  const theme = useTheme();
  const { label } = timeOption;
  const backgroundColor = isSelected
    ? theme.colors.main.light.light
    : theme.colors.ui.card;

  const handleClick = () => {
    onClick(timeOption);
  };

  return (
    <Flexbox
      data-testid={label + '-option'}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      cursor='pointer'
      onClick={handleClick}
      height='auto'
      backgroundColor={backgroundColor}
      hoverBackgroundColor={theme.colors.main.light.light}
      borderRadius={0.25}
      p={0.25}
    >
      <Text tag='p3'>{label}</Text>
    </Flexbox>
  );
};

export const MemoizedTimeOption = memo(TimeOption);
