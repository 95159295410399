import { Button } from '../../../../Button';
import { FC, memo, useState } from 'react';
import { FilterNodeProps } from '../types';
import { Flexbox } from '../../../../Flexbox';
import { RemoveNode } from '../../../../RemoveNode';
import { RequiredIndicator } from '../../../../RequiredIndicator';
import { Text } from '../../../../Typography';
import { Textarea } from '../../../../Textarea';
import { useHotkeys } from 'react-hotkeys-hook';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const FilterConfigure: FC<FilterNodeProps> = ({ id, data }) => {
  const {
    configuration,
    onUpdateNode,
    onAddNode,
    onRemoveNode,
    isReadOnly,
    isRequired,
  } = data.value;

  const celExpressionStr = configuration?.celExpressionStr;
  const [updatedCelExpressionStr, setUpdatedCelExpressionStr] =
    useState(celExpressionStr);

  const isValidNode =
    updatedCelExpressionStr && updatedCelExpressionStr !== celExpressionStr;

  useHotkeys(
    'enter',
    () => {
      if (isValidNode) {
        handleNodeSave();
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: ['textarea'],
    }
  );

  const handleNodeSave = () => {
    const filterNode = new workflows.v1.CreateWorkflowFilterRequest({
      celExpressionStr: updatedCelExpressionStr,
    });

    if (isRequired) {
      onAddNode &&
        onAddNode({
          node: new workflows.v1.CreateWorkflowNodeRequest({
            id: id,
            name: data.value.name,
            description: data.value.description,
            filter: filterNode,
          }),
        });
    } else {
      onUpdateNode &&
        onUpdateNode({
          nodeId: id,
          type: new workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType(
            {
              filter: filterNode,
            }
          ),
        });
    }
  };

  const handleNodeRemove = () => {
    onRemoveNode &&
      onRemoveNode({
        nodeId: id,
      });
  };

  return (
    <Flexbox
      data-testid='filter-node'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={2}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.5}
      >
        <Textarea
          name='cel-expression-text-area'
          placeholder='custom CEL expression'
          defaultValue={updatedCelExpressionStr}
          onChange={(e) => setUpdatedCelExpressionStr(e.target.value)}
          readOnly={isReadOnly}
          rows={5}
        />
        {!updatedCelExpressionStr && <RequiredIndicator />}
        {isValidNode && (
          <Button variant='secondary' onClick={handleNodeSave}>
            <Text tag='h5' color='inherit'>
              Save
            </Text>
          </Button>
        )}
      </Flexbox>

      {!isReadOnly && (
        <RemoveNode type='filter' onRemoveNode={handleNodeRemove} />
      )}
    </Flexbox>
  );
};

export const MemoizedFilterConfigure = memo(FilterConfigure);
