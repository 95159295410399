import { FC } from 'react';
import { Text } from '../Typography';
import { Timeslots } from '../Timeslots';
import { Loader } from '../Loader';
import { useTheme } from '@morf/theming';
import { useResize } from '../Hooks/useResize';
import { ProviderCardExtendedSlotsProps } from './types';

export const ProviderCardExtendedSlots: FC<ProviderCardExtendedSlotsProps> = ({
  slots,
  id,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
}) => {
  const theme = useTheme();

  const responsiveSlots = useResize({
    columns: {
      xxl: 4,
      xl: 4,
      lg: 4,
      md: 4,
      sm: 2,
      xs: 1,
    },
  });

  if (slots) {
    if (slots.length === 0) {
      return (
        <Text tag='h5' color={theme.colors.text.muted}>
          Provider is not available. Choose another date.
        </Text>
      );
    } else {
      return (
        <Timeslots
          key={id}
          columns={responsiveSlots.columns}
          slots={slots}
          selectedSlot={selectedSlot}
          onSelectSlot={onSelectSlot}
          onConfirmSlot={onConfirmSlot}
        />
      );
    }
  } else {
    return <Loader height='8.5rem' />;
  }
};
