'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.protobuf = (() => {
    const protobuf = {};
    protobuf.Any = (() => {
      class Any {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Any(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.typeUrl != null &&
            Object.hasOwnProperty.call(message, 'typeUrl')
          ) {
            writer.uint32(10).string(message.typeUrl);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(18).bytes(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Any();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.typeUrl = reader.string();
                break;
              }
              case 2: {
                message.value = reader.bytes();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.protobuf.Any) {
            return object;
          }
          const message = new $root.google.protobuf.Any();
          if (object.typeUrl != null) {
            message.typeUrl = String(object.typeUrl);
          }
          if (object.value != null) {
            if (typeof object.value === 'string') {
              $util.base64.decode(
                object.value,
                (message.value = $util.newBuffer(
                  $util.base64.length(object.value)
                )),
                0
              );
            } else if (object.value.length >= 0) {
              message.value = object.value;
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.typeUrl = '';
            if (options.bytes === String) {
              object.value = '';
            } else {
              object.value = [];
              if (options.bytes !== Array) {
                object.value = $util.newBuffer(object.value);
              }
            }
          }
          let keys;
          if (message.typeUrl != null && message.hasOwnProperty('typeUrl')) {
            object.typeUrl = message.typeUrl;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value =
              options.bytes === String
                ? $util.base64.encode(message.value, 0, message.value.length)
                : options.bytes === Array
                ? Array.prototype.slice.call(message.value)
                : message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'google.protobuf.Any';
        }
      }

      Any.prototype.typeUrl = '';
      Any.prototype.value = new Uint8Array();

      return Any;
    })();

    return protobuf;
  })();

  return google;
})();

export const google = $root.google;
