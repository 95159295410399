export const Wait = () => {
  return (
    <>
      <path
        d='M16 5.5V6.97346C16 7.48449 15.8044 7.97614 15.4533 8.34748L13.2991 10.626C12.5701 11.397 12.5701 12.603 13.2991 13.374L15.4533 15.6525C15.8044 16.0239 16 16.5155 16 17.0265V18.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 5.5C8 5.77327 8 6.43496 8 6.97372C8 7.48476 8.19562 7.97614 8.54671 8.34748L10.7009 10.626C11.4299 11.397 11.4299 12.603 10.7009 13.374L8.54671 15.6525C8.19562 16.0239 8 16.5155 8 17.0265V18.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7929 16.5H11.2071L12 15.7071L12.7929 16.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7 18.5H17' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 5.5H17' strokeLinecap='round' strokeLinejoin='round' />
    </>
  );
};
