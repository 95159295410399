import { FC } from 'react';
import { ComingSoonProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

export const ComingSoon: FC<ComingSoonProps> = ({
  email = 'help@morf.health',
  type,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='coming-soon'
      direction='column'
      borderRadius={1}
      borderType={'border'}
      height='auto'
      p={1.5}
      gap={0.75}
    >
      <Text tag='h3'>More {type} coming soon</Text>
      <Flexbox direction='column'>
        <Text tag='p2'>
          We're working hard to offer more {type}. If you don't see an app that
          you need, let us know about it!
        </Text>
        <a href={`mailto:${email}`}>
          <Text tag='link' color={theme.colors.main.primary.darker}>
            Contact us
          </Text>
        </a>
      </Flexbox>
    </Flexbox>
  );
};
