import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const getEventPayloadFieldLookup = ({
  static: staticConfig,
  dynamic: dynamicConfig,
}: workflow_parameters.v1.WorkflowEventPayloadFieldSchema):
  | workflow_parameters.v1.EventPayloadFieldLookup
  | null
  | undefined => {
  return (
    staticConfig?.staticParameterConfig?.source?.eventPayloadFieldLookup ||
    dynamicConfig?.dynamicParameterConfig?.arraySource
      ?.eventPayloadFieldLookup ||
    dynamicConfig?.dynamicParameterConfig?.objectSource?.eventPayloadFieldLookup
  );
};
