import _ from 'lodash';
import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WeekAvailabilityCard } from './WeekAvailabilityCard';
import { WeekAvailabilityProps } from './types';
import { formatDay } from './formatDay';
import { formatMonth } from './formatMonth';
import { goToNextWeek } from './goToNextWeek';
import { goToPrevWeek } from './gotToPrevWeek';
import { useTheme } from '@morf/theming';

const WeekAvailability: FC<WeekAvailabilityProps> = ({
  week,
  onWeekChange,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
}) => {
  const theme = useTheme();
  const hideNoAvailabilityCard = theme.hideNoAvailablityCard;

  const startDate = week[0].date;
  const lastDate = week[6].date;

  const handlePrevWeekClick = () => {
    const prevWeek = goToPrevWeek(startDate);
    onWeekChange(prevWeek);
  };

  const handleNextWeekClick = () => {
    const nextWeek = goToNextWeek(startDate);
    onWeekChange(nextWeek);
  };

  return (
    <Flexbox
      direction='column'
      data-testid='week-availability'
      backgroundColor={theme.colors.ui.card}
      borderRadius={theme.container.borderRadius}
      borderColor={theme.container.borderColor}
      borderType={theme.container.borderType}
      borderWidth={theme.container.borderWidth}
      height='auto'
      gap={0}
    >
      <Flexbox alignItems='center' p={1.25}>
        <Container width='auto'>
          <Text tag='h3' whiteSpace='nowrap'>
            Week of {formatMonth(startDate)} {formatDay(startDate)}-
            {formatDay(lastDate)}
          </Text>
        </Container>
        <Flexbox justifyContent='flex-end' alignItems='center'>
          <Icon
            name='chevron-left'
            onClick={handlePrevWeekClick}
            stroke={theme.colors.main.primary.darker}
            size={1.25}
            cursor='pointer'
          />
          <Icon
            name='chevron-right'
            onClick={handleNextWeekClick}
            stroke={theme.colors.main.primary.darker}
            size={1.25}
            cursor='pointer'
          />
        </Flexbox>
      </Flexbox>

      <Flexbox direction='column' overflow='scroll' gap={0}>
        {hideNoAvailabilityCard &&
          week.every(({ slots }) => _.isEmpty(slots)) && (
            <Container p={1.25} borderType='borderTop'>
              <Text>No availability for this week</Text>
            </Container>
          )}
        {week
          .filter(({ slots }) => !hideNoAvailabilityCard || slots.length > 0)
          .map(({ date, slots }, index) => (
            <WeekAvailabilityCard
              key={index}
              date={date}
              slots={slots}
              selectedSlot={selectedSlot}
              onSelectSlot={onSelectSlot}
              onConfirmSlot={onConfirmSlot}
            />
          ))}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWeekAvailability = memo(WeekAvailability);
