'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.formsort = (() => {
  const formsort = {};
  formsort.v1 = (() => {
    const v1 = {};
    v1.ListFlowRequest = (() => {
      class ListFlowRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFlowRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFlowRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListFlowRequest) {
            return object;
          }
          const message = new $root.formsort.v1.ListFlowRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.formsort.v1.ListFlowRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListFlowRequest';
        }
      }

      ListFlowRequest.prototype.page = null;

      return ListFlowRequest;
    })();

    v1.ListFlowResponse = (() => {
      class ListFlowResponse {
        constructor(properties) {
          this.flows = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFlowResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.flows != null &&
            Object.hasOwnProperty.call(message, 'flows')
          ) {
            for (const element of message.flows) {
              $root.formsort.v1.FormsortFlow.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFlowResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.flows || !message.flows.length) {
                  message.flows = [];
                }
                message.flows.push(
                  $root.formsort.v1.FormsortFlow.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListFlowResponse) {
            return object;
          }
          const message = new $root.formsort.v1.ListFlowResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.flows) {
            if (!Array.isArray(object.flows)) {
              throw new TypeError(
                '.formsort.v1.ListFlowResponse.flows: array type expected, but got ' +
                  typeof object.flows
              );
            }
            message.flows = new Array(object.flows.length);
            for (let i = 0; i < object.flows.length; ++i) {
              if (typeof object.flows[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.ListFlowResponse.flows: object expected, but got ' +
                    typeof object.flows[i]
                );
              }
              message.flows[i] = $root.formsort.v1.FormsortFlow.fromObject(
                object.flows[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.flows = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.flows && message.flows.length) {
            object.flows = new Array(message.flows.length);
            for (let i = 0; i < message.flows.length; ++i) {
              object.flows[i] = $root.formsort.v1.FormsortFlow.toObject(
                message.flows[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListFlowResponse';
        }
      }

      ListFlowResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListFlowResponse.prototype.flows = $util.emptyArray;

      return ListFlowResponse;
    })();

    v1.ListVariantsRequest = (() => {
      class ListVariantsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListVariantsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.flowLabel != null &&
            Object.hasOwnProperty.call(message, 'flowLabel')
          ) {
            writer.uint32(18).string(message.flowLabel);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListVariantsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.flowLabel = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListVariantsRequest) {
            return object;
          }
          const message = new $root.formsort.v1.ListVariantsRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.formsort.v1.ListVariantsRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.flowLabel != null) {
            message.flowLabel = String(object.flowLabel);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.flowLabel = '';
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.flowLabel != null &&
            message.hasOwnProperty('flowLabel')
          ) {
            object.flowLabel = message.flowLabel;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListVariantsRequest';
        }
      }

      ListVariantsRequest.prototype.page = null;
      ListVariantsRequest.prototype.flowLabel = '';

      return ListVariantsRequest;
    })();

    v1.ListVariantsResponse = (() => {
      class ListVariantsResponse {
        constructor(properties) {
          this.variants = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListVariantsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.variants != null &&
            Object.hasOwnProperty.call(message, 'variants')
          ) {
            for (const element of message.variants) {
              $root.formsort.v1.FormsortVariantSummary.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListVariantsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.variants || !message.variants.length) {
                  message.variants = [];
                }
                message.variants.push(
                  $root.formsort.v1.FormsortVariantSummary.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListVariantsResponse) {
            return object;
          }
          const message = new $root.formsort.v1.ListVariantsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.variants) {
            if (!Array.isArray(object.variants)) {
              throw new TypeError(
                '.formsort.v1.ListVariantsResponse.variants: array type expected, but got ' +
                  typeof object.variants
              );
            }
            message.variants = new Array(object.variants.length);
            for (let i = 0; i < object.variants.length; ++i) {
              if (typeof object.variants[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.ListVariantsResponse.variants: object expected, but got ' +
                    typeof object.variants[i]
                );
              }
              message.variants[i] =
                $root.formsort.v1.FormsortVariantSummary.fromObject(
                  object.variants[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.variants = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.variants && message.variants.length) {
            object.variants = new Array(message.variants.length);
            for (let i = 0; i < message.variants.length; ++i) {
              object.variants[i] =
                $root.formsort.v1.FormsortVariantSummary.toObject(
                  message.variants[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListVariantsResponse';
        }
      }

      ListVariantsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListVariantsResponse.prototype.variants = $util.emptyArray;

      return ListVariantsResponse;
    })();

    v1.ListDeploymentsRequest = (() => {
      class ListDeploymentsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDeploymentsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.flowLabel != null &&
            Object.hasOwnProperty.call(message, 'flowLabel')
          ) {
            writer.uint32(18).string(message.flowLabel);
          }
          if (
            message.variantLabel != null &&
            Object.hasOwnProperty.call(message, 'variantLabel')
          ) {
            writer.uint32(26).string(message.variantLabel);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDeploymentsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.flowLabel = reader.string();
                break;
              }
              case 3: {
                message.variantLabel = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListDeploymentsRequest) {
            return object;
          }
          const message = new $root.formsort.v1.ListDeploymentsRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.formsort.v1.ListDeploymentsRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.flowLabel != null) {
            message.flowLabel = String(object.flowLabel);
          }
          if (object.variantLabel != null) {
            message.variantLabel = String(object.variantLabel);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.flowLabel = '';
            object.variantLabel = '';
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.flowLabel != null &&
            message.hasOwnProperty('flowLabel')
          ) {
            object.flowLabel = message.flowLabel;
          }
          if (
            message.variantLabel != null &&
            message.hasOwnProperty('variantLabel')
          ) {
            object.variantLabel = message.variantLabel;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListDeploymentsRequest';
        }
      }

      ListDeploymentsRequest.prototype.page = null;
      ListDeploymentsRequest.prototype.flowLabel = '';
      ListDeploymentsRequest.prototype.variantLabel = '';

      return ListDeploymentsRequest;
    })();

    v1.ListDeploymentsResponse = (() => {
      class ListDeploymentsResponse {
        constructor(properties) {
          this.deployments = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDeploymentsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.deployments != null &&
            Object.hasOwnProperty.call(message, 'deployments')
          ) {
            for (const element of message.deployments) {
              $root.formsort.v1.FormsortDeployment.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDeploymentsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.deployments || !message.deployments.length) {
                  message.deployments = [];
                }
                message.deployments.push(
                  $root.formsort.v1.FormsortDeployment.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.ListDeploymentsResponse) {
            return object;
          }
          const message = new $root.formsort.v1.ListDeploymentsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.deployments) {
            if (!Array.isArray(object.deployments)) {
              throw new TypeError(
                '.formsort.v1.ListDeploymentsResponse.deployments: array type expected, but got ' +
                  typeof object.deployments
              );
            }
            message.deployments = new Array(object.deployments.length);
            for (let i = 0; i < object.deployments.length; ++i) {
              if (typeof object.deployments[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.ListDeploymentsResponse.deployments: object expected, but got ' +
                    typeof object.deployments[i]
                );
              }
              message.deployments[i] =
                $root.formsort.v1.FormsortDeployment.fromObject(
                  object.deployments[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.deployments = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.deployments && message.deployments.length) {
            object.deployments = new Array(message.deployments.length);
            for (let i = 0; i < message.deployments.length; ++i) {
              object.deployments[i] =
                $root.formsort.v1.FormsortDeployment.toObject(
                  message.deployments[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.ListDeploymentsResponse';
        }
      }

      ListDeploymentsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListDeploymentsResponse.prototype.deployments = $util.emptyArray;

      return ListDeploymentsResponse;
    })();

    v1.GetVariantRevisionDetailedRequest = (() => {
      class GetVariantRevisionDetailedRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetVariantRevisionDetailedRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.variantRevisionId != null &&
            Object.hasOwnProperty.call(message, 'variantRevisionId')
          ) {
            writer.uint32(10).string(message.variantRevisionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetVariantRevisionDetailedRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.variantRevisionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.formsort.v1.GetVariantRevisionDetailedRequest
          ) {
            return object;
          }
          const message =
            new $root.formsort.v1.GetVariantRevisionDetailedRequest();
          if (object.variantRevisionId != null) {
            message.variantRevisionId = String(object.variantRevisionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.variantRevisionId = '';
          }
          let keys;
          if (
            message.variantRevisionId != null &&
            message.hasOwnProperty('variantRevisionId')
          ) {
            object.variantRevisionId = message.variantRevisionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'formsort.v1.GetVariantRevisionDetailedRequest'
          );
        }
      }

      GetVariantRevisionDetailedRequest.prototype.variantRevisionId = '';

      return GetVariantRevisionDetailedRequest;
    })();

    v1.GetVariantRevisionDetailedResponse = (() => {
      class GetVariantRevisionDetailedResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetVariantRevisionDetailedResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.variant != null &&
            Object.hasOwnProperty.call(message, 'variant')
          ) {
            $root.formsort.v1.FormsortVariant.encode(
              message.variant,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.schema != null &&
            Object.hasOwnProperty.call(message, 'schema')
          ) {
            google$1.protobuf.Struct.encode(
              message.schema,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetVariantRevisionDetailedResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.variant = $root.formsort.v1.FormsortVariant.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.schema = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.formsort.v1.GetVariantRevisionDetailedResponse
          ) {
            return object;
          }
          const message =
            new $root.formsort.v1.GetVariantRevisionDetailedResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.variant != null) {
            if (typeof object.variant !== 'object') {
              throw new TypeError(
                '.formsort.v1.GetVariantRevisionDetailedResponse.variant: object expected, but got ' +
                  typeof object.variant
              );
            }
            message.variant = $root.formsort.v1.FormsortVariant.fromObject(
              object.variant
            );
          }
          if (object.schema != null) {
            if (typeof object.schema !== 'object') {
              throw new TypeError(
                '.formsort.v1.GetVariantRevisionDetailedResponse.schema: object expected, but got ' +
                  typeof object.schema
              );
            }
            message.schema = google$1.protobuf.Struct.fromObject(object.schema);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.variant = null;
            object.schema = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.variant != null && message.hasOwnProperty('variant')) {
            object.variant = $root.formsort.v1.FormsortVariant.toObject(
              message.variant,
              options
            );
          }
          if (message.schema != null && message.hasOwnProperty('schema')) {
            object.schema = google$1.protobuf.Struct.toObject(
              message.schema,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'formsort.v1.GetVariantRevisionDetailedResponse'
          );
        }
      }

      GetVariantRevisionDetailedResponse.prototype.id = '';
      GetVariantRevisionDetailedResponse.prototype.variant = null;
      GetVariantRevisionDetailedResponse.prototype.schema = null;

      return GetVariantRevisionDetailedResponse;
    })();

    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.formsort.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.formsort.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_FORMSORT_WEBHOOK_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'FORMSORT_STEP_COMPLETED':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.formsort.v1.FormsortEventType[
                      message.eventTypes[i]
                    ] === undefined
                    ? message.eventTypes[i]
                    : $root.formsort.v1.FormsortEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.FormsortDeployment = (() => {
      class FormsortDeployment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortDeployment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.deployedBy != null &&
            Object.hasOwnProperty.call(message, 'deployedBy')
          ) {
            writer.uint32(10).string(message.deployedBy);
          }
          if (
            message.deployedAt != null &&
            Object.hasOwnProperty.call(message, 'deployedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.deployedAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(26).string(message.notes);
          }
          if (
            message.variantRevisionUuid != null &&
            Object.hasOwnProperty.call(message, 'variantRevisionUuid')
          ) {
            writer.uint32(34).string(message.variantRevisionUuid);
          }
          if (
            message.deploymentEnvironment != null &&
            Object.hasOwnProperty.call(message, 'deploymentEnvironment')
          ) {
            writer.uint32(42).string(message.deploymentEnvironment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortDeployment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.deployedBy = reader.string();
                break;
              }
              case 2: {
                message.deployedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.notes = reader.string();
                break;
              }
              case 4: {
                message.variantRevisionUuid = reader.string();
                break;
              }
              case 5: {
                message.deploymentEnvironment = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortDeployment) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortDeployment();
          if (object.deployedBy != null) {
            message.deployedBy = String(object.deployedBy);
          }
          if (object.deployedAt != null) {
            if (typeof object.deployedAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortDeployment.deployedAt: object expected, but got ' +
                  typeof object.deployedAt
              );
            }
            message.deployedAt = timing$1.v1.Timestamp.fromObject(
              object.deployedAt
            );
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.variantRevisionUuid != null) {
            message.variantRevisionUuid = String(object.variantRevisionUuid);
          }
          if (object.deploymentEnvironment != null) {
            message.deploymentEnvironment = String(
              object.deploymentEnvironment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.deployedBy = '';
            object.deployedAt = null;
            object.variantRevisionUuid = '';
            object.deploymentEnvironment = '';
          }
          let keys;
          if (
            message.deployedBy != null &&
            message.hasOwnProperty('deployedBy')
          ) {
            object.deployedBy = message.deployedBy;
          }
          if (
            message.deployedAt != null &&
            message.hasOwnProperty('deployedAt')
          ) {
            object.deployedAt = timing$1.v1.Timestamp.toObject(
              message.deployedAt,
              options
            );
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (
            message.variantRevisionUuid != null &&
            message.hasOwnProperty('variantRevisionUuid')
          ) {
            object.variantRevisionUuid = message.variantRevisionUuid;
          }
          if (
            message.deploymentEnvironment != null &&
            message.hasOwnProperty('deploymentEnvironment')
          ) {
            object.deploymentEnvironment = message.deploymentEnvironment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortDeployment';
        }
      }

      FormsortDeployment.prototype.deployedBy = '';
      FormsortDeployment.prototype.deployedAt = null;
      FormsortDeployment.prototype.notes = null;
      FormsortDeployment.prototype.variantRevisionUuid = '';
      FormsortDeployment.prototype.deploymentEnvironment = '';

      return FormsortDeployment;
    })();

    v1.FormsortFlow = (() => {
      class FormsortFlow {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFlow(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFlow();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortFlow) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortFlow();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFlow.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.createdAt = null;
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortFlow';
        }
      }

      FormsortFlow.prototype.label = '';
      FormsortFlow.prototype.createdAt = null;

      return FormsortFlow;
    })();

    v1.FormsortVariantSummary = (() => {
      class FormsortVariantSummary {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortVariantSummary(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortVariantSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortVariantSummary) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortVariantSummary();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortVariantSummary.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.createdAt = null;
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortVariantSummary';
        }
      }

      FormsortVariantSummary.prototype.label = '';
      FormsortVariantSummary.prototype.createdAt = null;

      return FormsortVariantSummary;
    })();

    v1.FormsortVariant = (() => {
      class FormsortVariant {
        constructor(properties) {
          this.groups = [];
          this.redirects = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortVariant(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.groups != null &&
            Object.hasOwnProperty.call(message, 'groups')
          ) {
            for (const element of message.groups) {
              $root.formsort.v1.FormsortFlowContentGroup.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.redirects != null &&
            Object.hasOwnProperty.call(message, 'redirects')
          ) {
            for (const element of message.redirects) {
              $root.formsort.v1.FormsortRedirect.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.variables != null &&
            Object.hasOwnProperty.call(message, 'variables')
          ) {
            $root.formsort.v1.FormsortDenormAnswerKeys.encode(
              message.variables,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortVariant();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.groups || !message.groups.length) {
                  message.groups = [];
                }
                message.groups.push(
                  $root.formsort.v1.FormsortFlowContentGroup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                if (!message.redirects || !message.redirects.length) {
                  message.redirects = [];
                }
                message.redirects.push(
                  $root.formsort.v1.FormsortRedirect.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                message.variables =
                  $root.formsort.v1.FormsortDenormAnswerKeys.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortVariant) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortVariant();
          if (object.groups) {
            if (!Array.isArray(object.groups)) {
              throw new TypeError(
                '.formsort.v1.FormsortVariant.groups: array type expected, but got ' +
                  typeof object.groups
              );
            }
            message.groups = new Array(object.groups.length);
            for (let i = 0; i < object.groups.length; ++i) {
              if (typeof object.groups[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortVariant.groups: object expected, but got ' +
                    typeof object.groups[i]
                );
              }
              message.groups[i] =
                $root.formsort.v1.FormsortFlowContentGroup.fromObject(
                  object.groups[i]
                );
            }
          }
          if (object.redirects) {
            if (!Array.isArray(object.redirects)) {
              throw new TypeError(
                '.formsort.v1.FormsortVariant.redirects: array type expected, but got ' +
                  typeof object.redirects
              );
            }
            message.redirects = new Array(object.redirects.length);
            for (let i = 0; i < object.redirects.length; ++i) {
              if (typeof object.redirects[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortVariant.redirects: object expected, but got ' +
                    typeof object.redirects[i]
                );
              }
              message.redirects[i] =
                $root.formsort.v1.FormsortRedirect.fromObject(
                  object.redirects[i]
                );
            }
          }
          if (object.variables != null) {
            if (typeof object.variables !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortVariant.variables: object expected, but got ' +
                  typeof object.variables
              );
            }
            message.variables =
              $root.formsort.v1.FormsortDenormAnswerKeys.fromObject(
                object.variables
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.groups = [];
            object.redirects = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.groups && message.groups.length) {
            object.groups = new Array(message.groups.length);
            for (let i = 0; i < message.groups.length; ++i) {
              object.groups[i] =
                $root.formsort.v1.FormsortFlowContentGroup.toObject(
                  message.groups[i],
                  options
                );
            }
          }
          if (message.redirects && message.redirects.length) {
            object.redirects = new Array(message.redirects.length);
            for (let i = 0; i < message.redirects.length; ++i) {
              object.redirects[i] = $root.formsort.v1.FormsortRedirect.toObject(
                message.redirects[i],
                options
              );
            }
          }
          if (
            message.variables != null &&
            message.hasOwnProperty('variables')
          ) {
            object.variables =
              $root.formsort.v1.FormsortDenormAnswerKeys.toObject(
                message.variables,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortVariant';
        }
      }

      FormsortVariant.prototype.groups = $util.emptyArray;
      FormsortVariant.prototype.redirects = $util.emptyArray;
      FormsortVariant.prototype.variables = null;

      return FormsortVariant;
    })();

    v1.FormsortEvent = (() => {
      class FormsortEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(18).string(message.type);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.type = reader.string();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortEvent) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortEvent();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortEvent.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.type = '';
            object.createdAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortEvent';
        }
      }

      FormsortEvent.prototype.id = '';
      FormsortEvent.prototype.type = '';
      FormsortEvent.prototype.createdAt = null;

      return FormsortEvent;
    })();

    v1.FormsortFormRevisionUpdate = (() => {
      class FormsortFormRevisionUpdate {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFormRevisionUpdate(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.event != null &&
            Object.hasOwnProperty.call(message, 'event')
          ) {
            $root.formsort.v1.FormsortEvent.encode(
              message.event,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.flowLabel != null &&
            Object.hasOwnProperty.call(message, 'flowLabel')
          ) {
            writer.uint32(18).string(message.flowLabel);
          }
          if (
            message.environmentLabel != null &&
            Object.hasOwnProperty.call(message, 'environmentLabel')
          ) {
            writer.uint32(26).string(message.environmentLabel);
          }
          if (
            message.variantLabel != null &&
            Object.hasOwnProperty.call(message, 'variantLabel')
          ) {
            writer.uint32(34).string(message.variantLabel);
          }
          if (
            message.variantRevisionUuid != null &&
            Object.hasOwnProperty.call(message, 'variantRevisionUuid')
          ) {
            writer.uint32(42).string(message.variantRevisionUuid);
          }
          if (
            message.jsonSchema != null &&
            Object.hasOwnProperty.call(message, 'jsonSchema')
          ) {
            google$1.protobuf.Struct.encode(
              message.jsonSchema,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.publishedAt != null &&
            Object.hasOwnProperty.call(message, 'publishedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.publishedAt,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.publishedByEmail != null &&
            Object.hasOwnProperty.call(message, 'publishedByEmail')
          ) {
            writer.uint32(66).string(message.publishedByEmail);
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(74).string(message.notes);
          }
          if (
            message.flowContent != null &&
            Object.hasOwnProperty.call(message, 'flowContent')
          ) {
            $root.formsort.v1.FormsortVariant.encode(
              message.flowContent,
              writer.uint32(82).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFormRevisionUpdate();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.event = $root.formsort.v1.FormsortEvent.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.flowLabel = reader.string();
                break;
              }
              case 3: {
                message.environmentLabel = reader.string();
                break;
              }
              case 4: {
                message.variantLabel = reader.string();
                break;
              }
              case 5: {
                message.variantRevisionUuid = reader.string();
                break;
              }
              case 6: {
                message.jsonSchema = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.publishedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.publishedByEmail = reader.string();
                break;
              }
              case 9: {
                message.notes = reader.string();
                break;
              }
              case 10: {
                message.flowContent = $root.formsort.v1.FormsortVariant.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortFormRevisionUpdate) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortFormRevisionUpdate();
          if (object.event != null) {
            if (typeof object.event !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormRevisionUpdate.event: object expected, but got ' +
                  typeof object.event
              );
            }
            message.event = $root.formsort.v1.FormsortEvent.fromObject(
              object.event
            );
          }
          if (object.flowLabel != null) {
            message.flowLabel = String(object.flowLabel);
          }
          if (object.environmentLabel != null) {
            message.environmentLabel = String(object.environmentLabel);
          }
          if (object.variantLabel != null) {
            message.variantLabel = String(object.variantLabel);
          }
          if (object.variantRevisionUuid != null) {
            message.variantRevisionUuid = String(object.variantRevisionUuid);
          }
          if (object.jsonSchema != null) {
            if (typeof object.jsonSchema !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormRevisionUpdate.jsonSchema: object expected, but got ' +
                  typeof object.jsonSchema
              );
            }
            message.jsonSchema = google$1.protobuf.Struct.fromObject(
              object.jsonSchema
            );
          }
          if (object.publishedAt != null) {
            if (typeof object.publishedAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormRevisionUpdate.publishedAt: object expected, but got ' +
                  typeof object.publishedAt
              );
            }
            message.publishedAt = timing$1.v1.Timestamp.fromObject(
              object.publishedAt
            );
          }
          if (object.publishedByEmail != null) {
            message.publishedByEmail = String(object.publishedByEmail);
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.flowContent != null) {
            if (typeof object.flowContent !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormRevisionUpdate.flowContent: object expected, but got ' +
                  typeof object.flowContent
              );
            }
            message.flowContent = $root.formsort.v1.FormsortVariant.fromObject(
              object.flowContent
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.event = null;
            object.flowLabel = '';
            object.environmentLabel = '';
            object.variantLabel = '';
            object.variantRevisionUuid = '';
            object.jsonSchema = null;
            object.publishedAt = null;
            object.publishedByEmail = '';
            object.notes = '';
            object.flowContent = null;
          }
          let keys;
          if (message.event != null && message.hasOwnProperty('event')) {
            object.event = $root.formsort.v1.FormsortEvent.toObject(
              message.event,
              options
            );
          }
          if (
            message.flowLabel != null &&
            message.hasOwnProperty('flowLabel')
          ) {
            object.flowLabel = message.flowLabel;
          }
          if (
            message.environmentLabel != null &&
            message.hasOwnProperty('environmentLabel')
          ) {
            object.environmentLabel = message.environmentLabel;
          }
          if (
            message.variantLabel != null &&
            message.hasOwnProperty('variantLabel')
          ) {
            object.variantLabel = message.variantLabel;
          }
          if (
            message.variantRevisionUuid != null &&
            message.hasOwnProperty('variantRevisionUuid')
          ) {
            object.variantRevisionUuid = message.variantRevisionUuid;
          }
          if (
            message.jsonSchema != null &&
            message.hasOwnProperty('jsonSchema')
          ) {
            object.jsonSchema = google$1.protobuf.Struct.toObject(
              message.jsonSchema,
              options
            );
          }
          if (
            message.publishedAt != null &&
            message.hasOwnProperty('publishedAt')
          ) {
            object.publishedAt = timing$1.v1.Timestamp.toObject(
              message.publishedAt,
              options
            );
          }
          if (
            message.publishedByEmail != null &&
            message.hasOwnProperty('publishedByEmail')
          ) {
            object.publishedByEmail = message.publishedByEmail;
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (
            message.flowContent != null &&
            message.hasOwnProperty('flowContent')
          ) {
            object.flowContent = $root.formsort.v1.FormsortVariant.toObject(
              message.flowContent,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortFormRevisionUpdate';
        }
      }

      FormsortFormRevisionUpdate.prototype.event = null;
      FormsortFormRevisionUpdate.prototype.flowLabel = '';
      FormsortFormRevisionUpdate.prototype.environmentLabel = '';
      FormsortFormRevisionUpdate.prototype.variantLabel = '';
      FormsortFormRevisionUpdate.prototype.variantRevisionUuid = '';
      FormsortFormRevisionUpdate.prototype.jsonSchema = null;
      FormsortFormRevisionUpdate.prototype.publishedAt = null;
      FormsortFormRevisionUpdate.prototype.publishedByEmail = '';
      FormsortFormRevisionUpdate.prototype.notes = '';
      FormsortFormRevisionUpdate.prototype.flowContent = null;

      return FormsortFormRevisionUpdate;
    })();

    v1.FormsortFormResponse = (() => {
      class FormsortFormResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFormResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.answers != null &&
            Object.hasOwnProperty.call(message, 'answers')
          ) {
            google$1.protobuf.Struct.encode(
              message.answers,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.responderUuid != null &&
            Object.hasOwnProperty.call(message, 'responderUuid')
          ) {
            writer.uint32(18).string(message.responderUuid);
          }
          if (
            message.flowLabel != null &&
            Object.hasOwnProperty.call(message, 'flowLabel')
          ) {
            writer.uint32(26).string(message.flowLabel);
          }
          if (
            message.variantLabel != null &&
            Object.hasOwnProperty.call(message, 'variantLabel')
          ) {
            writer.uint32(34).string(message.variantLabel);
          }
          if (
            message.variantUuid != null &&
            Object.hasOwnProperty.call(message, 'variantUuid')
          ) {
            writer.uint32(42).string(message.variantUuid);
          }
          if (
            message.finalized != null &&
            Object.hasOwnProperty.call(message, 'finalized')
          ) {
            writer.uint32(48).bool(message.finalized);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.currentStepId != null &&
            Object.hasOwnProperty.call(message, 'currentStepId')
          ) {
            writer.uint32(66).string(message.currentStepId);
          }
          if (
            message.currentStepIndex != null &&
            Object.hasOwnProperty.call(message, 'currentStepIndex')
          ) {
            writer.uint32(74).string(message.currentStepIndex);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFormResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.answers = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.responderUuid = reader.string();
                break;
              }
              case 3: {
                message.flowLabel = reader.string();
                break;
              }
              case 4: {
                message.variantLabel = reader.string();
                break;
              }
              case 5: {
                message.variantUuid = reader.string();
                break;
              }
              case 6: {
                message.finalized = reader.bool();
                break;
              }
              case 7: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.currentStepId = reader.string();
                break;
              }
              case 9: {
                message.currentStepIndex = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortFormResponse) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortFormResponse();
          if (object.answers != null) {
            if (typeof object.answers !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormResponse.answers: object expected, but got ' +
                  typeof object.answers
              );
            }
            message.answers = google$1.protobuf.Struct.fromObject(
              object.answers
            );
          }
          if (object.responderUuid != null) {
            message.responderUuid = String(object.responderUuid);
          }
          if (object.flowLabel != null) {
            message.flowLabel = String(object.flowLabel);
          }
          if (object.variantLabel != null) {
            message.variantLabel = String(object.variantLabel);
          }
          if (object.variantUuid != null) {
            message.variantUuid = String(object.variantUuid);
          }
          if (object.finalized != null) {
            message.finalized = Boolean(object.finalized);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortFormResponse.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.currentStepId != null) {
            message.currentStepId = String(object.currentStepId);
          }
          if (object.currentStepIndex != null) {
            message.currentStepIndex = String(object.currentStepIndex);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.answers = null;
            object.responderUuid = '';
            object.flowLabel = '';
            object.variantLabel = '';
            object.variantUuid = '';
            object.finalized = false;
            object.createdAt = null;
          }
          let keys;
          if (message.answers != null && message.hasOwnProperty('answers')) {
            object.answers = google$1.protobuf.Struct.toObject(
              message.answers,
              options
            );
          }
          if (
            message.responderUuid != null &&
            message.hasOwnProperty('responderUuid')
          ) {
            object.responderUuid = message.responderUuid;
          }
          if (
            message.flowLabel != null &&
            message.hasOwnProperty('flowLabel')
          ) {
            object.flowLabel = message.flowLabel;
          }
          if (
            message.variantLabel != null &&
            message.hasOwnProperty('variantLabel')
          ) {
            object.variantLabel = message.variantLabel;
          }
          if (
            message.variantUuid != null &&
            message.hasOwnProperty('variantUuid')
          ) {
            object.variantUuid = message.variantUuid;
          }
          if (
            message.finalized != null &&
            message.hasOwnProperty('finalized')
          ) {
            object.finalized = message.finalized;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.currentStepId != null &&
            message.hasOwnProperty('currentStepId')
          ) {
            object.currentStepId = message.currentStepId;
          }
          if (
            message.currentStepIndex != null &&
            message.hasOwnProperty('currentStepIndex')
          ) {
            object.currentStepIndex = message.currentStepIndex;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortFormResponse';
        }
      }

      FormsortFormResponse.prototype.answers = null;
      FormsortFormResponse.prototype.responderUuid = '';
      FormsortFormResponse.prototype.flowLabel = '';
      FormsortFormResponse.prototype.variantLabel = '';
      FormsortFormResponse.prototype.variantUuid = '';
      FormsortFormResponse.prototype.finalized = false;
      FormsortFormResponse.prototype.createdAt = null;
      FormsortFormResponse.prototype.currentStepId = null;
      FormsortFormResponse.prototype.currentStepIndex = null;

      return FormsortFormResponse;
    })();

    v1.FormsortFlowContentGroup = (() => {
      class FormsortFlowContentGroup {
        constructor(properties) {
          this.steps = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFlowContentGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.steps != null &&
            Object.hasOwnProperty.call(message, 'steps')
          ) {
            for (const element of message.steps) {
              $root.formsort.v1.FormsortFlowContentStep.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFlowContentGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                if (!message.steps || !message.steps.length) {
                  message.steps = [];
                }
                message.steps.push(
                  $root.formsort.v1.FormsortFlowContentStep.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortFlowContentGroup) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortFlowContentGroup();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.steps) {
            if (!Array.isArray(object.steps)) {
              throw new TypeError(
                '.formsort.v1.FormsortFlowContentGroup.steps: array type expected, but got ' +
                  typeof object.steps
              );
            }
            message.steps = new Array(object.steps.length);
            for (let i = 0; i < object.steps.length; ++i) {
              if (typeof object.steps[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortFlowContentGroup.steps: object expected, but got ' +
                    typeof object.steps[i]
                );
              }
              message.steps[i] =
                $root.formsort.v1.FormsortFlowContentStep.fromObject(
                  object.steps[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.steps = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.steps && message.steps.length) {
            object.steps = new Array(message.steps.length);
            for (let i = 0; i < message.steps.length; ++i) {
              object.steps[i] =
                $root.formsort.v1.FormsortFlowContentStep.toObject(
                  message.steps[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortFlowContentGroup';
        }
      }

      FormsortFlowContentGroup.prototype.label = '';
      FormsortFlowContentGroup.prototype.steps = $util.emptyArray;

      return FormsortFlowContentGroup;
    })();

    v1.FormsortFlowContentStep = (() => {
      class FormsortFlowContentStep {
        constructor(properties) {
          this.questions = [];
          this.redirects = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFlowContentStep(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(18).string(message.id);
          }
          if (
            message.questions != null &&
            Object.hasOwnProperty.call(message, 'questions')
          ) {
            for (const element of message.questions) {
              $root.formsort.v1.FormsortQuestion.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.redirects != null &&
            Object.hasOwnProperty.call(message, 'redirects')
          ) {
            for (const element of message.redirects) {
              $root.formsort.v1.FormsortRedirect.encode(
                element,
                writer.uint32(34).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFlowContentStep();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                if (!message.questions || !message.questions.length) {
                  message.questions = [];
                }
                message.questions.push(
                  $root.formsort.v1.FormsortQuestion.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 4: {
                if (!message.redirects || !message.redirects.length) {
                  message.redirects = [];
                }
                message.redirects.push(
                  $root.formsort.v1.FormsortRedirect.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortFlowContentStep) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortFlowContentStep();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.questions) {
            if (!Array.isArray(object.questions)) {
              throw new TypeError(
                '.formsort.v1.FormsortFlowContentStep.questions: array type expected, but got ' +
                  typeof object.questions
              );
            }
            message.questions = new Array(object.questions.length);
            for (let i = 0; i < object.questions.length; ++i) {
              if (typeof object.questions[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortFlowContentStep.questions: object expected, but got ' +
                    typeof object.questions[i]
                );
              }
              message.questions[i] =
                $root.formsort.v1.FormsortQuestion.fromObject(
                  object.questions[i]
                );
            }
          }
          if (object.redirects) {
            if (!Array.isArray(object.redirects)) {
              throw new TypeError(
                '.formsort.v1.FormsortFlowContentStep.redirects: array type expected, but got ' +
                  typeof object.redirects
              );
            }
            message.redirects = new Array(object.redirects.length);
            for (let i = 0; i < object.redirects.length; ++i) {
              if (typeof object.redirects[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortFlowContentStep.redirects: object expected, but got ' +
                    typeof object.redirects[i]
                );
              }
              message.redirects[i] =
                $root.formsort.v1.FormsortRedirect.fromObject(
                  object.redirects[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.questions = [];
            object.redirects = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.questions && message.questions.length) {
            object.questions = new Array(message.questions.length);
            for (let i = 0; i < message.questions.length; ++i) {
              object.questions[i] = $root.formsort.v1.FormsortQuestion.toObject(
                message.questions[i],
                options
              );
            }
          }
          if (message.redirects && message.redirects.length) {
            object.redirects = new Array(message.redirects.length);
            for (let i = 0; i < message.redirects.length; ++i) {
              object.redirects[i] = $root.formsort.v1.FormsortRedirect.toObject(
                message.redirects[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortFlowContentStep';
        }
      }

      FormsortFlowContentStep.prototype.label = '';
      FormsortFlowContentStep.prototype.id = null;
      FormsortFlowContentStep.prototype.questions = $util.emptyArray;
      FormsortFlowContentStep.prototype.redirects = $util.emptyArray;

      return FormsortFlowContentStep;
    })();

    v1.FormsortQuestion = (() => {
      class FormsortQuestion {
        constructor(properties) {
          this.choices = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortQuestion(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(18).string(message.type);
          }
          if (
            message.schemaKey != null &&
            Object.hasOwnProperty.call(message, 'schemaKey')
          ) {
            writer.uint32(26).string(message.schemaKey);
          }
          if (
            message.choices != null &&
            Object.hasOwnProperty.call(message, 'choices')
          ) {
            for (const element of message.choices) {
              $root.formsort.v1.FormsortChoice.encode(
                element,
                writer.uint32(34).fork()
              ).ldelim();
            }
          }
          if (
            message.optional != null &&
            Object.hasOwnProperty.call(message, 'optional')
          ) {
            writer.uint32(40).bool(message.optional);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortQuestion();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.type = reader.string();
                break;
              }
              case 3: {
                message.schemaKey = reader.string();
                break;
              }
              case 4: {
                if (!message.choices || !message.choices.length) {
                  message.choices = [];
                }
                message.choices.push(
                  $root.formsort.v1.FormsortChoice.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 5: {
                message.optional = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortQuestion) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortQuestion();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.schemaKey != null) {
            message.schemaKey = String(object.schemaKey);
          }
          if (object.choices) {
            if (!Array.isArray(object.choices)) {
              throw new TypeError(
                '.formsort.v1.FormsortQuestion.choices: array type expected, but got ' +
                  typeof object.choices
              );
            }
            message.choices = new Array(object.choices.length);
            for (let i = 0; i < object.choices.length; ++i) {
              if (typeof object.choices[i] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortQuestion.choices: object expected, but got ' +
                    typeof object.choices[i]
                );
              }
              message.choices[i] = $root.formsort.v1.FormsortChoice.fromObject(
                object.choices[i]
              );
            }
          }
          if (object.optional != null) {
            message.optional = Boolean(object.optional);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.choices = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.type = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (
            message.schemaKey != null &&
            message.hasOwnProperty('schemaKey')
          ) {
            object.schemaKey = message.schemaKey;
          }
          if (message.choices && message.choices.length) {
            object.choices = new Array(message.choices.length);
            for (let i = 0; i < message.choices.length; ++i) {
              object.choices[i] = $root.formsort.v1.FormsortChoice.toObject(
                message.choices[i],
                options
              );
            }
          }
          if (message.optional != null && message.hasOwnProperty('optional')) {
            object.optional = message.optional;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortQuestion';
        }
      }

      FormsortQuestion.prototype.label = '';
      FormsortQuestion.prototype.type = '';
      FormsortQuestion.prototype.schemaKey = null;
      FormsortQuestion.prototype.choices = $util.emptyArray;
      FormsortQuestion.prototype.optional = null;

      return FormsortQuestion;
    })();

    v1.FormsortChoice = (() => {
      class FormsortChoice {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortChoice(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(18).string(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortChoice();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.value = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortChoice) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortChoice();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.value != null) {
            message.value = String(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.value = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortChoice';
        }
      }

      FormsortChoice.prototype.label = '';
      FormsortChoice.prototype.value = '';

      return FormsortChoice;
    })();

    v1.FormsortRedirect = (() => {
      class FormsortRedirect {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortRedirect(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.url != null &&
            Object.hasOwnProperty.call(message, 'url')
          ) {
            writer.uint32(10).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortRedirect();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortRedirect) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortRedirect();
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.url = '';
          }
          let keys;
          if (message.url != null && message.hasOwnProperty('url')) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortRedirect';
        }
      }

      FormsortRedirect.prototype.url = '';

      return FormsortRedirect;
    })();

    v1.FormsortDenormAnswerKeys = (() => {
      class FormsortDenormAnswerKeys {
        constructor(properties) {
          this.external = {};
          this.calculated = {};
          this.api = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortDenormAnswerKeys(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.external != null &&
            Object.hasOwnProperty.call(message, 'external')
          ) {
            for (const key of Object.keys(message.external)) {
              writer.uint32(10).fork().uint32(10).string(key);
              $root.formsort.v1.FormsortDenormExternalAnswerKey.encode(
                message.external[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          if (
            message.calculated != null &&
            Object.hasOwnProperty.call(message, 'calculated')
          ) {
            for (const key of Object.keys(message.calculated)) {
              writer
                .uint32(18)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.calculated[key])
                .ldelim();
            }
          }
          if (
            message.api != null &&
            Object.hasOwnProperty.call(message, 'api')
          ) {
            for (const key of Object.keys(message.api)) {
              writer.uint32(26).fork().uint32(10).string(key);
              $root.formsort.v1.FormsortApiAnswerKey.encode(
                message.api[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortDenormAnswerKeys();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.external === $util.emptyObject)
                  message.external = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value =
                        $root.formsort.v1.FormsortDenormExternalAnswerKey.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                  }
                  message.external[key] = value;
                }
                break;
              }
              case 2: {
                if (message.calculated === $util.emptyObject)
                  message.calculated = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.calculated[key] = value;
                }
                break;
              }
              case 3: {
                if (message.api === $util.emptyObject) message.api = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = $root.formsort.v1.FormsortApiAnswerKey.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                  }
                  message.api[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortDenormAnswerKeys) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortDenormAnswerKeys();
          if (object.external) {
            if (typeof object.external !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortDenormAnswerKeys.external: object expected, but got ' +
                  typeof object.external
              );
            }
            message.external = {};
            for (
              let keys = Object.keys(object.external), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.external[keys[i]] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortDenormAnswerKeys.value: object expected, but got ' +
                    typeof object.external[keys[i]]
                );
              }
              message.external[keys[i]] =
                $root.formsort.v1.FormsortDenormExternalAnswerKey.fromObject(
                  object.external[keys[i]]
                );
            }
          }
          if (object.calculated) {
            if (typeof object.calculated !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortDenormAnswerKeys.calculated: object expected, but got ' +
                  typeof object.calculated
              );
            }
            message.calculated = {};
            for (
              let keys = Object.keys(object.calculated), i = 0;
              i < keys.length;
              ++i
            ) {
              message.calculated[keys[i]] = String(object.calculated[keys[i]]);
            }
          }
          if (object.api) {
            if (typeof object.api !== 'object') {
              throw new TypeError(
                '.formsort.v1.FormsortDenormAnswerKeys.api: object expected, but got ' +
                  typeof object.api
              );
            }
            message.api = {};
            for (
              let keys = Object.keys(object.api), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.api[keys[i]] !== 'object') {
                throw new TypeError(
                  '.formsort.v1.FormsortDenormAnswerKeys.value: object expected, but got ' +
                    typeof object.api[keys[i]]
                );
              }
              message.api[keys[i]] =
                $root.formsort.v1.FormsortApiAnswerKey.fromObject(
                  object.api[keys[i]]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.external = {};
            object.calculated = {};
            object.api = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.external &&
            (keys = Object.keys(message.external)).length
          ) {
            object.external = {};
            for (let i = 0; i < keys.length; ++i) {
              object.external[keys[i]] =
                $root.formsort.v1.FormsortDenormExternalAnswerKey.toObject(
                  message.external[keys[i]],
                  options
                );
            }
          }
          if (
            message.calculated &&
            (keys = Object.keys(message.calculated)).length
          ) {
            object.calculated = {};
            for (let i = 0; i < keys.length; ++i) {
              object.calculated[keys[i]] = message.calculated[keys[i]];
            }
          }
          if (message.api && (keys = Object.keys(message.api)).length) {
            object.api = {};
            for (let i = 0; i < keys.length; ++i) {
              object.api[keys[i]] =
                $root.formsort.v1.FormsortApiAnswerKey.toObject(
                  message.api[keys[i]],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortDenormAnswerKeys';
        }
      }

      FormsortDenormAnswerKeys.prototype.external = $util.emptyObject;
      FormsortDenormAnswerKeys.prototype.calculated = $util.emptyObject;
      FormsortDenormAnswerKeys.prototype.api = $util.emptyObject;

      return FormsortDenormAnswerKeys;
    })();

    v1.FormsortDenormExternalAnswerKey = (() => {
      class FormsortDenormExternalAnswerKey {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortDenormExternalAnswerKey(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.required != null &&
            Object.hasOwnProperty.call(message, 'required')
          ) {
            writer.uint32(8).bool(message.required);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortDenormExternalAnswerKey();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.required = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.formsort.v1.FormsortDenormExternalAnswerKey
          ) {
            return object;
          }
          const message =
            new $root.formsort.v1.FormsortDenormExternalAnswerKey();
          if (object.required != null) {
            message.required = Boolean(object.required);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.required = false;
          }
          let keys;
          if (message.required != null && message.hasOwnProperty('required')) {
            object.required = message.required;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortDenormExternalAnswerKey';
        }
      }

      FormsortDenormExternalAnswerKey.prototype.required = false;

      return FormsortDenormExternalAnswerKey;
    })();

    v1.FormsortApiAnswerKey = (() => {
      class FormsortApiAnswerKey {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortApiAnswerKey(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.apiResultAccessor != null &&
            Object.hasOwnProperty.call(message, 'apiResultAccessor')
          ) {
            writer.uint32(10).string(message.apiResultAccessor);
          }
          if (
            message.mapperFn != null &&
            Object.hasOwnProperty.call(message, 'mapperFn')
          ) {
            writer.uint32(18).string(message.mapperFn);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortApiAnswerKey();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.apiResultAccessor = reader.string();
                break;
              }
              case 2: {
                message.mapperFn = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.formsort.v1.FormsortApiAnswerKey) {
            return object;
          }
          const message = new $root.formsort.v1.FormsortApiAnswerKey();
          if (object.apiResultAccessor != null) {
            message.apiResultAccessor = String(object.apiResultAccessor);
          }
          if (object.mapperFn != null) {
            message.mapperFn = String(object.mapperFn);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.apiResultAccessor != null &&
            message.hasOwnProperty('apiResultAccessor')
          ) {
            object.apiResultAccessor = message.apiResultAccessor;
          }
          if (message.mapperFn != null && message.hasOwnProperty('mapperFn')) {
            object.mapperFn = message.mapperFn;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'formsort.v1.FormsortApiAnswerKey';
        }
      }

      FormsortApiAnswerKey.prototype.apiResultAccessor = null;
      FormsortApiAnswerKey.prototype.mapperFn = null;

      return FormsortApiAnswerKey;
    })();

    v1.FormsortEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[
        (valuesById[0] = 'UNSPECIFIED_ILLEGAL_FORMSORT_WEBHOOK_EVENT_TYPE')
      ] = 0;
      values[(valuesById[1] = 'FORMSORT_STEP_COMPLETED')] = 1;
      return values;
    })();

    v1.FormsortService = (() => {
      class FormsortService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new FormsortService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (FormsortService.prototype.listFlows = function listFlows(
          request,
          callback
        ) {
          return this.rpcCall(
            listFlows,
            $root.formsort.v1.ListFlowRequest,
            $root.formsort.v1.ListFlowResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListFlows' }
      );

      Object.defineProperty(
        (FormsortService.prototype.listVariants = function listVariants(
          request,
          callback
        ) {
          return this.rpcCall(
            listVariants,
            $root.formsort.v1.ListVariantsRequest,
            $root.formsort.v1.ListVariantsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListVariants' }
      );

      Object.defineProperty(
        (FormsortService.prototype.listDeployments = function listDeployments(
          request,
          callback
        ) {
          return this.rpcCall(
            listDeployments,
            $root.formsort.v1.ListDeploymentsRequest,
            $root.formsort.v1.ListDeploymentsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListDeployments' }
      );

      Object.defineProperty(
        (FormsortService.prototype.getVariantRevisionDetailed =
          function getVariantRevisionDetailed(request, callback) {
            return this.rpcCall(
              getVariantRevisionDetailed,
              $root.formsort.v1.GetVariantRevisionDetailedRequest,
              $root.formsort.v1.GetVariantRevisionDetailedResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetVariantRevisionDetailed' }
      );

      return FormsortService;
    })();

    return v1;
  })();

  return formsort;
})();

export const formsort = $root.formsort;
