import { Button } from '../../../../../Button';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { Text } from '../../../../../Typography';
import { Textarea } from '../../../../../Textarea';
import { TriggerNodeProps } from '../../types';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const TriggerExtraFilter: FC<TriggerNodeProps> = ({ data }) => {
  const theme = useTheme();
  const { configuration, onUpdateTrigger, isReadOnly } = data.value;

  const celExpressionStr = configuration?.extraFilter?.celExpressionStr;
  const [updatedCelExpressionStr, setUpdatedCelExpressionStr] =
    useState(celExpressionStr);

  const isValid = updatedCelExpressionStr !== celExpressionStr;

  useHotkeys(
    'enter',
    () => {
      if (isValid) {
        handleNodeSave();
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: ['textarea'],
    }
  );

  const handleNodeSave = () => {
    onUpdateTrigger &&
      onUpdateTrigger({
        trigger: new workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger({
          triggerEventTypes: configuration?.triggerEvents,
          triggerExtraCelExpression: updatedCelExpressionStr || null,
          triggerObjectCategory: configuration?.objectCategory,
          triggerObjectTypeFilters: configuration?.objectTypeFilters,
          uniquenessConflictResolution:
            configuration?.uniquenessConflictResolution,
        }),
      });
  };

  return (
    <Flexbox
      data-testid='trigger-extra-filter'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <Text tag='p2'>Add custom condition</Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Optional condition for triggering workflow
        </Text>
      </Flexbox>

      <Textarea
        name='cel-expression-text-area'
        placeholder='custom CEL expression'
        defaultValue={updatedCelExpressionStr}
        onChange={(e) => setUpdatedCelExpressionStr(e.target.value)}
        readOnly={isReadOnly}
        rows={5}
      />

      <Button
        size='sm'
        variant='secondary'
        onClick={handleNodeSave}
        isDisabled={!isValid}
      >
        <Text tag='p2' color='inherit'>
          Save
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedTriggerExtraFilter = memo(TriggerExtraFilter);
