import { ThemeContainerProps, css, styled } from '@morf/theming';
import { Button } from '../Button';

const LightFill = css(
  ({ theme }) => `
      color: ${theme.colors.main.primary.darker};
      background-color: ${theme.colors.main.light.normal};

      :hover {
        background-color: ${theme.colors.ui.hover};
        color: ${theme.colors.text.body};
      }

      :active{
        color: ${theme.colors.text.inverse};
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
     
      :focus {
        border: 4px solid ${theme.colors.main.light.normal};       
        background-color: ${theme.colors.body};
      }
      `
);

const DarkFill = css(
  ({ theme }) => `
      color: ${theme.colors.text.inverse};
      background-color: ${theme.colors.main.primary.darkest};

      :hover {
        background-color: ${theme.colors.main.light.normal};
        color: ${theme.colors.text.body};
      }

      :active {
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
    
      :focus {
        border: 4px solid ${theme.colors.main.light.normal};
        background-color: ${theme.colors.main.primary.darker};
      }
      `
);

const Outline = css(
  ({ theme }) => `
      color: ${theme.colors.main.primary.darker};
      background-color: ${theme.colors.ui.card};
      border: 1px solid ${theme.colors.main.primary.darker};

      :hover {
        background-color: ${theme.colors.main.light.normal};
        color: ${theme.colors.text.body};
        }

      :active{
        color: ${theme.colors.text.inverse};
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
    
      :focus{
        border: 4px solid ${theme.colors.main.light.normal};
       }
      `
);

const handleStyle = (theme: ThemeContainerProps) => {
  const style = theme.timePicker.style;
  switch (style) {
    case 'lightFill':
      return LightFill;
    case 'darkFill':
      return DarkFill;
    case 'outline':
      return Outline;
  }
};

export const StyledTimeslot = styled(Button)<{
  isActive: boolean;
}>`
  width: 100%;
  min-height: 2.5rem;
  border-radius: ${(props) =>
    props.theme.borderRadius[props.theme.timePicker.borderRadius]};
  border: ${(props) => props.theme.borderWidth[0.0625]} solid transparent;
  transition: all 0.5s ease-out;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.theme.colors.text.inverse};
      background-color: ${props.theme.colors.main.primary.darkest};
      border-color: ${props.theme.colors.main.primary.darkest};
    `}

  ${(props) => !props.isActive && handleStyle(props.theme)};
`;
