import { styled } from '@morf/theming';
import { ImageProps } from './type';

export const StyledImage = styled.img<ImageProps>`
  border-radius: ${(props) => props.theme.borderRadius[props.radius || 0]};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: ${(props) =>
    props.hasVisibleBorder
      ? `${props.theme.borderWidth[0.0625]} solid ${props.theme.colors.ui.divider}`
      : 'none'};
`;
