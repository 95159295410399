'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { permissions as permissions$1 } from './permissions_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.api_key = (() => {
  const api_key = {};
  api_key.v1 = (() => {
    const v1 = {};
    v1.APIKey = (() => {
      class APIKey {
        constructor(properties) {
          this.permissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new APIKey(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.permissions != null &&
            Object.hasOwnProperty.call(message, 'permissions')
          ) {
            for (const element of message.permissions) {
              permissions$1.v1.Permission.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.keyVersion != null &&
            Object.hasOwnProperty.call(message, 'keyVersion')
          ) {
            writer.uint32(24).uint32(message.keyVersion);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new APIKey();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                if (!message.permissions || !message.permissions.length) {
                  message.permissions = [];
                }
                message.permissions.push(
                  permissions$1.v1.Permission.decode(reader, reader.uint32())
                );
                break;
              }
              case 3: {
                message.keyVersion = reader.uint32();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.APIKey) {
            return object;
          }
          const message = new $root.api_key.v1.APIKey();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.permissions) {
            if (!Array.isArray(object.permissions)) {
              throw new TypeError(
                '.api_key.v1.APIKey.permissions: array type expected, but got ' +
                  typeof object.permissions
              );
            }
            message.permissions = new Array(object.permissions.length);
            for (let i = 0; i < object.permissions.length; ++i) {
              if (typeof object.permissions[i] !== 'object') {
                throw new TypeError(
                  '.api_key.v1.APIKey.permissions: object expected, but got ' +
                    typeof object.permissions[i]
                );
              }
              message.permissions[i] = permissions$1.v1.Permission.fromObject(
                object.permissions[i]
              );
            }
          }
          if (object.keyVersion != null) {
            message.keyVersion = object.keyVersion >>> 0;
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.api_key.v1.APIKey.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.permissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.keyVersion = 0;
            object.createdAt = null;
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.permissions && message.permissions.length) {
            object.permissions = new Array(message.permissions.length);
            for (let i = 0; i < message.permissions.length; ++i) {
              object.permissions[i] = permissions$1.v1.Permission.toObject(
                message.permissions[i],
                options
              );
            }
          }
          if (
            message.keyVersion != null &&
            message.hasOwnProperty('keyVersion')
          ) {
            object.keyVersion = message.keyVersion;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.APIKey';
        }
      }

      APIKey.prototype.organizationId = '';
      APIKey.prototype.permissions = $util.emptyArray;
      APIKey.prototype.keyVersion = 0;
      APIKey.prototype.createdAt = null;

      return APIKey;
    })();

    v1.APIKeyState = (() => {
      class APIKeyState {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new APIKeyState(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.apiKey != null &&
            Object.hasOwnProperty.call(message, 'apiKey')
          ) {
            $root.api_key.v1.APIKey.encode(
              message.apiKey,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.enabled != null &&
            Object.hasOwnProperty.call(message, 'enabled')
          ) {
            writer.uint32(24).bool(message.enabled);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new APIKeyState();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.apiKey = $root.api_key.v1.APIKey.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.enabled = reader.bool();
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.APIKeyState) {
            return object;
          }
          const message = new $root.api_key.v1.APIKeyState();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.apiKey != null) {
            if (typeof object.apiKey !== 'object') {
              throw new TypeError(
                '.api_key.v1.APIKeyState.apiKey: object expected, but got ' +
                  typeof object.apiKey
              );
            }
            message.apiKey = $root.api_key.v1.APIKey.fromObject(object.apiKey);
          }
          if (object.enabled != null) {
            message.enabled = Boolean(object.enabled);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.api_key.v1.APIKeyState.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.apiKey = null;
            object.enabled = false;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.apiKey != null && message.hasOwnProperty('apiKey')) {
            object.apiKey = $root.api_key.v1.APIKey.toObject(
              message.apiKey,
              options
            );
          }
          if (message.enabled != null && message.hasOwnProperty('enabled')) {
            object.enabled = message.enabled;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.APIKeyState';
        }
      }

      APIKeyState.prototype.id = '';
      APIKeyState.prototype.apiKey = null;
      APIKeyState.prototype.enabled = false;
      APIKeyState.prototype.updatedAt = null;

      return APIKeyState;
    })();

    v1.CreateAPIKeyRequest = (() => {
      class CreateAPIKeyRequest {
        constructor(properties) {
          this.permissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAPIKeyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.permissions != null &&
            Object.hasOwnProperty.call(message, 'permissions')
          ) {
            for (const element of message.permissions) {
              permissions$1.v1.Permission.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAPIKeyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.permissions || !message.permissions.length) {
                  message.permissions = [];
                }
                message.permissions.push(
                  permissions$1.v1.Permission.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.CreateAPIKeyRequest) {
            return object;
          }
          const message = new $root.api_key.v1.CreateAPIKeyRequest();
          if (object.permissions) {
            if (!Array.isArray(object.permissions)) {
              throw new TypeError(
                '.api_key.v1.CreateAPIKeyRequest.permissions: array type expected, but got ' +
                  typeof object.permissions
              );
            }
            message.permissions = new Array(object.permissions.length);
            for (let i = 0; i < object.permissions.length; ++i) {
              if (typeof object.permissions[i] !== 'object') {
                throw new TypeError(
                  '.api_key.v1.CreateAPIKeyRequest.permissions: object expected, but got ' +
                    typeof object.permissions[i]
                );
              }
              message.permissions[i] = permissions$1.v1.Permission.fromObject(
                object.permissions[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.permissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.permissions && message.permissions.length) {
            object.permissions = new Array(message.permissions.length);
            for (let i = 0; i < message.permissions.length; ++i) {
              object.permissions[i] = permissions$1.v1.Permission.toObject(
                message.permissions[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.CreateAPIKeyRequest';
        }
      }

      CreateAPIKeyRequest.prototype.permissions = $util.emptyArray;

      return CreateAPIKeyRequest;
    })();

    v1.CreateAPIKeyResponse = (() => {
      class CreateAPIKeyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAPIKeyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.signedApiKey != null &&
            Object.hasOwnProperty.call(message, 'signedApiKey')
          ) {
            writer.uint32(10).string(message.signedApiKey);
          }
          if (
            message.apiKeyState != null &&
            Object.hasOwnProperty.call(message, 'apiKeyState')
          ) {
            $root.api_key.v1.APIKeyState.encode(
              message.apiKeyState,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAPIKeyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.signedApiKey = reader.string();
                break;
              }
              case 2: {
                message.apiKeyState = $root.api_key.v1.APIKeyState.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.CreateAPIKeyResponse) {
            return object;
          }
          const message = new $root.api_key.v1.CreateAPIKeyResponse();
          if (object.signedApiKey != null) {
            message.signedApiKey = String(object.signedApiKey);
          }
          if (object.apiKeyState != null) {
            if (typeof object.apiKeyState !== 'object') {
              throw new TypeError(
                '.api_key.v1.CreateAPIKeyResponse.apiKeyState: object expected, but got ' +
                  typeof object.apiKeyState
              );
            }
            message.apiKeyState = $root.api_key.v1.APIKeyState.fromObject(
              object.apiKeyState
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.signedApiKey = '';
            object.apiKeyState = null;
          }
          let keys;
          if (
            message.signedApiKey != null &&
            message.hasOwnProperty('signedApiKey')
          ) {
            object.signedApiKey = message.signedApiKey;
          }
          if (
            message.apiKeyState != null &&
            message.hasOwnProperty('apiKeyState')
          ) {
            object.apiKeyState = $root.api_key.v1.APIKeyState.toObject(
              message.apiKeyState,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.CreateAPIKeyResponse';
        }
      }

      CreateAPIKeyResponse.prototype.signedApiKey = '';
      CreateAPIKeyResponse.prototype.apiKeyState = null;

      return CreateAPIKeyResponse;
    })();

    v1.ListAPIKeysRequest = (() => {
      class ListAPIKeysRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAPIKeysRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAPIKeysRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.ListAPIKeysRequest) {
            return object;
          }
          const message = new $root.api_key.v1.ListAPIKeysRequest();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.ListAPIKeysRequest';
        }
      }

      return ListAPIKeysRequest;
    })();

    v1.ListAPIKeysResponse = (() => {
      class ListAPIKeysResponse {
        constructor(properties) {
          this.apiKeyStates = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAPIKeysResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.apiKeyStates != null &&
            Object.hasOwnProperty.call(message, 'apiKeyStates')
          ) {
            for (const element of message.apiKeyStates) {
              $root.api_key.v1.APIKeyState.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAPIKeysResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.apiKeyStates || !message.apiKeyStates.length) {
                  message.apiKeyStates = [];
                }
                message.apiKeyStates.push(
                  $root.api_key.v1.APIKeyState.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.ListAPIKeysResponse) {
            return object;
          }
          const message = new $root.api_key.v1.ListAPIKeysResponse();
          if (object.apiKeyStates) {
            if (!Array.isArray(object.apiKeyStates)) {
              throw new TypeError(
                '.api_key.v1.ListAPIKeysResponse.apiKeyStates: array type expected, but got ' +
                  typeof object.apiKeyStates
              );
            }
            message.apiKeyStates = new Array(object.apiKeyStates.length);
            for (let i = 0; i < object.apiKeyStates.length; ++i) {
              if (typeof object.apiKeyStates[i] !== 'object') {
                throw new TypeError(
                  '.api_key.v1.ListAPIKeysResponse.apiKeyStates: object expected, but got ' +
                    typeof object.apiKeyStates[i]
                );
              }
              message.apiKeyStates[i] = $root.api_key.v1.APIKeyState.fromObject(
                object.apiKeyStates[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.apiKeyStates = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.apiKeyStates && message.apiKeyStates.length) {
            object.apiKeyStates = new Array(message.apiKeyStates.length);
            for (let i = 0; i < message.apiKeyStates.length; ++i) {
              object.apiKeyStates[i] = $root.api_key.v1.APIKeyState.toObject(
                message.apiKeyStates[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.ListAPIKeysResponse';
        }
      }

      ListAPIKeysResponse.prototype.apiKeyStates = $util.emptyArray;

      return ListAPIKeysResponse;
    })();

    v1.DeleteAPIKeyRequest = (() => {
      class DeleteAPIKeyRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteAPIKeyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteAPIKeyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.DeleteAPIKeyRequest) {
            return object;
          }
          const message = new $root.api_key.v1.DeleteAPIKeyRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.DeleteAPIKeyRequest';
        }
      }

      DeleteAPIKeyRequest.prototype.id = '';

      return DeleteAPIKeyRequest;
    })();

    v1.DeleteAPIKeyResponse = (() => {
      class DeleteAPIKeyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteAPIKeyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.apiKeyState != null &&
            Object.hasOwnProperty.call(message, 'apiKeyState')
          ) {
            $root.api_key.v1.APIKeyState.encode(
              message.apiKeyState,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteAPIKeyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.apiKeyState = $root.api_key.v1.APIKeyState.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.api_key.v1.DeleteAPIKeyResponse) {
            return object;
          }
          const message = new $root.api_key.v1.DeleteAPIKeyResponse();
          if (object.apiKeyState != null) {
            if (typeof object.apiKeyState !== 'object') {
              throw new TypeError(
                '.api_key.v1.DeleteAPIKeyResponse.apiKeyState: object expected, but got ' +
                  typeof object.apiKeyState
              );
            }
            message.apiKeyState = $root.api_key.v1.APIKeyState.fromObject(
              object.apiKeyState
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.apiKeyState = null;
          }
          let keys;
          if (
            message.apiKeyState != null &&
            message.hasOwnProperty('apiKeyState')
          ) {
            object.apiKeyState = $root.api_key.v1.APIKeyState.toObject(
              message.apiKeyState,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'api_key.v1.DeleteAPIKeyResponse';
        }
      }

      DeleteAPIKeyResponse.prototype.apiKeyState = null;

      return DeleteAPIKeyResponse;
    })();

    return v1;
  })();

  return api_key;
})();

export const api_key = $root.api_key;
