import { Node } from 'reactflow';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { NodeType, onUpdateProfileLookupData } from '../types';
import { Dispatch, SetStateAction } from 'react';
import { validateLookupNode } from './validateLookupNode';

export const handleLookupNodeSave = (
  lookupNode: profiles.v1.LookupConfiguration,
  nodes: Node[],
  setNodes: Dispatch<SetStateAction<Node[]>>,
  onUpdateProfileLookup?: (data: onUpdateProfileLookupData) => void
) => {
  const updatedNodes = nodes.map((n) => {
    if (n.type === NodeType.ProfileLookupNode) {
      return {
        ...n,
        data: {
          ...n.data,
          value: {
            ...n.data.value,
            configuration: lookupNode,
          },
        },
      };
    }
    return n;
  });

  setNodes(updatedNodes);
  const isValidlookup = validateLookupNode(lookupNode);
  isValidlookup &&
    onUpdateProfileLookup &&
    onUpdateProfileLookup({ profileLookupConfiguration: lookupNode });
};
