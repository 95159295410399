export const FilterLines = () => {
  return (
    <>
      <path
        d='M4.41699 7.67769C7.04485 7.23206 9.74538 7 12.5001 7C15.2548 7 17.9553 7.23205 20.5831 7.67767'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 12.1643C15.1811 12.0555 13.8471 12 12.5001 12C11.1532 12 9.81914 12.0555 8.50024 12.1643'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1894 17.0292C13.6287 17.0098 13.0656 17 12.5002 17C11.9208 17 11.3437 17.0103 10.7693 17.0306'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
