import { PanelTableProps } from './types';
import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';
import { memo, useState } from 'react';
import { Container } from '../Container';
import { Allotment } from 'allotment';
import { ExtendedRowData } from '../Table/types';
import { FullPageTable } from '../FullPageTable';

const Panel: <RowData>(
  props: PanelTableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({
  onRowClick,
  panelContent,
  panelTitle,
  selectedRow,
  ...props
}) => {
  const [showPanelContent, setShowPanelContent] = useState(false);

  const handleClick = () => {
    setShowPanelContent(!showPanelContent);
  };

  const panelSize = showPanelContent
    ? { preferred: 390, min: 390, max: 800 }
    : { preferred: 0, min: 0, max: 0 };

  const panelHeader = (
    <PanelHeader
      panelTitle={panelTitle}
      isOpen={showPanelContent}
      onClick={handleClick}
    />
  );

  return (
    <Allotment proportionalLayout={false}>
      <Allotment.Pane>
        <Container position='relative' data-testid='panel-table'>
          <FullPageTable
            selectedRow={selectedRow}
            onRowClick={
              showPanelContent
                ? (rowId) => {
                    onRowClick && onRowClick(rowId);
                    rowId === null && setShowPanelContent(false);
                  }
                : (rowId) => {
                    onRowClick && onRowClick(rowId);
                    handleClick();
                  }
            }
            {...props}
          />
          {!showPanelContent && (
            <Container width='auto' height='auto' data-testid='hidden-panel'>
              {panelHeader}
            </Container>
          )}
        </Container>
      </Allotment.Pane>
      <Allotment.Pane
        preferredSize={panelSize.preferred}
        minSize={panelSize.min}
        maxSize={panelSize.max}
      >
        <Container key={selectedRow} data-testid='visible-panel'>
          {panelHeader}
          <PanelContent panelContent={panelContent} />
        </Container>
      </Allotment.Pane>
    </Allotment>
  );
};

export const PanelTable = memo(Panel) as typeof Panel;
