import { useTheme } from '@morf/theming';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { TimelineGroupHeaderProps } from './types';
import { Text } from '../Typography';
import { IntegrationIcon } from '../IntegrationIcon';

const TimelineGroupHeader: FC<TimelineGroupHeaderProps> = ({
  integrationIconName,
  isExpanded,
  workflow,
  nrOfEvents,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      data-testid='timeline-group-header'
      backgroundColor={theme.colors.ui.card}
      borderType='borderBottom'
      height='2.25rem'
      cursor='pointer'
      px={1}
      py={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        width='auto'
        gap={0.25}
      >
        <Flexbox
          width='1.25rem'
          justifyContent='flex-start'
          alignItems='center'
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <Icon
            name={isExpanded ? 'carrot-down' : 'carrot-right'}
            fill={theme.colors.ui.dark}
            stroke='none'
            size={1.25}
            cursor='pointer'
          />
        </Flexbox>
        <IntegrationIcon name={integrationIconName} />
        <Text tag='h6' whiteSpace='nowrap'>
          {workflow}
        </Text>
      </Flexbox>
      {nrOfEvents && (
        <Text tag='h6' color={theme.colors.text.muted} whiteSpace='nowrap'>
          {nrOfEvents} events
        </Text>
      )}
    </Flexbox>
  );
};

export const MemoizedTimelineGroupHeader = memo(TimelineGroupHeader);
