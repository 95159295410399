import _ from 'lodash';
import { Dropdown } from '../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { ConditionProps } from './types';
import { Text } from '../../Typography';
import { conditionWidth } from '../constants';

const Condition: FC<ConditionProps> = ({
  condition,
  conditionOptions,
  onConditionChange,
  readOnly,
}) => {
  return (
    <Flexbox
      data-testid='condition'
      justifyContent='center'
      alignItems='center'
      borderType='borderRight'
      minWidth={conditionWidth}
      maxWidth={conditionWidth}
      width={conditionWidth}
    >
      {_.isEqual(conditionOptions.length, 1) ? (
        <Text tag='p2'>{conditionOptions[0].label}</Text>
      ) : (
        <Dropdown
          defaultOptions={[condition]}
          options={conditionOptions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            onConditionChange && onConditionChange(selectedOption);
          }}
          height='2.25rem'
          borderType='none'
          iconName='chevron'
          iconSize={1}
          readOnly={readOnly}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedCondition = memo(Condition);
