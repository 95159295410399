import { Handle, Position } from 'reactflow';
import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { PauseNodeProps } from './types';
import { iconNameNodeTypeMap } from '../constants';
import { NodeType } from '../types';

const PauseNode: FC<PauseNodeProps> = ({ data, id }) => {
  const { isRequired, name, status, title } = data.value;
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel
        iconName={iconNameNodeTypeMap[NodeType.PauseNode]}
        id={id}
        isRequired={isRequired}
        name={name}
        status={status}
        title={title}
      />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedPauseNode = memo(PauseNode);
