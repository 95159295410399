export const GreenTicketIcon = () => {
  return (
    <svg
      width='104'
      height='129'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ paddingTop: '30px' }}
    >
      <path
        d='M6.75 5C6.19772 5 5.75 5.44772 5.75 6C5.75 6.55228 6.19772 7 6.75 7V5ZM62.75 63V80H64.75V63H62.75ZM6.75 7C37.6779 7 62.75 32.0721 62.75 63H64.75C64.75 30.9675 38.7825 5 6.75 5V7Z'
        fill='#E3E9FF'
      />
      <circle cx='6.75' cy='6' r='6' fill='#E3E9FF' />
      <circle cx='63.25' cy='89' r='40' fill='#E3E9FF' />
      <rect x='23' y='49' width='80' height='80' rx='20' fill='#0FB0A7' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.33 72C50.27 72 47 75.466 47 80.832V97.168C47 102.534 50.27 106 55.33 106H72.666C77.728 106 81 102.534 81 97.168V80.832C81 75.466 77.728 72 72.668 72H55.33ZM72.666 109H55.33C48.552 109 44 104.244 44 97.168V80.832C44 73.756 48.552 69 55.33 69H72.668C79.446 69 84 73.756 84 80.832V97.168C84 104.244 79.446 109 72.666 109V109Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.6264 95.2459C61.2444 95.2459 60.8584 95.0999 60.5664 94.8059L55.8184 90.0599C55.2324 89.4739 55.2324 88.5259 55.8184 87.9399C56.4044 87.3539 57.3524 87.3539 57.9384 87.9399L61.6264 91.6239L70.0584 83.1939C70.6444 82.6079 71.5924 82.6079 72.1784 83.1939C72.7644 83.7799 72.7644 84.7279 72.1784 85.3139L62.6864 94.8059C62.3944 95.0999 62.0104 95.2459 61.6264 95.2459'
        fill='white'
      />
    </svg>
  );
};
