export const LinkOut = () => {
  return (
    <>
      <path
        d='M16 16.8112C16 18.7766 14.6125 20 12.6491 20H7.35092C5.38746 20 4 18.7766 4 16.8112L4 11.1881C4 9.2227 5.38746 8 7.35092 8'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9077 4.05896L11.0583 12.9084'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7737 4.07604L19.9084 4.05871L19.8911 12.1934'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
