import { useContext } from 'react';
import { WorkflowContextProps } from './types';
import { WorkflowContext } from './workflowContext';

export const useWorkflow = (): WorkflowContextProps => {
  const context = useContext(WorkflowContext);

  if (!context) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }

  return context;
};
