import { FC, memo } from 'react';
import { FilterMenu } from '../FilterMenu';
import { FilterMenuOption } from '../FilterMenu/types';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Panel } from './Panel';
import { Search } from './Search';
import { Text } from '../Typography';
import { ToolbarProps } from './types';
import { useTheme } from '@morf/theming';

const Toolbar: FC<ToolbarProps> = ({
  borderType,
  filterOptions,
  isPanelOpen,
  searchTooltipText,
  searchValue,
  setIsPanelOpen,
  setSearchValue,
  showReadOnly,
  ...props
}) => {
  const theme = useTheme();

  const selectedFilterOptions = filterOptions.filter(
    ({ isSelected }) => isSelected
  );

  const unSelectedFilterOptions = filterOptions.filter(
    ({ isSelected }) => !isSelected
  );

  const handleClick = (filterOption: FilterMenuOption) => {
    filterOption.setIsSelected(true);
  };

  return (
    <Flexbox
      data-testid='toolbar'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      height='2.25rem'
      borderType={borderType}
      py={0.5}
      px={1}
      {...props}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.5}
      >
        {isPanelOpen !== undefined && setIsPanelOpen && (
          <Panel isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
        )}
        {searchValue !== undefined && searchTooltipText && setSearchValue && (
          <Search
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchTooltipText={searchTooltipText}
          />
        )}

        <FilterMenu
          filterOptions={unSelectedFilterOptions}
          onClick={handleClick}
        />

        <>{selectedFilterOptions.map(({ component }) => component)}</>
      </Flexbox>

      {showReadOnly && (
        <Flexbox
          data-testid='read-only-tag'
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          width='4.75rem'
          gap={0.25}
        >
          <Icon
            name='lock-closed'
            size={0.75}
            stroke={theme.colors.ui.dark}
            strokeWidth={2}
          />
          <Text tag='h6' color={theme.colors.text.muted}>
            Read Only
          </Text>
        </Flexbox>
      )}
    </Flexbox>
  );
};

export const MemoizedToolbar = memo(Toolbar);
