import { Container } from '../Container';
import { FC, memo } from 'react';
import { SearchMessageProps } from './types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const SearchMessage: FC<SearchMessageProps> = ({ nrOfRows, searchValue }) => {
  const theme = useTheme();
  let message;
  if (!nrOfRows && searchValue) {
    message = `No results for '${searchValue}'`;
  } else if (nrOfRows === 1) {
    message = `${nrOfRows} result for '${searchValue}'`;
  } else if (nrOfRows) {
    message = `${nrOfRows} results for '${searchValue}'`;
  }

  return message ? (
    <Container height='1rem' data-testid='search-message'>
      <Text tag='p2' color={theme.colors.text.muted}>
        {message}
      </Text>
    </Container>
  ) : null;
};

export const MemoizedSearchMessage = memo(SearchMessage);
