import { MORF_ORG_ID } from '@morf/constants';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

const getQueryParam = (param: string): string | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (!value || typeof value !== 'string') {
    return undefined;
  }

  return value;
};

const getQueryParamArray = (param: string): string[] | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return undefined;
};

export const useUrlParams = (): {
  attemptNumber?: string;
  classes?: string[];
  errorDetailLike?: string;
  eventId?: string;
  from?: string;
  issueErrorHash?: string;
  issueErrorType?: string;
  nodeId?: string;
  organizationId?: string;
  stytch_token_type?: string;
  tab?: string;
  thirdPartyId?: string;
  thirdPartyType?: string;
  time?: string;
  token?: string;
  workflowExecutionId?: string;
  workflowId?: string;
} => {
  const attemptNumber = getQueryParam('attemptNumber');
  const classes = getQueryParamArray('classes');
  const errorDetailLike = getQueryParam('errorDetailLike');
  const eventId = getQueryParam('eventId');
  const from = getQueryParam('from');
  const issueErrorHash = getQueryParam('issueErrorHash');
  const issueErrorType = getQueryParam('issueErrorType');
  const nodeId = getQueryParam('nodeId');
  const organizationId = getQueryParam('organizationId');
  const stytch_token_type = getQueryParam('stytch_token_type');
  const tab = getQueryParam('tab');
  const thirdPartyId = getQueryParam('thirdPartyId');
  const thirdPartyType = getQueryParam('thirdPartyType');
  const time = getQueryParam('time');
  const token = getQueryParam('token');
  const workflowExecutionId = getQueryParam('workflowExecutionId');
  const workflowId = getQueryParam('workflowId');

  if (organizationId && organizationId !== Cookies.get(MORF_ORG_ID)) {
    Cookies.set(MORF_ORG_ID, organizationId);
  }

  return {
    attemptNumber,
    classes,
    errorDetailLike,
    eventId,
    from,
    issueErrorHash,
    issueErrorType,
    nodeId,
    organizationId,
    stytch_token_type,
    tab,
    thirdPartyId,
    thirdPartyType,
    time,
    token,
    workflowExecutionId,
    workflowId,
  };
};
