'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$2 } from './any_ts_proto';
import { google as google$1 } from './struct_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.api = (() => {
    const api = {};
    api.expr = (() => {
      const expr = {};
      expr.v1alpha1 = (() => {
        const v1alpha1 = {};
        v1alpha1.Value = (() => {
          class Value {
            get kind() {
              for (const key of [
                'nullValue',
                'boolValue',
                'int64Value',
                'uint64Value',
                'doubleValue',
                'stringValue',
                'bytesValue',
                'enumValue',
                'objectValue',
                'mapValue',
                'listValue',
                'typeValue',
              ]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set kind(name) {
              for (const key of [
                'nullValue',
                'boolValue',
                'int64Value',
                'uint64Value',
                'doubleValue',
                'stringValue',
                'bytesValue',
                'enumValue',
                'objectValue',
                'mapValue',
                'listValue',
                'typeValue',
              ]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Value(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.nullValue != null &&
                Object.hasOwnProperty.call(message, 'nullValue')
              ) {
                writer.uint32(8).int32(message.nullValue);
              }
              if (
                message.boolValue != null &&
                Object.hasOwnProperty.call(message, 'boolValue')
              ) {
                writer.uint32(16).bool(message.boolValue);
              }
              if (
                message.int64Value != null &&
                Object.hasOwnProperty.call(message, 'int64Value')
              ) {
                writer.uint32(24).int64(message.int64Value);
              }
              if (
                message.uint64Value != null &&
                Object.hasOwnProperty.call(message, 'uint64Value')
              ) {
                writer.uint32(32).uint64(message.uint64Value);
              }
              if (
                message.doubleValue != null &&
                Object.hasOwnProperty.call(message, 'doubleValue')
              ) {
                writer.uint32(41).double(message.doubleValue);
              }
              if (
                message.stringValue != null &&
                Object.hasOwnProperty.call(message, 'stringValue')
              ) {
                writer.uint32(50).string(message.stringValue);
              }
              if (
                message.bytesValue != null &&
                Object.hasOwnProperty.call(message, 'bytesValue')
              ) {
                writer.uint32(58).bytes(message.bytesValue);
              }
              if (
                message.enumValue != null &&
                Object.hasOwnProperty.call(message, 'enumValue')
              ) {
                $root.google.api.expr.v1alpha1.EnumValue.encode(
                  message.enumValue,
                  writer.uint32(74).fork()
                ).ldelim();
              }
              if (
                message.objectValue != null &&
                Object.hasOwnProperty.call(message, 'objectValue')
              ) {
                google$2.protobuf.Any.encode(
                  message.objectValue,
                  writer.uint32(82).fork()
                ).ldelim();
              }
              if (
                message.mapValue != null &&
                Object.hasOwnProperty.call(message, 'mapValue')
              ) {
                $root.google.api.expr.v1alpha1.MapValue.encode(
                  message.mapValue,
                  writer.uint32(90).fork()
                ).ldelim();
              }
              if (
                message.listValue != null &&
                Object.hasOwnProperty.call(message, 'listValue')
              ) {
                $root.google.api.expr.v1alpha1.ListValue.encode(
                  message.listValue,
                  writer.uint32(98).fork()
                ).ldelim();
              }
              if (
                message.typeValue != null &&
                Object.hasOwnProperty.call(message, 'typeValue')
              ) {
                writer.uint32(122).string(message.typeValue);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Value();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.nullValue = reader.int32();
                    break;
                  }
                  case 2: {
                    message.boolValue = reader.bool();
                    break;
                  }
                  case 3: {
                    message.int64Value = reader.int64();
                    break;
                  }
                  case 4: {
                    message.uint64Value = reader.uint64();
                    break;
                  }
                  case 5: {
                    message.doubleValue = reader.double();
                    break;
                  }
                  case 6: {
                    message.stringValue = reader.string();
                    break;
                  }
                  case 7: {
                    message.bytesValue = reader.bytes();
                    break;
                  }
                  case 9: {
                    message.enumValue =
                      $root.google.api.expr.v1alpha1.EnumValue.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 10: {
                    message.objectValue = google$2.protobuf.Any.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 11: {
                    message.mapValue =
                      $root.google.api.expr.v1alpha1.MapValue.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 12: {
                    message.listValue =
                      $root.google.api.expr.v1alpha1.ListValue.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 15: {
                    message.typeValue = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Value) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Value();
              if (object.nullValue != null) {
                switch (object.nullValue) {
                  case 'NULL_VALUE':
                  case 0: {
                    message.nullValue = 0;
                    break;
                  }
                  default: {
                    if (typeof object.nullValue == 'number') {
                      message.nullValue = object.nullValue;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.boolValue != null) {
                message.boolValue = Boolean(object.boolValue);
              }
              if (object.int64Value != null) {
                if ($util.Long) {
                  (message.int64Value = $util.Long.fromValue(
                    object.int64Value
                  )).unsigned = false;
                } else if (typeof object.int64Value === 'string') {
                  message.int64Value = parseInt(object.int64Value, 10);
                } else if (typeof object.int64Value === 'number') {
                  message.int64Value = object.int64Value;
                } else if (typeof object.int64Value === 'object') {
                  message.int64Value = new $util.LongBits(
                    object.int64Value.low >>> 0,
                    object.int64Value.high >>> 0
                  ).toNumber();
                }
              }
              if (object.uint64Value != null) {
                if ($util.Long) {
                  (message.uint64Value = $util.Long.fromValue(
                    object.uint64Value
                  )).unsigned = true;
                } else if (typeof object.uint64Value === 'string') {
                  message.uint64Value = parseInt(object.uint64Value, 10);
                } else if (typeof object.uint64Value === 'number') {
                  message.uint64Value = object.uint64Value;
                } else if (typeof object.uint64Value === 'object') {
                  message.uint64Value = new $util.LongBits(
                    object.uint64Value.low >>> 0,
                    object.uint64Value.high >>> 0
                  ).toNumber(true);
                }
              }
              if (object.doubleValue != null) {
                message.doubleValue = Number(object.doubleValue);
              }
              if (object.stringValue != null) {
                message.stringValue = String(object.stringValue);
              }
              if (object.bytesValue != null) {
                if (typeof object.bytesValue === 'string') {
                  $util.base64.decode(
                    object.bytesValue,
                    (message.bytesValue = $util.newBuffer(
                      $util.base64.length(object.bytesValue)
                    )),
                    0
                  );
                } else if (object.bytesValue.length >= 0) {
                  message.bytesValue = object.bytesValue;
                }
              }
              if (object.enumValue != null) {
                if (typeof object.enumValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Value.enumValue: object expected, but got ' +
                      typeof object.enumValue
                  );
                }
                message.enumValue =
                  $root.google.api.expr.v1alpha1.EnumValue.fromObject(
                    object.enumValue
                  );
              }
              if (object.objectValue != null) {
                if (typeof object.objectValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Value.objectValue: object expected, but got ' +
                      typeof object.objectValue
                  );
                }
                message.objectValue = google$2.protobuf.Any.fromObject(
                  object.objectValue
                );
              }
              if (object.mapValue != null) {
                if (typeof object.mapValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Value.mapValue: object expected, but got ' +
                      typeof object.mapValue
                  );
                }
                message.mapValue =
                  $root.google.api.expr.v1alpha1.MapValue.fromObject(
                    object.mapValue
                  );
              }
              if (object.listValue != null) {
                if (typeof object.listValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Value.listValue: object expected, but got ' +
                      typeof object.listValue
                  );
                }
                message.listValue =
                  $root.google.api.expr.v1alpha1.ListValue.fromObject(
                    object.listValue
                  );
              }
              if (object.typeValue != null) {
                message.typeValue = String(object.typeValue);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (
                message.nullValue != null &&
                message.hasOwnProperty('nullValue')
              ) {
                object.nullValue =
                  options.enums === String
                    ? google$1.protobuf.NullValue[message.nullValue] ===
                      undefined
                      ? message.nullValue
                      : google$1.protobuf.NullValue[message.nullValue]
                    : message.nullValue;
                if (options.oneofs) {
                  object.kind = 'nullValue';
                }
              }
              if (
                message.boolValue != null &&
                message.hasOwnProperty('boolValue')
              ) {
                object.boolValue = message.boolValue;
                if (options.oneofs) {
                  object.kind = 'boolValue';
                }
              }
              if (
                message.int64Value != null &&
                message.hasOwnProperty('int64Value')
              ) {
                object.int64Value =
                  typeof message.int64Value === 'number'
                    ? options.longs === String
                      ? String(message.int64Value)
                      : message.int64Value
                    : options.longs === String
                    ? $util.Long.prototype.toString.call(message.int64Value)
                    : options.longs === Number
                    ? new $util.LongBits(
                        message.int64Value.low >>> 0,
                        message.int64Value.high >>> 0
                      ).toNumber()
                    : message.int64Value;
                if (options.oneofs) {
                  object.kind = 'int64Value';
                }
              }
              if (
                message.uint64Value != null &&
                message.hasOwnProperty('uint64Value')
              ) {
                object.uint64Value =
                  typeof message.uint64Value === 'number'
                    ? options.longs === String
                      ? String(message.uint64Value)
                      : message.uint64Value
                    : options.longs === String
                    ? $util.Long.prototype.toString.call(message.uint64Value)
                    : options.longs === Number
                    ? new $util.LongBits(
                        message.uint64Value.low >>> 0,
                        message.uint64Value.high >>> 0
                      ).toNumber(true)
                    : message.uint64Value;
                if (options.oneofs) {
                  object.kind = 'uint64Value';
                }
              }
              if (
                message.doubleValue != null &&
                message.hasOwnProperty('doubleValue')
              ) {
                object.doubleValue =
                  options.json && !isFinite(message.doubleValue)
                    ? String(message.doubleValue)
                    : message.doubleValue;
                if (options.oneofs) {
                  object.kind = 'doubleValue';
                }
              }
              if (
                message.stringValue != null &&
                message.hasOwnProperty('stringValue')
              ) {
                object.stringValue = message.stringValue;
                if (options.oneofs) {
                  object.kind = 'stringValue';
                }
              }
              if (
                message.bytesValue != null &&
                message.hasOwnProperty('bytesValue')
              ) {
                object.bytesValue =
                  options.bytes === String
                    ? $util.base64.encode(
                        message.bytesValue,
                        0,
                        message.bytesValue.length
                      )
                    : options.bytes === Array
                    ? Array.prototype.slice.call(message.bytesValue)
                    : message.bytesValue;
                if (options.oneofs) {
                  object.kind = 'bytesValue';
                }
              }
              if (
                message.enumValue != null &&
                message.hasOwnProperty('enumValue')
              ) {
                object.enumValue =
                  $root.google.api.expr.v1alpha1.EnumValue.toObject(
                    message.enumValue,
                    options
                  );
                if (options.oneofs) {
                  object.kind = 'enumValue';
                }
              }
              if (
                message.objectValue != null &&
                message.hasOwnProperty('objectValue')
              ) {
                object.objectValue = google$2.protobuf.Any.toObject(
                  message.objectValue,
                  options
                );
                if (options.oneofs) {
                  object.kind = 'objectValue';
                }
              }
              if (
                message.mapValue != null &&
                message.hasOwnProperty('mapValue')
              ) {
                object.mapValue =
                  $root.google.api.expr.v1alpha1.MapValue.toObject(
                    message.mapValue,
                    options
                  );
                if (options.oneofs) {
                  object.kind = 'mapValue';
                }
              }
              if (
                message.listValue != null &&
                message.hasOwnProperty('listValue')
              ) {
                object.listValue =
                  $root.google.api.expr.v1alpha1.ListValue.toObject(
                    message.listValue,
                    options
                  );
                if (options.oneofs) {
                  object.kind = 'listValue';
                }
              }
              if (
                message.typeValue != null &&
                message.hasOwnProperty('typeValue')
              ) {
                object.typeValue = message.typeValue;
                if (options.oneofs) {
                  object.kind = 'typeValue';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Value';
            }
          }

          Value.prototype.nullValue = 0;
          Value.prototype.boolValue = false;
          Value.prototype.int64Value = $util.Long
            ? $util.Long.fromBits(0, 0, false)
            : 0;
          Value.prototype.uint64Value = $util.Long
            ? $util.Long.fromBits(0, 0, true)
            : 0;
          Value.prototype.doubleValue = 0;
          Value.prototype.stringValue = '';
          Value.prototype.bytesValue = new Uint8Array();
          Value.prototype.enumValue = null;
          Value.prototype.objectValue = null;
          Value.prototype.mapValue = null;
          Value.prototype.listValue = null;
          Value.prototype.typeValue = '';

          return Value;
        })();

        v1alpha1.EnumValue = (() => {
          class EnumValue {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new EnumValue(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.type != null &&
                Object.hasOwnProperty.call(message, 'type')
              ) {
                writer.uint32(10).string(message.type);
              }
              if (
                message.value != null &&
                Object.hasOwnProperty.call(message, 'value')
              ) {
                writer.uint32(16).int32(message.value);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new EnumValue();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.type = reader.string();
                    break;
                  }
                  case 2: {
                    message.value = reader.int32();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.EnumValue) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.EnumValue();
              if (object.type != null) {
                message.type = String(object.type);
              }
              if (object.value != null) {
                message.value = object.value | 0;
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.type = '';
                object.value = 0;
              }
              let keys;
              if (message.type != null && message.hasOwnProperty('type')) {
                object.type = message.type;
              }
              if (message.value != null && message.hasOwnProperty('value')) {
                object.value = message.value;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.EnumValue';
            }
          }

          EnumValue.prototype.type = '';
          EnumValue.prototype.value = 0;

          return EnumValue;
        })();

        v1alpha1.ListValue = (() => {
          class ListValue {
            constructor(properties) {
              this.values = [];
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new ListValue(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.values != null &&
                Object.hasOwnProperty.call(message, 'values')
              ) {
                for (const element of message.values) {
                  $root.google.api.expr.v1alpha1.Value.encode(
                    element,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new ListValue();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    if (!message.values || !message.values.length) {
                      message.values = [];
                    }
                    message.values.push(
                      $root.google.api.expr.v1alpha1.Value.decode(
                        reader,
                        reader.uint32()
                      )
                    );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.ListValue) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.ListValue();
              if (object.values) {
                if (!Array.isArray(object.values)) {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.ListValue.values: array type expected, but got ' +
                      typeof object.values
                  );
                }
                message.values = new Array(object.values.length);
                for (let i = 0; i < object.values.length; ++i) {
                  if (typeof object.values[i] !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.ListValue.values: object expected, but got ' +
                        typeof object.values[i]
                    );
                  }
                  message.values[i] =
                    $root.google.api.expr.v1alpha1.Value.fromObject(
                      object.values[i]
                    );
                }
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
                object.values = [];
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (message.values && message.values.length) {
                object.values = new Array(message.values.length);
                for (let i = 0; i < message.values.length; ++i) {
                  object.values[i] =
                    $root.google.api.expr.v1alpha1.Value.toObject(
                      message.values[i],
                      options
                    );
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.ListValue';
            }
          }

          ListValue.prototype.values = $util.emptyArray;

          return ListValue;
        })();

        v1alpha1.MapValue = (() => {
          class MapValue {
            constructor(properties) {
              this.entries = [];
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new MapValue(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.entries != null &&
                Object.hasOwnProperty.call(message, 'entries')
              ) {
                for (const element of message.entries) {
                  $root.google.api.expr.v1alpha1.MapValue.Entry.encode(
                    element,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new MapValue();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    if (!message.entries || !message.entries.length) {
                      message.entries = [];
                    }
                    message.entries.push(
                      $root.google.api.expr.v1alpha1.MapValue.Entry.decode(
                        reader,
                        reader.uint32()
                      )
                    );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.MapValue) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.MapValue();
              if (object.entries) {
                if (!Array.isArray(object.entries)) {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.MapValue.entries: array type expected, but got ' +
                      typeof object.entries
                  );
                }
                message.entries = new Array(object.entries.length);
                for (let i = 0; i < object.entries.length; ++i) {
                  if (typeof object.entries[i] !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.MapValue.entries: object expected, but got ' +
                        typeof object.entries[i]
                    );
                  }
                  message.entries[i] =
                    $root.google.api.expr.v1alpha1.MapValue.Entry.fromObject(
                      object.entries[i]
                    );
                }
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
                object.entries = [];
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (message.entries && message.entries.length) {
                object.entries = new Array(message.entries.length);
                for (let i = 0; i < message.entries.length; ++i) {
                  object.entries[i] =
                    $root.google.api.expr.v1alpha1.MapValue.Entry.toObject(
                      message.entries[i],
                      options
                    );
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.MapValue';
            }
          }

          MapValue.prototype.entries = $util.emptyArray;
          MapValue.Entry = (() => {
            class Entry {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new Entry(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.key != null &&
                  Object.hasOwnProperty.call(message, 'key')
                ) {
                  $root.google.api.expr.v1alpha1.Value.encode(
                    message.key,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.value != null &&
                  Object.hasOwnProperty.call(message, 'value')
                ) {
                  $root.google.api.expr.v1alpha1.Value.encode(
                    message.value,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new Entry();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.key = $root.google.api.expr.v1alpha1.Value.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                    case 2: {
                      message.value =
                        $root.google.api.expr.v1alpha1.Value.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.MapValue.Entry
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.MapValue.Entry();
                if (object.key != null) {
                  if (typeof object.key !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.MapValue.Entry.key: object expected, but got ' +
                        typeof object.key
                    );
                  }
                  message.key = $root.google.api.expr.v1alpha1.Value.fromObject(
                    object.key
                  );
                }
                if (object.value != null) {
                  if (typeof object.value !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.MapValue.Entry.value: object expected, but got ' +
                        typeof object.value
                    );
                  }
                  message.value =
                    $root.google.api.expr.v1alpha1.Value.fromObject(
                      object.value
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.key = null;
                  object.value = null;
                }
                let keys;
                if (message.key != null && message.hasOwnProperty('key')) {
                  object.key = $root.google.api.expr.v1alpha1.Value.toObject(
                    message.key,
                    options
                  );
                }
                if (message.value != null && message.hasOwnProperty('value')) {
                  object.value = $root.google.api.expr.v1alpha1.Value.toObject(
                    message.value,
                    options
                  );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.MapValue.Entry'
                );
              }
            }

            Entry.prototype.key = null;
            Entry.prototype.value = null;

            return Entry;
          })();

          return MapValue;
        })();

        return v1alpha1;
      })();

      return expr;
    })();

    return api;
  })();

  return google;
})();

export const google = $root.google;
