import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { AppointmentHeaderProps } from './types';

export const AppointmentHeader: FC<AppointmentHeaderProps> = ({
  appointmentName,
  providerName,
  appointmentDate,
  appointmentTime,
  appointmentTimezone,
}) => {
  const theme = useTheme();
  return (
    <Flexbox direction='column' justifyContent='flex-start' gap={0.5}>
      <Text tag='h2'>
        {appointmentName} with {providerName}
      </Text>
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={0.25}
      >
        <Container width='1.25rem' height='1.25rem'>
          <Icon name='calendar' fill={theme.colors.ui.muted} stroke='none' />
        </Container>
        <Text tag='h5'>{appointmentDate}</Text>
      </Flexbox>
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={0.25}
      >
        <Container width='1.25rem' height='1.25rem'>
          <Icon name='clock' stroke={theme.colors.ui.muted} />
        </Container>
        <Text tag='h5'>{appointmentTime}</Text>
        <Text tag='h5'> • </Text>
        <Text tag='h5'>{appointmentTimezone}</Text>
      </Flexbox>
    </Flexbox>
  );
};
