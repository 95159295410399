import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';
import {
  WorkflowExecutionStatusDetails,
  WorkflowExecutionStatusProps,
} from './types';

const WorkflowExecutionStatus: FC<WorkflowExecutionStatusProps> = ({
  workflowErrors,
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  supersededWorkflowExecutionId,
  variant,
}) => {
  const theme = useTheme();

  const statusMap: Record<
    workflow_monitoring.v2.WorkflowExecutionStatus,
    WorkflowExecutionStatusDetails
  > = {
    [workflow_monitoring.v2.WorkflowExecutionStatus
      .UNSPECIFIED_WORKFLOW_EXECUTION_STATUS]: {
      description: 'Unspecified',
      hoverDescription: 'Unspecified',
      icon: <></>,
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.SKIPPED]: {
      description: 'Skipped',
      hoverDescription: 'Skipped',
      icon: (
        <Icon
          name='bolt-slash'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.TRIGGERED]: {
      description: 'Triggered',
      hoverDescription: 'Triggered',
      icon: (
        <Icon
          name='bolt'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.PROCESSING]: {
      description: 'Processing',
      hoverDescription: 'Processing',
      icon: (
        <Icon
          name='bolt'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.RETRYING]: {
      description: 'Retrying',
      hoverDescription: `Retry ${retryNumber} of ${retryOriginalWorkflowExecutionId}`,
      icon: (
        <Icon
          name='bolt-arrow'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.SUPERSEDED]: {
      description: 'Superseded',
      hoverDescription: `Superseded by ${supersededWorkflowExecutionId}`,
      icon: (
        <Icon
          name='bolt-double'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.EXECUTED]: {
      description: 'Executed',
      hoverDescription: 'Executed',
      icon: (
        <Icon
          name='bolt'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.green.darkest}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.FAILED]: {
      description: 'Failed',
      hoverDescription: `${workflowErrors
        .flatMap((e) => e.errorDetail)
        .join(', ')}`,
      icon: (
        <Icon
          name='bolt-slash'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.red.darkest}
        />
      ),
    },
  };

  const { description, hoverDescription, icon } = statusMap[status];

  return (
    <Flexbox
      data-testid='workflow-execution-status'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      width='auto'
      gap={0.25}
    >
      <Container height='auto' width='auto'>
        {icon}
      </Container>
      {variant === 'icon-description' && <Text tag='p3'>{description}</Text>}
      {variant === 'icon-hover-description' && (
        <Text tag='p3'>{hoverDescription}</Text>
      )}
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionStatus = memo(WorkflowExecutionStatus);
