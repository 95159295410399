import { FC } from 'react';
import { useTheme } from '@morf/theming';
import { SkeletonLoadable } from '../Skeleton';
import { ObjectGroupMappingHeaderProps } from './types';
import { ObjectStatus } from '../ObjectGroupCard/ObjectStatus/ObjectStatus';
import { CopyToClipboard } from '../CopyToClipboard';
import { Timestamp } from '../Timestamp';
import { useResize } from '../Hooks/useResize';
import { Text } from '../Typography';
import { Flexbox } from '../Flexbox';

export const ObjectGroupMappingHeader: FC<ObjectGroupMappingHeaderProps> = ({
  status,
  objectType,
  objectCategory,
  objectTypeLink,
  lastDeployed,
  countOfVariants,
  countOfProperties,
  isLoading,
}) => {
  const theme = useTheme();

  const responsiveHeader = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'column',
      sm: 'column',
      xs: 'column',
    },
    align: {
      xxl: 'center',
      xl: 'center',
      lg: 'center',
      md: 'flex-start',
      sm: 'flex-start',
      xs: 'flex-start',
    },
    gap: {
      xxl: 2.5,
      xl: 2.5,
      lg: 2.5,
      md: 1.5,
      sm: 0.5,
      xs: 0.5,
    },
  });

  const renderInfoBlock = (label: string, value: string) => (
    <Flexbox direction='column' gap={0} width='auto'>
      <Text tag='p2' color={theme.colors.text.muted}>
        {label}
      </Text>
      <SkeletonLoadable isLoading={isLoading} width='3rem' height='1rem'>
        <Text tag='p2'>{value}</Text>
      </SkeletonLoadable>
    </Flexbox>
  );

  return (
    <Flexbox
      px={2}
      py={1}
      direction={responsiveHeader.direction}
      justifyContent='flex-start'
      alignItems='flex-start'
      backgroundColor={theme.colors.ui.card}
    >
      <Flexbox direction='column' gap={0.25}>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
            <Text tag='h2'>{objectType}</Text>
          </SkeletonLoadable>
          {status && <ObjectStatus status={status} />}
        </Flexbox>

        <Flexbox
          direction={responsiveHeader.direction}
          justifyContent='flex-start'
          alignItems={responsiveHeader.align}
          gap={responsiveHeader.gap}
        >
          {renderInfoBlock('Object Category', objectCategory)}
          {renderInfoBlock('Variants', countOfVariants)}
          {renderInfoBlock('Properties', countOfProperties)}
        </Flexbox>
      </Flexbox>

      <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.25}
          width='auto'
          height='auto'
        >
          <Text tag='p2' color={theme.colors.text.muted} whiteSpace='nowrap'>
            Copy Source Link
          </Text>
          <CopyToClipboard isActive={false} text={objectTypeLink} />
        </Flexbox>
      </SkeletonLoadable>
      <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
        <Timestamp
          tag='p2'
          color={theme.colors.text.muted}
          whiteSpace='nowrap'
          timestamp={lastDeployed}
        />
      </SkeletonLoadable>
    </Flexbox>
  );
};
