import { styled } from '@morf/theming';
import { Container, ContainerProps } from '../Container';

export const StyledDropdown = styled(Container)<{
  borderType: ContainerProps['borderType'];
  showOptions: boolean;
}>`
  position: relative;
  outline: none;
  cursor: pointer;
  z-index: ${({ showOptions }) => (showOptions ? 1 : 'auto')};
`;
