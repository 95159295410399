import { FC } from 'react';
import { BadgeProps } from './types';
import { StyledBadge } from './Badge.css';
import { Text } from '../Typography';

export const Badge: FC<BadgeProps> = ({ label, type }) => {
  return (
    <StyledBadge
      data-testid='badge'
      justifyContent='center'
      alignItems='center'
      width='auto'
      height='auto'
      borderRadius={0.25}
      type={type}
      px={0.5}
      py={0.25}
    >
      <Text tag='h6' weight='bold' color='inherit' whiteSpace='nowrap'>
        {label}
      </Text>
    </StyledBadge>
  );
};
