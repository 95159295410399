import { Container } from '../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { WorkflowExecutionTooltipProps } from './types';
import { useTheme } from '@morf/theming';

const WorkflowExecutionTooltipProfileId: FC<
  Pick<WorkflowExecutionTooltipProps, 'matchedProfileIndex'>
> = ({ matchedProfileIndex }) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='workflow-execution-profile-id'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      gap={0.25}
    >
      <Container height='auto' width='auto'>
        <Icon
          name='patient-arrow'
          size={1}
          stroke={theme.colors.ui.dark}
          strokeWidth={1.75}
        />
      </Container>
      <Text tag='p3'>
        {matchedProfileIndex?.thirdPartyId || 'No profile match'}
      </Text>
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionTooltipProfileId = memo(
  WorkflowExecutionTooltipProfileId
);
