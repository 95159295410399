import { NodeType } from '../../../types';
import { newNodeData } from './types';

export const addPauseNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: {},
    isBranchNode: isBranch,
    name: 'Wait',
    type: NodeType.PauseNode,
  };
};
