import { styled } from '@morf/theming';
import { Flexbox } from '../Flexbox';

export const StyledStepContainer = styled(Flexbox)<{ width: string }>`
  position: relative;
  width: 100%;
  :before {
    content: '';
    position: absolute;
    background: ${(props) => props.theme.colors.main.light.normal};
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: ${(props) => props.theme.colors.main.dark.darkest};
    height: 1px;
    width: ${(props) => props.width};
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;
