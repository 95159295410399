import { FC } from 'react';
import { Text } from '../../Typography';
import moment from 'moment';
import { DateProps } from './types';
import { StyledDate } from './Date.css';

export const Date: FC<DateProps> = ({
  date,
  isActive,
  isDisabled,
  isHidden,
  onClick,
}) => {
  return (
    <StyledDate
      data-testid='date'
      isActive={isActive}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
    >
      <Text tag={isActive ? 'h4' : 'p1'} color='inherit' align='center'>
        {moment(date).format('DD')}
      </Text>
    </StyledDate>
  );
};
