import { FC, memo } from 'react';
import { Text } from 'recharts';
import { CustomTickProps } from './types';
import { useTheme } from '@morf/theming';

const CustomTick: FC<CustomTickProps> = ({ payload, ...props }) => {
  const theme = useTheme();
  return (
    <Text
      fill={theme.colors.text.muted}
      fontFamily={theme.fontTypes.p3.family}
      fontSize={theme.fontTypes.p3.size}
      textAnchor='middle'
      verticalAnchor='start'
      {...props}
    >
      {payload.value}
    </Text>
  );
};

export const MemoizedCustomTick = memo(CustomTick);
