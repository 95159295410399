import { css } from '@morf/theming';

export const StyledCustomEdge = css(
  ({ theme }) => `
  .react-flow__edge,
  .react-flow__edge-path,
  .react-flow__connection-path,
  .react-flow__arrowhead  {
    cursor: default;
    stroke: ${theme.colors.ui.muted} !important;
    strokeWidth: 1;
    fill: none;
  }
 `
);

export const StyledCustomHandle = css(
  ({ theme }) => `
  .react-flow__handle {
    min-width: 1px;
    min-height: 1px;
    background: ${theme.colors.ui.muted};
  }
  .react-flow__handle.connectionindicator {
    background-color: ${theme.colors.ui.muted};
  }
 
`
);
