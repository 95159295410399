import { FC, memo } from 'react';
import { Text } from '../Typography';
import { TimestampProps } from './types';
import { Tooltip } from '../Tooltip';
import { TooltipTextProps } from '../Typography/TooltipText/types';
import { formatTimestamp } from '../Helpers/formatTimestamp';

const Timestamp: FC<TimestampProps & Omit<TooltipTextProps, 'children'>> = ({
  arrowDirection,
  timestamp,
  tooltipDirection,
  ...props
}) => {
  const { fullTimestamp, formattedTimestamp } = formatTimestamp(timestamp);

  const isTodayOrYesterday =
    formattedTimestamp.includes('Today') ||
    formattedTimestamp.includes('Yesterday');

  return (
    <>
      <Tooltip
        tooltipDirection={tooltipDirection}
        arrowDirection={arrowDirection}
        isActive={isTodayOrYesterday}
        tooltipText={fullTimestamp}
      >
        <Text {...props}>{formattedTimestamp}</Text>
      </Tooltip>
    </>
  );
};

export const MemoizedTimestamp = memo(Timestamp);
