import { Edge, Node } from 'reactflow';

export const nodesWithMultipleEdges = (nodes: Node[], edges: Edge[]) => {
  const nodesWithMultipleEdges: string[] = [];

  nodes.forEach((node) => {
    const sourceEdges = edges.filter((edge) => edge.source === node.id);

    if (sourceEdges.length > 1) {
      nodesWithMultipleEdges.push(node.id);
    }
  });

  return nodesWithMultipleEdges;
};
