import { ActionExecutionStatus } from '../../../ActionExecutionStatus';
import { ActionExecutionTooltipProps } from './types';
import { Container } from '../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { Text } from '../../../Typography';
import { convertToKebabCase } from '../../../Helpers/convertToKebabCase';
import { useTheme } from '@morf/theming';
import { workflow_destination_applications } from '@morf/proto/workflow_destination_applications_v1_ts_proto';
import {
  WorkflowExecutionTooltipName,
  WorkflowExecutionTooltipProfileId,
} from '../../../WorkflowExecutionTimeline/WorkflowExecutionRow/WorkflowExecutionTooltip';
import {
  convertTimestampToMoment,
  defaultDateTimeFormatWithSeconds,
} from '../../../Filters';

const ActionExecutionTooltip: FC<ActionExecutionTooltipProps> = ({
  application,
  attemptNumber,
  error,
  executionEnd,
  executionStart,
  expectedExecutionEnd,
  name,
  status,
  workflowExecutionSummary,
}) => {
  const theme = useTheme();

  const {
    applicationType,
    matchedProfileIndex,
    temporalWorkflowExecution,
    workflowName,
  } = workflowExecutionSummary || {};

  return (
    <Flexbox
      data-testid='action-execution-tooltip'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      width='21.25rem'
      gap={0.25}
      p={0.75}
    >
      <Flexbox
        data-testid='action-execution-start-timestamp'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.25}
      >
        <Icon
          name='clock-start'
          size={1}
          fill={theme.colors.text.muted}
          stroke={theme.colors.ui.dark}
          strokeWidth={1.75}
        />
        <Text tag='p3'>
          {convertTimestampToMoment(executionStart).format(
            defaultDateTimeFormatWithSeconds
          )}
        </Text>
      </Flexbox>

      {executionEnd && (
        <Flexbox
          data-testid='action-execution-end-timestamp'
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='clock-end'
            size={1}
            fill={theme.colors.text.muted}
            stroke={theme.colors.ui.dark}
            strokeWidth={1.75}
          />
          <Text tag='p3'>
            {convertTimestampToMoment(executionEnd).format(
              defaultDateTimeFormatWithSeconds
            )}
          </Text>
        </Flexbox>
      )}

      {application && (
        <Flexbox
          data-testid='action-execution-name'
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Container width='auto' height='auto'>
            <IntegrationIcon
              name={convertToKebabCase(
                workflow_destination_applications.v1.DestinationApplication
                  .Application[application]
              )}
              size={1}
            />
          </Container>
          <Text tag='p3'>{name}</Text>
        </Flexbox>
      )}

      {applicationType && workflowName && (
        <WorkflowExecutionTooltipName
          applicationType={applicationType}
          workflowName={workflowName}
        />
      )}

      <WorkflowExecutionTooltipProfileId
        matchedProfileIndex={matchedProfileIndex}
      />

      <ActionExecutionStatus
        error={error}
        expectedExecutionEnd={expectedExecutionEnd}
        retryNumber={attemptNumber}
        status={status}
        variant='icon-hover-description'
      />
    </Flexbox>
  );
};

export const MemoizedActionExecutionTooltip = memo(ActionExecutionTooltip);
