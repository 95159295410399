import { FC, memo } from 'react';
import { VersionTimelineRowStatusProps } from './types';
import { StyledVersionTimelineRowStatus } from './VersionTimelineRowStatus.css';

const VersionTimelineRowStatus: FC<VersionTimelineRowStatusProps> = ({
  type,
}) => {
  return (
    <StyledVersionTimelineRowStatus
      data-testid='version-timeline-row-status'
      type={type}
    />
  );
};

export const MemoizedVersionTimelineRowStatus = memo(VersionTimelineRowStatus);
