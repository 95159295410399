import { FC } from 'react';
import { Dropdown } from '../Dropdown';
import { TimezonePickerProps } from './types';
import momentTimezone from 'moment-timezone';
import { formatTimezone } from './formatTimezone';

export const TimezonePicker: FC<TimezonePickerProps> = ({
  defaultTimezone,
  selectTimezone,
}) => {
  const timezoneNames: string[] = momentTimezone.tz.names();

  const defaultOption = {
    label: formatTimezone(defaultTimezone.value),
    value: defaultTimezone.value,
  };

  const timezones = timezoneNames.map((timezone) => ({
    label: formatTimezone(timezone),
    value: timezone,
  }));

  return (
    <Dropdown
      placeholder='Timezone'
      options={timezones}
      defaultOptions={[defaultOption]}
      onChange={(timezones) => {
        const timezone = timezones[0];
        selectTimezone({ label: timezone.label, value: timezone.value });
      }}
      searchable
      clearable
    />
  );
};
