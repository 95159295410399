import { destinationActionNode } from './CustomNode/DestinationActionNode/mockData';
import { fetchActionNode } from './CustomNode/FetchActionNode/mockData';
import { filterNode } from './CustomNode/FilterNode/mockData';
import { pauseNode } from './CustomNode/PauseNode/mockData';
import { profileConvertNode } from './CustomNode/ProfileConvertNode/mockData';
import { profileLookupNode } from './CustomNode/ProfileLookupNode/mockData';
import { profileUpdateNode } from './CustomNode/ProfileUpdateNode/mockData';
import { restartNode } from './CustomNode/RestartNode/mockData';
import { triggerNode } from './CustomNode/TriggerNode/mockData';

export const mockNodes = [
  triggerNode,
  profileLookupNode,
  profileUpdateNode,
  filterNode,
  pauseNode,
  fetchActionNode,
  destinationActionNode,
  profileConvertNode,
  restartNode,
];

const initialPathEdges = mockNodes.slice(0, 5).map((node, index) => ({
  id: `${node?.id}-${mockNodes?.[index + 1]?.id}`,
  source: node?.id,
  target: mockNodes?.[index + 1]?.id,
}));

const branchEdges = [
  {
    id: `${pauseNode.id}-${fetchActionNode.id}`,
    source: pauseNode.id,
    target: fetchActionNode.id,
  },
  {
    id: `${pauseNode.id}-${destinationActionNode.id}`,
    source: pauseNode.id,
    target: destinationActionNode.id,
  },
];

const continuedPathEdges = [
  {
    id: `${fetchActionNode.id}-${profileConvertNode.id}`,
    source: fetchActionNode.id,
    target: profileConvertNode.id,
  },
  {
    id: `${destinationActionNode.id}-${profileConvertNode.id}`,
    source: destinationActionNode.id,
    target: profileConvertNode.id,
  },
  {
    id: `${profileConvertNode.id}-${restartNode.id}`,
    source: profileConvertNode.id,
    target: restartNode.id,
  },
];

export const mockEdges = [
  ...initialPathEdges,
  ...branchEdges,
  ...continuedPathEdges,
];
