'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.freshdesk = (() => {
  const freshdesk = {};
  freshdesk.v1 = (() => {
    const v1 = {};
    v1.Contact = (() => {
      class Contact {
        constructor(properties) {
          this.otherEmails = [];
          this.otherCompanies = [];
          this.tags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Contact(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.address != null &&
            Object.hasOwnProperty.call(message, 'address')
          ) {
            writer.uint32(18).string(message.address);
          }
          if (
            message.companyId != null &&
            Object.hasOwnProperty.call(message, 'companyId')
          ) {
            writer.uint32(24).int64(message.companyId);
          }
          if (
            message.viewAllTickets != null &&
            Object.hasOwnProperty.call(message, 'viewAllTickets')
          ) {
            writer.uint32(32).bool(message.viewAllTickets);
          }
          if (
            message.deleted != null &&
            Object.hasOwnProperty.call(message, 'deleted')
          ) {
            writer.uint32(40).bool(message.deleted);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(50).string(message.description);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(58).string(message.email);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(64).int64(message.id);
          }
          if (
            message.jobTitle != null &&
            Object.hasOwnProperty.call(message, 'jobTitle')
          ) {
            writer.uint32(74).string(message.jobTitle);
          }
          if (
            message.language != null &&
            Object.hasOwnProperty.call(message, 'language')
          ) {
            writer.uint32(82).string(message.language);
          }
          if (
            message.mobile != null &&
            Object.hasOwnProperty.call(message, 'mobile')
          ) {
            writer.uint32(90).string(message.mobile);
          }
          if (
            message.phone != null &&
            Object.hasOwnProperty.call(message, 'phone')
          ) {
            writer.uint32(98).string(message.phone);
          }
          if (
            message.timeZone != null &&
            Object.hasOwnProperty.call(message, 'timeZone')
          ) {
            writer.uint32(106).string(message.timeZone);
          }
          if (
            message.twitterId != null &&
            Object.hasOwnProperty.call(message, 'twitterId')
          ) {
            writer.uint32(114).string(message.twitterId);
          }
          if (
            message.otherEmails != null &&
            Object.hasOwnProperty.call(message, 'otherEmails')
          ) {
            for (const element of message.otherEmails) {
              writer.uint32(122).string(element);
            }
          }
          if (
            message.otherCompanies != null &&
            Object.hasOwnProperty.call(message, 'otherCompanies')
          ) {
            for (const element of message.otherCompanies) {
              $root.freshdesk.v1.OtherCompany.encode(
                element,
                writer.uint32(130).fork()
              ).ldelim();
            }
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(138).string(element);
            }
          }
          if (
            message.active != null &&
            Object.hasOwnProperty.call(message, 'active')
          ) {
            writer.uint32(144).bool(message.active);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(154).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(162).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Contact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.address = reader.string();
                break;
              }
              case 3: {
                message.companyId = reader.int64();
                break;
              }
              case 4: {
                message.viewAllTickets = reader.bool();
                break;
              }
              case 5: {
                message.deleted = reader.bool();
                break;
              }
              case 6: {
                message.description = reader.string();
                break;
              }
              case 7: {
                message.email = reader.string();
                break;
              }
              case 8: {
                message.id = reader.int64();
                break;
              }
              case 9: {
                message.jobTitle = reader.string();
                break;
              }
              case 10: {
                message.language = reader.string();
                break;
              }
              case 11: {
                message.mobile = reader.string();
                break;
              }
              case 12: {
                message.phone = reader.string();
                break;
              }
              case 13: {
                message.timeZone = reader.string();
                break;
              }
              case 14: {
                message.twitterId = reader.string();
                break;
              }
              case 15: {
                if (!message.otherEmails || !message.otherEmails.length) {
                  message.otherEmails = [];
                }
                message.otherEmails.push(reader.string());
                break;
              }
              case 16: {
                if (!message.otherCompanies || !message.otherCompanies.length) {
                  message.otherCompanies = [];
                }
                message.otherCompanies.push(
                  $root.freshdesk.v1.OtherCompany.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 17: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 18: {
                message.active = reader.bool();
                break;
              }
              case 19: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 20: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.freshdesk.v1.Contact) {
            return object;
          }
          const message = new $root.freshdesk.v1.Contact();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.address != null) {
            message.address = String(object.address);
          }
          if (object.companyId != null) {
            if ($util.Long) {
              (message.companyId = $util.Long.fromValue(
                object.companyId
              )).unsigned = false;
            } else if (typeof object.companyId === 'string') {
              message.companyId = parseInt(object.companyId, 10);
            } else if (typeof object.companyId === 'number') {
              message.companyId = object.companyId;
            } else if (typeof object.companyId === 'object') {
              message.companyId = new $util.LongBits(
                object.companyId.low >>> 0,
                object.companyId.high >>> 0
              ).toNumber();
            }
          }
          if (object.viewAllTickets != null) {
            message.viewAllTickets = Boolean(object.viewAllTickets);
          }
          if (object.deleted != null) {
            message.deleted = Boolean(object.deleted);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.jobTitle != null) {
            message.jobTitle = String(object.jobTitle);
          }
          if (object.language != null) {
            message.language = String(object.language);
          }
          if (object.mobile != null) {
            message.mobile = String(object.mobile);
          }
          if (object.phone != null) {
            message.phone = String(object.phone);
          }
          if (object.timeZone != null) {
            message.timeZone = String(object.timeZone);
          }
          if (object.twitterId != null) {
            message.twitterId = String(object.twitterId);
          }
          if (object.otherEmails) {
            if (!Array.isArray(object.otherEmails)) {
              throw new TypeError(
                '.freshdesk.v1.Contact.otherEmails: array type expected, but got ' +
                  typeof object.otherEmails
              );
            }
            message.otherEmails = new Array(object.otherEmails.length);
            for (let i = 0; i < object.otherEmails.length; ++i) {
              message.otherEmails[i] = String(object.otherEmails[i]);
            }
          }
          if (object.otherCompanies) {
            if (!Array.isArray(object.otherCompanies)) {
              throw new TypeError(
                '.freshdesk.v1.Contact.otherCompanies: array type expected, but got ' +
                  typeof object.otherCompanies
              );
            }
            message.otherCompanies = new Array(object.otherCompanies.length);
            for (let i = 0; i < object.otherCompanies.length; ++i) {
              if (typeof object.otherCompanies[i] !== 'object') {
                throw new TypeError(
                  '.freshdesk.v1.Contact.otherCompanies: object expected, but got ' +
                    typeof object.otherCompanies[i]
                );
              }
              message.otherCompanies[i] =
                $root.freshdesk.v1.OtherCompany.fromObject(
                  object.otherCompanies[i]
                );
            }
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.freshdesk.v1.Contact.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Contact.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Contact.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.otherEmails = [];
            object.otherCompanies = [];
            object.tags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.viewAllTickets = false;
            object.deleted = false;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.language = '';
            object.timeZone = '';
            object.active = false;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.address != null && message.hasOwnProperty('address')) {
            object.address = message.address;
          }
          if (
            message.companyId != null &&
            message.hasOwnProperty('companyId')
          ) {
            object.companyId =
              typeof message.companyId === 'number'
                ? options.longs === String
                  ? String(message.companyId)
                  : message.companyId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.companyId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.companyId.low >>> 0,
                    message.companyId.high >>> 0
                  ).toNumber()
                : message.companyId;
          }
          if (
            message.viewAllTickets != null &&
            message.hasOwnProperty('viewAllTickets')
          ) {
            object.viewAllTickets = message.viewAllTickets;
          }
          if (message.deleted != null && message.hasOwnProperty('deleted')) {
            object.deleted = message.deleted;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (message.jobTitle != null && message.hasOwnProperty('jobTitle')) {
            object.jobTitle = message.jobTitle;
          }
          if (message.language != null && message.hasOwnProperty('language')) {
            object.language = message.language;
          }
          if (message.mobile != null && message.hasOwnProperty('mobile')) {
            object.mobile = message.mobile;
          }
          if (message.phone != null && message.hasOwnProperty('phone')) {
            object.phone = message.phone;
          }
          if (message.timeZone != null && message.hasOwnProperty('timeZone')) {
            object.timeZone = message.timeZone;
          }
          if (
            message.twitterId != null &&
            message.hasOwnProperty('twitterId')
          ) {
            object.twitterId = message.twitterId;
          }
          if (message.otherEmails && message.otherEmails.length) {
            object.otherEmails = new Array(message.otherEmails.length);
            for (let i = 0; i < message.otherEmails.length; ++i) {
              object.otherEmails[i] = message.otherEmails[i];
            }
          }
          if (message.otherCompanies && message.otherCompanies.length) {
            object.otherCompanies = new Array(message.otherCompanies.length);
            for (let i = 0; i < message.otherCompanies.length; ++i) {
              object.otherCompanies[i] =
                $root.freshdesk.v1.OtherCompany.toObject(
                  message.otherCompanies[i],
                  options
                );
            }
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.active != null && message.hasOwnProperty('active')) {
            object.active = message.active;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'freshdesk.v1.Contact';
        }
      }

      Contact.prototype.name = '';
      Contact.prototype.address = null;
      Contact.prototype.companyId = null;
      Contact.prototype.viewAllTickets = false;
      Contact.prototype.deleted = false;
      Contact.prototype.description = null;
      Contact.prototype.email = null;
      Contact.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Contact.prototype.jobTitle = null;
      Contact.prototype.language = '';
      Contact.prototype.mobile = null;
      Contact.prototype.phone = null;
      Contact.prototype.timeZone = '';
      Contact.prototype.twitterId = null;
      Contact.prototype.otherEmails = $util.emptyArray;
      Contact.prototype.otherCompanies = $util.emptyArray;
      Contact.prototype.tags = $util.emptyArray;
      Contact.prototype.active = false;
      Contact.prototype.createdAt = null;
      Contact.prototype.updatedAt = null;

      return Contact;
    })();

    v1.OtherCompany = (() => {
      class OtherCompany {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OtherCompany(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.companyId != null &&
            Object.hasOwnProperty.call(message, 'companyId')
          ) {
            writer.uint32(8).int64(message.companyId);
          }
          if (
            message.viewAllTickets != null &&
            Object.hasOwnProperty.call(message, 'viewAllTickets')
          ) {
            writer.uint32(16).bool(message.viewAllTickets);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OtherCompany();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.companyId = reader.int64();
                break;
              }
              case 2: {
                message.viewAllTickets = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.freshdesk.v1.OtherCompany) {
            return object;
          }
          const message = new $root.freshdesk.v1.OtherCompany();
          if (object.companyId != null) {
            if ($util.Long) {
              (message.companyId = $util.Long.fromValue(
                object.companyId
              )).unsigned = false;
            } else if (typeof object.companyId === 'string') {
              message.companyId = parseInt(object.companyId, 10);
            } else if (typeof object.companyId === 'number') {
              message.companyId = object.companyId;
            } else if (typeof object.companyId === 'object') {
              message.companyId = new $util.LongBits(
                object.companyId.low >>> 0,
                object.companyId.high >>> 0
              ).toNumber();
            }
          }
          if (object.viewAllTickets != null) {
            message.viewAllTickets = Boolean(object.viewAllTickets);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.companyId =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.companyId = options.longs === String ? '0' : 0;
            }
            object.viewAllTickets = false;
          }
          let keys;
          if (
            message.companyId != null &&
            message.hasOwnProperty('companyId')
          ) {
            object.companyId =
              typeof message.companyId === 'number'
                ? options.longs === String
                  ? String(message.companyId)
                  : message.companyId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.companyId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.companyId.low >>> 0,
                    message.companyId.high >>> 0
                  ).toNumber()
                : message.companyId;
          }
          if (
            message.viewAllTickets != null &&
            message.hasOwnProperty('viewAllTickets')
          ) {
            object.viewAllTickets = message.viewAllTickets;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'freshdesk.v1.OtherCompany';
        }
      }

      OtherCompany.prototype.companyId = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      OtherCompany.prototype.viewAllTickets = false;

      return OtherCompany;
    })();

    v1.Company = (() => {
      class Company {
        constructor(properties) {
          this.domains = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Company(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(8).int64(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.domains != null &&
            Object.hasOwnProperty.call(message, 'domains')
          ) {
            for (const element of message.domains) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.note != null &&
            Object.hasOwnProperty.call(message, 'note')
          ) {
            writer.uint32(42).string(message.note);
          }
          if (
            message.healthScore != null &&
            Object.hasOwnProperty.call(message, 'healthScore')
          ) {
            writer.uint32(50).string(message.healthScore);
          }
          if (
            message.accountTier != null &&
            Object.hasOwnProperty.call(message, 'accountTier')
          ) {
            writer.uint32(58).string(message.accountTier);
          }
          if (
            message.renewalDate != null &&
            Object.hasOwnProperty.call(message, 'renewalDate')
          ) {
            timing$1.v1.Timestamp.encode(
              message.renewalDate,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.industry != null &&
            Object.hasOwnProperty.call(message, 'industry')
          ) {
            writer.uint32(74).string(message.industry);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.customFields != null &&
            Object.hasOwnProperty.call(message, 'customFields')
          ) {
            google$1.protobuf.Struct.encode(
              message.customFields,
              writer.uint32(98).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Company();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.int64();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                if (!message.domains || !message.domains.length) {
                  message.domains = [];
                }
                message.domains.push(reader.string());
                break;
              }
              case 5: {
                message.note = reader.string();
                break;
              }
              case 6: {
                message.healthScore = reader.string();
                break;
              }
              case 7: {
                message.accountTier = reader.string();
                break;
              }
              case 8: {
                message.renewalDate = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.industry = reader.string();
                break;
              }
              case 10: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 11: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                message.customFields = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.freshdesk.v1.Company) {
            return object;
          }
          const message = new $root.freshdesk.v1.Company();
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.domains) {
            if (!Array.isArray(object.domains)) {
              throw new TypeError(
                '.freshdesk.v1.Company.domains: array type expected, but got ' +
                  typeof object.domains
              );
            }
            message.domains = new Array(object.domains.length);
            for (let i = 0; i < object.domains.length; ++i) {
              message.domains[i] = String(object.domains[i]);
            }
          }
          if (object.note != null) {
            message.note = String(object.note);
          }
          if (object.healthScore != null) {
            message.healthScore = String(object.healthScore);
          }
          if (object.accountTier != null) {
            message.accountTier = String(object.accountTier);
          }
          if (object.renewalDate != null) {
            if (typeof object.renewalDate !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Company.renewalDate: object expected, but got ' +
                  typeof object.renewalDate
              );
            }
            message.renewalDate = timing$1.v1.Timestamp.fromObject(
              object.renewalDate
            );
          }
          if (object.industry != null) {
            message.industry = String(object.industry);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Company.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Company.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.customFields != null) {
            if (typeof object.customFields !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Company.customFields: object expected, but got ' +
                  typeof object.customFields
              );
            }
            message.customFields = google$1.protobuf.Struct.fromObject(
              object.customFields
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.domains = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.name = '';
            object.customFields = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.domains && message.domains.length) {
            object.domains = new Array(message.domains.length);
            for (let i = 0; i < message.domains.length; ++i) {
              object.domains[i] = message.domains[i];
            }
          }
          if (message.note != null && message.hasOwnProperty('note')) {
            object.note = message.note;
          }
          if (
            message.healthScore != null &&
            message.hasOwnProperty('healthScore')
          ) {
            object.healthScore = message.healthScore;
          }
          if (
            message.accountTier != null &&
            message.hasOwnProperty('accountTier')
          ) {
            object.accountTier = message.accountTier;
          }
          if (
            message.renewalDate != null &&
            message.hasOwnProperty('renewalDate')
          ) {
            object.renewalDate = timing$1.v1.Timestamp.toObject(
              message.renewalDate,
              options
            );
          }
          if (message.industry != null && message.hasOwnProperty('industry')) {
            object.industry = message.industry;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.customFields != null &&
            message.hasOwnProperty('customFields')
          ) {
            object.customFields = google$1.protobuf.Struct.toObject(
              message.customFields,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'freshdesk.v1.Company';
        }
      }

      Company.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Company.prototype.name = '';
      Company.prototype.description = null;
      Company.prototype.domains = $util.emptyArray;
      Company.prototype.note = null;
      Company.prototype.healthScore = null;
      Company.prototype.accountTier = null;
      Company.prototype.renewalDate = null;
      Company.prototype.industry = null;
      Company.prototype.createdAt = null;
      Company.prototype.updatedAt = null;
      Company.prototype.customFields = null;

      return Company;
    })();

    v1.Ticket = (() => {
      class Ticket {
        constructor(properties) {
          this.ccEmails = [];
          this.tags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Ticket(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.requesterId != null &&
            Object.hasOwnProperty.call(message, 'requesterId')
          ) {
            writer.uint32(16).int64(message.requesterId);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(26).string(message.email);
          }
          if (
            message.facebookId != null &&
            Object.hasOwnProperty.call(message, 'facebookId')
          ) {
            writer.uint32(34).string(message.facebookId);
          }
          if (
            message.phone != null &&
            Object.hasOwnProperty.call(message, 'phone')
          ) {
            writer.uint32(42).string(message.phone);
          }
          if (
            message.twitterId != null &&
            Object.hasOwnProperty.call(message, 'twitterId')
          ) {
            writer.uint32(50).string(message.twitterId);
          }
          if (
            message.uniqueExternalId != null &&
            Object.hasOwnProperty.call(message, 'uniqueExternalId')
          ) {
            writer.uint32(58).string(message.uniqueExternalId);
          }
          if (
            message.subject != null &&
            Object.hasOwnProperty.call(message, 'subject')
          ) {
            writer.uint32(66).string(message.subject);
          }
          if (
            message.ticketType != null &&
            Object.hasOwnProperty.call(message, 'ticketType')
          ) {
            writer.uint32(74).string(message.ticketType);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(80).int64(message.status);
          }
          if (
            message.priority != null &&
            Object.hasOwnProperty.call(message, 'priority')
          ) {
            writer.uint32(88).int64(message.priority);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(98).string(message.description);
          }
          if (
            message.responderId != null &&
            Object.hasOwnProperty.call(message, 'responderId')
          ) {
            writer.uint32(104).int64(message.responderId);
          }
          if (
            message.ccEmails != null &&
            Object.hasOwnProperty.call(message, 'ccEmails')
          ) {
            for (const element of message.ccEmails) {
              writer.uint32(114).string(element);
            }
          }
          if (
            message.customFields != null &&
            Object.hasOwnProperty.call(message, 'customFields')
          ) {
            google$1.protobuf.Struct.encode(
              message.customFields,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.dueBy != null &&
            Object.hasOwnProperty.call(message, 'dueBy')
          ) {
            timing$1.v1.Timestamp.encode(
              message.dueBy,
              writer.uint32(130).fork()
            ).ldelim();
          }
          if (
            message.emailConfigId != null &&
            Object.hasOwnProperty.call(message, 'emailConfigId')
          ) {
            writer.uint32(136).int64(message.emailConfigId);
          }
          if (
            message.frDueBy != null &&
            Object.hasOwnProperty.call(message, 'frDueBy')
          ) {
            timing$1.v1.Timestamp.encode(
              message.frDueBy,
              writer.uint32(146).fork()
            ).ldelim();
          }
          if (
            message.groupId != null &&
            Object.hasOwnProperty.call(message, 'groupId')
          ) {
            writer.uint32(152).int64(message.groupId);
          }
          if (
            message.parentId != null &&
            Object.hasOwnProperty.call(message, 'parentId')
          ) {
            writer.uint32(160).int64(message.parentId);
          }
          if (
            message.productId != null &&
            Object.hasOwnProperty.call(message, 'productId')
          ) {
            writer.uint32(168).int64(message.productId);
          }
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            writer.uint32(176).int64(message.source);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(186).string(element);
            }
          }
          if (
            message.companyId != null &&
            Object.hasOwnProperty.call(message, 'companyId')
          ) {
            writer.uint32(192).int64(message.companyId);
          }
          if (
            message.internalAgentId != null &&
            Object.hasOwnProperty.call(message, 'internalAgentId')
          ) {
            writer.uint32(200).int64(message.internalAgentId);
          }
          if (
            message.internalGroupId != null &&
            Object.hasOwnProperty.call(message, 'internalGroupId')
          ) {
            writer.uint32(208).int64(message.internalGroupId);
          }
          if (
            message.lookupParameter != null &&
            Object.hasOwnProperty.call(message, 'lookupParameter')
          ) {
            writer.uint32(218).string(message.lookupParameter);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(224).int64(message.id);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(234).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(242).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Ticket();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.requesterId = reader.int64();
                break;
              }
              case 3: {
                message.email = reader.string();
                break;
              }
              case 4: {
                message.facebookId = reader.string();
                break;
              }
              case 5: {
                message.phone = reader.string();
                break;
              }
              case 6: {
                message.twitterId = reader.string();
                break;
              }
              case 7: {
                message.uniqueExternalId = reader.string();
                break;
              }
              case 8: {
                message.subject = reader.string();
                break;
              }
              case 9: {
                message.ticketType = reader.string();
                break;
              }
              case 10: {
                message.status = reader.int64();
                break;
              }
              case 11: {
                message.priority = reader.int64();
                break;
              }
              case 12: {
                message.description = reader.string();
                break;
              }
              case 13: {
                message.responderId = reader.int64();
                break;
              }
              case 14: {
                if (!message.ccEmails || !message.ccEmails.length) {
                  message.ccEmails = [];
                }
                message.ccEmails.push(reader.string());
                break;
              }
              case 15: {
                message.customFields = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 16: {
                message.dueBy = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 17: {
                message.emailConfigId = reader.int64();
                break;
              }
              case 18: {
                message.frDueBy = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 19: {
                message.groupId = reader.int64();
                break;
              }
              case 20: {
                message.parentId = reader.int64();
                break;
              }
              case 21: {
                message.productId = reader.int64();
                break;
              }
              case 22: {
                message.source = reader.int64();
                break;
              }
              case 23: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 24: {
                message.companyId = reader.int64();
                break;
              }
              case 25: {
                message.internalAgentId = reader.int64();
                break;
              }
              case 26: {
                message.internalGroupId = reader.int64();
                break;
              }
              case 27: {
                message.lookupParameter = reader.string();
                break;
              }
              case 28: {
                message.id = reader.int64();
                break;
              }
              case 29: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 30: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.freshdesk.v1.Ticket) {
            return object;
          }
          const message = new $root.freshdesk.v1.Ticket();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.requesterId != null) {
            if ($util.Long) {
              (message.requesterId = $util.Long.fromValue(
                object.requesterId
              )).unsigned = false;
            } else if (typeof object.requesterId === 'string') {
              message.requesterId = parseInt(object.requesterId, 10);
            } else if (typeof object.requesterId === 'number') {
              message.requesterId = object.requesterId;
            } else if (typeof object.requesterId === 'object') {
              message.requesterId = new $util.LongBits(
                object.requesterId.low >>> 0,
                object.requesterId.high >>> 0
              ).toNumber();
            }
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.facebookId != null) {
            message.facebookId = String(object.facebookId);
          }
          if (object.phone != null) {
            message.phone = String(object.phone);
          }
          if (object.twitterId != null) {
            message.twitterId = String(object.twitterId);
          }
          if (object.uniqueExternalId != null) {
            message.uniqueExternalId = String(object.uniqueExternalId);
          }
          if (object.subject != null) {
            message.subject = String(object.subject);
          }
          if (object.ticketType != null) {
            message.ticketType = String(object.ticketType);
          }
          if (object.status != null) {
            if ($util.Long) {
              (message.status = $util.Long.fromValue(
                object.status
              )).unsigned = false;
            } else if (typeof object.status === 'string') {
              message.status = parseInt(object.status, 10);
            } else if (typeof object.status === 'number') {
              message.status = object.status;
            } else if (typeof object.status === 'object') {
              message.status = new $util.LongBits(
                object.status.low >>> 0,
                object.status.high >>> 0
              ).toNumber();
            }
          }
          if (object.priority != null) {
            if ($util.Long) {
              (message.priority = $util.Long.fromValue(
                object.priority
              )).unsigned = false;
            } else if (typeof object.priority === 'string') {
              message.priority = parseInt(object.priority, 10);
            } else if (typeof object.priority === 'number') {
              message.priority = object.priority;
            } else if (typeof object.priority === 'object') {
              message.priority = new $util.LongBits(
                object.priority.low >>> 0,
                object.priority.high >>> 0
              ).toNumber();
            }
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.responderId != null) {
            if ($util.Long) {
              (message.responderId = $util.Long.fromValue(
                object.responderId
              )).unsigned = false;
            } else if (typeof object.responderId === 'string') {
              message.responderId = parseInt(object.responderId, 10);
            } else if (typeof object.responderId === 'number') {
              message.responderId = object.responderId;
            } else if (typeof object.responderId === 'object') {
              message.responderId = new $util.LongBits(
                object.responderId.low >>> 0,
                object.responderId.high >>> 0
              ).toNumber();
            }
          }
          if (object.ccEmails) {
            if (!Array.isArray(object.ccEmails)) {
              throw new TypeError(
                '.freshdesk.v1.Ticket.ccEmails: array type expected, but got ' +
                  typeof object.ccEmails
              );
            }
            message.ccEmails = new Array(object.ccEmails.length);
            for (let i = 0; i < object.ccEmails.length; ++i) {
              message.ccEmails[i] = String(object.ccEmails[i]);
            }
          }
          if (object.customFields != null) {
            if (typeof object.customFields !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Ticket.customFields: object expected, but got ' +
                  typeof object.customFields
              );
            }
            message.customFields = google$1.protobuf.Struct.fromObject(
              object.customFields
            );
          }
          if (object.dueBy != null) {
            if (typeof object.dueBy !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Ticket.dueBy: object expected, but got ' +
                  typeof object.dueBy
              );
            }
            message.dueBy = timing$1.v1.Timestamp.fromObject(object.dueBy);
          }
          if (object.emailConfigId != null) {
            if ($util.Long) {
              (message.emailConfigId = $util.Long.fromValue(
                object.emailConfigId
              )).unsigned = false;
            } else if (typeof object.emailConfigId === 'string') {
              message.emailConfigId = parseInt(object.emailConfigId, 10);
            } else if (typeof object.emailConfigId === 'number') {
              message.emailConfigId = object.emailConfigId;
            } else if (typeof object.emailConfigId === 'object') {
              message.emailConfigId = new $util.LongBits(
                object.emailConfigId.low >>> 0,
                object.emailConfigId.high >>> 0
              ).toNumber();
            }
          }
          if (object.frDueBy != null) {
            if (typeof object.frDueBy !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Ticket.frDueBy: object expected, but got ' +
                  typeof object.frDueBy
              );
            }
            message.frDueBy = timing$1.v1.Timestamp.fromObject(object.frDueBy);
          }
          if (object.groupId != null) {
            if ($util.Long) {
              (message.groupId = $util.Long.fromValue(
                object.groupId
              )).unsigned = false;
            } else if (typeof object.groupId === 'string') {
              message.groupId = parseInt(object.groupId, 10);
            } else if (typeof object.groupId === 'number') {
              message.groupId = object.groupId;
            } else if (typeof object.groupId === 'object') {
              message.groupId = new $util.LongBits(
                object.groupId.low >>> 0,
                object.groupId.high >>> 0
              ).toNumber();
            }
          }
          if (object.parentId != null) {
            if ($util.Long) {
              (message.parentId = $util.Long.fromValue(
                object.parentId
              )).unsigned = false;
            } else if (typeof object.parentId === 'string') {
              message.parentId = parseInt(object.parentId, 10);
            } else if (typeof object.parentId === 'number') {
              message.parentId = object.parentId;
            } else if (typeof object.parentId === 'object') {
              message.parentId = new $util.LongBits(
                object.parentId.low >>> 0,
                object.parentId.high >>> 0
              ).toNumber();
            }
          }
          if (object.productId != null) {
            if ($util.Long) {
              (message.productId = $util.Long.fromValue(
                object.productId
              )).unsigned = false;
            } else if (typeof object.productId === 'string') {
              message.productId = parseInt(object.productId, 10);
            } else if (typeof object.productId === 'number') {
              message.productId = object.productId;
            } else if (typeof object.productId === 'object') {
              message.productId = new $util.LongBits(
                object.productId.low >>> 0,
                object.productId.high >>> 0
              ).toNumber();
            }
          }
          if (object.source != null) {
            if ($util.Long) {
              (message.source = $util.Long.fromValue(
                object.source
              )).unsigned = false;
            } else if (typeof object.source === 'string') {
              message.source = parseInt(object.source, 10);
            } else if (typeof object.source === 'number') {
              message.source = object.source;
            } else if (typeof object.source === 'object') {
              message.source = new $util.LongBits(
                object.source.low >>> 0,
                object.source.high >>> 0
              ).toNumber();
            }
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.freshdesk.v1.Ticket.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.companyId != null) {
            if ($util.Long) {
              (message.companyId = $util.Long.fromValue(
                object.companyId
              )).unsigned = false;
            } else if (typeof object.companyId === 'string') {
              message.companyId = parseInt(object.companyId, 10);
            } else if (typeof object.companyId === 'number') {
              message.companyId = object.companyId;
            } else if (typeof object.companyId === 'object') {
              message.companyId = new $util.LongBits(
                object.companyId.low >>> 0,
                object.companyId.high >>> 0
              ).toNumber();
            }
          }
          if (object.internalAgentId != null) {
            if ($util.Long) {
              (message.internalAgentId = $util.Long.fromValue(
                object.internalAgentId
              )).unsigned = false;
            } else if (typeof object.internalAgentId === 'string') {
              message.internalAgentId = parseInt(object.internalAgentId, 10);
            } else if (typeof object.internalAgentId === 'number') {
              message.internalAgentId = object.internalAgentId;
            } else if (typeof object.internalAgentId === 'object') {
              message.internalAgentId = new $util.LongBits(
                object.internalAgentId.low >>> 0,
                object.internalAgentId.high >>> 0
              ).toNumber();
            }
          }
          if (object.internalGroupId != null) {
            if ($util.Long) {
              (message.internalGroupId = $util.Long.fromValue(
                object.internalGroupId
              )).unsigned = false;
            } else if (typeof object.internalGroupId === 'string') {
              message.internalGroupId = parseInt(object.internalGroupId, 10);
            } else if (typeof object.internalGroupId === 'number') {
              message.internalGroupId = object.internalGroupId;
            } else if (typeof object.internalGroupId === 'object') {
              message.internalGroupId = new $util.LongBits(
                object.internalGroupId.low >>> 0,
                object.internalGroupId.high >>> 0
              ).toNumber();
            }
          }
          if (object.lookupParameter != null) {
            message.lookupParameter = String(object.lookupParameter);
          }
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === 'string') {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === 'number') {
              message.id = object.id;
            } else if (typeof object.id === 'object') {
              message.id = new $util.LongBits(
                object.id.low >>> 0,
                object.id.high >>> 0
              ).toNumber();
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Ticket.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.freshdesk.v1.Ticket.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.ccEmails = [];
            object.tags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.id =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.id = options.longs === String ? '0' : 0;
            }
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.requesterId != null &&
            message.hasOwnProperty('requesterId')
          ) {
            object.requesterId =
              typeof message.requesterId === 'number'
                ? options.longs === String
                  ? String(message.requesterId)
                  : message.requesterId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.requesterId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.requesterId.low >>> 0,
                    message.requesterId.high >>> 0
                  ).toNumber()
                : message.requesterId;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (
            message.facebookId != null &&
            message.hasOwnProperty('facebookId')
          ) {
            object.facebookId = message.facebookId;
          }
          if (message.phone != null && message.hasOwnProperty('phone')) {
            object.phone = message.phone;
          }
          if (
            message.twitterId != null &&
            message.hasOwnProperty('twitterId')
          ) {
            object.twitterId = message.twitterId;
          }
          if (
            message.uniqueExternalId != null &&
            message.hasOwnProperty('uniqueExternalId')
          ) {
            object.uniqueExternalId = message.uniqueExternalId;
          }
          if (message.subject != null && message.hasOwnProperty('subject')) {
            object.subject = message.subject;
          }
          if (
            message.ticketType != null &&
            message.hasOwnProperty('ticketType')
          ) {
            object.ticketType = message.ticketType;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              typeof message.status === 'number'
                ? options.longs === String
                  ? String(message.status)
                  : message.status
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.status)
                : options.longs === Number
                ? new $util.LongBits(
                    message.status.low >>> 0,
                    message.status.high >>> 0
                  ).toNumber()
                : message.status;
          }
          if (message.priority != null && message.hasOwnProperty('priority')) {
            object.priority =
              typeof message.priority === 'number'
                ? options.longs === String
                  ? String(message.priority)
                  : message.priority
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.priority)
                : options.longs === Number
                ? new $util.LongBits(
                    message.priority.low >>> 0,
                    message.priority.high >>> 0
                  ).toNumber()
                : message.priority;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.responderId != null &&
            message.hasOwnProperty('responderId')
          ) {
            object.responderId =
              typeof message.responderId === 'number'
                ? options.longs === String
                  ? String(message.responderId)
                  : message.responderId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.responderId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.responderId.low >>> 0,
                    message.responderId.high >>> 0
                  ).toNumber()
                : message.responderId;
          }
          if (message.ccEmails && message.ccEmails.length) {
            object.ccEmails = new Array(message.ccEmails.length);
            for (let i = 0; i < message.ccEmails.length; ++i) {
              object.ccEmails[i] = message.ccEmails[i];
            }
          }
          if (
            message.customFields != null &&
            message.hasOwnProperty('customFields')
          ) {
            object.customFields = google$1.protobuf.Struct.toObject(
              message.customFields,
              options
            );
          }
          if (message.dueBy != null && message.hasOwnProperty('dueBy')) {
            object.dueBy = timing$1.v1.Timestamp.toObject(
              message.dueBy,
              options
            );
          }
          if (
            message.emailConfigId != null &&
            message.hasOwnProperty('emailConfigId')
          ) {
            object.emailConfigId =
              typeof message.emailConfigId === 'number'
                ? options.longs === String
                  ? String(message.emailConfigId)
                  : message.emailConfigId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.emailConfigId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.emailConfigId.low >>> 0,
                    message.emailConfigId.high >>> 0
                  ).toNumber()
                : message.emailConfigId;
          }
          if (message.frDueBy != null && message.hasOwnProperty('frDueBy')) {
            object.frDueBy = timing$1.v1.Timestamp.toObject(
              message.frDueBy,
              options
            );
          }
          if (message.groupId != null && message.hasOwnProperty('groupId')) {
            object.groupId =
              typeof message.groupId === 'number'
                ? options.longs === String
                  ? String(message.groupId)
                  : message.groupId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.groupId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.groupId.low >>> 0,
                    message.groupId.high >>> 0
                  ).toNumber()
                : message.groupId;
          }
          if (message.parentId != null && message.hasOwnProperty('parentId')) {
            object.parentId =
              typeof message.parentId === 'number'
                ? options.longs === String
                  ? String(message.parentId)
                  : message.parentId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.parentId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.parentId.low >>> 0,
                    message.parentId.high >>> 0
                  ).toNumber()
                : message.parentId;
          }
          if (
            message.productId != null &&
            message.hasOwnProperty('productId')
          ) {
            object.productId =
              typeof message.productId === 'number'
                ? options.longs === String
                  ? String(message.productId)
                  : message.productId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.productId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.productId.low >>> 0,
                    message.productId.high >>> 0
                  ).toNumber()
                : message.productId;
          }
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source =
              typeof message.source === 'number'
                ? options.longs === String
                  ? String(message.source)
                  : message.source
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.source)
                : options.longs === Number
                ? new $util.LongBits(
                    message.source.low >>> 0,
                    message.source.high >>> 0
                  ).toNumber()
                : message.source;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (
            message.companyId != null &&
            message.hasOwnProperty('companyId')
          ) {
            object.companyId =
              typeof message.companyId === 'number'
                ? options.longs === String
                  ? String(message.companyId)
                  : message.companyId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.companyId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.companyId.low >>> 0,
                    message.companyId.high >>> 0
                  ).toNumber()
                : message.companyId;
          }
          if (
            message.internalAgentId != null &&
            message.hasOwnProperty('internalAgentId')
          ) {
            object.internalAgentId =
              typeof message.internalAgentId === 'number'
                ? options.longs === String
                  ? String(message.internalAgentId)
                  : message.internalAgentId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.internalAgentId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.internalAgentId.low >>> 0,
                    message.internalAgentId.high >>> 0
                  ).toNumber()
                : message.internalAgentId;
          }
          if (
            message.internalGroupId != null &&
            message.hasOwnProperty('internalGroupId')
          ) {
            object.internalGroupId =
              typeof message.internalGroupId === 'number'
                ? options.longs === String
                  ? String(message.internalGroupId)
                  : message.internalGroupId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.internalGroupId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.internalGroupId.low >>> 0,
                    message.internalGroupId.high >>> 0
                  ).toNumber()
                : message.internalGroupId;
          }
          if (
            message.lookupParameter != null &&
            message.hasOwnProperty('lookupParameter')
          ) {
            object.lookupParameter = message.lookupParameter;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id =
              typeof message.id === 'number'
                ? options.longs === String
                  ? String(message.id)
                  : message.id
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                ? new $util.LongBits(
                    message.id.low >>> 0,
                    message.id.high >>> 0
                  ).toNumber()
                : message.id;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'freshdesk.v1.Ticket';
        }
      }

      Ticket.prototype.name = null;
      Ticket.prototype.requesterId = null;
      Ticket.prototype.email = null;
      Ticket.prototype.facebookId = null;
      Ticket.prototype.phone = null;
      Ticket.prototype.twitterId = null;
      Ticket.prototype.uniqueExternalId = null;
      Ticket.prototype.subject = null;
      Ticket.prototype.ticketType = null;
      Ticket.prototype.status = null;
      Ticket.prototype.priority = null;
      Ticket.prototype.description = null;
      Ticket.prototype.responderId = null;
      Ticket.prototype.ccEmails = $util.emptyArray;
      Ticket.prototype.customFields = null;
      Ticket.prototype.dueBy = null;
      Ticket.prototype.emailConfigId = null;
      Ticket.prototype.frDueBy = null;
      Ticket.prototype.groupId = null;
      Ticket.prototype.parentId = null;
      Ticket.prototype.productId = null;
      Ticket.prototype.source = null;
      Ticket.prototype.tags = $util.emptyArray;
      Ticket.prototype.companyId = null;
      Ticket.prototype.internalAgentId = null;
      Ticket.prototype.internalGroupId = null;
      Ticket.prototype.lookupParameter = null;
      Ticket.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Ticket.prototype.createdAt = null;
      Ticket.prototype.updatedAt = null;

      return Ticket;
    })();

    return v1;
  })();

  return freshdesk;
})();

export const freshdesk = $root.freshdesk;
