import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Divider } from '../Divider';
import { Input } from '../Input';
import { AppointmentPatientFormProps, PatientFormInputs } from './types';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Icon } from '../Icon';
import { Container } from '../Container';
import { StyledForm } from './AppointmentPatientForm.css';
import { AppointmentHeader } from '../AppointmentHeader/AppointmentHeader';

export const AppointmentPatientForm: FC<AppointmentPatientFormProps> = ({
  appointmentName,
  providerName,
  appointmentDate,
  appointmentTime,
  appointmentTimezone,
  onSchedule,
  onClick,
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<PatientFormInputs>({
    mode: 'onChange',
  });

  const onSubmit = async (data: PatientFormInputs) => {
    const { firstName, lastName, emailAddress, phoneNumber } = data;
    onSchedule({ firstName, lastName, emailAddress, phoneNumber });
  };

  return (
    <Flexbox
      direction='column'
      backgroundColor={theme.colors.ui.card}
      borderRadius={1}
      maxWidth='47.5rem'
      gap={0}
    >
      <Flexbox p={1.25} direction='column' gap={0.5}>
        <Container width='1.5rem' height='1.5rem' onClick={onClick}>
          <Icon
            name='arrow-left'
            cursor='pointer'
            stroke={theme.colors.main.primary.darker}
          />
        </Container>
        <AppointmentHeader
          appointmentName={appointmentName}
          appointmentDate={appointmentDate}
          providerName={providerName}
          appointmentTime={appointmentTime}
          appointmentTimezone={appointmentTimezone}
        />
      </Flexbox>

      <Divider />

      <Container p={1.25}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Flexbox gap={1} justifyContent='flex-start' direction='column'>
            <Flexbox gap={0.5} direction='column'>
              <Text tag='h5'>First name* </Text>
              <Input
                id='firstName'
                type='text'
                clearable
                {...register('firstName', { required: true })}
              />
            </Flexbox>
            <Flexbox gap={0.5} direction='column'>
              <Text tag='h5'>Last name* </Text>
              <Input
                id='lastName'
                type='text'
                clearable
                {...register('lastName', { required: true })}
              />
            </Flexbox>
            <Flexbox gap={0.5} direction='column'>
              <Text tag='h5'>Email address* </Text>
              <Input
                id='emailAddress'
                type='email'
                clearable
                error={!!errors.emailAddress}
                {...register('emailAddress', {
                  required: true,
                  pattern: /\S+@\S+\.\S+/,
                })}
              />
              {errors.emailAddress && (
                <Text tag='h6' color={theme.colors.support.red.darkest}>
                  Entered value does not match email format
                </Text>
              )}
            </Flexbox>
            <Flexbox gap={0.5} direction='column'>
              <Text tag='h5'>Phone number* </Text>
              <Input
                id='phoneNumber'
                clearable
                error={!!errors.phoneNumber}
                {...register('phoneNumber', {
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                })}
              />
              {errors.phoneNumber && (
                <Text tag='h6' color={theme.colors.support.red.darkest}>
                  Entered value does not match phone format +1 (XXX) XXX XXXX
                </Text>
              )}
            </Flexbox>
            <Button
              type='submit'
              width='100%'
              size='xl'
              variant='primary'
              isDisabled={!isDirty || !isValid}
            >
              <Text tag='h5' color='inherit'>
                Schedule Appointment
              </Text>
            </Button>
          </Flexbox>
        </StyledForm>
      </Container>
    </Flexbox>
  );
};
