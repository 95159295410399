import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { RenderHTML } from '../RenderHTML';

export const AppointmentFooter: FC = () => {
  const theme = useTheme();
  return (
    <>
      {theme.cancellationPolicyHeader &&
        theme.cancellationPolicyDescription && (
          <Flexbox direction='column' gap={0}>
            <Text tag='h4'>{theme.cancellationPolicyHeader}</Text>
            <Text>
              <RenderHTML htmlContent={theme.cancellationPolicyDescription} />
            </Text>
          </Flexbox>
        )}
      <Flexbox justifyContent='flex-start'>
        {theme.termsOfService && (
          <Container
            cursor='pointer'
            width='auto'
            onClick={() => window.open(theme.termsOfService)}
          >
            <Text tag='h5' color={theme.colors.main.primary.darker}>
              Terms of Service
            </Text>
          </Container>
        )}
        <Text>•</Text>
        {theme.privacyPolicy && (
          <Container
            cursor='pointer'
            width='auto'
            onClick={() => window.open(theme.privacyPolicy)}
          >
            <Text tag='h5' color={theme.colors.main.primary.darker}>
              Privacy Policy
            </Text>
          </Container>
        )}
      </Flexbox>
    </>
  );
};
