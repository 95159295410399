import { convertToKebabCase } from '../../../Helpers/convertToKebabCase';
import { convertToTitleCase } from '../../../Helpers/convertToTitleCase';
import { workflow_destination_applications } from '@morf/proto/workflow_destination_applications_v1_ts_proto';
import { ActionItem } from './AddEdgeModal/ActionList/types';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { extractAction } from './extractAction';

export const generateActionList = (
  actions:
    | workflows.v1.WorkflowDestinationAction[]
    | workflows.v1.WorkflowFetchAction[]
): ActionItem[] => {
  const actionList: ActionItem[] = [];

  for (const action of actions) {
    const { applicationType, actionType, actionValue } = extractAction(action);

    if (applicationType && actionType && actionValue) {
      actionList.push({
        application: applicationType,
        action: actionType,
        name: convertToTitleCase(actionType),
        ...(action.application && {
          integrationIconName: convertToKebabCase(
            workflow_destination_applications.v1.DestinationApplication
              .Application[action.application.application]
          ),
        }),
        params: actionValue.params || [],
        configs: [],
      });
    }
  }

  return actionList;
};
