import _ from 'lodash';
import { FilterOption } from '../../Filter/FilterDropdown/FilterOption/types';
import { convertToTitleCase } from '../../Helpers/convertToTitleCase';
import { workflow_evaluation_results } from '@morf/proto/workflow_evaluation_results_v1_ts_proto';

export const formatClasses = (
  classes: workflow_evaluation_results.v1.ErrorClass[]
): FilterOption[] => {
  return classes.map((errorClass) => ({
    id: _.toString(errorClass),
    name: convertToTitleCase(
      workflow_evaluation_results.v1.ErrorClass[errorClass]
    ),
  }));
};
