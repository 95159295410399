import moment from 'moment';
import { convertTimestampToMoment } from './convertTimestampToMoment';
import { defaultDateFormat, defaultTimeFormat } from './constants';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const determineTimestampFormat = (
  timestamp: timing.v1.Timestamp | null | undefined
): string => {
  if (!timestamp) {
    return defaultDateFormat;
  }
  const now = moment();
  const timestampMoment = convertTimestampToMoment(timestamp);
  const oneDayAgo = now.subtract(1, 'days').startOf('day');

  return timestampMoment.isSameOrAfter(oneDayAgo)
    ? defaultTimeFormat
    : defaultDateFormat;
};
