import { convertTimestampToMoment } from '../../convertTimestampToMoment';
import { defaultDateTimeFormatWithSeconds } from '../../constants';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const formatCustomLabel = (
  startTimestamp: timing.v1.Timestamp,
  endTimestamp: timing.v1.Timestamp
): string =>
  `${convertTimestampToMoment(startTimestamp).format(
    defaultDateTimeFormatWithSeconds
  )} - ${convertTimestampToMoment(endTimestamp).format(
    defaultDateTimeFormatWithSeconds
  )}`;
