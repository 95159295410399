import { values } from '@morf/proto/values_v1_ts_proto';
import { formatValue } from './formatValue';

export const arrayValueToString = (
  arrayValue: values.v1.IValue['arrayValue'] | null | undefined
): string => {
  if (arrayValue?.values) {
    return arrayValue.values.map((value) => formatValue(value)).join(', ');
  }
  return 'n/a';
};
