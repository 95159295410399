import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { NodeType } from '../types';

export const filterNode = {
  id: '27c1a4a8-5450-4845-bdf2-cb51db315d49',
  type: NodeType.FilterNode,
  data: {
    value: {
      title: 'Filter',
      name: 'Current step id is email address',
      configuration: new workflows.v1.WorkflowFilter({
        celExpressionStr:
          'event.current_step_id == "email_address" && event.calculated_eligibility.exists(x, x == "HEALTHIE")',
      }),
    },
  },
  position: { x: 0, y: 0 },
};
