import { FC, memo } from 'react';
import { Dropdown } from '../Dropdown';
import { TimeDropdownProps } from './types';
import { Option } from '../Dropdown/types';

const TimeDropdown: FC<TimeDropdownProps> = ({ timeframe, setTimeframe }) => {
  const options: Option[] = [
    {
      label: 'Last 24 hours',
      value: '24',
    },
    {
      label: 'Last 48 hours',
      value: '48',
    },
    {
      label: 'Last 72 hours',
      value: '72',
    },
    {
      label: 'Last 7 days',
      value: '168',
    },
    {
      label: 'Last 14 days',
      value: '336',
    },
    {
      label: 'Last 30 days',
      value: '720',
    },
    {
      label: 'Last 90 days',
      value: '2160',
    },
  ];

  return (
    <Dropdown
      placeholder='Select timeframe'
      defaultOptions={options.filter((option) => option.value === timeframe)}
      options={options}
      onChange={(option) => setTimeframe(option[0].value)}
      sortOptions={false}
    />
  );
};

export const MemoizedTimeDropdown = memo(TimeDropdown);
