'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { healthie as healthie$1 } from '../../healthie_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from '../../workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.destinationactions = (() => {
  const destinationactions = {};
  destinationactions.healthie = (() => {
    const healthie = {};
    healthie.v1 = (() => {
      const v1 = {};
      v1.DestinationAction = (() => {
        class DestinationAction {
          get type() {
            for (const key of [
              'appendToPatientQuickNotes',
              'createOrUpdatePatient',
              'createOrUpdatePatientLocation',
              'updatePatientWeight',
              'updatePatient',
              'createPatientStripeCreditCard',
              'createMetricEntry',
              'sendFormRequestReminder',
              'completeTask',
              'createRequestedFormCompletion',
              'lockFormAnswerGroup',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'appendToPatientQuickNotes',
              'createOrUpdatePatient',
              'createOrUpdatePatientLocation',
              'updatePatientWeight',
              'updatePatient',
              'createPatientStripeCreditCard',
              'createMetricEntry',
              'sendFormRequestReminder',
              'completeTask',
              'createRequestedFormCompletion',
              'lockFormAnswerGroup',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new DestinationAction(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.appendToPatientQuickNotes != null &&
              Object.hasOwnProperty.call(message, 'appendToPatientQuickNotes')
            ) {
              $root.destinationactions.healthie.v1.AppendToPatientQuickNotes.encode(
                message.appendToPatientQuickNotes,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.createOrUpdatePatient != null &&
              Object.hasOwnProperty.call(message, 'createOrUpdatePatient')
            ) {
              $root.destinationactions.healthie.v1.CreateOrUpdatePatient.encode(
                message.createOrUpdatePatient,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.createOrUpdatePatientLocation != null &&
              Object.hasOwnProperty.call(
                message,
                'createOrUpdatePatientLocation'
              )
            ) {
              $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.encode(
                message.createOrUpdatePatientLocation,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.updatePatientWeight != null &&
              Object.hasOwnProperty.call(message, 'updatePatientWeight')
            ) {
              $root.destinationactions.healthie.v1.UpdatePatientWeight.encode(
                message.updatePatientWeight,
                writer.uint32(50).fork()
              ).ldelim();
            }
            if (
              message.updatePatient != null &&
              Object.hasOwnProperty.call(message, 'updatePatient')
            ) {
              $root.destinationactions.healthie.v1.UpdatePatient.encode(
                message.updatePatient,
                writer.uint32(58).fork()
              ).ldelim();
            }
            if (
              message.createPatientStripeCreditCard != null &&
              Object.hasOwnProperty.call(
                message,
                'createPatientStripeCreditCard'
              )
            ) {
              $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard.encode(
                message.createPatientStripeCreditCard,
                writer.uint32(66).fork()
              ).ldelim();
            }
            if (
              message.createMetricEntry != null &&
              Object.hasOwnProperty.call(message, 'createMetricEntry')
            ) {
              $root.destinationactions.healthie.v1.CreateMetricEntry.encode(
                message.createMetricEntry,
                writer.uint32(74).fork()
              ).ldelim();
            }
            if (
              message.sendFormRequestReminder != null &&
              Object.hasOwnProperty.call(message, 'sendFormRequestReminder')
            ) {
              $root.destinationactions.healthie.v1.SendFormRequestReminder.encode(
                message.sendFormRequestReminder,
                writer.uint32(90).fork()
              ).ldelim();
            }
            if (
              message.completeTask != null &&
              Object.hasOwnProperty.call(message, 'completeTask')
            ) {
              $root.destinationactions.healthie.v1.CompleteTask.encode(
                message.completeTask,
                writer.uint32(98).fork()
              ).ldelim();
            }
            if (
              message.createRequestedFormCompletion != null &&
              Object.hasOwnProperty.call(
                message,
                'createRequestedFormCompletion'
              )
            ) {
              $root.destinationactions.healthie.v1.CreateRequestedFormCompletion.encode(
                message.createRequestedFormCompletion,
                writer.uint32(106).fork()
              ).ldelim();
            }
            if (
              message.lockFormAnswerGroup != null &&
              Object.hasOwnProperty.call(message, 'lockFormAnswerGroup')
            ) {
              $root.destinationactions.healthie.v1.LockFormAnswerGroup.encode(
                message.lockFormAnswerGroup,
                writer.uint32(114).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new DestinationAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.appendToPatientQuickNotes =
                    $root.destinationactions.healthie.v1.AppendToPatientQuickNotes.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.createOrUpdatePatient =
                    $root.destinationactions.healthie.v1.CreateOrUpdatePatient.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.createOrUpdatePatientLocation =
                    $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  message.updatePatientWeight =
                    $root.destinationactions.healthie.v1.UpdatePatientWeight.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 7: {
                  message.updatePatient =
                    $root.destinationactions.healthie.v1.UpdatePatient.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 8: {
                  message.createPatientStripeCreditCard =
                    $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 9: {
                  message.createMetricEntry =
                    $root.destinationactions.healthie.v1.CreateMetricEntry.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 11: {
                  message.sendFormRequestReminder =
                    $root.destinationactions.healthie.v1.SendFormRequestReminder.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 12: {
                  message.completeTask =
                    $root.destinationactions.healthie.v1.CompleteTask.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 13: {
                  message.createRequestedFormCompletion =
                    $root.destinationactions.healthie.v1.CreateRequestedFormCompletion.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 14: {
                  message.lockFormAnswerGroup =
                    $root.destinationactions.healthie.v1.LockFormAnswerGroup.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.DestinationAction
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.DestinationAction();
            if (object.appendToPatientQuickNotes != null) {
              if (typeof object.appendToPatientQuickNotes !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.appendToPatientQuickNotes: object expected, but got ' +
                    typeof object.appendToPatientQuickNotes
                );
              }
              message.appendToPatientQuickNotes =
                $root.destinationactions.healthie.v1.AppendToPatientQuickNotes.fromObject(
                  object.appendToPatientQuickNotes
                );
            }
            if (object.createOrUpdatePatient != null) {
              if (typeof object.createOrUpdatePatient !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.createOrUpdatePatient: object expected, but got ' +
                    typeof object.createOrUpdatePatient
                );
              }
              message.createOrUpdatePatient =
                $root.destinationactions.healthie.v1.CreateOrUpdatePatient.fromObject(
                  object.createOrUpdatePatient
                );
            }
            if (object.createOrUpdatePatientLocation != null) {
              if (typeof object.createOrUpdatePatientLocation !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.createOrUpdatePatientLocation: object expected, but got ' +
                    typeof object.createOrUpdatePatientLocation
                );
              }
              message.createOrUpdatePatientLocation =
                $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.fromObject(
                  object.createOrUpdatePatientLocation
                );
            }
            if (object.updatePatientWeight != null) {
              if (typeof object.updatePatientWeight !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.updatePatientWeight: object expected, but got ' +
                    typeof object.updatePatientWeight
                );
              }
              message.updatePatientWeight =
                $root.destinationactions.healthie.v1.UpdatePatientWeight.fromObject(
                  object.updatePatientWeight
                );
            }
            if (object.updatePatient != null) {
              if (typeof object.updatePatient !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.updatePatient: object expected, but got ' +
                    typeof object.updatePatient
                );
              }
              message.updatePatient =
                $root.destinationactions.healthie.v1.UpdatePatient.fromObject(
                  object.updatePatient
                );
            }
            if (object.createPatientStripeCreditCard != null) {
              if (typeof object.createPatientStripeCreditCard !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.createPatientStripeCreditCard: object expected, but got ' +
                    typeof object.createPatientStripeCreditCard
                );
              }
              message.createPatientStripeCreditCard =
                $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard.fromObject(
                  object.createPatientStripeCreditCard
                );
            }
            if (object.createMetricEntry != null) {
              if (typeof object.createMetricEntry !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.createMetricEntry: object expected, but got ' +
                    typeof object.createMetricEntry
                );
              }
              message.createMetricEntry =
                $root.destinationactions.healthie.v1.CreateMetricEntry.fromObject(
                  object.createMetricEntry
                );
            }
            if (object.sendFormRequestReminder != null) {
              if (typeof object.sendFormRequestReminder !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.sendFormRequestReminder: object expected, but got ' +
                    typeof object.sendFormRequestReminder
                );
              }
              message.sendFormRequestReminder =
                $root.destinationactions.healthie.v1.SendFormRequestReminder.fromObject(
                  object.sendFormRequestReminder
                );
            }
            if (object.completeTask != null) {
              if (typeof object.completeTask !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.completeTask: object expected, but got ' +
                    typeof object.completeTask
                );
              }
              message.completeTask =
                $root.destinationactions.healthie.v1.CompleteTask.fromObject(
                  object.completeTask
                );
            }
            if (object.createRequestedFormCompletion != null) {
              if (typeof object.createRequestedFormCompletion !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.createRequestedFormCompletion: object expected, but got ' +
                    typeof object.createRequestedFormCompletion
                );
              }
              message.createRequestedFormCompletion =
                $root.destinationactions.healthie.v1.CreateRequestedFormCompletion.fromObject(
                  object.createRequestedFormCompletion
                );
            }
            if (object.lockFormAnswerGroup != null) {
              if (typeof object.lockFormAnswerGroup !== 'object') {
                throw new TypeError(
                  '.destinationactions.healthie.v1.DestinationAction.lockFormAnswerGroup: object expected, but got ' +
                    typeof object.lockFormAnswerGroup
                );
              }
              message.lockFormAnswerGroup =
                $root.destinationactions.healthie.v1.LockFormAnswerGroup.fromObject(
                  object.lockFormAnswerGroup
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.appendToPatientQuickNotes != null &&
              message.hasOwnProperty('appendToPatientQuickNotes')
            ) {
              object.appendToPatientQuickNotes =
                $root.destinationactions.healthie.v1.AppendToPatientQuickNotes.toObject(
                  message.appendToPatientQuickNotes,
                  options
                );
              if (options.oneofs) {
                object.type = 'appendToPatientQuickNotes';
              }
            }
            if (
              message.createOrUpdatePatient != null &&
              message.hasOwnProperty('createOrUpdatePatient')
            ) {
              object.createOrUpdatePatient =
                $root.destinationactions.healthie.v1.CreateOrUpdatePatient.toObject(
                  message.createOrUpdatePatient,
                  options
                );
              if (options.oneofs) {
                object.type = 'createOrUpdatePatient';
              }
            }
            if (
              message.createOrUpdatePatientLocation != null &&
              message.hasOwnProperty('createOrUpdatePatientLocation')
            ) {
              object.createOrUpdatePatientLocation =
                $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.toObject(
                  message.createOrUpdatePatientLocation,
                  options
                );
              if (options.oneofs) {
                object.type = 'createOrUpdatePatientLocation';
              }
            }
            if (
              message.updatePatientWeight != null &&
              message.hasOwnProperty('updatePatientWeight')
            ) {
              object.updatePatientWeight =
                $root.destinationactions.healthie.v1.UpdatePatientWeight.toObject(
                  message.updatePatientWeight,
                  options
                );
              if (options.oneofs) {
                object.type = 'updatePatientWeight';
              }
            }
            if (
              message.updatePatient != null &&
              message.hasOwnProperty('updatePatient')
            ) {
              object.updatePatient =
                $root.destinationactions.healthie.v1.UpdatePatient.toObject(
                  message.updatePatient,
                  options
                );
              if (options.oneofs) {
                object.type = 'updatePatient';
              }
            }
            if (
              message.createPatientStripeCreditCard != null &&
              message.hasOwnProperty('createPatientStripeCreditCard')
            ) {
              object.createPatientStripeCreditCard =
                $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard.toObject(
                  message.createPatientStripeCreditCard,
                  options
                );
              if (options.oneofs) {
                object.type = 'createPatientStripeCreditCard';
              }
            }
            if (
              message.createMetricEntry != null &&
              message.hasOwnProperty('createMetricEntry')
            ) {
              object.createMetricEntry =
                $root.destinationactions.healthie.v1.CreateMetricEntry.toObject(
                  message.createMetricEntry,
                  options
                );
              if (options.oneofs) {
                object.type = 'createMetricEntry';
              }
            }
            if (
              message.sendFormRequestReminder != null &&
              message.hasOwnProperty('sendFormRequestReminder')
            ) {
              object.sendFormRequestReminder =
                $root.destinationactions.healthie.v1.SendFormRequestReminder.toObject(
                  message.sendFormRequestReminder,
                  options
                );
              if (options.oneofs) {
                object.type = 'sendFormRequestReminder';
              }
            }
            if (
              message.completeTask != null &&
              message.hasOwnProperty('completeTask')
            ) {
              object.completeTask =
                $root.destinationactions.healthie.v1.CompleteTask.toObject(
                  message.completeTask,
                  options
                );
              if (options.oneofs) {
                object.type = 'completeTask';
              }
            }
            if (
              message.createRequestedFormCompletion != null &&
              message.hasOwnProperty('createRequestedFormCompletion')
            ) {
              object.createRequestedFormCompletion =
                $root.destinationactions.healthie.v1.CreateRequestedFormCompletion.toObject(
                  message.createRequestedFormCompletion,
                  options
                );
              if (options.oneofs) {
                object.type = 'createRequestedFormCompletion';
              }
            }
            if (
              message.lockFormAnswerGroup != null &&
              message.hasOwnProperty('lockFormAnswerGroup')
            ) {
              object.lockFormAnswerGroup =
                $root.destinationactions.healthie.v1.LockFormAnswerGroup.toObject(
                  message.lockFormAnswerGroup,
                  options
                );
              if (options.oneofs) {
                object.type = 'lockFormAnswerGroup';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'destinationactions.healthie.v1.DestinationAction'
            );
          }
        }

        DestinationAction.prototype.appendToPatientQuickNotes = null;
        DestinationAction.prototype.createOrUpdatePatient = null;
        DestinationAction.prototype.createOrUpdatePatientLocation = null;
        DestinationAction.prototype.updatePatientWeight = null;
        DestinationAction.prototype.updatePatient = null;
        DestinationAction.prototype.createPatientStripeCreditCard = null;
        DestinationAction.prototype.createMetricEntry = null;
        DestinationAction.prototype.sendFormRequestReminder = null;
        DestinationAction.prototype.completeTask = null;
        DestinationAction.prototype.createRequestedFormCompletion = null;
        DestinationAction.prototype.lockFormAnswerGroup = null;

        return DestinationAction;
      })();

      v1.AppendToPatientQuickNotes = (() => {
        class AppendToPatientQuickNotes {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new AppendToPatientQuickNotes(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new AppendToPatientQuickNotes();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.AppendToPatientQuickNotes
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.AppendToPatientQuickNotes();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.AppendToPatientQuickNotes.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.AppendToPatientQuickNotes.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.AppendToPatientQuickNotes.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.AppendToPatientQuickNotes.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.AppendToPatientQuickNotes'
            );
          }
        }

        AppendToPatientQuickNotes.prototype.params = $util.emptyArray;
        AppendToPatientQuickNotes.prototype.configs = $util.emptyArray;
        AppendToPatientQuickNotes.prototype.requiredThirdPartyIdMissingBehavior = 0;
        AppendToPatientQuickNotes.prototype.healthieEnvironment = 0;

        return AppendToPatientQuickNotes;
      })();

      v1.CreateOrUpdatePatient = (() => {
        class CreateOrUpdatePatient {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdatePatient(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdatePatient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CreateOrUpdatePatient
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CreateOrUpdatePatient();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateOrUpdatePatient.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateOrUpdatePatient.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateOrUpdatePatient.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateOrUpdatePatient.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.CreateOrUpdatePatient'
            );
          }
        }

        CreateOrUpdatePatient.prototype.params = $util.emptyArray;
        CreateOrUpdatePatient.prototype.configs = $util.emptyArray;
        CreateOrUpdatePatient.prototype.healthieEnvironment = 0;

        return CreateOrUpdatePatient;
      })();

      v1.CreateOrUpdatePatientLocation = (() => {
        class CreateOrUpdatePatientLocation {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdatePatientLocation(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdatePatientLocation();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CreateOrUpdatePatientLocation();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateOrUpdatePatientLocation.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.CreateOrUpdatePatientLocation'
            );
          }
        }

        CreateOrUpdatePatientLocation.prototype.params = $util.emptyArray;
        CreateOrUpdatePatientLocation.prototype.configs = $util.emptyArray;
        CreateOrUpdatePatientLocation.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdatePatientLocation.prototype.healthieEnvironment = 0;

        return CreateOrUpdatePatientLocation;
      })();

      v1.UpdatePatientWeight = (() => {
        class UpdatePatientWeight {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdatePatientWeight(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdatePatientWeight();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.UpdatePatientWeight
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.UpdatePatientWeight();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.UpdatePatientWeight.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.UpdatePatientWeight.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.UpdatePatientWeight.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.UpdatePatientWeight.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.UpdatePatientWeight'
            );
          }
        }

        UpdatePatientWeight.prototype.params = $util.emptyArray;
        UpdatePatientWeight.prototype.configs = $util.emptyArray;
        UpdatePatientWeight.prototype.requiredThirdPartyIdMissingBehavior = 0;
        UpdatePatientWeight.prototype.healthieEnvironment = 0;

        return UpdatePatientWeight;
      })();

      v1.UpdatePatient = (() => {
        class UpdatePatient {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdatePatient(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdatePatient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.UpdatePatient
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.UpdatePatient();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.UpdatePatient.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.UpdatePatient.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.UpdatePatient.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.UpdatePatient.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'destinationactions.healthie.v1.UpdatePatient'
            );
          }
        }

        UpdatePatient.prototype.params = $util.emptyArray;
        UpdatePatient.prototype.configs = $util.emptyArray;
        UpdatePatient.prototype.requiredThirdPartyIdMissingBehavior = 0;
        UpdatePatient.prototype.healthieEnvironment = 0;

        return UpdatePatient;
      })();

      v1.CreatePatientStripeCreditCard = (() => {
        class CreatePatientStripeCreditCard {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreatePatientStripeCreditCard(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreatePatientStripeCreditCard();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CreatePatientStripeCreditCard();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreatePatientStripeCreditCard.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreatePatientStripeCreditCard.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreatePatientStripeCreditCard.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreatePatientStripeCreditCard.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.CreatePatientStripeCreditCard'
            );
          }
        }

        CreatePatientStripeCreditCard.prototype.params = $util.emptyArray;
        CreatePatientStripeCreditCard.prototype.configs = $util.emptyArray;
        CreatePatientStripeCreditCard.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreatePatientStripeCreditCard.prototype.healthieEnvironment = 0;

        return CreatePatientStripeCreditCard;
      })();

      v1.CreateMetricEntry = (() => {
        class CreateMetricEntry {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateMetricEntry(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateMetricEntry();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CreateMetricEntry
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CreateMetricEntry();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateMetricEntry.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateMetricEntry.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateMetricEntry.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateMetricEntry.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'destinationactions.healthie.v1.CreateMetricEntry'
            );
          }
        }

        CreateMetricEntry.prototype.params = $util.emptyArray;
        CreateMetricEntry.prototype.configs = $util.emptyArray;
        CreateMetricEntry.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateMetricEntry.prototype.healthieEnvironment = 0;

        return CreateMetricEntry;
      })();

      v1.SendFormRequestReminder = (() => {
        class SendFormRequestReminder {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SendFormRequestReminder(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SendFormRequestReminder();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.SendFormRequestReminder
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.SendFormRequestReminder();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.SendFormRequestReminder.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.SendFormRequestReminder.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.SendFormRequestReminder.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.SendFormRequestReminder.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.SendFormRequestReminder'
            );
          }
        }

        SendFormRequestReminder.prototype.params = $util.emptyArray;
        SendFormRequestReminder.prototype.configs = $util.emptyArray;
        SendFormRequestReminder.prototype.requiredThirdPartyIdMissingBehavior = 0;
        SendFormRequestReminder.prototype.healthieEnvironment = 0;

        return SendFormRequestReminder;
      })();

      v1.CompleteTask = (() => {
        class CompleteTask {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CompleteTask(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CompleteTask();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CompleteTask
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CompleteTask();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CompleteTask.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CompleteTask.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CompleteTask.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CompleteTask.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'destinationactions.healthie.v1.CompleteTask'
            );
          }
        }

        CompleteTask.prototype.params = $util.emptyArray;
        CompleteTask.prototype.configs = $util.emptyArray;
        CompleteTask.prototype.healthieEnvironment = 0;

        return CompleteTask;
      })();

      v1.CreateRequestedFormCompletion = (() => {
        class CreateRequestedFormCompletion {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateRequestedFormCompletion(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateRequestedFormCompletion();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.CreateRequestedFormCompletion
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.CreateRequestedFormCompletion();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateRequestedFormCompletion.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateRequestedFormCompletion.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.CreateRequestedFormCompletion.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.CreateRequestedFormCompletion.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.CreateRequestedFormCompletion'
            );
          }
        }

        CreateRequestedFormCompletion.prototype.params = $util.emptyArray;
        CreateRequestedFormCompletion.prototype.configs = $util.emptyArray;
        CreateRequestedFormCompletion.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateRequestedFormCompletion.prototype.healthieEnvironment = 0;

        return CreateRequestedFormCompletion;
      })();

      v1.LockFormAnswerGroup = (() => {
        class LockFormAnswerGroup {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new LockFormAnswerGroup(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.healthieEnvironment != null &&
              Object.hasOwnProperty.call(message, 'healthieEnvironment')
            ) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new LockFormAnswerGroup();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.destinationactions.healthie.v1.LockFormAnswerGroup
            ) {
              return object;
            }
            const message =
              new $root.destinationactions.healthie.v1.LockFormAnswerGroup();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.LockFormAnswerGroup.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.LockFormAnswerGroup.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.destinationactions.healthie.v1.LockFormAnswerGroup.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.destinationactions.healthie.v1.LockFormAnswerGroup.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case 'UNDEFINED_ILLEGAL':
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case 'SANDBOX':
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case 'PRODUCTION':
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == 'number') {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment =
                options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.healthieEnvironment != null &&
              message.hasOwnProperty('healthieEnvironment')
            ) {
              object.healthieEnvironment =
                options.enums === String
                  ? healthie$1.v1.HealthieEnvironment[
                      message.healthieEnvironment
                    ] === undefined
                    ? message.healthieEnvironment
                    : healthie$1.v1.HealthieEnvironment[
                        message.healthieEnvironment
                      ]
                  : message.healthieEnvironment;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'destinationactions.healthie.v1.LockFormAnswerGroup'
            );
          }
        }

        LockFormAnswerGroup.prototype.params = $util.emptyArray;
        LockFormAnswerGroup.prototype.configs = $util.emptyArray;
        LockFormAnswerGroup.prototype.healthieEnvironment = 0;

        return LockFormAnswerGroup;
      })();

      return v1;
    })();

    return healthie;
  })();

  return destinationactions;
})();

export const destinationactions = $root.destinationactions;
