export const CustomerIO = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.6937 25.5714C23.1652 25.5714 25.9794 22.7572 25.9794 19.2857C25.9794 15.8142 23.1652 13 19.6937 13C16.2222 13 13.408 15.8142 13.408 19.2857C13.408 22.7572 16.2222 25.5714 19.6937 25.5714Z'
        fill='#FFCD00'
      />
      <path
        d='M19.7348 28.7142C19.728 28.7142 19.7211 28.7142 19.7143 28.7142C15.3087 28.7142 11.6089 25.6927 10.5741 21.6089C10.2557 20.3525 9.23503 19.2856 7.93899 19.2856H4C4 27.9644 11.0355 34.9999 19.7143 34.9999C19.7211 34.9999 19.728 34.9999 19.7348 34.9999V28.7142Z'
        fill='#00ECBB'
      />
      <path
        d='M19.6933 28.7142C19.7001 28.7142 19.707 28.7142 19.7138 28.7142C24.1194 28.7142 27.8192 25.6927 28.854 21.6089C29.1724 20.3525 30.1931 19.2856 31.4892 19.2856H35.428C35.428 27.9644 28.3926 34.9999 19.7138 34.9999C19.707 34.9999 19.7001 34.9999 19.6933 34.9999V28.7142Z'
        fill='#AF64FF'
      />
      <path
        d='M30.8257 30.3973C27.982 33.241 24.0534 34.9999 19.714 34.9999C15.3747 34.9999 11.4461 33.241 8.60237 30.3973L13.047 25.9526C14.7533 27.6589 17.1104 28.7142 19.714 28.7142C22.3177 28.7142 24.6748 27.6589 26.3811 25.9526L30.8257 30.3973Z'
        fill='#7131FF'
      />
    </svg>
  );
};
