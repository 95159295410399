import { FC } from 'react';
import { ProviderFiltersProps } from './types';
import { useResize } from '../Hooks/useResize';
import { Flexbox } from '../Flexbox';
import { Container } from '../Container';
import { Dropdown } from '../Dropdown';
import { Text } from '../Typography';
import { Button } from '../Button';
import { RenderHTML } from '../RenderHTML';

export const ProviderFilters: FC<ProviderFiltersProps> = ({
  availableProvidersMessage,
  defaultState,
  states,
  setDefaultState,
  defaultInsurance,
  insurances,
  setDefaultInsurance,
  defaultTags,
  tags,
  setDefaultTags,
  hideState,
  hideInsurance,
  hideSpecialties,
}) => {
  const defaultStateValue = defaultState[0].value;
  const defaultInsuranceValue = defaultInsurance[0].value;

  const handleClearFilters = () => {
    setDefaultState('');
    setDefaultInsurance('');
    setDefaultTags([]);
  };

  const responsiveFilters = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'column',
      xs: 'column',
    },
    maxWidth: {
      xxl: '33.3%',
      xl: '33.3%',
      lg: '33.3%',
      md: '33.3%',
      sm: '100%',
      xs: '100%',
    },
  });

  const showState = hideState === 'false';
  const showInsurance = hideInsurance === 'false';
  const showSpecialties = hideSpecialties === 'false';
  const showFilters = showState || showInsurance || showSpecialties;
  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      gap={0}
    >
      <Flexbox
        justifyContent='flex-start'
        direction={responsiveFilters.direction}
        px={1.25}
        pt={showFilters ? 1.25 : 0}
      >
        {showState && (
          <Container maxWidth={responsiveFilters.maxWidth}>
            <Dropdown
              key={defaultStateValue}
              placeholder='State'
              options={states}
              defaultOptions={states.filter(
                (state) => state.value === defaultStateValue
              )}
              onChange={(states) => setDefaultState(states[0].value)}
              searchable
              clearable
            />
          </Container>
        )}
        {showInsurance && (
          <Container maxWidth={responsiveFilters.maxWidth}>
            <Dropdown
              key={defaultInsuranceValue}
              placeholder='Insurance'
              options={insurances}
              defaultOptions={insurances.filter(
                (insurance) => insurance.value === defaultInsuranceValue
              )}
              onChange={(insurances) =>
                setDefaultInsurance(insurances[0].value)
              }
              searchable
              clearable
            />
          </Container>
        )}
        {showSpecialties && (
          <Container maxWidth={responsiveFilters.maxWidth}>
            <Dropdown
              key={JSON.stringify(defaultTags)}
              placeholder='Specialty'
              options={tags}
              defaultOptions={defaultTags}
              onChange={(tags) => setDefaultTags(tags.map((tag) => tag.value))}
              searchable
              clearable
              multiSelect
            />
          </Container>
        )}
      </Flexbox>

      <Flexbox px={1.25} py={2} alignItems='center'>
        <Text tag='h4'>
          <RenderHTML htmlContent={availableProvidersMessage} />
        </Text>
        {showFilters && (
          <Button
            variant='tertiary'
            isDisabled={
              defaultStateValue === '' &&
              defaultInsuranceValue === '' &&
              defaultTags.length === 0
            }
            onClick={handleClearFilters}
          >
            <Text tag='h5' color='inherit'>
              Clear filters
            </Text>
          </Button>
        )}
      </Flexbox>
    </Flexbox>
  );
};
