export const PanelBottom = () => {
  return (
    <path
      d='M3.5 14.5V8.81818V6.90909C3.5 5.85473 4.50736 5 5.75 5H16.5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V14.5M3.5 14.5V17.0909C3.5 18.1453 4.50736 19 5.75 19H16.5H19.25C20.4926 19 21.5 18.1453 21.5 17.0909V14.5M3.5 14.5H21.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const PanelLeft = () => {
  return (
    <path
      d='M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5M8.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H8.5M8.5 5V19'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const SolidPanelLeft = () => {
  return (
    <>
      <path
        d='M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5M8.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H8.5M8.5 5V19'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.5 8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5V5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818Z' />
      <rect x='4.25' y='15' width='3.5' height='1.5' rx='0.75' fill='white' />
      <rect x='4.25' y='11' width='3.5' height='1.5' rx='0.75' fill='white' />
      <rect x='4.25' y='7' width='3.5' height='1.5' rx='0.75' fill='white' />
    </>
  );
};

export const PanelLeftSlash = () => {
  return (
    <>
      <path
        d='M21.5 8.81818V9V17.0909C21.5 18.1453 20.4926 19 19.25 19H11.5M18.5 5H18H8.5M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 17.7528 3.89701 18.336 4.5 18.6785M8.5 5V15'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 3L18.8363 4.66365L4 19.5L2.5 21'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PanelRight = () => {
  return (
    <path
      d='M16.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H16.5M16.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H16.5M16.5 5V19'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};
