'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.axle_health = (() => {
  const axle_health = {};
  axle_health.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.axle_health.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.axle_health.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_AXLE_HEALTH_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'AXLE_HEALTH_LAB_ORDER_STATUS_UPDATE':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'AXLE_HEALTH_LAB_ORDER_RESULTS_UPDATE':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.axle_health.v1.AxleHealthEventType[
                      message.eventTypes[i]
                    ] === undefined
                    ? message.eventTypes[i]
                    : $root.axle_health.v1.AxleHealthEventType[
                        message.eventTypes[i]
                      ]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Visit = (() => {
      class Visit {
        constructor(properties) {
          this.serviceIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Visit(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(18).string(message.id);
          }
          if (
            message.serviceIds != null &&
            Object.hasOwnProperty.call(message, 'serviceIds')
          ) {
            for (const element of message.serviceIds) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(34).string(message.patientId);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(42).string(message.status);
          }
          if (
            message.visitDatetime != null &&
            Object.hasOwnProperty.call(message, 'visitDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.visitDatetime,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Visit();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                if (!message.serviceIds || !message.serviceIds.length) {
                  message.serviceIds = [];
                }
                message.serviceIds.push(reader.string());
                break;
              }
              case 4: {
                message.patientId = reader.string();
                break;
              }
              case 5: {
                message.status = reader.string();
                break;
              }
              case 6: {
                message.visitDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.Visit) {
            return object;
          }
          const message = new $root.axle_health.v1.Visit();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.serviceIds) {
            if (!Array.isArray(object.serviceIds)) {
              throw new TypeError(
                '.axle_health.v1.Visit.serviceIds: array type expected, but got ' +
                  typeof object.serviceIds
              );
            }
            message.serviceIds = new Array(object.serviceIds.length);
            for (let i = 0; i < object.serviceIds.length; ++i) {
              message.serviceIds[i] = String(object.serviceIds[i]);
            }
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.visitDatetime != null) {
            if (typeof object.visitDatetime !== 'object') {
              throw new TypeError(
                '.axle_health.v1.Visit.visitDatetime: object expected, but got ' +
                  typeof object.visitDatetime
              );
            }
            message.visitDatetime = timing$1.v1.Timestamp.fromObject(
              object.visitDatetime
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.serviceIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = '';
            object.id = '';
            object.patientId = '';
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.serviceIds && message.serviceIds.length) {
            object.serviceIds = new Array(message.serviceIds.length);
            for (let i = 0; i < message.serviceIds.length; ++i) {
              object.serviceIds[i] = message.serviceIds[i];
            }
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (
            message.visitDatetime != null &&
            message.hasOwnProperty('visitDatetime')
          ) {
            object.visitDatetime = timing$1.v1.Timestamp.toObject(
              message.visitDatetime,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.Visit';
        }
      }

      Visit.prototype.type = '';
      Visit.prototype.id = '';
      Visit.prototype.serviceIds = $util.emptyArray;
      Visit.prototype.patientId = '';
      Visit.prototype.status = null;
      Visit.prototype.visitDatetime = null;

      return Visit;
    })();

    v1.LabResults = (() => {
      class LabResults {
        constructor(properties) {
          this.panels = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LabResults(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(18).string(message.id);
          }
          if (
            message.visitId != null &&
            Object.hasOwnProperty.call(message, 'visitId')
          ) {
            writer.uint32(26).string(message.visitId);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(34).string(message.patientId);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(42).string(message.status);
          }
          if (
            message.reportedAt != null &&
            Object.hasOwnProperty.call(message, 'reportedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.reportedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.panels != null &&
            Object.hasOwnProperty.call(message, 'panels')
          ) {
            for (const element of message.panels) {
              $root.axle_health.v1.Panel.encode(
                element,
                writer.uint32(58).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LabResults();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                message.visitId = reader.string();
                break;
              }
              case 4: {
                message.patientId = reader.string();
                break;
              }
              case 5: {
                message.status = reader.string();
                break;
              }
              case 6: {
                message.reportedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                if (!message.panels || !message.panels.length) {
                  message.panels = [];
                }
                message.panels.push(
                  $root.axle_health.v1.Panel.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.LabResults) {
            return object;
          }
          const message = new $root.axle_health.v1.LabResults();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.visitId != null) {
            message.visitId = String(object.visitId);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.reportedAt != null) {
            if (typeof object.reportedAt !== 'object') {
              throw new TypeError(
                '.axle_health.v1.LabResults.reportedAt: object expected, but got ' +
                  typeof object.reportedAt
              );
            }
            message.reportedAt = timing$1.v1.Timestamp.fromObject(
              object.reportedAt
            );
          }
          if (object.panels) {
            if (!Array.isArray(object.panels)) {
              throw new TypeError(
                '.axle_health.v1.LabResults.panels: array type expected, but got ' +
                  typeof object.panels
              );
            }
            message.panels = new Array(object.panels.length);
            for (let i = 0; i < object.panels.length; ++i) {
              if (typeof object.panels[i] !== 'object') {
                throw new TypeError(
                  '.axle_health.v1.LabResults.panels: object expected, but got ' +
                    typeof object.panels[i]
                );
              }
              message.panels[i] = $root.axle_health.v1.Panel.fromObject(
                object.panels[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.panels = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = '';
            object.id = '';
            object.visitId = '';
            object.patientId = '';
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.visitId != null && message.hasOwnProperty('visitId')) {
            object.visitId = message.visitId;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (
            message.reportedAt != null &&
            message.hasOwnProperty('reportedAt')
          ) {
            object.reportedAt = timing$1.v1.Timestamp.toObject(
              message.reportedAt,
              options
            );
          }
          if (message.panels && message.panels.length) {
            object.panels = new Array(message.panels.length);
            for (let i = 0; i < message.panels.length; ++i) {
              object.panels[i] = $root.axle_health.v1.Panel.toObject(
                message.panels[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.LabResults';
        }
      }

      LabResults.prototype.type = '';
      LabResults.prototype.id = '';
      LabResults.prototype.visitId = '';
      LabResults.prototype.patientId = '';
      LabResults.prototype.status = null;
      LabResults.prototype.reportedAt = null;
      LabResults.prototype.panels = $util.emptyArray;

      return LabResults;
    })();

    v1.Panel = (() => {
      class Panel {
        constructor(properties) {
          this.results = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Panel(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.results != null &&
            Object.hasOwnProperty.call(message, 'results')
          ) {
            for (const element of message.results) {
              $root.axle_health.v1.Result.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Panel();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                if (!message.results || !message.results.length) {
                  message.results = [];
                }
                message.results.push(
                  $root.axle_health.v1.Result.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.Panel) {
            return object;
          }
          const message = new $root.axle_health.v1.Panel();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.results) {
            if (!Array.isArray(object.results)) {
              throw new TypeError(
                '.axle_health.v1.Panel.results: array type expected, but got ' +
                  typeof object.results
              );
            }
            message.results = new Array(object.results.length);
            for (let i = 0; i < object.results.length; ++i) {
              if (typeof object.results[i] !== 'object') {
                throw new TypeError(
                  '.axle_health.v1.Panel.results: object expected, but got ' +
                    typeof object.results[i]
                );
              }
              message.results[i] = $root.axle_health.v1.Result.fromObject(
                object.results[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.results = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.results && message.results.length) {
            object.results = new Array(message.results.length);
            for (let i = 0; i < message.results.length; ++i) {
              object.results[i] = $root.axle_health.v1.Result.toObject(
                message.results[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.Panel';
        }
      }

      Panel.prototype.id = '';
      Panel.prototype.name = '';
      Panel.prototype.results = $util.emptyArray;

      return Panel;
    })();

    v1.Result = (() => {
      class Result {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Result(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(26).string(message.type);
          }
          if (
            message.unit != null &&
            Object.hasOwnProperty.call(message, 'unit')
          ) {
            writer.uint32(34).string(message.unit);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.abnormalFlag != null &&
            Object.hasOwnProperty.call(message, 'abnormalFlag')
          ) {
            writer.uint32(50).string(message.abnormalFlag);
          }
          if (
            message.referenceRangeLow != null &&
            Object.hasOwnProperty.call(message, 'referenceRangeLow')
          ) {
            values$1.v1.Value.encode(
              message.referenceRangeLow,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.referenceRangeHigh != null &&
            Object.hasOwnProperty.call(message, 'referenceRangeHigh')
          ) {
            values$1.v1.Value.encode(
              message.referenceRangeHigh,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Result();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.type = reader.string();
                break;
              }
              case 4: {
                message.unit = reader.string();
                break;
              }
              case 5: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.abnormalFlag = reader.string();
                break;
              }
              case 7: {
                message.referenceRangeLow = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.referenceRangeHigh = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.Result) {
            return object;
          }
          const message = new $root.axle_health.v1.Result();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.unit != null) {
            message.unit = String(object.unit);
          }
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.axle_health.v1.Result.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.abnormalFlag != null) {
            message.abnormalFlag = String(object.abnormalFlag);
          }
          if (object.referenceRangeLow != null) {
            if (typeof object.referenceRangeLow !== 'object') {
              throw new TypeError(
                '.axle_health.v1.Result.referenceRangeLow: object expected, but got ' +
                  typeof object.referenceRangeLow
              );
            }
            message.referenceRangeLow = values$1.v1.Value.fromObject(
              object.referenceRangeLow
            );
          }
          if (object.referenceRangeHigh != null) {
            if (typeof object.referenceRangeHigh !== 'object') {
              throw new TypeError(
                '.axle_health.v1.Result.referenceRangeHigh: object expected, but got ' +
                  typeof object.referenceRangeHigh
              );
            }
            message.referenceRangeHigh = values$1.v1.Value.fromObject(
              object.referenceRangeHigh
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.type = '';
            object.unit = '';
            object.value = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (message.unit != null && message.hasOwnProperty('unit')) {
            object.unit = message.unit;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (
            message.abnormalFlag != null &&
            message.hasOwnProperty('abnormalFlag')
          ) {
            object.abnormalFlag = message.abnormalFlag;
          }
          if (
            message.referenceRangeLow != null &&
            message.hasOwnProperty('referenceRangeLow')
          ) {
            object.referenceRangeLow = values$1.v1.Value.toObject(
              message.referenceRangeLow,
              options
            );
          }
          if (
            message.referenceRangeHigh != null &&
            message.hasOwnProperty('referenceRangeHigh')
          ) {
            object.referenceRangeHigh = values$1.v1.Value.toObject(
              message.referenceRangeHigh,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.Result';
        }
      }

      Result.prototype.id = '';
      Result.prototype.name = '';
      Result.prototype.type = '';
      Result.prototype.unit = '';
      Result.prototype.value = null;
      Result.prototype.abnormalFlag = null;
      Result.prototype.referenceRangeLow = null;
      Result.prototype.referenceRangeHigh = null;

      return Result;
    })();

    v1.GetVisitRequest = (() => {
      class GetVisitRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetVisitRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetVisitRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.GetVisitRequest) {
            return object;
          }
          const message = new $root.axle_health.v1.GetVisitRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'DEVELOPMENT':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.axle_health.v1.AxleHealthEnvironment[
                    message.environment
                  ] === undefined
                  ? message.environment
                  : $root.axle_health.v1.AxleHealthEnvironment[
                      message.environment
                    ]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.GetVisitRequest';
        }
      }

      GetVisitRequest.prototype.id = '';
      GetVisitRequest.prototype.environment = 0;

      return GetVisitRequest;
    })();

    v1.GetVisitResponse = (() => {
      class GetVisitResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetVisitResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.visit != null &&
            Object.hasOwnProperty.call(message, 'visit')
          ) {
            $root.axle_health.v1.Visit.encode(
              message.visit,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetVisitResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.visit = $root.axle_health.v1.Visit.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.axle_health.v1.GetVisitResponse) {
            return object;
          }
          const message = new $root.axle_health.v1.GetVisitResponse();
          if (object.visit != null) {
            if (typeof object.visit !== 'object') {
              throw new TypeError(
                '.axle_health.v1.GetVisitResponse.visit: object expected, but got ' +
                  typeof object.visit
              );
            }
            message.visit = $root.axle_health.v1.Visit.fromObject(object.visit);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.visit = null;
          }
          let keys;
          if (message.visit != null && message.hasOwnProperty('visit')) {
            object.visit = $root.axle_health.v1.Visit.toObject(
              message.visit,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'axle_health.v1.GetVisitResponse';
        }
      }

      GetVisitResponse.prototype.visit = null;

      return GetVisitResponse;
    })();

    v1.AxleHealthEnvironment = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNDEFINED_ILLEGAL')] = 0;
      values[(valuesById[1] = 'DEVELOPMENT')] = 1;
      values[(valuesById[2] = 'PRODUCTION')] = 2;
      return values;
    })();

    v1.AxleHealthEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[
        (valuesById[0] = 'UNSPECIFIED_ILLEGAL_AXLE_HEALTH_EVENT_TYPE')
      ] = 0;
      values[(valuesById[1] = 'AXLE_HEALTH_LAB_ORDER_STATUS_UPDATE')] = 1;
      values[(valuesById[2] = 'AXLE_HEALTH_LAB_ORDER_RESULTS_UPDATE')] = 2;
      return values;
    })();

    v1.AxleHealthService = (() => {
      class AxleHealthService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new AxleHealthService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (AxleHealthService.prototype.getVisit = function getVisit(
          request,
          callback
        ) {
          return this.rpcCall(
            getVisit,
            $root.axle_health.v1.GetVisitRequest,
            $root.axle_health.v1.GetVisitResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetVisit' }
      );

      return AxleHealthService;
    })();

    return v1;
  })();

  return axle_health;
})();

export const axle_health = $root.axle_health;
