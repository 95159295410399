import { PermissionsContext } from './PermissionsContext';
import { PermissionsContextProps } from './types';
import { useContext } from 'react';

export const usePermissions = (): PermissionsContextProps => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};
