import { timing } from '@morf/proto/timing_v1_ts_proto';
import Long from 'long';

export const durationToString = (
  durationValue: timing.v1.IDuration | null | undefined
): string => {
  if (!!durationValue) {
    const duration =
      Long.fromValue(durationValue.nanoseconds || 0).toNumber() / 1000000000.0;
    return duration.toString();
  }

  return 'n/a';
};
