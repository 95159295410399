export const includesLowerCase = (
  str: string | undefined,
  searchStr: string | undefined
): boolean => {
  return (
    str !== undefined &&
    searchStr !== undefined &&
    str.toLowerCase().includes(searchStr.toLowerCase())
  );
};
