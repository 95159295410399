import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { NodeType } from '../types';

export const restartNode = {
  id: '1d35449d-2dc4-4dd2-a22f-5f137e8331ed',
  type: NodeType.RestartNode,
  data: {
    value: {
      title: 'Restart workflow',
      name: 'Restart Workflow',
      configuration: new workflows.v1.WorkflowRestart({}),
    },
  },
  position: { x: 0, y: 0 },
};
