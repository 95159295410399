import { useTheme } from '@morf/theming';
import { Container } from '../../Container';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Typography';
import { Timeslots } from '../../Timeslots';
import { FC, memo } from 'react';
import { useResize } from '../../Hooks/useResize';
import { WeekAvailabilityCardProps } from './types';
import { formatDate } from '../formatDate';

const WeekAvailabilityCard: FC<WeekAvailabilityCardProps> = ({
  date,
  slots,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
}) => {
  const theme = useTheme();

  const timeslots = useResize({
    columns: {
      xxl: 4,
      xl: 4,
      lg: 2,
      md: 2,
      sm: 2,
      xs: 1,
    },
  });

  const noAvailability = !slots || slots.length === 0;

  return (
    <Container
      data-testid={`week-availability-card-${date}`}
      borderType='borderTop'
      p={1.25}
    >
      {noAvailability ? (
        <Flexbox justifyContent='flex-start' alignItems='center' gap={0.5}>
          <Text tag='h4'>{formatDate(date)}</Text>
          <Text tag='p2' color={theme.colors.text.muted}>
            No availability
          </Text>
        </Flexbox>
      ) : (
        <Flexbox direction='column' justifyContent='flex-start'>
          <Text tag='h4'>{formatDate(date)}</Text>
          <Timeslots
            columns={timeslots.columns}
            slots={slots}
            selectedSlot={selectedSlot}
            onSelectSlot={onSelectSlot}
            onConfirmSlot={onConfirmSlot}
          />
        </Flexbox>
      )}
    </Container>
  );
};

export const MemoizedWeekAvailabilityCard = memo(WeekAvailabilityCard);
