import { NodeType } from '../types';
import { LeadLookupConfiguration } from './ProfileLookupSubflow/mockData';

export const profileLookupNode = {
  id: 'profile-lookup-node',
  type: NodeType.ProfileLookupNode,
  data: {
    value: {
      title: 'Profile lookup',
      name: 'Profile Lookup',
      configuration: LeadLookupConfiguration,
    },
  },
  position: { x: 0, y: 0 },
};
