import moment from 'moment';
import { TimeOption } from './TimeDropdown/TimeOption/types';
import { dateToTimestamp } from '../../../../apps/admin/components/helpers/formatValue/dateToTimestamp';
import { defaultDateTimeFormatWithSeconds } from './constants';

export const parseTimeRange = (label: string): TimeOption | undefined => {
  const [start, end] = label.split(' - ').map((t) => t.trim());
  const startDate = moment(start, defaultDateTimeFormatWithSeconds).toDate();
  const endDate = moment(end, defaultDateTimeFormatWithSeconds).toDate();

  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return undefined;
  }

  return {
    label,
    timeRange: {
      startTimestamp: dateToTimestamp(startDate),
      endTimestamp: dateToTimestamp(endDate),
    },
  };
};
