import { FC } from 'react';
import { LoaderProps } from './types';
import { LoaderContainer } from './Loader.css';
import { PropagateLoader } from 'react-spinners';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { Image } from '../Image';

export const Loader: FC<LoaderProps> = ({ color, ...props }) => {
  const theme = useTheme();
  return (
    <Container position='relative' {...props}>
      <LoaderContainer data-testid='loader'>
        {theme.loader ? (
          <Image
            width='5rem'
            height='5rem'
            src={theme.loader}
            alt='Loading...'
          />
        ) : (
          <PropagateLoader color={color || theme.colors.main.primary.darker} />
        )}
      </LoaderContainer>
    </Container>
  );
};
