import { FC } from 'react';
import { PrerequisitesProps } from './types';
import { Modal } from '../../Modal';
import { Flexbox } from '../../Flexbox';
import { useTheme } from '@morf/theming';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { Button } from '../../Button';
import { Container } from '../../Container';

export const Prerequisites: FC<PrerequisitesProps> = ({
  isOpen,
  onClose,
  onPrimaryButtonClick,
  prerequisites,
}) => {
  const theme = useTheme();
  const {
    iconName,
    title,
    description,
    secondaryButtonText,
    secondaryButtonOnClick,
    secondaryButtonIcon,
    primaryButtonText,
  } = prerequisites;
  return (
    <Modal isOpen={isOpen} onClose={onClose} width='27rem'>
      <Flexbox direction='column' alignItems='center' gap={1.5}>
        {iconName && (
          <Icon
            name={iconName}
            size={4}
            stroke={theme.colors.support.yellow.darkest}
          />
        )}
        <Flexbox direction='column' gap={0.75}>
          <Text tag='h2' align='center'>
            {title}
          </Text>
          <Text tag='p1' align='center'>
            {description}
          </Text>
        </Flexbox>
        <Flexbox justifyContent='center' flexBasis='1/2'>
          {secondaryButtonText && (
            <Button
              variant='secondary'
              onClick={secondaryButtonOnClick || onClose}
              width='100%'
            >
              <Flexbox justifyContent='center' alignItems='center' gap={0.5}>
                <Text color='inherit'>{secondaryButtonText}</Text>
                {secondaryButtonIcon && <Icon name='link-out' size={1} />}
              </Flexbox>
            </Button>
          )}

          <Button variant='primary' onClick={onPrimaryButtonClick} width='100%'>
            <Text color='inherit'>{primaryButtonText}</Text>
          </Button>
        </Flexbox>
      </Flexbox>
    </Modal>
  );
};
