import { EventPayloadExamplePanel } from '../../../apps/admin/components/workflow/BuildWorkflow/EventPayloadExamplePanel';
import { FC, memo, useLayoutEffect, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { NodePanel } from '../../../apps/admin/components/workflow/NodePanel';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import { PanelWorkflowProps } from './types';
import { Workflow } from '../Workflow';
import { renderPanel } from './renderPanel';
import { useWorkflow } from '../../../apps/admin/components/context/workflow/useWorkflow';

const PanelWorkflow: FC<PanelWorkflowProps> = ({
  eventPayloadExample,
  onUpdateDescription,
  onUpdateName,
  ...props
}) => {
  const minEventPayloadExamplePanelSize = 15;
  const defaultEventPayloadExamplePanelSize = 30;
  const maxEventPayloadExamplePanelSize = 50;

  const minNodePanelSizePixels = 650;
  const [minNodePanelSize, setMinNodePanelSize] = useState(50);
  const maxNodePanelSize = 100;

  const {
    selectedNode,
    setSelectedNode,
    showEventPayloadPanel,
    setShowEventPayloadPanel,
  } = useWorkflow();

  const handleEventPayloadPanelClose = () => {
    setShowEventPayloadPanel(false);
  };

  const handleNodePanelClose = () => {
    setSelectedNode(undefined);
  };

  useLayoutEffect(() => {
    const panelGroup = document.querySelector(
      '[data-panel-group-id="panel-workflow"]'
    ) as HTMLElement | null;
    const resizeHandles = document.querySelectorAll(
      '[data-panel-resize-handle-id]'
    ) as NodeListOf<HTMLElement>;

    if (panelGroup && resizeHandles.length > 0) {
      const observer = new ResizeObserver(() => {
        let width = panelGroup.offsetWidth;

        resizeHandles.forEach((resizeHandle) => {
          width -= resizeHandle.offsetWidth;
        });

        setMinNodePanelSize((minNodePanelSizePixels / width) * 100);
      });

      observer.observe(panelGroup);
      resizeHandles.forEach((resizeHandle) => {
        observer.observe(resizeHandle);
      });

      return () => {
        observer.unobserve(panelGroup);
        resizeHandles.forEach((resizeHandle) => {
          observer.unobserve(resizeHandle);
        });
        observer.disconnect();
      };
    }
  }, [selectedNode?.id]);

  return (
    <Flexbox
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      gap={0}
    >
      <PanelGroup id='panel-workflow' direction='horizontal'>
        {eventPayloadExample && showEventPayloadPanel && (
          <>
            <Panel
              id='event-payload-example-panel'
              order={1}
              minSize={minEventPayloadExamplePanelSize}
              defaultSize={defaultEventPayloadExamplePanelSize}
              maxSize={maxEventPayloadExamplePanelSize}
            >
              <EventPayloadExamplePanel
                eventPayloadExample={eventPayloadExample}
                onClose={handleEventPayloadPanelClose}
              />
            </Panel>
            <PanelResizeHandle />
          </>
        )}

        <Panel id='workflow' order={2}>
          <Workflow
            key={`${showEventPayloadPanel}-${selectedNode?.id}`}
            showEventPayload={!!eventPayloadExample}
            {...props}
          />
        </Panel>

        {selectedNode && (
          <>
            <PanelResizeHandle />
            <Panel
              id='node-panel'
              order={3}
              minSize={minNodePanelSize}
              defaultSize={minNodePanelSize}
              maxSize={maxNodePanelSize}
            >
              <NodePanel
                node={selectedNode}
                onClose={handleNodePanelClose}
                onUpdateDescription={onUpdateDescription}
                onUpdateName={onUpdateName}
                tabs={renderPanel(selectedNode)}
              />
            </Panel>
          </>
        )}
      </PanelGroup>
    </Flexbox>
  );
};

export const MemoizedPanelWorkflow = memo(PanelWorkflow);
