import { timing } from '@morf/proto/timing_v1_ts_proto';
import { TimestampType } from '@morf/ui';
import Long from 'long';
import moment from 'moment-timezone';

export const timestampToString = (
  timestampValue: timing.v1.ITimestamp | null | undefined,
  timezone: string = moment.tz.guess()
): TimestampType => {
  if (timestampValue && timestampValue.seconds) {
    const secondsAsMilliseconds =
      Long.fromValue(timestampValue.seconds).toNumber() * 1000;
    const nanosecondsAsMilliseconds = (timestampValue.nanoseconds || 0) / 1e6;
    const milliseconds = secondsAsMilliseconds + nanosecondsAsMilliseconds;
    const date = new Date(milliseconds);
    return {
      isoDateString: date.toISOString(),
      timezone: timezone,
    };
  }
  return {
    isoDateString: 'n/a',
    timezone: '',
  };
};
