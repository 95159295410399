import { useBreakpoint } from './useBreakpoint';

type ResponsiveObject<T> = {
  [key in keyof T]: T[key] extends object
    ? Partial<{ [key2 in keyof T[key]]: T[key][key2] }>
    : T[key];
};

export const useResize = <T extends object>(
  props: ResponsiveObject<T>
): any => {
  const breakpoint = useBreakpoint();

  return Object.entries(props).reduce((acc, [key, value]) => {
    const responsiveValue =
      (value as Record<string, unknown>)[breakpoint] ?? value;

    return {
      ...acc,
      [key]: responsiveValue,
    };
  }, {}) as T;
};
