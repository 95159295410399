import { AddRow } from '../../AddRow';
import { ExtendedColumnDef } from '../../types';
import { Flexbox } from '../../../Flexbox';
import { RowData, flexRender } from '@tanstack/react-table';
import { StyledHeadCell } from './TableHeadCell.css';
import { TableHeadCellProps } from './types';
import { memo } from 'react';

const HeadCell: <RowData>(
  props: TableHeadCellProps<RowData>
) => JSX.Element = ({ header, isLastCell, onRowAdd }) => {
  const columnDef = header.column.columnDef as ExtendedColumnDef<RowData>;
  const minWidth = `${header.column.getSize() / 16}rem`;

  return (
    <StyledHeadCell
      key={header.id}
      data-testid='head-cell'
      borderType={columnDef.headCellBorderType}
      headCellPl={columnDef.headCellPl}
      headCellPr={columnDef.headCellPr}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        minWidth={minWidth}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}

        {isLastCell && onRowAdd && <AddRow onClick={onRowAdd} />}
      </Flexbox>
    </StyledHeadCell>
  );
};
export const TableHeadCell = memo(HeadCell) as typeof HeadCell;
