import { FC, useRef, useEffect, useState } from 'react';
import { Text } from '../Text/Text';
import { Tooltip } from '../../Tooltip';
import { TooltipTextProps } from './types';
import { Container } from '../../Container';

export const TooltipText: FC<TooltipTextProps> = ({
  containerWidth,
  tooltipDirection,
  tooltipWidth,
  arrowDirection,
  ...props
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const updateOverflowState = () => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateOverflowState();
    const handleResize = () => {
      updateOverflowState();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props]);

  return (
    <Container height='auto'>
      <Tooltip
        containerWidth={containerWidth}
        tooltipDirection={tooltipDirection}
        tooltipWidth={tooltipWidth}
        arrowDirection={arrowDirection}
        tooltipText={props.children?.toString() || ''}
        isActive={isOverflow}
      >
        <Text {...props} align='left' whiteSpace='nowrap' textRef={textRef}>
          {props.children}
        </Text>
      </Tooltip>
    </Container>
  );
};
