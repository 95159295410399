import { LocalStorage } from '@morf/ui';
import { MORF_USER } from '@morf/constants';
import { MorfUser } from '../../privateRoute/types';
import { decodeStringBase64 } from '../../helpers';

export const getDecodedUser = (): MorfUser | undefined => {
  const encodedUser = LocalStorage.get<string>(MORF_USER);

  if (encodedUser) {
    try {
      return JSON.parse(decodeStringBase64(encodedUser));
    } catch (error) {
      throw Error('Failed to decode and parse user');
    }
  }

  return undefined;
};
