import { ActionItemProps } from './types';
import { FC, memo } from 'react';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Container } from '../Container';
import { Text } from '../Typography';

const ActionItem: FC<ActionItemProps> = ({
  iconName,
  isExpandable,
  label,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      direction='row'
      data-testid='action-item'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      cursor='pointer'
      onClick={onClick}
      hoverBackgroundColor={theme.colors.ui.body}
      px={0.75}
      py={0.5}
      gap={0.75}
    >
      {iconName && (
        <Icon
          name={iconName}
          stroke={theme.colors.ui.dark}
          size={1.25}
          strokeWidth={1.75}
        />
      )}

      <Container>
        <Text tag='p2'>{label}</Text>
      </Container>

      {isExpandable && (
        <Icon
          name='chevron-right'
          stroke={theme.colors.ui.dark}
          size={1.25}
          strokeWidth={1.75}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedActionItem = memo(ActionItem);
