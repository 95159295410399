import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { ParameterMissingPolicy } from '../../../../ActionSettings/ParameterMissingPolicy';
import { ProfileConvertNodeProps } from '../types';
import { RemoveNode } from '../../../../RemoveNode';
import { handleNodeSave } from '../handleNodeSave';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const ProfileConvertSettings: FC<ProfileConvertNodeProps> = (node) => {
  const { nodes, setNodes } = useWorkflow();
  const { id, data } = node;

  const {
    configuration,
    onAddNode,
    onRemoveNode,
    onUpdateNode,
    isReadOnly,
    isRequired,
  } = data.value;

  const requiredParameterMissingAction =
    configuration?.parameterMissingPolicy?.requiredParameterMissingBehavior;

  const optionalParameterMissingAction =
    configuration?.parameterMissingPolicy?.optionalParameterMissingBehavior;

  const handleRequiredParameterMissingChange = (
    updatedRequiredParameterMissingAction: number
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        parameterMissingPolicy:
          new workflow_parameters.v1.ParameterMissingPolicy({
            ...configuration.parameterMissingPolicy,
            requiredParameterMissingBehavior:
              updatedRequiredParameterMissingAction,
          }),
      });

      handleNodeSave(
        data,
        destinationAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleOptionalParameterMissingChange = (
    updatedOptionalParameterMissingAction: number
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        parameterMissingPolicy:
          new workflow_parameters.v1.ParameterMissingPolicy({
            ...configuration.parameterMissingPolicy,
            optionalParameterMissingBehavior:
              updatedOptionalParameterMissingAction,
          }),
      });

      handleNodeSave(
        data,
        destinationAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleRemoveNode = () => {
    onRemoveNode &&
      onRemoveNode({
        nodeId: node.id,
      });
  };

  return (
    <Flexbox
      data-testid='profile-convert-settings'
      direction='column'
      justifyContent='flex-start'
      height='auto'
    >
      <ParameterMissingPolicy
        isReadOnly={isReadOnly}
        onOptionalParameterMissingChange={handleOptionalParameterMissingChange}
        onRequiredParameterMissingChange={handleRequiredParameterMissingChange}
        optionalParameterMissingAction={optionalParameterMissingAction}
        requiredParameterMissingAction={requiredParameterMissingAction}
      />
      {!isReadOnly && (
        <RemoveNode
          type='convert profile to patient'
          onRemoveNode={handleRemoveNode}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedProfileConvertSettings = memo(ProfileConvertSettings);
