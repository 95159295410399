import { styled } from '@morf/theming';
import { Container, ContainerProps } from '../../../../Container';

export const StyledContainer = styled(Container)<
  ContainerProps & { labelX: number; labelY: number }
>`
  position: absolute;
  width: auto;
  height: auto;
  padding: 0.25rem;
  pointer-events: all;
  transform: translate(-50%, -50%)
    translate(${(props) => props.labelX}px, ${(props) => props.labelY}px);
`;

export const StyledAddEdgeButton = styled.button`
  padding: 0;
  height: 0.75rem;
  width: 0.75rem;
  border-color: transparent;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius[0.125]};
  background-color: ${(props) => props.theme.colors.ui.muted};
  color: ${(props) => props.theme.colors.text.inverse};

  :hover {
    background-color: ${(props) => props.theme.colors.ui.card};
    outline: ${(props) => props.theme.borderWidth[0.125]} solid
      ${(props) => props.theme.colors.main.primary.lightest};
    color: ${(props) => props.theme.colors.main.primary.lightest};
  }

  :focus {
    background-color: ${(props) => props.theme.colors.ui.card};
    outline: ${(props) => props.theme.borderWidth[0.125]} solid
      ${(props) => props.theme.colors.main.primary.darkest};
    color: ${(props) => props.theme.colors.main.primary.darkest};
  }
`;
