import { Dropdown } from '../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { RequiredIndicator } from '../../../RequiredIndicator';
import { RequiredParameterMissingProps } from './types';
import { Text } from '../../../Typography';
import { formatEnumToOptions } from '../../../Helpers/formatEnumToOptions';
import { labelMap } from './constants';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const RequiredParameterMissing: FC<RequiredParameterMissingProps> = ({
  isReadOnly,
  onRequiredParameterMissingChange,
  requiredParameterMissingAction,
}) => {
  const theme = useTheme();

  const requiredParameterMissingActions = formatEnumToOptions(
    workflow_parameters.v1.ParameterMissingPolicy
      .RequiredParameterMissingBehavior
  ).map((option) =>
    labelMap[option.label]
      ? { ...option, label: labelMap[option.label] }
      : option
  );

  const requiredParameterMissingActionAsOption =
    requiredParameterMissingActions.filter(
      (fieldNotPresentAction) =>
        fieldNotPresentAction.value ===
        requiredParameterMissingAction?.toString()
    );

  return (
    <Flexbox
      data-testid='required-parameter-missing'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.25}
    >
      <Flexbox direction='row' height='auto'>
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={0}
        >
          <Text tag='p2' whiteSpace='nowrap'>
            Required parameters not present on the payload
          </Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select what should happen.
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={requiredParameterMissingActionAsOption}
          options={requiredParameterMissingActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onRequiredParameterMissingChange(selectedOptionNumber);
          }}
          readOnly={isReadOnly}
          clearable
          height='2.25rem'
        />
      </Flexbox>
      {!requiredParameterMissingAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedRequiredParameterMissing = memo(RequiredParameterMissing);
