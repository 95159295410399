import { useTheme } from '@morf/theming';
import { ComingSoon } from '../../../ComingSoon';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { FC, Ref, forwardRef } from 'react';
import { IntegrationCardExtended } from '../../../IntegrationCardExtended';
import { IntegrationStepProps } from './types';
import { Container } from '../../../Container';
import { useResize } from '../../../Hooks/useResize';

export const IntegrationStep: FC<IntegrationStepProps> = forwardRef(
  (
    {
      currentStepIndex,
      currentStep,
      integrationType,
      selectedIntegration,
      setSelectedIntegration,
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    const responsiveIntegration = useResize({
      width: {
        xxl: '50%',
        xl: '50%',
        lg: '50%',
        md: '100%',
        sm: '100%',
        xs: '100%',
      },
    });

    return (
      <Flexbox
        containerRef={ref}
        direction='column'
        alignItems='center'
        gap={2}
        p={2}
        overflowY='scroll'
      >
        <Flexbox
          direction='column'
          gap={0.75}
          width={responsiveIntegration.width}
        >
          <Text tag='h3' color={theme.colors.text.muted}>
            Step {currentStepIndex + 1}
          </Text>
          <Text tag='h1'>{currentStep.title}</Text>
          <Text tag='subtitle'>{currentStep.description}</Text>
        </Flexbox>
        <Container width={responsiveIntegration.width}>
          {currentStep &&
            currentStep.integrations &&
            currentStep.integrations.map((integration, index) => (
              <IntegrationCardExtended
                key={index}
                id={integration.id}
                imageUrl={integration.imageUrl}
                shortDescription={integration.shortDescription}
                longDescription={integration.longDescription}
                name={integration.name}
                useCases={integration.useCases}
                compatibility={integration.compatibility}
                selectedIntegration={selectedIntegration}
                setSelectedIntegration={setSelectedIntegration}
                parentRef={ref}
              />
            ))}
        </Container>

        <Container width={responsiveIntegration.width}>
          <ComingSoon type={integrationType + 's'} />
        </Container>
      </Flexbox>
    );
  }
);
