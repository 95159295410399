import { FC } from 'react';
import { FlexboxProps } from './types';
import { StyledFlexbox } from './Flexbox.css';

export const Flexbox: FC<FlexboxProps> = ({
  containerRef,
  children,
  direction = 'row',
  gap = 1,
  justifyContent = 'space-between',
  alignItems = 'flex-start',
  flexWrap = 'nowrap',
  id,
  ...props
}) => {
  return (
    <StyledFlexbox
      data-testid={id}
      ref={containerRef}
      direction={direction}
      gap={gap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      {...props}
    >
      {children}
    </StyledFlexbox>
  );
};
