import _ from 'lodash';
import { ObjectTypeId } from './ObjectTypeId/types';

export const filterObjectTypeIds = (
  objectTypeIds: ObjectTypeId[],
  searchValue: string
): ObjectTypeId[] => {
  if (!searchValue) return objectTypeIds;
  const lowercasedSearchValue = _.toLower(searchValue);
  return _.filter(
    objectTypeIds,
    ({ id, label }) =>
      _.includes(_.toLower(id), lowercasedSearchValue) ||
      _.includes(_.toLower(label), lowercasedSearchValue)
  );
};
