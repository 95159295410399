import { FC } from 'react';
import { useTheme } from '@morf/theming';
import { Modal } from '../Modal';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { Button } from '../Button';
import { AppointmentRescheduleModalProps } from './types';
import { useResize } from '../Hooks/useResize';
import { RenderHTML } from '../RenderHTML';

export const AppointmentRescheduleModal: FC<
  AppointmentRescheduleModalProps
> = ({ isOpen, onClose, onReschedule }) => {
  const theme = useTheme();

  const handleReschedule = () => {
    onReschedule();
    onClose();
  };

  const responsiveButtons = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'column',
      xs: 'column',
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} width='25.5rem'>
      <Flexbox
        direction='column'
        alignItems='center'
        gap={1.5}
        data-testid='reschedule-modal'
      >
        <Icon name='alert' size={4} stroke={theme.colors.ui.muted} />
        {theme.withinTimeWindowTitle && (
          <Text tag='h5' align='center'>
            {theme.withinTimeWindowTitle}
          </Text>
        )}
        {theme.withinTimeWindowMessage && (
          <Text tag='p3' align='center'>
            <RenderHTML htmlContent={theme.withinTimeWindowMessage} />
          </Text>
        )}
        <Flexbox
          direction={responsiveButtons.direction}
          justifyContent='center'
          flexBasis='1/2'
        >
          <Button
            variant='custom'
            color={theme.colors.main.primary.darker}
            borderColor={theme.colors.ui.divider}
            backgroundColor={theme.colors.ui.card}
            width='100%'
            onClick={onClose}
          >
            <Text color='inherit' whiteSpace='nowrap'>
              No, go back
            </Text>
          </Button>
          <Button
            variant='custom'
            width='100%'
            backgroundColor={theme.colors.main.primary.darker}
            color={theme.colors.text.inverse}
            borderColor={theme.colors.main.primary.darker}
            onClick={handleReschedule}
          >
            <Text color='inherit' whiteSpace='nowrap'>
              Accept fee and reschedule
            </Text>
          </Button>
        </Flexbox>
      </Flexbox>
    </Modal>
  );
};
