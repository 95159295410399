import _ from 'lodash';
import { Identifier } from './Identifier/types';

export const filterIdentifiers = (
  identifiers: Identifier[],
  searchValue: string
): Identifier[] => {
  if (!searchValue) return identifiers;
  return identifiers.filter(({ name }) =>
    name?.toLowerCase().includes(searchValue.toLowerCase())
  );
};
