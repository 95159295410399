import { Editor } from '@monaco-editor/react';
import { styled } from '@morf/theming';

export const StyledEditor = styled(Editor)`
  .monaco-editor .scroll-decoration {
    box-shadow: none;
  }

  .monaco-editor .find-widget .monaco-inputbox.synthetic-focus {
    outline-color: #ffffff;
  }
`;
