import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { IntegrationIcon } from '../../../../../IntegrationIcon';
import { Text } from '../../../../../Typography';
import { ActionListProps } from './types';
import { useTheme } from '@morf/theming';

const ActionList: FC<ActionListProps> = ({ list, onClick }) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='action-list'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='scroll'
      gap={0}
    >
      {list.map((item) => (
        <Flexbox
          key={item.name}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          cursor='pointer'
          hoverBackgroundColor={theme.colors.ui.body}
          onClick={() => onClick(item)}
          px={0.75}
          py={0.5}
          gap={0.5}
        >
          {item.integrationIconName && (
            <IntegrationIcon name={item.integrationIconName} size={1.25} />
          )}
          <Text tag='p2'> {item.name}</Text>
        </Flexbox>
      ))}
    </Flexbox>
  );
};

export const MemoizedActionList = memo(ActionList);
