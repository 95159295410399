'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$2 } from './duration_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { google as google$3 } from './timestamp_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.api = (() => {
    const api = {};
    api.expr = (() => {
      const expr = {};
      expr.v1alpha1 = (() => {
        const v1alpha1 = {};
        v1alpha1.ParsedExpr = (() => {
          class ParsedExpr {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new ParsedExpr(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.expr != null &&
                Object.hasOwnProperty.call(message, 'expr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.encode(
                  message.expr,
                  writer.uint32(18).fork()
                ).ldelim();
              }
              if (
                message.sourceInfo != null &&
                Object.hasOwnProperty.call(message, 'sourceInfo')
              ) {
                $root.google.api.expr.v1alpha1.SourceInfo.encode(
                  message.sourceInfo,
                  writer.uint32(26).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new ParsedExpr();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 2: {
                    message.expr = $root.google.api.expr.v1alpha1.Expr.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 3: {
                    message.sourceInfo =
                      $root.google.api.expr.v1alpha1.SourceInfo.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.ParsedExpr) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.ParsedExpr();
              if (object.expr != null) {
                if (typeof object.expr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.ParsedExpr.expr: object expected, but got ' +
                      typeof object.expr
                  );
                }
                message.expr = $root.google.api.expr.v1alpha1.Expr.fromObject(
                  object.expr
                );
              }
              if (object.sourceInfo != null) {
                if (typeof object.sourceInfo !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.ParsedExpr.sourceInfo: object expected, but got ' +
                      typeof object.sourceInfo
                  );
                }
                message.sourceInfo =
                  $root.google.api.expr.v1alpha1.SourceInfo.fromObject(
                    object.sourceInfo
                  );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.expr = null;
                object.sourceInfo = null;
              }
              let keys;
              if (message.expr != null && message.hasOwnProperty('expr')) {
                object.expr = $root.google.api.expr.v1alpha1.Expr.toObject(
                  message.expr,
                  options
                );
              }
              if (
                message.sourceInfo != null &&
                message.hasOwnProperty('sourceInfo')
              ) {
                object.sourceInfo =
                  $root.google.api.expr.v1alpha1.SourceInfo.toObject(
                    message.sourceInfo,
                    options
                  );
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.ParsedExpr';
            }
          }

          ParsedExpr.prototype.expr = null;
          ParsedExpr.prototype.sourceInfo = null;

          return ParsedExpr;
        })();

        v1alpha1.Expr = (() => {
          class Expr {
            get exprKind() {
              for (const key of [
                'constExpr',
                'identExpr',
                'selectExpr',
                'callExpr',
                'listExpr',
                'structExpr',
                'comprehensionExpr',
              ]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set exprKind(name) {
              for (const key of [
                'constExpr',
                'identExpr',
                'selectExpr',
                'callExpr',
                'listExpr',
                'structExpr',
                'comprehensionExpr',
              ]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Expr(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.id != null &&
                Object.hasOwnProperty.call(message, 'id')
              ) {
                writer.uint32(16).int64(message.id);
              }
              if (
                message.constExpr != null &&
                Object.hasOwnProperty.call(message, 'constExpr')
              ) {
                $root.google.api.expr.v1alpha1.Constant.encode(
                  message.constExpr,
                  writer.uint32(26).fork()
                ).ldelim();
              }
              if (
                message.identExpr != null &&
                Object.hasOwnProperty.call(message, 'identExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.Ident.encode(
                  message.identExpr,
                  writer.uint32(34).fork()
                ).ldelim();
              }
              if (
                message.selectExpr != null &&
                Object.hasOwnProperty.call(message, 'selectExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.Select.encode(
                  message.selectExpr,
                  writer.uint32(42).fork()
                ).ldelim();
              }
              if (
                message.callExpr != null &&
                Object.hasOwnProperty.call(message, 'callExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.Call.encode(
                  message.callExpr,
                  writer.uint32(50).fork()
                ).ldelim();
              }
              if (
                message.listExpr != null &&
                Object.hasOwnProperty.call(message, 'listExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.CreateList.encode(
                  message.listExpr,
                  writer.uint32(58).fork()
                ).ldelim();
              }
              if (
                message.structExpr != null &&
                Object.hasOwnProperty.call(message, 'structExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.CreateStruct.encode(
                  message.structExpr,
                  writer.uint32(66).fork()
                ).ldelim();
              }
              if (
                message.comprehensionExpr != null &&
                Object.hasOwnProperty.call(message, 'comprehensionExpr')
              ) {
                $root.google.api.expr.v1alpha1.Expr.Comprehension.encode(
                  message.comprehensionExpr,
                  writer.uint32(74).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Expr();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 2: {
                    message.id = reader.int64();
                    break;
                  }
                  case 3: {
                    message.constExpr =
                      $root.google.api.expr.v1alpha1.Constant.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 4: {
                    message.identExpr =
                      $root.google.api.expr.v1alpha1.Expr.Ident.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 5: {
                    message.selectExpr =
                      $root.google.api.expr.v1alpha1.Expr.Select.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 6: {
                    message.callExpr =
                      $root.google.api.expr.v1alpha1.Expr.Call.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 7: {
                    message.listExpr =
                      $root.google.api.expr.v1alpha1.Expr.CreateList.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 8: {
                    message.structExpr =
                      $root.google.api.expr.v1alpha1.Expr.CreateStruct.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 9: {
                    message.comprehensionExpr =
                      $root.google.api.expr.v1alpha1.Expr.Comprehension.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Expr) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Expr();
              if (object.id != null) {
                if ($util.Long) {
                  (message.id = $util.Long.fromValue(
                    object.id
                  )).unsigned = false;
                } else if (typeof object.id === 'string') {
                  message.id = parseInt(object.id, 10);
                } else if (typeof object.id === 'number') {
                  message.id = object.id;
                } else if (typeof object.id === 'object') {
                  message.id = new $util.LongBits(
                    object.id.low >>> 0,
                    object.id.high >>> 0
                  ).toNumber();
                }
              }
              if (object.constExpr != null) {
                if (typeof object.constExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.constExpr: object expected, but got ' +
                      typeof object.constExpr
                  );
                }
                message.constExpr =
                  $root.google.api.expr.v1alpha1.Constant.fromObject(
                    object.constExpr
                  );
              }
              if (object.identExpr != null) {
                if (typeof object.identExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.identExpr: object expected, but got ' +
                      typeof object.identExpr
                  );
                }
                message.identExpr =
                  $root.google.api.expr.v1alpha1.Expr.Ident.fromObject(
                    object.identExpr
                  );
              }
              if (object.selectExpr != null) {
                if (typeof object.selectExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.selectExpr: object expected, but got ' +
                      typeof object.selectExpr
                  );
                }
                message.selectExpr =
                  $root.google.api.expr.v1alpha1.Expr.Select.fromObject(
                    object.selectExpr
                  );
              }
              if (object.callExpr != null) {
                if (typeof object.callExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.callExpr: object expected, but got ' +
                      typeof object.callExpr
                  );
                }
                message.callExpr =
                  $root.google.api.expr.v1alpha1.Expr.Call.fromObject(
                    object.callExpr
                  );
              }
              if (object.listExpr != null) {
                if (typeof object.listExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.listExpr: object expected, but got ' +
                      typeof object.listExpr
                  );
                }
                message.listExpr =
                  $root.google.api.expr.v1alpha1.Expr.CreateList.fromObject(
                    object.listExpr
                  );
              }
              if (object.structExpr != null) {
                if (typeof object.structExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.structExpr: object expected, but got ' +
                      typeof object.structExpr
                  );
                }
                message.structExpr =
                  $root.google.api.expr.v1alpha1.Expr.CreateStruct.fromObject(
                    object.structExpr
                  );
              }
              if (object.comprehensionExpr != null) {
                if (typeof object.comprehensionExpr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Expr.comprehensionExpr: object expected, but got ' +
                      typeof object.comprehensionExpr
                  );
                }
                message.comprehensionExpr =
                  $root.google.api.expr.v1alpha1.Expr.Comprehension.fromObject(
                    object.comprehensionExpr
                  );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false);
                  object.id =
                    options.longs === String
                      ? long.toString()
                      : options.longs === Number
                      ? long.toNumber()
                      : long;
                } else {
                  object.id = options.longs === String ? '0' : 0;
                }
              }
              let keys;
              if (message.id != null && message.hasOwnProperty('id')) {
                object.id =
                  typeof message.id === 'number'
                    ? options.longs === String
                      ? String(message.id)
                      : message.id
                    : options.longs === String
                    ? $util.Long.prototype.toString.call(message.id)
                    : options.longs === Number
                    ? new $util.LongBits(
                        message.id.low >>> 0,
                        message.id.high >>> 0
                      ).toNumber()
                    : message.id;
              }
              if (
                message.constExpr != null &&
                message.hasOwnProperty('constExpr')
              ) {
                object.constExpr =
                  $root.google.api.expr.v1alpha1.Constant.toObject(
                    message.constExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'constExpr';
                }
              }
              if (
                message.identExpr != null &&
                message.hasOwnProperty('identExpr')
              ) {
                object.identExpr =
                  $root.google.api.expr.v1alpha1.Expr.Ident.toObject(
                    message.identExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'identExpr';
                }
              }
              if (
                message.selectExpr != null &&
                message.hasOwnProperty('selectExpr')
              ) {
                object.selectExpr =
                  $root.google.api.expr.v1alpha1.Expr.Select.toObject(
                    message.selectExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'selectExpr';
                }
              }
              if (
                message.callExpr != null &&
                message.hasOwnProperty('callExpr')
              ) {
                object.callExpr =
                  $root.google.api.expr.v1alpha1.Expr.Call.toObject(
                    message.callExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'callExpr';
                }
              }
              if (
                message.listExpr != null &&
                message.hasOwnProperty('listExpr')
              ) {
                object.listExpr =
                  $root.google.api.expr.v1alpha1.Expr.CreateList.toObject(
                    message.listExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'listExpr';
                }
              }
              if (
                message.structExpr != null &&
                message.hasOwnProperty('structExpr')
              ) {
                object.structExpr =
                  $root.google.api.expr.v1alpha1.Expr.CreateStruct.toObject(
                    message.structExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'structExpr';
                }
              }
              if (
                message.comprehensionExpr != null &&
                message.hasOwnProperty('comprehensionExpr')
              ) {
                object.comprehensionExpr =
                  $root.google.api.expr.v1alpha1.Expr.Comprehension.toObject(
                    message.comprehensionExpr,
                    options
                  );
                if (options.oneofs) {
                  object.exprKind = 'comprehensionExpr';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Expr';
            }
          }

          Expr.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
          Expr.prototype.constExpr = null;
          Expr.prototype.identExpr = null;
          Expr.prototype.selectExpr = null;
          Expr.prototype.callExpr = null;
          Expr.prototype.listExpr = null;
          Expr.prototype.structExpr = null;
          Expr.prototype.comprehensionExpr = null;
          Expr.Ident = (() => {
            class Ident {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new Ident(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.name != null &&
                  Object.hasOwnProperty.call(message, 'name')
                ) {
                  writer.uint32(10).string(message.name);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new Ident();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.name = reader.string();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof $root.google.api.expr.v1alpha1.Expr.Ident
                ) {
                  return object;
                }
                const message = new $root.google.api.expr.v1alpha1.Expr.Ident();
                if (object.name != null) {
                  message.name = String(object.name);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.name = '';
                }
                let keys;
                if (message.name != null && message.hasOwnProperty('name')) {
                  object.name = message.name;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.Ident';
              }
            }

            Ident.prototype.name = '';

            return Ident;
          })();

          Expr.Select = (() => {
            class Select {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new Select(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.operand != null &&
                  Object.hasOwnProperty.call(message, 'operand')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.operand,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.field != null &&
                  Object.hasOwnProperty.call(message, 'field')
                ) {
                  writer.uint32(18).string(message.field);
                }
                if (
                  message.testOnly != null &&
                  Object.hasOwnProperty.call(message, 'testOnly')
                ) {
                  writer.uint32(24).bool(message.testOnly);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new Select();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.operand =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 2: {
                      message.field = reader.string();
                      break;
                    }
                    case 3: {
                      message.testOnly = reader.bool();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof $root.google.api.expr.v1alpha1.Expr.Select
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Expr.Select();
                if (object.operand != null) {
                  if (typeof object.operand !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Select.operand: object expected, but got ' +
                        typeof object.operand
                    );
                  }
                  message.operand =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.operand
                    );
                }
                if (object.field != null) {
                  message.field = String(object.field);
                }
                if (object.testOnly != null) {
                  message.testOnly = Boolean(object.testOnly);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.operand = null;
                  object.field = '';
                  object.testOnly = false;
                }
                let keys;
                if (
                  message.operand != null &&
                  message.hasOwnProperty('operand')
                ) {
                  object.operand = $root.google.api.expr.v1alpha1.Expr.toObject(
                    message.operand,
                    options
                  );
                }
                if (message.field != null && message.hasOwnProperty('field')) {
                  object.field = message.field;
                }
                if (
                  message.testOnly != null &&
                  message.hasOwnProperty('testOnly')
                ) {
                  object.testOnly = message.testOnly;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.Select';
              }
            }

            Select.prototype.operand = null;
            Select.prototype.field = '';
            Select.prototype.testOnly = false;

            return Select;
          })();

          Expr.Call = (() => {
            class Call {
              constructor(properties) {
                this.args = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new Call(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.target != null &&
                  Object.hasOwnProperty.call(message, 'target')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.target,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.function != null &&
                  Object.hasOwnProperty.call(message, 'function')
                ) {
                  writer.uint32(18).string(message.function);
                }
                if (
                  message.args != null &&
                  Object.hasOwnProperty.call(message, 'args')
                ) {
                  for (const element of message.args) {
                    $root.google.api.expr.v1alpha1.Expr.encode(
                      element,
                      writer.uint32(26).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new Call();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.target =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 2: {
                      message.function = reader.string();
                      break;
                    }
                    case 3: {
                      if (!message.args || !message.args.length) {
                        message.args = [];
                      }
                      message.args.push(
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof $root.google.api.expr.v1alpha1.Expr.Call
                ) {
                  return object;
                }
                const message = new $root.google.api.expr.v1alpha1.Expr.Call();
                if (object.target != null) {
                  if (typeof object.target !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Call.target: object expected, but got ' +
                        typeof object.target
                    );
                  }
                  message.target =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.target
                    );
                }
                if (object.function != null) {
                  message.function = String(object.function);
                }
                if (object.args) {
                  if (!Array.isArray(object.args)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Call.args: array type expected, but got ' +
                        typeof object.args
                    );
                  }
                  message.args = new Array(object.args.length);
                  for (let i = 0; i < object.args.length; ++i) {
                    if (typeof object.args[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Expr.Call.args: object expected, but got ' +
                          typeof object.args[i]
                      );
                    }
                    message.args[i] =
                      $root.google.api.expr.v1alpha1.Expr.fromObject(
                        object.args[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.args = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.target = null;
                  object.function = '';
                }
                let keys;
                if (
                  message.target != null &&
                  message.hasOwnProperty('target')
                ) {
                  object.target = $root.google.api.expr.v1alpha1.Expr.toObject(
                    message.target,
                    options
                  );
                }
                if (
                  message.function != null &&
                  message.hasOwnProperty('function')
                ) {
                  object.function = message.function;
                }
                if (message.args && message.args.length) {
                  object.args = new Array(message.args.length);
                  for (let i = 0; i < message.args.length; ++i) {
                    object.args[i] =
                      $root.google.api.expr.v1alpha1.Expr.toObject(
                        message.args[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.Call';
              }
            }

            Call.prototype.target = null;
            Call.prototype.function = '';
            Call.prototype.args = $util.emptyArray;

            return Call;
          })();

          Expr.CreateList = (() => {
            class CreateList {
              constructor(properties) {
                this.elements = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new CreateList(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.elements != null &&
                  Object.hasOwnProperty.call(message, 'elements')
                ) {
                  for (const element of message.elements) {
                    $root.google.api.expr.v1alpha1.Expr.encode(
                      element,
                      writer.uint32(10).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new CreateList();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      if (!message.elements || !message.elements.length) {
                        message.elements = [];
                      }
                      message.elements.push(
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Expr.CreateList
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Expr.CreateList();
                if (object.elements) {
                  if (!Array.isArray(object.elements)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.CreateList.elements: array type expected, but got ' +
                        typeof object.elements
                    );
                  }
                  message.elements = new Array(object.elements.length);
                  for (let i = 0; i < object.elements.length; ++i) {
                    if (typeof object.elements[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Expr.CreateList.elements: object expected, but got ' +
                          typeof object.elements[i]
                      );
                    }
                    message.elements[i] =
                      $root.google.api.expr.v1alpha1.Expr.fromObject(
                        object.elements[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.elements = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                }
                let keys;
                if (message.elements && message.elements.length) {
                  object.elements = new Array(message.elements.length);
                  for (let i = 0; i < message.elements.length; ++i) {
                    object.elements[i] =
                      $root.google.api.expr.v1alpha1.Expr.toObject(
                        message.elements[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.CreateList'
                );
              }
            }

            CreateList.prototype.elements = $util.emptyArray;

            return CreateList;
          })();

          Expr.CreateStruct = (() => {
            class CreateStruct {
              constructor(properties) {
                this.entries = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new CreateStruct(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.messageName != null &&
                  Object.hasOwnProperty.call(message, 'messageName')
                ) {
                  writer.uint32(10).string(message.messageName);
                }
                if (
                  message.entries != null &&
                  Object.hasOwnProperty.call(message, 'entries')
                ) {
                  for (const element of message.entries) {
                    $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.encode(
                      element,
                      writer.uint32(18).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new CreateStruct();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.messageName = reader.string();
                      break;
                    }
                    case 2: {
                      if (!message.entries || !message.entries.length) {
                        message.entries = [];
                      }
                      message.entries.push(
                        $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Expr.CreateStruct
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Expr.CreateStruct();
                if (object.messageName != null) {
                  message.messageName = String(object.messageName);
                }
                if (object.entries) {
                  if (!Array.isArray(object.entries)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.CreateStruct.entries: array type expected, but got ' +
                        typeof object.entries
                    );
                  }
                  message.entries = new Array(object.entries.length);
                  for (let i = 0; i < object.entries.length; ++i) {
                    if (typeof object.entries[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Expr.CreateStruct.entries: object expected, but got ' +
                          typeof object.entries[i]
                      );
                    }
                    message.entries[i] =
                      $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.fromObject(
                        object.entries[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.entries = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.messageName = '';
                }
                let keys;
                if (
                  message.messageName != null &&
                  message.hasOwnProperty('messageName')
                ) {
                  object.messageName = message.messageName;
                }
                if (message.entries && message.entries.length) {
                  object.entries = new Array(message.entries.length);
                  for (let i = 0; i < message.entries.length; ++i) {
                    object.entries[i] =
                      $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.toObject(
                        message.entries[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.CreateStruct'
                );
              }
            }

            CreateStruct.prototype.messageName = '';
            CreateStruct.prototype.entries = $util.emptyArray;
            CreateStruct.Entry = (() => {
              class Entry {
                get keyKind() {
                  for (const key of ['fieldKey', 'mapKey']) {
                    if (this[key] !== null && this[key] !== undefined)
                      return key;
                  }
                }

                set keyKind(name) {
                  for (const key of ['fieldKey', 'mapKey']) {
                    if (key !== name) delete this[key];
                  }
                }

                constructor(properties) {
                  if (properties) {
                    for (let key of Object.keys(properties)) {
                      if (properties[key] != null) this[key] = properties[key];
                    }
                  }
                }

                static create(properties) {
                  return new Entry(properties);
                }

                static encode(message, writer) {
                  if (!writer) writer = $Writer.create();
                  if (
                    message.id != null &&
                    Object.hasOwnProperty.call(message, 'id')
                  ) {
                    writer.uint32(8).int64(message.id);
                  }
                  if (
                    message.fieldKey != null &&
                    Object.hasOwnProperty.call(message, 'fieldKey')
                  ) {
                    writer.uint32(18).string(message.fieldKey);
                  }
                  if (
                    message.mapKey != null &&
                    Object.hasOwnProperty.call(message, 'mapKey')
                  ) {
                    $root.google.api.expr.v1alpha1.Expr.encode(
                      message.mapKey,
                      writer.uint32(26).fork()
                    ).ldelim();
                  }
                  if (
                    message.value != null &&
                    Object.hasOwnProperty.call(message, 'value')
                  ) {
                    $root.google.api.expr.v1alpha1.Expr.encode(
                      message.value,
                      writer.uint32(34).fork()
                    ).ldelim();
                  }
                  if (
                    message.optionalEntry != null &&
                    Object.hasOwnProperty.call(message, 'optionalEntry')
                  ) {
                    writer.uint32(40).bool(message.optionalEntry);
                  }
                  return writer;
                }

                static decode(reader, length) {
                  if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                  let end =
                    length === undefined ? reader.len : reader.pos + length;
                  let message = new Entry();
                  let key, value;
                  while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                      case 1: {
                        message.id = reader.int64();
                        break;
                      }
                      case 2: {
                        message.fieldKey = reader.string();
                        break;
                      }
                      case 3: {
                        message.mapKey =
                          $root.google.api.expr.v1alpha1.Expr.decode(
                            reader,
                            reader.uint32()
                          );
                        break;
                      }
                      case 4: {
                        message.value =
                          $root.google.api.expr.v1alpha1.Expr.decode(
                            reader,
                            reader.uint32()
                          );
                        break;
                      }
                      case 5: {
                        message.optionalEntry = reader.bool();
                        break;
                      }
                      default: {
                        reader.skipType(tag & 7);
                        break;
                      }
                    }
                  }
                  return message;
                }

                static fromObject(object) {
                  if (
                    object instanceof
                    $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry
                  ) {
                    return object;
                  }
                  const message =
                    new $root.google.api.expr.v1alpha1.Expr.CreateStruct.Entry();
                  if (object.id != null) {
                    if ($util.Long) {
                      (message.id = $util.Long.fromValue(
                        object.id
                      )).unsigned = false;
                    } else if (typeof object.id === 'string') {
                      message.id = parseInt(object.id, 10);
                    } else if (typeof object.id === 'number') {
                      message.id = object.id;
                    } else if (typeof object.id === 'object') {
                      message.id = new $util.LongBits(
                        object.id.low >>> 0,
                        object.id.high >>> 0
                      ).toNumber();
                    }
                  }
                  if (object.fieldKey != null) {
                    message.fieldKey = String(object.fieldKey);
                  }
                  if (object.mapKey != null) {
                    if (typeof object.mapKey !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.mapKey: object expected, but got ' +
                          typeof object.mapKey
                      );
                    }
                    message.mapKey =
                      $root.google.api.expr.v1alpha1.Expr.fromObject(
                        object.mapKey
                      );
                  }
                  if (object.value != null) {
                    if (typeof object.value !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Expr.CreateStruct.Entry.value: object expected, but got ' +
                          typeof object.value
                      );
                    }
                    message.value =
                      $root.google.api.expr.v1alpha1.Expr.fromObject(
                        object.value
                      );
                  }
                  if (object.optionalEntry != null) {
                    message.optionalEntry = Boolean(object.optionalEntry);
                  }
                  return message;
                }

                static toObject(message, options = {}) {
                  const object = {};
                  if (options.arrays || options.defaults) {
                  }
                  if (options.objects || options.defaults) {
                  }
                  if (options.defaults) {
                    if ($util.Long) {
                      let long = new $util.Long(0, 0, false);
                      object.id =
                        options.longs === String
                          ? long.toString()
                          : options.longs === Number
                          ? long.toNumber()
                          : long;
                    } else {
                      object.id = options.longs === String ? '0' : 0;
                    }
                    object.value = null;
                    object.optionalEntry = false;
                  }
                  let keys;
                  if (message.id != null && message.hasOwnProperty('id')) {
                    object.id =
                      typeof message.id === 'number'
                        ? options.longs === String
                          ? String(message.id)
                          : message.id
                        : options.longs === String
                        ? $util.Long.prototype.toString.call(message.id)
                        : options.longs === Number
                        ? new $util.LongBits(
                            message.id.low >>> 0,
                            message.id.high >>> 0
                          ).toNumber()
                        : message.id;
                  }
                  if (
                    message.fieldKey != null &&
                    message.hasOwnProperty('fieldKey')
                  ) {
                    object.fieldKey = message.fieldKey;
                    if (options.oneofs) {
                      object.keyKind = 'fieldKey';
                    }
                  }
                  if (
                    message.mapKey != null &&
                    message.hasOwnProperty('mapKey')
                  ) {
                    object.mapKey =
                      $root.google.api.expr.v1alpha1.Expr.toObject(
                        message.mapKey,
                        options
                      );
                    if (options.oneofs) {
                      object.keyKind = 'mapKey';
                    }
                  }
                  if (
                    message.value != null &&
                    message.hasOwnProperty('value')
                  ) {
                    object.value = $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.value,
                      options
                    );
                  }
                  if (
                    message.optionalEntry != null &&
                    message.hasOwnProperty('optionalEntry')
                  ) {
                    object.optionalEntry = message.optionalEntry;
                  }
                  return object;
                }

                static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                  return (
                    typeUrlPrefix +
                    'google.api.expr.v1alpha1.Expr.CreateStruct.Entry'
                  );
                }
              }

              Entry.prototype.id = $util.Long
                ? $util.Long.fromBits(0, 0, false)
                : 0;
              Entry.prototype.fieldKey = '';
              Entry.prototype.mapKey = null;
              Entry.prototype.value = null;
              Entry.prototype.optionalEntry = false;

              return Entry;
            })();

            return CreateStruct;
          })();

          Expr.Comprehension = (() => {
            class Comprehension {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new Comprehension(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.iterVar != null &&
                  Object.hasOwnProperty.call(message, 'iterVar')
                ) {
                  writer.uint32(10).string(message.iterVar);
                }
                if (
                  message.iterRange != null &&
                  Object.hasOwnProperty.call(message, 'iterRange')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.iterRange,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                if (
                  message.accuVar != null &&
                  Object.hasOwnProperty.call(message, 'accuVar')
                ) {
                  writer.uint32(26).string(message.accuVar);
                }
                if (
                  message.accuInit != null &&
                  Object.hasOwnProperty.call(message, 'accuInit')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.accuInit,
                    writer.uint32(34).fork()
                  ).ldelim();
                }
                if (
                  message.loopCondition != null &&
                  Object.hasOwnProperty.call(message, 'loopCondition')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.loopCondition,
                    writer.uint32(42).fork()
                  ).ldelim();
                }
                if (
                  message.loopStep != null &&
                  Object.hasOwnProperty.call(message, 'loopStep')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.loopStep,
                    writer.uint32(50).fork()
                  ).ldelim();
                }
                if (
                  message.result != null &&
                  Object.hasOwnProperty.call(message, 'result')
                ) {
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.result,
                    writer.uint32(58).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new Comprehension();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.iterVar = reader.string();
                      break;
                    }
                    case 2: {
                      message.iterRange =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 3: {
                      message.accuVar = reader.string();
                      break;
                    }
                    case 4: {
                      message.accuInit =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 5: {
                      message.loopCondition =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 6: {
                      message.loopStep =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 7: {
                      message.result =
                        $root.google.api.expr.v1alpha1.Expr.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Expr.Comprehension
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Expr.Comprehension();
                if (object.iterVar != null) {
                  message.iterVar = String(object.iterVar);
                }
                if (object.iterRange != null) {
                  if (typeof object.iterRange !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Comprehension.iterRange: object expected, but got ' +
                        typeof object.iterRange
                    );
                  }
                  message.iterRange =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.iterRange
                    );
                }
                if (object.accuVar != null) {
                  message.accuVar = String(object.accuVar);
                }
                if (object.accuInit != null) {
                  if (typeof object.accuInit !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Comprehension.accuInit: object expected, but got ' +
                        typeof object.accuInit
                    );
                  }
                  message.accuInit =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.accuInit
                    );
                }
                if (object.loopCondition != null) {
                  if (typeof object.loopCondition !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Comprehension.loopCondition: object expected, but got ' +
                        typeof object.loopCondition
                    );
                  }
                  message.loopCondition =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.loopCondition
                    );
                }
                if (object.loopStep != null) {
                  if (typeof object.loopStep !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Comprehension.loopStep: object expected, but got ' +
                        typeof object.loopStep
                    );
                  }
                  message.loopStep =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.loopStep
                    );
                }
                if (object.result != null) {
                  if (typeof object.result !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Expr.Comprehension.result: object expected, but got ' +
                        typeof object.result
                    );
                  }
                  message.result =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.result
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.iterVar = '';
                  object.iterRange = null;
                  object.accuVar = '';
                  object.accuInit = null;
                  object.loopCondition = null;
                  object.loopStep = null;
                  object.result = null;
                }
                let keys;
                if (
                  message.iterVar != null &&
                  message.hasOwnProperty('iterVar')
                ) {
                  object.iterVar = message.iterVar;
                }
                if (
                  message.iterRange != null &&
                  message.hasOwnProperty('iterRange')
                ) {
                  object.iterRange =
                    $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.iterRange,
                      options
                    );
                }
                if (
                  message.accuVar != null &&
                  message.hasOwnProperty('accuVar')
                ) {
                  object.accuVar = message.accuVar;
                }
                if (
                  message.accuInit != null &&
                  message.hasOwnProperty('accuInit')
                ) {
                  object.accuInit =
                    $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.accuInit,
                      options
                    );
                }
                if (
                  message.loopCondition != null &&
                  message.hasOwnProperty('loopCondition')
                ) {
                  object.loopCondition =
                    $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.loopCondition,
                      options
                    );
                }
                if (
                  message.loopStep != null &&
                  message.hasOwnProperty('loopStep')
                ) {
                  object.loopStep =
                    $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.loopStep,
                      options
                    );
                }
                if (
                  message.result != null &&
                  message.hasOwnProperty('result')
                ) {
                  object.result = $root.google.api.expr.v1alpha1.Expr.toObject(
                    message.result,
                    options
                  );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Expr.Comprehension'
                );
              }
            }

            Comprehension.prototype.iterVar = '';
            Comprehension.prototype.iterRange = null;
            Comprehension.prototype.accuVar = '';
            Comprehension.prototype.accuInit = null;
            Comprehension.prototype.loopCondition = null;
            Comprehension.prototype.loopStep = null;
            Comprehension.prototype.result = null;

            return Comprehension;
          })();

          return Expr;
        })();

        v1alpha1.Constant = (() => {
          class Constant {
            get constantKind() {
              for (const key of [
                'nullValue',
                'boolValue',
                'int64Value',
                'uint64Value',
                'doubleValue',
                'stringValue',
                'bytesValue',
                'durationValue',
                'timestampValue',
              ]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set constantKind(name) {
              for (const key of [
                'nullValue',
                'boolValue',
                'int64Value',
                'uint64Value',
                'doubleValue',
                'stringValue',
                'bytesValue',
                'durationValue',
                'timestampValue',
              ]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Constant(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.nullValue != null &&
                Object.hasOwnProperty.call(message, 'nullValue')
              ) {
                writer.uint32(8).int32(message.nullValue);
              }
              if (
                message.boolValue != null &&
                Object.hasOwnProperty.call(message, 'boolValue')
              ) {
                writer.uint32(16).bool(message.boolValue);
              }
              if (
                message.int64Value != null &&
                Object.hasOwnProperty.call(message, 'int64Value')
              ) {
                writer.uint32(24).int64(message.int64Value);
              }
              if (
                message.uint64Value != null &&
                Object.hasOwnProperty.call(message, 'uint64Value')
              ) {
                writer.uint32(32).uint64(message.uint64Value);
              }
              if (
                message.doubleValue != null &&
                Object.hasOwnProperty.call(message, 'doubleValue')
              ) {
                writer.uint32(41).double(message.doubleValue);
              }
              if (
                message.stringValue != null &&
                Object.hasOwnProperty.call(message, 'stringValue')
              ) {
                writer.uint32(50).string(message.stringValue);
              }
              if (
                message.bytesValue != null &&
                Object.hasOwnProperty.call(message, 'bytesValue')
              ) {
                writer.uint32(58).bytes(message.bytesValue);
              }
              if (
                message.durationValue != null &&
                Object.hasOwnProperty.call(message, 'durationValue')
              ) {
                google$2.protobuf.Duration.encode(
                  message.durationValue,
                  writer.uint32(66).fork()
                ).ldelim();
              }
              if (
                message.timestampValue != null &&
                Object.hasOwnProperty.call(message, 'timestampValue')
              ) {
                google$3.protobuf.Timestamp.encode(
                  message.timestampValue,
                  writer.uint32(74).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Constant();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.nullValue = reader.int32();
                    break;
                  }
                  case 2: {
                    message.boolValue = reader.bool();
                    break;
                  }
                  case 3: {
                    message.int64Value = reader.int64();
                    break;
                  }
                  case 4: {
                    message.uint64Value = reader.uint64();
                    break;
                  }
                  case 5: {
                    message.doubleValue = reader.double();
                    break;
                  }
                  case 6: {
                    message.stringValue = reader.string();
                    break;
                  }
                  case 7: {
                    message.bytesValue = reader.bytes();
                    break;
                  }
                  case 8: {
                    message.durationValue = google$2.protobuf.Duration.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 9: {
                    message.timestampValue = google$3.protobuf.Timestamp.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Constant) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Constant();
              if (object.nullValue != null) {
                switch (object.nullValue) {
                  case 'NULL_VALUE':
                  case 0: {
                    message.nullValue = 0;
                    break;
                  }
                  default: {
                    if (typeof object.nullValue == 'number') {
                      message.nullValue = object.nullValue;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.boolValue != null) {
                message.boolValue = Boolean(object.boolValue);
              }
              if (object.int64Value != null) {
                if ($util.Long) {
                  (message.int64Value = $util.Long.fromValue(
                    object.int64Value
                  )).unsigned = false;
                } else if (typeof object.int64Value === 'string') {
                  message.int64Value = parseInt(object.int64Value, 10);
                } else if (typeof object.int64Value === 'number') {
                  message.int64Value = object.int64Value;
                } else if (typeof object.int64Value === 'object') {
                  message.int64Value = new $util.LongBits(
                    object.int64Value.low >>> 0,
                    object.int64Value.high >>> 0
                  ).toNumber();
                }
              }
              if (object.uint64Value != null) {
                if ($util.Long) {
                  (message.uint64Value = $util.Long.fromValue(
                    object.uint64Value
                  )).unsigned = true;
                } else if (typeof object.uint64Value === 'string') {
                  message.uint64Value = parseInt(object.uint64Value, 10);
                } else if (typeof object.uint64Value === 'number') {
                  message.uint64Value = object.uint64Value;
                } else if (typeof object.uint64Value === 'object') {
                  message.uint64Value = new $util.LongBits(
                    object.uint64Value.low >>> 0,
                    object.uint64Value.high >>> 0
                  ).toNumber(true);
                }
              }
              if (object.doubleValue != null) {
                message.doubleValue = Number(object.doubleValue);
              }
              if (object.stringValue != null) {
                message.stringValue = String(object.stringValue);
              }
              if (object.bytesValue != null) {
                if (typeof object.bytesValue === 'string') {
                  $util.base64.decode(
                    object.bytesValue,
                    (message.bytesValue = $util.newBuffer(
                      $util.base64.length(object.bytesValue)
                    )),
                    0
                  );
                } else if (object.bytesValue.length >= 0) {
                  message.bytesValue = object.bytesValue;
                }
              }
              if (object.durationValue != null) {
                if (typeof object.durationValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Constant.durationValue: object expected, but got ' +
                      typeof object.durationValue
                  );
                }
                message.durationValue = google$2.protobuf.Duration.fromObject(
                  object.durationValue
                );
              }
              if (object.timestampValue != null) {
                if (typeof object.timestampValue !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Constant.timestampValue: object expected, but got ' +
                      typeof object.timestampValue
                  );
                }
                message.timestampValue = google$3.protobuf.Timestamp.fromObject(
                  object.timestampValue
                );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (
                message.nullValue != null &&
                message.hasOwnProperty('nullValue')
              ) {
                object.nullValue =
                  options.enums === String
                    ? google$1.protobuf.NullValue[message.nullValue] ===
                      undefined
                      ? message.nullValue
                      : google$1.protobuf.NullValue[message.nullValue]
                    : message.nullValue;
                if (options.oneofs) {
                  object.constantKind = 'nullValue';
                }
              }
              if (
                message.boolValue != null &&
                message.hasOwnProperty('boolValue')
              ) {
                object.boolValue = message.boolValue;
                if (options.oneofs) {
                  object.constantKind = 'boolValue';
                }
              }
              if (
                message.int64Value != null &&
                message.hasOwnProperty('int64Value')
              ) {
                object.int64Value =
                  typeof message.int64Value === 'number'
                    ? options.longs === String
                      ? String(message.int64Value)
                      : message.int64Value
                    : options.longs === String
                    ? $util.Long.prototype.toString.call(message.int64Value)
                    : options.longs === Number
                    ? new $util.LongBits(
                        message.int64Value.low >>> 0,
                        message.int64Value.high >>> 0
                      ).toNumber()
                    : message.int64Value;
                if (options.oneofs) {
                  object.constantKind = 'int64Value';
                }
              }
              if (
                message.uint64Value != null &&
                message.hasOwnProperty('uint64Value')
              ) {
                object.uint64Value =
                  typeof message.uint64Value === 'number'
                    ? options.longs === String
                      ? String(message.uint64Value)
                      : message.uint64Value
                    : options.longs === String
                    ? $util.Long.prototype.toString.call(message.uint64Value)
                    : options.longs === Number
                    ? new $util.LongBits(
                        message.uint64Value.low >>> 0,
                        message.uint64Value.high >>> 0
                      ).toNumber(true)
                    : message.uint64Value;
                if (options.oneofs) {
                  object.constantKind = 'uint64Value';
                }
              }
              if (
                message.doubleValue != null &&
                message.hasOwnProperty('doubleValue')
              ) {
                object.doubleValue =
                  options.json && !isFinite(message.doubleValue)
                    ? String(message.doubleValue)
                    : message.doubleValue;
                if (options.oneofs) {
                  object.constantKind = 'doubleValue';
                }
              }
              if (
                message.stringValue != null &&
                message.hasOwnProperty('stringValue')
              ) {
                object.stringValue = message.stringValue;
                if (options.oneofs) {
                  object.constantKind = 'stringValue';
                }
              }
              if (
                message.bytesValue != null &&
                message.hasOwnProperty('bytesValue')
              ) {
                object.bytesValue =
                  options.bytes === String
                    ? $util.base64.encode(
                        message.bytesValue,
                        0,
                        message.bytesValue.length
                      )
                    : options.bytes === Array
                    ? Array.prototype.slice.call(message.bytesValue)
                    : message.bytesValue;
                if (options.oneofs) {
                  object.constantKind = 'bytesValue';
                }
              }
              if (
                message.durationValue != null &&
                message.hasOwnProperty('durationValue')
              ) {
                object.durationValue = google$2.protobuf.Duration.toObject(
                  message.durationValue,
                  options
                );
                if (options.oneofs) {
                  object.constantKind = 'durationValue';
                }
              }
              if (
                message.timestampValue != null &&
                message.hasOwnProperty('timestampValue')
              ) {
                object.timestampValue = google$3.protobuf.Timestamp.toObject(
                  message.timestampValue,
                  options
                );
                if (options.oneofs) {
                  object.constantKind = 'timestampValue';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Constant';
            }
          }

          Constant.prototype.nullValue = 0;
          Constant.prototype.boolValue = false;
          Constant.prototype.int64Value = $util.Long
            ? $util.Long.fromBits(0, 0, false)
            : 0;
          Constant.prototype.uint64Value = $util.Long
            ? $util.Long.fromBits(0, 0, true)
            : 0;
          Constant.prototype.doubleValue = 0;
          Constant.prototype.stringValue = '';
          Constant.prototype.bytesValue = new Uint8Array();
          Constant.prototype.durationValue = null;
          Constant.prototype.timestampValue = null;

          return Constant;
        })();

        v1alpha1.SourceInfo = (() => {
          class SourceInfo {
            constructor(properties) {
              this.lineOffsets = [];
              this.positions = {};
              this.macroCalls = {};
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new SourceInfo(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.syntaxVersion != null &&
                Object.hasOwnProperty.call(message, 'syntaxVersion')
              ) {
                writer.uint32(10).string(message.syntaxVersion);
              }
              if (
                message.location != null &&
                Object.hasOwnProperty.call(message, 'location')
              ) {
                writer.uint32(18).string(message.location);
              }
              if (
                message.lineOffsets != null &&
                Object.hasOwnProperty.call(message, 'lineOffsets')
              ) {
                writer.uint32(26).fork();
                for (const element of message.lineOffsets) {
                  writer.int32(element);
                }
                writer.ldelim();
              }
              if (
                message.positions != null &&
                Object.hasOwnProperty.call(message, 'positions')
              ) {
                for (const key of Object.keys(message.positions)) {
                  writer
                    .uint32(34)
                    .fork()
                    .uint32(8)
                    .int64(key)
                    .uint32(16)
                    .int32(message.positions[key])
                    .ldelim();
                }
              }
              if (
                message.macroCalls != null &&
                Object.hasOwnProperty.call(message, 'macroCalls')
              ) {
                for (const key of Object.keys(message.macroCalls)) {
                  writer.uint32(42).fork().uint32(8).int64(key);
                  $root.google.api.expr.v1alpha1.Expr.encode(
                    message.macroCalls[key],
                    writer.uint32(18).fork()
                  )
                    .ldelim()
                    .ldelim();
                }
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new SourceInfo();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.syntaxVersion = reader.string();
                    break;
                  }
                  case 2: {
                    message.location = reader.string();
                    break;
                  }
                  case 3: {
                    if (!message.lineOffsets || !message.lineOffsets.length) {
                      message.lineOffsets = [];
                    }
                    if ((tag & 7) === 2) {
                      let end2 = reader.uint32() + reader.pos;
                      while (reader.pos < end2)
                        message.lineOffsets.push(reader.int32());
                    } else {
                      message.lineOffsets.push(reader.int32());
                    }
                    break;
                  }
                  case 4: {
                    if (message.positions === $util.emptyObject)
                      message.positions = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                    value = 0;
                    while (reader.pos < end2) {
                      let tag2 = reader.uint32();
                      switch (tag2 >>> 3) {
                        case 1: {
                          key = reader.int64();
                          break;
                        }
                        case 2: {
                          value = reader.int32();
                          break;
                        }
                      }
                      message.positions[key] = value;
                    }
                    break;
                  }
                  case 5: {
                    if (message.macroCalls === $util.emptyObject)
                      message.macroCalls = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                    value = null;
                    while (reader.pos < end2) {
                      let tag2 = reader.uint32();
                      switch (tag2 >>> 3) {
                        case 1: {
                          key = reader.int64();
                          break;
                        }
                        case 2: {
                          value = $root.google.api.expr.v1alpha1.Expr.decode(
                            reader,
                            reader.uint32()
                          );
                          break;
                        }
                      }
                      message.macroCalls[key] = value;
                    }
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.SourceInfo) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.SourceInfo();
              if (object.syntaxVersion != null) {
                message.syntaxVersion = String(object.syntaxVersion);
              }
              if (object.location != null) {
                message.location = String(object.location);
              }
              if (object.lineOffsets) {
                if (!Array.isArray(object.lineOffsets)) {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.SourceInfo.lineOffsets: array type expected, but got ' +
                      typeof object.lineOffsets
                  );
                }
                message.lineOffsets = new Array(object.lineOffsets.length);
                for (let i = 0; i < object.lineOffsets.length; ++i) {
                  message.lineOffsets[i] = object.lineOffsets[i] | 0;
                }
              }
              if (object.positions) {
                if (typeof object.positions !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.SourceInfo.positions: object expected, but got ' +
                      typeof object.positions
                  );
                }
                message.positions = {};
                for (
                  let keys = Object.keys(object.positions), i = 0;
                  i < keys.length;
                  ++i
                ) {
                  message.positions[keys[i]] = object.positions[keys[i]] | 0;
                }
              }
              if (object.macroCalls) {
                if (typeof object.macroCalls !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.SourceInfo.macroCalls: object expected, but got ' +
                      typeof object.macroCalls
                  );
                }
                message.macroCalls = {};
                for (
                  let keys = Object.keys(object.macroCalls), i = 0;
                  i < keys.length;
                  ++i
                ) {
                  if (typeof object.macroCalls[keys[i]] !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.SourceInfo.value: object expected, but got ' +
                        typeof object.macroCalls[keys[i]]
                    );
                  }
                  message.macroCalls[keys[i]] =
                    $root.google.api.expr.v1alpha1.Expr.fromObject(
                      object.macroCalls[keys[i]]
                    );
                }
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
                object.lineOffsets = [];
              }
              if (options.objects || options.defaults) {
                object.positions = {};
                object.macroCalls = {};
              }
              if (options.defaults) {
                object.syntaxVersion = '';
                object.location = '';
              }
              let keys;
              if (
                message.syntaxVersion != null &&
                message.hasOwnProperty('syntaxVersion')
              ) {
                object.syntaxVersion = message.syntaxVersion;
              }
              if (
                message.location != null &&
                message.hasOwnProperty('location')
              ) {
                object.location = message.location;
              }
              if (message.lineOffsets && message.lineOffsets.length) {
                object.lineOffsets = new Array(message.lineOffsets.length);
                for (let i = 0; i < message.lineOffsets.length; ++i) {
                  object.lineOffsets[i] = message.lineOffsets[i];
                }
              }
              if (
                message.positions &&
                (keys = Object.keys(message.positions)).length
              ) {
                object.positions = {};
                for (let i = 0; i < keys.length; ++i) {
                  object.positions[keys[i]] = message.positions[keys[i]];
                }
              }
              if (
                message.macroCalls &&
                (keys = Object.keys(message.macroCalls)).length
              ) {
                object.macroCalls = {};
                for (let i = 0; i < keys.length; ++i) {
                  object.macroCalls[keys[i]] =
                    $root.google.api.expr.v1alpha1.Expr.toObject(
                      message.macroCalls[keys[i]],
                      options
                    );
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.SourceInfo';
            }
          }

          SourceInfo.prototype.syntaxVersion = '';
          SourceInfo.prototype.location = '';
          SourceInfo.prototype.lineOffsets = $util.emptyArray;
          SourceInfo.prototype.positions = $util.emptyObject;
          SourceInfo.prototype.macroCalls = $util.emptyObject;

          return SourceInfo;
        })();

        v1alpha1.SourcePosition = (() => {
          class SourcePosition {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new SourcePosition(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.location != null &&
                Object.hasOwnProperty.call(message, 'location')
              ) {
                writer.uint32(10).string(message.location);
              }
              if (
                message.offset != null &&
                Object.hasOwnProperty.call(message, 'offset')
              ) {
                writer.uint32(16).int32(message.offset);
              }
              if (
                message.line != null &&
                Object.hasOwnProperty.call(message, 'line')
              ) {
                writer.uint32(24).int32(message.line);
              }
              if (
                message.column != null &&
                Object.hasOwnProperty.call(message, 'column')
              ) {
                writer.uint32(32).int32(message.column);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new SourcePosition();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.location = reader.string();
                    break;
                  }
                  case 2: {
                    message.offset = reader.int32();
                    break;
                  }
                  case 3: {
                    message.line = reader.int32();
                    break;
                  }
                  case 4: {
                    message.column = reader.int32();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (
                object instanceof $root.google.api.expr.v1alpha1.SourcePosition
              ) {
                return object;
              }
              const message =
                new $root.google.api.expr.v1alpha1.SourcePosition();
              if (object.location != null) {
                message.location = String(object.location);
              }
              if (object.offset != null) {
                message.offset = object.offset | 0;
              }
              if (object.line != null) {
                message.line = object.line | 0;
              }
              if (object.column != null) {
                message.column = object.column | 0;
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.location = '';
                object.offset = 0;
                object.line = 0;
                object.column = 0;
              }
              let keys;
              if (
                message.location != null &&
                message.hasOwnProperty('location')
              ) {
                object.location = message.location;
              }
              if (message.offset != null && message.hasOwnProperty('offset')) {
                object.offset = message.offset;
              }
              if (message.line != null && message.hasOwnProperty('line')) {
                object.line = message.line;
              }
              if (message.column != null && message.hasOwnProperty('column')) {
                object.column = message.column;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.SourcePosition';
            }
          }

          SourcePosition.prototype.location = '';
          SourcePosition.prototype.offset = 0;
          SourcePosition.prototype.line = 0;
          SourcePosition.prototype.column = 0;

          return SourcePosition;
        })();

        return v1alpha1;
      })();

      return expr;
    })();

    return api;
  })();

  return google;
})();

export const google = $root.google;
