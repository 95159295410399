import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { RefreshEventPayloadProps } from './types';
import { Text } from '../../../../../Typography';
import { Toggle } from '../../../../../Toggle';
import { useTheme } from '@morf/theming';

const RefreshEventPayload: FC<RefreshEventPayloadProps> = ({
  isReadOnly,
  refreshEventPayload,
  onRefreshEventPayloadChange,
}) => {
  const theme = useTheme();
  return (
    <Flexbox data-testid='refresh-event-payload' direction='row' height='auto'>
      <Flexbox
        alignItems='flex-start'
        direction='column'
        justifyContent='flex-start'
        gap={0}
      >
        <Text tag='p2'>Refresh event payload</Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Select what should happen.
        </Text>
      </Flexbox>

      <Toggle
        key='refresh-event-payload'
        defaultOption={refreshEventPayload ? 'on' : 'off'}
        onChange={(value) => onRefreshEventPayloadChange(value === 'on')}
        options={['off', 'on']}
        readOnly={isReadOnly}
      />
    </Flexbox>
  );
};

export const MemoizedRefreshEventPayload = memo(RefreshEventPayload);
