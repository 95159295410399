import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { ObjectTypeIdsProps } from './types';
import { SkeletonLoadable } from '../../../../../../Skeleton';
import { ObjectTypeId } from './ObjectTypeId';

const ObjectTypeIds: FC<ObjectTypeIdsProps> = ({
  isLoading,
  objectTypeIds,
  onClick,
}) => {
  if (isLoading) {
    return (
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.5}
        p={0.5}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <SkeletonLoadable key={index} isLoading height='1.5rem' width='22rem'>
            <></>
          </SkeletonLoadable>
        ))}
      </Flexbox>
    );
  }

  return (
    <Flexbox
      data-testid='object-type-ids'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='scroll'
      gap={0}
    >
      {objectTypeIds.map((objectTypeId, index) => (
        <ObjectTypeId
          key={index}
          objectTypeId={objectTypeId}
          onClick={onClick}
        />
      ))}
    </Flexbox>
  );
};

export const MemoizedObjectTypeIds = memo(ObjectTypeIds);
