import { FC, useState } from 'react';
import { AppointmentDetailProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Button } from '../Button';
import { AppointmentHeader } from '../AppointmentHeader/AppointmentHeader';
import { AppointmentCancellationModal } from '../AppointmentCancellationModal';
import { Divider } from '../Divider';
import { AppointmentFooter } from './AppointmentFooter';
import { AppointmentRescheduleModal } from '../AppointmentRescheduleModal';

export const AppointmentDetail: FC<AppointmentDetailProps> = ({
  appointmentName,
  providerName,
  appointmentDate,
  appointmentTime,
  appointmentTimezone,
  onCancel,
  onReschedule,
  withinTimeWindow,
}) => {
  const theme = useTheme();
  const [cancellationModal, setCancellationModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);

  return (
    <>
      <Flexbox p={1.25} direction='column' gap={1} maxWidth='47.5rem'>
        <AppointmentHeader
          appointmentName={appointmentName}
          appointmentDate={appointmentDate}
          providerName={providerName}
          appointmentTime={appointmentTime}
          appointmentTimezone={appointmentTimezone}
        />
        <Flexbox gap={0.5} justifyContent='flex-start'>
          <Button
            variant='custom'
            color={theme.colors.support.red.darkest}
            backgroundColor={theme.colors.ui.card}
            borderColor={theme.colors.ui.divider}
            onClick={() => setCancellationModal(true)}
          >
            <Text tag='h5' color='inherit'>
              Cancel
            </Text>
          </Button>
          <Button
            variant='custom'
            color={theme.colors.main.primary.darker}
            backgroundColor={theme.colors.ui.card}
            borderColor={theme.colors.ui.divider}
            onClick={
              withinTimeWindow ? () => setRescheduleModal(true) : onReschedule
            }
          >
            <Text tag='h5' color='inherit'>
              Reschedule
            </Text>
          </Button>
        </Flexbox>
        <Divider />
        <AppointmentFooter />
      </Flexbox>
      <AppointmentCancellationModal
        isOpen={cancellationModal}
        onClose={() => setCancellationModal(false)}
        onCancel={onCancel}
        withinTimeWindow={withinTimeWindow}
      />
      <AppointmentRescheduleModal
        isOpen={rescheduleModal}
        onClose={() => setRescheduleModal(false)}
        onReschedule={onReschedule}
      />
    </>
  );
};
