import { css, styled } from '@morf/theming';
import { Container } from '../Container';

export const StyledModal = styled(Container)`
  position: fixed;
  z-index: var(--modal-z-index);
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};

  ${(props) =>
    props.top === '50%' &&
    props.left === '50%' &&
    css`
      transform: translate(-50%, -50%);
    `}
`;
