'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { active_campaign as active_campaign$1 } from './active_campaign_v1_ts_proto';
import { acuity as acuity$1 } from './acuity_v1_ts_proto';
import { axle_health as axle_health$1 } from './axle_health_v1_ts_proto';
import { butterfly_labs as butterfly_labs$1 } from './butterfly_labs_v1_ts_proto';
import { customerio as customerio$1 } from './customerio_v1_ts_proto';
import { formsort as formsort$1 } from './formsort_v1_ts_proto';
import { healthie as healthie$1 } from './healthie_v1_ts_proto';
import { hubspot as hubspot$1 } from './hubspot_v1_ts_proto';
import { intakeq as intakeq$1 } from './intakeq_v1_ts_proto';
import { morf_appointment as morf_appointment$1 } from './morf_appointment_v1_ts_proto';
import { push_json as push_json$1 } from './push_json_v1_ts_proto';
import { segment as segment$1 } from './segment_v1_ts_proto';
import { source_application as source_application$1 } from './source_application_v2_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { vital as vital$1 } from './vital_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.event_payload = (() => {
  const event_payload = {};
  event_payload.v2 = (() => {
    const v2 = {};
    v2.EventSnapshot = (() => {
      class EventSnapshot {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventSnapshot(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(18).string(message.sourceId);
          }
          if (
            message.webhookId != null &&
            Object.hasOwnProperty.call(message, 'webhookId')
          ) {
            writer.uint32(26).string(message.webhookId);
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            $root.event_payload.v2.EventPayload.encode(
              message.eventPayload,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.eventTime != null &&
            Object.hasOwnProperty.call(message, 'eventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventTime,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventSnapshot();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.sourceId = reader.string();
                break;
              }
              case 3: {
                message.webhookId = reader.string();
                break;
              }
              case 4: {
                message.eventPayload =
                  $root.event_payload.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.eventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.EventSnapshot) {
            return object;
          }
          const message = new $root.event_payload.v2.EventSnapshot();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.webhookId != null) {
            message.webhookId = String(object.webhookId);
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventSnapshot.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload =
              $root.event_payload.v2.EventPayload.fromObject(
                object.eventPayload
              );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventSnapshot.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.eventTime != null) {
            if (typeof object.eventTime !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventSnapshot.eventTime: object expected, but got ' +
                  typeof object.eventTime
              );
            }
            message.eventTime = timing$1.v1.Timestamp.fromObject(
              object.eventTime
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.sourceId = '';
            object.eventPayload = null;
            object.createdAt = null;
            object.eventTime = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.webhookId != null &&
            message.hasOwnProperty('webhookId')
          ) {
            object.webhookId = message.webhookId;
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = $root.event_payload.v2.EventPayload.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.eventTime != null &&
            message.hasOwnProperty('eventTime')
          ) {
            object.eventTime = timing$1.v1.Timestamp.toObject(
              message.eventTime,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.EventSnapshot';
        }
      }

      EventSnapshot.prototype.id = '';
      EventSnapshot.prototype.sourceId = '';
      EventSnapshot.prototype.webhookId = null;
      EventSnapshot.prototype.eventPayload = null;
      EventSnapshot.prototype.createdAt = null;
      EventSnapshot.prototype.eventTime = null;

      return EventSnapshot;
    })();

    v2.EventSnapshotSummary = (() => {
      class EventSnapshotSummary {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventSnapshotSummary(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(18).string(message.eventType);
          }
          if (
            message.eventTime != null &&
            Object.hasOwnProperty.call(message, 'eventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventTime,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.createTime != null &&
            Object.hasOwnProperty.call(message, 'createTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createTime,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(40).int32(message.sourceApplication);
          }
          if (
            message.hasWorkflowExecutions != null &&
            Object.hasOwnProperty.call(message, 'hasWorkflowExecutions')
          ) {
            writer.uint32(48).bool(message.hasWorkflowExecutions);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventSnapshotSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.eventType = reader.string();
                break;
              }
              case 3: {
                message.eventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.createTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 6: {
                message.hasWorkflowExecutions = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.EventSnapshotSummary) {
            return object;
          }
          const message = new $root.event_payload.v2.EventSnapshotSummary();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.eventType != null) {
            message.eventType = String(object.eventType);
          }
          if (object.eventTime != null) {
            if (typeof object.eventTime !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventSnapshotSummary.eventTime: object expected, but got ' +
                  typeof object.eventTime
              );
            }
            message.eventTime = timing$1.v1.Timestamp.fromObject(
              object.eventTime
            );
          }
          if (object.createTime != null) {
            if (typeof object.createTime !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventSnapshotSummary.createTime: object expected, but got ' +
                  typeof object.createTime
              );
            }
            message.createTime = timing$1.v1.Timestamp.fromObject(
              object.createTime
            );
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.hasWorkflowExecutions != null) {
            message.hasWorkflowExecutions = Boolean(
              object.hasWorkflowExecutions
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.eventType = '';
            object.eventTime = null;
            object.createTime = null;
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.hasWorkflowExecutions = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType = message.eventType;
          }
          if (
            message.eventTime != null &&
            message.hasOwnProperty('eventTime')
          ) {
            object.eventTime = timing$1.v1.Timestamp.toObject(
              message.eventTime,
              options
            );
          }
          if (
            message.createTime != null &&
            message.hasOwnProperty('createTime')
          ) {
            object.createTime = timing$1.v1.Timestamp.toObject(
              message.createTime,
              options
            );
          }
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (
            message.hasWorkflowExecutions != null &&
            message.hasOwnProperty('hasWorkflowExecutions')
          ) {
            object.hasWorkflowExecutions = message.hasWorkflowExecutions;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.EventSnapshotSummary';
        }
      }

      EventSnapshotSummary.prototype.id = '';
      EventSnapshotSummary.prototype.eventType = '';
      EventSnapshotSummary.prototype.eventTime = null;
      EventSnapshotSummary.prototype.createTime = null;
      EventSnapshotSummary.prototype.sourceApplication = 0;
      EventSnapshotSummary.prototype.hasWorkflowExecutions = false;

      return EventSnapshotSummary;
    })();

    v2.CreateEventSnapshotRequest = (() => {
      class CreateEventSnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateEventSnapshotRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.orgId != null &&
            Object.hasOwnProperty.call(message, 'orgId')
          ) {
            writer.uint32(10).string(message.orgId);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(18).string(message.sourceId);
          }
          if (
            message.webhookId != null &&
            Object.hasOwnProperty.call(message, 'webhookId')
          ) {
            writer.uint32(26).string(message.webhookId);
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            $root.event_payload.v2.EventPayload.encode(
              message.eventPayload,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateEventSnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orgId = reader.string();
                break;
              }
              case 2: {
                message.sourceId = reader.string();
                break;
              }
              case 3: {
                message.webhookId = reader.string();
                break;
              }
              case 4: {
                message.eventPayload =
                  $root.event_payload.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_payload.v2.CreateEventSnapshotRequest
          ) {
            return object;
          }
          const message =
            new $root.event_payload.v2.CreateEventSnapshotRequest();
          if (object.orgId != null) {
            message.orgId = String(object.orgId);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.webhookId != null) {
            message.webhookId = String(object.webhookId);
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.CreateEventSnapshotRequest.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload =
              $root.event_payload.v2.EventPayload.fromObject(
                object.eventPayload
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orgId = '';
            object.sourceId = '';
            object.eventPayload = null;
          }
          let keys;
          if (message.orgId != null && message.hasOwnProperty('orgId')) {
            object.orgId = message.orgId;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.webhookId != null &&
            message.hasOwnProperty('webhookId')
          ) {
            object.webhookId = message.webhookId;
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = $root.event_payload.v2.EventPayload.toObject(
              message.eventPayload,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.CreateEventSnapshotRequest';
        }
      }

      CreateEventSnapshotRequest.prototype.orgId = '';
      CreateEventSnapshotRequest.prototype.sourceId = '';
      CreateEventSnapshotRequest.prototype.webhookId = null;
      CreateEventSnapshotRequest.prototype.eventPayload = null;

      return CreateEventSnapshotRequest;
    })();

    v2.EventTypes = (() => {
      class EventTypes {
        get type() {
          for (const key of [
            'formsortEventTypes',
            'healthieEventTypes',
            'axleHealthEventTypes',
            'butterflyLabsEventTypes',
            'vitalEventTypes',
            'acuityEventTypes',
            'segmentEventTypes',
            'activeCampaignEventTypes',
            'morfAppointmentEventTypes',
            'intakeqEventTypes',
            'pushJsonEventTypes',
            'customerIoEventTypes',
            'hubspotEventTypes',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'formsortEventTypes',
            'healthieEventTypes',
            'axleHealthEventTypes',
            'butterflyLabsEventTypes',
            'vitalEventTypes',
            'acuityEventTypes',
            'segmentEventTypes',
            'activeCampaignEventTypes',
            'morfAppointmentEventTypes',
            'intakeqEventTypes',
            'pushJsonEventTypes',
            'customerIoEventTypes',
            'hubspotEventTypes',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formsortEventTypes != null &&
            Object.hasOwnProperty.call(message, 'formsortEventTypes')
          ) {
            formsort$1.v1.SubscribedEventTypes.encode(
              message.formsortEventTypes,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.healthieEventTypes != null &&
            Object.hasOwnProperty.call(message, 'healthieEventTypes')
          ) {
            healthie$1.v1.SubscribedEventTypes.encode(
              message.healthieEventTypes,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.axleHealthEventTypes != null &&
            Object.hasOwnProperty.call(message, 'axleHealthEventTypes')
          ) {
            axle_health$1.v1.SubscribedEventTypes.encode(
              message.axleHealthEventTypes,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.butterflyLabsEventTypes != null &&
            Object.hasOwnProperty.call(message, 'butterflyLabsEventTypes')
          ) {
            butterfly_labs$1.v1.SubscribedEventTypes.encode(
              message.butterflyLabsEventTypes,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.vitalEventTypes != null &&
            Object.hasOwnProperty.call(message, 'vitalEventTypes')
          ) {
            vital$1.v1.SubscribedEventTypes.encode(
              message.vitalEventTypes,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.acuityEventTypes != null &&
            Object.hasOwnProperty.call(message, 'acuityEventTypes')
          ) {
            acuity$1.v1.SubscribedEventTypes.encode(
              message.acuityEventTypes,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.segmentEventTypes != null &&
            Object.hasOwnProperty.call(message, 'segmentEventTypes')
          ) {
            segment$1.v1.SubscribedEventTypes.encode(
              message.segmentEventTypes,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignEventTypes != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignEventTypes')
          ) {
            active_campaign$1.v1.SubscribedEventTypes.encode(
              message.activeCampaignEventTypes,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.morfAppointmentEventTypes != null &&
            Object.hasOwnProperty.call(message, 'morfAppointmentEventTypes')
          ) {
            morf_appointment$1.v1.SubscribedEventTypes.encode(
              message.morfAppointmentEventTypes,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.intakeqEventTypes != null &&
            Object.hasOwnProperty.call(message, 'intakeqEventTypes')
          ) {
            intakeq$1.v1.SubscribedEventTypes.encode(
              message.intakeqEventTypes,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.pushJsonEventTypes != null &&
            Object.hasOwnProperty.call(message, 'pushJsonEventTypes')
          ) {
            push_json$1.v1.SubscribedEventTypes.encode(
              message.pushJsonEventTypes,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.customerIoEventTypes != null &&
            Object.hasOwnProperty.call(message, 'customerIoEventTypes')
          ) {
            customerio$1.v1.SubscribedEventTypes.encode(
              message.customerIoEventTypes,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.hubspotEventTypes != null &&
            Object.hasOwnProperty.call(message, 'hubspotEventTypes')
          ) {
            hubspot$1.v1.SubscribedEventTypes.encode(
              message.hubspotEventTypes,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortEventTypes =
                  formsort$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.healthieEventTypes =
                  healthie$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.axleHealthEventTypes =
                  axle_health$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.butterflyLabsEventTypes =
                  butterfly_labs$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.vitalEventTypes =
                  vital$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.acuityEventTypes =
                  acuity$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.segmentEventTypes =
                  segment$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.activeCampaignEventTypes =
                  active_campaign$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.morfAppointmentEventTypes =
                  morf_appointment$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.intakeqEventTypes =
                  intakeq$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.pushJsonEventTypes =
                  push_json$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.customerIoEventTypes =
                  customerio$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.hubspotEventTypes =
                  hubspot$1.v1.SubscribedEventTypes.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.EventTypes) {
            return object;
          }
          const message = new $root.event_payload.v2.EventTypes();
          if (object.formsortEventTypes != null) {
            if (typeof object.formsortEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.formsortEventTypes: object expected, but got ' +
                  typeof object.formsortEventTypes
              );
            }
            message.formsortEventTypes =
              formsort$1.v1.SubscribedEventTypes.fromObject(
                object.formsortEventTypes
              );
          }
          if (object.healthieEventTypes != null) {
            if (typeof object.healthieEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.healthieEventTypes: object expected, but got ' +
                  typeof object.healthieEventTypes
              );
            }
            message.healthieEventTypes =
              healthie$1.v1.SubscribedEventTypes.fromObject(
                object.healthieEventTypes
              );
          }
          if (object.axleHealthEventTypes != null) {
            if (typeof object.axleHealthEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.axleHealthEventTypes: object expected, but got ' +
                  typeof object.axleHealthEventTypes
              );
            }
            message.axleHealthEventTypes =
              axle_health$1.v1.SubscribedEventTypes.fromObject(
                object.axleHealthEventTypes
              );
          }
          if (object.butterflyLabsEventTypes != null) {
            if (typeof object.butterflyLabsEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.butterflyLabsEventTypes: object expected, but got ' +
                  typeof object.butterflyLabsEventTypes
              );
            }
            message.butterflyLabsEventTypes =
              butterfly_labs$1.v1.SubscribedEventTypes.fromObject(
                object.butterflyLabsEventTypes
              );
          }
          if (object.vitalEventTypes != null) {
            if (typeof object.vitalEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.vitalEventTypes: object expected, but got ' +
                  typeof object.vitalEventTypes
              );
            }
            message.vitalEventTypes =
              vital$1.v1.SubscribedEventTypes.fromObject(
                object.vitalEventTypes
              );
          }
          if (object.acuityEventTypes != null) {
            if (typeof object.acuityEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.acuityEventTypes: object expected, but got ' +
                  typeof object.acuityEventTypes
              );
            }
            message.acuityEventTypes =
              acuity$1.v1.SubscribedEventTypes.fromObject(
                object.acuityEventTypes
              );
          }
          if (object.segmentEventTypes != null) {
            if (typeof object.segmentEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.segmentEventTypes: object expected, but got ' +
                  typeof object.segmentEventTypes
              );
            }
            message.segmentEventTypes =
              segment$1.v1.SubscribedEventTypes.fromObject(
                object.segmentEventTypes
              );
          }
          if (object.activeCampaignEventTypes != null) {
            if (typeof object.activeCampaignEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.activeCampaignEventTypes: object expected, but got ' +
                  typeof object.activeCampaignEventTypes
              );
            }
            message.activeCampaignEventTypes =
              active_campaign$1.v1.SubscribedEventTypes.fromObject(
                object.activeCampaignEventTypes
              );
          }
          if (object.morfAppointmentEventTypes != null) {
            if (typeof object.morfAppointmentEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.morfAppointmentEventTypes: object expected, but got ' +
                  typeof object.morfAppointmentEventTypes
              );
            }
            message.morfAppointmentEventTypes =
              morf_appointment$1.v1.SubscribedEventTypes.fromObject(
                object.morfAppointmentEventTypes
              );
          }
          if (object.intakeqEventTypes != null) {
            if (typeof object.intakeqEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.intakeqEventTypes: object expected, but got ' +
                  typeof object.intakeqEventTypes
              );
            }
            message.intakeqEventTypes =
              intakeq$1.v1.SubscribedEventTypes.fromObject(
                object.intakeqEventTypes
              );
          }
          if (object.pushJsonEventTypes != null) {
            if (typeof object.pushJsonEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.pushJsonEventTypes: object expected, but got ' +
                  typeof object.pushJsonEventTypes
              );
            }
            message.pushJsonEventTypes =
              push_json$1.v1.SubscribedEventTypes.fromObject(
                object.pushJsonEventTypes
              );
          }
          if (object.customerIoEventTypes != null) {
            if (typeof object.customerIoEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.customerIoEventTypes: object expected, but got ' +
                  typeof object.customerIoEventTypes
              );
            }
            message.customerIoEventTypes =
              customerio$1.v1.SubscribedEventTypes.fromObject(
                object.customerIoEventTypes
              );
          }
          if (object.hubspotEventTypes != null) {
            if (typeof object.hubspotEventTypes !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventTypes.hubspotEventTypes: object expected, but got ' +
                  typeof object.hubspotEventTypes
              );
            }
            message.hubspotEventTypes =
              hubspot$1.v1.SubscribedEventTypes.fromObject(
                object.hubspotEventTypes
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.formsortEventTypes != null &&
            message.hasOwnProperty('formsortEventTypes')
          ) {
            object.formsortEventTypes =
              formsort$1.v1.SubscribedEventTypes.toObject(
                message.formsortEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'formsortEventTypes';
            }
          }
          if (
            message.healthieEventTypes != null &&
            message.hasOwnProperty('healthieEventTypes')
          ) {
            object.healthieEventTypes =
              healthie$1.v1.SubscribedEventTypes.toObject(
                message.healthieEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'healthieEventTypes';
            }
          }
          if (
            message.axleHealthEventTypes != null &&
            message.hasOwnProperty('axleHealthEventTypes')
          ) {
            object.axleHealthEventTypes =
              axle_health$1.v1.SubscribedEventTypes.toObject(
                message.axleHealthEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'axleHealthEventTypes';
            }
          }
          if (
            message.butterflyLabsEventTypes != null &&
            message.hasOwnProperty('butterflyLabsEventTypes')
          ) {
            object.butterflyLabsEventTypes =
              butterfly_labs$1.v1.SubscribedEventTypes.toObject(
                message.butterflyLabsEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'butterflyLabsEventTypes';
            }
          }
          if (
            message.vitalEventTypes != null &&
            message.hasOwnProperty('vitalEventTypes')
          ) {
            object.vitalEventTypes = vital$1.v1.SubscribedEventTypes.toObject(
              message.vitalEventTypes,
              options
            );
            if (options.oneofs) {
              object.type = 'vitalEventTypes';
            }
          }
          if (
            message.acuityEventTypes != null &&
            message.hasOwnProperty('acuityEventTypes')
          ) {
            object.acuityEventTypes = acuity$1.v1.SubscribedEventTypes.toObject(
              message.acuityEventTypes,
              options
            );
            if (options.oneofs) {
              object.type = 'acuityEventTypes';
            }
          }
          if (
            message.segmentEventTypes != null &&
            message.hasOwnProperty('segmentEventTypes')
          ) {
            object.segmentEventTypes =
              segment$1.v1.SubscribedEventTypes.toObject(
                message.segmentEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'segmentEventTypes';
            }
          }
          if (
            message.activeCampaignEventTypes != null &&
            message.hasOwnProperty('activeCampaignEventTypes')
          ) {
            object.activeCampaignEventTypes =
              active_campaign$1.v1.SubscribedEventTypes.toObject(
                message.activeCampaignEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'activeCampaignEventTypes';
            }
          }
          if (
            message.morfAppointmentEventTypes != null &&
            message.hasOwnProperty('morfAppointmentEventTypes')
          ) {
            object.morfAppointmentEventTypes =
              morf_appointment$1.v1.SubscribedEventTypes.toObject(
                message.morfAppointmentEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'morfAppointmentEventTypes';
            }
          }
          if (
            message.intakeqEventTypes != null &&
            message.hasOwnProperty('intakeqEventTypes')
          ) {
            object.intakeqEventTypes =
              intakeq$1.v1.SubscribedEventTypes.toObject(
                message.intakeqEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'intakeqEventTypes';
            }
          }
          if (
            message.pushJsonEventTypes != null &&
            message.hasOwnProperty('pushJsonEventTypes')
          ) {
            object.pushJsonEventTypes =
              push_json$1.v1.SubscribedEventTypes.toObject(
                message.pushJsonEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'pushJsonEventTypes';
            }
          }
          if (
            message.customerIoEventTypes != null &&
            message.hasOwnProperty('customerIoEventTypes')
          ) {
            object.customerIoEventTypes =
              customerio$1.v1.SubscribedEventTypes.toObject(
                message.customerIoEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'customerIoEventTypes';
            }
          }
          if (
            message.hubspotEventTypes != null &&
            message.hasOwnProperty('hubspotEventTypes')
          ) {
            object.hubspotEventTypes =
              hubspot$1.v1.SubscribedEventTypes.toObject(
                message.hubspotEventTypes,
                options
              );
            if (options.oneofs) {
              object.type = 'hubspotEventTypes';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.EventTypes';
        }
      }

      EventTypes.prototype.formsortEventTypes = null;
      EventTypes.prototype.healthieEventTypes = null;
      EventTypes.prototype.axleHealthEventTypes = null;
      EventTypes.prototype.butterflyLabsEventTypes = null;
      EventTypes.prototype.vitalEventTypes = null;
      EventTypes.prototype.acuityEventTypes = null;
      EventTypes.prototype.segmentEventTypes = null;
      EventTypes.prototype.activeCampaignEventTypes = null;
      EventTypes.prototype.morfAppointmentEventTypes = null;
      EventTypes.prototype.intakeqEventTypes = null;
      EventTypes.prototype.pushJsonEventTypes = null;
      EventTypes.prototype.customerIoEventTypes = null;
      EventTypes.prototype.hubspotEventTypes = null;

      return EventTypes;
    })();

    v2.EventPayload = (() => {
      class EventPayload {
        get payload() {
          for (const key of [
            'formsortEventPayload',
            'healthieEventPayload',
            'axleHealthEventPayload',
            'butterflyLabsEventPayload',
            'vitalEventPayload',
            'acuityEventPayload',
            'morfAppointmentEventPayload',
            'segmentEventPayload',
            'activeCampaignEventPayload',
            'intakeqEventPayload',
            'pushJsonEventPayload',
            'customerIoEventPayload',
            'hubspotEventPayload',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set payload(name) {
          for (const key of [
            'formsortEventPayload',
            'healthieEventPayload',
            'axleHealthEventPayload',
            'butterflyLabsEventPayload',
            'vitalEventPayload',
            'acuityEventPayload',
            'morfAppointmentEventPayload',
            'segmentEventPayload',
            'activeCampaignEventPayload',
            'intakeqEventPayload',
            'pushJsonEventPayload',
            'customerIoEventPayload',
            'hubspotEventPayload',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formsortEventPayload != null &&
            Object.hasOwnProperty.call(message, 'formsortEventPayload')
          ) {
            $root.event_payload.v2.FormsortEventPayload.encode(
              message.formsortEventPayload,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.healthieEventPayload != null &&
            Object.hasOwnProperty.call(message, 'healthieEventPayload')
          ) {
            $root.event_payload.v2.HealthieEventPayload.encode(
              message.healthieEventPayload,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.axleHealthEventPayload != null &&
            Object.hasOwnProperty.call(message, 'axleHealthEventPayload')
          ) {
            $root.event_payload.v2.AxleHealthEventPayload.encode(
              message.axleHealthEventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.butterflyLabsEventPayload != null &&
            Object.hasOwnProperty.call(message, 'butterflyLabsEventPayload')
          ) {
            $root.event_payload.v2.ButterflyLabsEventPayload.encode(
              message.butterflyLabsEventPayload,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.vitalEventPayload != null &&
            Object.hasOwnProperty.call(message, 'vitalEventPayload')
          ) {
            $root.event_payload.v2.VitalEventPayload.encode(
              message.vitalEventPayload,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.acuityEventPayload != null &&
            Object.hasOwnProperty.call(message, 'acuityEventPayload')
          ) {
            $root.event_payload.v2.AcuityEventPayload.encode(
              message.acuityEventPayload,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.morfAppointmentEventPayload != null &&
            Object.hasOwnProperty.call(message, 'morfAppointmentEventPayload')
          ) {
            $root.event_payload.v2.MorfAppointmentEventPayload.encode(
              message.morfAppointmentEventPayload,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.segmentEventPayload != null &&
            Object.hasOwnProperty.call(message, 'segmentEventPayload')
          ) {
            $root.event_payload.v2.SegmentEventPayload.encode(
              message.segmentEventPayload,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignEventPayload != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignEventPayload')
          ) {
            $root.event_payload.v2.ActiveCampaignEventPayload.encode(
              message.activeCampaignEventPayload,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.intakeqEventPayload != null &&
            Object.hasOwnProperty.call(message, 'intakeqEventPayload')
          ) {
            $root.event_payload.v2.IntakeQEventPayload.encode(
              message.intakeqEventPayload,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.pushJsonEventPayload != null &&
            Object.hasOwnProperty.call(message, 'pushJsonEventPayload')
          ) {
            $root.event_payload.v2.PushJsonEventPayload.encode(
              message.pushJsonEventPayload,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.customerIoEventPayload != null &&
            Object.hasOwnProperty.call(message, 'customerIoEventPayload')
          ) {
            $root.event_payload.v2.CustomerIoEventPayload.encode(
              message.customerIoEventPayload,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.hubspotEventPayload != null &&
            Object.hasOwnProperty.call(message, 'hubspotEventPayload')
          ) {
            $root.event_payload.v2.HubspotEventPayload.encode(
              message.hubspotEventPayload,
              writer.uint32(114).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortEventPayload =
                  $root.event_payload.v2.FormsortEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.healthieEventPayload =
                  $root.event_payload.v2.HealthieEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.axleHealthEventPayload =
                  $root.event_payload.v2.AxleHealthEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.butterflyLabsEventPayload =
                  $root.event_payload.v2.ButterflyLabsEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.vitalEventPayload =
                  $root.event_payload.v2.VitalEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.acuityEventPayload =
                  $root.event_payload.v2.AcuityEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.morfAppointmentEventPayload =
                  $root.event_payload.v2.MorfAppointmentEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.segmentEventPayload =
                  $root.event_payload.v2.SegmentEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.activeCampaignEventPayload =
                  $root.event_payload.v2.ActiveCampaignEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.intakeqEventPayload =
                  $root.event_payload.v2.IntakeQEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.pushJsonEventPayload =
                  $root.event_payload.v2.PushJsonEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.customerIoEventPayload =
                  $root.event_payload.v2.CustomerIoEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.hubspotEventPayload =
                  $root.event_payload.v2.HubspotEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.EventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.EventPayload();
          if (object.formsortEventPayload != null) {
            if (typeof object.formsortEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.formsortEventPayload: object expected, but got ' +
                  typeof object.formsortEventPayload
              );
            }
            message.formsortEventPayload =
              $root.event_payload.v2.FormsortEventPayload.fromObject(
                object.formsortEventPayload
              );
          }
          if (object.healthieEventPayload != null) {
            if (typeof object.healthieEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.healthieEventPayload: object expected, but got ' +
                  typeof object.healthieEventPayload
              );
            }
            message.healthieEventPayload =
              $root.event_payload.v2.HealthieEventPayload.fromObject(
                object.healthieEventPayload
              );
          }
          if (object.axleHealthEventPayload != null) {
            if (typeof object.axleHealthEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.axleHealthEventPayload: object expected, but got ' +
                  typeof object.axleHealthEventPayload
              );
            }
            message.axleHealthEventPayload =
              $root.event_payload.v2.AxleHealthEventPayload.fromObject(
                object.axleHealthEventPayload
              );
          }
          if (object.butterflyLabsEventPayload != null) {
            if (typeof object.butterflyLabsEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.butterflyLabsEventPayload: object expected, but got ' +
                  typeof object.butterflyLabsEventPayload
              );
            }
            message.butterflyLabsEventPayload =
              $root.event_payload.v2.ButterflyLabsEventPayload.fromObject(
                object.butterflyLabsEventPayload
              );
          }
          if (object.vitalEventPayload != null) {
            if (typeof object.vitalEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.vitalEventPayload: object expected, but got ' +
                  typeof object.vitalEventPayload
              );
            }
            message.vitalEventPayload =
              $root.event_payload.v2.VitalEventPayload.fromObject(
                object.vitalEventPayload
              );
          }
          if (object.acuityEventPayload != null) {
            if (typeof object.acuityEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.acuityEventPayload: object expected, but got ' +
                  typeof object.acuityEventPayload
              );
            }
            message.acuityEventPayload =
              $root.event_payload.v2.AcuityEventPayload.fromObject(
                object.acuityEventPayload
              );
          }
          if (object.morfAppointmentEventPayload != null) {
            if (typeof object.morfAppointmentEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.morfAppointmentEventPayload: object expected, but got ' +
                  typeof object.morfAppointmentEventPayload
              );
            }
            message.morfAppointmentEventPayload =
              $root.event_payload.v2.MorfAppointmentEventPayload.fromObject(
                object.morfAppointmentEventPayload
              );
          }
          if (object.segmentEventPayload != null) {
            if (typeof object.segmentEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.segmentEventPayload: object expected, but got ' +
                  typeof object.segmentEventPayload
              );
            }
            message.segmentEventPayload =
              $root.event_payload.v2.SegmentEventPayload.fromObject(
                object.segmentEventPayload
              );
          }
          if (object.activeCampaignEventPayload != null) {
            if (typeof object.activeCampaignEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.activeCampaignEventPayload: object expected, but got ' +
                  typeof object.activeCampaignEventPayload
              );
            }
            message.activeCampaignEventPayload =
              $root.event_payload.v2.ActiveCampaignEventPayload.fromObject(
                object.activeCampaignEventPayload
              );
          }
          if (object.intakeqEventPayload != null) {
            if (typeof object.intakeqEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.intakeqEventPayload: object expected, but got ' +
                  typeof object.intakeqEventPayload
              );
            }
            message.intakeqEventPayload =
              $root.event_payload.v2.IntakeQEventPayload.fromObject(
                object.intakeqEventPayload
              );
          }
          if (object.pushJsonEventPayload != null) {
            if (typeof object.pushJsonEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.pushJsonEventPayload: object expected, but got ' +
                  typeof object.pushJsonEventPayload
              );
            }
            message.pushJsonEventPayload =
              $root.event_payload.v2.PushJsonEventPayload.fromObject(
                object.pushJsonEventPayload
              );
          }
          if (object.customerIoEventPayload != null) {
            if (typeof object.customerIoEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.customerIoEventPayload: object expected, but got ' +
                  typeof object.customerIoEventPayload
              );
            }
            message.customerIoEventPayload =
              $root.event_payload.v2.CustomerIoEventPayload.fromObject(
                object.customerIoEventPayload
              );
          }
          if (object.hubspotEventPayload != null) {
            if (typeof object.hubspotEventPayload !== 'object') {
              throw new TypeError(
                '.event_payload.v2.EventPayload.hubspotEventPayload: object expected, but got ' +
                  typeof object.hubspotEventPayload
              );
            }
            message.hubspotEventPayload =
              $root.event_payload.v2.HubspotEventPayload.fromObject(
                object.hubspotEventPayload
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.formsortEventPayload != null &&
            message.hasOwnProperty('formsortEventPayload')
          ) {
            object.formsortEventPayload =
              $root.event_payload.v2.FormsortEventPayload.toObject(
                message.formsortEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'formsortEventPayload';
            }
          }
          if (
            message.healthieEventPayload != null &&
            message.hasOwnProperty('healthieEventPayload')
          ) {
            object.healthieEventPayload =
              $root.event_payload.v2.HealthieEventPayload.toObject(
                message.healthieEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'healthieEventPayload';
            }
          }
          if (
            message.axleHealthEventPayload != null &&
            message.hasOwnProperty('axleHealthEventPayload')
          ) {
            object.axleHealthEventPayload =
              $root.event_payload.v2.AxleHealthEventPayload.toObject(
                message.axleHealthEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'axleHealthEventPayload';
            }
          }
          if (
            message.butterflyLabsEventPayload != null &&
            message.hasOwnProperty('butterflyLabsEventPayload')
          ) {
            object.butterflyLabsEventPayload =
              $root.event_payload.v2.ButterflyLabsEventPayload.toObject(
                message.butterflyLabsEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'butterflyLabsEventPayload';
            }
          }
          if (
            message.vitalEventPayload != null &&
            message.hasOwnProperty('vitalEventPayload')
          ) {
            object.vitalEventPayload =
              $root.event_payload.v2.VitalEventPayload.toObject(
                message.vitalEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'vitalEventPayload';
            }
          }
          if (
            message.acuityEventPayload != null &&
            message.hasOwnProperty('acuityEventPayload')
          ) {
            object.acuityEventPayload =
              $root.event_payload.v2.AcuityEventPayload.toObject(
                message.acuityEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'acuityEventPayload';
            }
          }
          if (
            message.morfAppointmentEventPayload != null &&
            message.hasOwnProperty('morfAppointmentEventPayload')
          ) {
            object.morfAppointmentEventPayload =
              $root.event_payload.v2.MorfAppointmentEventPayload.toObject(
                message.morfAppointmentEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'morfAppointmentEventPayload';
            }
          }
          if (
            message.segmentEventPayload != null &&
            message.hasOwnProperty('segmentEventPayload')
          ) {
            object.segmentEventPayload =
              $root.event_payload.v2.SegmentEventPayload.toObject(
                message.segmentEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'segmentEventPayload';
            }
          }
          if (
            message.activeCampaignEventPayload != null &&
            message.hasOwnProperty('activeCampaignEventPayload')
          ) {
            object.activeCampaignEventPayload =
              $root.event_payload.v2.ActiveCampaignEventPayload.toObject(
                message.activeCampaignEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'activeCampaignEventPayload';
            }
          }
          if (
            message.intakeqEventPayload != null &&
            message.hasOwnProperty('intakeqEventPayload')
          ) {
            object.intakeqEventPayload =
              $root.event_payload.v2.IntakeQEventPayload.toObject(
                message.intakeqEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'intakeqEventPayload';
            }
          }
          if (
            message.pushJsonEventPayload != null &&
            message.hasOwnProperty('pushJsonEventPayload')
          ) {
            object.pushJsonEventPayload =
              $root.event_payload.v2.PushJsonEventPayload.toObject(
                message.pushJsonEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'pushJsonEventPayload';
            }
          }
          if (
            message.customerIoEventPayload != null &&
            message.hasOwnProperty('customerIoEventPayload')
          ) {
            object.customerIoEventPayload =
              $root.event_payload.v2.CustomerIoEventPayload.toObject(
                message.customerIoEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'customerIoEventPayload';
            }
          }
          if (
            message.hubspotEventPayload != null &&
            message.hasOwnProperty('hubspotEventPayload')
          ) {
            object.hubspotEventPayload =
              $root.event_payload.v2.HubspotEventPayload.toObject(
                message.hubspotEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'hubspotEventPayload';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.EventPayload';
        }
      }

      EventPayload.prototype.formsortEventPayload = null;
      EventPayload.prototype.healthieEventPayload = null;
      EventPayload.prototype.axleHealthEventPayload = null;
      EventPayload.prototype.butterflyLabsEventPayload = null;
      EventPayload.prototype.vitalEventPayload = null;
      EventPayload.prototype.acuityEventPayload = null;
      EventPayload.prototype.morfAppointmentEventPayload = null;
      EventPayload.prototype.segmentEventPayload = null;
      EventPayload.prototype.activeCampaignEventPayload = null;
      EventPayload.prototype.intakeqEventPayload = null;
      EventPayload.prototype.pushJsonEventPayload = null;
      EventPayload.prototype.customerIoEventPayload = null;
      EventPayload.prototype.hubspotEventPayload = null;

      return EventPayload;
    })();

    v2.FormsortEventPayload = (() => {
      class FormsortEventPayload {
        get event() {
          for (const key of ['formResponse']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['formResponse']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.formResponse != null &&
            Object.hasOwnProperty.call(message, 'formResponse')
          ) {
            formsort$1.v1.FormsortFormResponse.encode(
              message.formResponse,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.formResponse =
                  formsort$1.v1.FormsortFormResponse.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.FormsortEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.FormsortEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_FORMSORT_WEBHOOK_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_STEP_COMPLETED':
              case 1: {
                message.eventType = 1;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.formResponse != null) {
            if (typeof object.formResponse !== 'object') {
              throw new TypeError(
                '.event_payload.v2.FormsortEventPayload.formResponse: object expected, but got ' +
                  typeof object.formResponse
              );
            }
            message.formResponse =
              formsort$1.v1.FormsortFormResponse.fromObject(
                object.formResponse
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_FORMSORT_WEBHOOK_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? formsort$1.v1.FormsortEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : formsort$1.v1.FormsortEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.formResponse != null &&
            message.hasOwnProperty('formResponse')
          ) {
            object.formResponse = formsort$1.v1.FormsortFormResponse.toObject(
              message.formResponse,
              options
            );
            if (options.oneofs) {
              object.event = 'formResponse';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.FormsortEventPayload';
        }
      }

      FormsortEventPayload.prototype.eventType = 0;
      FormsortEventPayload.prototype.formResponse = null;

      return FormsortEventPayload;
    })();

    v2.HealthieEventPayload = (() => {
      class HealthieEventPayload {
        get event() {
          for (const key of [
            'formAnswerGroup',
            'appointment',
            'user',
            'cms1500',
            'insuranceAuthorization',
            'requestedFormCompletion',
            'billingItem',
            'policy',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of [
            'formAnswerGroup',
            'appointment',
            'user',
            'cms1500',
            'insuranceAuthorization',
            'requestedFormCompletion',
            'billingItem',
            'policy',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.formAnswerGroup != null &&
            Object.hasOwnProperty.call(message, 'formAnswerGroup')
          ) {
            healthie$1.v1.FormAnswerGroup.encode(
              message.formAnswerGroup,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            healthie$1.v1.Appointment.encode(
              message.appointment,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            healthie$1.v1.User.encode(
              message.user,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.cms1500 != null &&
            Object.hasOwnProperty.call(message, 'cms1500')
          ) {
            healthie$1.v1.CMS1500.encode(
              message.cms1500,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.insuranceAuthorization != null &&
            Object.hasOwnProperty.call(message, 'insuranceAuthorization')
          ) {
            healthie$1.v1.InsuranceAuthorization.encode(
              message.insuranceAuthorization,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.requestedFormCompletion != null &&
            Object.hasOwnProperty.call(message, 'requestedFormCompletion')
          ) {
            healthie$1.v1.RequestedFormCompletion.encode(
              message.requestedFormCompletion,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.billingItem != null &&
            Object.hasOwnProperty.call(message, 'billingItem')
          ) {
            healthie$1.v1.BillingItem.encode(
              message.billingItem,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.policy != null &&
            Object.hasOwnProperty.call(message, 'policy')
          ) {
            healthie$1.v1.Policy.encode(
              message.policy,
              writer.uint32(74).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.formAnswerGroup = healthie$1.v1.FormAnswerGroup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.appointment = healthie$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.user = healthie$1.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.cms1500 = healthie$1.v1.CMS1500.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.insuranceAuthorization =
                  healthie$1.v1.InsuranceAuthorization.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.requestedFormCompletion =
                  healthie$1.v1.RequestedFormCompletion.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.billingItem = healthie$1.v1.BillingItem.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.policy = healthie$1.v1.Policy.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.HealthieEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.HealthieEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_DELETED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_CREATED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_UPDATED':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'HEALTHIE_CMS1500_CREATED':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'HEALTHIE_CMS1500_UPDATED':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'HEALTHIE_CMS1500_DELETED':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'HEALTHIE_COMMENT_CREATED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'HEALTHIE_COMMENT_UPDATED':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'HEALTHIE_COMMENT_DELETED':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'HEALTHIE_DOCUMENT_CREATED':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'HEALTHIE_DOCUMENT_UPDATED':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'HEALTHIE_DOCUMENT_DELETED':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'HEALTHIE_ENTRY_CREATED':
              case 16: {
                message.eventType = 16;
                break;
              }
              case 'HEALTHIE_ENTRY_UPDATED':
              case 17: {
                message.eventType = 17;
                break;
              }
              case 'HEALTHIE_ENTRY_DELETED':
              case 18: {
                message.eventType = 18;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_CREATED':
              case 19: {
                message.eventType = 19;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_DELETED':
              case 20: {
                message.eventType = 20;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED':
              case 21: {
                message.eventType = 21;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED':
              case 22: {
                message.eventType = 22;
                break;
              }
              case 'HEALTHIE_GOAL_CREATED':
              case 23: {
                message.eventType = 23;
                break;
              }
              case 'HEALTHIE_GOAL_UPDATED':
              case 24: {
                message.eventType = 24;
                break;
              }
              case 'HEALTHIE_GOAL_DELETED':
              case 25: {
                message.eventType = 25;
                break;
              }
              case 'HEALTHIE_GOAL_HISTORY_CREATED':
              case 26: {
                message.eventType = 26;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED':
              case 27: {
                message.eventType = 27;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED':
              case 28: {
                message.eventType = 28;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED':
              case 29: {
                message.eventType = 29;
                break;
              }
              case 'HEALTHIE_MESSAGE_CREATED':
              case 30: {
                message.eventType = 30;
                break;
              }
              case 'HEALTHIE_MESSAGE_DELETED':
              case 31: {
                message.eventType = 31;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_CREATED':
              case 32: {
                message.eventType = 32;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_UPDATED':
              case 33: {
                message.eventType = 33;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_DELETED':
              case 34: {
                message.eventType = 34;
                break;
              }
              case 'HEALTHIE_PATIENT_CREATED':
              case 35: {
                message.eventType = 35;
                break;
              }
              case 'HEALTHIE_PATIENT_UPDATED':
              case 36: {
                message.eventType = 36;
                break;
              }
              case 'HEALTHIE_POLICY_CREATED':
              case 37: {
                message.eventType = 37;
                break;
              }
              case 'HEALTHIE_POLICY_UPDATED':
              case 38: {
                message.eventType = 38;
                break;
              }
              case 'HEALTHIE_POLICY_DELETED':
              case 39: {
                message.eventType = 39;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED':
              case 40: {
                message.eventType = 40;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED':
              case 41: {
                message.eventType = 41;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED':
              case 42: {
                message.eventType = 42;
                break;
              }
              case 'HEALTHIE_TASK_CREATED':
              case 43: {
                message.eventType = 43;
                break;
              }
              case 'HEALTHIE_TASK_UPDATED':
              case 44: {
                message.eventType = 44;
                break;
              }
              case 'HEALTHIE_TASK_DELETED':
              case 45: {
                message.eventType = 45;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED':
              case 46: {
                message.eventType = 46;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_NO_SHOW':
              case 47: {
                message.eventType = 47;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_RESCHEDULED':
              case 48: {
                message.eventType = 48;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_CANCELLED':
              case 49: {
                message.eventType = 49;
                break;
              }
              case 'HEALTHIE_APPLIED_TAG_CREATED':
              case 50: {
                message.eventType = 50;
                break;
              }
              case 'HEALTHIE_APPLIED_TAG_DELETED':
              case 51: {
                message.eventType = 51;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.formAnswerGroup != null) {
            if (typeof object.formAnswerGroup !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.formAnswerGroup: object expected, but got ' +
                  typeof object.formAnswerGroup
              );
            }
            message.formAnswerGroup = healthie$1.v1.FormAnswerGroup.fromObject(
              object.formAnswerGroup
            );
          }
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment = healthie$1.v1.Appointment.fromObject(
              object.appointment
            );
          }
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = healthie$1.v1.User.fromObject(object.user);
          }
          if (object.cms1500 != null) {
            if (typeof object.cms1500 !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.cms1500: object expected, but got ' +
                  typeof object.cms1500
              );
            }
            message.cms1500 = healthie$1.v1.CMS1500.fromObject(object.cms1500);
          }
          if (object.insuranceAuthorization != null) {
            if (typeof object.insuranceAuthorization !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.insuranceAuthorization: object expected, but got ' +
                  typeof object.insuranceAuthorization
              );
            }
            message.insuranceAuthorization =
              healthie$1.v1.InsuranceAuthorization.fromObject(
                object.insuranceAuthorization
              );
          }
          if (object.requestedFormCompletion != null) {
            if (typeof object.requestedFormCompletion !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.requestedFormCompletion: object expected, but got ' +
                  typeof object.requestedFormCompletion
              );
            }
            message.requestedFormCompletion =
              healthie$1.v1.RequestedFormCompletion.fromObject(
                object.requestedFormCompletion
              );
          }
          if (object.billingItem != null) {
            if (typeof object.billingItem !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.billingItem: object expected, but got ' +
                  typeof object.billingItem
              );
            }
            message.billingItem = healthie$1.v1.BillingItem.fromObject(
              object.billingItem
            );
          }
          if (object.policy != null) {
            if (typeof object.policy !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HealthieEventPayload.policy: object expected, but got ' +
                  typeof object.policy
              );
            }
            message.policy = healthie$1.v1.Policy.fromObject(object.policy);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? healthie$1.v1.HealthieEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : healthie$1.v1.HealthieEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.formAnswerGroup != null &&
            message.hasOwnProperty('formAnswerGroup')
          ) {
            object.formAnswerGroup = healthie$1.v1.FormAnswerGroup.toObject(
              message.formAnswerGroup,
              options
            );
            if (options.oneofs) {
              object.event = 'formAnswerGroup';
            }
          }
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment = healthie$1.v1.Appointment.toObject(
              message.appointment,
              options
            );
            if (options.oneofs) {
              object.event = 'appointment';
            }
          }
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = healthie$1.v1.User.toObject(message.user, options);
            if (options.oneofs) {
              object.event = 'user';
            }
          }
          if (message.cms1500 != null && message.hasOwnProperty('cms1500')) {
            object.cms1500 = healthie$1.v1.CMS1500.toObject(
              message.cms1500,
              options
            );
            if (options.oneofs) {
              object.event = 'cms1500';
            }
          }
          if (
            message.insuranceAuthorization != null &&
            message.hasOwnProperty('insuranceAuthorization')
          ) {
            object.insuranceAuthorization =
              healthie$1.v1.InsuranceAuthorization.toObject(
                message.insuranceAuthorization,
                options
              );
            if (options.oneofs) {
              object.event = 'insuranceAuthorization';
            }
          }
          if (
            message.requestedFormCompletion != null &&
            message.hasOwnProperty('requestedFormCompletion')
          ) {
            object.requestedFormCompletion =
              healthie$1.v1.RequestedFormCompletion.toObject(
                message.requestedFormCompletion,
                options
              );
            if (options.oneofs) {
              object.event = 'requestedFormCompletion';
            }
          }
          if (
            message.billingItem != null &&
            message.hasOwnProperty('billingItem')
          ) {
            object.billingItem = healthie$1.v1.BillingItem.toObject(
              message.billingItem,
              options
            );
            if (options.oneofs) {
              object.event = 'billingItem';
            }
          }
          if (message.policy != null && message.hasOwnProperty('policy')) {
            object.policy = healthie$1.v1.Policy.toObject(
              message.policy,
              options
            );
            if (options.oneofs) {
              object.event = 'policy';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.HealthieEventPayload';
        }
      }

      HealthieEventPayload.prototype.eventType = 0;
      HealthieEventPayload.prototype.formAnswerGroup = null;
      HealthieEventPayload.prototype.appointment = null;
      HealthieEventPayload.prototype.user = null;
      HealthieEventPayload.prototype.cms1500 = null;
      HealthieEventPayload.prototype.insuranceAuthorization = null;
      HealthieEventPayload.prototype.requestedFormCompletion = null;
      HealthieEventPayload.prototype.billingItem = null;
      HealthieEventPayload.prototype.policy = null;

      return HealthieEventPayload;
    })();

    v2.HubspotEventPayload = (() => {
      class HubspotEventPayload {
        get event() {
          for (const key of ['contact']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['contact']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HubspotEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.contact != null &&
            Object.hasOwnProperty.call(message, 'contact')
          ) {
            hubspot$1.v1.Contact.encode(
              message.contact,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HubspotEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.contact = hubspot$1.v1.Contact.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.HubspotEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.HubspotEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_HUBSPOT_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'HUBSPOT_CONTACT_CREATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'HUBSPOT_CONTACT_MERGED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HUBSPOT_CONTACT_DELETED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'HUBSPOT_CONTACT_RESTORED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'HUBSPOT_CONTACT_PROPERTIES_UPDATED':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'HUBSPOT_CONTACT_ASSOCIATIONS_UPDATED':
              case 6: {
                message.eventType = 6;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.contact != null) {
            if (typeof object.contact !== 'object') {
              throw new TypeError(
                '.event_payload.v2.HubspotEventPayload.contact: object expected, but got ' +
                  typeof object.contact
              );
            }
            message.contact = hubspot$1.v1.Contact.fromObject(object.contact);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_HUBSPOT_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? hubspot$1.v1.HubspotEventType[message.eventType] === undefined
                  ? message.eventType
                  : hubspot$1.v1.HubspotEventType[message.eventType]
                : message.eventType;
          }
          if (message.contact != null && message.hasOwnProperty('contact')) {
            object.contact = hubspot$1.v1.Contact.toObject(
              message.contact,
              options
            );
            if (options.oneofs) {
              object.event = 'contact';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.HubspotEventPayload';
        }
      }

      HubspotEventPayload.prototype.eventType = 0;
      HubspotEventPayload.prototype.contact = null;

      return HubspotEventPayload;
    })();

    v2.AxleHealthEventPayload = (() => {
      class AxleHealthEventPayload {
        get event() {
          for (const key of ['visit', 'labResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['visit', 'labResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AxleHealthEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.visit != null &&
            Object.hasOwnProperty.call(message, 'visit')
          ) {
            axle_health$1.v1.Visit.encode(
              message.visit,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.labResults != null &&
            Object.hasOwnProperty.call(message, 'labResults')
          ) {
            axle_health$1.v1.LabResults.encode(
              message.labResults,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AxleHealthEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.visit = axle_health$1.v1.Visit.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.labResults = axle_health$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.AxleHealthEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.AxleHealthEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_AXLE_HEALTH_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER_STATUS_UPDATE':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER_RESULTS_UPDATE':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.visit != null) {
            if (typeof object.visit !== 'object') {
              throw new TypeError(
                '.event_payload.v2.AxleHealthEventPayload.visit: object expected, but got ' +
                  typeof object.visit
              );
            }
            message.visit = axle_health$1.v1.Visit.fromObject(object.visit);
          }
          if (object.labResults != null) {
            if (typeof object.labResults !== 'object') {
              throw new TypeError(
                '.event_payload.v2.AxleHealthEventPayload.labResults: object expected, but got ' +
                  typeof object.labResults
              );
            }
            message.labResults = axle_health$1.v1.LabResults.fromObject(
              object.labResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_AXLE_HEALTH_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? axle_health$1.v1.AxleHealthEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : axle_health$1.v1.AxleHealthEventType[message.eventType]
                : message.eventType;
          }
          if (message.visit != null && message.hasOwnProperty('visit')) {
            object.visit = axle_health$1.v1.Visit.toObject(
              message.visit,
              options
            );
            if (options.oneofs) {
              object.event = 'visit';
            }
          }
          if (
            message.labResults != null &&
            message.hasOwnProperty('labResults')
          ) {
            object.labResults = axle_health$1.v1.LabResults.toObject(
              message.labResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.AxleHealthEventPayload';
        }
      }

      AxleHealthEventPayload.prototype.eventType = 0;
      AxleHealthEventPayload.prototype.visit = null;
      AxleHealthEventPayload.prototype.labResults = null;

      return AxleHealthEventPayload;
    })();

    v2.ButterflyLabsEventPayload = (() => {
      class ButterflyLabsEventPayload {
        get event() {
          for (const key of ['order', 'labResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['order', 'labResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ButterflyLabsEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.order != null &&
            Object.hasOwnProperty.call(message, 'order')
          ) {
            butterfly_labs$1.v1.Order.encode(
              message.order,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.labResults != null &&
            Object.hasOwnProperty.call(message, 'labResults')
          ) {
            butterfly_labs$1.v1.LabResults.encode(
              message.labResults,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ButterflyLabsEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.order = butterfly_labs$1.v1.Order.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.labResults = butterfly_labs$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_payload.v2.ButterflyLabsEventPayload
          ) {
            return object;
          }
          const message =
            new $root.event_payload.v2.ButterflyLabsEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_BUTTERFLY_LABS_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER_STATUS_UPDATE':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER_RESULTS_UPDATE':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.order != null) {
            if (typeof object.order !== 'object') {
              throw new TypeError(
                '.event_payload.v2.ButterflyLabsEventPayload.order: object expected, but got ' +
                  typeof object.order
              );
            }
            message.order = butterfly_labs$1.v1.Order.fromObject(object.order);
          }
          if (object.labResults != null) {
            if (typeof object.labResults !== 'object') {
              throw new TypeError(
                '.event_payload.v2.ButterflyLabsEventPayload.labResults: object expected, but got ' +
                  typeof object.labResults
              );
            }
            message.labResults = butterfly_labs$1.v1.LabResults.fromObject(
              object.labResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_BUTTERFLY_LABS_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? butterfly_labs$1.v1.ButterflyLabsEventType[
                    message.eventType
                  ] === undefined
                  ? message.eventType
                  : butterfly_labs$1.v1.ButterflyLabsEventType[
                      message.eventType
                    ]
                : message.eventType;
          }
          if (message.order != null && message.hasOwnProperty('order')) {
            object.order = butterfly_labs$1.v1.Order.toObject(
              message.order,
              options
            );
            if (options.oneofs) {
              object.event = 'order';
            }
          }
          if (
            message.labResults != null &&
            message.hasOwnProperty('labResults')
          ) {
            object.labResults = butterfly_labs$1.v1.LabResults.toObject(
              message.labResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.ButterflyLabsEventPayload';
        }
      }

      ButterflyLabsEventPayload.prototype.eventType = 0;
      ButterflyLabsEventPayload.prototype.order = null;
      ButterflyLabsEventPayload.prototype.labResults = null;

      return ButterflyLabsEventPayload;
    })();

    v2.VitalEventPayload = (() => {
      class VitalEventPayload {
        get event() {
          for (const key of ['order', 'labResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['order', 'labResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VitalEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.order != null &&
            Object.hasOwnProperty.call(message, 'order')
          ) {
            vital$1.v1.Order.encode(
              message.order,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.labResults != null &&
            Object.hasOwnProperty.call(message, 'labResults')
          ) {
            vital$1.v1.LabResults.encode(
              message.labResults,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VitalEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.order = vital$1.v1.Order.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.labResults = vital$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.VitalEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.VitalEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_VITAL_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'VITAL_LAB_ORDER_STATUS_UPDATE':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'VITAL_LAB_ORDER_RESULTS_UPDATE':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.order != null) {
            if (typeof object.order !== 'object') {
              throw new TypeError(
                '.event_payload.v2.VitalEventPayload.order: object expected, but got ' +
                  typeof object.order
              );
            }
            message.order = vital$1.v1.Order.fromObject(object.order);
          }
          if (object.labResults != null) {
            if (typeof object.labResults !== 'object') {
              throw new TypeError(
                '.event_payload.v2.VitalEventPayload.labResults: object expected, but got ' +
                  typeof object.labResults
              );
            }
            message.labResults = vital$1.v1.LabResults.fromObject(
              object.labResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_VITAL_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? vital$1.v1.VitalEventType[message.eventType] === undefined
                  ? message.eventType
                  : vital$1.v1.VitalEventType[message.eventType]
                : message.eventType;
          }
          if (message.order != null && message.hasOwnProperty('order')) {
            object.order = vital$1.v1.Order.toObject(message.order, options);
            if (options.oneofs) {
              object.event = 'order';
            }
          }
          if (
            message.labResults != null &&
            message.hasOwnProperty('labResults')
          ) {
            object.labResults = vital$1.v1.LabResults.toObject(
              message.labResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.VitalEventPayload';
        }
      }

      VitalEventPayload.prototype.eventType = 0;
      VitalEventPayload.prototype.order = null;
      VitalEventPayload.prototype.labResults = null;

      return VitalEventPayload;
    })();

    v2.AcuityEventPayload = (() => {
      class AcuityEventPayload {
        get event() {
          for (const key of ['appointment']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['appointment']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AcuityEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            acuity$1.v1.Appointment.encode(
              message.appointment,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AcuityEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.appointment = acuity$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.AcuityEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.AcuityEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'ACUITY_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'ACUITY_APPOINTMENT_RESCHEDULED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'ACUITY_APPOINTMENT_CANCELLED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'ACUITY_APPOINTMENT_UPDATED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'ACUITY_APPOINTMENT_NO_SHOW':
              case 5: {
                message.eventType = 5;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.event_payload.v2.AcuityEventPayload.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment = acuity$1.v1.Appointment.fromObject(
              object.appointment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? acuity$1.v1.AcuityEventType[message.eventType] === undefined
                  ? message.eventType
                  : acuity$1.v1.AcuityEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment = acuity$1.v1.Appointment.toObject(
              message.appointment,
              options
            );
            if (options.oneofs) {
              object.event = 'appointment';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.AcuityEventPayload';
        }
      }

      AcuityEventPayload.prototype.eventType = 0;
      AcuityEventPayload.prototype.appointment = null;

      return AcuityEventPayload;
    })();

    v2.SegmentEventPayload = (() => {
      class SegmentEventPayload {
        get event() {
          for (const key of ['identify', 'track', 'page']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['identify', 'track', 'page']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SegmentEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.identify != null &&
            Object.hasOwnProperty.call(message, 'identify')
          ) {
            segment$1.v1.Identify.encode(
              message.identify,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.track != null &&
            Object.hasOwnProperty.call(message, 'track')
          ) {
            segment$1.v1.Track.encode(
              message.track,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            segment$1.v1.Page.encode(
              message.page,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SegmentEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.identify = segment$1.v1.Identify.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.track = segment$1.v1.Track.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.page = segment$1.v1.Page.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.SegmentEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.SegmentEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_SEGMENT_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'SEGMENT_IDENTIFY':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'SEGMENT_TRACK':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'SEGMENT_PAGE':
              case 3: {
                message.eventType = 3;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.identify != null) {
            if (typeof object.identify !== 'object') {
              throw new TypeError(
                '.event_payload.v2.SegmentEventPayload.identify: object expected, but got ' +
                  typeof object.identify
              );
            }
            message.identify = segment$1.v1.Identify.fromObject(
              object.identify
            );
          }
          if (object.track != null) {
            if (typeof object.track !== 'object') {
              throw new TypeError(
                '.event_payload.v2.SegmentEventPayload.track: object expected, but got ' +
                  typeof object.track
              );
            }
            message.track = segment$1.v1.Track.fromObject(object.track);
          }
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.event_payload.v2.SegmentEventPayload.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = segment$1.v1.Page.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_SEGMENT_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? segment$1.v1.SegmentEventType[message.eventType] === undefined
                  ? message.eventType
                  : segment$1.v1.SegmentEventType[message.eventType]
                : message.eventType;
          }
          if (message.identify != null && message.hasOwnProperty('identify')) {
            object.identify = segment$1.v1.Identify.toObject(
              message.identify,
              options
            );
            if (options.oneofs) {
              object.event = 'identify';
            }
          }
          if (message.track != null && message.hasOwnProperty('track')) {
            object.track = segment$1.v1.Track.toObject(message.track, options);
            if (options.oneofs) {
              object.event = 'track';
            }
          }
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = segment$1.v1.Page.toObject(message.page, options);
            if (options.oneofs) {
              object.event = 'page';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.SegmentEventPayload';
        }
      }

      SegmentEventPayload.prototype.eventType = 0;
      SegmentEventPayload.prototype.identify = null;
      SegmentEventPayload.prototype.track = null;
      SegmentEventPayload.prototype.page = null;

      return SegmentEventPayload;
    })();

    v2.ActiveCampaignEventPayload = (() => {
      class ActiveCampaignEventPayload {
        get event() {
          for (const key of ['deal']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['deal']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActiveCampaignEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.deal != null &&
            Object.hasOwnProperty.call(message, 'deal')
          ) {
            active_campaign$1.v1.Deal.encode(
              message.deal,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActiveCampaignEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.deal = active_campaign$1.v1.Deal.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_payload.v2.ActiveCampaignEventPayload
          ) {
            return object;
          }
          const message =
            new $root.event_payload.v2.ActiveCampaignEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_ACTIVE_CAMPAIGN_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN_DEAL_UPDATE':
              case 1: {
                message.eventType = 1;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.deal != null) {
            if (typeof object.deal !== 'object') {
              throw new TypeError(
                '.event_payload.v2.ActiveCampaignEventPayload.deal: object expected, but got ' +
                  typeof object.deal
              );
            }
            message.deal = active_campaign$1.v1.Deal.fromObject(object.deal);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_ACTIVE_CAMPAIGN_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? active_campaign$1.v1.ActiveCampaignEventType[
                    message.eventType
                  ] === undefined
                  ? message.eventType
                  : active_campaign$1.v1.ActiveCampaignEventType[
                      message.eventType
                    ]
                : message.eventType;
          }
          if (message.deal != null && message.hasOwnProperty('deal')) {
            object.deal = active_campaign$1.v1.Deal.toObject(
              message.deal,
              options
            );
            if (options.oneofs) {
              object.event = 'deal';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.ActiveCampaignEventPayload';
        }
      }

      ActiveCampaignEventPayload.prototype.eventType = 0;
      ActiveCampaignEventPayload.prototype.deal = null;

      return ActiveCampaignEventPayload;
    })();

    v2.MorfAppointmentEventPayload = (() => {
      class MorfAppointmentEventPayload {
        get event() {
          for (const key of ['morfAppointment']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['morfAppointment']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAppointmentEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.morfAppointment != null &&
            Object.hasOwnProperty.call(message, 'morfAppointment')
          ) {
            morf_appointment$1.v1.MorfAppointment.encode(
              message.morfAppointment,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAppointmentEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.morfAppointment =
                  morf_appointment$1.v1.MorfAppointment.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_payload.v2.MorfAppointmentEventPayload
          ) {
            return object;
          }
          const message =
            new $root.event_payload.v2.MorfAppointmentEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.morfAppointment != null) {
            if (typeof object.morfAppointment !== 'object') {
              throw new TypeError(
                '.event_payload.v2.MorfAppointmentEventPayload.morfAppointment: object expected, but got ' +
                  typeof object.morfAppointment
              );
            }
            message.morfAppointment =
              morf_appointment$1.v1.MorfAppointment.fromObject(
                object.morfAppointment
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? morf_appointment$1.v1.MorfAppointmentEventType[
                    message.eventType
                  ] === undefined
                  ? message.eventType
                  : morf_appointment$1.v1.MorfAppointmentEventType[
                      message.eventType
                    ]
                : message.eventType;
          }
          if (
            message.morfAppointment != null &&
            message.hasOwnProperty('morfAppointment')
          ) {
            object.morfAppointment =
              morf_appointment$1.v1.MorfAppointment.toObject(
                message.morfAppointment,
                options
              );
            if (options.oneofs) {
              object.event = 'morfAppointment';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.MorfAppointmentEventPayload';
        }
      }

      MorfAppointmentEventPayload.prototype.eventType = 0;
      MorfAppointmentEventPayload.prototype.morfAppointment = null;

      return MorfAppointmentEventPayload;
    })();

    v2.IntakeQEventPayload = (() => {
      class IntakeQEventPayload {
        get event() {
          for (const key of ['appointment']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['appointment']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IntakeQEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            intakeq$1.v1.Appointment.encode(
              message.appointment,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IntakeQEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.appointment = intakeq$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.IntakeQEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.IntakeQEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'INTAKEQ_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'INTAKEQ_APPOINTMENT_CANCELLED':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.event_payload.v2.IntakeQEventPayload.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment = intakeq$1.v1.Appointment.fromObject(
              object.appointment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? intakeq$1.v1.IntakeQEventType[message.eventType] === undefined
                  ? message.eventType
                  : intakeq$1.v1.IntakeQEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment = intakeq$1.v1.Appointment.toObject(
              message.appointment,
              options
            );
            if (options.oneofs) {
              object.event = 'appointment';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.IntakeQEventPayload';
        }
      }

      IntakeQEventPayload.prototype.eventType = 0;
      IntakeQEventPayload.prototype.appointment = null;

      return IntakeQEventPayload;
    })();

    v2.PushJsonEventPayload = (() => {
      class PushJsonEventPayload {
        get event() {
          for (const key of ['json', 'jsonBody']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['json', 'jsonBody']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PushJsonEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.json != null &&
            Object.hasOwnProperty.call(message, 'json')
          ) {
            google$1.protobuf.Struct.encode(
              message.json,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.jsonBody != null &&
            Object.hasOwnProperty.call(message, 'jsonBody')
          ) {
            push_json$1.v1.PushJsonBody.encode(
              message.jsonBody,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PushJsonEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.json = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.jsonBody = push_json$1.v1.PushJsonBody.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.PushJsonEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.PushJsonEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_PUSH_JSON_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'MORF_API_PUSH_UPDATE':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'MORF_API_PUSH_JSON_BODY_UPDATE':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.json != null) {
            if (typeof object.json !== 'object') {
              throw new TypeError(
                '.event_payload.v2.PushJsonEventPayload.json: object expected, but got ' +
                  typeof object.json
              );
            }
            message.json = google$1.protobuf.Struct.fromObject(object.json);
          }
          if (object.jsonBody != null) {
            if (typeof object.jsonBody !== 'object') {
              throw new TypeError(
                '.event_payload.v2.PushJsonEventPayload.jsonBody: object expected, but got ' +
                  typeof object.jsonBody
              );
            }
            message.jsonBody = push_json$1.v1.PushJsonBody.fromObject(
              object.jsonBody
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_PUSH_JSON_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? push_json$1.v1.PushJsonEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : push_json$1.v1.PushJsonEventType[message.eventType]
                : message.eventType;
          }
          if (message.json != null && message.hasOwnProperty('json')) {
            object.json = google$1.protobuf.Struct.toObject(
              message.json,
              options
            );
            if (options.oneofs) {
              object.event = 'json';
            }
          }
          if (message.jsonBody != null && message.hasOwnProperty('jsonBody')) {
            object.jsonBody = push_json$1.v1.PushJsonBody.toObject(
              message.jsonBody,
              options
            );
            if (options.oneofs) {
              object.event = 'jsonBody';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.PushJsonEventPayload';
        }
      }

      PushJsonEventPayload.prototype.eventType = 0;
      PushJsonEventPayload.prototype.json = null;
      PushJsonEventPayload.prototype.jsonBody = null;

      return PushJsonEventPayload;
    })();

    v2.CustomerIoEventPayload = (() => {
      class CustomerIoEventPayload {
        get event() {
          for (const key of ['identify', 'track', 'page']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['identify', 'track', 'page']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomerIoEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.identify != null &&
            Object.hasOwnProperty.call(message, 'identify')
          ) {
            customerio$1.v1.Identify.encode(
              message.identify,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.track != null &&
            Object.hasOwnProperty.call(message, 'track')
          ) {
            customerio$1.v1.Track.encode(
              message.track,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            customerio$1.v1.Page.encode(
              message.page,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomerIoEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.identify = customerio$1.v1.Identify.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.track = customerio$1.v1.Track.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.page = customerio$1.v1.Page.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_payload.v2.CustomerIoEventPayload) {
            return object;
          }
          const message = new $root.event_payload.v2.CustomerIoEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_CUSTOMERIO_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'CUSTOMER_IO_IDENTIFY':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'CUSTOMER_IO_TRACK':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'CUSTOMER_IO_PAGE':
              case 3: {
                message.eventType = 3;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.identify != null) {
            if (typeof object.identify !== 'object') {
              throw new TypeError(
                '.event_payload.v2.CustomerIoEventPayload.identify: object expected, but got ' +
                  typeof object.identify
              );
            }
            message.identify = customerio$1.v1.Identify.fromObject(
              object.identify
            );
          }
          if (object.track != null) {
            if (typeof object.track !== 'object') {
              throw new TypeError(
                '.event_payload.v2.CustomerIoEventPayload.track: object expected, but got ' +
                  typeof object.track
              );
            }
            message.track = customerio$1.v1.Track.fromObject(object.track);
          }
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.event_payload.v2.CustomerIoEventPayload.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = customerio$1.v1.Page.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_CUSTOMERIO_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? customerio$1.v1.CustomerIoEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : customerio$1.v1.CustomerIoEventType[message.eventType]
                : message.eventType;
          }
          if (message.identify != null && message.hasOwnProperty('identify')) {
            object.identify = customerio$1.v1.Identify.toObject(
              message.identify,
              options
            );
            if (options.oneofs) {
              object.event = 'identify';
            }
          }
          if (message.track != null && message.hasOwnProperty('track')) {
            object.track = customerio$1.v1.Track.toObject(
              message.track,
              options
            );
            if (options.oneofs) {
              object.event = 'track';
            }
          }
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = customerio$1.v1.Page.toObject(message.page, options);
            if (options.oneofs) {
              object.event = 'page';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_payload.v2.CustomerIoEventPayload';
        }
      }

      CustomerIoEventPayload.prototype.eventType = 0;
      CustomerIoEventPayload.prototype.identify = null;
      CustomerIoEventPayload.prototype.track = null;
      CustomerIoEventPayload.prototype.page = null;

      return CustomerIoEventPayload;
    })();

    return v2;
  })();

  return event_payload;
})();

export const event_payload = $root.event_payload;
