import { Container } from '../../Container';
import { FC } from 'react';
import { Icon } from '../../Icon';
import { PanelProps } from './types';
import { Tooltip } from '../../Tooltip';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const Panel: FC<PanelProps> = ({ isPanelOpen, setIsPanelOpen }) => {
  const theme = useTheme();
  const panelTooltipText = isPanelOpen ? 'Hide panel' : 'Show panel';
  const panelIconName = isPanelOpen ? 'panel-left-solid' : 'panel-left';
  const panelIconStroke = isPanelOpen
    ? theme.colors.main.primary.darker
    : theme.colors.ui.dark;
  const handleTogglePanel = () => setIsPanelOpen(!isPanelOpen);

  return (
    <Tooltip
      tooltipText={panelTooltipText}
      tooltipDirection='bottom'
      arrowDirection='left'
    >
      <Container
        data-testid='panel'
        borderRadius={0.25}
        cursor='pointer'
        height='auto'
        hoverBackgroundColor={theme.colors.main.light.light}
        onClick={handleTogglePanel}
        p={0.125}
        width='auto'
      >
        <Icon
          name={panelIconName}
          size={1.125}
          stroke={panelIconStroke}
          strokeWidth={2}
        />
      </Container>
    </Tooltip>
  );
};

export const MemoizedPanel = memo(Panel);
