import { FC } from 'react';
import { ImageProps } from './type';
import { StyledImage } from './Image.css';

export const Image: FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  radius,
  objectFit,
  hasVisibleBorder = false,
}) => {
  return (
    <StyledImage
      src={src}
      alt={alt}
      width={width}
      height={height}
      radius={radius}
      objectFit={objectFit}
      hasVisibleBorder={hasVisibleBorder}
    />
  );
};
