import Long from 'long';
import _ from 'lodash';
import { Button } from '../../../../../../Button';
import { ConstantValueFormProps, ConstantValueProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Text } from '../../../../../../Typography';
import { Textarea } from '../../../../../../Textarea';
import { formatConstantValue } from './formatConstantValue';
import { google as googlechecked } from '@morf/proto/checked_ts_proto';
import { google } from '@morf/proto/cel_values_ts_proto';
import { useForm } from 'react-hook-form';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const ConstantValue: FC<ConstantValueProps> = ({
  selectedParam,
  selectedSource,
  onClick,
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ConstantValueFormProps>({ mode: 'onChange' });

  const type =
    selectedParam?.static?.fieldType?.primitiveType ||
    selectedParam?.dynamic?.elementFieldType?.mapType?.keyType?.primitive ||
    selectedParam?.dynamic?.elementFieldType?.listType?.elemType?.primitiveType;

  const constantValue = formatConstantValue(selectedSource);

  const trueValues = ['true', '1', 't'];
  const falseValues = ['false', '0', 'f'];

  const isBoolean = (constantValue: string): boolean => {
    const lowerCaseVal = constantValue.toLowerCase();
    return (
      trueValues.includes(lowerCaseVal) || falseValues.includes(lowerCaseVal)
    );
  };

  const isDouble = (constantValue: string): boolean =>
    /^-?\d+(\.\d+)?$/.test(constantValue);

  const isInteger = (constantValue: string): boolean =>
    /^-?\d+$/.test(constantValue);

  const validateConstantValue = (constantValue: string): boolean | string => {
    if (constantValue.trim() === '') {
      return 'Required';
    }

    switch (type) {
      case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.BOOL:
        return (
          isBoolean(constantValue) ||
          `Invalid boolean value - Expected 'true', 'false', '1', or '0'`
        );
      case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.DOUBLE:
        return isDouble(constantValue) || 'Invalid double value';
      case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.INT64:
      case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.UINT64:
        return isInteger(constantValue) || 'Invalid integer value';
      case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.STRING:
        return true;
      default:
        return true;
    }
  };

  const onSubmit = ({ constantValue }: ConstantValueFormProps) => {
    let value;

    if (constantValue) {
      switch (type) {
        case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.BOOL:
          const isTrue = _.includes(
            trueValues.map((val) => val.toLowerCase()),
            constantValue.toLowerCase()
          );

          value = new google.api.expr.v1alpha1.Value({
            boolValue: isTrue ? true : false,
          });
          break;
        case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.DOUBLE:
          value = new google.api.expr.v1alpha1.Value({
            doubleValue: parseFloat(constantValue),
          });
          break;
        case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.INT64:
          value = new google.api.expr.v1alpha1.Value({
            int64Value: new Long(parseInt(constantValue)),
          });
          break;
        case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.STRING:
          value = new google.api.expr.v1alpha1.Value({
            stringValue: constantValue,
          });
          break;
        case googlechecked.api.expr.v1alpha1.Type.PrimitiveType.UINT64:
          value = new google.api.expr.v1alpha1.Value({
            uint64Value: new Long(parseInt(constantValue)),
          });
          break;
        default:
          value = new google.api.expr.v1alpha1.Value({
            stringValue: constantValue,
          });
      }
    }

    if (value) {
      onClick(
        new workflow_parameters.v1.DestinationActionParameterSource({
          constantValue: value,
        })
      );
    }
  };

  return (
    <Flexbox
      data-testid='constant-value'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      p={0.75}
      gap={0.5}
    >
      <Textarea
        {...register('constantValue', {
          validate: validateConstantValue,
        })}
        placeholder='Add value'
        defaultValue={constantValue}
        error={!!errors.constantValue}
        rows={10}
      />

      {errors.constantValue && (
        <Text tag='p3' color={theme.colors.support.red.darkest}>
          {errors.constantValue.message}
        </Text>
      )}

      <Button
        variant='primary'
        onClick={handleSubmit(onSubmit)}
        isDisabled={!isValid}
        width='100%'
      >
        <Text tag='h5' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedConstantValue = memo(ConstantValue);
