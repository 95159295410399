import _ from 'lodash';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { SkeletonLoadable } from '../../Skeleton';

const EventRowLoading: FC = () => {
  return (
    <Flexbox
      data-testid='event-row-loading'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      borderRadius={0.25}
      height='auto'
      cursor='default'
      width='16.5rem'
      pl={0.25}
      pr={0.5}
      py={0.75}
      gap={0}
    >
      <SkeletonLoadable isLoading width='1rem' height='1rem' />

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        pl={0.25}
        gap={0}
      >
        <SkeletonLoadable isLoading width='11.5rem' height='1rem' />
        <SkeletonLoadable isLoading width='11.5rem' height='0.5rem' />
      </Flexbox>

      <SkeletonLoadable isLoading width='1rem' height='1rem' />
    </Flexbox>
  );
};

export const MemoizedEventRowLoading = memo(EventRowLoading);
