import { Dropdown } from '../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { ProfileLookupMultipleProfilesFoundProps } from './types';
import { RequiredIndicator } from '../../../RequiredIndicator';
import { Text } from '../../../Typography';
import { capitalizeFirstLetter } from '../../../Helpers/capitalizeFirstLetter';
import { formatEnumToOptions } from '../../../Helpers/formatEnumToOptions';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useTheme } from '@morf/theming';

const ProfileLookupMultipleProfilesFound: FC<
  ProfileLookupMultipleProfilesFoundProps
> = ({ isReadOnly, lookup, onMultipleProfilesActionChange }) => {
  const theme = useTheme();

  const multipleProfilesFoundActions = formatEnumToOptions(
    profiles.v1.MultipleProfilesAction
  ).map((action) => ({
    label: capitalizeFirstLetter(
      action.label.replace('Multiple profiles action', '').trim()
    ),
    value: action.value,
  }));

  const multipleProfilesFoundAction =
    lookup?.index?.email?.multipleProfilesAction ||
    lookup?.index?.phone?.multipleProfilesAction;

  const selectedMultipleProfilesActionAsOption =
    multipleProfilesFoundActions.filter(
      (action) => action.value === multipleProfilesFoundAction?.toString()
    );

  return (
    <Flexbox
      data-testid='profile-lookup-multiple-profiles-found'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='p2' whiteSpace='nowrap'>
            If multiple profiles found
          </Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select the workflow action.
          </Text>
        </Flexbox>

        <Dropdown
          defaultOptions={selectedMultipleProfilesActionAsOption}
          options={multipleProfilesFoundActions}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onMultipleProfilesActionChange(selectedOptionNumber);
          }}
          height='2.25rem'
          readOnly={isReadOnly}
          clearable
        />
      </Flexbox>

      {!multipleProfilesFoundAction && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedProfileLookupMultipleProfilesFound = memo(
  ProfileLookupMultipleProfilesFound
);
