import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { newNodeData } from './types';

const defaultConvertToPatient =
  new destinationactions.morf.v1.ConvertProfileToPatient({
    mergeIfEmailAddressAlreadyExists: false,
    mergeIfPhoneNumberAlreadyExists: false,
  });

export const addProfileConvertNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: {
      type: 'morfV1',
      retryPolicy: defaultRetryPolicy,
      parameterMissingPolicy: defaultParameterMissingPolicy,
      morfV1: {
        convertProfileToPatient: defaultConvertToPatient,
      },
    },
    integrationIconName: 'morf',
    isBranchNode: isBranch,
    name: 'Convert Profile to Patient',
    type: NodeType.ProfileConvertNode,
  };
};
