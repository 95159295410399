import { FC, useState } from 'react';
import { Timeslot } from './Timeslot';
import { Slot, TimeslotsProps } from './types';
import { FlexItem, Flexbox } from '../Flexbox';
import { Button } from '../Button';
import { Text } from '../Typography';
import { useResizeHandler } from '../../../apps/questions/lib/hooks/useResizeHandler';
import { useResize } from '../Hooks/useResize';
import { useTheme } from '@morf/theming';

export const Timeslots: FC<TimeslotsProps> = ({
  slots,
  columns,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
}) => {
  const gap = 0.5;
  const spacing = (columns - 1) * gap;
  const columnWidth = `calc((100% - ${spacing}rem) / ${columns})`;
  const theme = useTheme();

  const responsiveSlots = useResize({
    slots: theme.maxNrOfSlots,
  });
  const maxNrOfSlots = parseInt(responsiveSlots.slots);

  const [expanded, setExpanded] = useState(false);

  useResizeHandler([expanded]);

  return (
    <Flexbox
      justifyContent='flex-start'
      alignItems='center'
      flexWrap='wrap'
      gap={gap}
    >
      {(expanded ? slots : slots.slice(0, maxNrOfSlots)).map((slot, index) => {
        return (
          <FlexItem key={index} flexBasis={columnWidth}>
            <Timeslot
              slot={slot}
              selectedSlot={selectedSlot}
              onSelectSlot={onSelectSlot}
              onConfirmSlot={onConfirmSlot}
            />
          </FlexItem>
        );
      })}
      {!expanded && slots.length > maxNrOfSlots && (
        <Button
          variant='tertiary'
          width={columnWidth}
          onClick={() => setExpanded(true)}
        >
          <Text tag='h5' color='inherit'>
            Show more
          </Text>
        </Button>
      )}
      {expanded && (
        <Button
          variant='tertiary'
          width={columnWidth}
          onClick={() => setExpanded(false)}
        >
          <Text tag='h5' color='inherit'>
            Show less
          </Text>
        </Button>
      )}
    </Flexbox>
  );
};
