import { ParamSourceConfigProps } from '../../../types';
import { extractAction } from '../../../CustomEdge/AddEdge/extractAction';
import { formatDestinationActionParameter } from './formatDestinationActionParameter';
import { formatDestinationActionParameterSource } from '../../../../Helpers/formatDestinationActionParameterSource';
import { google } from '@morf/proto/empty_ts_proto';
import { sortKeys } from './sortKeys';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const formatDestinationAction = (
  configuration:
    | workflows.v1.WorkflowDestinationAction
    | workflows.v1.WorkflowFetchAction
    | null
    | undefined,
  isReadOnly: boolean | undefined
): ParamSourceConfigProps[] => {
  if (!configuration) {
    return [];
  }

  const { actionValue } = extractAction(configuration);

  return actionValue.params.map(
    (param: workflow_parameters.v1.DestinationActionParameter) => {
      const matchingStaticConfig: workflow_parameters.v1.StaticDestinationActionParameterConfig =
        actionValue.configs.find(
          (config: workflow_parameters.v1.DestinationActionParameterConfig) =>
            config.staticConfig?.parameter?.fieldName ===
            param?.static?.fieldName
        )?.staticConfig;

      const matchingDynamicConfig: workflow_parameters.v1.DynamicDestinationActionParameterConfig =
        actionValue.configs.find(
          (config: workflow_parameters.v1.DestinationActionParameterConfig) =>
            config.dynamicConfig?.parameter?.fieldName ===
            param?.dynamic?.fieldName
        )?.dynamicConfig;

      const subRows =
        param.dynamic && matchingDynamicConfig
          ? matchingDynamicConfig.mapSource
            ? Object.entries(
                matchingDynamicConfig.mapSource.elementSources as Record<
                  string,
                  workflow_parameters.v1.DestinationActionParameterSource
                >
              )
                .sort(([keyA], [keyB]) => sortKeys(keyA, keyB))
                .map(([key, value]) => {
                  const keySource =
                    matchingDynamicConfig.mapSource?.keySources &&
                    matchingDynamicConfig.mapSource?.keySources[key];
                  return {
                    param: keySource
                      ? formatDestinationActionParameterSource(
                          keySource,
                          isReadOnly,
                          false
                        )
                      : {
                          fieldName: matchingDynamicConfig.mapSource
                            ?.keyContext?.[key]
                            ? matchingDynamicConfig.mapSource.keyContext[key]
                                .objectTypeId?.label ||
                              matchingDynamicConfig.mapSource.keyContext[key]
                                .objectTypeId?.id
                            : key,
                          isReadOnly: isReadOnly,
                          tagType: matchingDynamicConfig.mapSource?.keyContext
                            ? 'objectTypeId'
                            : 'constant',
                        },
                    source: formatDestinationActionParameterSource(
                      value,
                      isReadOnly,
                      false
                    ),
                  };
                })
            : matchingDynamicConfig.listSource
            ? matchingDynamicConfig.listSource.sources.map((value) => ({
                param: {
                  fieldName: ``,
                },
                source: formatDestinationActionParameterSource(
                  value,
                  isReadOnly,
                  false
                ),
              }))
            : []
          : [];

      return {
        param: formatDestinationActionParameter(param, isReadOnly),
        source: subRows.length
          ? null
          : formatDestinationActionParameterSource(
              matchingStaticConfig?.source ||
                (matchingDynamicConfig?.arraySource &&
                  new workflow_parameters.v1.DestinationActionParameterSource({
                    ...matchingDynamicConfig?.arraySource,
                  })) ||
                (matchingDynamicConfig?.objectSource &&
                  new workflow_parameters.v1.DestinationActionParameterSource({
                    ...matchingDynamicConfig?.objectSource,
                  })) ||
                new workflow_parameters.v1.DestinationActionParameterSource({
                  elidedValue: new google.protobuf.Empty(),
                }),
              isReadOnly,
              param.static?.isRequired || param.dynamic?.isRequired
            ),
        subRows: subRows,
      };
    }
  );
};
