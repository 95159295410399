import { FC, useEffect } from 'react';
import moment from 'moment';
import { useTheme } from '@morf/theming';
import { getDates } from './getDates';
import { getEnrichedDates } from './getEnrichedDates';
import { goToPrevMonth } from './gotToPrevMonth';
import { goToNextMonth } from './goToNextMonth';
import { isDateInCurrentMonth } from './isDateInCurrentMonth';
import { DatePickerProps } from './types';
import { Icon } from '../Icon';
import { FlexItem, Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Loader } from '../Loader';
import { StyledWeek } from './DatePicker.css';
import { Date } from './Date/Date';
import { useResize } from '../Hooks/useResize';

export const DatePicker: FC<DatePickerProps> = ({
  startDate,
  endDate,
  selectedDate,
  highlightedDates,
  onSelectedDate,
  onMonthChange,
  multiSelect = false,
  closeDatePicker,
  isLoading,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (highlightedDates.length > 0) {
      if (!selectedDate) {
        onSelectedDate(highlightedDates[0]);
      }
    } else if (!isLoading && isDateInCurrentMonth(startDate)) {
      const nextMonth = goToNextMonth(startDate);
      onMonthChange(nextMonth);
    }
  }, [
    isLoading,
    highlightedDates,
    selectedDate,
    startDate,
    onSelectedDate,
    onMonthChange,
  ]);

  const dates = getDates(startDate, endDate, multiSelect);
  const enrichedDates = getEnrichedDates({
    startDate,
    selectedDate,
    highlightedDates,
    dates,
  });

  const dayAbbreviations = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const columnSize = 'calc(100%/7)';
  const selectedWeek: string[] = [];

  const handlePrevMonthClick = () => {
    const prevMonth = goToPrevMonth(startDate);
    onMonthChange(prevMonth);
  };

  const handleNextMonthClick = () => {
    const nextMonth = goToNextMonth(startDate);
    onMonthChange(nextMonth);
  };

  const handleDateSelection = (date: string) => {
    onSelectedDate(date);
    if (closeDatePicker) closeDatePicker();
  };

  const responsiveDatePicker = useResize({
    p: {
      xxl: 1.25,
      xl: 1.25,
      lg: 1.25,
      md: 0.75,
      sm: 0.75,
      xs: 0.5,
    },
  });

  return (
    <Flexbox
      data-testid='date-picker'
      backgroundColor='transparent'
      direction='column'
      alignItems='center'
      height='100%'
      minWidth='16rem'
      gap={1}
      {...responsiveDatePicker}
    >
      {isLoading ? (
        <Loader height='16rem' />
      ) : (
        <>
          <Flexbox px={0.5}>
            {isDateInCurrentMonth(startDate) ? (
              <Icon
                name='chevron-left'
                cursor='default'
                onClick={undefined}
                stroke={theme.colors.text.disabled}
                size={1.25}
              />
            ) : (
              <Icon
                name='chevron-left'
                cursor='pointer'
                onClick={handlePrevMonthClick}
                stroke={theme.colors.main.primary.darker}
                size={1.25}
              />
            )}
            <Text tag='h4' color={theme.colors.text.body}>
              {moment(startDate).format('MMMM')}
            </Text>
            <Icon
              name='chevron-right'
              cursor='pointer'
              onClick={handleNextMonthClick}
              stroke={theme.colors.main.primary.darker}
              size={1.25}
            />
          </Flexbox>
          <Flexbox>
            {dayAbbreviations.map((day, index) => (
              <FlexItem key={index} flexBasis={columnSize}>
                <Text tag='p2' align='center' color={theme.colors.text.muted}>
                  {day}
                </Text>
              </FlexItem>
            ))}
          </Flexbox>
          <Flexbox
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='center'
            gap={0}
          >
            {enrichedDates.map((day, index) => {
              if (day.isSelectedWeek) selectedWeek.push(day.date);
              return (
                <FlexItem key={index} flexBasis={columnSize} alignSelf='center'>
                  {multiSelect ? (
                    <StyledWeek
                      isSelectedWeek={day.isSelectedWeek}
                      isFirstDate={selectedWeek[0] === day.date}
                      isLastDate={selectedWeek[6] === day.date}
                    >
                      <Date
                        date={day.date}
                        isActive={day.isSelectedWeek && day.isAvailable}
                        isDisabled={!day.isAvailable}
                        isHidden={false}
                        onClick={() => handleDateSelection(day.date)}
                      />
                    </StyledWeek>
                  ) : (
                    <Date
                      date={day.date}
                      isActive={day.isSelectedDate}
                      isDisabled={!day.isAvailable || !day.isCurrentMonth}
                      isHidden={!day.isCurrentMonth}
                      onClick={() =>
                        day.isCurrentMonth &&
                        day.isAvailable &&
                        handleDateSelection(day.date)
                      }
                    />
                  )}
                </FlexItem>
              );
            })}
          </Flexbox>
        </>
      )}
    </Flexbox>
  );
};
