import Cookies from 'js-cookie';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import { Layout } from './Layout';
import { LocalStorage, NotFound } from '@morf/ui';
import { MORF_USER, STYTCH_SESSION_JWT } from '@morf/constants';
import { MorfUser, PrivateRouteProps } from './types';
import { decodeStringBase64 } from '../helpers/decodeStringBase64';
import { isAccessGranted } from './isAccessGranted';
import { useRouter } from 'next/router';

const PrivateRoute: FC<PrivateRouteProps> = ({ children, organizationId }) => {
  const router = useRouter();
  const { pathname, asPath } = router;

  const [morfUser, setMorfUser] = useState<MorfUser>();

  const stytch_session_jwt = Cookies.get(STYTCH_SESSION_JWT);
  const encodedUser = LocalStorage.get<string>(MORF_USER);

  useEffect(() => {
    encodedUser && setMorfUser(JSON.parse(decodeStringBase64(encodedUser)));

    if (!stytch_session_jwt) {
      const pathname = morfUser ? '/sessionExpired' : '/login';
      router.push({
        pathname,
        query: {
          from: asPath,
        },
      });
    }
  }, [pathname]);

  return (
    <Fragment>
      {stytch_session_jwt && morfUser && (
        <Layout
          organizationPermissions={morfUser.organizationPermissions}
          userImage={morfUser.profilePictureUrl}
          organizationId={organizationId}
        >
          {isAccessGranted(morfUser, organizationId, pathname) ? (
            children
          ) : (
            <NotFound />
          )}
        </Layout>
      )}
    </Fragment>
  );
};

export const MemoizedPrivateRoute = memo(PrivateRoute);
