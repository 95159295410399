import { FC, memo } from 'react';
import { MenuProps } from './types';
import { Container } from '../Container';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';

const Menu: FC<MenuProps> = ({ children, items, ref, ...props }) => {
  const theme = useTheme();
  return (
    <Container
      ref={ref}
      data-testid='menu'
      position={props.position}
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
      backgroundColor={theme.colors.ui.card}
      borderRadius={1}
      width={props.width}
      shadow='base'
      borderType='border'
      minWidth='14rem'
      height='auto'
      maxHeight='fit-content'
      cursor='default'
      zIndex={'var(--menu-z-index)'}
    >
      {children && <Container>{children}</Container>}
      {items && !children && (
        <Flexbox
          direction='column'
          justifyContent='center'
          height='auto'
          gap={0}
          p={0.5}
        >
          {items.map((item, index) => (
            <Flexbox
              key={index}
              onClick={item.onClick}
              justifyContent='flex-start'
              alignItems='center'
              cursor='pointer'
              hoverBackgroundColor={theme.colors.ui.body}
              borderRadius={0.5}
              p={0.5}
            >
              {item.icon && <Icon {...item.icon} />}
              <Text tag='p2'>{item.label}</Text>
            </Flexbox>
          ))}
        </Flexbox>
      )}
    </Container>
  );
};

export const MemoizedMenu = memo(Menu);
