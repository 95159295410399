import { Dropdown } from '../../../../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Text } from '../../../../../../Typography';
import { TriggerNodeProps } from '../../../types';
import { formatEnumToOptions } from '../../../../../../Helpers/formatEnumToOptions';
import { labelMap } from '../constants';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const TriggerConflictResolution: FC<TriggerNodeProps> = ({ data }) => {
  const theme = useTheme();
  const { configuration, onUpdateTrigger, isReadOnly } = data.value;

  const uniquenessConflictResolution =
    configuration?.uniquenessConflictResolution;

  const workflowUniquenessConflictResolutions = formatEnumToOptions(
    workflows.v1.WorkflowUniquenessConflictResolution.ConflictResolution
  ).map((option) =>
    labelMap[option.label]
      ? { ...option, label: labelMap[option.label] }
      : option
  );

  const selectedWworkflowUniquenessConflictResolution =
    workflowUniquenessConflictResolutions.filter(
      (workflowUniquenessConflictResolution) =>
        uniquenessConflictResolution?.conflictResolution?.toString() ===
        workflowUniquenessConflictResolution.value
    );

  const handleNodeSave = (updatedConflictResolution: number) => {
    onUpdateTrigger &&
      onUpdateTrigger({
        trigger: new workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger({
          triggerEventTypes: configuration?.triggerEvents,
          triggerExtraCelExpression:
            configuration?.extraFilter?.celExpressionStr,
          triggerObjectCategory: configuration?.objectCategory,
          triggerObjectTypeFilters: configuration?.objectTypeFilters,
          uniquenessConflictResolution: {
            conflictResolution: updatedConflictResolution,
            scope:
              workflows.v1.WorkflowUniquenessConflictResolution.ConflictScope
                .WORKFLOW,
          },
        }),
      });
  };

  return (
    <Flexbox
      data-testid='trigger-conflict-resolution'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
    >
      <Flexbox
        direction='column'
        alignItems='flex-start'
        justifyContent='center'
        height='auto'
        gap={0}
      >
        <Text tag='p2' whiteSpace='nowrap'>
          If a new workflow execution is triggered
        </Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Select what should happen
        </Text>
      </Flexbox>
      <Dropdown
        defaultOptions={selectedWworkflowUniquenessConflictResolution}
        options={workflowUniquenessConflictResolutions}
        onChange={(selectedOptions) => {
          const selectedOption = selectedOptions[0];
          const selectedOptionValue = selectedOption.value;
          const selectedOptionNumber = parseInt(selectedOptionValue);
          handleNodeSave(selectedOptionNumber);
        }}
        height='2.25rem'
        readOnly={isReadOnly}
      />
    </Flexbox>
  );
};

export const MemoizedTriggerConflictResolution = memo(
  TriggerConflictResolution
);
