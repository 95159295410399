import _ from 'lodash';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { SkeletonLoadable } from '../../Skeleton';

const WorkflowExecutionRowLoading: FC = () => {
  return (
    <Flexbox
      data-testid='workflow-execution-row-loading'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      borderRadius={0.25}
      height='auto'
      width='16.5rem'
      cursor='default'
      px={0.5}
      py={0.75}
      gap={0.5}
    >
      <SkeletonLoadable isLoading width='1rem' height='1rem' />

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <SkeletonLoadable isLoading width='11.5rem' height='1rem' />
          <SkeletonLoadable isLoading width='1rem' height='1rem' />
        </Flexbox>
        <SkeletonLoadable isLoading width='14rem' height='0.5rem' />
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionRowLoading = memo(
  WorkflowExecutionRowLoading
);
