import moment from 'moment';

export const getDates = (
  startDateString: string,
  endDateString: string,
  multiSelect: boolean
): string[] => {
  const dates = [];

  let startDate = moment(startDateString);
  const endDate = moment(endDateString);

  const monthStartDay = startDate.day();
  startDate = startDate.subtract(monthStartDay, 'd');

  while (
    (multiSelect && dates.length < 35) ||
    (!multiSelect && startDate.isSameOrBefore(endDate))
  ) {
    dates.push(startDate.format());
    startDate = startDate.add(1, 'd');
  }
  return dates;
};
