import { css, styled } from '@morf/theming';
import { TextProps } from './types';

const textStyles = css<TextProps>`
  ${({ align, color, decoration, theme, weight, whiteSpace, wordWrap }) => css`
    color: ${color || theme.colors.text.body};
    text-align: ${align};
    text-decoration: ${decoration};
    white-space: ${whiteSpace};
    font-weight: ${weight};
    word-wrap: ${wordWrap};
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    max-width: inherit;
  `}
`;

const H1 = styled.h1<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h1.size};
  line-height: ${({ theme }) => theme.fontTypes.h1.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h1.weight};
  font-family: ${({ theme }) => theme.fontTypes.h1.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h1.letterSpacing};
  ${textStyles}
`;

const H2 = styled.h2<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h2.size};
  line-height: ${({ theme }) => theme.fontTypes.h2.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h2.weight};
  font-family: ${({ theme }) => theme.fontTypes.h2.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h2.letterSpacing};
  ${textStyles}
`;

const H3 = styled.h3<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h3.size};
  line-height: ${({ theme }) => theme.fontTypes.h3.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h3.weight};
  font-family: ${({ theme }) => theme.fontTypes.h3.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h3.letterSpacing};
  ${textStyles}
`;

const H4 = styled.h4<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h4.size};
  line-height: ${({ theme }) => theme.fontTypes.h4.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h4.weight};
  font-family: ${({ theme }) => theme.fontTypes.h4.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h4.letterSpacing};
  ${textStyles}
`;

const H5 = styled.h5<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h5.size};
  line-height: ${({ theme }) => theme.fontTypes.h5.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h5.weight};
  font-family: ${({ theme }) => theme.fontTypes.h5.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h5.letterSpacing};
  ${textStyles}
`;

const H6 = styled.h6<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h6.size};
  line-height: ${({ theme }) => theme.fontTypes.h6.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h6.weight};
  font-family: ${({ theme }) => theme.fontTypes.h6.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h6.letterSpacing};
  ${textStyles}
`;

const H7 = styled.h6<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.h7.size};
  line-height: ${({ theme }) => theme.fontTypes.h7.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.h7.weight};
  font-family: ${({ theme }) => theme.fontTypes.h7.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.h7.letterSpacing};
  ${textStyles}
`;

const P1 = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.p1.size};
  line-height: ${({ theme }) => theme.fontTypes.p1.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.p1.weight};
  font-family: ${({ theme }) => theme.fontTypes.p1.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.p1.letterSpacing};
  ${textStyles}
`;

const P2 = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.p2.size};
  line-height: ${({ theme }) => theme.fontTypes.p2.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.p2.weight};
  font-family: ${({ theme }) => theme.fontTypes.p2.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.p2.letterSpacing};
  ${textStyles}
`;

const P3 = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.p3.size};
  line-height: ${({ theme }) => theme.fontTypes.p3.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.p3.weight};
  font-family: ${({ theme }) => theme.fontTypes.p3.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.p3.letterSpacing};
  ${textStyles}
`;

const P4 = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.p4.size};
  line-height: ${({ theme }) => theme.fontTypes.p4.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.p4.weight};
  font-family: ${({ theme }) => theme.fontTypes.p4.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.p4.letterSpacing};
  ${textStyles}
`;

const Subtitle = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.subtitle.size};
  line-height: ${({ theme }) => theme.fontTypes.subtitle.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.subtitle.weight};
  font-family: ${({ theme }) => theme.fontTypes.subtitle.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.subtitle.letterSpacing};
  ${textStyles}
`;

const Longform = styled.p<TextProps>`
  font-size: ${({ theme }) => theme.fontTypes.longform.size};
  line-height: ${({ theme }) => theme.fontTypes.longform.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.longform.weight};
  font-family: ${({ theme }) => theme.fontTypes.longform.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.longform.letterSpacing};
  ${textStyles}
`;

const Link = styled.p<TextProps>`
  text-decoration: ${({ theme }) => theme.fontTypes.link.decoration};
  font-size: ${({ theme }) => theme.fontTypes.link.size};
  line-height: ${({ theme }) => theme.fontTypes.link.lineHeight};
  font-weight: ${({ theme }) => theme.fontTypes.link.weight};
  font-family: ${({ theme }) => theme.fontTypes.link.family};
  letter-spacing: ${({ theme }) => theme.fontTypes.link.letterSpacing};
  ${textStyles}
`;

export const tagMap: Record<string, React.ElementType> = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  h7: H7,
  p1: P1,
  p2: P2,
  p3: P3,
  p4: P4,
  subtitle: Subtitle,
  longform: Longform,
  link: Link,
};
