import _ from 'lodash';
import { FilterOption } from '../../Filter/FilterDropdown/FilterOption/types';
import { defaultErrorClasses } from './ClassFilter';
import { formatClasses } from './formatClasses';

export const getMatchingClassesOptions = (
  classes: string[] | undefined
): FilterOption[] => {
  if (!classes) return [];
  const matchingClasses = defaultErrorClasses.filter((errorClass) =>
    classes.includes(_.toString(errorClass))
  );
  return formatClasses(matchingClasses);
};
