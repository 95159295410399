import { css, styled } from '@morf/theming';
import { Container } from '../Container';

export const StyledIntegration = styled(Container)<{ index: number }>`
  ${(props) =>
    props.index !== 0 &&
    css`
      margin-left: -0.5rem;
    `}
`;
