import { styled } from '@morf/theming';
import { ContainerProps } from '../../../../Container';
import { borderStyles } from '../../../../Container/Container.css';

export const StyledCell = styled.td<{
  borderType: ContainerProps['borderType'];
  cellPt: ContainerProps['pt'];
  cellPb: ContainerProps['pb'];
  cellPl: ContainerProps['pl'];
  cellPr: ContainerProps['pr'];
  isFirstRow: boolean;
}>`
  ${borderStyles}
  padding-top: ${(props) => `${props.cellPt}rem`};
  padding-bottom: ${(props) => `${props.cellPb}rem`};
  padding-left: ${(props) => `${props.cellPl}rem`};
  padding-right: ${(props) => `${props.cellPr}rem`};

  ${({ isFirstRow }) => isFirstRow && ` border-top: none;`};
`;
