import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { useTheme } from '@morf/theming';
import { DropdownInputIconProps } from './types';

const DropdownInputIcon: FC<DropdownInputIconProps> = ({
  iconName = 'chevron',
  iconSize = 1.25,
  showOptions,
}) => {
  const theme = useTheme();
  const fullIconName = showOptions ? `${iconName}-up` : `${iconName}-down`;
  const fill = iconName === 'carrot' ? theme.colors.text.muted : 'none';

  return (
    <Flexbox
      width='auto'
      height='auto'
      justifyContent='flex-start'
      alignItems='center'
    >
      <Icon
        name={fullIconName}
        stroke={theme.colors.ui.dark}
        strokeWidth={1.75}
        fill={fill}
        size={iconSize}
      />
    </Flexbox>
  );
};

export const MemoizedDropdownInputIcon = memo(DropdownInputIcon);
