export const ArrowUp = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18'
    />
  );
};

export const ArrowDown = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3'
    />
  );
};

export const ArrowLeft = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'
    />
  );
};

export const ArrowLeftCircle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
    />
  );
};

export const ArrowRight = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3'
    />
  );
};

export const ArrowRightLeft = () => {
  return (
    <path
      d='M8 21L3.5 16.5M3.5 16.5L8 12M3.5 16.5H17M17 3L21.5 7.5M21.5 7.5L17 12M21.5 7.5L8 7.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const ArrowPath = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99'
    />
  );
};

export const ArrowPathSquared = () => {
  return (
    <path d='M20 12C20 10.7681 19.9536 9.54699 19.8624 8.3384C19.7128 6.35425 18.1458 4.78724 16.1616 4.63757C14.953 4.54641 13.7319 4.5 12.5 4.5C11.2681 4.5 10.047 4.54641 8.8384 4.63757C6.85425 4.78724 5.28724 6.35425 5.13757 8.3384C5.12097 8.55852 5.10585 8.77906 5.09222 9M20 12L23 9M20 12L17 9M5 12C5 13.2319 5.04641 14.453 5.13757 15.6616C5.28724 17.6458 6.85425 19.2128 8.8384 19.3624C10.047 19.4536 11.2681 19.5 12.5 19.5C13.7319 19.5 14.953 19.4536 16.1616 19.3624C18.1458 19.2128 19.7128 17.6458 19.8624 15.6616C19.879 15.4415 19.8941 15.2209 19.9078 15M5 12L8 15M5 12L2 15' />
  );
};

export const ArrowOut = () => {
  return (
    <path
      d='M14 6H5.75C4.50736 6 3.5 7.00736 3.5 8.25V18.75C3.5 19.9926 4.50736 21 5.75 21H16.25C17.4926 21 18.5 19.9926 18.5 18.75V10.5M8 16.5L21.5 3M21.5 3L16.25 3M21.5 3V8.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const ArrowsFlowRight = () => {
  return (
    <>
      <g clipPath='url(#clip0_819_2269)'>
        <path
          d='M18.5 16.5L16.25 14.25M18.5 16.5L16.25 18.75M18.5 16.5H15.75C13.5409 16.5 11.75 14.7091 11.75 12.5V11.25C11.75 9.04086 9.95914 7.25 7.75 7.25H5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.5 7.25L7 7.25M20.5 7.25L18.25 9.5M20.5 7.25L18.25 5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_819_2269'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </>
  );
};

export const ArrowDownload = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3'
    />
  );
};
