import { styled } from '@morf/theming';
import { TableRowStyling } from './types';

export const StyledRow = styled.tr<
  {
    isSelected: boolean;
    isHovered: boolean;
    isClickable: boolean;
  } & TableRowStyling
>`
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  background-color: ${(props) => props.cellBackgroundColor};

  ${(props) =>
    props.isHovered &&
    `
      background-color: ${props.cellHoveredBackgroundColor};
      border-color: ${props.cellHoveredBorderColor};
  `}

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.cellSelectedBackgroundColor};
    border-color: ${props.cellSelectedBorderColor};
  `}
`;
