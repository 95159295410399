'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { intercom as intercom$1 } from '../../intercom_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from '../../workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.intercom = (() => {
    const intercom = {};
    intercom.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of [
              'sendEvent',
              'createOrUpdateContact',
              'searchContacts',
              'archiveContact',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'sendEvent',
              'createOrUpdateContact',
              'searchContacts',
              'archiveContact',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sendEvent != null &&
              Object.hasOwnProperty.call(message, 'sendEvent')
            ) {
              $root.fetchactions.intercom.v1.SendEvent.encode(
                message.sendEvent,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.createOrUpdateContact != null &&
              Object.hasOwnProperty.call(message, 'createOrUpdateContact')
            ) {
              $root.fetchactions.intercom.v1.CreateOrUpdateContact.encode(
                message.createOrUpdateContact,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.searchContacts != null &&
              Object.hasOwnProperty.call(message, 'searchContacts')
            ) {
              $root.fetchactions.intercom.v1.SearchContacts.encode(
                message.searchContacts,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.archiveContact != null &&
              Object.hasOwnProperty.call(message, 'archiveContact')
            ) {
              $root.fetchactions.intercom.v1.ArchiveContact.encode(
                message.archiveContact,
                writer.uint32(34).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sendEvent =
                    $root.fetchactions.intercom.v1.SendEvent.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.createOrUpdateContact =
                    $root.fetchactions.intercom.v1.CreateOrUpdateContact.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.searchContacts =
                    $root.fetchactions.intercom.v1.SearchContacts.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.archiveContact =
                    $root.fetchactions.intercom.v1.ArchiveContact.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intercom.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.intercom.v1.FetchAction();
            if (object.sendEvent != null) {
              if (typeof object.sendEvent !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.FetchAction.sendEvent: object expected, but got ' +
                    typeof object.sendEvent
                );
              }
              message.sendEvent =
                $root.fetchactions.intercom.v1.SendEvent.fromObject(
                  object.sendEvent
                );
            }
            if (object.createOrUpdateContact != null) {
              if (typeof object.createOrUpdateContact !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.FetchAction.createOrUpdateContact: object expected, but got ' +
                    typeof object.createOrUpdateContact
                );
              }
              message.createOrUpdateContact =
                $root.fetchactions.intercom.v1.CreateOrUpdateContact.fromObject(
                  object.createOrUpdateContact
                );
            }
            if (object.searchContacts != null) {
              if (typeof object.searchContacts !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.FetchAction.searchContacts: object expected, but got ' +
                    typeof object.searchContacts
                );
              }
              message.searchContacts =
                $root.fetchactions.intercom.v1.SearchContacts.fromObject(
                  object.searchContacts
                );
            }
            if (object.archiveContact != null) {
              if (typeof object.archiveContact !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.FetchAction.archiveContact: object expected, but got ' +
                    typeof object.archiveContact
                );
              }
              message.archiveContact =
                $root.fetchactions.intercom.v1.ArchiveContact.fromObject(
                  object.archiveContact
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.sendEvent != null &&
              message.hasOwnProperty('sendEvent')
            ) {
              object.sendEvent =
                $root.fetchactions.intercom.v1.SendEvent.toObject(
                  message.sendEvent,
                  options
                );
              if (options.oneofs) {
                object.type = 'sendEvent';
              }
            }
            if (
              message.createOrUpdateContact != null &&
              message.hasOwnProperty('createOrUpdateContact')
            ) {
              object.createOrUpdateContact =
                $root.fetchactions.intercom.v1.CreateOrUpdateContact.toObject(
                  message.createOrUpdateContact,
                  options
                );
              if (options.oneofs) {
                object.type = 'createOrUpdateContact';
              }
            }
            if (
              message.searchContacts != null &&
              message.hasOwnProperty('searchContacts')
            ) {
              object.searchContacts =
                $root.fetchactions.intercom.v1.SearchContacts.toObject(
                  message.searchContacts,
                  options
                );
              if (options.oneofs) {
                object.type = 'searchContacts';
              }
            }
            if (
              message.archiveContact != null &&
              message.hasOwnProperty('archiveContact')
            ) {
              object.archiveContact =
                $root.fetchactions.intercom.v1.ArchiveContact.toObject(
                  message.archiveContact,
                  options
                );
              if (options.oneofs) {
                object.type = 'archiveContact';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.intercom.v1.FetchAction';
          }
        }

        FetchAction.prototype.sendEvent = null;
        FetchAction.prototype.createOrUpdateContact = null;
        FetchAction.prototype.searchContacts = null;
        FetchAction.prototype.archiveContact = null;

        return FetchAction;
      })();

      v1.SendEvent = (() => {
        class SendEvent {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SendEvent(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              intercom$1.v1.SendEventResponse.encode(
                message.result,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SendEvent();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 5: {
                  message.result = intercom$1.v1.SendEventResponse.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intercom.v1.SendEvent) {
              return object;
            }
            const message = new $root.fetchactions.intercom.v1.SendEvent();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SendEvent.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.SendEvent.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SendEvent.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.SendEvent.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SendEvent.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = intercom$1.v1.SendEventResponse.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = intercom$1.v1.SendEventResponse.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.intercom.v1.SendEvent';
          }
        }

        SendEvent.prototype.params = $util.emptyArray;
        SendEvent.prototype.configs = $util.emptyArray;
        SendEvent.prototype.result = null;

        return SendEvent;
      })();

      v1.CreateOrUpdateContact = (() => {
        class CreateOrUpdateContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateContact(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              Object.hasOwnProperty.call(message, 'doNotAssociateThirdPartyId')
            ) {
              writer.uint32(24).bool(message.doNotAssociateThirdPartyId);
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              intercom$1.v1.Contact.encode(
                message.result,
                writer.uint32(34).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 4: {
                  message.result = intercom$1.v1.Contact.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.fetchactions.intercom.v1.CreateOrUpdateContact
            ) {
              return object;
            }
            const message =
              new $root.fetchactions.intercom.v1.CreateOrUpdateContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.CreateOrUpdateContact.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.CreateOrUpdateContact.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.CreateOrUpdateContact.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.CreateOrUpdateContact.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(
                object.doNotAssociateThirdPartyId
              );
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.CreateOrUpdateContact.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = intercom$1.v1.Contact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              message.hasOwnProperty('doNotAssociateThirdPartyId')
            ) {
              object.doNotAssociateThirdPartyId =
                message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = intercom$1.v1.Contact.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'fetchactions.intercom.v1.CreateOrUpdateContact'
            );
          }
        }

        CreateOrUpdateContact.prototype.params = $util.emptyArray;
        CreateOrUpdateContact.prototype.configs = $util.emptyArray;
        CreateOrUpdateContact.prototype.doNotAssociateThirdPartyId = false;
        CreateOrUpdateContact.prototype.result = null;

        return CreateOrUpdateContact;
      })();

      v1.SearchContacts = (() => {
        class SearchContacts {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchContacts(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              for (const element of message.result) {
                intercom$1.v1.Contact.encode(
                  element,
                  writer.uint32(26).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchContacts();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(
                    intercom$1.v1.Contact.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.intercom.v1.SearchContacts
            ) {
              return object;
            }
            const message = new $root.fetchactions.intercom.v1.SearchContacts();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SearchContacts.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.SearchContacts.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SearchContacts.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.SearchContacts.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.SearchContacts.result: array type expected, but got ' +
                    typeof object.result
                );
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.SearchContacts.result: object expected, but got ' +
                      typeof object.result[i]
                  );
                }
                message.result[i] = intercom$1.v1.Contact.fromObject(
                  object.result[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = intercom$1.v1.Contact.toObject(
                  message.result[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.intercom.v1.SearchContacts';
          }
        }

        SearchContacts.prototype.params = $util.emptyArray;
        SearchContacts.prototype.configs = $util.emptyArray;
        SearchContacts.prototype.result = $util.emptyArray;

        return SearchContacts;
      })();

      v1.ArchiveContact = (() => {
        class ArchiveContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ArchiveContact(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              intercom$1.v1.ArchivedContact.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ArchiveContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = intercom$1.v1.ArchivedContact.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.intercom.v1.ArchiveContact
            ) {
              return object;
            }
            const message = new $root.fetchactions.intercom.v1.ArchiveContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.ArchiveContact.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.ArchiveContact.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.intercom.v1.ArchiveContact.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.intercom.v1.ArchiveContact.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.intercom.v1.ArchiveContact.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = intercom$1.v1.ArchivedContact.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = intercom$1.v1.ArchivedContact.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.intercom.v1.ArchiveContact';
          }
        }

        ArchiveContact.prototype.params = $util.emptyArray;
        ArchiveContact.prototype.configs = $util.emptyArray;
        ArchiveContact.prototype.result = null;

        return ArchiveContact;
      })();

      return v1;
    })();

    return intercom;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
