import { FC } from 'react';
import { useTheme } from '@morf/theming';
import { ProviderCardExtendedProps } from './types';
import { FlexItem, Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { Text } from '../Typography';
import { ProviderTooltip } from '../ProviderTooltip/ProviderTooltip';
import { DateField } from '../DateField';
import { TimezonePicker } from '../TimezonePicker';
import { Checklist } from '../Checklist';
import { useResize } from '../Hooks/useResize';
import { ProviderCardExtendedSlots } from './ProviderCardExtendedSlots';
import { RenderHTML } from '../RenderHTML';

export const ProviderCardExtended: FC<ProviderCardExtendedProps> = ({
  id,
  imageUrl,
  name,
  qualifications,
  detailedQualifications,
  appointmentName,
  specialties,
  longBio,
  startDate,
  endDate,
  selectedDate,
  onSelectDate,
  onMonthChange,
  timezone,
  onBackClick,
  onChangeTimezone,
  selectedSlot,
  onSelectSlot,
  onConfirmSlot,
  slots,
  highlightedDates,
  isLoading,
}) => {
  const theme = useTheme();

  const responsiveLayout = useResize({
    p: {
      xxl: 1.25,
      xl: 1.25,
      lg: 1.25,
      md: 0.75,
      sm: 0.75,
      xs: 0.5,
    },
  });

  const responsivePickers = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'column',
      xs: 'column',
    },
  });

  return (
    <Flexbox
      direction='column'
      data-testid='provider-card-extended'
      alignItems='center'
      maxWidth='47.5rem'
      {...responsiveLayout}
      gap={1}
    >
      <Flexbox gap={0} pr={onBackClick ? 2 : 0}>
        {onBackClick && (
          <Icon
            name='arrow-left'
            cursor='pointer'
            stroke={theme.colors.main.primary.darker}
            strokeWidth={1}
            size={2}
            onClick={onBackClick}
          />
        )}

        <FlexItem flexGrow={1} alignSelf='center'>
          <Image
            src={imageUrl}
            alt={name}
            width='7.5rem'
            height='7.5rem'
            radius='full'
          />
        </FlexItem>
      </Flexbox>

      <Flexbox
        justifyContent='center'
        alignItems='center'
        direction='column'
        gap={0.25}
      >
        <Text tag='h3'>{name}</Text>
        <FlexItem alignSelf='center'>
          <ProviderTooltip
            tooltipDirection='top'
            arrowDirection='center'
            qualifications={qualifications}
            detailedQualifications={detailedQualifications}
          />
        </FlexItem>
      </Flexbox>

      <FlexItem alignSelf='flex-start'>
        <Text tag='h4' align='left'>
          {appointmentName} Availability:
        </Text>
      </FlexItem>

      <Flexbox direction={responsivePickers.direction}>
        <DateField
          startDate={startDate}
          endDate={endDate}
          selectedDate={selectedDate}
          highlightedDates={highlightedDates}
          onSelectedDate={onSelectDate}
          onMonthChange={onMonthChange}
          isLoading={isLoading}
        />
        <TimezonePicker
          defaultTimezone={timezone}
          selectTimezone={(timezone) => onChangeTimezone(timezone)}
        />
      </Flexbox>
      <ProviderCardExtendedSlots
        slots={slots}
        id={id}
        selectedSlot={selectedSlot}
        onSelectSlot={onSelectSlot}
        onConfirmSlot={onConfirmSlot}
      />

      {!!specialties.length && (
        <FlexItem flexOrder={theme.contentOrder?.specialties} width='100%'>
          <Flexbox direction='column' gap={0.5}>
            <Text tag='h5'>Specialties</Text>
            <Checklist list={specialties} showAllItems />
          </Flexbox>
        </FlexItem>
      )}

      <FlexItem flexOrder={theme.contentOrder?.bio} width='100%'>
        <Flexbox direction='column' gap={0.5}>
          <Text tag='h5'>About</Text>
          <Text tag='p2'>
            <RenderHTML htmlContent={longBio} />
          </Text>
        </Flexbox>
      </FlexItem>
    </Flexbox>
  );
};
