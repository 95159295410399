import { CategoryLabelProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const CategoryLabel: FC<CategoryLabelProps> = ({ label, onClick }) => {
  const theme = useTheme();

  const handleClick = () => {
    onClick(false);
  };

  return (
    <Flexbox
      data-testid='category-label'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      cursor='pointer'
      height='auto'
      onClick={handleClick}
      gap={0.5}
    >
      <Icon
        name='chevron-left'
        stroke={theme.colors.ui.dark}
        size={1}
        strokeWidth={1.75}
      />
      <Text tag='p2' color={theme.colors.text.muted}>
        {label}
      </Text>
    </Flexbox>
  );
};

export const MemoizedCategoryLabel = memo(CategoryLabel);
