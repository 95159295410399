import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

export const berrySt = [
  '11c3e69d-38fc-4fe3-9ddb-2ce302fdf011',
  '59ed784b-b598-4fae-9141-ea02180093e4',
];

export const useIsBerrySt = (): boolean => {
  const { organizationId } = useUrlParams();
  return Boolean(organizationId && berrySt.includes(organizationId));
};
