import { FC, useRef } from 'react';
import { IntegrationCardExtendedProps } from './types';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Avatar } from '../Avatar';
import { Text } from '../Typography';
import { useClickOutside } from '../Hooks/useClickOutside';
import { Container } from '../Container';
import { useResize } from '../Hooks/useResize';

export const IntegrationCardExtended: FC<IntegrationCardExtendedProps> = ({
  id,
  imageUrl,
  name,
  shortDescription,
  longDescription,
  useCases,
  compatibility,
  selectedIntegration = null,
  setSelectedIntegration,
  parentRef = null,
}) => {
  const theme = useTheme();
  const integrationCardRef = useRef<HTMLDivElement>(null);

  useClickOutside(integrationCardRef, (e) => {
    if (
      parentRef &&
      'current' in parentRef &&
      parentRef.current &&
      parentRef.current.contains(e.target as Node)
    ) {
      setSelectedIntegration(null);
    }
  });

  const isSelected = selectedIntegration && selectedIntegration.name === name;

  const responsiveIntegrationCard = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'row',
      xs: 'column',
    },
    align: {
      xxl: 'center',
      xl: 'center',
      lg: 'center',
      md: 'center',
      sm: 'center',
      xs: 'flex-start',
    },
  });

  const hasUseCases = !!useCases.length;
  const hasCompatibility = !!compatibility.length;

  return (
    <Flexbox
      containerRef={integrationCardRef}
      data-id={name}
      direction={responsiveIntegrationCard.direction}
      justifyContent='flex-start'
      alignItems='flex-start'
      data-testid='integration-card-extended'
      backgroundColor={theme.colors.ui.card}
      borderType='border'
      cursor='pointer'
      borderRadius={1}
      borderWidth={isSelected ? 0.125 : 0.0625}
      borderColor={
        isSelected ? theme.colors.main.primary.darker : theme.colors.ui.divider
      }
      shadow={isSelected ? 'base' : 'none'}
      onClick={() =>
        setSelectedIntegration({
          id,
          imageUrl,
          name,
          shortDescription,
          longDescription,
          useCases,
          compatibility,
        })
      }
      p={2}
      gap={2}
      maxWidth='52rem'
    >
      <Flexbox direction='column' gap={1.25}>
        <Flexbox
          direction={responsiveIntegrationCard.direction}
          alignItems={responsiveIntegrationCard.align}
          justifyContent='flex-start'
          gap={1.5}
          height='auto'
        >
          <Container width='auto'>
            <Avatar size='lg' src={imageUrl} alt={name} />
          </Container>
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            height='auto'
            gap={0}
          >
            <Text tag='h2'>{name}</Text>
            <Text tag='p1'>{shortDescription}</Text>
          </Flexbox>
        </Flexbox>
        <Text tag='p1'>{longDescription}</Text>
      </Flexbox>

      {(hasUseCases || hasCompatibility) && (
        <Flexbox direction='column' width='20rem'>
          {hasUseCases && (
            <Flexbox direction='column'>
              <Flexbox direction='column' justifyContent='flex-start' gap={0}>
                <Text tag='h5' weight='bold' color={theme.colors.text.muted}>
                  Best used for
                </Text>
                {useCases.map((useCase, index) => (
                  <Text key={index} tag='p2' color={theme.colors.text.muted}>
                    {useCase}
                  </Text>
                ))}
              </Flexbox>
            </Flexbox>
          )}

          {hasUseCases && (
            <Flexbox direction='column' justifyContent='flex-start' gap={0}>
              <Text tag='h5' weight='bold' color={theme.colors.text.muted}>
                Compatible Destinations
              </Text>
              {compatibility.map((integration, index) => (
                <Flexbox
                  key={index}
                  justifyContent='flex-start'
                  alignItems='center'
                  gap={0.25}
                  pt={0.25}
                >
                  <Avatar
                    size='sm'
                    src={integration.imageUrl}
                    alt={integration.name}
                  />
                  <Text tag='p2' color={theme.colors.text.muted}>
                    {integration.name}
                  </Text>
                </Flexbox>
              ))}
            </Flexbox>
          )}
        </Flexbox>
      )}
    </Flexbox>
  );
};
