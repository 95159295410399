import { google } from '@morf/proto/struct_ts_proto';
import {
  FormattedGoogleProtobufValue,
  formatGoogleProtobufValue,
} from './formatGoogleProtobufValue';

export const formatGoogleStruct = (
  struct: google.protobuf.Struct | null | undefined
): { [key: string]: FormattedGoogleProtobufValue } => {
  if (!struct || !struct.fields) return {};

  let flatStruct: { [key: string]: FormattedGoogleProtobufValue } = {};

  return Object.keys(struct.fields)
    .sort()
    .reduce((flatStruct, key) => {
      flatStruct[key] = formatGoogleProtobufValue(struct.fields[key]);
      return flatStruct;
    }, flatStruct);
};
