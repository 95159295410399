import { Container, Flexbox, Icon, Text } from '@morf/ui';
import { FC, memo } from 'react';
import { ReadOnlyTagProps } from './types';
import { usePermissions } from '../../../context/permissions/usePermissions';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../helpers/useUrlParams';

const ReadOnlyTag: FC<ReadOnlyTagProps> = ({}) => {
  const theme = useTheme();
  const router = useRouter();
  const { canEditWorkflow } = usePermissions();
  const { organizationId, workflowId } = useUrlParams();

  const handleEditWorkflowClick = () => {
    router.push(
      `/organizations/${organizationId}/workflows/${workflowId}/build`
    );
  };

  return (
    <Container data-testid='read-only-tag' height='auto' px={1.25}>
      <Flexbox
        direction='row'
        justifyContent='center'
        alignItems='center'
        height='auto'
        backgroundColor={theme.colors.ui.body}
        borderRadius={0.25}
        gap={0.5}
        p={0.5}
      >
        <Icon
          name='lock-closed'
          size={1}
          stroke={theme.colors.ui.dark}
          strokeWidth={2}
        />
        <Text tag='h6' color={theme.colors.ui.dark}>
          Read Only View
        </Text>

        {canEditWorkflow && (
          <Container
            onClick={handleEditWorkflowClick}
            height='auto'
            width='auto'
            cursor='pointer'
          >
            <Text tag='h6' color={theme.colors.ui.dark} decoration='underline'>
              Edit Workflow
            </Text>
          </Container>
        )}
      </Flexbox>
    </Container>
  );
};

export const MemoizedReadOnlyTag = memo(ReadOnlyTag);
