import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WorkflowExecutionHeaderButton } from './WorkflowExecutionHeaderButton';
import { WorkflowExecutionHeaderProps } from './types';
import { WorkflowExecutionStatus } from '../WorkflowExecutionStatus';
import { createEventLink } from './createEventLink';
import { createExecutionLink } from './createExecutionLink';
import { getDefaultProfilePath } from '../../../apps/admin/components/helpers/getDefaultProfilePath';
import { getDefaultTemporalPath } from '../../../apps/admin/components/helpers/getDefaultTemporalPath';
import { timestampToString } from '../../../apps/admin/components/helpers';
import { usePermissions } from '../../../apps/admin/components/context/permissions/usePermissions';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

const WorkflowExecutionHeader: FC<WorkflowExecutionHeaderProps> = ({
  eventSnapshot,
  onClose,
  workflowExecutionSummary,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const { organizationId } = useUrlParams();
  const { isMorfInternalUser } = usePermissions();

  const { executionEndTime, matchedProfileIndex, temporalWorkflowExecution } =
    workflowExecutionSummary;

  const executionEndTimeString = executionEndTime
    ? timestampToString(executionEndTime).isoDateString
    : 'Processing';

  const thirdPartyId = matchedProfileIndex?.thirdPartyId;

  const profileLink =
    organizationId &&
    thirdPartyId &&
    getDefaultProfilePath(organizationId, 'customer', thirdPartyId);

  const eventLink = createEventLink(organizationId, eventSnapshot);

  const temporalLink = temporalWorkflowExecution
    ? getDefaultTemporalPath(temporalWorkflowExecution)
    : undefined;

  const executionLink = createExecutionLink(
    organizationId,
    workflowExecutionSummary
  );

  const handleClose = () => {
    updateUrlParam({
      workflowExecutionId: null,
      nodeId: null,
      attemptNumber: null,
    });
    onClose();
  };

  return (
    <Flexbox
      data-testid='workflow-execution-header'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      backgroundColor={theme.colors.ui.card}
      borderType='borderBottomRight'
      width='17.5rem'
      height='auto'
      shadow='sm'
      gap={0.5}
      p={0.5}
      pt={0.75}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.5}
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='h5'>{workflowExecutionSummary.workflowName}</Text>
          <Text tag='p3'>{executionEndTimeString}</Text>
        </Flexbox>

        <Icon
          name='close'
          cursor='pointer'
          onClick={handleClose}
          size={1.125}
          stroke={theme.colors.text.muted}
          strokeWidth={1.75}
        />
      </Flexbox>

      <WorkflowExecutionStatus
        retryNumber={workflowExecutionSummary.retryNumber}
        retryOriginalWorkflowExecutionId={
          workflowExecutionSummary.retryOriginalWorkflowExecutionId
        }
        status={workflowExecutionSummary.status}
        supersededWorkflowExecutionId={
          workflowExecutionSummary.supersededWorkflowExecutionId
        }
        variant='icon-description'
        workflowErrors={workflowExecutionSummary.workflowErrors}
      />

      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.25}
      >
        <WorkflowExecutionHeaderButton
          iconName='patient-arrow'
          tooltipText='Go to Profile'
          link={profileLink}
        />

        <WorkflowExecutionHeaderButton
          iconName='webhook'
          tooltipText='Go to Event Snapshot'
          link={eventLink}
        />

        {isMorfInternalUser && (
          <WorkflowExecutionHeaderButton
            iconName='temporal'
            tooltipText='Open in Temporal'
            link={temporalLink}
          />
        )}

        <WorkflowExecutionHeaderButton
          iconName='link'
          tooltipText='Go to Execution'
          link={executionLink}
        />

        <WorkflowExecutionHeaderButton
          iconName='copy'
          tooltipText='Copy Execution ID'
          copyText={workflowExecutionSummary?.workflowExecutionId}
        />
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionHeader = memo(WorkflowExecutionHeader);
