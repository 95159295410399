import { FC, forwardRef } from 'react';
import { HiddenCheckbox } from './Checkbox.css';
import { CheckboxProps } from './types';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Container } from '../Container';

export const Checkbox: FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>(
  (
    {
      id,
      name,
      children,
      checked = false,
      className,
      type,
      onChange,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Flexbox
        data-testid={name}
        justifyContent='flex-start'
        alignItems='center'
        width='auto'
        height='auto'
        gap={0.5}
      >
        <HiddenCheckbox
          type='checkbox'
          defaultChecked={checked}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        <Container
          borderColor={theme.colors.ui.divider}
          borderRadius={0.25}
          borderType='border'
          borderWidth={0.0625}
          cursor='pointer'
          height='0.75rem'
          width='0.75rem'
        >
          {checked && (
            <Icon
              name='check'
              size={0.75}
              stroke={theme.colors.text.body}
              strokeWidth={4}
            />
          )}
        </Container>
      </Flexbox>
    );
  }
);
