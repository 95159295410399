'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.protobuf = (() => {
    const protobuf = {};
    protobuf.Struct = (() => {
      class Struct {
        constructor(properties) {
          this.fields = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Struct(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const key of Object.keys(message.fields)) {
              writer.uint32(10).fork().uint32(10).string(key);
              $root.google.protobuf.Value.encode(
                message.fields[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Struct();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.fields === $util.emptyObject) message.fields = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = $root.google.protobuf.Value.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                  }
                  message.fields[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.protobuf.Struct) {
            return object;
          }
          const message = new $root.google.protobuf.Struct();
          if (object.fields) {
            if (typeof object.fields !== 'object') {
              throw new TypeError(
                '.google.protobuf.Struct.fields: object expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = {};
            for (
              let keys = Object.keys(object.fields), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.fields[keys[i]] !== 'object') {
                throw new TypeError(
                  '.google.protobuf.Struct.value: object expected, but got ' +
                    typeof object.fields[keys[i]]
                );
              }
              message.fields[keys[i]] = $root.google.protobuf.Value.fromObject(
                object.fields[keys[i]]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.fields = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.fields && (keys = Object.keys(message.fields)).length) {
            object.fields = {};
            for (let i = 0; i < keys.length; ++i) {
              object.fields[keys[i]] = $root.google.protobuf.Value.toObject(
                message.fields[keys[i]],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'google.protobuf.Struct';
        }
      }

      Struct.prototype.fields = $util.emptyObject;

      return Struct;
    })();

    protobuf.Value = (() => {
      class Value {
        get kind() {
          for (const key of [
            'nullValue',
            'numberValue',
            'stringValue',
            'boolValue',
            'structValue',
            'listValue',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set kind(name) {
          for (const key of [
            'nullValue',
            'numberValue',
            'stringValue',
            'boolValue',
            'structValue',
            'listValue',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Value(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.nullValue != null &&
            Object.hasOwnProperty.call(message, 'nullValue')
          ) {
            writer.uint32(8).int32(message.nullValue);
          }
          if (
            message.numberValue != null &&
            Object.hasOwnProperty.call(message, 'numberValue')
          ) {
            writer.uint32(17).double(message.numberValue);
          }
          if (
            message.stringValue != null &&
            Object.hasOwnProperty.call(message, 'stringValue')
          ) {
            writer.uint32(26).string(message.stringValue);
          }
          if (
            message.boolValue != null &&
            Object.hasOwnProperty.call(message, 'boolValue')
          ) {
            writer.uint32(32).bool(message.boolValue);
          }
          if (
            message.structValue != null &&
            Object.hasOwnProperty.call(message, 'structValue')
          ) {
            $root.google.protobuf.Struct.encode(
              message.structValue,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.listValue != null &&
            Object.hasOwnProperty.call(message, 'listValue')
          ) {
            $root.google.protobuf.ListValue.encode(
              message.listValue,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Value();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.nullValue = reader.int32();
                break;
              }
              case 2: {
                message.numberValue = reader.double();
                break;
              }
              case 3: {
                message.stringValue = reader.string();
                break;
              }
              case 4: {
                message.boolValue = reader.bool();
                break;
              }
              case 5: {
                message.structValue = $root.google.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.listValue = $root.google.protobuf.ListValue.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.protobuf.Value) {
            return object;
          }
          const message = new $root.google.protobuf.Value();
          if (object.nullValue != null) {
            switch (object.nullValue) {
              case 'NULL_VALUE':
              case 0: {
                message.nullValue = 0;
                break;
              }
              default: {
                if (typeof object.nullValue == 'number') {
                  message.nullValue = object.nullValue;
                  break;
                }
                break;
              }
            }
          }
          if (object.numberValue != null) {
            message.numberValue = Number(object.numberValue);
          }
          if (object.stringValue != null) {
            message.stringValue = String(object.stringValue);
          }
          if (object.boolValue != null) {
            message.boolValue = Boolean(object.boolValue);
          }
          if (object.structValue != null) {
            if (typeof object.structValue !== 'object') {
              throw new TypeError(
                '.google.protobuf.Value.structValue: object expected, but got ' +
                  typeof object.structValue
              );
            }
            message.structValue = $root.google.protobuf.Struct.fromObject(
              object.structValue
            );
          }
          if (object.listValue != null) {
            if (typeof object.listValue !== 'object') {
              throw new TypeError(
                '.google.protobuf.Value.listValue: object expected, but got ' +
                  typeof object.listValue
              );
            }
            message.listValue = $root.google.protobuf.ListValue.fromObject(
              object.listValue
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.nullValue != null &&
            message.hasOwnProperty('nullValue')
          ) {
            object.nullValue =
              options.enums === String
                ? $root.google.protobuf.NullValue[message.nullValue] ===
                  undefined
                  ? message.nullValue
                  : $root.google.protobuf.NullValue[message.nullValue]
                : message.nullValue;
            if (options.oneofs) {
              object.kind = 'nullValue';
            }
          }
          if (
            message.numberValue != null &&
            message.hasOwnProperty('numberValue')
          ) {
            object.numberValue =
              options.json && !isFinite(message.numberValue)
                ? String(message.numberValue)
                : message.numberValue;
            if (options.oneofs) {
              object.kind = 'numberValue';
            }
          }
          if (
            message.stringValue != null &&
            message.hasOwnProperty('stringValue')
          ) {
            object.stringValue = message.stringValue;
            if (options.oneofs) {
              object.kind = 'stringValue';
            }
          }
          if (
            message.boolValue != null &&
            message.hasOwnProperty('boolValue')
          ) {
            object.boolValue = message.boolValue;
            if (options.oneofs) {
              object.kind = 'boolValue';
            }
          }
          if (
            message.structValue != null &&
            message.hasOwnProperty('structValue')
          ) {
            object.structValue = $root.google.protobuf.Struct.toObject(
              message.structValue,
              options
            );
            if (options.oneofs) {
              object.kind = 'structValue';
            }
          }
          if (
            message.listValue != null &&
            message.hasOwnProperty('listValue')
          ) {
            object.listValue = $root.google.protobuf.ListValue.toObject(
              message.listValue,
              options
            );
            if (options.oneofs) {
              object.kind = 'listValue';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'google.protobuf.Value';
        }
      }

      Value.prototype.nullValue = 0;
      Value.prototype.numberValue = 0;
      Value.prototype.stringValue = '';
      Value.prototype.boolValue = false;
      Value.prototype.structValue = null;
      Value.prototype.listValue = null;

      return Value;
    })();

    protobuf.ListValue = (() => {
      class ListValue {
        constructor(properties) {
          this.values = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListValue(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.values != null &&
            Object.hasOwnProperty.call(message, 'values')
          ) {
            for (const element of message.values) {
              $root.google.protobuf.Value.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.values || !message.values.length) {
                  message.values = [];
                }
                message.values.push(
                  $root.google.protobuf.Value.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.protobuf.ListValue) {
            return object;
          }
          const message = new $root.google.protobuf.ListValue();
          if (object.values) {
            if (!Array.isArray(object.values)) {
              throw new TypeError(
                '.google.protobuf.ListValue.values: array type expected, but got ' +
                  typeof object.values
              );
            }
            message.values = new Array(object.values.length);
            for (let i = 0; i < object.values.length; ++i) {
              if (typeof object.values[i] !== 'object') {
                throw new TypeError(
                  '.google.protobuf.ListValue.values: object expected, but got ' +
                    typeof object.values[i]
                );
              }
              message.values[i] = $root.google.protobuf.Value.fromObject(
                object.values[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.values = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.values && message.values.length) {
            object.values = new Array(message.values.length);
            for (let i = 0; i < message.values.length; ++i) {
              object.values[i] = $root.google.protobuf.Value.toObject(
                message.values[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'google.protobuf.ListValue';
        }
      }

      ListValue.prototype.values = $util.emptyArray;

      return ListValue;
    })();

    protobuf.NullValue = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'NULL_VALUE')] = 0;
      return values;
    })();

    return protobuf;
  })();

  return google;
})();

export const google = $root.google;
