import { FC, memo, useEffect, useRef, useState } from 'react';
import { AddEdgeButtonProps } from './types';
import { StyledAddEdgeButton, StyledContainer } from './AddEdgeButton.css';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { AddEdgeModal } from '../AddEdgeModal';
import { generateActionList } from '../generateActionList';
import { useViewport } from 'reactflow';

const AddEdgeButton: FC<AddEdgeButtonProps> = ({
  destinationActions,
  fetchActions,
  labelX,
  labelY,
  name,
  onClick,
}) => {
  const viewport = useViewport();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAddEdgeModal, setShowAddEdgeModal] = useState(false);
  const [addEdgeModalPosition, setAddEdgeModalPosition] = useState({
    top: '0px',
    left: '0px',
  });

  const updateModalPosition = () => {
    if (containerRef.current) {
      const buttonRect = containerRef.current.getBoundingClientRect();
      const top = `${buttonRect.bottom - 170}px`;
      const left = `${buttonRect.left + 30}px`;
      setAddEdgeModalPosition({ top, left });
    }
  };

  useEffect(() => {
    updateModalPosition();
  }, [containerRef.current, viewport]);

  return (
    <StyledContainer labelX={labelX} labelY={labelY} data-testid={name}>
      <Flexbox
        containerRef={containerRef}
        justifyContent='center'
        alignItems='center'
      >
        <StyledAddEdgeButton
          data-testid='add-button'
          onClick={() => setShowAddEdgeModal(true)}
        >
          <Icon name='plus' strokeWidth={5} />
        </StyledAddEdgeButton>

        <AddEdgeModal
          destinationActions={generateActionList(destinationActions)}
          fetchActions={generateActionList(fetchActions)}
          isOpen={showAddEdgeModal}
          left={addEdgeModalPosition.left}
          onClick={onClick}
          onClose={() => setShowAddEdgeModal(false)}
          top={addEdgeModalPosition.top}
        />
      </Flexbox>
    </StyledContainer>
  );
};

export const MemoizedAddEdgeButton = memo(AddEdgeButton);
