'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.healthie = (() => {
  const healthie = {};
  healthie.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.healthie.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.healthie.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_SCHEDULED':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_UPDATED':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_DELETED':
                case 3: {
                  message.eventTypes[i] = 3;
                  break;
                }
                case 'HEALTHIE_BILLING_ITEM_CREATED':
                case 4: {
                  message.eventTypes[i] = 4;
                  break;
                }
                case 'HEALTHIE_BILLING_ITEM_UPDATED':
                case 5: {
                  message.eventTypes[i] = 5;
                  break;
                }
                case 'HEALTHIE_CMS1500_CREATED':
                case 6: {
                  message.eventTypes[i] = 6;
                  break;
                }
                case 'HEALTHIE_CMS1500_UPDATED':
                case 7: {
                  message.eventTypes[i] = 7;
                  break;
                }
                case 'HEALTHIE_CMS1500_DELETED':
                case 8: {
                  message.eventTypes[i] = 8;
                  break;
                }
                case 'HEALTHIE_COMMENT_CREATED':
                case 9: {
                  message.eventTypes[i] = 9;
                  break;
                }
                case 'HEALTHIE_COMMENT_UPDATED':
                case 10: {
                  message.eventTypes[i] = 10;
                  break;
                }
                case 'HEALTHIE_COMMENT_DELETED':
                case 11: {
                  message.eventTypes[i] = 11;
                  break;
                }
                case 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED':
                case 12: {
                  message.eventTypes[i] = 12;
                  break;
                }
                case 'HEALTHIE_DOCUMENT_CREATED':
                case 13: {
                  message.eventTypes[i] = 13;
                  break;
                }
                case 'HEALTHIE_DOCUMENT_UPDATED':
                case 14: {
                  message.eventTypes[i] = 14;
                  break;
                }
                case 'HEALTHIE_DOCUMENT_DELETED':
                case 15: {
                  message.eventTypes[i] = 15;
                  break;
                }
                case 'HEALTHIE_ENTRY_CREATED':
                case 16: {
                  message.eventTypes[i] = 16;
                  break;
                }
                case 'HEALTHIE_ENTRY_UPDATED':
                case 17: {
                  message.eventTypes[i] = 17;
                  break;
                }
                case 'HEALTHIE_ENTRY_DELETED':
                case 18: {
                  message.eventTypes[i] = 18;
                  break;
                }
                case 'HEALTHIE_FORM_ANSWER_GROUP_CREATED':
                case 19: {
                  message.eventTypes[i] = 19;
                  break;
                }
                case 'HEALTHIE_FORM_ANSWER_GROUP_DELETED':
                case 20: {
                  message.eventTypes[i] = 20;
                  break;
                }
                case 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED':
                case 21: {
                  message.eventTypes[i] = 21;
                  break;
                }
                case 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED':
                case 22: {
                  message.eventTypes[i] = 22;
                  break;
                }
                case 'HEALTHIE_GOAL_CREATED':
                case 23: {
                  message.eventTypes[i] = 23;
                  break;
                }
                case 'HEALTHIE_GOAL_UPDATED':
                case 24: {
                  message.eventTypes[i] = 24;
                  break;
                }
                case 'HEALTHIE_GOAL_DELETED':
                case 25: {
                  message.eventTypes[i] = 25;
                  break;
                }
                case 'HEALTHIE_GOAL_HISTORY_CREATED':
                case 26: {
                  message.eventTypes[i] = 26;
                  break;
                }
                case 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED':
                case 27: {
                  message.eventTypes[i] = 27;
                  break;
                }
                case 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED':
                case 28: {
                  message.eventTypes[i] = 28;
                  break;
                }
                case 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED':
                case 29: {
                  message.eventTypes[i] = 29;
                  break;
                }
                case 'HEALTHIE_MESSAGE_CREATED':
                case 30: {
                  message.eventTypes[i] = 30;
                  break;
                }
                case 'HEALTHIE_MESSAGE_DELETED':
                case 31: {
                  message.eventTypes[i] = 31;
                  break;
                }
                case 'HEALTHIE_METRIC_ENTRY_CREATED':
                case 32: {
                  message.eventTypes[i] = 32;
                  break;
                }
                case 'HEALTHIE_METRIC_ENTRY_UPDATED':
                case 33: {
                  message.eventTypes[i] = 33;
                  break;
                }
                case 'HEALTHIE_METRIC_ENTRY_DELETED':
                case 34: {
                  message.eventTypes[i] = 34;
                  break;
                }
                case 'HEALTHIE_PATIENT_CREATED':
                case 35: {
                  message.eventTypes[i] = 35;
                  break;
                }
                case 'HEALTHIE_PATIENT_UPDATED':
                case 36: {
                  message.eventTypes[i] = 36;
                  break;
                }
                case 'HEALTHIE_POLICY_CREATED':
                case 37: {
                  message.eventTypes[i] = 37;
                  break;
                }
                case 'HEALTHIE_POLICY_UPDATED':
                case 38: {
                  message.eventTypes[i] = 38;
                  break;
                }
                case 'HEALTHIE_POLICY_DELETED':
                case 39: {
                  message.eventTypes[i] = 39;
                  break;
                }
                case 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED':
                case 40: {
                  message.eventTypes[i] = 40;
                  break;
                }
                case 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED':
                case 41: {
                  message.eventTypes[i] = 41;
                  break;
                }
                case 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED':
                case 42: {
                  message.eventTypes[i] = 42;
                  break;
                }
                case 'HEALTHIE_TASK_CREATED':
                case 43: {
                  message.eventTypes[i] = 43;
                  break;
                }
                case 'HEALTHIE_TASK_UPDATED':
                case 44: {
                  message.eventTypes[i] = 44;
                  break;
                }
                case 'HEALTHIE_TASK_DELETED':
                case 45: {
                  message.eventTypes[i] = 45;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED':
                case 46: {
                  message.eventTypes[i] = 46;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_NO_SHOW':
                case 47: {
                  message.eventTypes[i] = 47;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_RESCHEDULED':
                case 48: {
                  message.eventTypes[i] = 48;
                  break;
                }
                case 'HEALTHIE_APPOINTMENT_CANCELLED':
                case 49: {
                  message.eventTypes[i] = 49;
                  break;
                }
                case 'HEALTHIE_APPLIED_TAG_CREATED':
                case 50: {
                  message.eventTypes[i] = 50;
                  break;
                }
                case 'HEALTHIE_APPLIED_TAG_DELETED':
                case 51: {
                  message.eventTypes[i] = 51;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.healthie.v1.HealthieEventType[
                      message.eventTypes[i]
                    ] === undefined
                    ? message.eventTypes[i]
                    : $root.healthie.v1.HealthieEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.UserGroup = (() => {
      class UserGroup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UserGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UserGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.UserGroup) {
            return object;
          }
          const message = new $root.healthie.v1.UserGroup();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.UserGroup.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.createdAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UserGroup';
        }
      }

      UserGroup.prototype.id = '';
      UserGroup.prototype.name = null;
      UserGroup.prototype.createdAt = null;

      return UserGroup;
    })();

    v1.InsurancePlan = (() => {
      class InsurancePlan {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InsurancePlan(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.payerId != null &&
            Object.hasOwnProperty.call(message, 'payerId')
          ) {
            writer.uint32(10).string(message.payerId);
          }
          if (
            message.payerName != null &&
            Object.hasOwnProperty.call(message, 'payerName')
          ) {
            writer.uint32(18).string(message.payerName);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(26).string(message.id);
          }
          if (
            message.nameAndId != null &&
            Object.hasOwnProperty.call(message, 'nameAndId')
          ) {
            writer.uint32(34).string(message.nameAndId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InsurancePlan();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.payerId = reader.string();
                break;
              }
              case 2: {
                message.payerName = reader.string();
                break;
              }
              case 3: {
                message.id = reader.string();
                break;
              }
              case 4: {
                message.nameAndId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.InsurancePlan) {
            return object;
          }
          const message = new $root.healthie.v1.InsurancePlan();
          if (object.payerId != null) {
            message.payerId = String(object.payerId);
          }
          if (object.payerName != null) {
            message.payerName = String(object.payerName);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.nameAndId != null) {
            message.nameAndId = String(object.nameAndId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.payerId != null && message.hasOwnProperty('payerId')) {
            object.payerId = message.payerId;
          }
          if (
            message.payerName != null &&
            message.hasOwnProperty('payerName')
          ) {
            object.payerName = message.payerName;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.nameAndId != null &&
            message.hasOwnProperty('nameAndId')
          ) {
            object.nameAndId = message.nameAndId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.InsurancePlan';
        }
      }

      InsurancePlan.prototype.payerId = null;
      InsurancePlan.prototype.payerName = null;
      InsurancePlan.prototype.id = '';
      InsurancePlan.prototype.nameAndId = null;

      return InsurancePlan;
    })();

    v1.PolicyBenefits = (() => {
      class PolicyBenefits {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PolicyBenefits(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.coinsurance != null &&
            Object.hasOwnProperty.call(message, 'coinsurance')
          ) {
            writer.uint32(10).string(message.coinsurance);
          }
          if (
            message.copay != null &&
            Object.hasOwnProperty.call(message, 'copay')
          ) {
            writer.uint32(18).string(message.copay);
          }
          if (
            message.deductibleCalendarYear != null &&
            Object.hasOwnProperty.call(message, 'deductibleCalendarYear')
          ) {
            writer.uint32(26).string(message.deductibleCalendarYear);
          }
          if (
            message.deductibleYearToDate != null &&
            Object.hasOwnProperty.call(message, 'deductibleYearToDate')
          ) {
            writer.uint32(34).string(message.deductibleYearToDate);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PolicyBenefits();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.coinsurance = reader.string();
                break;
              }
              case 2: {
                message.copay = reader.string();
                break;
              }
              case 3: {
                message.deductibleCalendarYear = reader.string();
                break;
              }
              case 4: {
                message.deductibleYearToDate = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.PolicyBenefits) {
            return object;
          }
          const message = new $root.healthie.v1.PolicyBenefits();
          if (object.coinsurance != null) {
            message.coinsurance = String(object.coinsurance);
          }
          if (object.copay != null) {
            message.copay = String(object.copay);
          }
          if (object.deductibleCalendarYear != null) {
            message.deductibleCalendarYear = String(
              object.deductibleCalendarYear
            );
          }
          if (object.deductibleYearToDate != null) {
            message.deductibleYearToDate = String(object.deductibleYearToDate);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.coinsurance != null &&
            message.hasOwnProperty('coinsurance')
          ) {
            object.coinsurance = message.coinsurance;
          }
          if (message.copay != null && message.hasOwnProperty('copay')) {
            object.copay = message.copay;
          }
          if (
            message.deductibleCalendarYear != null &&
            message.hasOwnProperty('deductibleCalendarYear')
          ) {
            object.deductibleCalendarYear = message.deductibleCalendarYear;
          }
          if (
            message.deductibleYearToDate != null &&
            message.hasOwnProperty('deductibleYearToDate')
          ) {
            object.deductibleYearToDate = message.deductibleYearToDate;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.PolicyBenefits';
        }
      }

      PolicyBenefits.prototype.coinsurance = null;
      PolicyBenefits.prototype.copay = null;
      PolicyBenefits.prototype.deductibleCalendarYear = null;
      PolicyBenefits.prototype.deductibleYearToDate = null;

      return PolicyBenefits;
    })();

    v1.Policy = (() => {
      class Policy {
        constructor(properties) {
          this.benefits = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Policy(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.insurancePlan != null &&
            Object.hasOwnProperty.call(message, 'insurancePlan')
          ) {
            $root.healthie.v1.InsurancePlan.encode(
              message.insurancePlan,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.num != null &&
            Object.hasOwnProperty.call(message, 'num')
          ) {
            writer.uint32(18).string(message.num);
          }
          if (
            message.groupNum != null &&
            Object.hasOwnProperty.call(message, 'groupNum')
          ) {
            writer.uint32(26).string(message.groupNum);
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(34).string(message.notes);
          }
          if (
            message.effectiveEnd != null &&
            Object.hasOwnProperty.call(message, 'effectiveEnd')
          ) {
            values$1.v1.Date.encode(
              message.effectiveEnd,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(50).string(message.id);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(58).string(message.patientId);
          }
          if (
            message.insuranceCardFrontId != null &&
            Object.hasOwnProperty.call(message, 'insuranceCardFrontId')
          ) {
            writer.uint32(66).string(message.insuranceCardFrontId);
          }
          if (
            message.insuranceCardBackId != null &&
            Object.hasOwnProperty.call(message, 'insuranceCardBackId')
          ) {
            writer.uint32(74).string(message.insuranceCardBackId);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.insuranceType != null &&
            Object.hasOwnProperty.call(message, 'insuranceType')
          ) {
            writer.uint32(138).string(message.insuranceType);
          }
          if (
            message.priorityType != null &&
            Object.hasOwnProperty.call(message, 'priorityType')
          ) {
            writer.uint32(146).string(message.priorityType);
          }
          if (
            message.benefits != null &&
            Object.hasOwnProperty.call(message, 'benefits')
          ) {
            for (const element of message.benefits) {
              $root.healthie.v1.PolicyBenefits.encode(
                element,
                writer.uint32(154).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Policy();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.insurancePlan = $root.healthie.v1.InsurancePlan.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.num = reader.string();
                break;
              }
              case 3: {
                message.groupNum = reader.string();
                break;
              }
              case 4: {
                message.notes = reader.string();
                break;
              }
              case 5: {
                message.effectiveEnd = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.id = reader.string();
                break;
              }
              case 7: {
                message.patientId = reader.string();
                break;
              }
              case 8: {
                message.insuranceCardFrontId = reader.string();
                break;
              }
              case 9: {
                message.insuranceCardBackId = reader.string();
                break;
              }
              case 11: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 17: {
                message.insuranceType = reader.string();
                break;
              }
              case 18: {
                message.priorityType = reader.string();
                break;
              }
              case 19: {
                if (!message.benefits || !message.benefits.length) {
                  message.benefits = [];
                }
                message.benefits.push(
                  $root.healthie.v1.PolicyBenefits.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Policy) {
            return object;
          }
          const message = new $root.healthie.v1.Policy();
          if (object.insurancePlan != null) {
            if (typeof object.insurancePlan !== 'object') {
              throw new TypeError(
                '.healthie.v1.Policy.insurancePlan: object expected, but got ' +
                  typeof object.insurancePlan
              );
            }
            message.insurancePlan = $root.healthie.v1.InsurancePlan.fromObject(
              object.insurancePlan
            );
          }
          if (object.num != null) {
            message.num = String(object.num);
          }
          if (object.groupNum != null) {
            message.groupNum = String(object.groupNum);
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.effectiveEnd != null) {
            if (typeof object.effectiveEnd !== 'object') {
              throw new TypeError(
                '.healthie.v1.Policy.effectiveEnd: object expected, but got ' +
                  typeof object.effectiveEnd
              );
            }
            message.effectiveEnd = values$1.v1.Date.fromObject(
              object.effectiveEnd
            );
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.insuranceCardFrontId != null) {
            message.insuranceCardFrontId = String(object.insuranceCardFrontId);
          }
          if (object.insuranceCardBackId != null) {
            message.insuranceCardBackId = String(object.insuranceCardBackId);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Policy.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.insuranceType != null) {
            message.insuranceType = String(object.insuranceType);
          }
          if (object.priorityType != null) {
            message.priorityType = String(object.priorityType);
          }
          if (object.benefits) {
            if (!Array.isArray(object.benefits)) {
              throw new TypeError(
                '.healthie.v1.Policy.benefits: array type expected, but got ' +
                  typeof object.benefits
              );
            }
            message.benefits = new Array(object.benefits.length);
            for (let i = 0; i < object.benefits.length; ++i) {
              if (typeof object.benefits[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.Policy.benefits: object expected, but got ' +
                    typeof object.benefits[i]
                );
              }
              message.benefits[i] = $root.healthie.v1.PolicyBenefits.fromObject(
                object.benefits[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.benefits = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.patientId = '';
            object.updatedAt = null;
          }
          let keys;
          if (
            message.insurancePlan != null &&
            message.hasOwnProperty('insurancePlan')
          ) {
            object.insurancePlan = $root.healthie.v1.InsurancePlan.toObject(
              message.insurancePlan,
              options
            );
          }
          if (message.num != null && message.hasOwnProperty('num')) {
            object.num = message.num;
          }
          if (message.groupNum != null && message.hasOwnProperty('groupNum')) {
            object.groupNum = message.groupNum;
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (
            message.effectiveEnd != null &&
            message.hasOwnProperty('effectiveEnd')
          ) {
            object.effectiveEnd = values$1.v1.Date.toObject(
              message.effectiveEnd,
              options
            );
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (
            message.insuranceCardFrontId != null &&
            message.hasOwnProperty('insuranceCardFrontId')
          ) {
            object.insuranceCardFrontId = message.insuranceCardFrontId;
          }
          if (
            message.insuranceCardBackId != null &&
            message.hasOwnProperty('insuranceCardBackId')
          ) {
            object.insuranceCardBackId = message.insuranceCardBackId;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.insuranceType != null &&
            message.hasOwnProperty('insuranceType')
          ) {
            object.insuranceType = message.insuranceType;
          }
          if (
            message.priorityType != null &&
            message.hasOwnProperty('priorityType')
          ) {
            object.priorityType = message.priorityType;
          }
          if (message.benefits && message.benefits.length) {
            object.benefits = new Array(message.benefits.length);
            for (let i = 0; i < message.benefits.length; ++i) {
              object.benefits[i] = $root.healthie.v1.PolicyBenefits.toObject(
                message.benefits[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Policy';
        }
      }

      Policy.prototype.insurancePlan = null;
      Policy.prototype.num = null;
      Policy.prototype.groupNum = null;
      Policy.prototype.notes = null;
      Policy.prototype.effectiveEnd = null;
      Policy.prototype.id = '';
      Policy.prototype.patientId = '';
      Policy.prototype.insuranceCardFrontId = null;
      Policy.prototype.insuranceCardBackId = null;
      Policy.prototype.updatedAt = null;
      Policy.prototype.insuranceType = null;
      Policy.prototype.priorityType = null;
      Policy.prototype.benefits = $util.emptyArray;

      return Policy;
    })();

    v1.UserInsuranceAuthorization = (() => {
      class UserInsuranceAuthorization {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UserInsuranceAuthorization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.unitsAuthorized != null &&
            Object.hasOwnProperty.call(message, 'unitsAuthorized')
          ) {
            writer.uint32(10).string(message.unitsAuthorized);
          }
          if (
            message.unitsUsed != null &&
            Object.hasOwnProperty.call(message, 'unitsUsed')
          ) {
            writer.uint32(18).string(message.unitsUsed);
          }
          if (
            message.unitsLeft != null &&
            Object.hasOwnProperty.call(message, 'unitsLeft')
          ) {
            writer.uint32(26).string(message.unitsLeft);
          }
          if (
            message.visitsLeft != null &&
            Object.hasOwnProperty.call(message, 'visitsLeft')
          ) {
            writer.uint32(34).string(message.visitsLeft);
          }
          if (
            message.startOn != null &&
            Object.hasOwnProperty.call(message, 'startOn')
          ) {
            values$1.v1.Date.encode(
              message.startOn,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.endOn != null &&
            Object.hasOwnProperty.call(message, 'endOn')
          ) {
            values$1.v1.Date.encode(
              message.endOn,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UserInsuranceAuthorization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.unitsAuthorized = reader.string();
                break;
              }
              case 2: {
                message.unitsUsed = reader.string();
                break;
              }
              case 3: {
                message.unitsLeft = reader.string();
                break;
              }
              case 4: {
                message.visitsLeft = reader.string();
                break;
              }
              case 5: {
                message.startOn = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.endOn = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.UserInsuranceAuthorization) {
            return object;
          }
          const message = new $root.healthie.v1.UserInsuranceAuthorization();
          if (object.unitsAuthorized != null) {
            message.unitsAuthorized = String(object.unitsAuthorized);
          }
          if (object.unitsUsed != null) {
            message.unitsUsed = String(object.unitsUsed);
          }
          if (object.unitsLeft != null) {
            message.unitsLeft = String(object.unitsLeft);
          }
          if (object.visitsLeft != null) {
            message.visitsLeft = String(object.visitsLeft);
          }
          if (object.startOn != null) {
            if (typeof object.startOn !== 'object') {
              throw new TypeError(
                '.healthie.v1.UserInsuranceAuthorization.startOn: object expected, but got ' +
                  typeof object.startOn
              );
            }
            message.startOn = values$1.v1.Date.fromObject(object.startOn);
          }
          if (object.endOn != null) {
            if (typeof object.endOn !== 'object') {
              throw new TypeError(
                '.healthie.v1.UserInsuranceAuthorization.endOn: object expected, but got ' +
                  typeof object.endOn
              );
            }
            message.endOn = values$1.v1.Date.fromObject(object.endOn);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.unitsAuthorized != null &&
            message.hasOwnProperty('unitsAuthorized')
          ) {
            object.unitsAuthorized = message.unitsAuthorized;
          }
          if (
            message.unitsUsed != null &&
            message.hasOwnProperty('unitsUsed')
          ) {
            object.unitsUsed = message.unitsUsed;
          }
          if (
            message.unitsLeft != null &&
            message.hasOwnProperty('unitsLeft')
          ) {
            object.unitsLeft = message.unitsLeft;
          }
          if (
            message.visitsLeft != null &&
            message.hasOwnProperty('visitsLeft')
          ) {
            object.visitsLeft = message.visitsLeft;
          }
          if (message.startOn != null && message.hasOwnProperty('startOn')) {
            object.startOn = values$1.v1.Date.toObject(
              message.startOn,
              options
            );
          }
          if (message.endOn != null && message.hasOwnProperty('endOn')) {
            object.endOn = values$1.v1.Date.toObject(message.endOn, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UserInsuranceAuthorization';
        }
      }

      UserInsuranceAuthorization.prototype.unitsAuthorized = null;
      UserInsuranceAuthorization.prototype.unitsUsed = null;
      UserInsuranceAuthorization.prototype.unitsLeft = null;
      UserInsuranceAuthorization.prototype.visitsLeft = null;
      UserInsuranceAuthorization.prototype.startOn = null;
      UserInsuranceAuthorization.prototype.endOn = null;

      return UserInsuranceAuthorization;
    })();

    v1.Location = (() => {
      class Location {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Location(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.line1 != null &&
            Object.hasOwnProperty.call(message, 'line1')
          ) {
            writer.uint32(10).string(message.line1);
          }
          if (
            message.line2 != null &&
            Object.hasOwnProperty.call(message, 'line2')
          ) {
            writer.uint32(18).string(message.line2);
          }
          if (
            message.city != null &&
            Object.hasOwnProperty.call(message, 'city')
          ) {
            writer.uint32(26).string(message.city);
          }
          if (
            message.state != null &&
            Object.hasOwnProperty.call(message, 'state')
          ) {
            writer.uint32(34).string(message.state);
          }
          if (
            message.zip != null &&
            Object.hasOwnProperty.call(message, 'zip')
          ) {
            writer.uint32(42).string(message.zip);
          }
          if (
            message.country != null &&
            Object.hasOwnProperty.call(message, 'country')
          ) {
            writer.uint32(50).string(message.country);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Location();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.line1 = reader.string();
                break;
              }
              case 2: {
                message.line2 = reader.string();
                break;
              }
              case 3: {
                message.city = reader.string();
                break;
              }
              case 4: {
                message.state = reader.string();
                break;
              }
              case 5: {
                message.zip = reader.string();
                break;
              }
              case 6: {
                message.country = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Location) {
            return object;
          }
          const message = new $root.healthie.v1.Location();
          if (object.line1 != null) {
            message.line1 = String(object.line1);
          }
          if (object.line2 != null) {
            message.line2 = String(object.line2);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.zip != null) {
            message.zip = String(object.zip);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.line1 != null && message.hasOwnProperty('line1')) {
            object.line1 = message.line1;
          }
          if (message.line2 != null && message.hasOwnProperty('line2')) {
            object.line2 = message.line2;
          }
          if (message.city != null && message.hasOwnProperty('city')) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty('state')) {
            object.state = message.state;
          }
          if (message.zip != null && message.hasOwnProperty('zip')) {
            object.zip = message.zip;
          }
          if (message.country != null && message.hasOwnProperty('country')) {
            object.country = message.country;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Location';
        }
      }

      Location.prototype.line1 = null;
      Location.prototype.line2 = null;
      Location.prototype.city = null;
      Location.prototype.state = null;
      Location.prototype.zip = null;
      Location.prototype.country = null;

      return Location;
    })();

    v1.ICDCode = (() => {
      class ICDCode {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ICDCode(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.code != null &&
            Object.hasOwnProperty.call(message, 'code')
          ) {
            writer.uint32(10).string(message.code);
          }
          if (
            message.displayName != null &&
            Object.hasOwnProperty.call(message, 'displayName')
          ) {
            writer.uint32(18).string(message.displayName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ICDCode();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.string();
                break;
              }
              case 2: {
                message.displayName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ICDCode) {
            return object;
          }
          const message = new $root.healthie.v1.ICDCode();
          if (object.code != null) {
            message.code = String(object.code);
          }
          if (object.displayName != null) {
            message.displayName = String(object.displayName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.code != null && message.hasOwnProperty('code')) {
            object.code = message.code;
          }
          if (
            message.displayName != null &&
            message.hasOwnProperty('displayName')
          ) {
            object.displayName = message.displayName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ICDCode';
        }
      }

      ICDCode.prototype.code = null;
      ICDCode.prototype.displayName = null;

      return ICDCode;
    })();

    v1.Diagnosis = (() => {
      class Diagnosis {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Diagnosis(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.active != null &&
            Object.hasOwnProperty.call(message, 'active')
          ) {
            writer.uint32(8).bool(message.active);
          }
          if (
            message.firstSymptomDate != null &&
            Object.hasOwnProperty.call(message, 'firstSymptomDate')
          ) {
            values$1.v1.Date.encode(
              message.firstSymptomDate,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.endDate != null &&
            Object.hasOwnProperty.call(message, 'endDate')
          ) {
            values$1.v1.Date.encode(
              message.endDate,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.icdCode != null &&
            Object.hasOwnProperty.call(message, 'icdCode')
          ) {
            $root.healthie.v1.ICDCode.encode(
              message.icdCode,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Diagnosis();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.active = reader.bool();
                break;
              }
              case 2: {
                message.firstSymptomDate = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.endDate = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.icdCode = $root.healthie.v1.ICDCode.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Diagnosis) {
            return object;
          }
          const message = new $root.healthie.v1.Diagnosis();
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          if (object.firstSymptomDate != null) {
            if (typeof object.firstSymptomDate !== 'object') {
              throw new TypeError(
                '.healthie.v1.Diagnosis.firstSymptomDate: object expected, but got ' +
                  typeof object.firstSymptomDate
              );
            }
            message.firstSymptomDate = values$1.v1.Date.fromObject(
              object.firstSymptomDate
            );
          }
          if (object.endDate != null) {
            if (typeof object.endDate !== 'object') {
              throw new TypeError(
                '.healthie.v1.Diagnosis.endDate: object expected, but got ' +
                  typeof object.endDate
              );
            }
            message.endDate = values$1.v1.Date.fromObject(object.endDate);
          }
          if (object.icdCode != null) {
            if (typeof object.icdCode !== 'object') {
              throw new TypeError(
                '.healthie.v1.Diagnosis.icdCode: object expected, but got ' +
                  typeof object.icdCode
              );
            }
            message.icdCode = $root.healthie.v1.ICDCode.fromObject(
              object.icdCode
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.active = false;
          }
          let keys;
          if (message.active != null && message.hasOwnProperty('active')) {
            object.active = message.active;
          }
          if (
            message.firstSymptomDate != null &&
            message.hasOwnProperty('firstSymptomDate')
          ) {
            object.firstSymptomDate = values$1.v1.Date.toObject(
              message.firstSymptomDate,
              options
            );
          }
          if (message.endDate != null && message.hasOwnProperty('endDate')) {
            object.endDate = values$1.v1.Date.toObject(
              message.endDate,
              options
            );
          }
          if (message.icdCode != null && message.hasOwnProperty('icdCode')) {
            object.icdCode = $root.healthie.v1.ICDCode.toObject(
              message.icdCode,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Diagnosis';
        }
      }

      Diagnosis.prototype.active = false;
      Diagnosis.prototype.firstSymptomDate = null;
      Diagnosis.prototype.endDate = null;
      Diagnosis.prototype.icdCode = null;

      return Diagnosis;
    })();

    v1.NotificationContact = (() => {
      class NotificationContact {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NotificationContact(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(18).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(26).string(message.lastName);
          }
          if (
            message.emailAddress != null &&
            Object.hasOwnProperty.call(message, 'emailAddress')
          ) {
            writer.uint32(34).string(message.emailAddress);
          }
          if (
            message.isEmergencyContact != null &&
            Object.hasOwnProperty.call(message, 'isEmergencyContact')
          ) {
            writer.uint32(40).bool(message.isEmergencyContact);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(50).string(message.phoneNumber);
          }
          if (
            message.relationship != null &&
            Object.hasOwnProperty.call(message, 'relationship')
          ) {
            writer.uint32(58).string(message.relationship);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NotificationContact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.emailAddress = reader.string();
                break;
              }
              case 5: {
                message.isEmergencyContact = reader.bool();
                break;
              }
              case 6: {
                message.phoneNumber = reader.string();
                break;
              }
              case 7: {
                message.relationship = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.NotificationContact) {
            return object;
          }
          const message = new $root.healthie.v1.NotificationContact();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.isEmergencyContact != null) {
            message.isEmergencyContact = Boolean(object.isEmergencyContact);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.relationship != null) {
            message.relationship = String(object.relationship);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.isEmergencyContact = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (
            message.emailAddress != null &&
            message.hasOwnProperty('emailAddress')
          ) {
            object.emailAddress = message.emailAddress;
          }
          if (
            message.isEmergencyContact != null &&
            message.hasOwnProperty('isEmergencyContact')
          ) {
            object.isEmergencyContact = message.isEmergencyContact;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          if (
            message.relationship != null &&
            message.hasOwnProperty('relationship')
          ) {
            object.relationship = message.relationship;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.NotificationContact';
        }
      }

      NotificationContact.prototype.id = '';
      NotificationContact.prototype.firstName = null;
      NotificationContact.prototype.lastName = null;
      NotificationContact.prototype.emailAddress = null;
      NotificationContact.prototype.isEmergencyContact = false;
      NotificationContact.prototype.phoneNumber = null;
      NotificationContact.prototype.relationship = null;

      return NotificationContact;
    })();

    v1.Provider = (() => {
      class Provider {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Provider(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.qualifications != null &&
            Object.hasOwnProperty.call(message, 'qualifications')
          ) {
            writer.uint32(26).string(message.qualifications);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(34).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(42).string(message.lastName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Provider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.qualifications = reader.string();
                break;
              }
              case 4: {
                message.firstName = reader.string();
                break;
              }
              case 5: {
                message.lastName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Provider) {
            return object;
          }
          const message = new $root.healthie.v1.Provider();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.qualifications != null) {
            message.qualifications = String(object.qualifications);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.qualifications != null &&
            message.hasOwnProperty('qualifications')
          ) {
            object.qualifications = message.qualifications;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Provider';
        }
      }

      Provider.prototype.id = '';
      Provider.prototype.name = null;
      Provider.prototype.qualifications = null;
      Provider.prototype.firstName = null;
      Provider.prototype.lastName = null;

      return Provider;
    })();

    v1.CourseMembership = (() => {
      class CourseMembership {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CourseMembership(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.courseId != null &&
            Object.hasOwnProperty.call(message, 'courseId')
          ) {
            writer.uint32(10).string(message.courseId);
          }
          if (
            message.courseName != null &&
            Object.hasOwnProperty.call(message, 'courseName')
          ) {
            writer.uint32(18).string(message.courseName);
          }
          if (
            message.courseStatus != null &&
            Object.hasOwnProperty.call(message, 'courseStatus')
          ) {
            writer.uint32(26).string(message.courseStatus);
          }
          if (
            message.startedAt != null &&
            Object.hasOwnProperty.call(message, 'startedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.startedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CourseMembership();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.courseId = reader.string();
                break;
              }
              case 2: {
                message.courseName = reader.string();
                break;
              }
              case 3: {
                message.courseStatus = reader.string();
                break;
              }
              case 4: {
                message.startedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CourseMembership) {
            return object;
          }
          const message = new $root.healthie.v1.CourseMembership();
          if (object.courseId != null) {
            message.courseId = String(object.courseId);
          }
          if (object.courseName != null) {
            message.courseName = String(object.courseName);
          }
          if (object.courseStatus != null) {
            message.courseStatus = String(object.courseStatus);
          }
          if (object.startedAt != null) {
            if (typeof object.startedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.CourseMembership.startedAt: object expected, but got ' +
                  typeof object.startedAt
              );
            }
            message.startedAt = timing$1.v1.Timestamp.fromObject(
              object.startedAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.CourseMembership.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.courseId = '';
            object.courseStatus = '';
            object.updatedAt = null;
          }
          let keys;
          if (message.courseId != null && message.hasOwnProperty('courseId')) {
            object.courseId = message.courseId;
          }
          if (
            message.courseName != null &&
            message.hasOwnProperty('courseName')
          ) {
            object.courseName = message.courseName;
          }
          if (
            message.courseStatus != null &&
            message.hasOwnProperty('courseStatus')
          ) {
            object.courseStatus = message.courseStatus;
          }
          if (
            message.startedAt != null &&
            message.hasOwnProperty('startedAt')
          ) {
            object.startedAt = timing$1.v1.Timestamp.toObject(
              message.startedAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CourseMembership';
        }
      }

      CourseMembership.prototype.courseId = '';
      CourseMembership.prototype.courseName = null;
      CourseMembership.prototype.courseStatus = '';
      CourseMembership.prototype.startedAt = null;
      CourseMembership.prototype.updatedAt = null;

      return CourseMembership;
    })();

    v1.User = (() => {
      class User {
        constructor(properties) {
          this.policies = [];
          this.diagnoses = [];
          this.referringPhysicianEmails = [];
          this.notificationContacts = [];
          this.activeTags = [];
          this.otherProviderIds = [];
          this.providers = [];
          this.courseMemberships = [];
          this.referringPhysicians = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new User(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.recordIdentifier != null &&
            Object.hasOwnProperty.call(message, 'recordIdentifier')
          ) {
            writer.uint32(18).string(message.recordIdentifier);
          }
          if (
            message.active != null &&
            Object.hasOwnProperty.call(message, 'active')
          ) {
            writer.uint32(24).bool(message.active);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(34).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(42).string(message.lastName);
          }
          if (
            message.dateOfBirth != null &&
            Object.hasOwnProperty.call(message, 'dateOfBirth')
          ) {
            values$1.v1.Date.encode(
              message.dateOfBirth,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(58).string(message.phoneNumber);
          }
          if (
            message.emailAddress != null &&
            Object.hasOwnProperty.call(message, 'emailAddress')
          ) {
            writer.uint32(66).string(message.emailAddress);
          }
          if (
            message.sexAssignedAtBirth != null &&
            Object.hasOwnProperty.call(message, 'sexAssignedAtBirth')
          ) {
            writer.uint32(74).string(message.sexAssignedAtBirth);
          }
          if (
            message.gender != null &&
            Object.hasOwnProperty.call(message, 'gender')
          ) {
            writer.uint32(82).string(message.gender);
          }
          if (
            message.genderIdentity != null &&
            Object.hasOwnProperty.call(message, 'genderIdentity')
          ) {
            writer.uint32(90).string(message.genderIdentity);
          }
          if (
            message.pronouns != null &&
            Object.hasOwnProperty.call(message, 'pronouns')
          ) {
            writer.uint32(98).string(message.pronouns);
          }
          if (
            message.userGroup != null &&
            Object.hasOwnProperty.call(message, 'userGroup')
          ) {
            $root.healthie.v1.UserGroup.encode(
              message.userGroup,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.policies != null &&
            Object.hasOwnProperty.call(message, 'policies')
          ) {
            for (const element of message.policies) {
              $root.healthie.v1.Policy.encode(
                element,
                writer.uint32(114).fork()
              ).ldelim();
            }
          }
          if (
            message.insuranceAuthorization != null &&
            Object.hasOwnProperty.call(message, 'insuranceAuthorization')
          ) {
            $root.healthie.v1.UserInsuranceAuthorization.encode(
              message.insuranceAuthorization,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.timezone != null &&
            Object.hasOwnProperty.call(message, 'timezone')
          ) {
            writer.uint32(130).string(message.timezone);
          }
          if (
            message.location != null &&
            Object.hasOwnProperty.call(message, 'location')
          ) {
            $root.healthie.v1.Location.encode(
              message.location,
              writer.uint32(138).fork()
            ).ldelim();
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(146).fork()
            ).ldelim();
          }
          if (
            message.defaultStripeCustomerDetailId != null &&
            Object.hasOwnProperty.call(message, 'defaultStripeCustomerDetailId')
          ) {
            writer.uint32(154).string(message.defaultStripeCustomerDetailId);
          }
          if (
            message.diagnoses != null &&
            Object.hasOwnProperty.call(message, 'diagnoses')
          ) {
            for (const element of message.diagnoses) {
              $root.healthie.v1.Diagnosis.encode(
                element,
                writer.uint32(162).fork()
              ).ldelim();
            }
          }
          if (
            message.hasCreatedPassword != null &&
            Object.hasOwnProperty.call(message, 'hasCreatedPassword')
          ) {
            writer.uint32(168).bool(message.hasCreatedPassword);
          }
          if (
            message.lastActive != null &&
            Object.hasOwnProperty.call(message, 'lastActive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lastActive,
              writer.uint32(178).fork()
            ).ldelim();
          }
          if (
            message.moneyOwed != null &&
            Object.hasOwnProperty.call(message, 'moneyOwed')
          ) {
            writer.uint32(186).string(message.moneyOwed);
          }
          if (
            message.nextRequiredStep != null &&
            Object.hasOwnProperty.call(message, 'nextRequiredStep')
          ) {
            writer.uint32(194).string(message.nextRequiredStep);
          }
          if (
            message.clientReadableSource != null &&
            Object.hasOwnProperty.call(message, 'clientReadableSource')
          ) {
            writer.uint32(202).string(message.clientReadableSource);
          }
          if (
            message.referringPhysicianEmails != null &&
            Object.hasOwnProperty.call(message, 'referringPhysicianEmails')
          ) {
            for (const element of message.referringPhysicianEmails) {
              writer.uint32(210).string(element);
            }
          }
          if (
            message.referringProviderName != null &&
            Object.hasOwnProperty.call(message, 'referringProviderName')
          ) {
            writer.uint32(218).string(message.referringProviderName);
          }
          if (
            message.unconfirmedAppointmentCount != null &&
            Object.hasOwnProperty.call(message, 'unconfirmedAppointmentCount')
          ) {
            writer.uint32(224).uint32(message.unconfirmedAppointmentCount);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(234).string(message.dieticianOrganizationId);
          }
          if (
            message.heightMeters != null &&
            Object.hasOwnProperty.call(message, 'heightMeters')
          ) {
            writer.uint32(241).double(message.heightMeters);
          }
          if (
            message.weightKilograms != null &&
            Object.hasOwnProperty.call(message, 'weightKilograms')
          ) {
            writer.uint32(249).double(message.weightKilograms);
          }
          if (
            message.quickNotes != null &&
            Object.hasOwnProperty.call(message, 'quickNotes')
          ) {
            writer.uint32(258).string(message.quickNotes);
          }
          if (
            message.notificationContacts != null &&
            Object.hasOwnProperty.call(message, 'notificationContacts')
          ) {
            for (const element of message.notificationContacts) {
              $root.healthie.v1.NotificationContact.encode(
                element,
                writer.uint32(266).fork()
              ).ldelim();
            }
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(274).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(282).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(290).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(298).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(306).string(message.dietitianId);
          }
          if (
            message.sex != null &&
            Object.hasOwnProperty.call(message, 'sex')
          ) {
            writer.uint32(314).string(message.sex);
          }
          if (
            message.sexualOrientation != null &&
            Object.hasOwnProperty.call(message, 'sexualOrientation')
          ) {
            writer.uint32(322).string(message.sexualOrientation);
          }
          if (
            message.primaryRace != null &&
            Object.hasOwnProperty.call(message, 'primaryRace')
          ) {
            writer.uint32(330).string(message.primaryRace);
          }
          if (
            message.activeTags != null &&
            Object.hasOwnProperty.call(message, 'activeTags')
          ) {
            for (const element of message.activeTags) {
              writer.uint32(338).string(element);
            }
          }
          if (
            message.otherProviderIds != null &&
            Object.hasOwnProperty.call(message, 'otherProviderIds')
          ) {
            for (const element of message.otherProviderIds) {
              writer.uint32(346).string(element);
            }
          }
          if (
            message.providers != null &&
            Object.hasOwnProperty.call(message, 'providers')
          ) {
            for (const element of message.providers) {
              $root.healthie.v1.Provider.encode(
                element,
                writer.uint32(354).fork()
              ).ldelim();
            }
          }
          if (
            message.courseMemberships != null &&
            Object.hasOwnProperty.call(message, 'courseMemberships')
          ) {
            for (const element of message.courseMemberships) {
              $root.healthie.v1.CourseMembership.encode(
                element,
                writer.uint32(362).fork()
              ).ldelim();
            }
          }
          if (
            message.nextApptDate != null &&
            Object.hasOwnProperty.call(message, 'nextApptDate')
          ) {
            timing$1.v1.Timestamp.encode(
              message.nextApptDate,
              writer.uint32(370).fork()
            ).ldelim();
          }
          if (
            message.primaryRaceCode != null &&
            Object.hasOwnProperty.call(message, 'primaryRaceCode')
          ) {
            writer.uint32(378).string(message.primaryRaceCode);
          }
          if (
            message.primaryEthnicityCode != null &&
            Object.hasOwnProperty.call(message, 'primaryEthnicityCode')
          ) {
            writer.uint32(386).string(message.primaryEthnicityCode);
          }
          if (
            message.lastApptDate != null &&
            Object.hasOwnProperty.call(message, 'lastApptDate')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lastApptDate,
              writer.uint32(394).fork()
            ).ldelim();
          }
          if (
            message.clientSource != null &&
            Object.hasOwnProperty.call(message, 'clientSource')
          ) {
            $root.healthie.v1.ClientSource.encode(
              message.clientSource,
              writer.uint32(402).fork()
            ).ldelim();
          }
          if (
            message.referringPhysicians != null &&
            Object.hasOwnProperty.call(message, 'referringPhysicians')
          ) {
            for (const element of message.referringPhysicians) {
              $root.healthie.v1.ReferringProvider.encode(
                element,
                writer.uint32(410).fork()
              ).ldelim();
            }
          }
          if (
            message.setPasswordLink != null &&
            Object.hasOwnProperty.call(message, 'setPasswordLink')
          ) {
            writer.uint32(418).string(message.setPasswordLink);
          }
          if (
            message.nextApptId != null &&
            Object.hasOwnProperty.call(message, 'nextApptId')
          ) {
            writer.uint32(426).string(message.nextApptId);
          }
          if (
            message.lastApptId != null &&
            Object.hasOwnProperty.call(message, 'lastApptId')
          ) {
            writer.uint32(434).string(message.lastApptId);
          }
          if (
            message.legalName != null &&
            Object.hasOwnProperty.call(message, 'legalName')
          ) {
            writer.uint32(442).string(message.legalName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new User();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.recordIdentifier = reader.string();
                break;
              }
              case 3: {
                message.active = reader.bool();
                break;
              }
              case 4: {
                message.firstName = reader.string();
                break;
              }
              case 5: {
                message.lastName = reader.string();
                break;
              }
              case 6: {
                message.dateOfBirth = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.phoneNumber = reader.string();
                break;
              }
              case 8: {
                message.emailAddress = reader.string();
                break;
              }
              case 9: {
                message.sexAssignedAtBirth = reader.string();
                break;
              }
              case 10: {
                message.gender = reader.string();
                break;
              }
              case 11: {
                message.genderIdentity = reader.string();
                break;
              }
              case 12: {
                message.pronouns = reader.string();
                break;
              }
              case 13: {
                message.userGroup = $root.healthie.v1.UserGroup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 14: {
                if (!message.policies || !message.policies.length) {
                  message.policies = [];
                }
                message.policies.push(
                  $root.healthie.v1.Policy.decode(reader, reader.uint32())
                );
                break;
              }
              case 15: {
                message.insuranceAuthorization =
                  $root.healthie.v1.UserInsuranceAuthorization.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 16: {
                message.timezone = reader.string();
                break;
              }
              case 17: {
                message.location = $root.healthie.v1.Location.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 18: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 19: {
                message.defaultStripeCustomerDetailId = reader.string();
                break;
              }
              case 20: {
                if (!message.diagnoses || !message.diagnoses.length) {
                  message.diagnoses = [];
                }
                message.diagnoses.push(
                  $root.healthie.v1.Diagnosis.decode(reader, reader.uint32())
                );
                break;
              }
              case 21: {
                message.hasCreatedPassword = reader.bool();
                break;
              }
              case 22: {
                message.lastActive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 23: {
                message.moneyOwed = reader.string();
                break;
              }
              case 24: {
                message.nextRequiredStep = reader.string();
                break;
              }
              case 25: {
                message.clientReadableSource = reader.string();
                break;
              }
              case 26: {
                if (
                  !message.referringPhysicianEmails ||
                  !message.referringPhysicianEmails.length
                ) {
                  message.referringPhysicianEmails = [];
                }
                message.referringPhysicianEmails.push(reader.string());
                break;
              }
              case 27: {
                message.referringProviderName = reader.string();
                break;
              }
              case 28: {
                message.unconfirmedAppointmentCount = reader.uint32();
                break;
              }
              case 29: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 30: {
                message.heightMeters = reader.double();
                break;
              }
              case 31: {
                message.weightKilograms = reader.double();
                break;
              }
              case 32: {
                message.quickNotes = reader.string();
                break;
              }
              case 33: {
                if (
                  !message.notificationContacts ||
                  !message.notificationContacts.length
                ) {
                  message.notificationContacts = [];
                }
                message.notificationContacts.push(
                  $root.healthie.v1.NotificationContact.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 34: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 35: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 36: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 37: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 38: {
                message.dietitianId = reader.string();
                break;
              }
              case 39: {
                message.sex = reader.string();
                break;
              }
              case 40: {
                message.sexualOrientation = reader.string();
                break;
              }
              case 41: {
                message.primaryRace = reader.string();
                break;
              }
              case 42: {
                if (!message.activeTags || !message.activeTags.length) {
                  message.activeTags = [];
                }
                message.activeTags.push(reader.string());
                break;
              }
              case 43: {
                if (
                  !message.otherProviderIds ||
                  !message.otherProviderIds.length
                ) {
                  message.otherProviderIds = [];
                }
                message.otherProviderIds.push(reader.string());
                break;
              }
              case 44: {
                if (!message.providers || !message.providers.length) {
                  message.providers = [];
                }
                message.providers.push(
                  $root.healthie.v1.Provider.decode(reader, reader.uint32())
                );
                break;
              }
              case 45: {
                if (
                  !message.courseMemberships ||
                  !message.courseMemberships.length
                ) {
                  message.courseMemberships = [];
                }
                message.courseMemberships.push(
                  $root.healthie.v1.CourseMembership.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 46: {
                message.nextApptDate = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 47: {
                message.primaryRaceCode = reader.string();
                break;
              }
              case 48: {
                message.primaryEthnicityCode = reader.string();
                break;
              }
              case 49: {
                message.lastApptDate = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 50: {
                message.clientSource = $root.healthie.v1.ClientSource.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 51: {
                if (
                  !message.referringPhysicians ||
                  !message.referringPhysicians.length
                ) {
                  message.referringPhysicians = [];
                }
                message.referringPhysicians.push(
                  $root.healthie.v1.ReferringProvider.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 52: {
                message.setPasswordLink = reader.string();
                break;
              }
              case 53: {
                message.nextApptId = reader.string();
                break;
              }
              case 54: {
                message.lastApptId = reader.string();
                break;
              }
              case 55: {
                message.legalName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.User) {
            return object;
          }
          const message = new $root.healthie.v1.User();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.recordIdentifier != null) {
            message.recordIdentifier = String(object.recordIdentifier);
          }
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.dateOfBirth: object expected, but got ' +
                  typeof object.dateOfBirth
              );
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(
              object.dateOfBirth
            );
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.sexAssignedAtBirth != null) {
            message.sexAssignedAtBirth = String(object.sexAssignedAtBirth);
          }
          if (object.gender != null) {
            message.gender = String(object.gender);
          }
          if (object.genderIdentity != null) {
            message.genderIdentity = String(object.genderIdentity);
          }
          if (object.pronouns != null) {
            message.pronouns = String(object.pronouns);
          }
          if (object.userGroup != null) {
            if (typeof object.userGroup !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.userGroup: object expected, but got ' +
                  typeof object.userGroup
              );
            }
            message.userGroup = $root.healthie.v1.UserGroup.fromObject(
              object.userGroup
            );
          }
          if (object.policies) {
            if (!Array.isArray(object.policies)) {
              throw new TypeError(
                '.healthie.v1.User.policies: array type expected, but got ' +
                  typeof object.policies
              );
            }
            message.policies = new Array(object.policies.length);
            for (let i = 0; i < object.policies.length; ++i) {
              if (typeof object.policies[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.policies: object expected, but got ' +
                    typeof object.policies[i]
                );
              }
              message.policies[i] = $root.healthie.v1.Policy.fromObject(
                object.policies[i]
              );
            }
          }
          if (object.insuranceAuthorization != null) {
            if (typeof object.insuranceAuthorization !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.insuranceAuthorization: object expected, but got ' +
                  typeof object.insuranceAuthorization
              );
            }
            message.insuranceAuthorization =
              $root.healthie.v1.UserInsuranceAuthorization.fromObject(
                object.insuranceAuthorization
              );
          }
          if (object.timezone != null) {
            message.timezone = String(object.timezone);
          }
          if (object.location != null) {
            if (typeof object.location !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.location: object expected, but got ' +
                  typeof object.location
              );
            }
            message.location = $root.healthie.v1.Location.fromObject(
              object.location
            );
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.defaultStripeCustomerDetailId != null) {
            message.defaultStripeCustomerDetailId = String(
              object.defaultStripeCustomerDetailId
            );
          }
          if (object.diagnoses) {
            if (!Array.isArray(object.diagnoses)) {
              throw new TypeError(
                '.healthie.v1.User.diagnoses: array type expected, but got ' +
                  typeof object.diagnoses
              );
            }
            message.diagnoses = new Array(object.diagnoses.length);
            for (let i = 0; i < object.diagnoses.length; ++i) {
              if (typeof object.diagnoses[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.diagnoses: object expected, but got ' +
                    typeof object.diagnoses[i]
                );
              }
              message.diagnoses[i] = $root.healthie.v1.Diagnosis.fromObject(
                object.diagnoses[i]
              );
            }
          }
          if (object.hasCreatedPassword != null) {
            message.hasCreatedPassword = Boolean(object.hasCreatedPassword);
          }
          if (object.lastActive != null) {
            if (typeof object.lastActive !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.lastActive: object expected, but got ' +
                  typeof object.lastActive
              );
            }
            message.lastActive = timing$1.v1.Timestamp.fromObject(
              object.lastActive
            );
          }
          if (object.moneyOwed != null) {
            message.moneyOwed = String(object.moneyOwed);
          }
          if (object.nextRequiredStep != null) {
            message.nextRequiredStep = String(object.nextRequiredStep);
          }
          if (object.clientReadableSource != null) {
            message.clientReadableSource = String(object.clientReadableSource);
          }
          if (object.referringPhysicianEmails) {
            if (!Array.isArray(object.referringPhysicianEmails)) {
              throw new TypeError(
                '.healthie.v1.User.referringPhysicianEmails: array type expected, but got ' +
                  typeof object.referringPhysicianEmails
              );
            }
            message.referringPhysicianEmails = new Array(
              object.referringPhysicianEmails.length
            );
            for (let i = 0; i < object.referringPhysicianEmails.length; ++i) {
              message.referringPhysicianEmails[i] = String(
                object.referringPhysicianEmails[i]
              );
            }
          }
          if (object.referringProviderName != null) {
            message.referringProviderName = String(
              object.referringProviderName
            );
          }
          if (object.unconfirmedAppointmentCount != null) {
            message.unconfirmedAppointmentCount =
              object.unconfirmedAppointmentCount >>> 0;
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.heightMeters != null) {
            message.heightMeters = Number(object.heightMeters);
          }
          if (object.weightKilograms != null) {
            message.weightKilograms = Number(object.weightKilograms);
          }
          if (object.quickNotes != null) {
            message.quickNotes = String(object.quickNotes);
          }
          if (object.notificationContacts) {
            if (!Array.isArray(object.notificationContacts)) {
              throw new TypeError(
                '.healthie.v1.User.notificationContacts: array type expected, but got ' +
                  typeof object.notificationContacts
              );
            }
            message.notificationContacts = new Array(
              object.notificationContacts.length
            );
            for (let i = 0; i < object.notificationContacts.length; ++i) {
              if (typeof object.notificationContacts[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.notificationContacts: object expected, but got ' +
                    typeof object.notificationContacts[i]
                );
              }
              message.notificationContacts[i] =
                $root.healthie.v1.NotificationContact.fromObject(
                  object.notificationContacts[i]
                );
            }
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          if (object.sex != null) {
            message.sex = String(object.sex);
          }
          if (object.sexualOrientation != null) {
            message.sexualOrientation = String(object.sexualOrientation);
          }
          if (object.primaryRace != null) {
            message.primaryRace = String(object.primaryRace);
          }
          if (object.activeTags) {
            if (!Array.isArray(object.activeTags)) {
              throw new TypeError(
                '.healthie.v1.User.activeTags: array type expected, but got ' +
                  typeof object.activeTags
              );
            }
            message.activeTags = new Array(object.activeTags.length);
            for (let i = 0; i < object.activeTags.length; ++i) {
              message.activeTags[i] = String(object.activeTags[i]);
            }
          }
          if (object.otherProviderIds) {
            if (!Array.isArray(object.otherProviderIds)) {
              throw new TypeError(
                '.healthie.v1.User.otherProviderIds: array type expected, but got ' +
                  typeof object.otherProviderIds
              );
            }
            message.otherProviderIds = new Array(
              object.otherProviderIds.length
            );
            for (let i = 0; i < object.otherProviderIds.length; ++i) {
              message.otherProviderIds[i] = String(object.otherProviderIds[i]);
            }
          }
          if (object.providers) {
            if (!Array.isArray(object.providers)) {
              throw new TypeError(
                '.healthie.v1.User.providers: array type expected, but got ' +
                  typeof object.providers
              );
            }
            message.providers = new Array(object.providers.length);
            for (let i = 0; i < object.providers.length; ++i) {
              if (typeof object.providers[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.providers: object expected, but got ' +
                    typeof object.providers[i]
                );
              }
              message.providers[i] = $root.healthie.v1.Provider.fromObject(
                object.providers[i]
              );
            }
          }
          if (object.courseMemberships) {
            if (!Array.isArray(object.courseMemberships)) {
              throw new TypeError(
                '.healthie.v1.User.courseMemberships: array type expected, but got ' +
                  typeof object.courseMemberships
              );
            }
            message.courseMemberships = new Array(
              object.courseMemberships.length
            );
            for (let i = 0; i < object.courseMemberships.length; ++i) {
              if (typeof object.courseMemberships[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.courseMemberships: object expected, but got ' +
                    typeof object.courseMemberships[i]
                );
              }
              message.courseMemberships[i] =
                $root.healthie.v1.CourseMembership.fromObject(
                  object.courseMemberships[i]
                );
            }
          }
          if (object.nextApptDate != null) {
            if (typeof object.nextApptDate !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.nextApptDate: object expected, but got ' +
                  typeof object.nextApptDate
              );
            }
            message.nextApptDate = timing$1.v1.Timestamp.fromObject(
              object.nextApptDate
            );
          }
          if (object.primaryRaceCode != null) {
            message.primaryRaceCode = String(object.primaryRaceCode);
          }
          if (object.primaryEthnicityCode != null) {
            message.primaryEthnicityCode = String(object.primaryEthnicityCode);
          }
          if (object.lastApptDate != null) {
            if (typeof object.lastApptDate !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.lastApptDate: object expected, but got ' +
                  typeof object.lastApptDate
              );
            }
            message.lastApptDate = timing$1.v1.Timestamp.fromObject(
              object.lastApptDate
            );
          }
          if (object.clientSource != null) {
            if (typeof object.clientSource !== 'object') {
              throw new TypeError(
                '.healthie.v1.User.clientSource: object expected, but got ' +
                  typeof object.clientSource
              );
            }
            message.clientSource = $root.healthie.v1.ClientSource.fromObject(
              object.clientSource
            );
          }
          if (object.referringPhysicians) {
            if (!Array.isArray(object.referringPhysicians)) {
              throw new TypeError(
                '.healthie.v1.User.referringPhysicians: array type expected, but got ' +
                  typeof object.referringPhysicians
              );
            }
            message.referringPhysicians = new Array(
              object.referringPhysicians.length
            );
            for (let i = 0; i < object.referringPhysicians.length; ++i) {
              if (typeof object.referringPhysicians[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.User.referringPhysicians: object expected, but got ' +
                    typeof object.referringPhysicians[i]
                );
              }
              message.referringPhysicians[i] =
                $root.healthie.v1.ReferringProvider.fromObject(
                  object.referringPhysicians[i]
                );
            }
          }
          if (object.setPasswordLink != null) {
            message.setPasswordLink = String(object.setPasswordLink);
          }
          if (object.nextApptId != null) {
            message.nextApptId = String(object.nextApptId);
          }
          if (object.lastApptId != null) {
            message.lastApptId = String(object.lastApptId);
          }
          if (object.legalName != null) {
            message.legalName = String(object.legalName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.policies = [];
            object.diagnoses = [];
            object.referringPhysicianEmails = [];
            object.notificationContacts = [];
            object.activeTags = [];
            object.otherProviderIds = [];
            object.providers = [];
            object.courseMemberships = [];
            object.referringPhysicians = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.active = false;
            object.timing = null;
            object.hasCreatedPassword = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.recordIdentifier != null &&
            message.hasOwnProperty('recordIdentifier')
          ) {
            object.recordIdentifier = message.recordIdentifier;
          }
          if (message.active != null && message.hasOwnProperty('active')) {
            object.active = message.active;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (
            message.dateOfBirth != null &&
            message.hasOwnProperty('dateOfBirth')
          ) {
            object.dateOfBirth = values$1.v1.Date.toObject(
              message.dateOfBirth,
              options
            );
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          if (
            message.emailAddress != null &&
            message.hasOwnProperty('emailAddress')
          ) {
            object.emailAddress = message.emailAddress;
          }
          if (
            message.sexAssignedAtBirth != null &&
            message.hasOwnProperty('sexAssignedAtBirth')
          ) {
            object.sexAssignedAtBirth = message.sexAssignedAtBirth;
          }
          if (message.gender != null && message.hasOwnProperty('gender')) {
            object.gender = message.gender;
          }
          if (
            message.genderIdentity != null &&
            message.hasOwnProperty('genderIdentity')
          ) {
            object.genderIdentity = message.genderIdentity;
          }
          if (message.pronouns != null && message.hasOwnProperty('pronouns')) {
            object.pronouns = message.pronouns;
          }
          if (
            message.userGroup != null &&
            message.hasOwnProperty('userGroup')
          ) {
            object.userGroup = $root.healthie.v1.UserGroup.toObject(
              message.userGroup,
              options
            );
          }
          if (message.policies && message.policies.length) {
            object.policies = new Array(message.policies.length);
            for (let i = 0; i < message.policies.length; ++i) {
              object.policies[i] = $root.healthie.v1.Policy.toObject(
                message.policies[i],
                options
              );
            }
          }
          if (
            message.insuranceAuthorization != null &&
            message.hasOwnProperty('insuranceAuthorization')
          ) {
            object.insuranceAuthorization =
              $root.healthie.v1.UserInsuranceAuthorization.toObject(
                message.insuranceAuthorization,
                options
              );
          }
          if (message.timezone != null && message.hasOwnProperty('timezone')) {
            object.timezone = message.timezone;
          }
          if (message.location != null && message.hasOwnProperty('location')) {
            object.location = $root.healthie.v1.Location.toObject(
              message.location,
              options
            );
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (
            message.defaultStripeCustomerDetailId != null &&
            message.hasOwnProperty('defaultStripeCustomerDetailId')
          ) {
            object.defaultStripeCustomerDetailId =
              message.defaultStripeCustomerDetailId;
          }
          if (message.diagnoses && message.diagnoses.length) {
            object.diagnoses = new Array(message.diagnoses.length);
            for (let i = 0; i < message.diagnoses.length; ++i) {
              object.diagnoses[i] = $root.healthie.v1.Diagnosis.toObject(
                message.diagnoses[i],
                options
              );
            }
          }
          if (
            message.hasCreatedPassword != null &&
            message.hasOwnProperty('hasCreatedPassword')
          ) {
            object.hasCreatedPassword = message.hasCreatedPassword;
          }
          if (
            message.lastActive != null &&
            message.hasOwnProperty('lastActive')
          ) {
            object.lastActive = timing$1.v1.Timestamp.toObject(
              message.lastActive,
              options
            );
          }
          if (
            message.moneyOwed != null &&
            message.hasOwnProperty('moneyOwed')
          ) {
            object.moneyOwed = message.moneyOwed;
          }
          if (
            message.nextRequiredStep != null &&
            message.hasOwnProperty('nextRequiredStep')
          ) {
            object.nextRequiredStep = message.nextRequiredStep;
          }
          if (
            message.clientReadableSource != null &&
            message.hasOwnProperty('clientReadableSource')
          ) {
            object.clientReadableSource = message.clientReadableSource;
          }
          if (
            message.referringPhysicianEmails &&
            message.referringPhysicianEmails.length
          ) {
            object.referringPhysicianEmails = new Array(
              message.referringPhysicianEmails.length
            );
            for (let i = 0; i < message.referringPhysicianEmails.length; ++i) {
              object.referringPhysicianEmails[i] =
                message.referringPhysicianEmails[i];
            }
          }
          if (
            message.referringProviderName != null &&
            message.hasOwnProperty('referringProviderName')
          ) {
            object.referringProviderName = message.referringProviderName;
          }
          if (
            message.unconfirmedAppointmentCount != null &&
            message.hasOwnProperty('unconfirmedAppointmentCount')
          ) {
            object.unconfirmedAppointmentCount =
              message.unconfirmedAppointmentCount;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.heightMeters != null &&
            message.hasOwnProperty('heightMeters')
          ) {
            object.heightMeters =
              options.json && !isFinite(message.heightMeters)
                ? String(message.heightMeters)
                : message.heightMeters;
          }
          if (
            message.weightKilograms != null &&
            message.hasOwnProperty('weightKilograms')
          ) {
            object.weightKilograms =
              options.json && !isFinite(message.weightKilograms)
                ? String(message.weightKilograms)
                : message.weightKilograms;
          }
          if (
            message.quickNotes != null &&
            message.hasOwnProperty('quickNotes')
          ) {
            object.quickNotes = message.quickNotes;
          }
          if (
            message.notificationContacts &&
            message.notificationContacts.length
          ) {
            object.notificationContacts = new Array(
              message.notificationContacts.length
            );
            for (let i = 0; i < message.notificationContacts.length; ++i) {
              object.notificationContacts[i] =
                $root.healthie.v1.NotificationContact.toObject(
                  message.notificationContacts[i],
                  options
                );
            }
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          if (message.sex != null && message.hasOwnProperty('sex')) {
            object.sex = message.sex;
          }
          if (
            message.sexualOrientation != null &&
            message.hasOwnProperty('sexualOrientation')
          ) {
            object.sexualOrientation = message.sexualOrientation;
          }
          if (
            message.primaryRace != null &&
            message.hasOwnProperty('primaryRace')
          ) {
            object.primaryRace = message.primaryRace;
          }
          if (message.activeTags && message.activeTags.length) {
            object.activeTags = new Array(message.activeTags.length);
            for (let i = 0; i < message.activeTags.length; ++i) {
              object.activeTags[i] = message.activeTags[i];
            }
          }
          if (message.otherProviderIds && message.otherProviderIds.length) {
            object.otherProviderIds = new Array(
              message.otherProviderIds.length
            );
            for (let i = 0; i < message.otherProviderIds.length; ++i) {
              object.otherProviderIds[i] = message.otherProviderIds[i];
            }
          }
          if (message.providers && message.providers.length) {
            object.providers = new Array(message.providers.length);
            for (let i = 0; i < message.providers.length; ++i) {
              object.providers[i] = $root.healthie.v1.Provider.toObject(
                message.providers[i],
                options
              );
            }
          }
          if (message.courseMemberships && message.courseMemberships.length) {
            object.courseMemberships = new Array(
              message.courseMemberships.length
            );
            for (let i = 0; i < message.courseMemberships.length; ++i) {
              object.courseMemberships[i] =
                $root.healthie.v1.CourseMembership.toObject(
                  message.courseMemberships[i],
                  options
                );
            }
          }
          if (
            message.nextApptDate != null &&
            message.hasOwnProperty('nextApptDate')
          ) {
            object.nextApptDate = timing$1.v1.Timestamp.toObject(
              message.nextApptDate,
              options
            );
          }
          if (
            message.primaryRaceCode != null &&
            message.hasOwnProperty('primaryRaceCode')
          ) {
            object.primaryRaceCode = message.primaryRaceCode;
          }
          if (
            message.primaryEthnicityCode != null &&
            message.hasOwnProperty('primaryEthnicityCode')
          ) {
            object.primaryEthnicityCode = message.primaryEthnicityCode;
          }
          if (
            message.lastApptDate != null &&
            message.hasOwnProperty('lastApptDate')
          ) {
            object.lastApptDate = timing$1.v1.Timestamp.toObject(
              message.lastApptDate,
              options
            );
          }
          if (
            message.clientSource != null &&
            message.hasOwnProperty('clientSource')
          ) {
            object.clientSource = $root.healthie.v1.ClientSource.toObject(
              message.clientSource,
              options
            );
          }
          if (
            message.referringPhysicians &&
            message.referringPhysicians.length
          ) {
            object.referringPhysicians = new Array(
              message.referringPhysicians.length
            );
            for (let i = 0; i < message.referringPhysicians.length; ++i) {
              object.referringPhysicians[i] =
                $root.healthie.v1.ReferringProvider.toObject(
                  message.referringPhysicians[i],
                  options
                );
            }
          }
          if (
            message.setPasswordLink != null &&
            message.hasOwnProperty('setPasswordLink')
          ) {
            object.setPasswordLink = message.setPasswordLink;
          }
          if (
            message.nextApptId != null &&
            message.hasOwnProperty('nextApptId')
          ) {
            object.nextApptId = message.nextApptId;
          }
          if (
            message.lastApptId != null &&
            message.hasOwnProperty('lastApptId')
          ) {
            object.lastApptId = message.lastApptId;
          }
          if (
            message.legalName != null &&
            message.hasOwnProperty('legalName')
          ) {
            object.legalName = message.legalName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.User';
        }
      }

      User.prototype.id = '';
      User.prototype.recordIdentifier = null;
      User.prototype.active = false;
      User.prototype.firstName = null;
      User.prototype.lastName = null;
      User.prototype.dateOfBirth = null;
      User.prototype.phoneNumber = null;
      User.prototype.emailAddress = null;
      User.prototype.sexAssignedAtBirth = null;
      User.prototype.gender = null;
      User.prototype.genderIdentity = null;
      User.prototype.pronouns = null;
      User.prototype.userGroup = null;
      User.prototype.policies = $util.emptyArray;
      User.prototype.insuranceAuthorization = null;
      User.prototype.timezone = null;
      User.prototype.location = null;
      User.prototype.timing = null;
      User.prototype.defaultStripeCustomerDetailId = null;
      User.prototype.diagnoses = $util.emptyArray;
      User.prototype.hasCreatedPassword = false;
      User.prototype.lastActive = null;
      User.prototype.moneyOwed = null;
      User.prototype.nextRequiredStep = null;
      User.prototype.clientReadableSource = null;
      User.prototype.referringPhysicianEmails = $util.emptyArray;
      User.prototype.referringProviderName = null;
      User.prototype.unconfirmedAppointmentCount = null;
      User.prototype.dieticianOrganizationId = null;
      User.prototype.heightMeters = null;
      User.prototype.weightKilograms = null;
      User.prototype.quickNotes = null;
      User.prototype.notificationContacts = $util.emptyArray;
      User.prototype.dietitianOrganizationId = null;
      User.prototype.dietitianFirstName = null;
      User.prototype.dietitianLastName = null;
      User.prototype.dietitianEmailAddress = null;
      User.prototype.dietitianId = null;
      User.prototype.sex = null;
      User.prototype.sexualOrientation = null;
      User.prototype.primaryRace = null;
      User.prototype.activeTags = $util.emptyArray;
      User.prototype.otherProviderIds = $util.emptyArray;
      User.prototype.providers = $util.emptyArray;
      User.prototype.courseMemberships = $util.emptyArray;
      User.prototype.nextApptDate = null;
      User.prototype.primaryRaceCode = null;
      User.prototype.primaryEthnicityCode = null;
      User.prototype.lastApptDate = null;
      User.prototype.clientSource = null;
      User.prototype.referringPhysicians = $util.emptyArray;
      User.prototype.setPasswordLink = null;
      User.prototype.nextApptId = null;
      User.prototype.lastApptId = null;
      User.prototype.legalName = null;

      return User;
    })();

    v1.CustomModule = (() => {
      class CustomModule {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomModule(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.modType != null &&
            Object.hasOwnProperty.call(message, 'modType')
          ) {
            writer.uint32(18).string(message.modType);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(26).string(message.label);
          }
          if (
            message.subLabel != null &&
            Object.hasOwnProperty.call(message, 'subLabel')
          ) {
            writer.uint32(34).string(message.subLabel);
          }
          if (
            message.isRequired != null &&
            Object.hasOwnProperty.call(message, 'isRequired')
          ) {
            writer.uint32(40).bool(message.isRequired);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomModule();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.modType = reader.string();
                break;
              }
              case 3: {
                message.label = reader.string();
                break;
              }
              case 4: {
                message.subLabel = reader.string();
                break;
              }
              case 5: {
                message.isRequired = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CustomModule) {
            return object;
          }
          const message = new $root.healthie.v1.CustomModule();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.modType != null) {
            message.modType = String(object.modType);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.subLabel != null) {
            message.subLabel = String(object.subLabel);
          }
          if (object.isRequired != null) {
            message.isRequired = Boolean(object.isRequired);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.isRequired = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.modType != null && message.hasOwnProperty('modType')) {
            object.modType = message.modType;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.subLabel != null && message.hasOwnProperty('subLabel')) {
            object.subLabel = message.subLabel;
          }
          if (
            message.isRequired != null &&
            message.hasOwnProperty('isRequired')
          ) {
            object.isRequired = message.isRequired;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CustomModule';
        }
      }

      CustomModule.prototype.id = '';
      CustomModule.prototype.modType = null;
      CustomModule.prototype.label = null;
      CustomModule.prototype.subLabel = null;
      CustomModule.prototype.isRequired = false;

      return CustomModule;
    })();

    v1.CustomModuleForm = (() => {
      class CustomModuleForm {
        constructor(properties) {
          this.customModules = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomModuleForm(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.lastUpdatedByEmail != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedByEmail')
          ) {
            writer.uint32(26).string(message.lastUpdatedByEmail);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.customModules != null &&
            Object.hasOwnProperty.call(message, 'customModules')
          ) {
            for (const element of message.customModules) {
              $root.healthie.v1.CustomModule.encode(
                element,
                writer.uint32(42).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomModuleForm();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.lastUpdatedByEmail = reader.string();
                break;
              }
              case 4: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                if (!message.customModules || !message.customModules.length) {
                  message.customModules = [];
                }
                message.customModules.push(
                  $root.healthie.v1.CustomModule.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CustomModuleForm) {
            return object;
          }
          const message = new $root.healthie.v1.CustomModuleForm();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.lastUpdatedByEmail != null) {
            message.lastUpdatedByEmail = String(object.lastUpdatedByEmail);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.healthie.v1.CustomModuleForm.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.customModules) {
            if (!Array.isArray(object.customModules)) {
              throw new TypeError(
                '.healthie.v1.CustomModuleForm.customModules: array type expected, but got ' +
                  typeof object.customModules
              );
            }
            message.customModules = new Array(object.customModules.length);
            for (let i = 0; i < object.customModules.length; ++i) {
              if (typeof object.customModules[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.CustomModuleForm.customModules: object expected, but got ' +
                    typeof object.customModules[i]
                );
              }
              message.customModules[i] =
                $root.healthie.v1.CustomModule.fromObject(
                  object.customModules[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.customModules = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.timing = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.lastUpdatedByEmail != null &&
            message.hasOwnProperty('lastUpdatedByEmail')
          ) {
            object.lastUpdatedByEmail = message.lastUpdatedByEmail;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.customModules && message.customModules.length) {
            object.customModules = new Array(message.customModules.length);
            for (let i = 0; i < message.customModules.length; ++i) {
              object.customModules[i] = $root.healthie.v1.CustomModule.toObject(
                message.customModules[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CustomModuleForm';
        }
      }

      CustomModuleForm.prototype.id = '';
      CustomModuleForm.prototype.name = null;
      CustomModuleForm.prototype.lastUpdatedByEmail = null;
      CustomModuleForm.prototype.timing = null;
      CustomModuleForm.prototype.customModules = $util.emptyArray;

      return CustomModuleForm;
    })();

    v1.FormAnswer = (() => {
      class FormAnswer {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormAnswer(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.customModuleId != null &&
            Object.hasOwnProperty.call(message, 'customModuleId')
          ) {
            writer.uint32(18).string(message.customModuleId);
          }
          if (
            message.answer != null &&
            Object.hasOwnProperty.call(message, 'answer')
          ) {
            writer.uint32(26).string(message.answer);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(42).string(message.label);
          }
          if (
            message.readOnly != null &&
            Object.hasOwnProperty.call(message, 'readOnly')
          ) {
            writer.uint32(48).bool(message.readOnly);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormAnswer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.customModuleId = reader.string();
                break;
              }
              case 3: {
                message.answer = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.label = reader.string();
                break;
              }
              case 6: {
                message.readOnly = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.FormAnswer) {
            return object;
          }
          const message = new $root.healthie.v1.FormAnswer();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.customModuleId != null) {
            message.customModuleId = String(object.customModuleId);
          }
          if (object.answer != null) {
            message.answer = String(object.answer);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswer.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.readOnly != null) {
            message.readOnly = Boolean(object.readOnly);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.customModuleId = '';
            object.createdAt = null;
            object.readOnly = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.customModuleId != null &&
            message.hasOwnProperty('customModuleId')
          ) {
            object.customModuleId = message.customModuleId;
          }
          if (message.answer != null && message.hasOwnProperty('answer')) {
            object.answer = message.answer;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.readOnly != null && message.hasOwnProperty('readOnly')) {
            object.readOnly = message.readOnly;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.FormAnswer';
        }
      }

      FormAnswer.prototype.id = '';
      FormAnswer.prototype.customModuleId = '';
      FormAnswer.prototype.answer = null;
      FormAnswer.prototype.createdAt = null;
      FormAnswer.prototype.label = null;
      FormAnswer.prototype.readOnly = false;

      return FormAnswer;
    })();

    v1.FormAnswerGroupSigning = (() => {
      class FormAnswerGroupSigning {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormAnswerGroupSigning(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.userId != null &&
            Object.hasOwnProperty.call(message, 'userId')
          ) {
            writer.uint32(26).string(message.userId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormAnswerGroupSigning();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.userId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.FormAnswerGroupSigning) {
            return object;
          }
          const message = new $root.healthie.v1.FormAnswerGroupSigning();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroupSigning.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.createdAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (message.userId != null && message.hasOwnProperty('userId')) {
            object.userId = message.userId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.FormAnswerGroupSigning';
        }
      }

      FormAnswerGroupSigning.prototype.id = '';
      FormAnswerGroupSigning.prototype.createdAt = null;
      FormAnswerGroupSigning.prototype.userId = null;

      return FormAnswerGroupSigning;
    })();

    v1.FormAnswerGroupAutoscoredSection = (() => {
      class FormAnswerGroupAutoscoredSection {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormAnswerGroupAutoscoredSection(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sectionKey != null &&
            Object.hasOwnProperty.call(message, 'sectionKey')
          ) {
            writer.uint32(10).string(message.sectionKey);
          }
          if (
            message.sectionTitle != null &&
            Object.hasOwnProperty.call(message, 'sectionTitle')
          ) {
            writer.uint32(18).string(message.sectionTitle);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(25).double(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormAnswerGroupAutoscoredSection();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sectionKey = reader.string();
                break;
              }
              case 2: {
                message.sectionTitle = reader.string();
                break;
              }
              case 3: {
                message.value = reader.double();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.FormAnswerGroupAutoscoredSection
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.FormAnswerGroupAutoscoredSection();
          if (object.sectionKey != null) {
            message.sectionKey = String(object.sectionKey);
          }
          if (object.sectionTitle != null) {
            message.sectionTitle = String(object.sectionTitle);
          }
          if (object.value != null) {
            message.value = Number(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.sectionKey != null &&
            message.hasOwnProperty('sectionKey')
          ) {
            object.sectionKey = message.sectionKey;
          }
          if (
            message.sectionTitle != null &&
            message.hasOwnProperty('sectionTitle')
          ) {
            object.sectionTitle = message.sectionTitle;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value =
              options.json && !isFinite(message.value)
                ? String(message.value)
                : message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.FormAnswerGroupAutoscoredSection';
        }
      }

      FormAnswerGroupAutoscoredSection.prototype.sectionKey = null;
      FormAnswerGroupAutoscoredSection.prototype.sectionTitle = null;
      FormAnswerGroupAutoscoredSection.prototype.value = null;

      return FormAnswerGroupAutoscoredSection;
    })();

    v1.FormAnswerGroup = (() => {
      class FormAnswerGroup {
        constructor(properties) {
          this.formAnswers = {};
          this.signings = [];
          this.autoscoredSections = [];
          this.orderedAnswers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormAnswerGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formAnswerGroupId != null &&
            Object.hasOwnProperty.call(message, 'formAnswerGroupId')
          ) {
            writer.uint32(10).string(message.formAnswerGroupId);
          }
          if (
            message.userId != null &&
            Object.hasOwnProperty.call(message, 'userId')
          ) {
            writer.uint32(18).string(message.userId);
          }
          if (
            message.customModuleFormId != null &&
            Object.hasOwnProperty.call(message, 'customModuleFormId')
          ) {
            writer.uint32(26).string(message.customModuleFormId);
          }
          if (
            message.fillerId != null &&
            Object.hasOwnProperty.call(message, 'fillerId')
          ) {
            writer.uint32(34).string(message.fillerId);
          }
          if (
            message.fillerIsPatient != null &&
            Object.hasOwnProperty.call(message, 'fillerIsPatient')
          ) {
            writer.uint32(40).bool(message.fillerIsPatient);
          }
          if (
            message.fillerEmail != null &&
            Object.hasOwnProperty.call(message, 'fillerEmail')
          ) {
            writer.uint32(50).string(message.fillerEmail);
          }
          if (
            message.finished != null &&
            Object.hasOwnProperty.call(message, 'finished')
          ) {
            writer.uint32(56).bool(message.finished);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.lockedAt != null &&
            Object.hasOwnProperty.call(message, 'lockedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lockedAt,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.lockedById != null &&
            Object.hasOwnProperty.call(message, 'lockedById')
          ) {
            writer.uint32(82).string(message.lockedById);
          }
          if (
            message.lockedByEmail != null &&
            Object.hasOwnProperty.call(message, 'lockedByEmail')
          ) {
            writer.uint32(90).string(message.lockedByEmail);
          }
          if (
            message.deletedAt != null &&
            Object.hasOwnProperty.call(message, 'deletedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.deletedAt,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.formAnswers != null &&
            Object.hasOwnProperty.call(message, 'formAnswers')
          ) {
            for (const key of Object.keys(message.formAnswers)) {
              writer.uint32(106).fork().uint32(10).string(key);
              $root.healthie.v1.FormAnswer.encode(
                message.formAnswers[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          if (
            message.formAnswerGroupName != null &&
            Object.hasOwnProperty.call(message, 'formAnswerGroupName')
          ) {
            writer.uint32(114).string(message.formAnswerGroupName);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(122).string(message.dieticianOrganizationId);
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(130).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(138).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(146).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(154).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(162).string(message.dietitianId);
          }
          if (
            message.signings != null &&
            Object.hasOwnProperty.call(message, 'signings')
          ) {
            for (const element of message.signings) {
              $root.healthie.v1.FormAnswerGroupSigning.encode(
                element,
                writer.uint32(170).fork()
              ).ldelim();
            }
          }
          if (
            message.autoscoredSections != null &&
            Object.hasOwnProperty.call(message, 'autoscoredSections')
          ) {
            for (const element of message.autoscoredSections) {
              $root.healthie.v1.FormAnswerGroupAutoscoredSection.encode(
                element,
                writer.uint32(178).fork()
              ).ldelim();
            }
          }
          if (
            message.useForCharting != null &&
            Object.hasOwnProperty.call(message, 'useForCharting')
          ) {
            writer.uint32(184).bool(message.useForCharting);
          }
          if (
            message.orderedAnswers != null &&
            Object.hasOwnProperty.call(message, 'orderedAnswers')
          ) {
            for (const element of message.orderedAnswers) {
              $root.healthie.v1.FormAnswer.encode(
                element,
                writer.uint32(194).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormAnswerGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formAnswerGroupId = reader.string();
                break;
              }
              case 2: {
                message.userId = reader.string();
                break;
              }
              case 3: {
                message.customModuleFormId = reader.string();
                break;
              }
              case 4: {
                message.fillerId = reader.string();
                break;
              }
              case 5: {
                message.fillerIsPatient = reader.bool();
                break;
              }
              case 6: {
                message.fillerEmail = reader.string();
                break;
              }
              case 7: {
                message.finished = reader.bool();
                break;
              }
              case 8: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.lockedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.lockedById = reader.string();
                break;
              }
              case 11: {
                message.lockedByEmail = reader.string();
                break;
              }
              case 12: {
                message.deletedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 13: {
                if (message.formAnswers === $util.emptyObject)
                  message.formAnswers = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = $root.healthie.v1.FormAnswer.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                  }
                  message.formAnswers[key] = value;
                }
                break;
              }
              case 14: {
                message.formAnswerGroupName = reader.string();
                break;
              }
              case 15: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 16: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 17: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 18: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 19: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 20: {
                message.dietitianId = reader.string();
                break;
              }
              case 21: {
                if (!message.signings || !message.signings.length) {
                  message.signings = [];
                }
                message.signings.push(
                  $root.healthie.v1.FormAnswerGroupSigning.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 22: {
                if (
                  !message.autoscoredSections ||
                  !message.autoscoredSections.length
                ) {
                  message.autoscoredSections = [];
                }
                message.autoscoredSections.push(
                  $root.healthie.v1.FormAnswerGroupAutoscoredSection.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 23: {
                message.useForCharting = reader.bool();
                break;
              }
              case 24: {
                if (!message.orderedAnswers || !message.orderedAnswers.length) {
                  message.orderedAnswers = [];
                }
                message.orderedAnswers.push(
                  $root.healthie.v1.FormAnswer.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.FormAnswerGroup) {
            return object;
          }
          const message = new $root.healthie.v1.FormAnswerGroup();
          if (object.formAnswerGroupId != null) {
            message.formAnswerGroupId = String(object.formAnswerGroupId);
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.customModuleFormId != null) {
            message.customModuleFormId = String(object.customModuleFormId);
          }
          if (object.fillerId != null) {
            message.fillerId = String(object.fillerId);
          }
          if (object.fillerIsPatient != null) {
            message.fillerIsPatient = Boolean(object.fillerIsPatient);
          }
          if (object.fillerEmail != null) {
            message.fillerEmail = String(object.fillerEmail);
          }
          if (object.finished != null) {
            message.finished = Boolean(object.finished);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.lockedAt != null) {
            if (typeof object.lockedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.lockedAt: object expected, but got ' +
                  typeof object.lockedAt
              );
            }
            message.lockedAt = timing$1.v1.Timestamp.fromObject(
              object.lockedAt
            );
          }
          if (object.lockedById != null) {
            message.lockedById = String(object.lockedById);
          }
          if (object.lockedByEmail != null) {
            message.lockedByEmail = String(object.lockedByEmail);
          }
          if (object.deletedAt != null) {
            if (typeof object.deletedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.deletedAt: object expected, but got ' +
                  typeof object.deletedAt
              );
            }
            message.deletedAt = timing$1.v1.Timestamp.fromObject(
              object.deletedAt
            );
          }
          if (object.formAnswers) {
            if (typeof object.formAnswers !== 'object') {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.formAnswers: object expected, but got ' +
                  typeof object.formAnswers
              );
            }
            message.formAnswers = {};
            for (
              let keys = Object.keys(object.formAnswers), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.formAnswers[keys[i]] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.FormAnswerGroup.value: object expected, but got ' +
                    typeof object.formAnswers[keys[i]]
                );
              }
              message.formAnswers[keys[i]] =
                $root.healthie.v1.FormAnswer.fromObject(
                  object.formAnswers[keys[i]]
                );
            }
          }
          if (object.formAnswerGroupName != null) {
            message.formAnswerGroupName = String(object.formAnswerGroupName);
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          if (object.signings) {
            if (!Array.isArray(object.signings)) {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.signings: array type expected, but got ' +
                  typeof object.signings
              );
            }
            message.signings = new Array(object.signings.length);
            for (let i = 0; i < object.signings.length; ++i) {
              if (typeof object.signings[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.FormAnswerGroup.signings: object expected, but got ' +
                    typeof object.signings[i]
                );
              }
              message.signings[i] =
                $root.healthie.v1.FormAnswerGroupSigning.fromObject(
                  object.signings[i]
                );
            }
          }
          if (object.autoscoredSections) {
            if (!Array.isArray(object.autoscoredSections)) {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.autoscoredSections: array type expected, but got ' +
                  typeof object.autoscoredSections
              );
            }
            message.autoscoredSections = new Array(
              object.autoscoredSections.length
            );
            for (let i = 0; i < object.autoscoredSections.length; ++i) {
              if (typeof object.autoscoredSections[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.FormAnswerGroup.autoscoredSections: object expected, but got ' +
                    typeof object.autoscoredSections[i]
                );
              }
              message.autoscoredSections[i] =
                $root.healthie.v1.FormAnswerGroupAutoscoredSection.fromObject(
                  object.autoscoredSections[i]
                );
            }
          }
          if (object.useForCharting != null) {
            message.useForCharting = Boolean(object.useForCharting);
          }
          if (object.orderedAnswers) {
            if (!Array.isArray(object.orderedAnswers)) {
              throw new TypeError(
                '.healthie.v1.FormAnswerGroup.orderedAnswers: array type expected, but got ' +
                  typeof object.orderedAnswers
              );
            }
            message.orderedAnswers = new Array(object.orderedAnswers.length);
            for (let i = 0; i < object.orderedAnswers.length; ++i) {
              if (typeof object.orderedAnswers[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.FormAnswerGroup.orderedAnswers: object expected, but got ' +
                    typeof object.orderedAnswers[i]
                );
              }
              message.orderedAnswers[i] =
                $root.healthie.v1.FormAnswer.fromObject(
                  object.orderedAnswers[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.signings = [];
            object.autoscoredSections = [];
            object.orderedAnswers = [];
          }
          if (options.objects || options.defaults) {
            object.formAnswers = {};
          }
          if (options.defaults) {
            object.formAnswerGroupId = '';
            object.userId = '';
            object.customModuleFormId = '';
            object.finished = false;
            object.timing = null;
            object.useForCharting = false;
          }
          let keys;
          if (
            message.formAnswerGroupId != null &&
            message.hasOwnProperty('formAnswerGroupId')
          ) {
            object.formAnswerGroupId = message.formAnswerGroupId;
          }
          if (message.userId != null && message.hasOwnProperty('userId')) {
            object.userId = message.userId;
          }
          if (
            message.customModuleFormId != null &&
            message.hasOwnProperty('customModuleFormId')
          ) {
            object.customModuleFormId = message.customModuleFormId;
          }
          if (message.fillerId != null && message.hasOwnProperty('fillerId')) {
            object.fillerId = message.fillerId;
          }
          if (
            message.fillerIsPatient != null &&
            message.hasOwnProperty('fillerIsPatient')
          ) {
            object.fillerIsPatient = message.fillerIsPatient;
          }
          if (
            message.fillerEmail != null &&
            message.hasOwnProperty('fillerEmail')
          ) {
            object.fillerEmail = message.fillerEmail;
          }
          if (message.finished != null && message.hasOwnProperty('finished')) {
            object.finished = message.finished;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.lockedAt != null && message.hasOwnProperty('lockedAt')) {
            object.lockedAt = timing$1.v1.Timestamp.toObject(
              message.lockedAt,
              options
            );
          }
          if (
            message.lockedById != null &&
            message.hasOwnProperty('lockedById')
          ) {
            object.lockedById = message.lockedById;
          }
          if (
            message.lockedByEmail != null &&
            message.hasOwnProperty('lockedByEmail')
          ) {
            object.lockedByEmail = message.lockedByEmail;
          }
          if (
            message.deletedAt != null &&
            message.hasOwnProperty('deletedAt')
          ) {
            object.deletedAt = timing$1.v1.Timestamp.toObject(
              message.deletedAt,
              options
            );
          }
          if (
            message.formAnswers &&
            (keys = Object.keys(message.formAnswers)).length
          ) {
            object.formAnswers = {};
            for (let i = 0; i < keys.length; ++i) {
              object.formAnswers[keys[i]] =
                $root.healthie.v1.FormAnswer.toObject(
                  message.formAnswers[keys[i]],
                  options
                );
            }
          }
          if (
            message.formAnswerGroupName != null &&
            message.hasOwnProperty('formAnswerGroupName')
          ) {
            object.formAnswerGroupName = message.formAnswerGroupName;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          if (message.signings && message.signings.length) {
            object.signings = new Array(message.signings.length);
            for (let i = 0; i < message.signings.length; ++i) {
              object.signings[i] =
                $root.healthie.v1.FormAnswerGroupSigning.toObject(
                  message.signings[i],
                  options
                );
            }
          }
          if (message.autoscoredSections && message.autoscoredSections.length) {
            object.autoscoredSections = new Array(
              message.autoscoredSections.length
            );
            for (let i = 0; i < message.autoscoredSections.length; ++i) {
              object.autoscoredSections[i] =
                $root.healthie.v1.FormAnswerGroupAutoscoredSection.toObject(
                  message.autoscoredSections[i],
                  options
                );
            }
          }
          if (
            message.useForCharting != null &&
            message.hasOwnProperty('useForCharting')
          ) {
            object.useForCharting = message.useForCharting;
          }
          if (message.orderedAnswers && message.orderedAnswers.length) {
            object.orderedAnswers = new Array(message.orderedAnswers.length);
            for (let i = 0; i < message.orderedAnswers.length; ++i) {
              object.orderedAnswers[i] = $root.healthie.v1.FormAnswer.toObject(
                message.orderedAnswers[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.FormAnswerGroup';
        }
      }

      FormAnswerGroup.prototype.formAnswerGroupId = '';
      FormAnswerGroup.prototype.userId = '';
      FormAnswerGroup.prototype.customModuleFormId = '';
      FormAnswerGroup.prototype.fillerId = null;
      FormAnswerGroup.prototype.fillerIsPatient = null;
      FormAnswerGroup.prototype.fillerEmail = null;
      FormAnswerGroup.prototype.finished = false;
      FormAnswerGroup.prototype.timing = null;
      FormAnswerGroup.prototype.lockedAt = null;
      FormAnswerGroup.prototype.lockedById = null;
      FormAnswerGroup.prototype.lockedByEmail = null;
      FormAnswerGroup.prototype.deletedAt = null;
      FormAnswerGroup.prototype.formAnswers = $util.emptyObject;
      FormAnswerGroup.prototype.formAnswerGroupName = null;
      FormAnswerGroup.prototype.dieticianOrganizationId = null;
      FormAnswerGroup.prototype.dietitianOrganizationId = null;
      FormAnswerGroup.prototype.dietitianFirstName = null;
      FormAnswerGroup.prototype.dietitianLastName = null;
      FormAnswerGroup.prototype.dietitianEmailAddress = null;
      FormAnswerGroup.prototype.dietitianId = null;
      FormAnswerGroup.prototype.signings = $util.emptyArray;
      FormAnswerGroup.prototype.autoscoredSections = $util.emptyArray;
      FormAnswerGroup.prototype.useForCharting = false;
      FormAnswerGroup.prototype.orderedAnswers = $util.emptyArray;

      return FormAnswerGroup;
    })();

    v1.AppointmentType = (() => {
      class AppointmentType {
        constructor(properties) {
          this.associatedProviderNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AppointmentType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.lengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'lengthMinutes')
          ) {
            writer.uint32(24).uint32(message.lengthMinutes);
          }
          if (
            message.userGroupId != null &&
            Object.hasOwnProperty.call(message, 'userGroupId')
          ) {
            writer.uint32(34).string(message.userGroupId);
          }
          if (
            message.associatedProviderNames != null &&
            Object.hasOwnProperty.call(message, 'associatedProviderNames')
          ) {
            for (const element of message.associatedProviderNames) {
              writer.uint32(42).string(element);
            }
          }
          if (
            message.deletedAt != null &&
            Object.hasOwnProperty.call(message, 'deletedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.deletedAt,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AppointmentType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.lengthMinutes = reader.uint32();
                break;
              }
              case 4: {
                message.userGroupId = reader.string();
                break;
              }
              case 5: {
                if (
                  !message.associatedProviderNames ||
                  !message.associatedProviderNames.length
                ) {
                  message.associatedProviderNames = [];
                }
                message.associatedProviderNames.push(reader.string());
                break;
              }
              case 6: {
                message.deletedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.AppointmentType) {
            return object;
          }
          const message = new $root.healthie.v1.AppointmentType();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = object.lengthMinutes >>> 0;
          }
          if (object.userGroupId != null) {
            message.userGroupId = String(object.userGroupId);
          }
          if (object.associatedProviderNames) {
            if (!Array.isArray(object.associatedProviderNames)) {
              throw new TypeError(
                '.healthie.v1.AppointmentType.associatedProviderNames: array type expected, but got ' +
                  typeof object.associatedProviderNames
              );
            }
            message.associatedProviderNames = new Array(
              object.associatedProviderNames.length
            );
            for (let i = 0; i < object.associatedProviderNames.length; ++i) {
              message.associatedProviderNames[i] = String(
                object.associatedProviderNames[i]
              );
            }
          }
          if (object.deletedAt != null) {
            if (typeof object.deletedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.AppointmentType.deletedAt: object expected, but got ' +
                  typeof object.deletedAt
              );
            }
            message.deletedAt = timing$1.v1.Timestamp.fromObject(
              object.deletedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.associatedProviderNames = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.lengthMinutes = 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.lengthMinutes != null &&
            message.hasOwnProperty('lengthMinutes')
          ) {
            object.lengthMinutes = message.lengthMinutes;
          }
          if (
            message.userGroupId != null &&
            message.hasOwnProperty('userGroupId')
          ) {
            object.userGroupId = message.userGroupId;
          }
          if (
            message.associatedProviderNames &&
            message.associatedProviderNames.length
          ) {
            object.associatedProviderNames = new Array(
              message.associatedProviderNames.length
            );
            for (let i = 0; i < message.associatedProviderNames.length; ++i) {
              object.associatedProviderNames[i] =
                message.associatedProviderNames[i];
            }
          }
          if (
            message.deletedAt != null &&
            message.hasOwnProperty('deletedAt')
          ) {
            object.deletedAt = timing$1.v1.Timestamp.toObject(
              message.deletedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.AppointmentType';
        }
      }

      AppointmentType.prototype.id = '';
      AppointmentType.prototype.name = '';
      AppointmentType.prototype.lengthMinutes = 0;
      AppointmentType.prototype.userGroupId = null;
      AppointmentType.prototype.associatedProviderNames = $util.emptyArray;
      AppointmentType.prototype.deletedAt = null;

      return AppointmentType;
    })();

    v1.Appointment = (() => {
      class Appointment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Appointment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointmentId != null &&
            Object.hasOwnProperty.call(message, 'appointmentId')
          ) {
            writer.uint32(10).string(message.appointmentId);
          }
          if (
            message.healthiePatientId != null &&
            Object.hasOwnProperty.call(message, 'healthiePatientId')
          ) {
            writer.uint32(18).string(message.healthiePatientId);
          }
          if (
            message.appointmentTypeId != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeId')
          ) {
            writer.uint32(26).string(message.appointmentTypeId);
          }
          if (
            message.appointmentTypeTitle != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeTitle')
          ) {
            writer.uint32(34).string(message.appointmentTypeTitle);
          }
          if (
            message.appointmentProviderConfirmed != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderConfirmed')
          ) {
            writer.uint32(40).bool(message.appointmentProviderConfirmed);
          }
          if (
            message.appointmentClientConfirmed != null &&
            Object.hasOwnProperty.call(message, 'appointmentClientConfirmed')
          ) {
            writer.uint32(48).bool(message.appointmentClientConfirmed);
          }
          if (
            message.appointmentAddToCalLink != null &&
            Object.hasOwnProperty.call(message, 'appointmentAddToCalLink')
          ) {
            writer.uint32(58).string(message.appointmentAddToCalLink);
          }
          if (
            message.appointmentLocationLabel != null &&
            Object.hasOwnProperty.call(message, 'appointmentLocationLabel')
          ) {
            writer.uint32(66).string(message.appointmentLocationLabel);
          }
          if (
            message.appointmentLocationType != null &&
            Object.hasOwnProperty.call(message, 'appointmentLocationType')
          ) {
            writer.uint32(74).string(message.appointmentLocationType);
          }
          if (
            message.appointmentLocationDetail != null &&
            Object.hasOwnProperty.call(message, 'appointmentLocationDetail')
          ) {
            writer.uint32(82).string(message.appointmentLocationDetail);
          }
          if (
            message.appointmentZoomMeetingId != null &&
            Object.hasOwnProperty.call(message, 'appointmentZoomMeetingId')
          ) {
            writer.uint32(98).string(message.appointmentZoomMeetingId);
          }
          if (
            message.appointmentProviderId != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderId')
          ) {
            writer.uint32(106).string(message.appointmentProviderId);
          }
          if (
            message.appointmentProviderFullName != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderFullName')
          ) {
            writer.uint32(114).string(message.appointmentProviderFullName);
          }
          if (
            message.appointmentProviderEmail != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderEmail')
          ) {
            writer.uint32(122).string(message.appointmentProviderEmail);
          }
          if (
            message.appointmentReason != null &&
            Object.hasOwnProperty.call(message, 'appointmentReason')
          ) {
            writer.uint32(130).string(message.appointmentReason);
          }
          if (
            message.appointmentDatetimeRaw != null &&
            Object.hasOwnProperty.call(message, 'appointmentDatetimeRaw')
          ) {
            writer.uint32(138).string(message.appointmentDatetimeRaw);
          }
          if (
            message.appointmentDatetime != null &&
            Object.hasOwnProperty.call(message, 'appointmentDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentDatetime,
              writer.uint32(146).fork()
            ).ldelim();
          }
          if (
            message.appointmentEndDatetime != null &&
            Object.hasOwnProperty.call(message, 'appointmentEndDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentEndDatetime,
              writer.uint32(154).fork()
            ).ldelim();
          }
          if (
            message.appointmentLengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'appointmentLengthMinutes')
          ) {
            writer.uint32(160).uint32(message.appointmentLengthMinutes);
          }
          if (
            message.appointmentStatus != null &&
            Object.hasOwnProperty.call(message, 'appointmentStatus')
          ) {
            writer.uint32(170).string(message.appointmentStatus);
          }
          if (
            message.appointmentStatusChangedAt != null &&
            Object.hasOwnProperty.call(message, 'appointmentStatusChangedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentStatusChangedAt,
              writer.uint32(178).fork()
            ).ldelim();
          }
          if (
            message.appointmentNotes != null &&
            Object.hasOwnProperty.call(message, 'appointmentNotes')
          ) {
            writer.uint32(186).string(message.appointmentNotes);
          }
          if (
            message.appointmentCreatedAt != null &&
            Object.hasOwnProperty.call(message, 'appointmentCreatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentCreatedAt,
              writer.uint32(194).fork()
            ).ldelim();
          }
          if (
            message.appointmentUpdatedAt != null &&
            Object.hasOwnProperty.call(message, 'appointmentUpdatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentUpdatedAt,
              writer.uint32(202).fork()
            ).ldelim();
          }
          if (
            message.appointmentDeletedAt != null &&
            Object.hasOwnProperty.call(message, 'appointmentDeletedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentDeletedAt,
              writer.uint32(210).fork()
            ).ldelim();
          }
          if (
            message.appointmentCreatedBy != null &&
            Object.hasOwnProperty.call(message, 'appointmentCreatedBy')
          ) {
            writer.uint32(218).string(message.appointmentCreatedBy);
          }
          if (
            message.appointmentTimezoneShortName != null &&
            Object.hasOwnProperty.call(message, 'appointmentTimezoneShortName')
          ) {
            writer.uint32(234).string(message.appointmentTimezoneShortName);
          }
          if (
            message.appointmentTimezoneOffset != null &&
            Object.hasOwnProperty.call(message, 'appointmentTimezoneOffset')
          ) {
            writer.uint32(242).string(message.appointmentTimezoneOffset);
          }
          if (
            message.appointmentTimezoneOffsetSecondsEastOfUtc != null &&
            Object.hasOwnProperty.call(
              message,
              'appointmentTimezoneOffsetSecondsEastOfUtc'
            )
          ) {
            writer
              .uint32(248)
              .int32(message.appointmentTimezoneOffsetSecondsEastOfUtc);
          }
          if (
            message.appointmentPatientTimezoneIdentifier != null &&
            Object.hasOwnProperty.call(
              message,
              'appointmentPatientTimezoneIdentifier'
            )
          ) {
            writer
              .uint32(258)
              .string(message.appointmentPatientTimezoneIdentifier);
          }
          if (
            message.healthiePatientRecordIdentifier != null &&
            Object.hasOwnProperty.call(
              message,
              'healthiePatientRecordIdentifier'
            )
          ) {
            writer.uint32(266).string(message.healthiePatientRecordIdentifier);
          }
          if (
            message.appointmentIsBlocker != null &&
            Object.hasOwnProperty.call(message, 'appointmentIsBlocker')
          ) {
            writer.uint32(272).bool(message.appointmentIsBlocker);
          }
          if (
            message.appointmentType != null &&
            Object.hasOwnProperty.call(message, 'appointmentType')
          ) {
            $root.healthie.v1.AppointmentType.encode(
              message.appointmentType,
              writer.uint32(282).fork()
            ).ldelim();
          }
          if (
            message.appointmentDayOfWeek != null &&
            Object.hasOwnProperty.call(message, 'appointmentDayOfWeek')
          ) {
            writer.uint32(290).string(message.appointmentDayOfWeek);
          }
          if (
            message.appointmentDayOfMonth != null &&
            Object.hasOwnProperty.call(message, 'appointmentDayOfMonth')
          ) {
            writer.uint32(298).string(message.appointmentDayOfMonth);
          }
          if (
            message.appointmentMonth != null &&
            Object.hasOwnProperty.call(message, 'appointmentMonth')
          ) {
            writer.uint32(306).string(message.appointmentMonth);
          }
          if (
            message.appointmentDate != null &&
            Object.hasOwnProperty.call(message, 'appointmentDate')
          ) {
            writer.uint32(314).string(message.appointmentDate);
          }
          if (
            message.appointmentTime != null &&
            Object.hasOwnProperty.call(message, 'appointmentTime')
          ) {
            writer.uint32(322).string(message.appointmentTime);
          }
          if (
            message.appointmentEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'appointmentEmailAddress')
          ) {
            writer.uint32(330).string(message.appointmentEmailAddress);
          }
          if (
            message.appointmentFirstName != null &&
            Object.hasOwnProperty.call(message, 'appointmentFirstName')
          ) {
            writer.uint32(338).string(message.appointmentFirstName);
          }
          if (
            message.appointmentLastName != null &&
            Object.hasOwnProperty.call(message, 'appointmentLastName')
          ) {
            writer.uint32(346).string(message.appointmentLastName);
          }
          if (
            message.appointmentPhoneNumber != null &&
            Object.hasOwnProperty.call(message, 'appointmentPhoneNumber')
          ) {
            writer.uint32(354).string(message.appointmentPhoneNumber);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(362).string(message.dieticianOrganizationId);
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(370).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(378).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(386).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(394).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(402).string(message.dietitianId);
          }
          if (
            message.appointmentMetadata != null &&
            Object.hasOwnProperty.call(message, 'appointmentMetadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.appointmentMetadata,
              writer.uint32(410).fork()
            ).ldelim();
          }
          if (
            message.actualDurationMinutes != null &&
            Object.hasOwnProperty.call(message, 'actualDurationMinutes')
          ) {
            writer.uint32(416).uint32(message.actualDurationMinutes);
          }
          if (
            message.lastUpdatedById != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedById')
          ) {
            writer.uint32(426).string(message.lastUpdatedById);
          }
          if (
            message.connectedChartNoteLocked != null &&
            Object.hasOwnProperty.call(message, 'connectedChartNoteLocked')
          ) {
            writer.uint32(432).bool(message.connectedChartNoteLocked);
          }
          if (
            message.connectedChartNoteString != null &&
            Object.hasOwnProperty.call(message, 'connectedChartNoteString')
          ) {
            writer.uint32(442).string(message.connectedChartNoteString);
          }
          if (
            message.statusLastUpdatedById != null &&
            Object.hasOwnProperty.call(message, 'statusLastUpdatedById')
          ) {
            writer.uint32(450).string(message.statusLastUpdatedById);
          }
          if (
            message.addToIcalLink != null &&
            Object.hasOwnProperty.call(message, 'addToIcalLink')
          ) {
            writer.uint32(458).string(message.addToIcalLink);
          }
          if (
            message.appointmentLegalName != null &&
            Object.hasOwnProperty.call(message, 'appointmentLegalName')
          ) {
            writer.uint32(466).string(message.appointmentLegalName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Appointment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentId = reader.string();
                break;
              }
              case 2: {
                message.healthiePatientId = reader.string();
                break;
              }
              case 3: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 4: {
                message.appointmentTypeTitle = reader.string();
                break;
              }
              case 5: {
                message.appointmentProviderConfirmed = reader.bool();
                break;
              }
              case 6: {
                message.appointmentClientConfirmed = reader.bool();
                break;
              }
              case 7: {
                message.appointmentAddToCalLink = reader.string();
                break;
              }
              case 8: {
                message.appointmentLocationLabel = reader.string();
                break;
              }
              case 9: {
                message.appointmentLocationType = reader.string();
                break;
              }
              case 10: {
                message.appointmentLocationDetail = reader.string();
                break;
              }
              case 12: {
                message.appointmentZoomMeetingId = reader.string();
                break;
              }
              case 13: {
                message.appointmentProviderId = reader.string();
                break;
              }
              case 14: {
                message.appointmentProviderFullName = reader.string();
                break;
              }
              case 15: {
                message.appointmentProviderEmail = reader.string();
                break;
              }
              case 16: {
                message.appointmentReason = reader.string();
                break;
              }
              case 17: {
                message.appointmentDatetimeRaw = reader.string();
                break;
              }
              case 18: {
                message.appointmentDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 19: {
                message.appointmentEndDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 20: {
                message.appointmentLengthMinutes = reader.uint32();
                break;
              }
              case 21: {
                message.appointmentStatus = reader.string();
                break;
              }
              case 22: {
                message.appointmentStatusChangedAt =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 23: {
                message.appointmentNotes = reader.string();
                break;
              }
              case 24: {
                message.appointmentCreatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 25: {
                message.appointmentUpdatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 26: {
                message.appointmentDeletedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 27: {
                message.appointmentCreatedBy = reader.string();
                break;
              }
              case 29: {
                message.appointmentTimezoneShortName = reader.string();
                break;
              }
              case 30: {
                message.appointmentTimezoneOffset = reader.string();
                break;
              }
              case 31: {
                message.appointmentTimezoneOffsetSecondsEastOfUtc =
                  reader.int32();
                break;
              }
              case 32: {
                message.appointmentPatientTimezoneIdentifier = reader.string();
                break;
              }
              case 33: {
                message.healthiePatientRecordIdentifier = reader.string();
                break;
              }
              case 34: {
                message.appointmentIsBlocker = reader.bool();
                break;
              }
              case 35: {
                message.appointmentType =
                  $root.healthie.v1.AppointmentType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 36: {
                message.appointmentDayOfWeek = reader.string();
                break;
              }
              case 37: {
                message.appointmentDayOfMonth = reader.string();
                break;
              }
              case 38: {
                message.appointmentMonth = reader.string();
                break;
              }
              case 39: {
                message.appointmentDate = reader.string();
                break;
              }
              case 40: {
                message.appointmentTime = reader.string();
                break;
              }
              case 41: {
                message.appointmentEmailAddress = reader.string();
                break;
              }
              case 42: {
                message.appointmentFirstName = reader.string();
                break;
              }
              case 43: {
                message.appointmentLastName = reader.string();
                break;
              }
              case 44: {
                message.appointmentPhoneNumber = reader.string();
                break;
              }
              case 45: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 46: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 47: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 48: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 49: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 50: {
                message.dietitianId = reader.string();
                break;
              }
              case 51: {
                message.appointmentMetadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 52: {
                message.actualDurationMinutes = reader.uint32();
                break;
              }
              case 53: {
                message.lastUpdatedById = reader.string();
                break;
              }
              case 54: {
                message.connectedChartNoteLocked = reader.bool();
                break;
              }
              case 55: {
                message.connectedChartNoteString = reader.string();
                break;
              }
              case 56: {
                message.statusLastUpdatedById = reader.string();
                break;
              }
              case 57: {
                message.addToIcalLink = reader.string();
                break;
              }
              case 58: {
                message.appointmentLegalName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Appointment) {
            return object;
          }
          const message = new $root.healthie.v1.Appointment();
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.healthiePatientId != null) {
            message.healthiePatientId = String(object.healthiePatientId);
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.appointmentTypeTitle != null) {
            message.appointmentTypeTitle = String(object.appointmentTypeTitle);
          }
          if (object.appointmentProviderConfirmed != null) {
            message.appointmentProviderConfirmed = Boolean(
              object.appointmentProviderConfirmed
            );
          }
          if (object.appointmentClientConfirmed != null) {
            message.appointmentClientConfirmed = Boolean(
              object.appointmentClientConfirmed
            );
          }
          if (object.appointmentAddToCalLink != null) {
            message.appointmentAddToCalLink = String(
              object.appointmentAddToCalLink
            );
          }
          if (object.appointmentLocationLabel != null) {
            message.appointmentLocationLabel = String(
              object.appointmentLocationLabel
            );
          }
          if (object.appointmentLocationType != null) {
            message.appointmentLocationType = String(
              object.appointmentLocationType
            );
          }
          if (object.appointmentLocationDetail != null) {
            message.appointmentLocationDetail = String(
              object.appointmentLocationDetail
            );
          }
          if (object.appointmentZoomMeetingId != null) {
            message.appointmentZoomMeetingId = String(
              object.appointmentZoomMeetingId
            );
          }
          if (object.appointmentProviderId != null) {
            message.appointmentProviderId = String(
              object.appointmentProviderId
            );
          }
          if (object.appointmentProviderFullName != null) {
            message.appointmentProviderFullName = String(
              object.appointmentProviderFullName
            );
          }
          if (object.appointmentProviderEmail != null) {
            message.appointmentProviderEmail = String(
              object.appointmentProviderEmail
            );
          }
          if (object.appointmentReason != null) {
            message.appointmentReason = String(object.appointmentReason);
          }
          if (object.appointmentDatetimeRaw != null) {
            message.appointmentDatetimeRaw = String(
              object.appointmentDatetimeRaw
            );
          }
          if (object.appointmentDatetime != null) {
            if (typeof object.appointmentDatetime !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentDatetime: object expected, but got ' +
                  typeof object.appointmentDatetime
              );
            }
            message.appointmentDatetime = timing$1.v1.Timestamp.fromObject(
              object.appointmentDatetime
            );
          }
          if (object.appointmentEndDatetime != null) {
            if (typeof object.appointmentEndDatetime !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentEndDatetime: object expected, but got ' +
                  typeof object.appointmentEndDatetime
              );
            }
            message.appointmentEndDatetime = timing$1.v1.Timestamp.fromObject(
              object.appointmentEndDatetime
            );
          }
          if (object.appointmentLengthMinutes != null) {
            message.appointmentLengthMinutes =
              object.appointmentLengthMinutes >>> 0;
          }
          if (object.appointmentStatus != null) {
            message.appointmentStatus = String(object.appointmentStatus);
          }
          if (object.appointmentStatusChangedAt != null) {
            if (typeof object.appointmentStatusChangedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentStatusChangedAt: object expected, but got ' +
                  typeof object.appointmentStatusChangedAt
              );
            }
            message.appointmentStatusChangedAt =
              timing$1.v1.Timestamp.fromObject(
                object.appointmentStatusChangedAt
              );
          }
          if (object.appointmentNotes != null) {
            message.appointmentNotes = String(object.appointmentNotes);
          }
          if (object.appointmentCreatedAt != null) {
            if (typeof object.appointmentCreatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentCreatedAt: object expected, but got ' +
                  typeof object.appointmentCreatedAt
              );
            }
            message.appointmentCreatedAt = timing$1.v1.Timestamp.fromObject(
              object.appointmentCreatedAt
            );
          }
          if (object.appointmentUpdatedAt != null) {
            if (typeof object.appointmentUpdatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentUpdatedAt: object expected, but got ' +
                  typeof object.appointmentUpdatedAt
              );
            }
            message.appointmentUpdatedAt = timing$1.v1.Timestamp.fromObject(
              object.appointmentUpdatedAt
            );
          }
          if (object.appointmentDeletedAt != null) {
            if (typeof object.appointmentDeletedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentDeletedAt: object expected, but got ' +
                  typeof object.appointmentDeletedAt
              );
            }
            message.appointmentDeletedAt = timing$1.v1.Timestamp.fromObject(
              object.appointmentDeletedAt
            );
          }
          if (object.appointmentCreatedBy != null) {
            message.appointmentCreatedBy = String(object.appointmentCreatedBy);
          }
          if (object.appointmentTimezoneShortName != null) {
            message.appointmentTimezoneShortName = String(
              object.appointmentTimezoneShortName
            );
          }
          if (object.appointmentTimezoneOffset != null) {
            message.appointmentTimezoneOffset = String(
              object.appointmentTimezoneOffset
            );
          }
          if (object.appointmentTimezoneOffsetSecondsEastOfUtc != null) {
            message.appointmentTimezoneOffsetSecondsEastOfUtc =
              object.appointmentTimezoneOffsetSecondsEastOfUtc | 0;
          }
          if (object.appointmentPatientTimezoneIdentifier != null) {
            message.appointmentPatientTimezoneIdentifier = String(
              object.appointmentPatientTimezoneIdentifier
            );
          }
          if (object.healthiePatientRecordIdentifier != null) {
            message.healthiePatientRecordIdentifier = String(
              object.healthiePatientRecordIdentifier
            );
          }
          if (object.appointmentIsBlocker != null) {
            message.appointmentIsBlocker = Boolean(object.appointmentIsBlocker);
          }
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentType: object expected, but got ' +
                  typeof object.appointmentType
              );
            }
            message.appointmentType =
              $root.healthie.v1.AppointmentType.fromObject(
                object.appointmentType
              );
          }
          if (object.appointmentDayOfWeek != null) {
            message.appointmentDayOfWeek = String(object.appointmentDayOfWeek);
          }
          if (object.appointmentDayOfMonth != null) {
            message.appointmentDayOfMonth = String(
              object.appointmentDayOfMonth
            );
          }
          if (object.appointmentMonth != null) {
            message.appointmentMonth = String(object.appointmentMonth);
          }
          if (object.appointmentDate != null) {
            message.appointmentDate = String(object.appointmentDate);
          }
          if (object.appointmentTime != null) {
            message.appointmentTime = String(object.appointmentTime);
          }
          if (object.appointmentEmailAddress != null) {
            message.appointmentEmailAddress = String(
              object.appointmentEmailAddress
            );
          }
          if (object.appointmentFirstName != null) {
            message.appointmentFirstName = String(object.appointmentFirstName);
          }
          if (object.appointmentLastName != null) {
            message.appointmentLastName = String(object.appointmentLastName);
          }
          if (object.appointmentPhoneNumber != null) {
            message.appointmentPhoneNumber = String(
              object.appointmentPhoneNumber
            );
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          if (object.appointmentMetadata != null) {
            if (typeof object.appointmentMetadata !== 'object') {
              throw new TypeError(
                '.healthie.v1.Appointment.appointmentMetadata: object expected, but got ' +
                  typeof object.appointmentMetadata
              );
            }
            message.appointmentMetadata = google$1.protobuf.Struct.fromObject(
              object.appointmentMetadata
            );
          }
          if (object.actualDurationMinutes != null) {
            message.actualDurationMinutes = object.actualDurationMinutes >>> 0;
          }
          if (object.lastUpdatedById != null) {
            message.lastUpdatedById = String(object.lastUpdatedById);
          }
          if (object.connectedChartNoteLocked != null) {
            message.connectedChartNoteLocked = Boolean(
              object.connectedChartNoteLocked
            );
          }
          if (object.connectedChartNoteString != null) {
            message.connectedChartNoteString = String(
              object.connectedChartNoteString
            );
          }
          if (object.statusLastUpdatedById != null) {
            message.statusLastUpdatedById = String(
              object.statusLastUpdatedById
            );
          }
          if (object.addToIcalLink != null) {
            message.addToIcalLink = String(object.addToIcalLink);
          }
          if (object.appointmentLegalName != null) {
            message.appointmentLegalName = String(object.appointmentLegalName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentId = '';
            object.appointmentProviderConfirmed = false;
            object.appointmentClientConfirmed = false;
            object.appointmentDatetimeRaw = '';
            object.appointmentDatetime = null;
            object.appointmentEndDatetime = null;
            object.appointmentCreatedAt = null;
            object.appointmentUpdatedAt = null;
            object.appointmentTimezoneOffset = '';
            object.appointmentTimezoneOffsetSecondsEastOfUtc = 0;
            object.appointmentIsBlocker = false;
            object.appointmentDayOfWeek = '';
            object.appointmentDayOfMonth = '';
            object.appointmentMonth = '';
            object.appointmentDate = '';
            object.appointmentTime = '';
            object.connectedChartNoteLocked = false;
          }
          let keys;
          if (
            message.appointmentId != null &&
            message.hasOwnProperty('appointmentId')
          ) {
            object.appointmentId = message.appointmentId;
          }
          if (
            message.healthiePatientId != null &&
            message.hasOwnProperty('healthiePatientId')
          ) {
            object.healthiePatientId = message.healthiePatientId;
          }
          if (
            message.appointmentTypeId != null &&
            message.hasOwnProperty('appointmentTypeId')
          ) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (
            message.appointmentTypeTitle != null &&
            message.hasOwnProperty('appointmentTypeTitle')
          ) {
            object.appointmentTypeTitle = message.appointmentTypeTitle;
          }
          if (
            message.appointmentProviderConfirmed != null &&
            message.hasOwnProperty('appointmentProviderConfirmed')
          ) {
            object.appointmentProviderConfirmed =
              message.appointmentProviderConfirmed;
          }
          if (
            message.appointmentClientConfirmed != null &&
            message.hasOwnProperty('appointmentClientConfirmed')
          ) {
            object.appointmentClientConfirmed =
              message.appointmentClientConfirmed;
          }
          if (
            message.appointmentAddToCalLink != null &&
            message.hasOwnProperty('appointmentAddToCalLink')
          ) {
            object.appointmentAddToCalLink = message.appointmentAddToCalLink;
          }
          if (
            message.appointmentLocationLabel != null &&
            message.hasOwnProperty('appointmentLocationLabel')
          ) {
            object.appointmentLocationLabel = message.appointmentLocationLabel;
          }
          if (
            message.appointmentLocationType != null &&
            message.hasOwnProperty('appointmentLocationType')
          ) {
            object.appointmentLocationType = message.appointmentLocationType;
          }
          if (
            message.appointmentLocationDetail != null &&
            message.hasOwnProperty('appointmentLocationDetail')
          ) {
            object.appointmentLocationDetail =
              message.appointmentLocationDetail;
          }
          if (
            message.appointmentZoomMeetingId != null &&
            message.hasOwnProperty('appointmentZoomMeetingId')
          ) {
            object.appointmentZoomMeetingId = message.appointmentZoomMeetingId;
          }
          if (
            message.appointmentProviderId != null &&
            message.hasOwnProperty('appointmentProviderId')
          ) {
            object.appointmentProviderId = message.appointmentProviderId;
          }
          if (
            message.appointmentProviderFullName != null &&
            message.hasOwnProperty('appointmentProviderFullName')
          ) {
            object.appointmentProviderFullName =
              message.appointmentProviderFullName;
          }
          if (
            message.appointmentProviderEmail != null &&
            message.hasOwnProperty('appointmentProviderEmail')
          ) {
            object.appointmentProviderEmail = message.appointmentProviderEmail;
          }
          if (
            message.appointmentReason != null &&
            message.hasOwnProperty('appointmentReason')
          ) {
            object.appointmentReason = message.appointmentReason;
          }
          if (
            message.appointmentDatetimeRaw != null &&
            message.hasOwnProperty('appointmentDatetimeRaw')
          ) {
            object.appointmentDatetimeRaw = message.appointmentDatetimeRaw;
          }
          if (
            message.appointmentDatetime != null &&
            message.hasOwnProperty('appointmentDatetime')
          ) {
            object.appointmentDatetime = timing$1.v1.Timestamp.toObject(
              message.appointmentDatetime,
              options
            );
          }
          if (
            message.appointmentEndDatetime != null &&
            message.hasOwnProperty('appointmentEndDatetime')
          ) {
            object.appointmentEndDatetime = timing$1.v1.Timestamp.toObject(
              message.appointmentEndDatetime,
              options
            );
          }
          if (
            message.appointmentLengthMinutes != null &&
            message.hasOwnProperty('appointmentLengthMinutes')
          ) {
            object.appointmentLengthMinutes = message.appointmentLengthMinutes;
          }
          if (
            message.appointmentStatus != null &&
            message.hasOwnProperty('appointmentStatus')
          ) {
            object.appointmentStatus = message.appointmentStatus;
          }
          if (
            message.appointmentStatusChangedAt != null &&
            message.hasOwnProperty('appointmentStatusChangedAt')
          ) {
            object.appointmentStatusChangedAt = timing$1.v1.Timestamp.toObject(
              message.appointmentStatusChangedAt,
              options
            );
          }
          if (
            message.appointmentNotes != null &&
            message.hasOwnProperty('appointmentNotes')
          ) {
            object.appointmentNotes = message.appointmentNotes;
          }
          if (
            message.appointmentCreatedAt != null &&
            message.hasOwnProperty('appointmentCreatedAt')
          ) {
            object.appointmentCreatedAt = timing$1.v1.Timestamp.toObject(
              message.appointmentCreatedAt,
              options
            );
          }
          if (
            message.appointmentUpdatedAt != null &&
            message.hasOwnProperty('appointmentUpdatedAt')
          ) {
            object.appointmentUpdatedAt = timing$1.v1.Timestamp.toObject(
              message.appointmentUpdatedAt,
              options
            );
          }
          if (
            message.appointmentDeletedAt != null &&
            message.hasOwnProperty('appointmentDeletedAt')
          ) {
            object.appointmentDeletedAt = timing$1.v1.Timestamp.toObject(
              message.appointmentDeletedAt,
              options
            );
          }
          if (
            message.appointmentCreatedBy != null &&
            message.hasOwnProperty('appointmentCreatedBy')
          ) {
            object.appointmentCreatedBy = message.appointmentCreatedBy;
          }
          if (
            message.appointmentTimezoneShortName != null &&
            message.hasOwnProperty('appointmentTimezoneShortName')
          ) {
            object.appointmentTimezoneShortName =
              message.appointmentTimezoneShortName;
          }
          if (
            message.appointmentTimezoneOffset != null &&
            message.hasOwnProperty('appointmentTimezoneOffset')
          ) {
            object.appointmentTimezoneOffset =
              message.appointmentTimezoneOffset;
          }
          if (
            message.appointmentTimezoneOffsetSecondsEastOfUtc != null &&
            message.hasOwnProperty('appointmentTimezoneOffsetSecondsEastOfUtc')
          ) {
            object.appointmentTimezoneOffsetSecondsEastOfUtc =
              message.appointmentTimezoneOffsetSecondsEastOfUtc;
          }
          if (
            message.appointmentPatientTimezoneIdentifier != null &&
            message.hasOwnProperty('appointmentPatientTimezoneIdentifier')
          ) {
            object.appointmentPatientTimezoneIdentifier =
              message.appointmentPatientTimezoneIdentifier;
          }
          if (
            message.healthiePatientRecordIdentifier != null &&
            message.hasOwnProperty('healthiePatientRecordIdentifier')
          ) {
            object.healthiePatientRecordIdentifier =
              message.healthiePatientRecordIdentifier;
          }
          if (
            message.appointmentIsBlocker != null &&
            message.hasOwnProperty('appointmentIsBlocker')
          ) {
            object.appointmentIsBlocker = message.appointmentIsBlocker;
          }
          if (
            message.appointmentType != null &&
            message.hasOwnProperty('appointmentType')
          ) {
            object.appointmentType = $root.healthie.v1.AppointmentType.toObject(
              message.appointmentType,
              options
            );
          }
          if (
            message.appointmentDayOfWeek != null &&
            message.hasOwnProperty('appointmentDayOfWeek')
          ) {
            object.appointmentDayOfWeek = message.appointmentDayOfWeek;
          }
          if (
            message.appointmentDayOfMonth != null &&
            message.hasOwnProperty('appointmentDayOfMonth')
          ) {
            object.appointmentDayOfMonth = message.appointmentDayOfMonth;
          }
          if (
            message.appointmentMonth != null &&
            message.hasOwnProperty('appointmentMonth')
          ) {
            object.appointmentMonth = message.appointmentMonth;
          }
          if (
            message.appointmentDate != null &&
            message.hasOwnProperty('appointmentDate')
          ) {
            object.appointmentDate = message.appointmentDate;
          }
          if (
            message.appointmentTime != null &&
            message.hasOwnProperty('appointmentTime')
          ) {
            object.appointmentTime = message.appointmentTime;
          }
          if (
            message.appointmentEmailAddress != null &&
            message.hasOwnProperty('appointmentEmailAddress')
          ) {
            object.appointmentEmailAddress = message.appointmentEmailAddress;
          }
          if (
            message.appointmentFirstName != null &&
            message.hasOwnProperty('appointmentFirstName')
          ) {
            object.appointmentFirstName = message.appointmentFirstName;
          }
          if (
            message.appointmentLastName != null &&
            message.hasOwnProperty('appointmentLastName')
          ) {
            object.appointmentLastName = message.appointmentLastName;
          }
          if (
            message.appointmentPhoneNumber != null &&
            message.hasOwnProperty('appointmentPhoneNumber')
          ) {
            object.appointmentPhoneNumber = message.appointmentPhoneNumber;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          if (
            message.appointmentMetadata != null &&
            message.hasOwnProperty('appointmentMetadata')
          ) {
            object.appointmentMetadata = google$1.protobuf.Struct.toObject(
              message.appointmentMetadata,
              options
            );
          }
          if (
            message.actualDurationMinutes != null &&
            message.hasOwnProperty('actualDurationMinutes')
          ) {
            object.actualDurationMinutes = message.actualDurationMinutes;
          }
          if (
            message.lastUpdatedById != null &&
            message.hasOwnProperty('lastUpdatedById')
          ) {
            object.lastUpdatedById = message.lastUpdatedById;
          }
          if (
            message.connectedChartNoteLocked != null &&
            message.hasOwnProperty('connectedChartNoteLocked')
          ) {
            object.connectedChartNoteLocked = message.connectedChartNoteLocked;
          }
          if (
            message.connectedChartNoteString != null &&
            message.hasOwnProperty('connectedChartNoteString')
          ) {
            object.connectedChartNoteString = message.connectedChartNoteString;
          }
          if (
            message.statusLastUpdatedById != null &&
            message.hasOwnProperty('statusLastUpdatedById')
          ) {
            object.statusLastUpdatedById = message.statusLastUpdatedById;
          }
          if (
            message.addToIcalLink != null &&
            message.hasOwnProperty('addToIcalLink')
          ) {
            object.addToIcalLink = message.addToIcalLink;
          }
          if (
            message.appointmentLegalName != null &&
            message.hasOwnProperty('appointmentLegalName')
          ) {
            object.appointmentLegalName = message.appointmentLegalName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Appointment';
        }
      }

      Appointment.prototype.appointmentId = '';
      Appointment.prototype.healthiePatientId = null;
      Appointment.prototype.appointmentTypeId = null;
      Appointment.prototype.appointmentTypeTitle = null;
      Appointment.prototype.appointmentProviderConfirmed = false;
      Appointment.prototype.appointmentClientConfirmed = false;
      Appointment.prototype.appointmentAddToCalLink = null;
      Appointment.prototype.appointmentLocationLabel = null;
      Appointment.prototype.appointmentLocationType = null;
      Appointment.prototype.appointmentLocationDetail = null;
      Appointment.prototype.appointmentZoomMeetingId = null;
      Appointment.prototype.appointmentProviderId = null;
      Appointment.prototype.appointmentProviderFullName = null;
      Appointment.prototype.appointmentProviderEmail = null;
      Appointment.prototype.appointmentReason = null;
      Appointment.prototype.appointmentDatetimeRaw = '';
      Appointment.prototype.appointmentDatetime = null;
      Appointment.prototype.appointmentEndDatetime = null;
      Appointment.prototype.appointmentLengthMinutes = null;
      Appointment.prototype.appointmentStatus = null;
      Appointment.prototype.appointmentStatusChangedAt = null;
      Appointment.prototype.appointmentNotes = null;
      Appointment.prototype.appointmentCreatedAt = null;
      Appointment.prototype.appointmentUpdatedAt = null;
      Appointment.prototype.appointmentDeletedAt = null;
      Appointment.prototype.appointmentCreatedBy = null;
      Appointment.prototype.appointmentTimezoneShortName = null;
      Appointment.prototype.appointmentTimezoneOffset = '';
      Appointment.prototype.appointmentTimezoneOffsetSecondsEastOfUtc = 0;
      Appointment.prototype.appointmentPatientTimezoneIdentifier = null;
      Appointment.prototype.healthiePatientRecordIdentifier = null;
      Appointment.prototype.appointmentIsBlocker = false;
      Appointment.prototype.appointmentType = null;
      Appointment.prototype.appointmentDayOfWeek = '';
      Appointment.prototype.appointmentDayOfMonth = '';
      Appointment.prototype.appointmentMonth = '';
      Appointment.prototype.appointmentDate = '';
      Appointment.prototype.appointmentTime = '';
      Appointment.prototype.appointmentEmailAddress = null;
      Appointment.prototype.appointmentFirstName = null;
      Appointment.prototype.appointmentLastName = null;
      Appointment.prototype.appointmentPhoneNumber = null;
      Appointment.prototype.dieticianOrganizationId = null;
      Appointment.prototype.dietitianOrganizationId = null;
      Appointment.prototype.dietitianFirstName = null;
      Appointment.prototype.dietitianLastName = null;
      Appointment.prototype.dietitianEmailAddress = null;
      Appointment.prototype.dietitianId = null;
      Appointment.prototype.appointmentMetadata = null;
      Appointment.prototype.actualDurationMinutes = null;
      Appointment.prototype.lastUpdatedById = null;
      Appointment.prototype.connectedChartNoteLocked = false;
      Appointment.prototype.connectedChartNoteString = null;
      Appointment.prototype.statusLastUpdatedById = null;
      Appointment.prototype.addToIcalLink = null;
      Appointment.prototype.appointmentLegalName = null;

      return Appointment;
    })();

    v1.CMS1500 = (() => {
      class CMS1500 {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CMS1500(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(18).string(message.patientId);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(26).string(message.dieticianOrganizationId);
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(34).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(42).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(50).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(58).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(66).string(message.dietitianId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CMS1500();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 4: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 5: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 6: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 7: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 8: {
                message.dietitianId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CMS1500) {
            return object;
          }
          const message = new $root.healthie.v1.CMS1500();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CMS1500';
        }
      }

      CMS1500.prototype.id = '';
      CMS1500.prototype.patientId = null;
      CMS1500.prototype.dieticianOrganizationId = null;
      CMS1500.prototype.dietitianOrganizationId = null;
      CMS1500.prototype.dietitianFirstName = null;
      CMS1500.prototype.dietitianLastName = null;
      CMS1500.prototype.dietitianEmailAddress = null;
      CMS1500.prototype.dietitianId = null;

      return CMS1500;
    })();

    v1.InsuranceAuthorization = (() => {
      class InsuranceAuthorization {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InsuranceAuthorization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(18).string(message.patientId);
          }
          if (
            message.endOn != null &&
            Object.hasOwnProperty.call(message, 'endOn')
          ) {
            values$1.v1.Date.encode(
              message.endOn,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(34).string(message.dieticianOrganizationId);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(50).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(58).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(66).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(74).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(82).string(message.dietitianId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InsuranceAuthorization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.endOn = values$1.v1.Date.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 5: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 7: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 8: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 9: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 10: {
                message.dietitianId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.InsuranceAuthorization) {
            return object;
          }
          const message = new $root.healthie.v1.InsuranceAuthorization();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.endOn != null) {
            if (typeof object.endOn !== 'object') {
              throw new TypeError(
                '.healthie.v1.InsuranceAuthorization.endOn: object expected, but got ' +
                  typeof object.endOn
              );
            }
            message.endOn = values$1.v1.Date.fromObject(object.endOn);
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.InsuranceAuthorization.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.patientId = '';
            object.endOn = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (message.endOn != null && message.hasOwnProperty('endOn')) {
            object.endOn = values$1.v1.Date.toObject(message.endOn, options);
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.InsuranceAuthorization';
        }
      }

      InsuranceAuthorization.prototype.id = '';
      InsuranceAuthorization.prototype.patientId = '';
      InsuranceAuthorization.prototype.endOn = null;
      InsuranceAuthorization.prototype.dieticianOrganizationId = null;
      InsuranceAuthorization.prototype.updatedAt = null;
      InsuranceAuthorization.prototype.dietitianOrganizationId = null;
      InsuranceAuthorization.prototype.dietitianFirstName = null;
      InsuranceAuthorization.prototype.dietitianLastName = null;
      InsuranceAuthorization.prototype.dietitianEmailAddress = null;
      InsuranceAuthorization.prototype.dietitianId = null;

      return InsuranceAuthorization;
    })();

    v1.RequestedFormCompletion = (() => {
      class RequestedFormCompletion {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RequestedFormCompletion(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(18).string(message.patientId);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(26).string(message.dieticianOrganizationId);
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(34).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(42).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(50).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(58).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(66).string(message.dietitianId);
          }
          if (
            message.customModuleFormId != null &&
            Object.hasOwnProperty.call(message, 'customModuleFormId')
          ) {
            writer.uint32(74).string(message.customModuleFormId);
          }
          if (
            message.customModuleFormName != null &&
            Object.hasOwnProperty.call(message, 'customModuleFormName')
          ) {
            writer.uint32(82).string(message.customModuleFormName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RequestedFormCompletion();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 4: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 5: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 6: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 7: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 8: {
                message.dietitianId = reader.string();
                break;
              }
              case 9: {
                message.customModuleFormId = reader.string();
                break;
              }
              case 10: {
                message.customModuleFormName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.RequestedFormCompletion) {
            return object;
          }
          const message = new $root.healthie.v1.RequestedFormCompletion();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          if (object.customModuleFormId != null) {
            message.customModuleFormId = String(object.customModuleFormId);
          }
          if (object.customModuleFormName != null) {
            message.customModuleFormName = String(object.customModuleFormName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.patientId = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          if (
            message.customModuleFormId != null &&
            message.hasOwnProperty('customModuleFormId')
          ) {
            object.customModuleFormId = message.customModuleFormId;
          }
          if (
            message.customModuleFormName != null &&
            message.hasOwnProperty('customModuleFormName')
          ) {
            object.customModuleFormName = message.customModuleFormName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.RequestedFormCompletion';
        }
      }

      RequestedFormCompletion.prototype.id = '';
      RequestedFormCompletion.prototype.patientId = '';
      RequestedFormCompletion.prototype.dieticianOrganizationId = null;
      RequestedFormCompletion.prototype.dietitianOrganizationId = null;
      RequestedFormCompletion.prototype.dietitianFirstName = null;
      RequestedFormCompletion.prototype.dietitianLastName = null;
      RequestedFormCompletion.prototype.dietitianEmailAddress = null;
      RequestedFormCompletion.prototype.dietitianId = null;
      RequestedFormCompletion.prototype.customModuleFormId = null;
      RequestedFormCompletion.prototype.customModuleFormName = null;

      return RequestedFormCompletion;
    })();

    v1.BillingItem = (() => {
      class BillingItem {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BillingItem(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(18).string(message.patientId);
          }
          if (
            message.dieticianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dieticianOrganizationId')
          ) {
            writer.uint32(26).string(message.dieticianOrganizationId);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.dietitianOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'dietitianOrganizationId')
          ) {
            writer.uint32(42).string(message.dietitianOrganizationId);
          }
          if (
            message.dietitianFirstName != null &&
            Object.hasOwnProperty.call(message, 'dietitianFirstName')
          ) {
            writer.uint32(50).string(message.dietitianFirstName);
          }
          if (
            message.dietitianLastName != null &&
            Object.hasOwnProperty.call(message, 'dietitianLastName')
          ) {
            writer.uint32(58).string(message.dietitianLastName);
          }
          if (
            message.dietitianEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'dietitianEmailAddress')
          ) {
            writer.uint32(66).string(message.dietitianEmailAddress);
          }
          if (
            message.dietitianId != null &&
            Object.hasOwnProperty.call(message, 'dietitianId')
          ) {
            writer.uint32(74).string(message.dietitianId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BillingItem();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.dieticianOrganizationId = reader.string();
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.dietitianOrganizationId = reader.string();
                break;
              }
              case 6: {
                message.dietitianFirstName = reader.string();
                break;
              }
              case 7: {
                message.dietitianLastName = reader.string();
                break;
              }
              case 8: {
                message.dietitianEmailAddress = reader.string();
                break;
              }
              case 9: {
                message.dietitianId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.BillingItem) {
            return object;
          }
          const message = new $root.healthie.v1.BillingItem();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.dieticianOrganizationId != null) {
            message.dieticianOrganizationId = String(
              object.dieticianOrganizationId
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.BillingItem.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.dietitianOrganizationId != null) {
            message.dietitianOrganizationId = String(
              object.dietitianOrganizationId
            );
          }
          if (object.dietitianFirstName != null) {
            message.dietitianFirstName = String(object.dietitianFirstName);
          }
          if (object.dietitianLastName != null) {
            message.dietitianLastName = String(object.dietitianLastName);
          }
          if (object.dietitianEmailAddress != null) {
            message.dietitianEmailAddress = String(
              object.dietitianEmailAddress
            );
          }
          if (object.dietitianId != null) {
            message.dietitianId = String(object.dietitianId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.patientId = '';
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (
            message.dieticianOrganizationId != null &&
            message.hasOwnProperty('dieticianOrganizationId')
          ) {
            object.dieticianOrganizationId = message.dieticianOrganizationId;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.dietitianOrganizationId != null &&
            message.hasOwnProperty('dietitianOrganizationId')
          ) {
            object.dietitianOrganizationId = message.dietitianOrganizationId;
          }
          if (
            message.dietitianFirstName != null &&
            message.hasOwnProperty('dietitianFirstName')
          ) {
            object.dietitianFirstName = message.dietitianFirstName;
          }
          if (
            message.dietitianLastName != null &&
            message.hasOwnProperty('dietitianLastName')
          ) {
            object.dietitianLastName = message.dietitianLastName;
          }
          if (
            message.dietitianEmailAddress != null &&
            message.hasOwnProperty('dietitianEmailAddress')
          ) {
            object.dietitianEmailAddress = message.dietitianEmailAddress;
          }
          if (
            message.dietitianId != null &&
            message.hasOwnProperty('dietitianId')
          ) {
            object.dietitianId = message.dietitianId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.BillingItem';
        }
      }

      BillingItem.prototype.id = '';
      BillingItem.prototype.patientId = '';
      BillingItem.prototype.dieticianOrganizationId = null;
      BillingItem.prototype.updatedAt = null;
      BillingItem.prototype.dietitianOrganizationId = null;
      BillingItem.prototype.dietitianFirstName = null;
      BillingItem.prototype.dietitianLastName = null;
      BillingItem.prototype.dietitianEmailAddress = null;
      BillingItem.prototype.dietitianId = null;

      return BillingItem;
    })();

    v1.ReferringProvider = (() => {
      class ReferringProvider {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ReferringProvider(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(18).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(26).string(message.lastName);
          }
          if (
            message.location != null &&
            Object.hasOwnProperty.call(message, 'location')
          ) {
            $root.healthie.v1.Location.encode(
              message.location,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.businessName != null &&
            Object.hasOwnProperty.call(message, 'businessName')
          ) {
            writer.uint32(50).string(message.businessName);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(58).string(message.phoneNumber);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(66).string(message.email);
          }
          if (
            message.fullName != null &&
            Object.hasOwnProperty.call(message, 'fullName')
          ) {
            writer.uint32(74).string(message.fullName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ReferringProvider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.location = $root.healthie.v1.Location.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.businessName = reader.string();
                break;
              }
              case 7: {
                message.phoneNumber = reader.string();
                break;
              }
              case 8: {
                message.email = reader.string();
                break;
              }
              case 9: {
                message.fullName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ReferringProvider) {
            return object;
          }
          const message = new $root.healthie.v1.ReferringProvider();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.location != null) {
            if (typeof object.location !== 'object') {
              throw new TypeError(
                '.healthie.v1.ReferringProvider.location: object expected, but got ' +
                  typeof object.location
              );
            }
            message.location = $root.healthie.v1.Location.fromObject(
              object.location
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.ReferringProvider.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.businessName != null) {
            message.businessName = String(object.businessName);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.fullName != null) {
            message.fullName = String(object.fullName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.firstName = '';
            object.lastName = '';
            object.location = null;
            object.createdAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.location != null && message.hasOwnProperty('location')) {
            object.location = $root.healthie.v1.Location.toObject(
              message.location,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.businessName != null &&
            message.hasOwnProperty('businessName')
          ) {
            object.businessName = message.businessName;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (message.fullName != null && message.hasOwnProperty('fullName')) {
            object.fullName = message.fullName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ReferringProvider';
        }
      }

      ReferringProvider.prototype.id = '';
      ReferringProvider.prototype.firstName = '';
      ReferringProvider.prototype.lastName = '';
      ReferringProvider.prototype.location = null;
      ReferringProvider.prototype.createdAt = null;
      ReferringProvider.prototype.businessName = null;
      ReferringProvider.prototype.phoneNumber = null;
      ReferringProvider.prototype.email = null;
      ReferringProvider.prototype.fullName = null;

      return ReferringProvider;
    })();

    v1.Tag = (() => {
      class Tag {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Tag(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Tag();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Tag) {
            return object;
          }
          const message = new $root.healthie.v1.Tag();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Tag.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.Tag.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Tag';
        }
      }

      Tag.prototype.id = '';
      Tag.prototype.name = '';
      Tag.prototype.createdAt = null;
      Tag.prototype.updatedAt = null;

      return Tag;
    })();

    v1.ClientSource = (() => {
      class ClientSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ClientSource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.readableSource != null &&
            Object.hasOwnProperty.call(message, 'readableSource')
          ) {
            writer.uint32(18).string(message.readableSource);
          }
          if (
            message.refSource != null &&
            Object.hasOwnProperty.call(message, 'refSource')
          ) {
            writer.uint32(26).string(message.refSource);
          }
          if (
            message.refSourceOther != null &&
            Object.hasOwnProperty.call(message, 'refSourceOther')
          ) {
            writer.uint32(34).string(message.refSourceOther);
          }
          if (
            message.refType != null &&
            Object.hasOwnProperty.call(message, 'refType')
          ) {
            writer.uint32(42).string(message.refType);
          }
          if (
            message.relatedInsuranceId != null &&
            Object.hasOwnProperty.call(message, 'relatedInsuranceId')
          ) {
            writer.uint32(50).string(message.relatedInsuranceId);
          }
          if (
            message.relatedObjectId != null &&
            Object.hasOwnProperty.call(message, 'relatedObjectId')
          ) {
            writer.uint32(58).string(message.relatedObjectId);
          }
          if (
            message.relatedObjectName != null &&
            Object.hasOwnProperty.call(message, 'relatedObjectName')
          ) {
            writer.uint32(66).string(message.relatedObjectName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ClientSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.readableSource = reader.string();
                break;
              }
              case 3: {
                message.refSource = reader.string();
                break;
              }
              case 4: {
                message.refSourceOther = reader.string();
                break;
              }
              case 5: {
                message.refType = reader.string();
                break;
              }
              case 6: {
                message.relatedInsuranceId = reader.string();
                break;
              }
              case 7: {
                message.relatedObjectId = reader.string();
                break;
              }
              case 8: {
                message.relatedObjectName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ClientSource) {
            return object;
          }
          const message = new $root.healthie.v1.ClientSource();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.readableSource != null) {
            message.readableSource = String(object.readableSource);
          }
          if (object.refSource != null) {
            message.refSource = String(object.refSource);
          }
          if (object.refSourceOther != null) {
            message.refSourceOther = String(object.refSourceOther);
          }
          if (object.refType != null) {
            message.refType = String(object.refType);
          }
          if (object.relatedInsuranceId != null) {
            message.relatedInsuranceId = String(object.relatedInsuranceId);
          }
          if (object.relatedObjectId != null) {
            message.relatedObjectId = String(object.relatedObjectId);
          }
          if (object.relatedObjectName != null) {
            message.relatedObjectName = String(object.relatedObjectName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.readableSource != null &&
            message.hasOwnProperty('readableSource')
          ) {
            object.readableSource = message.readableSource;
          }
          if (
            message.refSource != null &&
            message.hasOwnProperty('refSource')
          ) {
            object.refSource = message.refSource;
          }
          if (
            message.refSourceOther != null &&
            message.hasOwnProperty('refSourceOther')
          ) {
            object.refSourceOther = message.refSourceOther;
          }
          if (message.refType != null && message.hasOwnProperty('refType')) {
            object.refType = message.refType;
          }
          if (
            message.relatedInsuranceId != null &&
            message.hasOwnProperty('relatedInsuranceId')
          ) {
            object.relatedInsuranceId = message.relatedInsuranceId;
          }
          if (
            message.relatedObjectId != null &&
            message.hasOwnProperty('relatedObjectId')
          ) {
            object.relatedObjectId = message.relatedObjectId;
          }
          if (
            message.relatedObjectName != null &&
            message.hasOwnProperty('relatedObjectName')
          ) {
            object.relatedObjectName = message.relatedObjectName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ClientSource';
        }
      }

      ClientSource.prototype.id = '';
      ClientSource.prototype.readableSource = null;
      ClientSource.prototype.refSource = null;
      ClientSource.prototype.refSourceOther = null;
      ClientSource.prototype.refType = null;
      ClientSource.prototype.relatedInsuranceId = null;
      ClientSource.prototype.relatedObjectId = null;
      ClientSource.prototype.relatedObjectName = null;

      return ClientSource;
    })();

    v1.Document = (() => {
      class Document {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Document(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Document();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Document) {
            return object;
          }
          const message = new $root.healthie.v1.Document();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Document';
        }
      }

      Document.prototype.id = '';

      return Document;
    })();

    v1.RequestedPayment = (() => {
      class RequestedPayment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RequestedPayment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.appointmentId != null &&
            Object.hasOwnProperty.call(message, 'appointmentId')
          ) {
            writer.uint32(18).string(message.appointmentId);
          }
          if (
            message.cms1500Id != null &&
            Object.hasOwnProperty.call(message, 'cms1500Id')
          ) {
            writer.uint32(26).string(message.cms1500Id);
          }
          if (
            message.invoiceId != null &&
            Object.hasOwnProperty.call(message, 'invoiceId')
          ) {
            writer.uint32(34).string(message.invoiceId);
          }
          if (
            message.senderId != null &&
            Object.hasOwnProperty.call(message, 'senderId')
          ) {
            writer.uint32(42).string(message.senderId);
          }
          if (
            message.recipientId != null &&
            Object.hasOwnProperty.call(message, 'recipientId')
          ) {
            writer.uint32(50).string(message.recipientId);
          }
          if (
            message.details != null &&
            Object.hasOwnProperty.call(message, 'details')
          ) {
            writer.uint32(58).string(message.details);
          }
          if (
            message.invoiceType != null &&
            Object.hasOwnProperty.call(message, 'invoiceType')
          ) {
            writer.uint32(66).string(message.invoiceType);
          }
          if (
            message.notes != null &&
            Object.hasOwnProperty.call(message, 'notes')
          ) {
            writer.uint32(74).string(message.notes);
          }
          if (
            message.price != null &&
            Object.hasOwnProperty.call(message, 'price')
          ) {
            writer.uint32(81).double(message.price);
          }
          if (
            message.serviceDate != null &&
            Object.hasOwnProperty.call(message, 'serviceDate')
          ) {
            timing$1.v1.Timestamp.encode(
              message.serviceDate,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.servicesProvided != null &&
            Object.hasOwnProperty.call(message, 'servicesProvided')
          ) {
            writer.uint32(98).string(message.servicesProvided);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(106).string(message.status);
          }
          if (
            message.isPreview != null &&
            Object.hasOwnProperty.call(message, 'isPreview')
          ) {
            writer.uint32(112).bool(message.isPreview);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RequestedPayment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.appointmentId = reader.string();
                break;
              }
              case 3: {
                message.cms1500Id = reader.string();
                break;
              }
              case 4: {
                message.invoiceId = reader.string();
                break;
              }
              case 5: {
                message.senderId = reader.string();
                break;
              }
              case 6: {
                message.recipientId = reader.string();
                break;
              }
              case 7: {
                message.details = reader.string();
                break;
              }
              case 8: {
                message.invoiceType = reader.string();
                break;
              }
              case 9: {
                message.notes = reader.string();
                break;
              }
              case 10: {
                message.price = reader.double();
                break;
              }
              case 11: {
                message.serviceDate = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                message.servicesProvided = reader.string();
                break;
              }
              case 13: {
                message.status = reader.string();
                break;
              }
              case 14: {
                message.isPreview = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.RequestedPayment) {
            return object;
          }
          const message = new $root.healthie.v1.RequestedPayment();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.cms1500Id != null) {
            message.cms1500Id = String(object.cms1500Id);
          }
          if (object.invoiceId != null) {
            message.invoiceId = String(object.invoiceId);
          }
          if (object.senderId != null) {
            message.senderId = String(object.senderId);
          }
          if (object.recipientId != null) {
            message.recipientId = String(object.recipientId);
          }
          if (object.details != null) {
            message.details = String(object.details);
          }
          if (object.invoiceType != null) {
            message.invoiceType = String(object.invoiceType);
          }
          if (object.notes != null) {
            message.notes = String(object.notes);
          }
          if (object.price != null) {
            message.price = Number(object.price);
          }
          if (object.serviceDate != null) {
            if (typeof object.serviceDate !== 'object') {
              throw new TypeError(
                '.healthie.v1.RequestedPayment.serviceDate: object expected, but got ' +
                  typeof object.serviceDate
              );
            }
            message.serviceDate = timing$1.v1.Timestamp.fromObject(
              object.serviceDate
            );
          }
          if (object.servicesProvided != null) {
            message.servicesProvided = String(object.servicesProvided);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.isPreview != null) {
            message.isPreview = Boolean(object.isPreview);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.status = '';
            object.isPreview = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.appointmentId != null &&
            message.hasOwnProperty('appointmentId')
          ) {
            object.appointmentId = message.appointmentId;
          }
          if (
            message.cms1500Id != null &&
            message.hasOwnProperty('cms1500Id')
          ) {
            object.cms1500Id = message.cms1500Id;
          }
          if (
            message.invoiceId != null &&
            message.hasOwnProperty('invoiceId')
          ) {
            object.invoiceId = message.invoiceId;
          }
          if (message.senderId != null && message.hasOwnProperty('senderId')) {
            object.senderId = message.senderId;
          }
          if (
            message.recipientId != null &&
            message.hasOwnProperty('recipientId')
          ) {
            object.recipientId = message.recipientId;
          }
          if (message.details != null && message.hasOwnProperty('details')) {
            object.details = message.details;
          }
          if (
            message.invoiceType != null &&
            message.hasOwnProperty('invoiceType')
          ) {
            object.invoiceType = message.invoiceType;
          }
          if (message.notes != null && message.hasOwnProperty('notes')) {
            object.notes = message.notes;
          }
          if (message.price != null && message.hasOwnProperty('price')) {
            object.price =
              options.json && !isFinite(message.price)
                ? String(message.price)
                : message.price;
          }
          if (
            message.serviceDate != null &&
            message.hasOwnProperty('serviceDate')
          ) {
            object.serviceDate = timing$1.v1.Timestamp.toObject(
              message.serviceDate,
              options
            );
          }
          if (
            message.servicesProvided != null &&
            message.hasOwnProperty('servicesProvided')
          ) {
            object.servicesProvided = message.servicesProvided;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (
            message.isPreview != null &&
            message.hasOwnProperty('isPreview')
          ) {
            object.isPreview = message.isPreview;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.RequestedPayment';
        }
      }

      RequestedPayment.prototype.id = '';
      RequestedPayment.prototype.appointmentId = null;
      RequestedPayment.prototype.cms1500Id = null;
      RequestedPayment.prototype.invoiceId = null;
      RequestedPayment.prototype.senderId = null;
      RequestedPayment.prototype.recipientId = null;
      RequestedPayment.prototype.details = null;
      RequestedPayment.prototype.invoiceType = null;
      RequestedPayment.prototype.notes = null;
      RequestedPayment.prototype.price = null;
      RequestedPayment.prototype.serviceDate = null;
      RequestedPayment.prototype.servicesProvided = null;
      RequestedPayment.prototype.status = '';
      RequestedPayment.prototype.isPreview = false;

      return RequestedPayment;
    })();

    v1.StripeCustomerDetail = (() => {
      class StripeCustomerDetail {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StripeCustomerDetail(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerId != null &&
            Object.hasOwnProperty.call(message, 'customerId')
          ) {
            writer.uint32(10).string(message.customerId);
          }
          if (
            message.stripeCustomerId != null &&
            Object.hasOwnProperty.call(message, 'stripeCustomerId')
          ) {
            writer.uint32(18).string(message.stripeCustomerId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StripeCustomerDetail();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerId = reader.string();
                break;
              }
              case 2: {
                message.stripeCustomerId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.StripeCustomerDetail) {
            return object;
          }
          const message = new $root.healthie.v1.StripeCustomerDetail();
          if (object.customerId != null) {
            message.customerId = String(object.customerId);
          }
          if (object.stripeCustomerId != null) {
            message.stripeCustomerId = String(object.stripeCustomerId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerId = '';
            object.stripeCustomerId = '';
          }
          let keys;
          if (
            message.customerId != null &&
            message.hasOwnProperty('customerId')
          ) {
            object.customerId = message.customerId;
          }
          if (
            message.stripeCustomerId != null &&
            message.hasOwnProperty('stripeCustomerId')
          ) {
            object.stripeCustomerId = message.stripeCustomerId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.StripeCustomerDetail';
        }
      }

      StripeCustomerDetail.prototype.customerId = '';
      StripeCustomerDetail.prototype.stripeCustomerId = '';

      return StripeCustomerDetail;
    })();

    v1.AllergySensitivity = (() => {
      class AllergySensitivity {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AllergySensitivity(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AllergySensitivity();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.AllergySensitivity) {
            return object;
          }
          const message = new $root.healthie.v1.AllergySensitivity();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.AllergySensitivity';
        }
      }

      AllergySensitivity.prototype.id = '';

      return AllergySensitivity;
    })();

    v1.Webhook = (() => {
      class Webhook {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Webhook(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(16).int32(message.eventType);
          }
          if (
            message.url != null &&
            Object.hasOwnProperty.call(message, 'url')
          ) {
            writer.uint32(26).string(message.url);
          }
          if (
            message.active != null &&
            Object.hasOwnProperty.call(message, 'active')
          ) {
            writer.uint32(32).bool(message.active);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Webhook();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.eventType = reader.int32();
                break;
              }
              case 3: {
                message.url = reader.string();
                break;
              }
              case 4: {
                message.active = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Webhook) {
            return object;
          }
          const message = new $root.healthie.v1.Webhook();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_DELETED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_CREATED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_UPDATED':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'HEALTHIE_CMS1500_CREATED':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'HEALTHIE_CMS1500_UPDATED':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'HEALTHIE_CMS1500_DELETED':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'HEALTHIE_COMMENT_CREATED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'HEALTHIE_COMMENT_UPDATED':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'HEALTHIE_COMMENT_DELETED':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'HEALTHIE_DOCUMENT_CREATED':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'HEALTHIE_DOCUMENT_UPDATED':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'HEALTHIE_DOCUMENT_DELETED':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'HEALTHIE_ENTRY_CREATED':
              case 16: {
                message.eventType = 16;
                break;
              }
              case 'HEALTHIE_ENTRY_UPDATED':
              case 17: {
                message.eventType = 17;
                break;
              }
              case 'HEALTHIE_ENTRY_DELETED':
              case 18: {
                message.eventType = 18;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_CREATED':
              case 19: {
                message.eventType = 19;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_DELETED':
              case 20: {
                message.eventType = 20;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED':
              case 21: {
                message.eventType = 21;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED':
              case 22: {
                message.eventType = 22;
                break;
              }
              case 'HEALTHIE_GOAL_CREATED':
              case 23: {
                message.eventType = 23;
                break;
              }
              case 'HEALTHIE_GOAL_UPDATED':
              case 24: {
                message.eventType = 24;
                break;
              }
              case 'HEALTHIE_GOAL_DELETED':
              case 25: {
                message.eventType = 25;
                break;
              }
              case 'HEALTHIE_GOAL_HISTORY_CREATED':
              case 26: {
                message.eventType = 26;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED':
              case 27: {
                message.eventType = 27;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED':
              case 28: {
                message.eventType = 28;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED':
              case 29: {
                message.eventType = 29;
                break;
              }
              case 'HEALTHIE_MESSAGE_CREATED':
              case 30: {
                message.eventType = 30;
                break;
              }
              case 'HEALTHIE_MESSAGE_DELETED':
              case 31: {
                message.eventType = 31;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_CREATED':
              case 32: {
                message.eventType = 32;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_UPDATED':
              case 33: {
                message.eventType = 33;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_DELETED':
              case 34: {
                message.eventType = 34;
                break;
              }
              case 'HEALTHIE_PATIENT_CREATED':
              case 35: {
                message.eventType = 35;
                break;
              }
              case 'HEALTHIE_PATIENT_UPDATED':
              case 36: {
                message.eventType = 36;
                break;
              }
              case 'HEALTHIE_POLICY_CREATED':
              case 37: {
                message.eventType = 37;
                break;
              }
              case 'HEALTHIE_POLICY_UPDATED':
              case 38: {
                message.eventType = 38;
                break;
              }
              case 'HEALTHIE_POLICY_DELETED':
              case 39: {
                message.eventType = 39;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED':
              case 40: {
                message.eventType = 40;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED':
              case 41: {
                message.eventType = 41;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED':
              case 42: {
                message.eventType = 42;
                break;
              }
              case 'HEALTHIE_TASK_CREATED':
              case 43: {
                message.eventType = 43;
                break;
              }
              case 'HEALTHIE_TASK_UPDATED':
              case 44: {
                message.eventType = 44;
                break;
              }
              case 'HEALTHIE_TASK_DELETED':
              case 45: {
                message.eventType = 45;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED':
              case 46: {
                message.eventType = 46;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_NO_SHOW':
              case 47: {
                message.eventType = 47;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_RESCHEDULED':
              case 48: {
                message.eventType = 48;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_CANCELLED':
              case 49: {
                message.eventType = 49;
                break;
              }
              case 'HEALTHIE_APPLIED_TAG_CREATED':
              case 50: {
                message.eventType = 50;
                break;
              }
              case 'HEALTHIE_APPLIED_TAG_DELETED':
              case 51: {
                message.eventType = 51;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE'
                : 0;
            object.url = '';
            object.active = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? $root.healthie.v1.HealthieEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : $root.healthie.v1.HealthieEventType[message.eventType]
                : message.eventType;
          }
          if (message.url != null && message.hasOwnProperty('url')) {
            object.url = message.url;
          }
          if (message.active != null && message.hasOwnProperty('active')) {
            object.active = message.active;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Webhook';
        }
      }

      Webhook.prototype.id = '';
      Webhook.prototype.eventType = 0;
      Webhook.prototype.url = '';
      Webhook.prototype.active = false;

      return Webhook;
    })();

    v1.CompletedOnboardingItem = (() => {
      class CompletedOnboardingItem {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CompletedOnboardingItem(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.itemId != null &&
            Object.hasOwnProperty.call(message, 'itemId')
          ) {
            writer.uint32(18).string(message.itemId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CompletedOnboardingItem();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.itemId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CompletedOnboardingItem) {
            return object;
          }
          const message = new $root.healthie.v1.CompletedOnboardingItem();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.itemId != null) {
            message.itemId = String(object.itemId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.itemId != null && message.hasOwnProperty('itemId')) {
            object.itemId = message.itemId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CompletedOnboardingItem';
        }
      }

      CompletedOnboardingItem.prototype.id = '';
      CompletedOnboardingItem.prototype.itemId = null;

      return CompletedOnboardingItem;
    })();

    v1.OnboardingItem = (() => {
      class OnboardingItem {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OnboardingItem(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.itemId != null &&
            Object.hasOwnProperty.call(message, 'itemId')
          ) {
            writer.uint32(18).string(message.itemId);
          }
          if (
            message.displayName != null &&
            Object.hasOwnProperty.call(message, 'displayName')
          ) {
            writer.uint32(26).string(message.displayName);
          }
          if (
            message.incompleteCustomModuleFormId != null &&
            Object.hasOwnProperty.call(message, 'incompleteCustomModuleFormId')
          ) {
            writer.uint32(34).string(message.incompleteCustomModuleFormId);
          }
          if (
            message.isLastItem != null &&
            Object.hasOwnProperty.call(message, 'isLastItem')
          ) {
            writer.uint32(40).bool(message.isLastItem);
          }
          if (
            message.completedOnboardingItem != null &&
            Object.hasOwnProperty.call(message, 'completedOnboardingItem')
          ) {
            $root.healthie.v1.CompletedOnboardingItem.encode(
              message.completedOnboardingItem,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OnboardingItem();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.itemId = reader.string();
                break;
              }
              case 3: {
                message.displayName = reader.string();
                break;
              }
              case 4: {
                message.incompleteCustomModuleFormId = reader.string();
                break;
              }
              case 5: {
                message.isLastItem = reader.bool();
                break;
              }
              case 7: {
                message.completedOnboardingItem =
                  $root.healthie.v1.CompletedOnboardingItem.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.OnboardingItem) {
            return object;
          }
          const message = new $root.healthie.v1.OnboardingItem();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.itemId != null) {
            message.itemId = String(object.itemId);
          }
          if (object.displayName != null) {
            message.displayName = String(object.displayName);
          }
          if (object.incompleteCustomModuleFormId != null) {
            message.incompleteCustomModuleFormId = String(
              object.incompleteCustomModuleFormId
            );
          }
          if (object.isLastItem != null) {
            message.isLastItem = Boolean(object.isLastItem);
          }
          if (object.completedOnboardingItem != null) {
            if (typeof object.completedOnboardingItem !== 'object') {
              throw new TypeError(
                '.healthie.v1.OnboardingItem.completedOnboardingItem: object expected, but got ' +
                  typeof object.completedOnboardingItem
              );
            }
            message.completedOnboardingItem =
              $root.healthie.v1.CompletedOnboardingItem.fromObject(
                object.completedOnboardingItem
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.isLastItem = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.itemId != null && message.hasOwnProperty('itemId')) {
            object.itemId = message.itemId;
          }
          if (
            message.displayName != null &&
            message.hasOwnProperty('displayName')
          ) {
            object.displayName = message.displayName;
          }
          if (
            message.incompleteCustomModuleFormId != null &&
            message.hasOwnProperty('incompleteCustomModuleFormId')
          ) {
            object.incompleteCustomModuleFormId =
              message.incompleteCustomModuleFormId;
          }
          if (
            message.isLastItem != null &&
            message.hasOwnProperty('isLastItem')
          ) {
            object.isLastItem = message.isLastItem;
          }
          if (
            message.completedOnboardingItem != null &&
            message.hasOwnProperty('completedOnboardingItem')
          ) {
            object.completedOnboardingItem =
              $root.healthie.v1.CompletedOnboardingItem.toObject(
                message.completedOnboardingItem,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.OnboardingItem';
        }
      }

      OnboardingItem.prototype.id = '';
      OnboardingItem.prototype.itemId = null;
      OnboardingItem.prototype.displayName = null;
      OnboardingItem.prototype.incompleteCustomModuleFormId = null;
      OnboardingItem.prototype.isLastItem = false;
      OnboardingItem.prototype.completedOnboardingItem = null;

      return OnboardingItem;
    })();

    v1.OnboardingFlow = (() => {
      class OnboardingFlow {
        constructor(properties) {
          this.onboardingItems = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OnboardingFlow(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.organizationHasMultipleProviders != null &&
            Object.hasOwnProperty.call(
              message,
              'organizationHasMultipleProviders'
            )
          ) {
            writer.uint32(24).bool(message.organizationHasMultipleProviders);
          }
          if (
            message.hasFormsAfterWelcome != null &&
            Object.hasOwnProperty.call(message, 'hasFormsAfterWelcome')
          ) {
            writer.uint32(32).bool(message.hasFormsAfterWelcome);
          }
          if (
            message.onboardingItems != null &&
            Object.hasOwnProperty.call(message, 'onboardingItems')
          ) {
            for (const element of message.onboardingItems) {
              $root.healthie.v1.OnboardingItem.encode(
                element,
                writer.uint32(42).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OnboardingFlow();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.organizationHasMultipleProviders = reader.bool();
                break;
              }
              case 4: {
                message.hasFormsAfterWelcome = reader.bool();
                break;
              }
              case 5: {
                if (
                  !message.onboardingItems ||
                  !message.onboardingItems.length
                ) {
                  message.onboardingItems = [];
                }
                message.onboardingItems.push(
                  $root.healthie.v1.OnboardingItem.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.OnboardingFlow) {
            return object;
          }
          const message = new $root.healthie.v1.OnboardingFlow();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.organizationHasMultipleProviders != null) {
            message.organizationHasMultipleProviders = Boolean(
              object.organizationHasMultipleProviders
            );
          }
          if (object.hasFormsAfterWelcome != null) {
            message.hasFormsAfterWelcome = Boolean(object.hasFormsAfterWelcome);
          }
          if (object.onboardingItems) {
            if (!Array.isArray(object.onboardingItems)) {
              throw new TypeError(
                '.healthie.v1.OnboardingFlow.onboardingItems: array type expected, but got ' +
                  typeof object.onboardingItems
              );
            }
            message.onboardingItems = new Array(object.onboardingItems.length);
            for (let i = 0; i < object.onboardingItems.length; ++i) {
              if (typeof object.onboardingItems[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.OnboardingFlow.onboardingItems: object expected, but got ' +
                    typeof object.onboardingItems[i]
                );
              }
              message.onboardingItems[i] =
                $root.healthie.v1.OnboardingItem.fromObject(
                  object.onboardingItems[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.onboardingItems = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.organizationHasMultipleProviders = false;
            object.hasFormsAfterWelcome = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.organizationHasMultipleProviders != null &&
            message.hasOwnProperty('organizationHasMultipleProviders')
          ) {
            object.organizationHasMultipleProviders =
              message.organizationHasMultipleProviders;
          }
          if (
            message.hasFormsAfterWelcome != null &&
            message.hasOwnProperty('hasFormsAfterWelcome')
          ) {
            object.hasFormsAfterWelcome = message.hasFormsAfterWelcome;
          }
          if (message.onboardingItems && message.onboardingItems.length) {
            object.onboardingItems = new Array(message.onboardingItems.length);
            for (let i = 0; i < message.onboardingItems.length; ++i) {
              object.onboardingItems[i] =
                $root.healthie.v1.OnboardingItem.toObject(
                  message.onboardingItems[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.OnboardingFlow';
        }
      }

      OnboardingFlow.prototype.id = '';
      OnboardingFlow.prototype.name = null;
      OnboardingFlow.prototype.organizationHasMultipleProviders = false;
      OnboardingFlow.prototype.hasFormsAfterWelcome = false;
      OnboardingFlow.prototype.onboardingItems = $util.emptyArray;

      return OnboardingFlow;
    })();

    v1.Task = (() => {
      class Task {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Task(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Task();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Task) {
            return object;
          }
          const message = new $root.healthie.v1.Task();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Task';
        }
      }

      Task.prototype.id = '';

      return Task;
    })();

    v1.Conversation = (() => {
      class Conversation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Conversation(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Conversation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.Conversation) {
            return object;
          }
          const message = new $root.healthie.v1.Conversation();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.Conversation';
        }
      }

      Conversation.prototype.id = '';

      return Conversation;
    })();

    v1.MetricEntry = (() => {
      class MetricEntry {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MetricEntry(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(18).string(message.type);
          }
          if (
            message.addedByUser != null &&
            Object.hasOwnProperty.call(message, 'addedByUser')
          ) {
            writer.uint32(26).string(message.addedByUser);
          }
          if (
            message.category != null &&
            Object.hasOwnProperty.call(message, 'category')
          ) {
            writer.uint32(34).string(message.category);
          }
          if (
            message.emotions != null &&
            Object.hasOwnProperty.call(message, 'emotions')
          ) {
            writer.uint32(42).string(message.emotions);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(50).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(58).string(message.description);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(73).double(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MetricEntry();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.type = reader.string();
                break;
              }
              case 3: {
                message.addedByUser = reader.string();
                break;
              }
              case 4: {
                message.category = reader.string();
                break;
              }
              case 5: {
                message.emotions = reader.string();
                break;
              }
              case 6: {
                message.name = reader.string();
                break;
              }
              case 7: {
                message.description = reader.string();
                break;
              }
              case 8: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.value = reader.double();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.MetricEntry) {
            return object;
          }
          const message = new $root.healthie.v1.MetricEntry();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.addedByUser != null) {
            message.addedByUser = String(object.addedByUser);
          }
          if (object.category != null) {
            message.category = String(object.category);
          }
          if (object.emotions != null) {
            message.emotions = String(object.emotions);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.MetricEntry.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.value != null) {
            message.value = Number(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.createdAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (
            message.addedByUser != null &&
            message.hasOwnProperty('addedByUser')
          ) {
            object.addedByUser = message.addedByUser;
          }
          if (message.category != null && message.hasOwnProperty('category')) {
            object.category = message.category;
          }
          if (message.emotions != null && message.hasOwnProperty('emotions')) {
            object.emotions = message.emotions;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value =
              options.json && !isFinite(message.value)
                ? String(message.value)
                : message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.MetricEntry';
        }
      }

      MetricEntry.prototype.id = '';
      MetricEntry.prototype.type = null;
      MetricEntry.prototype.addedByUser = null;
      MetricEntry.prototype.category = null;
      MetricEntry.prototype.emotions = null;
      MetricEntry.prototype.name = null;
      MetricEntry.prototype.description = null;
      MetricEntry.prototype.createdAt = null;
      MetricEntry.prototype.value = null;

      return MetricEntry;
    })();

    v1.MetricEntries = (() => {
      class MetricEntries {
        constructor(properties) {
          this.metricEntries = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MetricEntries(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.metricEntries != null &&
            Object.hasOwnProperty.call(message, 'metricEntries')
          ) {
            for (const element of message.metricEntries) {
              $root.healthie.v1.MetricEntry.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MetricEntries();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.metricEntries || !message.metricEntries.length) {
                  message.metricEntries = [];
                }
                message.metricEntries.push(
                  $root.healthie.v1.MetricEntry.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.MetricEntries) {
            return object;
          }
          const message = new $root.healthie.v1.MetricEntries();
          if (object.metricEntries) {
            if (!Array.isArray(object.metricEntries)) {
              throw new TypeError(
                '.healthie.v1.MetricEntries.metricEntries: array type expected, but got ' +
                  typeof object.metricEntries
              );
            }
            message.metricEntries = new Array(object.metricEntries.length);
            for (let i = 0; i < object.metricEntries.length; ++i) {
              if (typeof object.metricEntries[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.MetricEntries.metricEntries: object expected, but got ' +
                    typeof object.metricEntries[i]
                );
              }
              message.metricEntries[i] =
                $root.healthie.v1.MetricEntry.fromObject(
                  object.metricEntries[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.metricEntries = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.metricEntries && message.metricEntries.length) {
            object.metricEntries = new Array(message.metricEntries.length);
            for (let i = 0; i < message.metricEntries.length; ++i) {
              object.metricEntries[i] = $root.healthie.v1.MetricEntry.toObject(
                message.metricEntries[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.MetricEntries';
        }
      }

      MetricEntries.prototype.metricEntries = $util.emptyArray;

      return MetricEntries;
    })();

    v1.HealthieWebhookEvent = (() => {
      class HealthieWebhookEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieWebhookEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.resourceId != null &&
            Object.hasOwnProperty.call(message, 'resourceId')
          ) {
            writer.uint32(10).string(message.resourceId);
          }
          if (
            message.resourceIdType != null &&
            Object.hasOwnProperty.call(message, 'resourceIdType')
          ) {
            writer.uint32(18).string(message.resourceIdType);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(26).string(message.eventType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieWebhookEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.resourceId = reader.string();
                break;
              }
              case 2: {
                message.resourceIdType = reader.string();
                break;
              }
              case 3: {
                message.eventType = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.HealthieWebhookEvent) {
            return object;
          }
          const message = new $root.healthie.v1.HealthieWebhookEvent();
          if (object.resourceId != null) {
            message.resourceId = String(object.resourceId);
          }
          if (object.resourceIdType != null) {
            message.resourceIdType = String(object.resourceIdType);
          }
          if (object.eventType != null) {
            message.eventType = String(object.eventType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.resourceId = '';
            object.resourceIdType = '';
            object.eventType = '';
          }
          let keys;
          if (
            message.resourceId != null &&
            message.hasOwnProperty('resourceId')
          ) {
            object.resourceId = message.resourceId;
          }
          if (
            message.resourceIdType != null &&
            message.hasOwnProperty('resourceIdType')
          ) {
            object.resourceIdType = message.resourceIdType;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType = message.eventType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.HealthieWebhookEvent';
        }
      }

      HealthieWebhookEvent.prototype.resourceId = '';
      HealthieWebhookEvent.prototype.resourceIdType = '';
      HealthieWebhookEvent.prototype.eventType = '';

      return HealthieWebhookEvent;
    })();

    v1.GetUserRequest = (() => {
      class GetUserRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetUserRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetUserRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetUserRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetUserRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetUserRequest';
        }
      }

      GetUserRequest.prototype.id = '';
      GetUserRequest.prototype.environment = 0;

      return GetUserRequest;
    })();

    v1.GetUserResponse = (() => {
      class GetUserResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetUserResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            $root.healthie.v1.User.encode(
              message.user,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetUserResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = $root.healthie.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetUserResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetUserResponse();
          if (object.user != null) {
            if (typeof object.user !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetUserResponse.user: object expected, but got ' +
                  typeof object.user
              );
            }
            message.user = $root.healthie.v1.User.fromObject(object.user);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = null;
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = $root.healthie.v1.User.toObject(
              message.user,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetUserResponse';
        }
      }

      GetUserResponse.prototype.user = null;

      return GetUserResponse;
    })();

    v1.GetCustomModuleFormRequest = (() => {
      class GetCustomModuleFormRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetCustomModuleFormRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetCustomModuleFormRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetCustomModuleFormRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetCustomModuleFormRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetCustomModuleFormRequest';
        }
      }

      GetCustomModuleFormRequest.prototype.id = '';
      GetCustomModuleFormRequest.prototype.environment = 0;

      return GetCustomModuleFormRequest;
    })();

    v1.GetCustomModuleFormResponse = (() => {
      class GetCustomModuleFormResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetCustomModuleFormResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customModuleForm != null &&
            Object.hasOwnProperty.call(message, 'customModuleForm')
          ) {
            $root.healthie.v1.CustomModuleForm.encode(
              message.customModuleForm,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetCustomModuleFormResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customModuleForm =
                  $root.healthie.v1.CustomModuleForm.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetCustomModuleFormResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetCustomModuleFormResponse();
          if (object.customModuleForm != null) {
            if (typeof object.customModuleForm !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetCustomModuleFormResponse.customModuleForm: object expected, but got ' +
                  typeof object.customModuleForm
              );
            }
            message.customModuleForm =
              $root.healthie.v1.CustomModuleForm.fromObject(
                object.customModuleForm
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customModuleForm = null;
          }
          let keys;
          if (
            message.customModuleForm != null &&
            message.hasOwnProperty('customModuleForm')
          ) {
            object.customModuleForm =
              $root.healthie.v1.CustomModuleForm.toObject(
                message.customModuleForm,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetCustomModuleFormResponse';
        }
      }

      GetCustomModuleFormResponse.prototype.customModuleForm = null;

      return GetCustomModuleFormResponse;
    })();

    v1.ListCustomModuleFormsRequest = (() => {
      class ListCustomModuleFormsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListCustomModuleFormsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListCustomModuleFormsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.ListCustomModuleFormsRequest
          ) {
            return object;
          }
          const message = new $root.healthie.v1.ListCustomModuleFormsRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.healthie.v1.ListCustomModuleFormsRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListCustomModuleFormsRequest';
        }
      }

      ListCustomModuleFormsRequest.prototype.page = null;
      ListCustomModuleFormsRequest.prototype.environment = 0;

      return ListCustomModuleFormsRequest;
    })();

    v1.ListCustomModuleFormsResponse = (() => {
      class ListCustomModuleFormsResponse {
        constructor(properties) {
          this.customModuleForms = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListCustomModuleFormsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.customModuleForms != null &&
            Object.hasOwnProperty.call(message, 'customModuleForms')
          ) {
            for (const element of message.customModuleForms) {
              $root.healthie.v1.CustomModuleForm.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListCustomModuleFormsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (
                  !message.customModuleForms ||
                  !message.customModuleForms.length
                ) {
                  message.customModuleForms = [];
                }
                message.customModuleForms.push(
                  $root.healthie.v1.CustomModuleForm.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.ListCustomModuleFormsResponse
          ) {
            return object;
          }
          const message = new $root.healthie.v1.ListCustomModuleFormsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.customModuleForms) {
            if (!Array.isArray(object.customModuleForms)) {
              throw new TypeError(
                '.healthie.v1.ListCustomModuleFormsResponse.customModuleForms: array type expected, but got ' +
                  typeof object.customModuleForms
              );
            }
            message.customModuleForms = new Array(
              object.customModuleForms.length
            );
            for (let i = 0; i < object.customModuleForms.length; ++i) {
              if (typeof object.customModuleForms[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListCustomModuleFormsResponse.customModuleForms: object expected, but got ' +
                    typeof object.customModuleForms[i]
                );
              }
              message.customModuleForms[i] =
                $root.healthie.v1.CustomModuleForm.fromObject(
                  object.customModuleForms[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.customModuleForms = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.customModuleForms && message.customModuleForms.length) {
            object.customModuleForms = new Array(
              message.customModuleForms.length
            );
            for (let i = 0; i < message.customModuleForms.length; ++i) {
              object.customModuleForms[i] =
                $root.healthie.v1.CustomModuleForm.toObject(
                  message.customModuleForms[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListCustomModuleFormsResponse';
        }
      }

      ListCustomModuleFormsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListCustomModuleFormsResponse.prototype.customModuleForms =
        $util.emptyArray;

      return ListCustomModuleFormsResponse;
    })();

    v1.GetFormAnswerGroupRequest = (() => {
      class GetFormAnswerGroupRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetFormAnswerGroupRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetFormAnswerGroupRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetFormAnswerGroupRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetFormAnswerGroupRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetFormAnswerGroupRequest';
        }
      }

      GetFormAnswerGroupRequest.prototype.id = '';
      GetFormAnswerGroupRequest.prototype.environment = 0;

      return GetFormAnswerGroupRequest;
    })();

    v1.GetFormAnswerGroupResponse = (() => {
      class GetFormAnswerGroupResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetFormAnswerGroupResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formAnswerGroup != null &&
            Object.hasOwnProperty.call(message, 'formAnswerGroup')
          ) {
            $root.healthie.v1.FormAnswerGroup.encode(
              message.formAnswerGroup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetFormAnswerGroupResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formAnswerGroup =
                  $root.healthie.v1.FormAnswerGroup.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetFormAnswerGroupResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetFormAnswerGroupResponse();
          if (object.formAnswerGroup != null) {
            if (typeof object.formAnswerGroup !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetFormAnswerGroupResponse.formAnswerGroup: object expected, but got ' +
                  typeof object.formAnswerGroup
              );
            }
            message.formAnswerGroup =
              $root.healthie.v1.FormAnswerGroup.fromObject(
                object.formAnswerGroup
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.formAnswerGroup = null;
          }
          let keys;
          if (
            message.formAnswerGroup != null &&
            message.hasOwnProperty('formAnswerGroup')
          ) {
            object.formAnswerGroup = $root.healthie.v1.FormAnswerGroup.toObject(
              message.formAnswerGroup,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetFormAnswerGroupResponse';
        }
      }

      GetFormAnswerGroupResponse.prototype.formAnswerGroup = null;

      return GetFormAnswerGroupResponse;
    })();

    v1.ListAppointmentTypesRequest = (() => {
      class ListAppointmentTypesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAppointmentTypesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAppointmentTypesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListAppointmentTypesRequest) {
            return object;
          }
          const message = new $root.healthie.v1.ListAppointmentTypesRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.healthie.v1.ListAppointmentTypesRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListAppointmentTypesRequest';
        }
      }

      ListAppointmentTypesRequest.prototype.page = null;
      ListAppointmentTypesRequest.prototype.environment = 0;

      return ListAppointmentTypesRequest;
    })();

    v1.ListAppointmentTypesResponse = (() => {
      class ListAppointmentTypesResponse {
        constructor(properties) {
          this.appointmentTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAppointmentTypesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.appointmentTypes != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypes')
          ) {
            for (const element of message.appointmentTypes) {
              $root.healthie.v1.AppointmentType.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAppointmentTypesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (
                  !message.appointmentTypes ||
                  !message.appointmentTypes.length
                ) {
                  message.appointmentTypes = [];
                }
                message.appointmentTypes.push(
                  $root.healthie.v1.AppointmentType.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.ListAppointmentTypesResponse
          ) {
            return object;
          }
          const message = new $root.healthie.v1.ListAppointmentTypesResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.appointmentTypes) {
            if (!Array.isArray(object.appointmentTypes)) {
              throw new TypeError(
                '.healthie.v1.ListAppointmentTypesResponse.appointmentTypes: array type expected, but got ' +
                  typeof object.appointmentTypes
              );
            }
            message.appointmentTypes = new Array(
              object.appointmentTypes.length
            );
            for (let i = 0; i < object.appointmentTypes.length; ++i) {
              if (typeof object.appointmentTypes[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListAppointmentTypesResponse.appointmentTypes: object expected, but got ' +
                    typeof object.appointmentTypes[i]
                );
              }
              message.appointmentTypes[i] =
                $root.healthie.v1.AppointmentType.fromObject(
                  object.appointmentTypes[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.appointmentTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.appointmentTypes && message.appointmentTypes.length) {
            object.appointmentTypes = new Array(
              message.appointmentTypes.length
            );
            for (let i = 0; i < message.appointmentTypes.length; ++i) {
              object.appointmentTypes[i] =
                $root.healthie.v1.AppointmentType.toObject(
                  message.appointmentTypes[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListAppointmentTypesResponse';
        }
      }

      ListAppointmentTypesResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListAppointmentTypesResponse.prototype.appointmentTypes =
        $util.emptyArray;

      return ListAppointmentTypesResponse;
    })();

    v1.GetAppointmentTypeRequest = (() => {
      class GetAppointmentTypeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentTypeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentTypeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetAppointmentTypeRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetAppointmentTypeRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetAppointmentTypeRequest';
        }
      }

      GetAppointmentTypeRequest.prototype.id = '';
      GetAppointmentTypeRequest.prototype.environment = 0;

      return GetAppointmentTypeRequest;
    })();

    v1.GetAppointmentTypeResponse = (() => {
      class GetAppointmentTypeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentTypeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointmentType != null &&
            Object.hasOwnProperty.call(message, 'appointmentType')
          ) {
            $root.healthie.v1.AppointmentType.encode(
              message.appointmentType,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentTypeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentType =
                  $root.healthie.v1.AppointmentType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetAppointmentTypeResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetAppointmentTypeResponse();
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetAppointmentTypeResponse.appointmentType: object expected, but got ' +
                  typeof object.appointmentType
              );
            }
            message.appointmentType =
              $root.healthie.v1.AppointmentType.fromObject(
                object.appointmentType
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentType = null;
          }
          let keys;
          if (
            message.appointmentType != null &&
            message.hasOwnProperty('appointmentType')
          ) {
            object.appointmentType = $root.healthie.v1.AppointmentType.toObject(
              message.appointmentType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetAppointmentTypeResponse';
        }
      }

      GetAppointmentTypeResponse.prototype.appointmentType = null;

      return GetAppointmentTypeResponse;
    })();

    v1.GetAppointmentRequest = (() => {
      class GetAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetAppointmentRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetAppointmentRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetAppointmentRequest';
        }
      }

      GetAppointmentRequest.prototype.id = '';
      GetAppointmentRequest.prototype.environment = 0;

      return GetAppointmentRequest;
    })();

    v1.GetAppointmentResponse = (() => {
      class GetAppointmentResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            $root.healthie.v1.Appointment.encode(
              message.appointment,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointment = $root.healthie.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetAppointmentResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetAppointmentResponse();
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetAppointmentResponse.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment = $root.healthie.v1.Appointment.fromObject(
              object.appointment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointment = null;
          }
          let keys;
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment = $root.healthie.v1.Appointment.toObject(
              message.appointment,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetAppointmentResponse';
        }
      }

      GetAppointmentResponse.prototype.appointment = null;

      return GetAppointmentResponse;
    })();

    v1.GetCMS1500Request = (() => {
      class GetCMS1500Request {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetCMS1500Request(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetCMS1500Request();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetCMS1500Request) {
            return object;
          }
          const message = new $root.healthie.v1.GetCMS1500Request();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetCMS1500Request';
        }
      }

      GetCMS1500Request.prototype.id = '';
      GetCMS1500Request.prototype.environment = 0;

      return GetCMS1500Request;
    })();

    v1.GetCMS1500Response = (() => {
      class GetCMS1500Response {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetCMS1500Response(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.cms1500 != null &&
            Object.hasOwnProperty.call(message, 'cms1500')
          ) {
            $root.healthie.v1.CMS1500.encode(
              message.cms1500,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetCMS1500Response();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.cms1500 = $root.healthie.v1.CMS1500.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetCMS1500Response) {
            return object;
          }
          const message = new $root.healthie.v1.GetCMS1500Response();
          if (object.cms1500 != null) {
            if (typeof object.cms1500 !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetCMS1500Response.cms1500: object expected, but got ' +
                  typeof object.cms1500
              );
            }
            message.cms1500 = $root.healthie.v1.CMS1500.fromObject(
              object.cms1500
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.cms1500 = null;
          }
          let keys;
          if (message.cms1500 != null && message.hasOwnProperty('cms1500')) {
            object.cms1500 = $root.healthie.v1.CMS1500.toObject(
              message.cms1500,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetCMS1500Response';
        }
      }

      GetCMS1500Response.prototype.cms1500 = null;

      return GetCMS1500Response;
    })();

    v1.GetInsuranceAuthorizationRequest = (() => {
      class GetInsuranceAuthorizationRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetInsuranceAuthorizationRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetInsuranceAuthorizationRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.GetInsuranceAuthorizationRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.GetInsuranceAuthorizationRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetInsuranceAuthorizationRequest';
        }
      }

      GetInsuranceAuthorizationRequest.prototype.id = '';
      GetInsuranceAuthorizationRequest.prototype.environment = 0;

      return GetInsuranceAuthorizationRequest;
    })();

    v1.GetInsuranceAuthorizationResponse = (() => {
      class GetInsuranceAuthorizationResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetInsuranceAuthorizationResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.insuranceAuthorization != null &&
            Object.hasOwnProperty.call(message, 'insuranceAuthorization')
          ) {
            $root.healthie.v1.InsuranceAuthorization.encode(
              message.insuranceAuthorization,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetInsuranceAuthorizationResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.insuranceAuthorization =
                  $root.healthie.v1.InsuranceAuthorization.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.GetInsuranceAuthorizationResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.GetInsuranceAuthorizationResponse();
          if (object.insuranceAuthorization != null) {
            if (typeof object.insuranceAuthorization !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetInsuranceAuthorizationResponse.insuranceAuthorization: object expected, but got ' +
                  typeof object.insuranceAuthorization
              );
            }
            message.insuranceAuthorization =
              $root.healthie.v1.InsuranceAuthorization.fromObject(
                object.insuranceAuthorization
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.insuranceAuthorization = null;
          }
          let keys;
          if (
            message.insuranceAuthorization != null &&
            message.hasOwnProperty('insuranceAuthorization')
          ) {
            object.insuranceAuthorization =
              $root.healthie.v1.InsuranceAuthorization.toObject(
                message.insuranceAuthorization,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.GetInsuranceAuthorizationResponse'
          );
        }
      }

      GetInsuranceAuthorizationResponse.prototype.insuranceAuthorization = null;

      return GetInsuranceAuthorizationResponse;
    })();

    v1.GetRequestedFormCompletionRequest = (() => {
      class GetRequestedFormCompletionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetRequestedFormCompletionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetRequestedFormCompletionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.GetRequestedFormCompletionRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.GetRequestedFormCompletionRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.GetRequestedFormCompletionRequest'
          );
        }
      }

      GetRequestedFormCompletionRequest.prototype.id = '';
      GetRequestedFormCompletionRequest.prototype.environment = 0;

      return GetRequestedFormCompletionRequest;
    })();

    v1.GetRequestedFormCompletionResponse = (() => {
      class GetRequestedFormCompletionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetRequestedFormCompletionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.requestedFormCompletion != null &&
            Object.hasOwnProperty.call(message, 'requestedFormCompletion')
          ) {
            $root.healthie.v1.RequestedFormCompletion.encode(
              message.requestedFormCompletion,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetRequestedFormCompletionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.requestedFormCompletion =
                  $root.healthie.v1.RequestedFormCompletion.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.GetRequestedFormCompletionResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.GetRequestedFormCompletionResponse();
          if (object.requestedFormCompletion != null) {
            if (typeof object.requestedFormCompletion !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetRequestedFormCompletionResponse.requestedFormCompletion: object expected, but got ' +
                  typeof object.requestedFormCompletion
              );
            }
            message.requestedFormCompletion =
              $root.healthie.v1.RequestedFormCompletion.fromObject(
                object.requestedFormCompletion
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.requestedFormCompletion = null;
          }
          let keys;
          if (
            message.requestedFormCompletion != null &&
            message.hasOwnProperty('requestedFormCompletion')
          ) {
            object.requestedFormCompletion =
              $root.healthie.v1.RequestedFormCompletion.toObject(
                message.requestedFormCompletion,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.GetRequestedFormCompletionResponse'
          );
        }
      }

      GetRequestedFormCompletionResponse.prototype.requestedFormCompletion =
        null;

      return GetRequestedFormCompletionResponse;
    })();

    v1.GetBillingItemRequest = (() => {
      class GetBillingItemRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetBillingItemRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetBillingItemRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetBillingItemRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetBillingItemRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetBillingItemRequest';
        }
      }

      GetBillingItemRequest.prototype.id = '';
      GetBillingItemRequest.prototype.environment = 0;

      return GetBillingItemRequest;
    })();

    v1.GetBillingItemResponse = (() => {
      class GetBillingItemResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetBillingItemResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.billingItem != null &&
            Object.hasOwnProperty.call(message, 'billingItem')
          ) {
            $root.healthie.v1.BillingItem.encode(
              message.billingItem,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetBillingItemResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.billingItem = $root.healthie.v1.BillingItem.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetBillingItemResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetBillingItemResponse();
          if (object.billingItem != null) {
            if (typeof object.billingItem !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetBillingItemResponse.billingItem: object expected, but got ' +
                  typeof object.billingItem
              );
            }
            message.billingItem = $root.healthie.v1.BillingItem.fromObject(
              object.billingItem
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.billingItem = null;
          }
          let keys;
          if (
            message.billingItem != null &&
            message.hasOwnProperty('billingItem')
          ) {
            object.billingItem = $root.healthie.v1.BillingItem.toObject(
              message.billingItem,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetBillingItemResponse';
        }
      }

      GetBillingItemResponse.prototype.billingItem = null;

      return GetBillingItemResponse;
    })();

    v1.GetPolicyRequest = (() => {
      class GetPolicyRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPolicyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPolicyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetPolicyRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetPolicyRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetPolicyRequest';
        }
      }

      GetPolicyRequest.prototype.id = '';
      GetPolicyRequest.prototype.environment = 0;

      return GetPolicyRequest;
    })();

    v1.GetPolicyResponse = (() => {
      class GetPolicyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPolicyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.policy != null &&
            Object.hasOwnProperty.call(message, 'policy')
          ) {
            $root.healthie.v1.Policy.encode(
              message.policy,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPolicyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.policy = $root.healthie.v1.Policy.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetPolicyResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetPolicyResponse();
          if (object.policy != null) {
            if (typeof object.policy !== 'object') {
              throw new TypeError(
                '.healthie.v1.GetPolicyResponse.policy: object expected, but got ' +
                  typeof object.policy
              );
            }
            message.policy = $root.healthie.v1.Policy.fromObject(object.policy);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.policy = null;
          }
          let keys;
          if (message.policy != null && message.hasOwnProperty('policy')) {
            object.policy = $root.healthie.v1.Policy.toObject(
              message.policy,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetPolicyResponse';
        }
      }

      GetPolicyResponse.prototype.policy = null;

      return GetPolicyResponse;
    })();

    v1.ListUserFormAnswerGroupsRequest = (() => {
      class ListUserFormAnswerGroupsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListUserFormAnswerGroupsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(8).int32(message.environment);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(18).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListUserFormAnswerGroupsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.environment = reader.int32();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.ListUserFormAnswerGroupsRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.ListUserFormAnswerGroupsRequest();
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            object.id = '';
          }
          let keys;
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListUserFormAnswerGroupsRequest';
        }
      }

      ListUserFormAnswerGroupsRequest.prototype.environment = 0;
      ListUserFormAnswerGroupsRequest.prototype.id = '';

      return ListUserFormAnswerGroupsRequest;
    })();

    v1.ListUserFormAnswerGroupsResponse = (() => {
      class ListUserFormAnswerGroupsResponse {
        constructor(properties) {
          this.formAnswerGroups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListUserFormAnswerGroupsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formAnswerGroups != null &&
            Object.hasOwnProperty.call(message, 'formAnswerGroups')
          ) {
            for (const element of message.formAnswerGroups) {
              $root.healthie.v1.FormAnswerGroup.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListUserFormAnswerGroupsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 3: {
                if (
                  !message.formAnswerGroups ||
                  !message.formAnswerGroups.length
                ) {
                  message.formAnswerGroups = [];
                }
                message.formAnswerGroups.push(
                  $root.healthie.v1.FormAnswerGroup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.ListUserFormAnswerGroupsResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.ListUserFormAnswerGroupsResponse();
          if (object.formAnswerGroups) {
            if (!Array.isArray(object.formAnswerGroups)) {
              throw new TypeError(
                '.healthie.v1.ListUserFormAnswerGroupsResponse.formAnswerGroups: array type expected, but got ' +
                  typeof object.formAnswerGroups
              );
            }
            message.formAnswerGroups = new Array(
              object.formAnswerGroups.length
            );
            for (let i = 0; i < object.formAnswerGroups.length; ++i) {
              if (typeof object.formAnswerGroups[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListUserFormAnswerGroupsResponse.formAnswerGroups: object expected, but got ' +
                    typeof object.formAnswerGroups[i]
                );
              }
              message.formAnswerGroups[i] =
                $root.healthie.v1.FormAnswerGroup.fromObject(
                  object.formAnswerGroups[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.formAnswerGroups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.formAnswerGroups && message.formAnswerGroups.length) {
            object.formAnswerGroups = new Array(
              message.formAnswerGroups.length
            );
            for (let i = 0; i < message.formAnswerGroups.length; ++i) {
              object.formAnswerGroups[i] =
                $root.healthie.v1.FormAnswerGroup.toObject(
                  message.formAnswerGroups[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListUserFormAnswerGroupsResponse';
        }
      }

      ListUserFormAnswerGroupsResponse.prototype.formAnswerGroups =
        $util.emptyArray;

      return ListUserFormAnswerGroupsResponse;
    })();

    v1.ListPatientsRequest = (() => {
      class ListPatientsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(8).int32(message.environment);
          }
          if (
            message.perPage != null &&
            Object.hasOwnProperty.call(message, 'perPage')
          ) {
            writer.uint32(16).int64(message.perPage);
          }
          if (
            message.after != null &&
            Object.hasOwnProperty.call(message, 'after')
          ) {
            writer.uint32(26).string(message.after);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.environment = reader.int32();
                break;
              }
              case 2: {
                message.perPage = reader.int64();
                break;
              }
              case 3: {
                message.after = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListPatientsRequest) {
            return object;
          }
          const message = new $root.healthie.v1.ListPatientsRequest();
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          if (object.perPage != null) {
            if ($util.Long) {
              (message.perPage = $util.Long.fromValue(
                object.perPage
              )).unsigned = false;
            } else if (typeof object.perPage === 'string') {
              message.perPage = parseInt(object.perPage, 10);
            } else if (typeof object.perPage === 'number') {
              message.perPage = object.perPage;
            } else if (typeof object.perPage === 'object') {
              message.perPage = new $util.LongBits(
                object.perPage.low >>> 0,
                object.perPage.high >>> 0
              ).toNumber();
            }
          }
          if (object.after != null) {
            message.after = String(object.after);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.perPage =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.perPage = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          if (message.perPage != null && message.hasOwnProperty('perPage')) {
            object.perPage =
              typeof message.perPage === 'number'
                ? options.longs === String
                  ? String(message.perPage)
                  : message.perPage
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.perPage)
                : options.longs === Number
                ? new $util.LongBits(
                    message.perPage.low >>> 0,
                    message.perPage.high >>> 0
                  ).toNumber()
                : message.perPage;
          }
          if (message.after != null && message.hasOwnProperty('after')) {
            object.after = message.after;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListPatientsRequest';
        }
      }

      ListPatientsRequest.prototype.environment = 0;
      ListPatientsRequest.prototype.perPage = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      ListPatientsRequest.prototype.after = null;

      return ListPatientsRequest;
    })();

    v1.ListPatientsResponse = (() => {
      class ListPatientsResponse {
        constructor(properties) {
          this.users = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.users != null &&
            Object.hasOwnProperty.call(message, 'users')
          ) {
            for (const element of message.users) {
              $root.healthie.v1.User.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.cursor != null &&
            Object.hasOwnProperty.call(message, 'cursor')
          ) {
            writer.uint32(26).string(message.cursor);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.users || !message.users.length) {
                  message.users = [];
                }
                message.users.push(
                  $root.healthie.v1.User.decode(reader, reader.uint32())
                );
                break;
              }
              case 3: {
                message.cursor = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListPatientsResponse) {
            return object;
          }
          const message = new $root.healthie.v1.ListPatientsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.users) {
            if (!Array.isArray(object.users)) {
              throw new TypeError(
                '.healthie.v1.ListPatientsResponse.users: array type expected, but got ' +
                  typeof object.users
              );
            }
            message.users = new Array(object.users.length);
            for (let i = 0; i < object.users.length; ++i) {
              if (typeof object.users[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListPatientsResponse.users: object expected, but got ' +
                    typeof object.users[i]
                );
              }
              message.users[i] = $root.healthie.v1.User.fromObject(
                object.users[i]
              );
            }
          }
          if (object.cursor != null) {
            message.cursor = String(object.cursor);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.users = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.users && message.users.length) {
            object.users = new Array(message.users.length);
            for (let i = 0; i < message.users.length; ++i) {
              object.users[i] = $root.healthie.v1.User.toObject(
                message.users[i],
                options
              );
            }
          }
          if (message.cursor != null && message.hasOwnProperty('cursor')) {
            object.cursor = message.cursor;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListPatientsResponse';
        }
      }

      ListPatientsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListPatientsResponse.prototype.users = $util.emptyArray;
      ListPatientsResponse.prototype.cursor = null;

      return ListPatientsResponse;
    })();

    v1.StreamPatientIDsRequest = (() => {
      class StreamPatientIDsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StreamPatientIDsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(8).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StreamPatientIDsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.StreamPatientIDsRequest) {
            return object;
          }
          const message = new $root.healthie.v1.StreamPatientIDsRequest();
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.StreamPatientIDsRequest';
        }
      }

      StreamPatientIDsRequest.prototype.environment = 0;

      return StreamPatientIDsRequest;
    })();

    v1.StreamPatientIDsResponse = (() => {
      class StreamPatientIDsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StreamPatientIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StreamPatientIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.StreamPatientIDsResponse) {
            return object;
          }
          const message = new $root.healthie.v1.StreamPatientIDsResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.StreamPatientIDsResponse';
        }
      }

      StreamPatientIDsResponse.prototype.id = '';

      return StreamPatientIDsResponse;
    })();

    v1.ListPatientIDsResponse = (() => {
      class ListPatientIDsResponse {
        constructor(properties) {
          this.ids = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.ids != null &&
            Object.hasOwnProperty.call(message, 'ids')
          ) {
            for (const element of message.ids) {
              writer.uint32(10).string(element);
            }
          }
          if (
            message.cursor != null &&
            Object.hasOwnProperty.call(message, 'cursor')
          ) {
            writer.uint32(18).string(message.cursor);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.ids || !message.ids.length) {
                  message.ids = [];
                }
                message.ids.push(reader.string());
                break;
              }
              case 2: {
                message.cursor = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListPatientIDsResponse) {
            return object;
          }
          const message = new $root.healthie.v1.ListPatientIDsResponse();
          if (object.ids) {
            if (!Array.isArray(object.ids)) {
              throw new TypeError(
                '.healthie.v1.ListPatientIDsResponse.ids: array type expected, but got ' +
                  typeof object.ids
              );
            }
            message.ids = new Array(object.ids.length);
            for (let i = 0; i < object.ids.length; ++i) {
              message.ids[i] = String(object.ids[i]);
            }
          }
          if (object.cursor != null) {
            message.cursor = String(object.cursor);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.ids = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.ids && message.ids.length) {
            object.ids = new Array(message.ids.length);
            for (let i = 0; i < message.ids.length; ++i) {
              object.ids[i] = message.ids[i];
            }
          }
          if (message.cursor != null && message.hasOwnProperty('cursor')) {
            object.cursor = message.cursor;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListPatientIDsResponse';
        }
      }

      ListPatientIDsResponse.prototype.ids = $util.emptyArray;
      ListPatientIDsResponse.prototype.cursor = null;

      return ListPatientIDsResponse;
    })();

    v1.ListUserGroupsRequest = (() => {
      class ListUserGroupsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListUserGroupsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(8).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListUserGroupsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListUserGroupsRequest) {
            return object;
          }
          const message = new $root.healthie.v1.ListUserGroupsRequest();
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
          }
          let keys;
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListUserGroupsRequest';
        }
      }

      ListUserGroupsRequest.prototype.environment = 0;

      return ListUserGroupsRequest;
    })();

    v1.ListUserGroupsResponse = (() => {
      class ListUserGroupsResponse {
        constructor(properties) {
          this.userGroups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListUserGroupsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.userGroups != null &&
            Object.hasOwnProperty.call(message, 'userGroups')
          ) {
            for (const element of message.userGroups) {
              $root.healthie.v1.UserGroup.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListUserGroupsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.userGroups || !message.userGroups.length) {
                  message.userGroups = [];
                }
                message.userGroups.push(
                  $root.healthie.v1.UserGroup.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.ListUserGroupsResponse) {
            return object;
          }
          const message = new $root.healthie.v1.ListUserGroupsResponse();
          if (object.userGroups) {
            if (!Array.isArray(object.userGroups)) {
              throw new TypeError(
                '.healthie.v1.ListUserGroupsResponse.userGroups: array type expected, but got ' +
                  typeof object.userGroups
              );
            }
            message.userGroups = new Array(object.userGroups.length);
            for (let i = 0; i < object.userGroups.length; ++i) {
              if (typeof object.userGroups[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListUserGroupsResponse.userGroups: object expected, but got ' +
                    typeof object.userGroups[i]
                );
              }
              message.userGroups[i] = $root.healthie.v1.UserGroup.fromObject(
                object.userGroups[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.userGroups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.userGroups && message.userGroups.length) {
            object.userGroups = new Array(message.userGroups.length);
            for (let i = 0; i < message.userGroups.length; ++i) {
              object.userGroups[i] = $root.healthie.v1.UserGroup.toObject(
                message.userGroups[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.ListUserGroupsResponse';
        }
      }

      ListUserGroupsResponse.prototype.userGroups = $util.emptyArray;

      return ListUserGroupsResponse;
    })();

    v1.CreateWebhooksForSourceRequest = (() => {
      class CreateWebhooksForSourceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWebhooksForSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWebhooksForSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.CreateWebhooksForSourceRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.CreateWebhooksForSourceRequest();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceId = '';
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CreateWebhooksForSourceRequest';
        }
      }

      CreateWebhooksForSourceRequest.prototype.sourceId = '';

      return CreateWebhooksForSourceRequest;
    })();

    v1.CreateWebhooksForSourceResponse = (() => {
      class CreateWebhooksForSourceResponse {
        constructor(properties) {
          this.webhooks = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWebhooksForSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhooks != null &&
            Object.hasOwnProperty.call(message, 'webhooks')
          ) {
            for (const element of message.webhooks) {
              $root.healthie.v1.Webhook.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWebhooksForSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.webhooks || !message.webhooks.length) {
                  message.webhooks = [];
                }
                message.webhooks.push(
                  $root.healthie.v1.Webhook.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.CreateWebhooksForSourceResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.CreateWebhooksForSourceResponse();
          if (object.webhooks) {
            if (!Array.isArray(object.webhooks)) {
              throw new TypeError(
                '.healthie.v1.CreateWebhooksForSourceResponse.webhooks: array type expected, but got ' +
                  typeof object.webhooks
              );
            }
            message.webhooks = new Array(object.webhooks.length);
            for (let i = 0; i < object.webhooks.length; ++i) {
              if (typeof object.webhooks[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.CreateWebhooksForSourceResponse.webhooks: object expected, but got ' +
                    typeof object.webhooks[i]
                );
              }
              message.webhooks[i] = $root.healthie.v1.Webhook.fromObject(
                object.webhooks[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.webhooks = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.webhooks && message.webhooks.length) {
            object.webhooks = new Array(message.webhooks.length);
            for (let i = 0; i < message.webhooks.length; ++i) {
              object.webhooks[i] = $root.healthie.v1.Webhook.toObject(
                message.webhooks[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CreateWebhooksForSourceResponse';
        }
      }

      CreateWebhooksForSourceResponse.prototype.webhooks = $util.emptyArray;

      return CreateWebhooksForSourceResponse;
    })();

    v1.CreateDocumentRequest = (() => {
      class CreateDocumentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDocumentRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerPatientId != null &&
            Object.hasOwnProperty.call(message, 'customerPatientId')
          ) {
            writer.uint32(10).string(message.customerPatientId);
          }
          if (
            message.propertyName != null &&
            Object.hasOwnProperty.call(message, 'propertyName')
          ) {
            writer.uint32(18).string(message.propertyName);
          }
          if (
            message.makePatientAccessible != null &&
            Object.hasOwnProperty.call(message, 'makePatientAccessible')
          ) {
            writer.uint32(24).bool(message.makePatientAccessible);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDocumentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerPatientId = reader.string();
                break;
              }
              case 2: {
                message.propertyName = reader.string();
                break;
              }
              case 3: {
                message.makePatientAccessible = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CreateDocumentRequest) {
            return object;
          }
          const message = new $root.healthie.v1.CreateDocumentRequest();
          if (object.customerPatientId != null) {
            message.customerPatientId = String(object.customerPatientId);
          }
          if (object.propertyName != null) {
            message.propertyName = String(object.propertyName);
          }
          if (object.makePatientAccessible != null) {
            message.makePatientAccessible = Boolean(
              object.makePatientAccessible
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerPatientId = '';
            object.propertyName = '';
          }
          let keys;
          if (
            message.customerPatientId != null &&
            message.hasOwnProperty('customerPatientId')
          ) {
            object.customerPatientId = message.customerPatientId;
          }
          if (
            message.propertyName != null &&
            message.hasOwnProperty('propertyName')
          ) {
            object.propertyName = message.propertyName;
          }
          if (
            message.makePatientAccessible != null &&
            message.hasOwnProperty('makePatientAccessible')
          ) {
            object.makePatientAccessible = message.makePatientAccessible;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CreateDocumentRequest';
        }
      }

      CreateDocumentRequest.prototype.customerPatientId = '';
      CreateDocumentRequest.prototype.propertyName = '';
      CreateDocumentRequest.prototype.makePatientAccessible = null;

      return CreateDocumentRequest;
    })();

    v1.CreateDocumentResponse = (() => {
      class CreateDocumentResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDocumentResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.documentId != null &&
            Object.hasOwnProperty.call(message, 'documentId')
          ) {
            writer.uint32(10).string(message.documentId);
          }
          if (
            message.document != null &&
            Object.hasOwnProperty.call(message, 'document')
          ) {
            $root.healthie.v1.Document.encode(
              message.document,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDocumentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.documentId = reader.string();
                break;
              }
              case 2: {
                message.document = $root.healthie.v1.Document.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.CreateDocumentResponse) {
            return object;
          }
          const message = new $root.healthie.v1.CreateDocumentResponse();
          if (object.documentId != null) {
            message.documentId = String(object.documentId);
          }
          if (object.document != null) {
            if (typeof object.document !== 'object') {
              throw new TypeError(
                '.healthie.v1.CreateDocumentResponse.document: object expected, but got ' +
                  typeof object.document
              );
            }
            message.document = $root.healthie.v1.Document.fromObject(
              object.document
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.documentId = '';
            object.document = null;
          }
          let keys;
          if (
            message.documentId != null &&
            message.hasOwnProperty('documentId')
          ) {
            object.documentId = message.documentId;
          }
          if (message.document != null && message.hasOwnProperty('document')) {
            object.document = $root.healthie.v1.Document.toObject(
              message.document,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.CreateDocumentResponse';
        }
      }

      CreateDocumentResponse.prototype.documentId = '';
      CreateDocumentResponse.prototype.document = null;

      return CreateDocumentResponse;
    })();

    v1.UpdatePolicyCardImageIdsRequest = (() => {
      class UpdatePolicyCardImageIdsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePolicyCardImageIdsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.policyId != null &&
            Object.hasOwnProperty.call(message, 'policyId')
          ) {
            writer.uint32(10).string(message.policyId);
          }
          if (
            message.insuranceCardFrontDocumentId != null &&
            Object.hasOwnProperty.call(message, 'insuranceCardFrontDocumentId')
          ) {
            writer.uint32(18).string(message.insuranceCardFrontDocumentId);
          }
          if (
            message.insuranceCardBackDocumentId != null &&
            Object.hasOwnProperty.call(message, 'insuranceCardBackDocumentId')
          ) {
            writer.uint32(26).string(message.insuranceCardBackDocumentId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePolicyCardImageIdsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.policyId = reader.string();
                break;
              }
              case 2: {
                message.insuranceCardFrontDocumentId = reader.string();
                break;
              }
              case 3: {
                message.insuranceCardBackDocumentId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.UpdatePolicyCardImageIdsRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.UpdatePolicyCardImageIdsRequest();
          if (object.policyId != null) {
            message.policyId = String(object.policyId);
          }
          if (object.insuranceCardFrontDocumentId != null) {
            message.insuranceCardFrontDocumentId = String(
              object.insuranceCardFrontDocumentId
            );
          }
          if (object.insuranceCardBackDocumentId != null) {
            message.insuranceCardBackDocumentId = String(
              object.insuranceCardBackDocumentId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.policyId = '';
          }
          let keys;
          if (message.policyId != null && message.hasOwnProperty('policyId')) {
            object.policyId = message.policyId;
          }
          if (
            message.insuranceCardFrontDocumentId != null &&
            message.hasOwnProperty('insuranceCardFrontDocumentId')
          ) {
            object.insuranceCardFrontDocumentId =
              message.insuranceCardFrontDocumentId;
          }
          if (
            message.insuranceCardBackDocumentId != null &&
            message.hasOwnProperty('insuranceCardBackDocumentId')
          ) {
            object.insuranceCardBackDocumentId =
              message.insuranceCardBackDocumentId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UpdatePolicyCardImageIdsRequest';
        }
      }

      UpdatePolicyCardImageIdsRequest.prototype.policyId = '';
      UpdatePolicyCardImageIdsRequest.prototype.insuranceCardFrontDocumentId =
        null;
      UpdatePolicyCardImageIdsRequest.prototype.insuranceCardBackDocumentId =
        null;

      return UpdatePolicyCardImageIdsRequest;
    })();

    v1.UpdatePolicyCardImageIdsResponse = (() => {
      class UpdatePolicyCardImageIdsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePolicyCardImageIdsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePolicyCardImageIdsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.UpdatePolicyCardImageIdsResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.UpdatePolicyCardImageIdsResponse();
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.healthie.v1.UpdatePolicyCardImageIdsResponse.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.updatedAt = null;
          }
          let keys;
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UpdatePolicyCardImageIdsResponse';
        }
      }

      UpdatePolicyCardImageIdsResponse.prototype.updatedAt = null;

      return UpdatePolicyCardImageIdsResponse;
    })();

    v1.UpdateRecordIdentifierRequest = (() => {
      class UpdateRecordIdentifierRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRecordIdentifierRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerPatientId != null &&
            Object.hasOwnProperty.call(message, 'customerPatientId')
          ) {
            writer.uint32(10).string(message.customerPatientId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRecordIdentifierRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerPatientId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.UpdateRecordIdentifierRequest
          ) {
            return object;
          }
          const message = new $root.healthie.v1.UpdateRecordIdentifierRequest();
          if (object.customerPatientId != null) {
            message.customerPatientId = String(object.customerPatientId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerPatientId = '';
          }
          let keys;
          if (
            message.customerPatientId != null &&
            message.hasOwnProperty('customerPatientId')
          ) {
            object.customerPatientId = message.customerPatientId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UpdateRecordIdentifierRequest';
        }
      }

      UpdateRecordIdentifierRequest.prototype.customerPatientId = '';

      return UpdateRecordIdentifierRequest;
    })();

    v1.UpdateRecordIdentifierResponse = (() => {
      class UpdateRecordIdentifierResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRecordIdentifierResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.healthiePatientId != null &&
            Object.hasOwnProperty.call(message, 'healthiePatientId')
          ) {
            writer.uint32(10).string(message.healthiePatientId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRecordIdentifierResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.healthiePatientId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.healthie.v1.UpdateRecordIdentifierResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.UpdateRecordIdentifierResponse();
          if (object.healthiePatientId != null) {
            message.healthiePatientId = String(object.healthiePatientId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.healthiePatientId = '';
          }
          let keys;
          if (
            message.healthiePatientId != null &&
            message.hasOwnProperty('healthiePatientId')
          ) {
            object.healthiePatientId = message.healthiePatientId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.UpdateRecordIdentifierResponse';
        }
      }

      UpdateRecordIdentifierResponse.prototype.healthiePatientId = '';

      return UpdateRecordIdentifierResponse;
    })();

    v1.ListOrganizationAppointmentsRequest = (() => {
      class ListOrganizationAppointmentsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationAppointmentsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.environment != null &&
            Object.hasOwnProperty.call(message, 'environment')
          ) {
            writer.uint32(16).int32(message.environment);
          }
          if (
            message.upcomingOnly != null &&
            Object.hasOwnProperty.call(message, 'upcomingOnly')
          ) {
            writer.uint32(24).bool(message.upcomingOnly);
          }
          if (
            message.recurringOnly != null &&
            Object.hasOwnProperty.call(message, 'recurringOnly')
          ) {
            writer.uint32(32).bool(message.recurringOnly);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationAppointmentsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              case 3: {
                message.upcomingOnly = reader.bool();
                break;
              }
              case 4: {
                message.recurringOnly = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.ListOrganizationAppointmentsRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.ListOrganizationAppointmentsRequest();
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.healthie.v1.ListOrganizationAppointmentsRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case 'UNDEFINED_ILLEGAL':
              case 0: {
                message.environment = 0;
                break;
              }
              case 'SANDBOX':
              case 1: {
                message.environment = 1;
                break;
              }
              case 'PRODUCTION':
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == 'number') {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          if (object.upcomingOnly != null) {
            message.upcomingOnly = Boolean(object.upcomingOnly);
          }
          if (object.recurringOnly != null) {
            message.recurringOnly = Boolean(object.recurringOnly);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
            object.environment =
              options.enums === String ? 'UNDEFINED_ILLEGAL' : 0;
            object.upcomingOnly = false;
            object.recurringOnly = false;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          if (
            message.environment != null &&
            message.hasOwnProperty('environment')
          ) {
            object.environment =
              options.enums === String
                ? $root.healthie.v1.HealthieEnvironment[message.environment] ===
                  undefined
                  ? message.environment
                  : $root.healthie.v1.HealthieEnvironment[message.environment]
                : message.environment;
          }
          if (
            message.upcomingOnly != null &&
            message.hasOwnProperty('upcomingOnly')
          ) {
            object.upcomingOnly = message.upcomingOnly;
          }
          if (
            message.recurringOnly != null &&
            message.hasOwnProperty('recurringOnly')
          ) {
            object.recurringOnly = message.recurringOnly;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.ListOrganizationAppointmentsRequest'
          );
        }
      }

      ListOrganizationAppointmentsRequest.prototype.page = null;
      ListOrganizationAppointmentsRequest.prototype.environment = 0;
      ListOrganizationAppointmentsRequest.prototype.upcomingOnly = false;
      ListOrganizationAppointmentsRequest.prototype.recurringOnly = false;

      return ListOrganizationAppointmentsRequest;
    })();

    v1.ListOrganizationAppointmentsResponse = (() => {
      class ListOrganizationAppointmentsResponse {
        constructor(properties) {
          this.appointments = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationAppointmentsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.appointments != null &&
            Object.hasOwnProperty.call(message, 'appointments')
          ) {
            for (const element of message.appointments) {
              $root.healthie.v1.Appointment.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationAppointmentsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.appointments || !message.appointments.length) {
                  message.appointments = [];
                }
                message.appointments.push(
                  $root.healthie.v1.Appointment.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.ListOrganizationAppointmentsResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.ListOrganizationAppointmentsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.appointments) {
            if (!Array.isArray(object.appointments)) {
              throw new TypeError(
                '.healthie.v1.ListOrganizationAppointmentsResponse.appointments: array type expected, but got ' +
                  typeof object.appointments
              );
            }
            message.appointments = new Array(object.appointments.length);
            for (let i = 0; i < object.appointments.length; ++i) {
              if (typeof object.appointments[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.ListOrganizationAppointmentsResponse.appointments: object expected, but got ' +
                    typeof object.appointments[i]
                );
              }
              message.appointments[i] =
                $root.healthie.v1.Appointment.fromObject(
                  object.appointments[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.appointments = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.appointments && message.appointments.length) {
            object.appointments = new Array(message.appointments.length);
            for (let i = 0; i < message.appointments.length; ++i) {
              object.appointments[i] = $root.healthie.v1.Appointment.toObject(
                message.appointments[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.ListOrganizationAppointmentsResponse'
          );
        }
      }

      ListOrganizationAppointmentsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListOrganizationAppointmentsResponse.prototype.appointments =
        $util.emptyArray;

      return ListOrganizationAppointmentsResponse;
    })();

    v1.UpsertUserReferringPhysicianRequest = (() => {
      class UpsertUserReferringPhysicianRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertUserReferringPhysicianRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerPatientId != null &&
            Object.hasOwnProperty.call(message, 'customerPatientId')
          ) {
            writer.uint32(10).string(message.customerPatientId);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(18).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(26).string(message.lastName);
          }
          if (
            message.city != null &&
            Object.hasOwnProperty.call(message, 'city')
          ) {
            writer.uint32(34).string(message.city);
          }
          if (
            message.state != null &&
            Object.hasOwnProperty.call(message, 'state')
          ) {
            writer.uint32(42).string(message.state);
          }
          if (
            message.specialty != null &&
            Object.hasOwnProperty.call(message, 'specialty')
          ) {
            writer.uint32(50).string(message.specialty);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(58).string(message.id);
          }
          if (
            message.businessName != null &&
            Object.hasOwnProperty.call(message, 'businessName')
          ) {
            writer.uint32(66).string(message.businessName);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(74).string(message.phoneNumber);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(82).string(message.email);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertUserReferringPhysicianRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerPatientId = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.city = reader.string();
                break;
              }
              case 5: {
                message.state = reader.string();
                break;
              }
              case 6: {
                message.specialty = reader.string();
                break;
              }
              case 7: {
                message.id = reader.string();
                break;
              }
              case 8: {
                message.businessName = reader.string();
                break;
              }
              case 9: {
                message.phoneNumber = reader.string();
                break;
              }
              case 10: {
                message.email = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.UpsertUserReferringPhysicianRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.UpsertUserReferringPhysicianRequest();
          if (object.customerPatientId != null) {
            message.customerPatientId = String(object.customerPatientId);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.specialty != null) {
            message.specialty = String(object.specialty);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.businessName != null) {
            message.businessName = String(object.businessName);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerPatientId = '';
            object.firstName = '';
            object.lastName = '';
          }
          let keys;
          if (
            message.customerPatientId != null &&
            message.hasOwnProperty('customerPatientId')
          ) {
            object.customerPatientId = message.customerPatientId;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.city != null && message.hasOwnProperty('city')) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty('state')) {
            object.state = message.state;
          }
          if (
            message.specialty != null &&
            message.hasOwnProperty('specialty')
          ) {
            object.specialty = message.specialty;
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.businessName != null &&
            message.hasOwnProperty('businessName')
          ) {
            object.businessName = message.businessName;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.UpsertUserReferringPhysicianRequest'
          );
        }
      }

      UpsertUserReferringPhysicianRequest.prototype.customerPatientId = '';
      UpsertUserReferringPhysicianRequest.prototype.firstName = '';
      UpsertUserReferringPhysicianRequest.prototype.lastName = '';
      UpsertUserReferringPhysicianRequest.prototype.city = null;
      UpsertUserReferringPhysicianRequest.prototype.state = null;
      UpsertUserReferringPhysicianRequest.prototype.specialty = null;
      UpsertUserReferringPhysicianRequest.prototype.id = null;
      UpsertUserReferringPhysicianRequest.prototype.businessName = null;
      UpsertUserReferringPhysicianRequest.prototype.phoneNumber = null;
      UpsertUserReferringPhysicianRequest.prototype.email = null;

      return UpsertUserReferringPhysicianRequest;
    })();

    v1.UpsertUserReferringPhysicianResponse = (() => {
      class UpsertUserReferringPhysicianResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertUserReferringPhysicianResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.physician != null &&
            Object.hasOwnProperty.call(message, 'physician')
          ) {
            $root.healthie.v1.ReferringProvider.encode(
              message.physician,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertUserReferringPhysicianResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.physician = $root.healthie.v1.ReferringProvider.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.UpsertUserReferringPhysicianResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.UpsertUserReferringPhysicianResponse();
          if (object.physician != null) {
            if (typeof object.physician !== 'object') {
              throw new TypeError(
                '.healthie.v1.UpsertUserReferringPhysicianResponse.physician: object expected, but got ' +
                  typeof object.physician
              );
            }
            message.physician = $root.healthie.v1.ReferringProvider.fromObject(
              object.physician
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.physician = null;
          }
          let keys;
          if (
            message.physician != null &&
            message.hasOwnProperty('physician')
          ) {
            object.physician = $root.healthie.v1.ReferringProvider.toObject(
              message.physician,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.UpsertUserReferringPhysicianResponse'
          );
        }
      }

      UpsertUserReferringPhysicianResponse.prototype.physician = null;

      return UpsertUserReferringPhysicianResponse;
    })();

    v1.CreateFormCompletionRequestRequest = (() => {
      class CreateFormCompletionRequestRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateFormCompletionRequestRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerPatientId != null &&
            Object.hasOwnProperty.call(message, 'customerPatientId')
          ) {
            writer.uint32(10).string(message.customerPatientId);
          }
          if (
            message.customModuleFormId != null &&
            Object.hasOwnProperty.call(message, 'customModuleFormId')
          ) {
            writer.uint32(18).string(message.customModuleFormId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateFormCompletionRequestRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerPatientId = reader.string();
                break;
              }
              case 2: {
                message.customModuleFormId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.CreateFormCompletionRequestRequest
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.CreateFormCompletionRequestRequest();
          if (object.customerPatientId != null) {
            message.customerPatientId = String(object.customerPatientId);
          }
          if (object.customModuleFormId != null) {
            message.customModuleFormId = String(object.customModuleFormId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerPatientId = '';
            object.customModuleFormId = '';
          }
          let keys;
          if (
            message.customerPatientId != null &&
            message.hasOwnProperty('customerPatientId')
          ) {
            object.customerPatientId = message.customerPatientId;
          }
          if (
            message.customModuleFormId != null &&
            message.hasOwnProperty('customModuleFormId')
          ) {
            object.customModuleFormId = message.customModuleFormId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.CreateFormCompletionRequestRequest'
          );
        }
      }

      CreateFormCompletionRequestRequest.prototype.customerPatientId = '';
      CreateFormCompletionRequestRequest.prototype.customModuleFormId = '';

      return CreateFormCompletionRequestRequest;
    })();

    v1.CreateFormCompletionRequestResponse = (() => {
      class CreateFormCompletionRequestResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateFormCompletionRequestResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.requestedFormCompletionStatus != null &&
            Object.hasOwnProperty.call(message, 'requestedFormCompletionStatus')
          ) {
            writer.uint32(10).string(message.requestedFormCompletionStatus);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateFormCompletionRequestResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.requestedFormCompletionStatus = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.healthie.v1.CreateFormCompletionRequestResponse
          ) {
            return object;
          }
          const message =
            new $root.healthie.v1.CreateFormCompletionRequestResponse();
          if (object.requestedFormCompletionStatus != null) {
            message.requestedFormCompletionStatus = String(
              object.requestedFormCompletionStatus
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.requestedFormCompletionStatus = '';
          }
          let keys;
          if (
            message.requestedFormCompletionStatus != null &&
            message.hasOwnProperty('requestedFormCompletionStatus')
          ) {
            object.requestedFormCompletionStatus =
              message.requestedFormCompletionStatus;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'healthie.v1.CreateFormCompletionRequestResponse'
          );
        }
      }

      CreateFormCompletionRequestResponse.prototype.requestedFormCompletionStatus =
        '';

      return CreateFormCompletionRequestResponse;
    })();

    v1.GetSentWebhooksRequest = (() => {
      class GetSentWebhooksRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSentWebhooksRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.offset != null &&
            Object.hasOwnProperty.call(message, 'offset')
          ) {
            writer.uint32(8).int32(message.offset);
          }
          if (
            message.order != null &&
            Object.hasOwnProperty.call(message, 'order')
          ) {
            writer.uint32(16).int32(message.order);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSentWebhooksRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.offset = reader.int32();
                break;
              }
              case 2: {
                message.order = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetSentWebhooksRequest) {
            return object;
          }
          const message = new $root.healthie.v1.GetSentWebhooksRequest();
          if (object.offset != null) {
            message.offset = object.offset | 0;
          }
          if (object.order != null) {
            switch (object.order) {
              case 'ORDER_UNSPECIFIED':
              case 0: {
                message.order = 0;
                break;
              }
              case 'ORDER_CREATED_AT_ASC':
              case 1: {
                message.order = 1;
                break;
              }
              case 'ORDER_CREATED_AT_DESC':
              case 2: {
                message.order = 2;
                break;
              }
              case 'ORDER_EVENT_TYPE_ASC':
              case 3: {
                message.order = 3;
                break;
              }
              case 'ORDER_EVENT_TYPE_DESC':
              case 4: {
                message.order = 4;
                break;
              }
              case 'ORDER_URL_ASC':
              case 5: {
                message.order = 5;
                break;
              }
              case 'ORDER_URL_DESC':
              case 6: {
                message.order = 6;
                break;
              }
              default: {
                if (typeof object.order == 'number') {
                  message.order = object.order;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.offset = 0;
            object.order = options.enums === String ? 'ORDER_UNSPECIFIED' : 0;
          }
          let keys;
          if (message.offset != null && message.hasOwnProperty('offset')) {
            object.offset = message.offset;
          }
          if (message.order != null && message.hasOwnProperty('order')) {
            object.order =
              options.enums === String
                ? $root.healthie.v1.GetSentWebhooksRequest.Order[
                    message.order
                  ] === undefined
                  ? message.order
                  : $root.healthie.v1.GetSentWebhooksRequest.Order[
                      message.order
                    ]
                : message.order;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetSentWebhooksRequest';
        }
      }

      GetSentWebhooksRequest.prototype.offset = 0;
      GetSentWebhooksRequest.prototype.order = 0;
      GetSentWebhooksRequest.Order = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'ORDER_UNSPECIFIED')] = 0;
        values[(valuesById[1] = 'ORDER_CREATED_AT_ASC')] = 1;
        values[(valuesById[2] = 'ORDER_CREATED_AT_DESC')] = 2;
        values[(valuesById[3] = 'ORDER_EVENT_TYPE_ASC')] = 3;
        values[(valuesById[4] = 'ORDER_EVENT_TYPE_DESC')] = 4;
        values[(valuesById[5] = 'ORDER_URL_ASC')] = 5;
        values[(valuesById[6] = 'ORDER_URL_DESC')] = 6;
        return values;
      })();

      return GetSentWebhooksRequest;
    })();

    v1.GetSentWebhooksResponse = (() => {
      class GetSentWebhooksResponse {
        constructor(properties) {
          this.webhooks = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSentWebhooksResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhooks != null &&
            Object.hasOwnProperty.call(message, 'webhooks')
          ) {
            for (const element of message.webhooks) {
              $root.healthie.v1.GetSentWebhooksResponse.WebhookSent.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSentWebhooksResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.webhooks || !message.webhooks.length) {
                  message.webhooks = [];
                }
                message.webhooks.push(
                  $root.healthie.v1.GetSentWebhooksResponse.WebhookSent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.healthie.v1.GetSentWebhooksResponse) {
            return object;
          }
          const message = new $root.healthie.v1.GetSentWebhooksResponse();
          if (object.webhooks) {
            if (!Array.isArray(object.webhooks)) {
              throw new TypeError(
                '.healthie.v1.GetSentWebhooksResponse.webhooks: array type expected, but got ' +
                  typeof object.webhooks
              );
            }
            message.webhooks = new Array(object.webhooks.length);
            for (let i = 0; i < object.webhooks.length; ++i) {
              if (typeof object.webhooks[i] !== 'object') {
                throw new TypeError(
                  '.healthie.v1.GetSentWebhooksResponse.webhooks: object expected, but got ' +
                    typeof object.webhooks[i]
                );
              }
              message.webhooks[i] =
                $root.healthie.v1.GetSentWebhooksResponse.WebhookSent.fromObject(
                  object.webhooks[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.webhooks = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.webhooks && message.webhooks.length) {
            object.webhooks = new Array(message.webhooks.length);
            for (let i = 0; i < message.webhooks.length; ++i) {
              object.webhooks[i] =
                $root.healthie.v1.GetSentWebhooksResponse.WebhookSent.toObject(
                  message.webhooks[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'healthie.v1.GetSentWebhooksResponse';
        }
      }

      GetSentWebhooksResponse.prototype.webhooks = $util.emptyArray;
      GetSentWebhooksResponse.WebhookSent = (() => {
        class WebhookSent {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WebhookSent(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.createdAt != null &&
              Object.hasOwnProperty.call(message, 'createdAt')
            ) {
              timing$1.v1.Timestamp.encode(
                message.createdAt,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.eventType != null &&
              Object.hasOwnProperty.call(message, 'eventType')
            ) {
              writer.uint32(18).string(message.eventType);
            }
            if (
              message.httpResponseStatus != null &&
              Object.hasOwnProperty.call(message, 'httpResponseStatus')
            ) {
              writer.uint32(24).int32(message.httpResponseStatus);
            }
            if (
              message.url != null &&
              Object.hasOwnProperty.call(message, 'url')
            ) {
              writer.uint32(34).string(message.url);
            }
            if (
              message.resourceId != null &&
              Object.hasOwnProperty.call(message, 'resourceId')
            ) {
              writer.uint32(42).string(message.resourceId);
            }
            if (
              message.resourceIdType != null &&
              Object.hasOwnProperty.call(message, 'resourceIdType')
            ) {
              writer.uint32(50).string(message.resourceIdType);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WebhookSent();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.createdAt = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.eventType = reader.string();
                  break;
                }
                case 3: {
                  message.httpResponseStatus = reader.int32();
                  break;
                }
                case 4: {
                  message.url = reader.string();
                  break;
                }
                case 5: {
                  message.resourceId = reader.string();
                  break;
                }
                case 6: {
                  message.resourceIdType = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.healthie.v1.GetSentWebhooksResponse.WebhookSent
            ) {
              return object;
            }
            const message =
              new $root.healthie.v1.GetSentWebhooksResponse.WebhookSent();
            if (object.createdAt != null) {
              if (typeof object.createdAt !== 'object') {
                throw new TypeError(
                  '.healthie.v1.GetSentWebhooksResponse.WebhookSent.createdAt: object expected, but got ' +
                    typeof object.createdAt
                );
              }
              message.createdAt = timing$1.v1.Timestamp.fromObject(
                object.createdAt
              );
            }
            if (object.eventType != null) {
              message.eventType = String(object.eventType);
            }
            if (object.httpResponseStatus != null) {
              message.httpResponseStatus = object.httpResponseStatus | 0;
            }
            if (object.url != null) {
              message.url = String(object.url);
            }
            if (object.resourceId != null) {
              message.resourceId = String(object.resourceId);
            }
            if (object.resourceIdType != null) {
              message.resourceIdType = String(object.resourceIdType);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.createdAt = null;
              object.eventType = '';
              object.httpResponseStatus = 0;
              object.url = '';
              object.resourceIdType = '';
            }
            let keys;
            if (
              message.createdAt != null &&
              message.hasOwnProperty('createdAt')
            ) {
              object.createdAt = timing$1.v1.Timestamp.toObject(
                message.createdAt,
                options
              );
            }
            if (
              message.eventType != null &&
              message.hasOwnProperty('eventType')
            ) {
              object.eventType = message.eventType;
            }
            if (
              message.httpResponseStatus != null &&
              message.hasOwnProperty('httpResponseStatus')
            ) {
              object.httpResponseStatus = message.httpResponseStatus;
            }
            if (message.url != null && message.hasOwnProperty('url')) {
              object.url = message.url;
            }
            if (
              message.resourceId != null &&
              message.hasOwnProperty('resourceId')
            ) {
              object.resourceId = message.resourceId;
            }
            if (
              message.resourceIdType != null &&
              message.hasOwnProperty('resourceIdType')
            ) {
              object.resourceIdType = message.resourceIdType;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'healthie.v1.GetSentWebhooksResponse.WebhookSent'
            );
          }
        }

        WebhookSent.prototype.createdAt = null;
        WebhookSent.prototype.eventType = '';
        WebhookSent.prototype.httpResponseStatus = 0;
        WebhookSent.prototype.url = '';
        WebhookSent.prototype.resourceId = null;
        WebhookSent.prototype.resourceIdType = '';

        return WebhookSent;
      })();

      return GetSentWebhooksResponse;
    })();

    v1.HealthieEnvironment = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNDEFINED_ILLEGAL')] = 0;
      values[(valuesById[1] = 'SANDBOX')] = 1;
      values[(valuesById[2] = 'PRODUCTION')] = 2;
      return values;
    })();

    v1.HealthieEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'HEALTHIE_APPOINTMENT_SCHEDULED')] = 1;
      values[(valuesById[2] = 'HEALTHIE_APPOINTMENT_UPDATED')] = 2;
      values[(valuesById[3] = 'HEALTHIE_APPOINTMENT_DELETED')] = 3;
      values[(valuesById[4] = 'HEALTHIE_BILLING_ITEM_CREATED')] = 4;
      values[(valuesById[5] = 'HEALTHIE_BILLING_ITEM_UPDATED')] = 5;
      values[(valuesById[6] = 'HEALTHIE_CMS1500_CREATED')] = 6;
      values[(valuesById[7] = 'HEALTHIE_CMS1500_UPDATED')] = 7;
      values[(valuesById[8] = 'HEALTHIE_CMS1500_DELETED')] = 8;
      values[(valuesById[9] = 'HEALTHIE_COMMENT_CREATED')] = 9;
      values[(valuesById[10] = 'HEALTHIE_COMMENT_UPDATED')] = 10;
      values[(valuesById[11] = 'HEALTHIE_COMMENT_DELETED')] = 11;
      values[(valuesById[12] = 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED')] = 12;
      values[(valuesById[13] = 'HEALTHIE_DOCUMENT_CREATED')] = 13;
      values[(valuesById[14] = 'HEALTHIE_DOCUMENT_UPDATED')] = 14;
      values[(valuesById[15] = 'HEALTHIE_DOCUMENT_DELETED')] = 15;
      values[(valuesById[16] = 'HEALTHIE_ENTRY_CREATED')] = 16;
      values[(valuesById[17] = 'HEALTHIE_ENTRY_UPDATED')] = 17;
      values[(valuesById[18] = 'HEALTHIE_ENTRY_DELETED')] = 18;
      values[(valuesById[19] = 'HEALTHIE_FORM_ANSWER_GROUP_CREATED')] = 19;
      values[(valuesById[20] = 'HEALTHIE_FORM_ANSWER_GROUP_DELETED')] = 20;
      values[(valuesById[21] = 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED')] = 21;
      values[(valuesById[22] = 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED')] = 22;
      values[(valuesById[23] = 'HEALTHIE_GOAL_CREATED')] = 23;
      values[(valuesById[24] = 'HEALTHIE_GOAL_UPDATED')] = 24;
      values[(valuesById[25] = 'HEALTHIE_GOAL_DELETED')] = 25;
      values[(valuesById[26] = 'HEALTHIE_GOAL_HISTORY_CREATED')] = 26;
      values[
        (valuesById[27] = 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED')
      ] = 27;
      values[
        (valuesById[28] = 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED')
      ] = 28;
      values[
        (valuesById[29] = 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED')
      ] = 29;
      values[(valuesById[30] = 'HEALTHIE_MESSAGE_CREATED')] = 30;
      values[(valuesById[31] = 'HEALTHIE_MESSAGE_DELETED')] = 31;
      values[(valuesById[32] = 'HEALTHIE_METRIC_ENTRY_CREATED')] = 32;
      values[(valuesById[33] = 'HEALTHIE_METRIC_ENTRY_UPDATED')] = 33;
      values[(valuesById[34] = 'HEALTHIE_METRIC_ENTRY_DELETED')] = 34;
      values[(valuesById[35] = 'HEALTHIE_PATIENT_CREATED')] = 35;
      values[(valuesById[36] = 'HEALTHIE_PATIENT_UPDATED')] = 36;
      values[(valuesById[37] = 'HEALTHIE_POLICY_CREATED')] = 37;
      values[(valuesById[38] = 'HEALTHIE_POLICY_UPDATED')] = 38;
      values[(valuesById[39] = 'HEALTHIE_POLICY_DELETED')] = 39;
      values[
        (valuesById[40] = 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED')
      ] = 40;
      values[
        (valuesById[41] = 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED')
      ] = 41;
      values[
        (valuesById[42] = 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED')
      ] = 42;
      values[(valuesById[43] = 'HEALTHIE_TASK_CREATED')] = 43;
      values[(valuesById[44] = 'HEALTHIE_TASK_UPDATED')] = 44;
      values[(valuesById[45] = 'HEALTHIE_TASK_DELETED')] = 45;
      values[(valuesById[46] = 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED')] = 46;
      values[(valuesById[47] = 'HEALTHIE_APPOINTMENT_NO_SHOW')] = 47;
      values[(valuesById[48] = 'HEALTHIE_APPOINTMENT_RESCHEDULED')] = 48;
      values[(valuesById[49] = 'HEALTHIE_APPOINTMENT_CANCELLED')] = 49;
      values[(valuesById[50] = 'HEALTHIE_APPLIED_TAG_CREATED')] = 50;
      values[(valuesById[51] = 'HEALTHIE_APPLIED_TAG_DELETED')] = 51;
      return values;
    })();

    v1.HealthieService = (() => {
      class HealthieService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new HealthieService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (HealthieService.prototype.listCustomModuleForms =
          function listCustomModuleForms(request, callback) {
            return this.rpcCall(
              listCustomModuleForms,
              $root.healthie.v1.ListCustomModuleFormsRequest,
              $root.healthie.v1.ListCustomModuleFormsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListCustomModuleForms' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getCustomModuleForm =
          function getCustomModuleForm(request, callback) {
            return this.rpcCall(
              getCustomModuleForm,
              $root.healthie.v1.GetCustomModuleFormRequest,
              $root.healthie.v1.GetCustomModuleFormResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetCustomModuleForm' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getFormAnswerGroup =
          function getFormAnswerGroup(request, callback) {
            return this.rpcCall(
              getFormAnswerGroup,
              $root.healthie.v1.GetFormAnswerGroupRequest,
              $root.healthie.v1.GetFormAnswerGroupResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetFormAnswerGroup' }
      );

      Object.defineProperty(
        (HealthieService.prototype.listAppointmentTypes =
          function listAppointmentTypes(request, callback) {
            return this.rpcCall(
              listAppointmentTypes,
              $root.healthie.v1.ListAppointmentTypesRequest,
              $root.healthie.v1.ListAppointmentTypesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListAppointmentTypes' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getAppointmentType =
          function getAppointmentType(request, callback) {
            return this.rpcCall(
              getAppointmentType,
              $root.healthie.v1.GetAppointmentTypeRequest,
              $root.healthie.v1.GetAppointmentTypeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetAppointmentType' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getAppointment = function getAppointment(
          request,
          callback
        ) {
          return this.rpcCall(
            getAppointment,
            $root.healthie.v1.GetAppointmentRequest,
            $root.healthie.v1.GetAppointmentResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetAppointment' }
      );

      Object.defineProperty(
        (HealthieService.prototype.listOrganizationAppointments =
          function listOrganizationAppointments(request, callback) {
            return this.rpcCall(
              listOrganizationAppointments,
              $root.healthie.v1.ListOrganizationAppointmentsRequest,
              $root.healthie.v1.ListOrganizationAppointmentsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListOrganizationAppointments' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getUser = function getUser(
          request,
          callback
        ) {
          return this.rpcCall(
            getUser,
            $root.healthie.v1.GetUserRequest,
            $root.healthie.v1.GetUserResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetUser' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getCMS1500 = function getCMS1500(
          request,
          callback
        ) {
          return this.rpcCall(
            getCMS1500,
            $root.healthie.v1.GetCMS1500Request,
            $root.healthie.v1.GetCMS1500Response,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetCMS1500' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getInsuranceAuthorization =
          function getInsuranceAuthorization(request, callback) {
            return this.rpcCall(
              getInsuranceAuthorization,
              $root.healthie.v1.GetInsuranceAuthorizationRequest,
              $root.healthie.v1.GetInsuranceAuthorizationResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetInsuranceAuthorization' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getRequestedFormCompletion =
          function getRequestedFormCompletion(request, callback) {
            return this.rpcCall(
              getRequestedFormCompletion,
              $root.healthie.v1.GetRequestedFormCompletionRequest,
              $root.healthie.v1.GetRequestedFormCompletionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetRequestedFormCompletion' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getBillingItem = function getBillingItem(
          request,
          callback
        ) {
          return this.rpcCall(
            getBillingItem,
            $root.healthie.v1.GetBillingItemRequest,
            $root.healthie.v1.GetBillingItemResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetBillingItem' }
      );

      Object.defineProperty(
        (HealthieService.prototype.getPolicy = function getPolicy(
          request,
          callback
        ) {
          return this.rpcCall(
            getPolicy,
            $root.healthie.v1.GetPolicyRequest,
            $root.healthie.v1.GetPolicyResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetPolicy' }
      );

      Object.defineProperty(
        (HealthieService.prototype.listUserFormAnswerGroups =
          function listUserFormAnswerGroups(request, callback) {
            return this.rpcCall(
              listUserFormAnswerGroups,
              $root.healthie.v1.ListUserFormAnswerGroupsRequest,
              $root.healthie.v1.ListUserFormAnswerGroupsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListUserFormAnswerGroups' }
      );

      Object.defineProperty(
        (HealthieService.prototype.listPatients = function listPatients(
          request,
          callback
        ) {
          return this.rpcCall(
            listPatients,
            $root.healthie.v1.ListPatientsRequest,
            $root.healthie.v1.ListPatientsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListPatients' }
      );

      Object.defineProperty(
        (HealthieService.prototype.streamPatientIDs = function streamPatientIDs(
          request,
          callback
        ) {
          return this.rpcCall(
            streamPatientIDs,
            $root.healthie.v1.StreamPatientIDsRequest,
            $root.healthie.v1.StreamPatientIDsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'StreamPatientIDs' }
      );

      Object.defineProperty(
        (HealthieService.prototype.listUserGroups = function listUserGroups(
          request,
          callback
        ) {
          return this.rpcCall(
            listUserGroups,
            $root.healthie.v1.ListUserGroupsRequest,
            $root.healthie.v1.ListUserGroupsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListUserGroups' }
      );

      return HealthieService;
    })();

    return v1;
  })();

  return healthie;
})();

export const healthie = $root.healthie;
