import { FC, memo } from 'react';
import { BadgeProps } from '../Badge/types';
import { Badge } from '../Badge';
import { WorkflowStatusProps } from './types';

const WorkflowStatus: FC<WorkflowStatusProps> = ({ status }) => {
  const statusMap: Record<
    WorkflowStatusProps['status'],
    { label: string; type: BadgeProps['type'] }
  > = {
    active: {
      label: 'Active',
      type: 'success',
    },
    draft: {
      label: 'Draft',
      type: 'live',
    },
    inactive: {
      label: 'Inactive',
      type: 'inactive',
    },
  };

  return (
    <Badge label={statusMap[status].label} type={statusMap[status].type} />
  );
};

export const MemoizedWorkflowStatus = memo(WorkflowStatus);
