import { FC, memo, useEffect } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { RemoveNode } from '../../../../RemoveNode';
import { RestartNodeProps } from '../types';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const RestartConfigure: FC<RestartNodeProps> = ({ id, data }) => {
  const {
    name,
    description,
    configuration,
    onAddNode,
    onRemoveNode,
    isReadOnly,
    isRequired,
  } = data.value;

  useEffect(() => {
    handleNodeSave();
  }, [isRequired]);

  const handleNodeSave = () => {
    if (isRequired) {
      onAddNode &&
        onAddNode({
          node: new workflows.v1.CreateWorkflowNodeRequest({
            id: id,
            name: name,
            description: description,
            restart: configuration,
          }),
        });
    }
  };

  const handleNodeRemove = () => {
    onRemoveNode &&
      onRemoveNode({
        nodeId: id,
      });
  };

  return (
    <Flexbox
      data-testid='restart-configure'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
    >
      {!isReadOnly && (
        <RemoveNode type='restart' onRemoveNode={handleNodeRemove} />
      )}
    </Flexbox>
  );
};

export const MemoizedRestartConfigure = memo(RestartConfigure);
