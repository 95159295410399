import { useTheme } from '@morf/theming';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { FC, Ref, forwardRef, useState } from 'react';
import { Button } from '../../../Button';
import { StepStatus } from '../../StepStatus/StepStatus';
import Link from 'next/link';
import { Icon } from '../../../Icon';
import { StepProps } from './types';
import { Input } from '../../../Input';
import { useResize } from '../../../Hooks/useResize';
import { Container } from '../../../Container';
import { CopyToClipboard } from '../../../CopyToClipboard';

export const Step: FC<StepProps> = forwardRef(
  ({ currentStepIndex, currentStep }, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState('');

    const responsiveStep = useResize({
      direction: {
        xxl: 'row',
        xl: 'row',
        lg: 'row',
        md: 'row',
        sm: 'row',
        xs: 'column',
      },
      position: {
        xxl: 'sticky',
        xl: 'sticky',
        lg: 'sticky',
        md: 'sticky',
        sm: 'sticky',
        xs: 'relative',
      },
      width: {
        xxl: '50%',
        xl: '50%',
        lg: '50%',
        md: '50%',
        sm: '50%',
        xs: '100%',
      },
      gap: {
        xxl: 12,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 0,
      },
      padding: {
        xxl: 4,
        xl: 4,
        lg: 4,
        md: 4,
        sm: 4,
        xs: 2,
      },
      copyTooltip: {
        xxl: 'right',
        xl: 'right',
        lg: 'right',
        md: 'right',
        sm: 'right',
        xs: 'left',
      },
    });
    return (
      <Flexbox
        alignItems='flex-start'
        height='auto'
        direction={responsiveStep.direction}
        px={2}
        gap={responsiveStep.gap}
        style={{ overflowY: 'scroll' }}
        containerRef={ref}
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          width={responsiveStep.width}
          position={responsiveStep.position}
          top={'0'}
          gap={2}
          py={responsiveStep.padding}
        >
          <Flexbox direction='column' justifyContent='flex-start' gap={0.75}>
            <Text tag='h3' color={theme.colors.text.muted}>
              Step {currentStepIndex + 1}
            </Text>
            <Text tag='h1'>{currentStep.title}</Text>
            <Text tag='subtitle'>{currentStep.description}</Text>
          </Flexbox>
          {currentStep.status && (
            <StepStatus
              name={currentStep.status.name}
              imageUrl={currentStep.status?.imageUrl}
              type={currentStep.status.type}
              subStatus={currentStep.status?.subStatus}
            />
          )}
        </Flexbox>

        <Flexbox direction='column' py={responsiveStep.padding} gap={2}>
          <Text tag='h3' color={theme.colors.text.muted}>
            Instructions
          </Text>
          {currentStep.instructions &&
            currentStep.instructions.map((instruction, index) => {
              const { title, description, type } = instruction;
              return (
                <Flexbox key={index} direction='column' gap={0.75}>
                  <Flexbox justifyContent='flex-start' height='auto'>
                    <Container width='auto'>
                      <Text tag='h4'>
                        {index + 1}
                        {')'}
                      </Text>
                    </Container>
                    <Text tag='p1'>{title}</Text>
                  </Flexbox>

                  {description && (
                    <Text tag='p2'>{instruction.description}</Text>
                  )}
                  {type === 'button' && (
                    <Link href={instruction.buttonLink} target='_blank'>
                      <Button>
                        <Flexbox direction='row' alignItems='center'>
                          <Text tag='p2' color='inherit'>
                            {instruction.buttonLabel}
                          </Text>
                          <Icon size={1.25} name='link-out' />
                        </Flexbox>
                      </Button>
                    </Link>
                  )}
                  {type === 'image' && (
                    <img
                      src={instruction.imageUrl}
                      alt={instruction.title}
                      width={instruction.imageWidth}
                      height={instruction.imageHeight}
                    />
                  )}
                  {type === 'input' && (
                    <Flexbox
                      justifyContent='flex-start'
                      alignItems='center'
                      maxWidth='20rem'
                    >
                      <Input
                        placeholder={instruction.inputPlaceholder}
                        value={instruction.inputValue}
                        type={instruction.inputType}
                        id={instruction.inputName}
                        required={instruction.inputRequired}
                        onChange={(e) => setInputValue(e.target.value)}
                        readOnly={instruction.inputReadOnly}
                        clearable
                      />
                      {instruction.inputButton === 'Copy' ? (
                        <CopyToClipboard
                          text={instruction.inputValue || ''}
                          tooltipDirection={responsiveStep.copyTooltip}
                        />
                      ) : (
                        <Button
                          variant='primary'
                          onClick={() =>
                            instruction.inputOnClick &&
                            instruction.inputOnClick(inputValue)
                          }
                        >
                          <Text color='inherit'>{instruction.inputButton}</Text>
                        </Button>
                      )}
                    </Flexbox>
                  )}
                </Flexbox>
              );
            })}
        </Flexbox>
      </Flexbox>
    );
  }
);
