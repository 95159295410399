export const Refresh = () => {
  return (
    <path
      d='M16.5228 9.34841H21.5154V9.34663M3.48413 19.6444V14.6517M3.48413 14.6517L8.47677 14.6517M3.48413 14.6517L6.66502 17.8347C7.65555 18.8271 8.91261 19.58 10.3644 19.969C14.7654 21.1483 19.2892 18.5364 20.4685 14.1353M4.53073 9.86484C5.71 5.46374 10.2338 2.85194 14.6349 4.03121C16.0866 4.4202 17.3437 5.17312 18.3342 6.1655L21.5154 9.34663M21.5154 4.3558V9.34663'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const RefreshChevronUp = () => {
  return (
    <>
      <path
        d='M13.3523 7.7905H17.5128V7.78902M2.48682 16.3705V12.21M2.48682 12.21L6.64735 12.2099M2.48682 12.21L5.13756 14.8624C5.963 15.6894 7.01055 16.3168 8.22034 16.641C11.8879 17.6237 15.6577 15.4472 16.6405 11.7796M3.35898 8.22086C4.3417 4.55328 8.11152 2.37678 11.7791 3.3595C12.9889 3.68367 14.0365 4.3111 14.8619 5.13808L17.5128 7.78902M17.5128 3.63V7.78902'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.5 11.25L28 8.75L30.5 11.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const RefreshChevronDown = () => {
  return (
    <>
      <path
        d='M13.3523 7.7905H17.5128V7.78902M2.48682 16.3705V12.21M2.48682 12.21L6.64735 12.2099M2.48682 12.21L5.13756 14.8624C5.963 15.6894 7.01055 16.3168 8.22034 16.641C11.8879 17.6237 15.6577 15.4472 16.6405 11.7796M3.35898 8.22086C4.3417 4.55328 8.11152 2.37678 11.7791 3.3595C12.9889 3.68367 14.0365 4.3111 14.8619 5.13808L17.5128 7.78902M17.5128 3.63V7.78902'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.5 8.75L28 11.25L25.5 8.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const RefreshCircle = () => {
  return (
    <>
      <circle cx='10' cy='10' r='9.25' />
      <path
        d='M12.0115 8.6742H14.5078V8.67332M5.49219 13.8222V11.3259M5.49219 11.3259L7.98851 11.3259M5.49219 11.3259L7.08263 12.9174C7.5779 13.4135 8.20642 13.79 8.9323 13.9845C11.1328 14.5741 13.3947 13.2682 13.9844 11.0677M6.01548 8.93242C6.60512 6.73187 8.86701 5.42597 11.0676 6.0156C11.7934 6.2101 12.422 6.58656 12.9172 7.08275L14.5078 8.67332M14.5078 6.1779V8.67332'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
