export const Patient = () => {
  return (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4848 15.3462C8.61719 15.3462 5.31433 15.931 5.31433 18.2729C5.31433 20.6148 8.59624 21.2205 12.4848 21.2205C16.3524 21.2205 19.6543 20.6348 19.6543 18.2938C19.6543 15.9529 16.3734 15.3462 12.4848 15.3462Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4848 12.0059C15.0229 12.0059 17.08 9.94779 17.08 7.40969C17.08 4.8716 15.0229 2.81445 12.4848 2.81445C9.94667 2.81445 7.88858 4.8716 7.88858 7.40969C7.88001 9.93922 9.92382 11.9973 12.4524 12.0059H12.4848Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PatientAdd = () => {
  return (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4848 15.3462C8.61719 15.3462 5.31433 15.931 5.31433 18.2729C5.31433 20.6148 8.59624 21.2205 12.4848 21.2205C16.3524 21.2205 19.6543 20.6348 19.6543 18.2938C19.6543 15.9529 16.3734 15.3462 12.4848 15.3462Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4848 12.0059C15.0229 12.0059 17.08 9.94779 17.08 7.40969C17.08 4.8716 15.0229 2.81445 12.4848 2.81445C9.94667 2.81445 7.88858 4.8716 7.88858 7.40969C7.88001 9.93922 9.92382 11.9973 12.4524 12.0059H12.4848Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PatientArrow = () => {
  return (
    <>
      <>
        <g clipPath='url(#clip0_854_306)'>
          <path
            d='M18.4815 18.7248C17.1121 16.9175 14.9424 15.75 12.5 15.75C10.0576 15.75 7.88789 16.9175 6.51846 18.7248M18.4815 18.7248C16.8915 20.1401 14.7962 21 12.5 21C10.2038 21 8.10851 20.1401 6.51846 18.7248M18.4815 18.7248C18.7373 18.4971 18.98 18.2551 19.2083 18M6.51846 18.7248C4.6665 17.0763 3.5 14.6744 3.5 12C3.5 7.02944 7.52944 3 12.5 3C15.6204 3 18.3699 4.58803 19.9845 7M15.5 9.75C15.5 11.4069 14.1569 12.75 12.5 12.75C10.8431 12.75 9.5 11.4069 9.5 9.75C9.5 8.09315 10.8431 6.75 12.5 6.75C14.1569 6.75 15.5 8.09315 15.5 9.75Z'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M23.5 12.25H18M23.5 12.25L21.25 14.5M23.5 12.25L21.25 10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_854_306'>
            <rect
              width='24'
              height='24'
              fill='white'
              transform='translate(0.5)'
            />
          </clipPath>
        </defs>
      </>
    </>
  );
};

export const PatientCircle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
    />
  );
};

export const PatientLookup = () => {
  return (
    <>
      <path d='M20.9812 10.4812L22.5 12M21.75 8.625C21.75 10.0747 20.5747 11.25 19.125 11.25C17.6753 11.25 16.5 10.0747 16.5 8.625C16.5 7.17525 17.6753 6 19.125 6C20.5747 6 21.75 7.17525 21.75 8.625Z' />
      <path d='M15.9815 16.7248C14.6121 14.9175 12.4424 13.75 10 13.75C7.55761 13.75 5.38789 14.9175 4.01846 16.7248M15.9815 16.7248C14.3915 18.1401 12.2962 19 10 19C7.70384 19 5.60851 18.1401 4.01846 16.7248M15.9815 16.7248C16.8033 15.9933 17.4902 15.1134 18 14.1272M4.01846 16.7248C2.1665 15.0763 1 12.6744 1 10C1 5.02944 5.02944 1 10 1C12.4754 1 14.7173 1.99933 16.3444 3.61657M13 7.75C13 9.40685 11.6569 10.75 10 10.75C8.34315 10.75 7 9.40685 7 7.75C7 6.09315 8.34315 4.75 10 4.75C11.6569 4.75 13 6.09315 13 7.75Z' />
    </>
  );
};
