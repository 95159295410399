import { css, styled } from '@morf/theming';
import { VersionTimelineRowStatusProps } from './types';

const typeStyles = {
  active: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.green.darkest};
  `
  ),
  draft: css(
    ({ theme }) => `
    background-color: ${theme.colors.main.primary.darker};
  `
  ),
  inactive: css(
    ({ theme }) => `
    background-color: ${theme.colors.text.muted};
  `
  ),
};

export const StyledVersionTimelineRowStatus = styled.div<{
  type: VersionTimelineRowStatusProps['type'];
}>`
  ${(props) => typeStyles[props.type]};
  width: 0.125rem;
  height: 2.125rem;
  border-radius: ${(props) => props.theme.borderRadius[0.25]};
`;
