import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const extractAction = (
  action:
    | workflows.v1.WorkflowDestinationAction
    | workflows.v1.WorkflowFetchAction
    | null
    | undefined
) => {
  const applicationType = action?.type;
  const applicationValue = applicationType
    ? //@ts-ignore
      action[applicationType]
    : undefined;

  const actionType = applicationValue?.type;
  const actionValue = actionType
    ? applicationValue[actionType]
    : { params: [], configs: [] };

  return {
    applicationType,
    applicationValue,
    actionType,
    actionValue,
  };
};
