import { phonenumbers } from '@morf/proto/phonenumbers_ts_proto';

export const formatPhoneNumber = (
  phoneNumber: phonenumbers.PhoneNumber | null | undefined
): string => {
  if (
    !phoneNumber ||
    (!phoneNumber.countryCode && !phoneNumber.nationalNumber)
  ) {
    return 'n/a';
  }

  return ['+', phoneNumber.countryCode, phoneNumber.nationalNumber]
    .filter(Boolean)
    .join('');
};
