import _ from 'lodash';
import { Container } from '../../../Container';
import { EventStatusProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { useTheme } from '@morf/theming';

const EventStatus: FC<EventStatusProps> = ({ variant }) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='event-status'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      width='auto'
      gap={0.25}
    >
      <Container height='auto' width='auto'>
        <Icon
          name='webhook'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.green.darkest}
        />
      </Container>

      {variant === 'icon-description' && <Text tag='p3'>Webhook received</Text>}
    </Flexbox>
  );
};

export const MemoizedEventStatus = memo(EventStatus);
