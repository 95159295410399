import { ThemeContainerProps, css, styled } from '@morf/theming';
import { ButtonProps } from './types';

const Primary = ({
  isActive,
  isDisabled,
  theme,
}: {
  isActive: ButtonProps['isActive'];
  isDisabled: ButtonProps['isDisabled'];
  theme: ThemeContainerProps;
}) => css`
  color: ${theme.colors.text.inverse};
  background-color: ${theme.colors.main.primary.darker};
  border: ${theme.borderWidth[0.0625]} solid ${theme.colors.main.primary.darker};

  ${!isDisabled &&
  `
      :hover {
        background-color: ${theme.colors.main.primary.lighter};
        border-color: ${theme.colors.main.primary.lighter};
        color:  ${theme.colors.text.inverse};
      }
    `}

  ${isDisabled &&
  `
      background-color: ${theme.colors.ui.divider};
      border-color: ${theme.colors.ui.divider};
      color: ${theme.colors.text.muted};
      cursor: not-allowed;
    `}

  ${!isActive &&
  !isDisabled &&
  `
  :not(:active):focus {
    background-color: ${theme.colors.main.primary.darker};
    border-color: ${theme.colors.main.primary.darker};
    outline: ${theme.colors.main.light.normal} solid ${theme.borderWidth[0.25]};}
  `}

  ${isActive &&
  !isDisabled &&
  `
    background-color: ${theme.colors.main.primary.darkest};
    border-color: ${theme.colors.main.primary.darkest};
  `}
`;

const Secondary = ({
  isActive,
  isDisabled,
  theme,
}: {
  isActive: ButtonProps['isActive'];
  isDisabled: ButtonProps['isDisabled'];
  theme: ThemeContainerProps;
}) => css`
  color: ${theme.colors.main.primary.darker};
  background-color: ${theme.colors.ui.card};
  border: ${theme.borderWidth[0.0625]} solid ${theme.colors.main.primary.darker};

  ${!isDisabled &&
  `
    :hover {
      border-color: ${theme.colors.main.primary.lighter};
      color: ${theme.colors.main.primary.lighter};
    }
  `}

  ${isDisabled &&
  `
      border-color: ${theme.colors.ui.divider};
      color: ${theme.colors.text.muted};
      cursor: not-allowed;
  `}

  ${!isActive &&
  !isDisabled &&
  `
  :not(:active):focus {
    border-color: transparent;
    outline: ${theme.colors.main.light.normal} solid ${theme.borderWidth[0.25]};}
  `}

  ${isActive &&
  !isDisabled &&
  `
    border-color: ${theme.colors.main.primary.darkest};
    color: ${theme.colors.main.primary.darkest};
  `}
`;

const Tertiary = ({
  isActive,
  isDisabled,
  theme,
}: {
  isActive: ButtonProps['isActive'];
  isDisabled: ButtonProps['isDisabled'];
  theme: ThemeContainerProps;
}) => css`
  color: ${theme.colors.main.primary.darker};
  background-color: ${theme.colors.ui.card};
  border: ${theme.borderWidth[0.0625]} solid transparent;

  ${!isDisabled &&
  `
  :hover {
      background-color: ${theme.colors.ui.body};
    }
    `}

  ${isDisabled &&
  `
      color: ${theme.colors.text.muted};
      cursor: not-allowed;
  `}

  ${!isActive &&
  !isDisabled &&
  `
  :not(:active):focus {
    outline: ${theme.colors.main.light.normal} solid ${theme.borderWidth[0.25]};}
  `}

  ${isActive &&
  !isDisabled &&
  `
    color: ${theme.colors.main.primary.darkest};
  `}
`;

const Custom = ({
  isActive,
  isDisabled,
  color,
  borderColor,
  backgroundColor,
  hoverColor,
  hoverBorderColor,
  hoverBackgroundColor,
  activeColor,
  activeBorderColor,
  activeBackgroundColor,
  disableColor,
  disableBorderColor,
  disableBackgroundColor,
  theme,
}: {
  isActive: ButtonProps['isActive'];
  isDisabled: ButtonProps['isDisabled'];
  color: ButtonProps['color'];
  borderColor: ButtonProps['borderColor'];
  backgroundColor: ButtonProps['backgroundColor'];
  hoverColor: ButtonProps['hoverColor'];
  hoverBorderColor: ButtonProps['hoverBorderColor'];
  hoverBackgroundColor: ButtonProps['hoverBackgroundColor'];
  activeColor: ButtonProps['activeColor'];
  activeBorderColor: ButtonProps['activeBorderColor'];
  activeBackgroundColor: ButtonProps['activeBackgroundColor'];
  disableColor: ButtonProps['disableColor'];
  disableBorderColor: ButtonProps['disableBorderColor'];
  disableBackgroundColor: ButtonProps['disableBackgroundColor'];
  theme: ThemeContainerProps;
}) => css`
  color: ${color};
  border: ${theme.borderWidth[0.0625]} solid ${borderColor};
  background-color: ${backgroundColor};

  ${!isDisabled &&
  `
    :hover {
      color: ${hoverColor || color};
      border-color: ${hoverBorderColor || borderColor};
      background-color: ${hoverBackgroundColor || backgroundColor};
    }
  `}

  ${!isActive &&
  !isDisabled &&
  `
    :not(:active):focus {
      color: ${activeColor || color};
      border-color: ${activeBorderColor || borderColor};
      background-color: ${activeBackgroundColor || backgroundColor};
    `}

  ${isDisabled &&
  `
      color: ${disableColor || color};
      border-color: ${disableBorderColor || borderColor};
      background-color: ${disableBackgroundColor || backgroundColor};
      cursor: not-allowed;
  `}
`;

const handleVariant = (props: ButtonProps, theme: ThemeContainerProps) => {
  const {
    isActive,
    isDisabled,
    color,
    borderColor,
    backgroundColor,
    hoverColor,
    hoverBorderColor,
    hoverBackgroundColor,
    activeColor,
    activeBorderColor,
    activeBackgroundColor,
    disableColor,
    disableBorderColor,
    disableBackgroundColor,
  } = props;
  switch (props.variant) {
    case 'primary':
      return Primary({ isActive, isDisabled, theme });
    case 'secondary':
      return Secondary({ isActive, isDisabled, theme });
    case 'tertiary':
      return Tertiary({ isActive, isDisabled, theme });
    case 'custom':
      return Custom({
        isActive,
        isDisabled,
        color,
        borderColor,
        backgroundColor,
        hoverColor,
        hoverBorderColor,
        hoverBackgroundColor,
        activeColor,
        activeBorderColor,
        activeBackgroundColor,
        disableColor,
        disableBorderColor,
        disableBackgroundColor,
        theme,
      });
  }
};

export const handleSize = (
  size: NonNullable<ButtonProps['size']>,
  shape: ButtonProps['shape'],
  theme: ThemeContainerProps
) => {
  const sizeMap = {
    xs: 0.125,
    sm: 0.25,
    base: 0.5,
    lg: 0.75,
    xl: 1,
  };
  const ratio = shape === 'rectangular' ? 2 : 1;
  const xValue = (ratio *
    sizeMap[size]) as keyof ThemeContainerProps['spacing'];
  const yValue = sizeMap[size] as keyof ThemeContainerProps['spacing'];

  return {
    padding: `${theme.spacing[yValue]} ${theme.spacing[xValue]}`,
  };
};

export const handleShape = (
  shape: ButtonProps['shape'],
  borderRadius: NonNullable<ButtonProps['borderRadius']>,
  theme: ThemeContainerProps
) => {
  const borderRadiusIndex = shape === 'round' ? 'full' : borderRadius;
  return {
    borderRadius: `${theme.borderRadius[borderRadiusIndex]}`,
  };
};

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  gap: ${(props) => props.theme.spacing[0.25]};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  ${(props) =>
    props.borderRadius &&
    handleShape(props.shape, props.borderRadius, props.theme)};
  ${(props) => props.size && handleSize(props.size, props.shape, props.theme)};
  ${(props) => handleVariant(props, props.theme)};
`;
