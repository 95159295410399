import { createGlobalStyle } from '@morf/theming';

export const ToastStyle = createGlobalStyle`
:root {
    --toast-y-position: 1.25rem;
    --toast-x-position: 2rem;
  }
  
  .Toastify__toast-container {
    width: auto;
    padding: 0;
  }
  
  .Toastify__toast-container--top-left {
    top: var(--toast-y-position);
    left: var(--toast-x-position);
  }
  
  .Toastify__toast-container--top-center {
    top: var(--toast-y-position);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .Toastify__toast-container--top-right {
    top: var(--toast-y-position);
    right: var(--toast-x-position);
  }
  
  .Toastify__toast-container--bottom-left {
    bottom: var(--toast-y-position);
    left: var(--toast-x-position);
  }
  
  .Toastify__toast-container--bottom-center {
    bottom: var(--toast-y-position);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .Toastify__toast-container--bottom-right {
    bottom: var(--toast-y-position);
    right: var(--toast-x-position);
  }
  
  .Toastify__toast {
    background: none;
    border-radius: 0;
    box-shadow: none;
    color: inherit;
    min-height: unset;
    overflow: unset;
    padding: 0;
  }
  
  .Toastify__toast-body {
    padding: 0;
  }
  
  .Toastify__progress-bar {
    visibility: hidden;
  }
  .Toastify__toast-icon {
    visibility: hidden;
  }
  .Toastify__spinner{
    display: none;
  }
  
`;
