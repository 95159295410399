import { TextareaProps } from './types';
import { styled } from '@morf/theming';

export const StyledTextarea = styled.textarea<TextareaProps>`
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadius[0.5]};
  border: ${({ theme }) => theme.borderWidth[0.0625]} solid
    ${({ error, theme }) =>
      error ? theme.colors.support.red.darkest : theme.colors.ui.divider};
  color: ${({ theme }) => theme.colors.text.body};
  cursor: ${({ readOnly }) => (readOnly ? 'not-allowed' : 'text')};
  font-family: ${({ theme }) => theme.fontTypes.p1.family};
  font-size: ${({ theme }) => theme.fontTypes.p2.size};
  font-synthesis: none;
  font-weight: ${({ theme }) => theme.fontTypes.p2.weight};
  letter-spacing: ${({ theme }) => theme.fontTypes.p2.letterSpacing};
  line-height: ${({ theme }) => theme.fontTypes.p2.lineHeight};
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing[0.75]};
  resize: none;
  text-overflow: ellipsis;
  text-rendering: optimizeLegibility;
  width: calc(100% - 1.75rem);

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.muted};
  }

  :active,
  :focus {
    border: ${({ readOnly, theme, error }) =>
      !readOnly &&
      `${theme.borderWidth[0.0625]} solid ${
        error
          ? theme.colors.support.red.darkest
          : theme.colors.main.primary.darker
      }`};
  }
`;
