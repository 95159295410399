export const IntakeQ = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.449 25.0694C27.6758 26.3035 28.8601 27.4952 30.0186 28.6611C28.8896 29.7993 27.8436 30.8526 26.7054 32C25.6355 30.8452 24.5157 29.6388 23.5399 28.5854C22.0825 29.0337 20.9185 29.5576 19.7047 29.7329C16.5539 30.1885 13.9288 29.0595 11.8184 26.7057C7.93895 22.3798 8.08653 14.9768 12.1247 10.8871C16.2772 6.68106 22.3924 7.10166 25.8144 11.9386C28.5889 15.8605 28.7642 20.0794 26.7884 24.4182C26.6759 24.6672 26.5357 24.9033 26.449 25.0675V25.0694ZM18.1846 23.5622C19.3265 22.4166 20.3577 21.3836 21.424 20.3155C21.8851 20.7527 22.4072 21.2489 22.9514 21.7673C24.1855 19.1459 23.503 15.7037 21.435 13.7759C19.7213 12.1784 17.3379 12.1747 15.6186 13.7685C14.3642 14.9326 13.7185 16.412 13.5543 18.0778C13.32 20.4649 13.9159 22.5974 15.7569 24.2503C16.9671 25.3369 18.5259 25.6173 19.8781 24.907C19.2527 24.4108 18.6808 23.957 18.1828 23.5622H18.1846Z'
        fill='#3C6E9B'
      />
    </svg>
  );
};
