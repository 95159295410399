import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { ObjectGroupHeaderProps } from './types';
import { Avatar } from '../Avatar';
import { Badge } from '../Badge';
import { SkeletonLoadable } from '../Skeleton';
import { Timestamp } from '../Timestamp';

export const ObjectGroupHeader: FC<ObjectGroupHeaderProps> = ({
  appName,
  appIconUrl,
  isConnected,
  lastUpdated,
  isLoading,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      px={2}
      py={1}
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      height='auto'
    >
      <SkeletonLoadable isLoading={isLoading} height='3rem'>
        <Avatar src={appIconUrl} alt={appName} size='lg' />
      </SkeletonLoadable>
      <Flexbox direction='column' height='auto' gap={0.25}>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
            <Text tag='h2'>{appName}</Text>
          </SkeletonLoadable>
          {isConnected && <Badge type='success' label='Connected' />}
        </Flexbox>
        <SkeletonLoadable isLoading={isLoading} height='1rem' width='10rem'>
          <Timestamp
            tag='p2'
            color={theme.colors.text.muted}
            timestamp={lastUpdated}
          />
        </SkeletonLoadable>
      </Flexbox>
    </Flexbox>
  );
};
