export const Recurly = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1123_66691)'>
        <path
          d='M22.8441 21.5166C25.7302 20.786 28.5858 18.3694 28.5858 14.4277C28.5858 9.59715 25.2719 7.2916 20.0274 7.28882H20.0219C15.3941 7.28882 12.7191 9.47493 12.0941 13.261H9.79688L14.3858 17.1638L19.2358 13.261H16.6302C16.6302 13.261 16.6524 10.5499 20.083 10.5499C23.0108 10.5499 24.0108 12.6833 24.0108 14.4583C24.0108 17.0444 22.6441 19.0027 19.3663 19.0027H12.0858V31.5888H16.6747V22.1166H16.6885C17.6997 22.1166 18.6802 22.4916 20.3747 24.9999L24.4635 31.586H29.5719L25.3524 24.8305C24.2441 23.1055 23.4163 21.9666 22.8413 21.5138L22.8441 21.5166Z'
          fill='#723080'
        />
      </g>
      <defs>
        <clipPath id='clip0_1123_66691'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
