import { ReactNode, useEffect, useState } from 'react';
import { WorkflowContext } from './workflowContext';
import { WorkflowContextProps } from './types';
import { useRouter } from 'next/router';

export const WorkflowProvider = (props: { children: ReactNode }) => {
  const [nodes, setNodes] = useState<WorkflowContextProps['nodes']>([]);
  const [edges, setEdges] = useState<WorkflowContextProps['edges']>([]);
  const [selectedNode, setSelectedNode] =
    useState<WorkflowContextProps['selectedNode']>();
  const [destinationActions, setDestinationActions] = useState<
    WorkflowContextProps['destinationActions']
  >([]);
  const [fetchActions, setFetchActions] = useState<
    WorkflowContextProps['fetchActions']
  >([]);
  const [defaultProperties, setDefaultProperties] = useState<
    WorkflowContextProps['defaultProperties']
  >({});
  const [customProperties, setCustomProperties] = useState<
    WorkflowContextProps['customProperties']
  >({});
  const [customPropertySchemas, setCustomPropertySchemas] = useState<
    WorkflowContextProps['customPropertySchemas']
  >([]);
  const [showEventPayloadPanel, setShowEventPayloadPanel] =
    useState<WorkflowContextProps['showEventPayloadPanel']>(false);

  const router = useRouter();

  useEffect(() => {
    setSelectedNode(undefined);
  }, [router.pathname]);

  useEffect(() => {
    if (selectedNode) {
      const updatedNode = nodes.find((node) => node.id === selectedNode.id);
      if (updatedNode) {
        setSelectedNode(updatedNode);
      }
    }
  }, [nodes]);

  const value = {
    nodes,
    edges,
    setNodes,
    setEdges,
    selectedNode,
    setSelectedNode,
    destinationActions,
    setDestinationActions,
    fetchActions,
    setFetchActions,
    defaultProperties,
    setDefaultProperties,
    customProperties,
    setCustomProperties,
    customPropertySchemas,
    setCustomPropertySchemas,
    showEventPayloadPanel,
    setShowEventPayloadPanel,
  };

  return (
    <WorkflowContext.Provider value={value}>
      {props.children}
    </WorkflowContext.Provider>
  );
};
