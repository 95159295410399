import { Edge, Node } from 'reactflow';

export const profileLookupSubflowProfileNotFound = (
  index: number,
  lookupsLength: number
) => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-profile-not-found-${index}`,
    type: 'branchNode',
    data: {
      value: {
        name: 'profile not found',
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: 0, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-profile-not-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-profile-not-found-${index}`,
  });

  edges.push({
    id: `lookup-subflow-profile-not-found-${index}-field-lookup-${index + 1}`,
    source: `lookup-subflow-profile-not-found-${index}`,
    target: `lookup-subflow-field-lookup-${index + 1}`,
  });

  return { nodes, edges };
};
