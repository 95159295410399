export const Spruce = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.33 15.78C6.04 15.78 3 19.7 3 23.89V32H22.67C26.96 32 30.01 28.07 30.01 23.88C29.99 19.7 26.97 15.78 22.66 15.78H10.33Z'
        fill='#007FFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.26953 16.1C9.26953 11.8 12.3895 8 16.5195 8H36.7095V16.13C36.7095 20.43 33.7595 24.21 29.6295 24.21H16.5195C12.3795 24.21 9.28953 20.4 9.26953 16.11V16.1Z'
        fill='#CCE5FF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.28027 15.86C9.28027 15.94 9.28027 16.02 9.28027 16.1C9.30027 20.4 12.3903 24.2 16.5303 24.2H29.6403C29.7703 24.2 29.8903 24.2 30.0103 24.19C30.0103 24.08 30.0103 23.98 30.0103 23.87C29.9903 19.69 26.9703 15.77 22.6603 15.77H10.3303C9.97027 15.77 9.62027 15.8 9.28027 15.85V15.86Z'
        fill='#4DA5FF'
      />
    </svg>
  );
};
