import Cookies from 'js-cookie';
import { MORF_ORG_ID } from '@morf/constants';
import { useEffect } from 'react';

const VISIBILITY_CHANGE_EVENT = 'visibilitychange';

export const useVisibilityChange = (organizationId?: string) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && organizationId) {
        Cookies.set(MORF_ORG_ID, organizationId);
      }
    };

    document.addEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange);

    return () => {
      document.removeEventListener(
        VISIBILITY_CHANGE_EVENT,
        handleVisibilityChange
      );
    };
  }, [organizationId]);
};
