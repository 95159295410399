import { ActionSettingsProps } from './types';
import { DoNotAssociateThirdPartyId } from './DoNotAssociateThirdPartyId';
import { Environment } from './Environment';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { RequiredThirdPartyIdMissing } from './RequiredThirdPartyIdMissing';
import { ParameterMissingPolicy } from './ParameterMissingPolicy';

const ActionSettings: FC<ActionSettingsProps> = ({
  includeDoNotAssociateThirdPartyId,
  includeEnvironment,
  includeRequiredThirdPartyIdMissing,
  ...props
}) => {
  return (
    <Flexbox
      data-testid='action-settings'
      direction='column'
      justifyContent='flex-start'
      height='auto'
    >
      <ParameterMissingPolicy {...props} />
      {includeRequiredThirdPartyIdMissing && (
        <RequiredThirdPartyIdMissing {...props} />
      )}
      {includeDoNotAssociateThirdPartyId && (
        <DoNotAssociateThirdPartyId {...props} />
      )}
      {includeEnvironment && <Environment {...props} />}
    </Flexbox>
  );
};

export const MemoizedActionSettings = memo(ActionSettings);
