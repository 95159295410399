import _ from 'lodash';
import { ActionItem } from './ActionList/types';

export const filterActionList = (data: ActionItem[], searchValue: string) => {
  if (!searchValue) return data;
  return data.filter((item) => {
    const { name, integrationIconName } = item;
    return _.some([name, integrationIconName], (key) =>
      _.includes(key?.toLowerCase(), searchValue.toLowerCase())
    );
  });
};
