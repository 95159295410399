import * as monaco from '@monaco-editor/react';

export const setEditorTheme = (
  monaco: monaco.Monaco,
  foreground: string,
  background: string,
  lineNumberForeground: string,
  findMatchBackground: string,
  selectionBackground: string,
  lineHighlightBackground: string,
  findMatchHighlightBackground: string,
  rangeHighlightBackground: string
) => {
  monaco.editor.defineTheme('default', {
    base: 'vs',
    inherit: false,
    rules: [],
    colors: {
      'editor.foreground': foreground,
      'editor.background': background,
      'editorLineNumber.foreground': lineNumberForeground,
      'editor.findMatchBackground': findMatchBackground,
      'editor.selectionBackground': selectionBackground,
      'editor.lineHighlightBackground': lineHighlightBackground,
      'editor.findMatchHighlightBackground': findMatchHighlightBackground,
      'editor.rangeHighlightBackground': rangeHighlightBackground,
    },
  });
};
