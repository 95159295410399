import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultH7,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const morf: ThemeContainerProps = {
  ...DefaultTheme,
  cancellationPolicyHeader: 'Late Change Policy',
  cancellationPolicyDescription: `<p>In order to protect our provider's time, appointment changes within 24hrs of the scheduled time will incur a $100 fee per our <a style="color: #003E32; text-decoration: underline;" href="https://www.berrystreet.co/policy#cancellations" target='_blank'>policies</a>.`,
  colors: {
    ...defaultColors,
    text: {
      body: '#202236',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#1120B1',
        darker: '#3C4CEC',
        dark: '#384DF5',
        normal: '#5063F6',
        light: '#6878F7',
        lighter: '#818EF9',
        lightest: '#99A4FA',
      },
      dark: {
        darkest: '#202236',
        darker: '#3F4155',
        dark: '#818398',
        normal: '#B0B2BF',
        light: '#BEC0DA',
        lighter: '#D2D3E5',
        lightest: '#E5E6F0',
      },
      light: {
        darkest: '#CACFFC',
        darker: '#E2E5FE',
        dark: '#E3E9FF',
        normal: '#E3E9FF',
        light: '#eff0fe',
        lighter: '#f7f8ff',
        lightest: '#fafaff',
      },
    },
    avatar: [
      '#7E9373',
      '#C79A86',
      '#498BBB',
      '#5D5FA3',
      '#D57FA9',
      '#333E6C',
      '#B19746',
      '#7ACBC6',
    ],
  },
  container: {
    borderType: 'none',
    borderRadius: 0.5,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.25,
    style: 'nofill',
  },
  fontTypes: {
    h1: defaultH1,
    h2: defaultH2,
    h3: defaultH3,
    h4: defaultH4,
    h5: defaultH5,
    h6: defaultH6,
    h7: defaultH7,
    p1: defaultP1,
    p2: defaultP2,
    p3: defaultP3,
    p4: defaultP4,
    subtitle: defaultSubtitle,
    longform: defaultLongform,
    link: defaultLink,
  },
  input: { borderRadius: 0.5 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'outline',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'light',
  },
  withinTimeWindowHours: 24,
  withinTimeWindowTitle: 'An appointment change within 24hrs will incur a fee',
  withinTimeWindowMessage: `<p>In order to protect our provider's time, our system automatically applies a late change fee ($100) which you agreed to when making your booking. <br/><br/> If you believe this is in error, you can reach out to us directly at support@berrystreet.co.</p>`,
};
