import { FC, memo } from 'react';
import { Text } from '../../../Typography';
import { ParameterLabelProps } from './types';

const ParameterLabel: FC<ParameterLabelProps> = ({
  label,
  objectFontColor,
  leafFontColor,
  wordWrap = 'break-word',
}) => {
  if (objectFontColor && label.includes('.') && !label.includes(' ')) {
    const lastPeriodIndex = label.lastIndexOf('.');
    const labelTextBeforeLastPeriod = label.substring(0, lastPeriodIndex + 1);
    const labelTextAfterLastPeriod = label.substring(lastPeriodIndex + 1);

    return (
      <Text tag='p2' whiteSpace='nowrap'>
        <span style={{ color: objectFontColor }}>
          {labelTextBeforeLastPeriod}
        </span>
        <span style={{ color: leafFontColor }}>{labelTextAfterLastPeriod}</span>
      </Text>
    );
  }

  return (
    <Text tag='p2' color={leafFontColor} wordWrap={wordWrap}>
      {label}
    </Text>
  );
};

export const MemoizedParameterLabel = memo(ParameterLabel);
