import { FC, memo, useState } from 'react';
import { ToggleProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { capitalizeFirstLetter } from '../Helpers/capitalizeFirstLetter';

const Toggle: FC<ToggleProps> = ({
  defaultOption,
  onChange,
  options,
  readOnly,
}) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleOptionClick = (value: string) => {
    if (!readOnly && selectedValue !== value) {
      setSelectedValue(value);
      if (onChange) {
        onChange(value);
      }
    }
  };

  return (
    <Flexbox
      backgroundColor={theme.colors.ui.body}
      borderRadius={1.25}
      data-testid='toggle'
      width='auto'
      height='auto'
      gap={0}
    >
      {options.map((option, index) => {
        const isSelected = selectedValue === option;
        return (
          <Container
            key={index}
            onClick={() => handleOptionClick(option)}
            cursor={readOnly ? 'not-allowed' : 'pointer'}
            borderType='border'
            borderColor='transparent'
            borderRadius={1.25}
            py={0.5}
            px={0.75}
            {...(isSelected && {
              backgroundColor: theme.colors.ui.card,
              borderColor: theme.colors.ui.card,
              shadow: 'base',
            })}
          >
            <Text
              tag='h6'
              color={
                isSelected ? theme.colors.text.body : theme.colors.text.muted
              }
            >
              {capitalizeFirstLetter(option)}
            </Text>
          </Container>
        );
      })}
    </Flexbox>
  );
};

export const MemoizedToggle = memo(Toggle);
