import { styled } from '@morf/theming';

export const StyledUserImage = styled.img<{ isActive: boolean }>`
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid transparent;
  border-radius: ${(props) => props.theme.borderRadius['full']};
  ${(props) =>
    props.isActive && `border-color: ${props.theme.colors.ui.divider}`};
`;
