import { ParameterMissingPolicyProps } from './types';
import { FC, memo } from 'react';
import { OptionalParameterMissing } from './OptionalParameterMissing';
import { RequiredParameterMissing } from './RequiredParameterMissing';
import { Flexbox } from '../../Flexbox';

const ParameterMissingPolicy: FC<ParameterMissingPolicyProps> = (props) => {
  return (
    <Flexbox
      data-testid='parameter-missing-policy'
      direction='column'
      justifyContent='flex-start'
      height='auto'
    >
      <RequiredParameterMissing {...props} />
      <OptionalParameterMissing {...props} />
    </Flexbox>
  );
};

export const MemoizedParameterMissingPolicy = memo(ParameterMissingPolicy);
