import { FC } from 'react';
import { useTheme } from '@morf/theming';
import { PanelContentProps } from './types';
import { Container } from '../Container';

export const PanelContent: FC<PanelContentProps> = ({ panelContent }) => {
  const theme = useTheme();
  return (
    <Container
      position='absolute'
      top='3.5rem'
      height='calc(100% - 3.5rem)'
      backgroundColor={theme.colors.ui.card}
      borderColor={theme.colors.ui.divider}
      data-testid='panel-content'
    >
      {panelContent}
    </Container>
  );
};
