import { Handle, Position } from 'reactflow';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { useTheme } from '@morf/theming';
import { Icon } from '../../../../Icon';
import { Text } from '../../../../Typography';
import { ProfileLookupNodeProps } from '../types';
import { nodeWidth } from '../../../constants';

const FieldNode: FC<ProfileLookupNodeProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <Flexbox
        direction='row'
        data-testid='field-node'
        backgroundColor={theme.colors.ui.card}
        justifyContent='center'
        alignItems='center'
        borderRadius={0.5}
        height='2rem'
        width={nodeWidth}
        gap={0.25}
        style={{
          outline: `${theme.colors.ui.muted} solid ${theme.borderWidth[0.0625]}`,
        }}
      >
        <Icon
          name='document-search'
          stroke={theme.colors.text.muted}
          size={1.25}
        />
        <Flexbox width='auto' maxWidth='9.5rem' alignItems='center'>
          <Text tag='p3' whiteSpace='nowrap' color={theme.colors.text.body}>
            {data.value.name}
          </Text>
        </Flexbox>
      </Flexbox>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedFieldNode = memo(FieldNode);
