import { FC } from 'react';
import { StyledStepContainer } from './Stepper.css';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { StepperProps } from './types';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';

export const Stepper: FC<StepperProps> = ({ steps, currentStepIndex }) => {
  const theme = useTheme();
  const width = `${(100 / (steps.length - 1)) * currentStepIndex}%`;

  return (
    <StyledStepContainer width={width} gap={4}>
      {steps.map((stepIndex, index) => {
        const isCurrent = currentStepIndex === stepIndex;
        const isCompleted = currentStepIndex > stepIndex;
        return (
          <Flexbox
            key={index}
            zIndex={1}
            justifyContent='center'
            alignItems='center'
            width='1.5rem'
            height='1.5rem'
            borderRadius='full'
            backgroundColor={
              isCompleted || isCurrent
                ? theme.colors.main.dark.darkest
                : theme.colors.main.light.normal
            }
          >
            {isCompleted ? (
              <Icon
                name='check'
                size={1}
                strokeWidth={2}
                stroke={theme.colors.text.inverse}
              />
            ) : (
              <Text
                weight='bold'
                color={
                  isCompleted || isCurrent
                    ? theme.colors.text.inverse
                    : theme.colors.text.muted
                }
              >
                {stepIndex + 1}
              </Text>
            )}
          </Flexbox>
        );
      })}
    </StyledStepContainer>
  );
};
