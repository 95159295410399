import { ActiveCampaign } from './Icons/ActiveCampaign';
import { Acuity } from './Icons/Acuity';
import { AxleHealth } from './Icons/AxleHealth';
import { ButterflyLabs } from './Icons/ButterflyLabs';
import { CustomerIO } from './Icons/CustomerIO';
import { Facebook } from './Icons/Facebook';
import { Formsort } from './Icons/Formsort';
import { Freshdesk } from './Icons/Freshdesk';
import { Google } from './Icons/Google';
import { Healthie } from './Icons/Healthie';
import { Hubspot } from './Icons/Hubspot';
import { IntakeQ } from './Icons/IntakeQ';
import { Intercom } from './Icons/Intercom';
import { Morf } from './Icons/Morf';
import { ReactNode, useMemo } from 'react';
import { Recurly } from './Icons/Recurly';
import { SanaBenefits } from './Icons/SanaBenefits';
import { Slack } from './Icons/Slack';
import { Spruce } from './Icons/Spruce';
import { Vital } from './Icons/Vital';
import { useIsBerrySt } from '../Hooks/useIsBerrySt';

export const IntegrationIconMapping = (): { [key: string]: ReactNode } => {
  const isBerrySt = useIsBerrySt();

  return useMemo(
    () => ({
      acuity: <Acuity />,
      'active-campaign': <ActiveCampaign />,
      'axle-health': <AxleHealth />,
      'butterfly-labs': <ButterflyLabs />,
      customer: <Morf />,
      'customer-io': isBerrySt ? <Morf /> : <CustomerIO />,
      facebook: <Facebook />,
      formsort: <Formsort />,
      freshdesk: <Freshdesk />,
      google: <Google />,
      healthie: <Healthie />,
      hubspot: <Hubspot />,
      intakeq: <IntakeQ />,
      intercom: <Intercom />,
      morf: <Morf />,
      'push-json': <Morf />,
      'sana-benefits': <SanaBenefits />,
      recurly: <Recurly />,
      segment: <Morf />,
      spruce: <Spruce />,
      slack: <Slack />,
      vital: <Vital />,
    }),
    [isBerrySt]
  );
};
