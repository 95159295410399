export const ButterflyLabs = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.5999 27.0719C25.4913 29.0427 27.8158 30.3477 28.9979 30.9341C29.0786 30.9718 29.1674 30.9886 29.2562 30.9831C29.345 30.9775 29.431 30.9497 29.5063 30.9023C29.5816 30.8548 29.6438 30.7893 29.6872 30.7115C29.7305 30.6338 29.7537 30.5464 29.7545 30.4574V26.3191C29.7531 25.4983 29.5027 24.6972 29.0363 24.0218C28.5699 23.3464 27.9096 22.8284 27.1425 22.5363L25.705 22.0181C21.8788 23.1661 23.4864 26.8316 23.5999 27.0719Z'
        fill='#2779FF'
      />
      <path
        d='M28.6936 20.0321C30.3183 19.6444 31.9997 19.0259 31.9997 16.4479V11.0102C31.9994 10.8421 31.9573 10.6768 31.8771 10.5291C31.797 10.3814 31.6813 10.256 31.5405 10.1643C31.3997 10.0725 31.2383 10.0172 31.0708 10.0034C30.9033 9.98963 30.7351 10.0178 30.5812 10.0853C27.555 11.4093 20.7271 15.037 20.7271 20.6771C20.7271 23.2607 22 25.4055 23.5887 27.0662C20.0348 22.0219 26.3123 20.6014 28.6936 20.0321Z'
        fill='#2779FF'
      />
      <path
        d='M16.3996 27.0719C14.5082 29.0427 12.1818 30.3477 10.9997 30.9341C10.9191 30.9718 10.8303 30.9886 10.7415 30.9831C10.6526 30.9775 10.5666 30.9497 10.4913 30.9023C10.416 30.8548 10.3538 30.7893 10.3105 30.7115C10.2671 30.6338 10.244 30.5464 10.2432 30.4574V26.3191C10.245 25.4981 10.4958 24.697 10.9625 24.0216C11.4292 23.3462 12.0898 22.8283 12.8571 22.5363L14.2926 22.0181C18.1208 23.1661 16.5112 26.8316 16.3996 27.0719Z'
        fill='#2779FF'
      />
      <path
        d='M11.3061 20.0321C9.67577 19.6444 8 19.0259 8 16.4479V11.0102C8.00028 10.8421 8.04241 10.6768 8.12259 10.5291C8.20276 10.3814 8.31846 10.256 8.45923 10.1643C8.6 10.0725 8.76141 10.0172 8.9289 10.0034C9.09638 9.98963 9.26466 10.0178 9.41854 10.0853C12.4448 11.4093 19.2727 15.037 19.2727 20.6771C19.2727 23.2607 17.9998 25.4055 16.4091 27.0662C19.9649 22.0219 13.6855 20.6014 11.3061 20.0321Z'
        fill='#2779FF'
      />
    </svg>
  );
};
