export const Vital = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32 10C31.8392 10.4727 31.7411 10.8061 31.6136 11.1277C29.2993 16.9174 26.9811 22.7051 24.6609 28.4928C23.9529 30.2559 23.2292 30.7423 21.3229 30.7443C20.507 30.7443 19.6891 30.7443 18.8733 30.7443C16.7257 30.7443 16.0883 30.3089 15.2783 28.281C13.0365 22.6815 10.7968 17.0782 8.55896 11.4768C8.39225 11.0571 8.23731 10.6315 8 10.002C8.82569 10.002 9.51998 10.002 10.2143 10.002C11.2263 10.002 12.2403 9.99804 13.2523 10.002C14.9488 10.0118 15.8353 10.6551 16.3726 12.2731C17.4827 15.619 18.5869 18.9669 19.695 22.3128C19.8049 22.6443 19.9206 22.9718 20.1128 23.5327C20.3128 23.0503 20.4344 22.8051 20.5188 22.5482C21.6288 19.2042 22.735 15.8583 23.8392 12.5124C24.4785 10.5727 25.2493 10.0039 27.2773 10.002C28.8031 10 30.329 10.002 32 10.002V10Z'
        fill='#1E40AF'
      />
    </svg>
  );
};
