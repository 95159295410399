import _ from 'lodash';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const validateNode = (
  action: workflows.v1.WorkflowDestinationAction | null | undefined
) => {
  const thirdPartyProfileIdUpdateSources =
    action?.morfV1?.updateProfileProperties?.thirdPartyProfileIdUpdateSources;

  const thirdPartyProfileIdUpdateSourcesValid =
    thirdPartyProfileIdUpdateSources?.every(
      (source) => source.type && source.source
    );

  const propertyUpdateSources =
    action?.morfV1?.updateProfileProperties?.propertyUpdateSources;

  const propertyUpdateSourcesValid = propertyUpdateSources?.every(
    (source) => source.property && source.source
  );

  return thirdPartyProfileIdUpdateSourcesValid && propertyUpdateSourcesValid;
};
