import { CustomOptionProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { Text } from '../../../../Typography';
import { useTheme } from '@morf/theming';

const CustomOption: FC<CustomOptionProps> = ({ isSelected, onClick }) => {
  const theme = useTheme();

  const backgroundColor = isSelected
    ? theme.colors.main.light.light
    : theme.colors.ui.card;

  return (
    <Flexbox
      data-testid='custom-option'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      cursor='pointer'
      height='auto'
      onClick={onClick}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={theme.colors.main.light.light}
      borderRadius={0.25}
      p={0.25}
    >
      <Text tag='p3'>Custom</Text>
      <Icon
        name='chevron-right'
        size={0.75}
        stroke={theme.colors.ui.dark}
        strokeWidth={2}
      />
    </Flexbox>
  );
};

export const MemoizedCustomOption = memo(CustomOption);
