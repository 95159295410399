import _ from 'lodash';
import { ProfileProperty } from './Property/types';

export const filterProperties = (
  properties: ProfileProperty[],
  searchValue: string
): ProfileProperty[] => {
  if (!searchValue) return properties;
  const lowercasedSearchValue = _.toLower(searchValue);
  return _.filter(properties, ({ name, propertySchema }) => {
    const nameMatches = _.includes(_.toLower(name), lowercasedSearchValue);
    const celExpressionMatches = propertySchema?.schema?.scalar
      ?.celExpressionStr
      ? _.includes(
          _.toLower(propertySchema.schema.scalar.celExpressionStr),
          lowercasedSearchValue
        )
      : false;
    return nameMatches || celExpressionMatches;
  });
};
