import { MorfUser } from './types';

const personalMorfUserEmails = [
  'amscher@gmail.com',
  'alex@smallhound.co',
  'bela.ndrio.is@gmail.com',
];

export const isAccessGranted = (
  user: MorfUser | undefined,
  organizationId: string,
  pathname: string
): boolean => {
  const allowFullAccessToMorfUser =
    user?.email?.includes('@morf.health') ?? false;

  const allowPersonalMorfUsers = user?.email
    ? personalMorfUserEmails.includes(user.email)
    : false;

  const allowFullAccessToNemaStaging =
    user?.email === 'chipper@nemahealth.com' &&
    organizationId === '8f5752d2-b315-4c2b-9d34-77c1e352cb9b';

  const allowPartialAccessToProvidersPath = pathname.endsWith('/providers');

  return (
    allowFullAccessToMorfUser ||
    allowPersonalMorfUsers ||
    allowFullAccessToNemaStaging ||
    allowPartialAccessToProvidersPath
  );
};
