import _ from 'lodash';
import { Button } from '../../../../../../Button';
import { Controller, useForm } from 'react-hook-form';
import { CreateCustomPropertyProps } from './types';
import { Dropdown } from '../../../../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Icon } from '../../../../../../Icon';
import { Input } from '../../../../../../Input';
import { Option } from '../../../../../../Dropdown/types';
import { Text } from '../../../../../../Typography';
import { Textarea } from '../../../../../../Textarea';
import { convertToTitleCase } from '../../../../../../Helpers/convertToTitleCase';
import { formatEnumToOptions } from '../../../../../../Helpers/formatEnumToOptions';
import { organization } from '@morf/proto/organization_v1_ts_proto';
import { profile_properties } from '@morf/proto/profile_properties_v1_ts_proto';
import { sensitivity } from '@morf/proto/sensitivity_v1_ts_proto';
import { useTheme } from '@morf/theming';

const CreateCustomProperty: FC<CreateCustomPropertyProps> = ({
  customPropertySchemas,
  name,
  onClick,
}) => {
  const theme = useTheme();
  const { register, handleSubmit, control, formState } =
    useForm<organization.v1.CreateProfilePropertyRequest>();

  const propertyTypeStubOptions: Option[] = customPropertySchemas.map(
    ({ stub }) => ({
      label: convertToTitleCase(Object.keys(stub!.custom!)[0]).replace(
        'Property',
        ''
      ),
      value: JSON.stringify(stub),
    })
  );

  const additionalCustomSensitivitiesOptions = _.map(
    formatEnumToOptions(sensitivity.v1.Sensitivity.Value),
    ({ label, value }) => ({ label: label.toUpperCase(), value })
  );

  return (
    <Flexbox
      data-testid='create-custom-property'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      p={0.75}
      gap={0.5}
    >
      <Input
        id='name'
        value={name}
        leftElement={
          <Icon
            name='tag'
            stroke={theme.colors.ui.dark}
            size={1}
            strokeWidth={1.75}
          />
        }
        height='2.25rem'
        {...register('name', { required: true })}
      />
      <Controller
        name='propertyTypeStub'
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Dropdown
              placeholder='Select type'
              options={propertyTypeStubOptions}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map((o) => o.value);
                const selectedValue = selectedValues[0];
                field.onChange(JSON.parse(selectedValue));
              }}
              clearable
            />
          );
        }}
      />

      <Controller
        name='additionalCustomSensitivities'
        control={control}
        render={({ field }) => {
          return (
            <Dropdown
              placeholder='Select sensitivity'
              options={additionalCustomSensitivitiesOptions}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(({ value }) =>
                  parseInt(value)
                );
                field.onChange(selectedValues);
              }}
              multiSelect
              clearable
            />
          );
        }}
      />
      <Textarea
        placeholder='Optional description'
        rows={4}
        {...register('description')}
      />
      <Button
        variant='primary'
        onClick={handleSubmit(onClick)}
        isDisabled={!formState.isValid}
        width='100%'
      >
        <Text tag='h5' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedCreateCustomProperty = memo(CreateCustomProperty);
