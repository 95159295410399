import { styled } from '@morf/theming';
import { Container } from '../Container';

export const StyledWeek = styled(Container)<{
  isSelectedWeek: boolean;
  isFirstDate: boolean;
  isLastDate: boolean;
}>`
  background-color: ${({ theme, isSelectedWeek }) =>
    isSelectedWeek ? theme.colors.main.primary.darkest : theme.colors.ui.card};
  border-radius: ${({ isFirstDate, isLastDate }) =>
    isFirstDate
      ? '1.25rem 0 0 1.25rem'
      : isLastDate
      ? '0 1.25rem 1.25rem 0'
      : 0};
`;
