import { css, styled, useTheme } from '@morf/theming';
import { BorderProps, ContainerProps, SpacingPropNames } from './types';

const getPaddingValue = (value: SpacingPropNames | undefined) => {
  const theme = useTheme();
  return (value != null && theme.spacing[value]) || '';
};

export const borderStyles = css<BorderProps>`
  ${({
    theme,
    borderType,
    borderWidth,
    borderStyle,
    borderColor,
    borderRadius,
  }) => {
    const width = theme.borderWidth[borderWidth || 0.0625];
    const radius = theme.borderRadius[borderRadius || 0];
    const color = borderColor || theme.colors.ui.divider;
    const style = borderStyle || 'solid';

    let borderCSS = '';
    switch (borderType) {
      case 'border':
        borderCSS = `border: ${width} ${style} ${color};`;
        break;
      case 'borderTop':
        borderCSS = `border-top: ${width} ${style} ${color};`;
        break;
      case 'borderRight':
        borderCSS = `border-right: ${width} ${style} ${color};`;
        break;
      case 'borderBottom':
        borderCSS = `border-bottom: ${width} ${style} ${color};`;
        break;
      case 'borderLeft':
        borderCSS = `border-left: ${width} ${style} ${color};`;
        break;
      case 'borderTopLeft':
        borderCSS = `
            border-top: ${width} ${style} ${color};
            border-left: ${width} ${style} ${color};
          `;
        break;
      case 'borderBottomLeft':
        borderCSS = `
            border-bottom: ${width} ${style} ${color};
            border-left: ${width} ${style} ${color};
          `;
        break;
      case 'borderTopRight':
        borderCSS = `
              border-top: ${width} ${style} ${color};
              border-right: ${width} ${style} ${color};
            `;
        break;
      case 'borderBottomRight':
        borderCSS = `
              border-bottom: ${width} ${style} ${color};
              border-right: ${width} ${style} ${color};
            `;
        break;
      case 'borderVertical':
        borderCSS = `
            border-top: ${width} ${style} ${color};
            border-bottom: ${width} ${style} ${color};
          `;
        break;
      case 'borderHorizontal':
        borderCSS = `
              border-left: ${width} ${style} ${color};
              border-right: ${width} ${style} ${color};
            `;
        break;
      default:
        break;
    }
    return css`
      ${borderCSS}
      border-radius: ${radius};
    `;
  }}
`;

export const SyledContainer = styled.div<ContainerProps>`
  padding: ${({ p, px, py, pt, pr, pb, pl }) => {
    const paddingX = getPaddingValue(px);
    const paddingY = getPaddingValue(py);
    const paddingTop = getPaddingValue(pt);
    const paddingRight = getPaddingValue(pr);
    const paddingBottom = getPaddingValue(pb);
    const paddingLeft = getPaddingValue(pl);
    const padding = getPaddingValue(p) || '0rem';
    return `${paddingTop || paddingY || padding} ${
      paddingRight || paddingX || padding
    } ${paddingBottom || paddingY || padding} ${
      paddingLeft || paddingX || padding
    }`;
  }};

  margin: ${({ m, mx, my, mt, mr, mb, ml }) => {
    const marginX = getPaddingValue(mx);
    const marginY = getPaddingValue(my);
    const marginTop = getPaddingValue(mt);
    const marginRight = getPaddingValue(mr);
    const marginBottom = getPaddingValue(mb);
    const marginLeft = getPaddingValue(ml);
    const margin = getPaddingValue(m) || '0rem';
    return `${marginTop || marginY || margin} ${
      marginRight || marginX || margin
    } ${marginBottom || marginY || margin} ${marginLeft || marginX || margin}`;
  }};

  width: ${({ width, p, px, pl, pr }) =>
    width === 'auto'
      ? 'auto'
      : `calc(${width || '100%'} - ${getPaddingValue(
          p || px || pl || 0
        )} - ${getPaddingValue(p || px || pr || 0)})`};

  height: ${({ height, p, py }) =>
    height === 'auto'
      ? 'auto'
      : `calc(${height || '100%'} - 2*${getPaddingValue(p || py || 0)})`};

  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'auto'};

  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};

  :hover {
    background-color: ${({ hoverBackgroundColor }) =>
      hoverBackgroundColor || 'none'};
  }

  box-shadow: ${({ theme, shadow }) =>
    shadow && shadow !== 'none'
      ? `${theme.boxShadow[shadow]} ${theme.colors.ui.divider}`
      : 'none'};

  opacity: ${({ opacity }) => opacity || '1'};

  position: ${({ position }) => position || 'static'};
  top: ${({ top }) => top || 'auto'};
  right: ${({ right }) => right || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  left: ${({ left }) => left || 'auto'};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  overflow: ${({ overflow }) => overflow};

  z-index: ${({ zIndex }) => zIndex};
  cursor: ${({ cursor }) => cursor || 'inherit'};

  ${borderStyles}
`;
