'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.sensitivity = (() => {
  const sensitivity = {};
  sensitivity.v1 = (() => {
    const v1 = {};
    v1.Sensitivity = (() => {
      class Sensitivity {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Sensitivity(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.kind != null &&
            Object.hasOwnProperty.call(message, 'kind')
          ) {
            writer.uint32(8).int32(message.kind);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Sensitivity();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.kind = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sensitivity.v1.Sensitivity) {
            return object;
          }
          const message = new $root.sensitivity.v1.Sensitivity();
          if (object.kind != null) {
            switch (object.kind) {
              case 'UNSPECIFIED':
              case 0: {
                message.kind = 0;
                break;
              }
              case 'PII':
              case 1: {
                message.kind = 1;
                break;
              }
              case 'HI':
              case 2: {
                message.kind = 2;
                break;
              }
              case 'ID':
              case 3: {
                message.kind = 3;
                break;
              }
              default: {
                if (typeof object.kind == 'number') {
                  message.kind = object.kind;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.kind = options.enums === String ? 'UNSPECIFIED' : 0;
          }
          let keys;
          if (message.kind != null && message.hasOwnProperty('kind')) {
            object.kind =
              options.enums === String
                ? $root.sensitivity.v1.Sensitivity.Value[message.kind] ===
                  undefined
                  ? message.kind
                  : $root.sensitivity.v1.Sensitivity.Value[message.kind]
                : message.kind;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sensitivity.v1.Sensitivity';
        }
      }

      Sensitivity.prototype.kind = 0;
      Sensitivity.Value = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED')] = 0;
        values[(valuesById[1] = 'PII')] = 1;
        values[(valuesById[2] = 'HI')] = 2;
        values[(valuesById[3] = 'ID')] = 3;
        return values;
      })();

      return Sensitivity;
    })();

    return v1;
  })();

  return sensitivity;
})();

export const sensitivity = $root.sensitivity;
