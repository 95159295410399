import { Button } from '../Button';
import { Container } from '../Container';
import { FC, useEffect, useRef, useState } from 'react';
import { FinalStep } from './Steps/FinalStep/FinalStep';
import { FlexItem, Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { IntegrationStep } from './Steps/IntegrationStep/IntegrationStep';
import { IntegrationWizardProps } from './types';
import { Prerequisites } from './Prerequisites/Prerequisites';
import { Step } from './Steps/Step/Step';
import { Stepper } from '../Stepper/Stepper';
import { Text } from '../Typography';
import { WrapperModal } from '../WrapperModal';
import { useResize } from '../Hooks/useResize';
import { useTheme } from '@morf/theming';

export const IntegrationWizard: FC<IntegrationWizardProps> = ({
  isOpen,
  steps,
  initialStep = 0,
  onClose,
  onFinish,
  integrationType = 'source',
  selectedIntegration,
  setSelectedIntegration,
}) => {
  const theme = useTheme();
  const stepRef = useRef<HTMLDivElement>(null);

  const [currentStepIndex, setCurrentStep] = useState(initialStep);
  const [lastStep, setLastStep] = useState(false);
  const [prerequisiteModal, setPrerequisiteModal] = useState(false);

  const currentStep = steps[currentStepIndex];

  const handlePrerequisites = () => {
    if (currentStep.prerequisiteModal) {
      setPrerequisiteModal(true);
    } else {
      handleNext();
    }
  };

  const handleNext = () => {
    setPrerequisiteModal(false);
    if (currentStepIndex < steps.length - 1) {
      setSelectedIntegration(selectedIntegration);
      setCurrentStep(currentStepIndex + 1);
    } else {
      setLastStep(true);
    }
  };

  const handlePrev = () => {
    if (currentStepIndex > 0) {
      setCurrentStep(currentStepIndex - 1);
    }
  };

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollTo(0, 0);
    }
  }, [currentStepIndex]);

  const responsiveWizard = useResize({
    stepper: {
      xxl: true,
      xl: true,
      lg: true,
      md: true,
      sm: true,
      xs: false,
    },
  });

  return (
    <>
      <WrapperModal
        isOpen={isOpen}
        onClose={onClose}
        width='100%'
        height='100%'
      >
        {lastStep && selectedIntegration ? (
          <FinalStep
            imageUrl={selectedIntegration.imageUrl}
            title={selectedIntegration.name + ' added!'}
            description={'Click continue to view your data.'}
            buttonLabel={'Continue'}
            buttonLink={`/apps/${selectedIntegration.name.toLowerCase()}`}
            onClose={onClose}
            onFinish={onFinish}
          />
        ) : (
          <Flexbox
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
            gap={0}
            height='100%'
          >
            <Flexbox alignItems='center' px={2} py={1.5} height='5.5rem'>
              <Text tag='h4'>Add a {integrationType}</Text>
              {responsiveWizard.stepper && (
                <FlexItem flexGrow={0}>
                  <Stepper
                    currentStepIndex={currentStepIndex}
                    steps={steps.map((_, index) => index)}
                  />
                </FlexItem>
              )}

              <Icon
                name='close'
                cursor='pointer'
                stroke={theme.colors.text.muted}
                size={1.5}
                onClick={onClose}
              />
            </Flexbox>

            <Container
              backgroundColor={theme.colors.ui.body}
              style={{ overflowY: 'scroll' }}
            >
              {currentStepIndex === 0 ? (
                <IntegrationStep
                  ref={stepRef}
                  currentStepIndex={currentStepIndex}
                  currentStep={currentStep}
                  integrationType={integrationType}
                  selectedIntegration={selectedIntegration}
                  setSelectedIntegration={setSelectedIntegration}
                />
              ) : (
                <Step
                  ref={stepRef}
                  currentStepIndex={currentStepIndex}
                  currentStep={currentStep}
                />
              )}
            </Container>

            <Flexbox
              height='5.5rem'
              backgroundColor={theme.colors.ui.card}
              alignItems='stretch'
              px={2}
              py={1.5}
            >
              <Container>
                {currentStepIndex !== 0 && (
                  <Button onClick={handlePrev}>
                    <Flexbox alignItems='center'>
                      <Icon name='arrow-left' size={1} />
                      <Text color='inherit'>Back</Text>
                    </Flexbox>
                  </Button>
                )}
              </Container>
              <Button
                key={currentStepIndex}
                isDisabled={currentStep.disabledContinue}
                variant='primary'
                onClick={handlePrerequisites}
              >
                <Flexbox alignItems='center'>
                  <Text color='inherit'>Continue</Text>
                  <Icon name='arrow-right' size={1} />
                </Flexbox>
              </Button>
            </Flexbox>
          </Flexbox>
        )}
      </WrapperModal>
      {currentStep.prerequisiteModal && (
        <Prerequisites
          isOpen={prerequisiteModal}
          onClose={() => setPrerequisiteModal(false)}
          onPrimaryButtonClick={handleNext}
          prerequisites={currentStep.prerequisiteModal}
        />
      )}
    </>
  );
};
