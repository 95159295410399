import _ from 'lodash';
import { ActionExecutionStatus } from '../../../ActionExecutionStatus';
import { ActionExecutionSubRowProps } from './types';
import { ActionExecutionTooltip } from '../ActionExecutionTooltip';
import { Container } from '../../../Container';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { WrapperModal } from '../../../WrapperModal';
import { convertTimestampToMoment } from '../../../Filters';
import { defaultDateTimeFormatWithSeconds } from '../../../Filters/TimeFilter/constants';
import { useHover } from '../../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../../Hooks/useUpdateUrlParam';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

const ActionExecutionSubRow: FC<ActionExecutionSubRowProps> = ({
  application,
  attemptNumber,
  error,
  executionEnd,
  executionStart,
  expectedExecutionEnd,
  isLastSubRow,
  isSubRowSelected,
  name,
  workflowExecutionSummary,
  ...props
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const backgroundColor = isSubRowSelected
    ? theme.colors.main.light.light
    : isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;

  const timestampTag = isSubRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    executionStart &&
    convertTimestampToMoment(executionStart).format(
      defaultDateTimeFormatWithSeconds
    );

  const status = workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING;

  const handleSubRowClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateUrlParam({ attemptNumber: _.toString(attemptNumber) });
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, handleShowTooltip]);

  return (
    <>
      <Flexbox
        containerRef={containerRef}
        data-testid='action-execution-sub-row'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        backgroundColor={backgroundColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleSubRowClick}
        width='16.5rem'
        height='auto'
        cursor='pointer'
        pl={0.75}
        pr={0.5}
        gap={0.5}
        {...(isLastSubRow && {
          borderType: 'borderBottom',
          borderColor: backgroundColor,
          borderRadius: 0.25,
        })}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          borderType='borderLeft'
          borderColor={theme.colors.ui.muted}
          position='relative'
          height='auto'
          gap={0.5}
          py={0.5}
        >
          {isLastSubRow && (
            <Container
              position='absolute'
              left='-0.1rem'
              top='1.175rem'
              width='1rem'
              height='1.125rem'
              borderType='borderLeft'
              borderColor={backgroundColor}
            />
          )}

          <Container
            width='1.75rem'
            borderType='borderTop'
            borderColor={theme.colors.ui.muted}
          />

          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='auto'
            gap={0.25}
          >
            <Text tag={timestampTag} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>

            <ActionExecutionStatus
              variant='icon'
              status={status}
              retryNumber={attemptNumber}
              expectedExecutionEnd={expectedExecutionEnd}
              error={error}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
      {showTooltip && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <ActionExecutionTooltip
            application={application}
            attemptNumber={attemptNumber}
            error={error}
            executionEnd={executionEnd}
            executionStart={executionStart}
            expectedExecutionEnd={expectedExecutionEnd}
            name={name}
            workflowExecutionSummary={workflowExecutionSummary}
            {...props}
          />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedActionExecutionSubRow = memo(ActionExecutionSubRow);
