import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './globalStyle';
import { ThemeContainerProps } from './types';

type ThemeProps = {
  children: React.ReactNode;
  theme: ThemeContainerProps;
};

export const ThemeContainer = ({ children, theme }: ThemeProps) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
