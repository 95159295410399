import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Container } from '../Container';
import { ObjectGroupCardProps } from './types';
import { Text, TooltipText } from '../Typography';
import { useTheme } from '@morf/theming';
import { Divider } from '../Divider';
import { Button } from '../Button';
import { CopyToClipboard } from '../CopyToClipboard';
import { ObjectStatus } from './ObjectStatus/ObjectStatus';
import { Timestamp } from '../Timestamp';
import { SkeletonLoadable } from '../Skeleton';

export const ObjectGroupCard: FC<ObjectGroupCardProps> = ({
  status,
  objectTypeLink,
  objectType,
  objectDescription,
  objectCategory,
  countOfVariants,
  lastDeployed,
  onClick,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <Container
      width='19.5rem'
      data-testid='object-group-card'
      backgroundColor={theme.colors.ui.card}
      borderRadius={1}
    >
      <Flexbox direction='column' pt={1.5} pb={1} px={1.5}>
        <Flexbox direction='row' justifyContent='flex-end'>
          {status && <ObjectStatus status={status} />}
          <SkeletonLoadable
            isLoading={isLoading}
            width='1.5rem'
            height='1.5rem'
          >
            <CopyToClipboard
              iconName='link'
              iconFill='none'
              iconStroke={theme.colors.main.primary.darker}
              iconViewBox='0 0 24 24'
              iconSize={1}
              text={objectTypeLink}
              label='Copy direct link'
              tooltipWidth='7rem'
              tooltipDirection='right'
            />
          </SkeletonLoadable>
        </Flexbox>
        <Flexbox direction='column' gap={0.25}>
          <SkeletonLoadable isLoading={isLoading} width='10rem' height='1rem'>
            <TooltipText tag='h4'>{objectType}</TooltipText>
          </SkeletonLoadable>
          {objectDescription && (
            <SkeletonLoadable isLoading={isLoading} width='10rem' height='1rem'>
              <TooltipText tag='p2' color={theme.colors.text.muted}>
                {objectDescription}
              </TooltipText>
            </SkeletonLoadable>
          )}
          <Flexbox direction='row' justifyContent='flex-start' gap={0.5}>
            <SkeletonLoadable isLoading={isLoading} width='4rem' height='1rem'>
              <Text tag='p2'>{objectCategory}</Text>
            </SkeletonLoadable>
            <SkeletonLoadable isLoading={isLoading} width='4rem' height='1rem'>
              <Text tag='p2' color={theme.colors.text.muted}>
                {countOfVariants}
              </Text>
            </SkeletonLoadable>
          </Flexbox>
        </Flexbox>
        <SkeletonLoadable isLoading={isLoading} width='10rem' height='1rem'>
          <Timestamp
            tag='p3'
            color={theme.colors.text.muted}
            timestamp={lastDeployed}
          />
        </SkeletonLoadable>
      </Flexbox>
      <Divider />
      <Flexbox justifyContent='center' pt={0.5} pb={1}>
        <SkeletonLoadable isLoading={isLoading} width='4rem' height='1.5rem'>
          <Button variant='tertiary' onClick={onClick}>
            <Text tag='h5' color='inherit'>
              View Mapping
            </Text>
          </Button>
        </SkeletonLoadable>
      </Flexbox>
    </Container>
  );
};
