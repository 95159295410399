export const formatPayload = (payload: string) => {
  try {
    JSON.parse(payload);
  } catch (error) {
    return payload;
  }

  const jsonObject = JSON.parse(payload);
  const formattedJson = JSON.stringify(jsonObject, null, 2);
  return formattedJson;
};
