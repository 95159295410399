'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { google as google$2 } from './empty_ts_proto';
import { google as google$1 } from './struct_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.active_campaign = (() => {
  const active_campaign = {};
  active_campaign.v1 = (() => {
    const v1 = {};
    v1.UpsertContactRequest = (() => {
      class UpsertContactRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertContactRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(10).string(message.customerProvidedId);
          }
          if (
            message.fieldValues != null &&
            Object.hasOwnProperty.call(message, 'fieldValues')
          ) {
            google$1.protobuf.Struct.encode(
              message.fieldValues,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertContactRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 2: {
                message.fieldValues = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.UpsertContactRequest) {
            return object;
          }
          const message = new $root.active_campaign.v1.UpsertContactRequest();
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.fieldValues != null) {
            if (typeof object.fieldValues !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.UpsertContactRequest.fieldValues: object expected, but got ' +
                  typeof object.fieldValues
              );
            }
            message.fieldValues = google$1.protobuf.Struct.fromObject(
              object.fieldValues
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerProvidedId = '';
            object.fieldValues = null;
          }
          let keys;
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (
            message.fieldValues != null &&
            message.hasOwnProperty('fieldValues')
          ) {
            object.fieldValues = google$1.protobuf.Struct.toObject(
              message.fieldValues,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.UpsertContactRequest';
        }
      }

      UpsertContactRequest.prototype.customerProvidedId = '';
      UpsertContactRequest.prototype.fieldValues = null;

      return UpsertContactRequest;
    })();

    v1.UpsertContactResponse = (() => {
      class UpsertContactResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertContactResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.activeCampaignContactId != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignContactId')
          ) {
            writer.uint32(10).string(message.activeCampaignContactId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertContactResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.activeCampaignContactId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.active_campaign.v1.UpsertContactResponse
          ) {
            return object;
          }
          const message = new $root.active_campaign.v1.UpsertContactResponse();
          if (object.activeCampaignContactId != null) {
            message.activeCampaignContactId = String(
              object.activeCampaignContactId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.activeCampaignContactId = '';
          }
          let keys;
          if (
            message.activeCampaignContactId != null &&
            message.hasOwnProperty('activeCampaignContactId')
          ) {
            object.activeCampaignContactId = message.activeCampaignContactId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.UpsertContactResponse';
        }
      }

      UpsertContactResponse.prototype.activeCampaignContactId = '';

      return UpsertContactResponse;
    })();

    v1.TrackEventRequest = (() => {
      class TrackEventRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackEventRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(10).string(message.customerProvidedId);
          }
          if (
            message.eventName != null &&
            Object.hasOwnProperty.call(message, 'eventName')
          ) {
            writer.uint32(18).string(message.eventName);
          }
          if (
            message.eventData != null &&
            Object.hasOwnProperty.call(message, 'eventData')
          ) {
            writer.uint32(26).string(message.eventData);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackEventRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 2: {
                message.eventName = reader.string();
                break;
              }
              case 3: {
                message.eventData = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.TrackEventRequest) {
            return object;
          }
          const message = new $root.active_campaign.v1.TrackEventRequest();
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          if (object.eventData != null) {
            message.eventData = String(object.eventData);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerProvidedId = '';
            object.eventName = '';
            object.eventData = '';
          }
          let keys;
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (
            message.eventName != null &&
            message.hasOwnProperty('eventName')
          ) {
            object.eventName = message.eventName;
          }
          if (
            message.eventData != null &&
            message.hasOwnProperty('eventData')
          ) {
            object.eventData = message.eventData;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.TrackEventRequest';
        }
      }

      TrackEventRequest.prototype.customerProvidedId = '';
      TrackEventRequest.prototype.eventName = '';
      TrackEventRequest.prototype.eventData = '';

      return TrackEventRequest;
    })();

    v1.TrackEventResponse = (() => {
      class TrackEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackEventResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.statusCode != null &&
            Object.hasOwnProperty.call(message, 'statusCode')
          ) {
            writer.uint32(8).int32(message.statusCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.statusCode = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.TrackEventResponse) {
            return object;
          }
          const message = new $root.active_campaign.v1.TrackEventResponse();
          if (object.statusCode != null) {
            message.statusCode = object.statusCode | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.statusCode = 0;
          }
          let keys;
          if (
            message.statusCode != null &&
            message.hasOwnProperty('statusCode')
          ) {
            object.statusCode = message.statusCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.TrackEventResponse';
        }
      }

      TrackEventResponse.prototype.statusCode = 0;

      return TrackEventResponse;
    })();

    v1.GetContactRequest = (() => {
      class GetContactRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetContactRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(10).string(message.customerProvidedId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetContactRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.GetContactRequest) {
            return object;
          }
          const message = new $root.active_campaign.v1.GetContactRequest();
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerProvidedId = '';
          }
          let keys;
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.GetContactRequest';
        }
      }

      GetContactRequest.prototype.customerProvidedId = '';

      return GetContactRequest;
    })();

    v1.GetContactResponse = (() => {
      class GetContactResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetContactResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.activeCampaignContactId != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignContactId')
          ) {
            writer.uint32(10).string(message.activeCampaignContactId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetContactResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.activeCampaignContactId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.GetContactResponse) {
            return object;
          }
          const message = new $root.active_campaign.v1.GetContactResponse();
          if (object.activeCampaignContactId != null) {
            message.activeCampaignContactId = String(
              object.activeCampaignContactId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.activeCampaignContactId = '';
          }
          let keys;
          if (
            message.activeCampaignContactId != null &&
            message.hasOwnProperty('activeCampaignContactId')
          ) {
            object.activeCampaignContactId = message.activeCampaignContactId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.GetContactResponse';
        }
      }

      GetContactResponse.prototype.activeCampaignContactId = '';

      return GetContactResponse;
    })();

    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.active_campaign.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.active_campaign.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_ACTIVE_CAMPAIGN_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'ACTIVE_CAMPAIGN_DEAL_UPDATE':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.active_campaign.v1.ActiveCampaignEventType[
                      message.eventTypes[i]
                    ] === undefined
                    ? message.eventTypes[i]
                    : $root.active_campaign.v1.ActiveCampaignEventType[
                        message.eventTypes[i]
                      ]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Deal = (() => {
      class Deal {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Deal(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.contactId != null &&
            Object.hasOwnProperty.call(message, 'contactId')
          ) {
            writer.uint32(18).string(message.contactId);
          }
          if (
            message.title != null &&
            Object.hasOwnProperty.call(message, 'title')
          ) {
            writer.uint32(26).string(message.title);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(32).int32(message.value);
          }
          if (
            message.currency != null &&
            Object.hasOwnProperty.call(message, 'currency')
          ) {
            writer.uint32(42).string(message.currency);
          }
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            writer.uint32(50).string(message.group);
          }
          if (
            message.stage != null &&
            Object.hasOwnProperty.call(message, 'stage')
          ) {
            writer.uint32(58).string(message.stage);
          }
          if (
            message.owner != null &&
            Object.hasOwnProperty.call(message, 'owner')
          ) {
            writer.uint32(66).string(message.owner);
          }
          if (
            message.fieldValues != null &&
            Object.hasOwnProperty.call(message, 'fieldValues')
          ) {
            google$1.protobuf.Struct.encode(
              message.fieldValues,
              writer.uint32(74).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Deal();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.contactId = reader.string();
                break;
              }
              case 3: {
                message.title = reader.string();
                break;
              }
              case 4: {
                message.value = reader.int32();
                break;
              }
              case 5: {
                message.currency = reader.string();
                break;
              }
              case 6: {
                message.group = reader.string();
                break;
              }
              case 7: {
                message.stage = reader.string();
                break;
              }
              case 8: {
                message.owner = reader.string();
                break;
              }
              case 9: {
                message.fieldValues = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.Deal) {
            return object;
          }
          const message = new $root.active_campaign.v1.Deal();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.contactId != null) {
            message.contactId = String(object.contactId);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          if (object.value != null) {
            message.value = object.value | 0;
          }
          if (object.currency != null) {
            message.currency = String(object.currency);
          }
          if (object.group != null) {
            message.group = String(object.group);
          }
          if (object.stage != null) {
            message.stage = String(object.stage);
          }
          if (object.owner != null) {
            message.owner = String(object.owner);
          }
          if (object.fieldValues != null) {
            if (typeof object.fieldValues !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.Deal.fieldValues: object expected, but got ' +
                  typeof object.fieldValues
              );
            }
            message.fieldValues = google$1.protobuf.Struct.fromObject(
              object.fieldValues
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.contactId = '';
            object.title = '';
            object.value = 0;
            object.currency = '';
            object.group = '';
            object.stage = '';
            object.owner = '';
            object.fieldValues = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.contactId != null &&
            message.hasOwnProperty('contactId')
          ) {
            object.contactId = message.contactId;
          }
          if (message.title != null && message.hasOwnProperty('title')) {
            object.title = message.title;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          if (message.currency != null && message.hasOwnProperty('currency')) {
            object.currency = message.currency;
          }
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = message.group;
          }
          if (message.stage != null && message.hasOwnProperty('stage')) {
            object.stage = message.stage;
          }
          if (message.owner != null && message.hasOwnProperty('owner')) {
            object.owner = message.owner;
          }
          if (
            message.fieldValues != null &&
            message.hasOwnProperty('fieldValues')
          ) {
            object.fieldValues = google$1.protobuf.Struct.toObject(
              message.fieldValues,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.Deal';
        }
      }

      Deal.prototype.id = '';
      Deal.prototype.contactId = '';
      Deal.prototype.title = '';
      Deal.prototype.value = 0;
      Deal.prototype.currency = '';
      Deal.prototype.group = '';
      Deal.prototype.stage = '';
      Deal.prototype.owner = '';
      Deal.prototype.fieldValues = null;

      return Deal;
    })();

    v1.CreateDealRequest = (() => {
      class CreateDealRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDealRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.contactId != null &&
            Object.hasOwnProperty.call(message, 'contactId')
          ) {
            writer.uint32(10).string(message.contactId);
          }
          if (
            message.title != null &&
            Object.hasOwnProperty.call(message, 'title')
          ) {
            writer.uint32(18).string(message.title);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(24).int32(message.value);
          }
          if (
            message.currency != null &&
            Object.hasOwnProperty.call(message, 'currency')
          ) {
            writer.uint32(34).string(message.currency);
          }
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            writer.uint32(42).string(message.group);
          }
          if (
            message.stage != null &&
            Object.hasOwnProperty.call(message, 'stage')
          ) {
            writer.uint32(50).string(message.stage);
          }
          if (
            message.owner != null &&
            Object.hasOwnProperty.call(message, 'owner')
          ) {
            writer.uint32(58).string(message.owner);
          }
          if (
            message.fieldValues != null &&
            Object.hasOwnProperty.call(message, 'fieldValues')
          ) {
            google$1.protobuf.Struct.encode(
              message.fieldValues,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDealRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.contactId = reader.string();
                break;
              }
              case 2: {
                message.title = reader.string();
                break;
              }
              case 3: {
                message.value = reader.int32();
                break;
              }
              case 4: {
                message.currency = reader.string();
                break;
              }
              case 5: {
                message.group = reader.string();
                break;
              }
              case 6: {
                message.stage = reader.string();
                break;
              }
              case 7: {
                message.owner = reader.string();
                break;
              }
              case 8: {
                message.fieldValues = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.CreateDealRequest) {
            return object;
          }
          const message = new $root.active_campaign.v1.CreateDealRequest();
          if (object.contactId != null) {
            message.contactId = String(object.contactId);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          if (object.value != null) {
            message.value = object.value | 0;
          }
          if (object.currency != null) {
            message.currency = String(object.currency);
          }
          if (object.group != null) {
            message.group = String(object.group);
          }
          if (object.stage != null) {
            message.stage = String(object.stage);
          }
          if (object.owner != null) {
            message.owner = String(object.owner);
          }
          if (object.fieldValues != null) {
            if (typeof object.fieldValues !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.CreateDealRequest.fieldValues: object expected, but got ' +
                  typeof object.fieldValues
              );
            }
            message.fieldValues = google$1.protobuf.Struct.fromObject(
              object.fieldValues
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.contactId = '';
            object.title = '';
            object.value = 0;
            object.currency = '';
            object.group = '';
            object.stage = '';
            object.owner = '';
            object.fieldValues = null;
          }
          let keys;
          if (
            message.contactId != null &&
            message.hasOwnProperty('contactId')
          ) {
            object.contactId = message.contactId;
          }
          if (message.title != null && message.hasOwnProperty('title')) {
            object.title = message.title;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          if (message.currency != null && message.hasOwnProperty('currency')) {
            object.currency = message.currency;
          }
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = message.group;
          }
          if (message.stage != null && message.hasOwnProperty('stage')) {
            object.stage = message.stage;
          }
          if (message.owner != null && message.hasOwnProperty('owner')) {
            object.owner = message.owner;
          }
          if (
            message.fieldValues != null &&
            message.hasOwnProperty('fieldValues')
          ) {
            object.fieldValues = google$1.protobuf.Struct.toObject(
              message.fieldValues,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.CreateDealRequest';
        }
      }

      CreateDealRequest.prototype.contactId = '';
      CreateDealRequest.prototype.title = '';
      CreateDealRequest.prototype.value = 0;
      CreateDealRequest.prototype.currency = '';
      CreateDealRequest.prototype.group = '';
      CreateDealRequest.prototype.stage = '';
      CreateDealRequest.prototype.owner = '';
      CreateDealRequest.prototype.fieldValues = null;

      return CreateDealRequest;
    })();

    v1.CreateDealResponse = (() => {
      class CreateDealResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDealResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.deal != null &&
            Object.hasOwnProperty.call(message, 'deal')
          ) {
            $root.active_campaign.v1.Deal.encode(
              message.deal,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDealResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.deal = $root.active_campaign.v1.Deal.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.CreateDealResponse) {
            return object;
          }
          const message = new $root.active_campaign.v1.CreateDealResponse();
          if (object.deal != null) {
            if (typeof object.deal !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.CreateDealResponse.deal: object expected, but got ' +
                  typeof object.deal
              );
            }
            message.deal = $root.active_campaign.v1.Deal.fromObject(
              object.deal
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.deal = null;
          }
          let keys;
          if (message.deal != null && message.hasOwnProperty('deal')) {
            object.deal = $root.active_campaign.v1.Deal.toObject(
              message.deal,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.CreateDealResponse';
        }
      }

      CreateDealResponse.prototype.deal = null;

      return CreateDealResponse;
    })();

    v1.UpdateDealRequest = (() => {
      class UpdateDealRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateDealRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.dealId != null &&
            Object.hasOwnProperty.call(message, 'dealId')
          ) {
            writer.uint32(10).string(message.dealId);
          }
          if (
            message.title != null &&
            Object.hasOwnProperty.call(message, 'title')
          ) {
            writer.uint32(18).string(message.title);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(24).int32(message.value);
          }
          if (
            message.currency != null &&
            Object.hasOwnProperty.call(message, 'currency')
          ) {
            writer.uint32(34).string(message.currency);
          }
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            writer.uint32(42).string(message.group);
          }
          if (
            message.stage != null &&
            Object.hasOwnProperty.call(message, 'stage')
          ) {
            writer.uint32(50).string(message.stage);
          }
          if (
            message.owner != null &&
            Object.hasOwnProperty.call(message, 'owner')
          ) {
            writer.uint32(58).string(message.owner);
          }
          if (
            message.fieldValues != null &&
            Object.hasOwnProperty.call(message, 'fieldValues')
          ) {
            google$1.protobuf.Struct.encode(
              message.fieldValues,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateDealRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.dealId = reader.string();
                break;
              }
              case 2: {
                message.title = reader.string();
                break;
              }
              case 3: {
                message.value = reader.int32();
                break;
              }
              case 4: {
                message.currency = reader.string();
                break;
              }
              case 5: {
                message.group = reader.string();
                break;
              }
              case 6: {
                message.stage = reader.string();
                break;
              }
              case 7: {
                message.owner = reader.string();
                break;
              }
              case 8: {
                message.fieldValues = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.UpdateDealRequest) {
            return object;
          }
          const message = new $root.active_campaign.v1.UpdateDealRequest();
          if (object.dealId != null) {
            message.dealId = String(object.dealId);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          if (object.value != null) {
            message.value = object.value | 0;
          }
          if (object.currency != null) {
            message.currency = String(object.currency);
          }
          if (object.group != null) {
            message.group = String(object.group);
          }
          if (object.stage != null) {
            message.stage = String(object.stage);
          }
          if (object.owner != null) {
            message.owner = String(object.owner);
          }
          if (object.fieldValues != null) {
            if (typeof object.fieldValues !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.UpdateDealRequest.fieldValues: object expected, but got ' +
                  typeof object.fieldValues
              );
            }
            message.fieldValues = google$1.protobuf.Struct.fromObject(
              object.fieldValues
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.dealId = '';
          }
          let keys;
          if (message.dealId != null && message.hasOwnProperty('dealId')) {
            object.dealId = message.dealId;
          }
          if (message.title != null && message.hasOwnProperty('title')) {
            object.title = message.title;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          if (message.currency != null && message.hasOwnProperty('currency')) {
            object.currency = message.currency;
          }
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = message.group;
          }
          if (message.stage != null && message.hasOwnProperty('stage')) {
            object.stage = message.stage;
          }
          if (message.owner != null && message.hasOwnProperty('owner')) {
            object.owner = message.owner;
          }
          if (
            message.fieldValues != null &&
            message.hasOwnProperty('fieldValues')
          ) {
            object.fieldValues = google$1.protobuf.Struct.toObject(
              message.fieldValues,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.UpdateDealRequest';
        }
      }

      UpdateDealRequest.prototype.dealId = '';
      UpdateDealRequest.prototype.title = null;
      UpdateDealRequest.prototype.value = null;
      UpdateDealRequest.prototype.currency = null;
      UpdateDealRequest.prototype.group = null;
      UpdateDealRequest.prototype.stage = null;
      UpdateDealRequest.prototype.owner = null;
      UpdateDealRequest.prototype.fieldValues = null;

      return UpdateDealRequest;
    })();

    v1.UpdateDealResponse = (() => {
      class UpdateDealResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateDealResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.deal != null &&
            Object.hasOwnProperty.call(message, 'deal')
          ) {
            $root.active_campaign.v1.Deal.encode(
              message.deal,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateDealResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.deal = $root.active_campaign.v1.Deal.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.UpdateDealResponse) {
            return object;
          }
          const message = new $root.active_campaign.v1.UpdateDealResponse();
          if (object.deal != null) {
            if (typeof object.deal !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.UpdateDealResponse.deal: object expected, but got ' +
                  typeof object.deal
              );
            }
            message.deal = $root.active_campaign.v1.Deal.fromObject(
              object.deal
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.deal = null;
          }
          let keys;
          if (message.deal != null && message.hasOwnProperty('deal')) {
            object.deal = $root.active_campaign.v1.Deal.toObject(
              message.deal,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.UpdateDealResponse';
        }
      }

      UpdateDealResponse.prototype.deal = null;

      return UpdateDealResponse;
    })();

    v1.ListContactsCustomFieldsRequest = (() => {
      class ListContactsCustomFieldsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListContactsCustomFieldsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$2.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListContactsCustomFieldsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$2.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.active_campaign.v1.ListContactsCustomFieldsRequest
          ) {
            return object;
          }
          const message =
            new $root.active_campaign.v1.ListContactsCustomFieldsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.active_campaign.v1.ListContactsCustomFieldsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$2.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$2.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'active_campaign.v1.ListContactsCustomFieldsRequest'
          );
        }
      }

      ListContactsCustomFieldsRequest.prototype.stub = null;

      return ListContactsCustomFieldsRequest;
    })();

    v1.ListContactsCustomFieldsResponse = (() => {
      class ListContactsCustomFieldsResponse {
        constructor(properties) {
          this.customFields = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListContactsCustomFieldsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customFields != null &&
            Object.hasOwnProperty.call(message, 'customFields')
          ) {
            for (const element of message.customFields) {
              $root.active_campaign.v1.ContactCustomField.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListContactsCustomFieldsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.customFields || !message.customFields.length) {
                  message.customFields = [];
                }
                message.customFields.push(
                  $root.active_campaign.v1.ContactCustomField.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.active_campaign.v1.ListContactsCustomFieldsResponse
          ) {
            return object;
          }
          const message =
            new $root.active_campaign.v1.ListContactsCustomFieldsResponse();
          if (object.customFields) {
            if (!Array.isArray(object.customFields)) {
              throw new TypeError(
                '.active_campaign.v1.ListContactsCustomFieldsResponse.customFields: array type expected, but got ' +
                  typeof object.customFields
              );
            }
            message.customFields = new Array(object.customFields.length);
            for (let i = 0; i < object.customFields.length; ++i) {
              if (typeof object.customFields[i] !== 'object') {
                throw new TypeError(
                  '.active_campaign.v1.ListContactsCustomFieldsResponse.customFields: object expected, but got ' +
                    typeof object.customFields[i]
                );
              }
              message.customFields[i] =
                $root.active_campaign.v1.ContactCustomField.fromObject(
                  object.customFields[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.customFields = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.customFields && message.customFields.length) {
            object.customFields = new Array(message.customFields.length);
            for (let i = 0; i < message.customFields.length; ++i) {
              object.customFields[i] =
                $root.active_campaign.v1.ContactCustomField.toObject(
                  message.customFields[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'active_campaign.v1.ListContactsCustomFieldsResponse'
          );
        }
      }

      ListContactsCustomFieldsResponse.prototype.customFields =
        $util.emptyArray;

      return ListContactsCustomFieldsResponse;
    })();

    v1.ContactCustomField = (() => {
      class ContactCustomField {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ContactCustomField(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.title != null &&
            Object.hasOwnProperty.call(message, 'title')
          ) {
            writer.uint32(18).string(message.title);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ContactCustomField();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.title = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.active_campaign.v1.ContactCustomField) {
            return object;
          }
          const message = new $root.active_campaign.v1.ContactCustomField();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.title = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.title != null && message.hasOwnProperty('title')) {
            object.title = message.title;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'active_campaign.v1.ContactCustomField';
        }
      }

      ContactCustomField.prototype.id = '';
      ContactCustomField.prototype.title = '';
      ContactCustomField.prototype.description = null;

      return ContactCustomField;
    })();

    v1.ActiveCampaignEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[
        (valuesById[0] = 'UNSPECIFIED_ILLEGAL_ACTIVE_CAMPAIGN_EVENT_TYPE')
      ] = 0;
      values[(valuesById[1] = 'ACTIVE_CAMPAIGN_DEAL_UPDATE')] = 1;
      return values;
    })();

    v1.ActiveCampaignService = (() => {
      class ActiveCampaignService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new ActiveCampaignService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (ActiveCampaignService.prototype.listContactsCustomFields =
          function listContactsCustomFields(request, callback) {
            return this.rpcCall(
              listContactsCustomFields,
              $root.active_campaign.v1.ListContactsCustomFieldsRequest,
              $root.active_campaign.v1.ListContactsCustomFieldsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListContactsCustomFields' }
      );

      return ActiveCampaignService;
    })();

    return v1;
  })();

  return active_campaign;
})();

export const active_campaign = $root.active_campaign;
