import { useState } from 'react';
import { Button } from '../../Button';
import { Container } from '../../Container';
import { Dropdown } from '../../Dropdown';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Typography';
import { Option } from '../../Dropdown/types';
import { EditPropertyMappingModalProps } from './types';
import { useTheme } from '@morf/theming';

export const EditPropertyMappingModal: React.FC<
  EditPropertyMappingModalProps
> = ({ properties, onSave }) => {
  const [selectedProperty, setSelectedProperty] = useState<Option[]>([]);
  const theme = useTheme();
  return (
    <Flexbox
      direction='column'
      p={1.5}
      backgroundColor={theme.colors.ui.card}
      width='26.25rem'
      borderRadius={1}
      gap={1.5}
    >
      <Flexbox direction='column' gap={0.5}>
        <Flexbox alignItems='center'>
          <Text tag='h4'>Map to:</Text>
          <Button
            variant='tertiary'
            onClick={() => onSave(selectedProperty)}
            isDisabled={!selectedProperty.length}
          >
            <Text tag='h4' color='inherit'>
              Save Changes
            </Text>
          </Button>
        </Flexbox>

        <Text tag='h5'>Select Property</Text>
        <Container width='100%'>
          <Dropdown
            placeholder='Select one'
            options={properties.map((property) => ({
              label: property.label,
              value: property.id,
            }))}
            onChange={(selectedOptions) => setSelectedProperty(selectedOptions)}
            searchable
          />
        </Container>
      </Flexbox>
    </Flexbox>
  );
};
