import { Container } from '../../Container';
import { FC } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { IntegrationIcon } from '../../IntegrationIcon';
import { ParameterLabel } from './ParameterLabel';
import { ParameterTagProps } from './types';
import { useHover } from '../../Hooks/useHover';

export const ParameterTag: FC<ParameterTagProps> = ({
  backgroundColor,
  iconColor,
  iconName,
  integrationIconName,
  isReadOnly,
  label,
  leafFontColor,
  objectFontColor,
  wordWrap,
}) => {
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();
  const isHoveredAllowed = isReadOnly ? false : isHovered;

  const iconColorStyle = isHoveredAllowed ? `${iconColor}80` : iconColor;
  const objectFontColorStyle = objectFontColor
    ? isHoveredAllowed
      ? `${objectFontColor}80`
      : objectFontColor
    : undefined;
  const leafFontColorStyle = isHoveredAllowed
    ? `${leafFontColor}80`
    : leafFontColor;

  return (
    <Flexbox
      data-testid='parameter-tag'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      width='auto'
      height='auto'
      gap={0.25}
      borderRadius={0.25}
      px={0.5}
      py={0.25}
      overflow='scroll'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Container height='auto' width='auto'>
        {integrationIconName && <IntegrationIcon name={integrationIconName} />}
        {iconName && iconColor && (
          <Icon
            name={iconName}
            stroke={iconColorStyle}
            size={1}
            strokeWidth={2}
          />
        )}
      </Container>

      <ParameterLabel
        label={label}
        leafFontColor={leafFontColorStyle}
        objectFontColor={objectFontColorStyle}
        wordWrap={wordWrap}
      />
    </Flexbox>
  );
};
