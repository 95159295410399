import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { PatientProfileHeaderProps } from './types';
import { SkeletonLoadable } from '../Skeleton';
import { CopyToClipboard } from '../CopyToClipboard';
import { Timestamp } from '../Timestamp';
import { InfoBlock } from '../InfoBlock';
import { ProfileImage } from '../ProfileImage';

export const PatientProfileHeader: FC<PatientProfileHeaderProps> = ({
  id,
  name,
  imageUrl,
  age,
  dob,
  sex,
  gender,
  createdAt,
  isLoading,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      px={2}
      py={1}
      justifyContent='flex-end'
      alignItems='center'
      height='auto'
      backgroundColor={theme.colors.ui.card}
    >
      <SkeletonLoadable isLoading={isLoading} height='5rem'>
        <ProfileImage imageUrl={imageUrl} name={name} />
      </SkeletonLoadable>
      <Flexbox
        direction='row'
        gap={0.25}
        justifyContent='flex-start'
        height='auto'
      >
        <Flexbox direction='column' gap={0.25}>
          <SkeletonLoadable isLoading={isLoading} height='2rem'>
            <Text tag='h2'>{name}</Text>
          </SkeletonLoadable>
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            height='auto'
            width='auto'
          >
            <InfoBlock isLoading={isLoading} label='Age' direction='column'>
              <Text tag='p2'>{age}</Text>
            </InfoBlock>
            <InfoBlock isLoading={isLoading} label='DOB' direction='column'>
              <Text tag='p2'>{dob}</Text>
            </InfoBlock>
            <InfoBlock isLoading={isLoading} label='Sex' direction='column'>
              <Text tag='p2'>{sex}</Text>
            </InfoBlock>
            <InfoBlock
              isLoading={isLoading}
              label='Gender Identity'
              direction='column'
            >
              <Text tag='p2'>{gender}</Text>
            </InfoBlock>
          </Flexbox>
        </Flexbox>

        <Flexbox justifyContent='flex-end' width='auto'>
          <Flexbox justifyContent='flex-start' alignItems='center' gap={0.25}>
            <Text tag='p2' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {id}
            </Text>
            <CopyToClipboard
              text={id}
              label='Copy patient id'
              tooltipDirection='left'
            />
          </Flexbox>
          <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
            <Timestamp
              tag='p2'
              tooltipDirection='left'
              whiteSpace='nowrap'
              color={theme.colors.text.muted}
              timestamp={createdAt}
            />
          </SkeletonLoadable>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
