import { FC, memo, Suspense, useEffect, useState } from 'react';
import { ReactQueryDevtoolsProduction } from './ReactQueryDevtoolsProduction';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePermissions } from '../../../components/context/permissions/usePermissions';

declare global {
  interface Window {
    toggleDevtools?: () => void;
  }
}

const DevTools: FC = () => {
  const { isMorfInternalUser } = usePermissions();
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    if (isMorfInternalUser) {
      window.toggleDevtools = () => setShowDevtools((old) => !old);
    }
    return () => {
      if (window.toggleDevtools) {
        delete window.toggleDevtools;
      }
    };
  }, [isMorfInternalUser]);

  return showDevtools ? (
    <Suspense fallback={null}>
      <ReactQueryDevtools initialIsOpen />
      <ReactQueryDevtoolsProduction />
    </Suspense>
  ) : (
    <ReactQueryDevtools initialIsOpen />
  );
};

export const MemoizedDevTools = memo(DevTools);
