import { Button } from '../../Button';
import { Container } from '../../Container';
import { DropdownOptionProps } from './types';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { useCallback } from 'react';
import { useTheme } from '@morf/theming';

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  isSelected,
  multiSelect,
  onChange,
  option,
  readOnly,
}) => {
  const theme = useTheme();

  const handleOptionClick = useCallback(() => {
    !readOnly && onChange(option);
  }, [onChange, option]);

  return (
    <Container
      data-testid={'dropdown-option-' + option.value}
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      borderRadius={theme.input.borderRadius}
      cursor={readOnly ? 'not-allowed' : 'default'}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={handleOptionClick}
      p={0.5}
    >
      {multiSelect ? (
        <Flexbox justifyContent='flex-start' alignItems='center' gap={0.25}>
          <Button
            borderRadius={0.25}
            isDisabled={readOnly}
            size='xs'
            type='button'
            variant={isSelected ? 'primary' : 'secondary'}
          >
            <Icon
              size={0.75}
              name='check'
              stroke={theme.colors.text.inverse}
              strokeWidth={1.75}
            />
          </Button>
          <Text tag='p2'>{option.label}</Text>
        </Flexbox>
      ) : (
        <Text tag='p2'>{option.label}</Text>
      )}
    </Container>
  );
};
