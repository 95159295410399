import { temporalLink } from './environment';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

export const getDefaultTemporalPath = (
  temporalWorkflowExecution: workflow_monitoring.v2.TemporalWorkflowExecution
): string => {
  const temporalWorkflowId = temporalWorkflowExecution?.temporalWorkflowId;
  const temporalWorkflowRunId =
    temporalWorkflowExecution?.temporalWorkflowRunId;

  const encodedWorkflowId = encodeURIComponent(temporalWorkflowId);
  return `https://${temporalLink}.morf.healthcare/temporal/namespaces/default/workflows/${encodedWorkflowId}/${temporalWorkflowRunId}/history`;
};
