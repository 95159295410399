import { FC } from 'react';
import { Button } from '../../Button';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { AddOptionProps } from './types';

export const AddOption: FC<AddOptionProps> = ({ value, onClick }) => {
  const trimmedValue = value.trim();

  const handleClick = () => {
    const newOption = {
      label: trimmedValue,
      value: trimmedValue,
    };

    onClick(newOption);
  };

  return (
    <Button
      variant='tertiary'
      onClick={handleClick}
      isDisabled={!trimmedValue}
      width='100%'
    >
      <Flexbox
        direction='row'
        gap={0.5}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Icon name='plus' size={1.25} />
        <Text color='inherit' tag='h5'>
          {'Add ' + trimmedValue}
        </Text>
      </Flexbox>
    </Button>
  );
};
