import React, { useState } from 'react';
import { PropertyMappingModalProps } from './types';
import { ViewPropertyMappingModal } from './ViewPropertyMappingModal/ViewPropertyMappingModal';
import { EditPropertyMappingModal } from './EditPropertyMappingModal/EditPropertyMappingModal';
import { Container } from '../Container';

export const PropertyMappingModal: React.FC<PropertyMappingModalProps> = ({
  currentProperty,
  properties,
  onSave,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <Container onClick={(e) => e.stopPropagation()}>
      {editMode ? (
        <EditPropertyMappingModal onSave={onSave} properties={properties} />
      ) : (
        <ViewPropertyMappingModal
          currentProperty={currentProperty}
          onEdit={() => {
            setEditMode(true);
          }}
        />
      )}
    </Container>
  );
};
