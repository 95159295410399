'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { phonenumbers as phonenumbers$1 } from './phonenumbers_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.intakeq = (() => {
  const intakeq = {};
  intakeq.v1 = (() => {
    const v1 = {};
    v1.GetClientRequest = (() => {
      class GetClientRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetClientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.clientId != null &&
            Object.hasOwnProperty.call(message, 'clientId')
          ) {
            writer.uint32(8).int64(message.clientId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetClientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientId = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.GetClientRequest) {
            return object;
          }
          const message = new $root.intakeq.v1.GetClientRequest();
          if (object.clientId != null) {
            if ($util.Long) {
              (message.clientId = $util.Long.fromValue(
                object.clientId
              )).unsigned = false;
            } else if (typeof object.clientId === 'string') {
              message.clientId = parseInt(object.clientId, 10);
            } else if (typeof object.clientId === 'number') {
              message.clientId = object.clientId;
            } else if (typeof object.clientId === 'object') {
              message.clientId = new $util.LongBits(
                object.clientId.low >>> 0,
                object.clientId.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.clientId =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.clientId = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.clientId != null && message.hasOwnProperty('clientId')) {
            object.clientId =
              typeof message.clientId === 'number'
                ? options.longs === String
                  ? String(message.clientId)
                  : message.clientId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.clientId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.clientId.low >>> 0,
                    message.clientId.high >>> 0
                  ).toNumber()
                : message.clientId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.GetClientRequest';
        }
      }

      GetClientRequest.prototype.clientId = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return GetClientRequest;
    })();

    v1.GetClientResponse = (() => {
      class GetClientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetClientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.client != null &&
            Object.hasOwnProperty.call(message, 'client')
          ) {
            $root.intakeq.v1.Client.encode(
              message.client,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetClientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.client = $root.intakeq.v1.Client.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.GetClientResponse) {
            return object;
          }
          const message = new $root.intakeq.v1.GetClientResponse();
          if (object.client != null) {
            if (typeof object.client !== 'object') {
              throw new TypeError(
                '.intakeq.v1.GetClientResponse.client: object expected, but got ' +
                  typeof object.client
              );
            }
            message.client = $root.intakeq.v1.Client.fromObject(object.client);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.client = null;
          }
          let keys;
          if (message.client != null && message.hasOwnProperty('client')) {
            object.client = $root.intakeq.v1.Client.toObject(
              message.client,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.GetClientResponse';
        }
      }

      GetClientResponse.prototype.client = null;

      return GetClientResponse;
    })();

    v1.UpsertClientRequest = (() => {
      class UpsertClientRequest {
        constructor(properties) {
          this.customFields = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertClientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.clientId != null &&
            Object.hasOwnProperty.call(message, 'clientId')
          ) {
            writer.uint32(8).int64(message.clientId);
          }
          if (
            message.externalClientId != null &&
            Object.hasOwnProperty.call(message, 'externalClientId')
          ) {
            writer.uint32(18).string(message.externalClientId);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(26).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(34).string(message.lastName);
          }
          if (
            message.emailAddress != null &&
            Object.hasOwnProperty.call(message, 'emailAddress')
          ) {
            writer.uint32(42).string(message.emailAddress);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            phonenumbers$1.PhoneNumber.encode(
              message.phoneNumber,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.primaryInsuranceCompany != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuranceCompany')
          ) {
            writer.uint32(58).string(message.primaryInsuranceCompany);
          }
          if (
            message.state != null &&
            Object.hasOwnProperty.call(message, 'state')
          ) {
            writer.uint32(66).string(message.state);
          }
          if (
            message.customFields != null &&
            Object.hasOwnProperty.call(message, 'customFields')
          ) {
            for (const key of Object.keys(message.customFields)) {
              writer
                .uint32(74)
                .fork()
                .uint32(10)
                .string(key)
                .uint32(18)
                .string(message.customFields[key])
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertClientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientId = reader.int64();
                break;
              }
              case 2: {
                message.externalClientId = reader.string();
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.lastName = reader.string();
                break;
              }
              case 5: {
                message.emailAddress = reader.string();
                break;
              }
              case 6: {
                message.phoneNumber = phonenumbers$1.PhoneNumber.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.primaryInsuranceCompany = reader.string();
                break;
              }
              case 8: {
                message.state = reader.string();
                break;
              }
              case 9: {
                if (message.customFields === $util.emptyObject)
                  message.customFields = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = '';
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.customFields[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.UpsertClientRequest) {
            return object;
          }
          const message = new $root.intakeq.v1.UpsertClientRequest();
          if (object.clientId != null) {
            if ($util.Long) {
              (message.clientId = $util.Long.fromValue(
                object.clientId
              )).unsigned = false;
            } else if (typeof object.clientId === 'string') {
              message.clientId = parseInt(object.clientId, 10);
            } else if (typeof object.clientId === 'number') {
              message.clientId = object.clientId;
            } else if (typeof object.clientId === 'object') {
              message.clientId = new $util.LongBits(
                object.clientId.low >>> 0,
                object.clientId.high >>> 0
              ).toNumber();
            }
          }
          if (object.externalClientId != null) {
            message.externalClientId = String(object.externalClientId);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== 'object') {
              throw new TypeError(
                '.intakeq.v1.UpsertClientRequest.phoneNumber: object expected, but got ' +
                  typeof object.phoneNumber
              );
            }
            message.phoneNumber = phonenumbers$1.PhoneNumber.fromObject(
              object.phoneNumber
            );
          }
          if (object.primaryInsuranceCompany != null) {
            message.primaryInsuranceCompany = String(
              object.primaryInsuranceCompany
            );
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.customFields) {
            if (typeof object.customFields !== 'object') {
              throw new TypeError(
                '.intakeq.v1.UpsertClientRequest.customFields: object expected, but got ' +
                  typeof object.customFields
              );
            }
            message.customFields = {};
            for (
              let keys = Object.keys(object.customFields), i = 0;
              i < keys.length;
              ++i
            ) {
              message.customFields[keys[i]] = String(
                object.customFields[keys[i]]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.customFields = {};
          }
          if (options.defaults) {
            object.externalClientId = '';
            object.firstName = '';
            object.lastName = '';
            object.emailAddress = '';
            object.phoneNumber = null;
          }
          let keys;
          if (message.clientId != null && message.hasOwnProperty('clientId')) {
            object.clientId =
              typeof message.clientId === 'number'
                ? options.longs === String
                  ? String(message.clientId)
                  : message.clientId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.clientId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.clientId.low >>> 0,
                    message.clientId.high >>> 0
                  ).toNumber()
                : message.clientId;
          }
          if (
            message.externalClientId != null &&
            message.hasOwnProperty('externalClientId')
          ) {
            object.externalClientId = message.externalClientId;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (
            message.emailAddress != null &&
            message.hasOwnProperty('emailAddress')
          ) {
            object.emailAddress = message.emailAddress;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = phonenumbers$1.PhoneNumber.toObject(
              message.phoneNumber,
              options
            );
          }
          if (
            message.primaryInsuranceCompany != null &&
            message.hasOwnProperty('primaryInsuranceCompany')
          ) {
            object.primaryInsuranceCompany = message.primaryInsuranceCompany;
          }
          if (message.state != null && message.hasOwnProperty('state')) {
            object.state = message.state;
          }
          if (
            message.customFields &&
            (keys = Object.keys(message.customFields)).length
          ) {
            object.customFields = {};
            for (let i = 0; i < keys.length; ++i) {
              object.customFields[keys[i]] = message.customFields[keys[i]];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.UpsertClientRequest';
        }
      }

      UpsertClientRequest.prototype.clientId = null;
      UpsertClientRequest.prototype.externalClientId = '';
      UpsertClientRequest.prototype.firstName = '';
      UpsertClientRequest.prototype.lastName = '';
      UpsertClientRequest.prototype.emailAddress = '';
      UpsertClientRequest.prototype.phoneNumber = null;
      UpsertClientRequest.prototype.primaryInsuranceCompany = null;
      UpsertClientRequest.prototype.state = null;
      UpsertClientRequest.prototype.customFields = $util.emptyObject;

      return UpsertClientRequest;
    })();

    v1.UpsertClientResponse = (() => {
      class UpsertClientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertClientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.client != null &&
            Object.hasOwnProperty.call(message, 'client')
          ) {
            $root.intakeq.v1.Client.encode(
              message.client,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertClientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.client = $root.intakeq.v1.Client.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.UpsertClientResponse) {
            return object;
          }
          const message = new $root.intakeq.v1.UpsertClientResponse();
          if (object.client != null) {
            if (typeof object.client !== 'object') {
              throw new TypeError(
                '.intakeq.v1.UpsertClientResponse.client: object expected, but got ' +
                  typeof object.client
              );
            }
            message.client = $root.intakeq.v1.Client.fromObject(object.client);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.client = null;
          }
          let keys;
          if (message.client != null && message.hasOwnProperty('client')) {
            object.client = $root.intakeq.v1.Client.toObject(
              message.client,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.UpsertClientResponse';
        }
      }

      UpsertClientResponse.prototype.client = null;

      return UpsertClientResponse;
    })();

    v1.UpdateClientTagRequest = (() => {
      class UpdateClientTagRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateClientTagRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.clientId != null &&
            Object.hasOwnProperty.call(message, 'clientId')
          ) {
            writer.uint32(8).int64(message.clientId);
          }
          if (
            message.tag != null &&
            Object.hasOwnProperty.call(message, 'tag')
          ) {
            writer.uint32(18).string(message.tag);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateClientTagRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientId = reader.int64();
                break;
              }
              case 2: {
                message.tag = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.UpdateClientTagRequest) {
            return object;
          }
          const message = new $root.intakeq.v1.UpdateClientTagRequest();
          if (object.clientId != null) {
            if ($util.Long) {
              (message.clientId = $util.Long.fromValue(
                object.clientId
              )).unsigned = false;
            } else if (typeof object.clientId === 'string') {
              message.clientId = parseInt(object.clientId, 10);
            } else if (typeof object.clientId === 'number') {
              message.clientId = object.clientId;
            } else if (typeof object.clientId === 'object') {
              message.clientId = new $util.LongBits(
                object.clientId.low >>> 0,
                object.clientId.high >>> 0
              ).toNumber();
            }
          }
          if (object.tag != null) {
            message.tag = String(object.tag);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.clientId =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.clientId = options.longs === String ? '0' : 0;
            }
            object.tag = '';
          }
          let keys;
          if (message.clientId != null && message.hasOwnProperty('clientId')) {
            object.clientId =
              typeof message.clientId === 'number'
                ? options.longs === String
                  ? String(message.clientId)
                  : message.clientId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.clientId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.clientId.low >>> 0,
                    message.clientId.high >>> 0
                  ).toNumber()
                : message.clientId;
          }
          if (message.tag != null && message.hasOwnProperty('tag')) {
            object.tag = message.tag;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.UpdateClientTagRequest';
        }
      }

      UpdateClientTagRequest.prototype.clientId = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      UpdateClientTagRequest.prototype.tag = '';

      return UpdateClientTagRequest;
    })();

    v1.UpdateClientTagResponse = (() => {
      class UpdateClientTagResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateClientTagResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateClientTagResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.UpdateClientTagResponse) {
            return object;
          }
          const message = new $root.intakeq.v1.UpdateClientTagResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.UpdateClientTagResponse';
        }
      }

      return UpdateClientTagResponse;
    })();

    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.intakeq.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.intakeq.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'INTAKEQ_APPOINTMENT_SCHEDULED':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'INTAKEQ_APPOINTMENT_CANCELLED':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.intakeq.v1.IntakeQEventType[message.eventTypes[i]] ===
                    undefined
                    ? message.eventTypes[i]
                    : $root.intakeq.v1.IntakeQEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Appointment = (() => {
      class Appointment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Appointment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointmentId != null &&
            Object.hasOwnProperty.call(message, 'appointmentId')
          ) {
            writer.uint32(10).string(message.appointmentId);
          }
          if (
            message.appointmentEmailAddress != null &&
            Object.hasOwnProperty.call(message, 'appointmentEmailAddress')
          ) {
            writer.uint32(18).string(message.appointmentEmailAddress);
          }
          if (
            message.appointmentPhoneNumber != null &&
            Object.hasOwnProperty.call(message, 'appointmentPhoneNumber')
          ) {
            writer.uint32(26).string(message.appointmentPhoneNumber);
          }
          if (
            message.appointmentTypeId != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeId')
          ) {
            writer.uint32(34).string(message.appointmentTypeId);
          }
          if (
            message.appointmentTypeTitle != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeTitle')
          ) {
            writer.uint32(42).string(message.appointmentTypeTitle);
          }
          if (
            message.appointmentClientConfirmed != null &&
            Object.hasOwnProperty.call(message, 'appointmentClientConfirmed')
          ) {
            writer.uint32(48).bool(message.appointmentClientConfirmed);
          }
          if (
            message.appointmentLocationDetail != null &&
            Object.hasOwnProperty.call(message, 'appointmentLocationDetail')
          ) {
            writer.uint32(58).string(message.appointmentLocationDetail);
          }
          if (
            message.appointmentZoomMeetingPassword != null &&
            Object.hasOwnProperty.call(
              message,
              'appointmentZoomMeetingPassword'
            )
          ) {
            writer.uint32(66).string(message.appointmentZoomMeetingPassword);
          }
          if (
            message.appointmentProviderId != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderId')
          ) {
            writer.uint32(74).string(message.appointmentProviderId);
          }
          if (
            message.appointmentProviderFullName != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderFullName')
          ) {
            writer.uint32(82).string(message.appointmentProviderFullName);
          }
          if (
            message.appointmentProviderEmail != null &&
            Object.hasOwnProperty.call(message, 'appointmentProviderEmail')
          ) {
            writer.uint32(90).string(message.appointmentProviderEmail);
          }
          if (
            message.appointmentDatetime != null &&
            Object.hasOwnProperty.call(message, 'appointmentDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentDatetime,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.appointmentEndDatetime != null &&
            Object.hasOwnProperty.call(message, 'appointmentEndDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentEndDatetime,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.appointmentLengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'appointmentLengthMinutes')
          ) {
            writer.uint32(112).uint32(message.appointmentLengthMinutes);
          }
          if (
            message.appointmentCreatedAt != null &&
            Object.hasOwnProperty.call(message, 'appointmentCreatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.appointmentCreatedAt,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.appointmentCreatedBy != null &&
            Object.hasOwnProperty.call(message, 'appointmentCreatedBy')
          ) {
            writer.uint32(130).string(message.appointmentCreatedBy);
          }
          if (
            message.appointmentFirstName != null &&
            Object.hasOwnProperty.call(message, 'appointmentFirstName')
          ) {
            writer.uint32(138).string(message.appointmentFirstName);
          }
          if (
            message.appointmentLastName != null &&
            Object.hasOwnProperty.call(message, 'appointmentLastName')
          ) {
            writer.uint32(146).string(message.appointmentLastName);
          }
          if (
            message.appointmentDayOfWeek != null &&
            Object.hasOwnProperty.call(message, 'appointmentDayOfWeek')
          ) {
            writer.uint32(154).string(message.appointmentDayOfWeek);
          }
          if (
            message.appointmentDayOfMonth != null &&
            Object.hasOwnProperty.call(message, 'appointmentDayOfMonth')
          ) {
            writer.uint32(162).string(message.appointmentDayOfMonth);
          }
          if (
            message.appointmentMonth != null &&
            Object.hasOwnProperty.call(message, 'appointmentMonth')
          ) {
            writer.uint32(170).string(message.appointmentMonth);
          }
          if (
            message.appointmentDate != null &&
            Object.hasOwnProperty.call(message, 'appointmentDate')
          ) {
            writer.uint32(178).string(message.appointmentDate);
          }
          if (
            message.appointmentTime != null &&
            Object.hasOwnProperty.call(message, 'appointmentTime')
          ) {
            writer.uint32(186).string(message.appointmentTime);
          }
          if (
            message.appointmentStatus != null &&
            Object.hasOwnProperty.call(message, 'appointmentStatus')
          ) {
            writer.uint32(194).string(message.appointmentStatus);
          }
          if (
            message.intakeqPatientId != null &&
            Object.hasOwnProperty.call(message, 'intakeqPatientId')
          ) {
            writer.uint32(202).string(message.intakeqPatientId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Appointment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentId = reader.string();
                break;
              }
              case 2: {
                message.appointmentEmailAddress = reader.string();
                break;
              }
              case 3: {
                message.appointmentPhoneNumber = reader.string();
                break;
              }
              case 4: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 5: {
                message.appointmentTypeTitle = reader.string();
                break;
              }
              case 6: {
                message.appointmentClientConfirmed = reader.bool();
                break;
              }
              case 7: {
                message.appointmentLocationDetail = reader.string();
                break;
              }
              case 8: {
                message.appointmentZoomMeetingPassword = reader.string();
                break;
              }
              case 9: {
                message.appointmentProviderId = reader.string();
                break;
              }
              case 10: {
                message.appointmentProviderFullName = reader.string();
                break;
              }
              case 11: {
                message.appointmentProviderEmail = reader.string();
                break;
              }
              case 12: {
                message.appointmentDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 13: {
                message.appointmentEndDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 14: {
                message.appointmentLengthMinutes = reader.uint32();
                break;
              }
              case 15: {
                message.appointmentCreatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 16: {
                message.appointmentCreatedBy = reader.string();
                break;
              }
              case 17: {
                message.appointmentFirstName = reader.string();
                break;
              }
              case 18: {
                message.appointmentLastName = reader.string();
                break;
              }
              case 19: {
                message.appointmentDayOfWeek = reader.string();
                break;
              }
              case 20: {
                message.appointmentDayOfMonth = reader.string();
                break;
              }
              case 21: {
                message.appointmentMonth = reader.string();
                break;
              }
              case 22: {
                message.appointmentDate = reader.string();
                break;
              }
              case 23: {
                message.appointmentTime = reader.string();
                break;
              }
              case 24: {
                message.appointmentStatus = reader.string();
                break;
              }
              case 25: {
                message.intakeqPatientId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.Appointment) {
            return object;
          }
          const message = new $root.intakeq.v1.Appointment();
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.appointmentEmailAddress != null) {
            message.appointmentEmailAddress = String(
              object.appointmentEmailAddress
            );
          }
          if (object.appointmentPhoneNumber != null) {
            message.appointmentPhoneNumber = String(
              object.appointmentPhoneNumber
            );
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.appointmentTypeTitle != null) {
            message.appointmentTypeTitle = String(object.appointmentTypeTitle);
          }
          if (object.appointmentClientConfirmed != null) {
            message.appointmentClientConfirmed = Boolean(
              object.appointmentClientConfirmed
            );
          }
          if (object.appointmentLocationDetail != null) {
            message.appointmentLocationDetail = String(
              object.appointmentLocationDetail
            );
          }
          if (object.appointmentZoomMeetingPassword != null) {
            message.appointmentZoomMeetingPassword = String(
              object.appointmentZoomMeetingPassword
            );
          }
          if (object.appointmentProviderId != null) {
            message.appointmentProviderId = String(
              object.appointmentProviderId
            );
          }
          if (object.appointmentProviderFullName != null) {
            message.appointmentProviderFullName = String(
              object.appointmentProviderFullName
            );
          }
          if (object.appointmentProviderEmail != null) {
            message.appointmentProviderEmail = String(
              object.appointmentProviderEmail
            );
          }
          if (object.appointmentDatetime != null) {
            if (typeof object.appointmentDatetime !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Appointment.appointmentDatetime: object expected, but got ' +
                  typeof object.appointmentDatetime
              );
            }
            message.appointmentDatetime = timing$1.v1.Timestamp.fromObject(
              object.appointmentDatetime
            );
          }
          if (object.appointmentEndDatetime != null) {
            if (typeof object.appointmentEndDatetime !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Appointment.appointmentEndDatetime: object expected, but got ' +
                  typeof object.appointmentEndDatetime
              );
            }
            message.appointmentEndDatetime = timing$1.v1.Timestamp.fromObject(
              object.appointmentEndDatetime
            );
          }
          if (object.appointmentLengthMinutes != null) {
            message.appointmentLengthMinutes =
              object.appointmentLengthMinutes >>> 0;
          }
          if (object.appointmentCreatedAt != null) {
            if (typeof object.appointmentCreatedAt !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Appointment.appointmentCreatedAt: object expected, but got ' +
                  typeof object.appointmentCreatedAt
              );
            }
            message.appointmentCreatedAt = timing$1.v1.Timestamp.fromObject(
              object.appointmentCreatedAt
            );
          }
          if (object.appointmentCreatedBy != null) {
            message.appointmentCreatedBy = String(object.appointmentCreatedBy);
          }
          if (object.appointmentFirstName != null) {
            message.appointmentFirstName = String(object.appointmentFirstName);
          }
          if (object.appointmentLastName != null) {
            message.appointmentLastName = String(object.appointmentLastName);
          }
          if (object.appointmentDayOfWeek != null) {
            message.appointmentDayOfWeek = String(object.appointmentDayOfWeek);
          }
          if (object.appointmentDayOfMonth != null) {
            message.appointmentDayOfMonth = String(
              object.appointmentDayOfMonth
            );
          }
          if (object.appointmentMonth != null) {
            message.appointmentMonth = String(object.appointmentMonth);
          }
          if (object.appointmentDate != null) {
            message.appointmentDate = String(object.appointmentDate);
          }
          if (object.appointmentTime != null) {
            message.appointmentTime = String(object.appointmentTime);
          }
          if (object.appointmentStatus != null) {
            message.appointmentStatus = String(object.appointmentStatus);
          }
          if (object.intakeqPatientId != null) {
            message.intakeqPatientId = String(object.intakeqPatientId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentId = '';
            object.appointmentEmailAddress = '';
            object.appointmentPhoneNumber = '';
            object.appointmentTypeId = '';
            object.appointmentTypeTitle = '';
            object.appointmentClientConfirmed = false;
            object.appointmentLocationDetail = '';
            object.appointmentProviderId = '';
            object.appointmentProviderFullName = '';
            object.appointmentProviderEmail = '';
            object.appointmentDatetime = null;
            object.appointmentEndDatetime = null;
            object.appointmentLengthMinutes = 0;
            object.appointmentCreatedAt = null;
            object.appointmentCreatedBy = '';
            object.appointmentFirstName = '';
            object.appointmentLastName = '';
            object.appointmentDayOfWeek = '';
            object.appointmentDayOfMonth = '';
            object.appointmentMonth = '';
            object.appointmentDate = '';
            object.appointmentTime = '';
            object.appointmentStatus = '';
            object.intakeqPatientId = '';
          }
          let keys;
          if (
            message.appointmentId != null &&
            message.hasOwnProperty('appointmentId')
          ) {
            object.appointmentId = message.appointmentId;
          }
          if (
            message.appointmentEmailAddress != null &&
            message.hasOwnProperty('appointmentEmailAddress')
          ) {
            object.appointmentEmailAddress = message.appointmentEmailAddress;
          }
          if (
            message.appointmentPhoneNumber != null &&
            message.hasOwnProperty('appointmentPhoneNumber')
          ) {
            object.appointmentPhoneNumber = message.appointmentPhoneNumber;
          }
          if (
            message.appointmentTypeId != null &&
            message.hasOwnProperty('appointmentTypeId')
          ) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (
            message.appointmentTypeTitle != null &&
            message.hasOwnProperty('appointmentTypeTitle')
          ) {
            object.appointmentTypeTitle = message.appointmentTypeTitle;
          }
          if (
            message.appointmentClientConfirmed != null &&
            message.hasOwnProperty('appointmentClientConfirmed')
          ) {
            object.appointmentClientConfirmed =
              message.appointmentClientConfirmed;
          }
          if (
            message.appointmentLocationDetail != null &&
            message.hasOwnProperty('appointmentLocationDetail')
          ) {
            object.appointmentLocationDetail =
              message.appointmentLocationDetail;
          }
          if (
            message.appointmentZoomMeetingPassword != null &&
            message.hasOwnProperty('appointmentZoomMeetingPassword')
          ) {
            object.appointmentZoomMeetingPassword =
              message.appointmentZoomMeetingPassword;
          }
          if (
            message.appointmentProviderId != null &&
            message.hasOwnProperty('appointmentProviderId')
          ) {
            object.appointmentProviderId = message.appointmentProviderId;
          }
          if (
            message.appointmentProviderFullName != null &&
            message.hasOwnProperty('appointmentProviderFullName')
          ) {
            object.appointmentProviderFullName =
              message.appointmentProviderFullName;
          }
          if (
            message.appointmentProviderEmail != null &&
            message.hasOwnProperty('appointmentProviderEmail')
          ) {
            object.appointmentProviderEmail = message.appointmentProviderEmail;
          }
          if (
            message.appointmentDatetime != null &&
            message.hasOwnProperty('appointmentDatetime')
          ) {
            object.appointmentDatetime = timing$1.v1.Timestamp.toObject(
              message.appointmentDatetime,
              options
            );
          }
          if (
            message.appointmentEndDatetime != null &&
            message.hasOwnProperty('appointmentEndDatetime')
          ) {
            object.appointmentEndDatetime = timing$1.v1.Timestamp.toObject(
              message.appointmentEndDatetime,
              options
            );
          }
          if (
            message.appointmentLengthMinutes != null &&
            message.hasOwnProperty('appointmentLengthMinutes')
          ) {
            object.appointmentLengthMinutes = message.appointmentLengthMinutes;
          }
          if (
            message.appointmentCreatedAt != null &&
            message.hasOwnProperty('appointmentCreatedAt')
          ) {
            object.appointmentCreatedAt = timing$1.v1.Timestamp.toObject(
              message.appointmentCreatedAt,
              options
            );
          }
          if (
            message.appointmentCreatedBy != null &&
            message.hasOwnProperty('appointmentCreatedBy')
          ) {
            object.appointmentCreatedBy = message.appointmentCreatedBy;
          }
          if (
            message.appointmentFirstName != null &&
            message.hasOwnProperty('appointmentFirstName')
          ) {
            object.appointmentFirstName = message.appointmentFirstName;
          }
          if (
            message.appointmentLastName != null &&
            message.hasOwnProperty('appointmentLastName')
          ) {
            object.appointmentLastName = message.appointmentLastName;
          }
          if (
            message.appointmentDayOfWeek != null &&
            message.hasOwnProperty('appointmentDayOfWeek')
          ) {
            object.appointmentDayOfWeek = message.appointmentDayOfWeek;
          }
          if (
            message.appointmentDayOfMonth != null &&
            message.hasOwnProperty('appointmentDayOfMonth')
          ) {
            object.appointmentDayOfMonth = message.appointmentDayOfMonth;
          }
          if (
            message.appointmentMonth != null &&
            message.hasOwnProperty('appointmentMonth')
          ) {
            object.appointmentMonth = message.appointmentMonth;
          }
          if (
            message.appointmentDate != null &&
            message.hasOwnProperty('appointmentDate')
          ) {
            object.appointmentDate = message.appointmentDate;
          }
          if (
            message.appointmentTime != null &&
            message.hasOwnProperty('appointmentTime')
          ) {
            object.appointmentTime = message.appointmentTime;
          }
          if (
            message.appointmentStatus != null &&
            message.hasOwnProperty('appointmentStatus')
          ) {
            object.appointmentStatus = message.appointmentStatus;
          }
          if (
            message.intakeqPatientId != null &&
            message.hasOwnProperty('intakeqPatientId')
          ) {
            object.intakeqPatientId = message.intakeqPatientId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.Appointment';
        }
      }

      Appointment.prototype.appointmentId = '';
      Appointment.prototype.appointmentEmailAddress = '';
      Appointment.prototype.appointmentPhoneNumber = '';
      Appointment.prototype.appointmentTypeId = '';
      Appointment.prototype.appointmentTypeTitle = '';
      Appointment.prototype.appointmentClientConfirmed = false;
      Appointment.prototype.appointmentLocationDetail = '';
      Appointment.prototype.appointmentZoomMeetingPassword = null;
      Appointment.prototype.appointmentProviderId = '';
      Appointment.prototype.appointmentProviderFullName = '';
      Appointment.prototype.appointmentProviderEmail = '';
      Appointment.prototype.appointmentDatetime = null;
      Appointment.prototype.appointmentEndDatetime = null;
      Appointment.prototype.appointmentLengthMinutes = 0;
      Appointment.prototype.appointmentCreatedAt = null;
      Appointment.prototype.appointmentCreatedBy = '';
      Appointment.prototype.appointmentFirstName = '';
      Appointment.prototype.appointmentLastName = '';
      Appointment.prototype.appointmentDayOfWeek = '';
      Appointment.prototype.appointmentDayOfMonth = '';
      Appointment.prototype.appointmentMonth = '';
      Appointment.prototype.appointmentDate = '';
      Appointment.prototype.appointmentTime = '';
      Appointment.prototype.appointmentStatus = '';
      Appointment.prototype.intakeqPatientId = '';

      return Appointment;
    })();

    v1.CustomField = (() => {
      class CustomField {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomField(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fieldId != null &&
            Object.hasOwnProperty.call(message, 'fieldId')
          ) {
            writer.uint32(10).string(message.fieldId);
          }
          if (
            message.text != null &&
            Object.hasOwnProperty.call(message, 'text')
          ) {
            writer.uint32(18).string(message.text);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(26).string(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomField();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldId = reader.string();
                break;
              }
              case 2: {
                message.text = reader.string();
                break;
              }
              case 3: {
                message.value = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.CustomField) {
            return object;
          }
          const message = new $root.intakeq.v1.CustomField();
          if (object.fieldId != null) {
            message.fieldId = String(object.fieldId);
          }
          if (object.text != null) {
            message.text = String(object.text);
          }
          if (object.value != null) {
            message.value = String(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldId = '';
            object.text = '';
          }
          let keys;
          if (message.fieldId != null && message.hasOwnProperty('fieldId')) {
            object.fieldId = message.fieldId;
          }
          if (message.text != null && message.hasOwnProperty('text')) {
            object.text = message.text;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.CustomField';
        }
      }

      CustomField.prototype.fieldId = '';
      CustomField.prototype.text = '';
      CustomField.prototype.value = null;

      return CustomField;
    })();

    v1.LinkedClient = (() => {
      class LinkedClient {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LinkedClient(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.clientNumber != null &&
            Object.hasOwnProperty.call(message, 'clientNumber')
          ) {
            writer.uint32(8).int64(message.clientNumber);
          }
          if (
            message.relationshipType != null &&
            Object.hasOwnProperty.call(message, 'relationshipType')
          ) {
            writer.uint32(16).int32(message.relationshipType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LinkedClient();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientNumber = reader.int64();
                break;
              }
              case 2: {
                message.relationshipType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.LinkedClient) {
            return object;
          }
          const message = new $root.intakeq.v1.LinkedClient();
          if (object.clientNumber != null) {
            if ($util.Long) {
              (message.clientNumber = $util.Long.fromValue(
                object.clientNumber
              )).unsigned = false;
            } else if (typeof object.clientNumber === 'string') {
              message.clientNumber = parseInt(object.clientNumber, 10);
            } else if (typeof object.clientNumber === 'number') {
              message.clientNumber = object.clientNumber;
            } else if (typeof object.clientNumber === 'object') {
              message.clientNumber = new $util.LongBits(
                object.clientNumber.low >>> 0,
                object.clientNumber.high >>> 0
              ).toNumber();
            }
          }
          if (object.relationshipType != null) {
            switch (object.relationshipType) {
              case 'UNSPECIFIED_UNKNOWN_RELATIONSHIP_TYPE':
              case 0: {
                message.relationshipType = 0;
                break;
              }
              case 'PARENT':
              case 1: {
                message.relationshipType = 1;
                break;
              }
              case 'CHILD':
              case 2: {
                message.relationshipType = 2;
                break;
              }
              case 'SPOUSE':
              case 3: {
                message.relationshipType = 3;
                break;
              }
              case 'SIBLING':
              case 4: {
                message.relationshipType = 4;
                break;
              }
              case 'OTHER':
              case 5: {
                message.relationshipType = 5;
                break;
              }
              case 'PARTNER':
              case 6: {
                message.relationshipType = 6;
                break;
              }
              default: {
                if (typeof object.relationshipType == 'number') {
                  message.relationshipType = object.relationshipType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.clientNumber =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.clientNumber = options.longs === String ? '0' : 0;
            }
            object.relationshipType =
              options.enums === String
                ? 'UNSPECIFIED_UNKNOWN_RELATIONSHIP_TYPE'
                : 0;
          }
          let keys;
          if (
            message.clientNumber != null &&
            message.hasOwnProperty('clientNumber')
          ) {
            object.clientNumber =
              typeof message.clientNumber === 'number'
                ? options.longs === String
                  ? String(message.clientNumber)
                  : message.clientNumber
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.clientNumber)
                : options.longs === Number
                ? new $util.LongBits(
                    message.clientNumber.low >>> 0,
                    message.clientNumber.high >>> 0
                  ).toNumber()
                : message.clientNumber;
          }
          if (
            message.relationshipType != null &&
            message.hasOwnProperty('relationshipType')
          ) {
            object.relationshipType =
              options.enums === String
                ? $root.intakeq.v1.RelationshipType[
                    message.relationshipType
                  ] === undefined
                  ? message.relationshipType
                  : $root.intakeq.v1.RelationshipType[message.relationshipType]
                : message.relationshipType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.LinkedClient';
        }
      }

      LinkedClient.prototype.clientNumber = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      LinkedClient.prototype.relationshipType = 0;

      return LinkedClient;
    })();

    v1.Client = (() => {
      class Client {
        constructor(properties) {
          this.tags = [];
          this.customFields = [];
          this.linkedClients = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Client(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.clientId != null &&
            Object.hasOwnProperty.call(message, 'clientId')
          ) {
            writer.uint32(8).int64(message.clientId);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(26).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(34).string(message.lastName);
          }
          if (
            message.middleName != null &&
            Object.hasOwnProperty.call(message, 'middleName')
          ) {
            writer.uint32(42).string(message.middleName);
          }
          if (
            message.emailAddress != null &&
            Object.hasOwnProperty.call(message, 'emailAddress')
          ) {
            writer.uint32(50).string(message.emailAddress);
          }
          if (
            message.phoneNumber != null &&
            Object.hasOwnProperty.call(message, 'phoneNumber')
          ) {
            writer.uint32(58).string(message.phoneNumber);
          }
          if (
            message.dateOfBirth != null &&
            Object.hasOwnProperty.call(message, 'dateOfBirth')
          ) {
            timing$1.v1.Timestamp.encode(
              message.dateOfBirth,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.maritalStatus != null &&
            Object.hasOwnProperty.call(message, 'maritalStatus')
          ) {
            writer.uint32(74).string(message.maritalStatus);
          }
          if (
            message.gender != null &&
            Object.hasOwnProperty.call(message, 'gender')
          ) {
            writer.uint32(82).string(message.gender);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(90).string(element);
            }
          }
          if (
            message.archived != null &&
            Object.hasOwnProperty.call(message, 'archived')
          ) {
            writer.uint32(96).bool(message.archived);
          }
          if (
            message.homePhone != null &&
            Object.hasOwnProperty.call(message, 'homePhone')
          ) {
            writer.uint32(106).string(message.homePhone);
          }
          if (
            message.workPhone != null &&
            Object.hasOwnProperty.call(message, 'workPhone')
          ) {
            writer.uint32(114).string(message.workPhone);
          }
          if (
            message.mobilePhone != null &&
            Object.hasOwnProperty.call(message, 'mobilePhone')
          ) {
            writer.uint32(122).string(message.mobilePhone);
          }
          if (
            message.address != null &&
            Object.hasOwnProperty.call(message, 'address')
          ) {
            writer.uint32(130).string(message.address);
          }
          if (
            message.unitNumber != null &&
            Object.hasOwnProperty.call(message, 'unitNumber')
          ) {
            writer.uint32(138).string(message.unitNumber);
          }
          if (
            message.streetAddress != null &&
            Object.hasOwnProperty.call(message, 'streetAddress')
          ) {
            writer.uint32(146).string(message.streetAddress);
          }
          if (
            message.city != null &&
            Object.hasOwnProperty.call(message, 'city')
          ) {
            writer.uint32(154).string(message.city);
          }
          if (
            message.state != null &&
            Object.hasOwnProperty.call(message, 'state')
          ) {
            writer.uint32(162).string(message.state);
          }
          if (
            message.postalCode != null &&
            Object.hasOwnProperty.call(message, 'postalCode')
          ) {
            writer.uint32(170).string(message.postalCode);
          }
          if (
            message.country != null &&
            Object.hasOwnProperty.call(message, 'country')
          ) {
            writer.uint32(178).string(message.country);
          }
          if (
            message.stateShort != null &&
            Object.hasOwnProperty.call(message, 'stateShort')
          ) {
            writer.uint32(186).string(message.stateShort);
          }
          if (
            message.additionalInformation != null &&
            Object.hasOwnProperty.call(message, 'additionalInformation')
          ) {
            writer.uint32(194).string(message.additionalInformation);
          }
          if (
            message.billingType != null &&
            Object.hasOwnProperty.call(message, 'billingType')
          ) {
            writer.uint32(200).int32(message.billingType);
          }
          if (
            message.primaryInsuranceCompany != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuranceCompany')
          ) {
            writer.uint32(210).string(message.primaryInsuranceCompany);
          }
          if (
            message.primaryInsurancePayerId != null &&
            Object.hasOwnProperty.call(message, 'primaryInsurancePayerId')
          ) {
            writer.uint32(218).string(message.primaryInsurancePayerId);
          }
          if (
            message.primaryInsurancePolicyNumber != null &&
            Object.hasOwnProperty.call(message, 'primaryInsurancePolicyNumber')
          ) {
            writer.uint32(226).string(message.primaryInsurancePolicyNumber);
          }
          if (
            message.primaryInsuranceGroupNumber != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuranceGroupNumber')
          ) {
            writer.uint32(234).string(message.primaryInsuranceGroupNumber);
          }
          if (
            message.primaryInsuranceHolderName != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuranceHolderName')
          ) {
            writer.uint32(242).string(message.primaryInsuranceHolderName);
          }
          if (
            message.primaryInsuranceHolderRelationship != null &&
            Object.hasOwnProperty.call(
              message,
              'primaryInsuranceHolderRelationship'
            )
          ) {
            writer
              .uint32(250)
              .string(message.primaryInsuranceHolderRelationship);
          }
          if (
            message.primaryInsuranceHolderDateOfBirth != null &&
            Object.hasOwnProperty.call(
              message,
              'primaryInsuranceHolderDateOfBirth'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.primaryInsuranceHolderDateOfBirth,
              writer.uint32(258).fork()
            ).ldelim();
          }
          if (
            message.secondaryInsuranceCompany != null &&
            Object.hasOwnProperty.call(message, 'secondaryInsuranceCompany')
          ) {
            writer.uint32(266).string(message.secondaryInsuranceCompany);
          }
          if (
            message.secondaryInsurancePayerId != null &&
            Object.hasOwnProperty.call(message, 'secondaryInsurancePayerId')
          ) {
            writer.uint32(274).string(message.secondaryInsurancePayerId);
          }
          if (
            message.secondaryInsurancePolicyNumber != null &&
            Object.hasOwnProperty.call(
              message,
              'secondaryInsurancePolicyNumber'
            )
          ) {
            writer.uint32(282).string(message.secondaryInsurancePolicyNumber);
          }
          if (
            message.secondaryInsuranceGroupNumber != null &&
            Object.hasOwnProperty.call(message, 'secondaryInsuranceGroupNumber')
          ) {
            writer.uint32(290).string(message.secondaryInsuranceGroupNumber);
          }
          if (
            message.secondaryInsuranceHolderName != null &&
            Object.hasOwnProperty.call(message, 'secondaryInsuranceHolderName')
          ) {
            writer.uint32(298).string(message.secondaryInsuranceHolderName);
          }
          if (
            message.secondaryInsuranceHolderRelationship != null &&
            Object.hasOwnProperty.call(
              message,
              'secondaryInsuranceHolderRelationship'
            )
          ) {
            writer
              .uint32(306)
              .string(message.secondaryInsuranceHolderRelationship);
          }
          if (
            message.secondaryInsuranceHolderDateOfBirth != null &&
            Object.hasOwnProperty.call(
              message,
              'secondaryInsuranceHolderDateOfBirth'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.secondaryInsuranceHolderDateOfBirth,
              writer.uint32(314).fork()
            ).ldelim();
          }
          if (
            message.secondaryRelationshipToInsured != null &&
            Object.hasOwnProperty.call(
              message,
              'secondaryRelationshipToInsured'
            )
          ) {
            writer.uint32(322).string(message.secondaryRelationshipToInsured);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(330).fork()
            ).ldelim();
          }
          if (
            message.lastActivityAt != null &&
            Object.hasOwnProperty.call(message, 'lastActivityAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lastActivityAt,
              writer.uint32(338).fork()
            ).ldelim();
          }
          if (
            message.practitionerId != null &&
            Object.hasOwnProperty.call(message, 'practitionerId')
          ) {
            writer.uint32(346).string(message.practitionerId);
          }
          if (
            message.customFields != null &&
            Object.hasOwnProperty.call(message, 'customFields')
          ) {
            for (const element of message.customFields) {
              $root.intakeq.v1.CustomField.encode(
                element,
                writer.uint32(354).fork()
              ).ldelim();
            }
          }
          if (
            message.guid != null &&
            Object.hasOwnProperty.call(message, 'guid')
          ) {
            writer.uint32(362).string(message.guid);
          }
          if (
            message.lastUpdatedAt != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lastUpdatedAt,
              writer.uint32(370).fork()
            ).ldelim();
          }
          if (
            message.stripeCustomerId != null &&
            Object.hasOwnProperty.call(message, 'stripeCustomerId')
          ) {
            writer.uint32(378).string(message.stripeCustomerId);
          }
          if (
            message.squareCustomerId != null &&
            Object.hasOwnProperty.call(message, 'squareCustomerId')
          ) {
            writer.uint32(386).string(message.squareCustomerId);
          }
          if (
            message.timezoneId != null &&
            Object.hasOwnProperty.call(message, 'timezoneId')
          ) {
            writer.uint32(394).string(message.timezoneId);
          }
          if (
            message.primaryInsurancePlan != null &&
            Object.hasOwnProperty.call(message, 'primaryInsurancePlan')
          ) {
            writer.uint32(402).string(message.primaryInsurancePlan);
          }
          if (
            message.primaryInsuredGender != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuredGender')
          ) {
            writer.uint32(410).string(message.primaryInsuredGender);
          }
          if (
            message.primaryInsuredCity != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuredCity')
          ) {
            writer.uint32(418).string(message.primaryInsuredCity);
          }
          if (
            message.primaryInsuredZipCode != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuredZipCode')
          ) {
            writer.uint32(426).string(message.primaryInsuredZipCode);
          }
          if (
            message.primaryInsuredState != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuredState')
          ) {
            writer.uint32(434).string(message.primaryInsuredState);
          }
          if (
            message.primaryInsuredStreetAddress != null &&
            Object.hasOwnProperty.call(message, 'primaryInsuredStreetAddress')
          ) {
            writer.uint32(442).string(message.primaryInsuredStreetAddress);
          }
          if (
            message.externalClientId != null &&
            Object.hasOwnProperty.call(message, 'externalClientId')
          ) {
            writer.uint32(450).string(message.externalClientId);
          }
          if (
            message.creditBalance != null &&
            Object.hasOwnProperty.call(message, 'creditBalance')
          ) {
            writer.uint32(457).double(message.creditBalance);
          }
          if (
            message.lastActivityName != null &&
            Object.hasOwnProperty.call(message, 'lastActivityName')
          ) {
            writer.uint32(466).string(message.lastActivityName);
          }
          if (
            message.parentClientId != null &&
            Object.hasOwnProperty.call(message, 'parentClientId')
          ) {
            writer.uint32(474).string(message.parentClientId);
          }
          if (
            message.linkedClients != null &&
            Object.hasOwnProperty.call(message, 'linkedClients')
          ) {
            for (const element of message.linkedClients) {
              $root.intakeq.v1.LinkedClient.encode(
                element,
                writer.uint32(482).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Client();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientId = reader.int64();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.lastName = reader.string();
                break;
              }
              case 5: {
                message.middleName = reader.string();
                break;
              }
              case 6: {
                message.emailAddress = reader.string();
                break;
              }
              case 7: {
                message.phoneNumber = reader.string();
                break;
              }
              case 8: {
                message.dateOfBirth = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.maritalStatus = reader.string();
                break;
              }
              case 10: {
                message.gender = reader.string();
                break;
              }
              case 11: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 12: {
                message.archived = reader.bool();
                break;
              }
              case 13: {
                message.homePhone = reader.string();
                break;
              }
              case 14: {
                message.workPhone = reader.string();
                break;
              }
              case 15: {
                message.mobilePhone = reader.string();
                break;
              }
              case 16: {
                message.address = reader.string();
                break;
              }
              case 17: {
                message.unitNumber = reader.string();
                break;
              }
              case 18: {
                message.streetAddress = reader.string();
                break;
              }
              case 19: {
                message.city = reader.string();
                break;
              }
              case 20: {
                message.state = reader.string();
                break;
              }
              case 21: {
                message.postalCode = reader.string();
                break;
              }
              case 22: {
                message.country = reader.string();
                break;
              }
              case 23: {
                message.stateShort = reader.string();
                break;
              }
              case 24: {
                message.additionalInformation = reader.string();
                break;
              }
              case 25: {
                message.billingType = reader.int32();
                break;
              }
              case 26: {
                message.primaryInsuranceCompany = reader.string();
                break;
              }
              case 27: {
                message.primaryInsurancePayerId = reader.string();
                break;
              }
              case 28: {
                message.primaryInsurancePolicyNumber = reader.string();
                break;
              }
              case 29: {
                message.primaryInsuranceGroupNumber = reader.string();
                break;
              }
              case 30: {
                message.primaryInsuranceHolderName = reader.string();
                break;
              }
              case 31: {
                message.primaryInsuranceHolderRelationship = reader.string();
                break;
              }
              case 32: {
                message.primaryInsuranceHolderDateOfBirth =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 33: {
                message.secondaryInsuranceCompany = reader.string();
                break;
              }
              case 34: {
                message.secondaryInsurancePayerId = reader.string();
                break;
              }
              case 35: {
                message.secondaryInsurancePolicyNumber = reader.string();
                break;
              }
              case 36: {
                message.secondaryInsuranceGroupNumber = reader.string();
                break;
              }
              case 37: {
                message.secondaryInsuranceHolderName = reader.string();
                break;
              }
              case 38: {
                message.secondaryInsuranceHolderRelationship = reader.string();
                break;
              }
              case 39: {
                message.secondaryInsuranceHolderDateOfBirth =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 40: {
                message.secondaryRelationshipToInsured = reader.string();
                break;
              }
              case 41: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 42: {
                message.lastActivityAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 43: {
                message.practitionerId = reader.string();
                break;
              }
              case 44: {
                if (!message.customFields || !message.customFields.length) {
                  message.customFields = [];
                }
                message.customFields.push(
                  $root.intakeq.v1.CustomField.decode(reader, reader.uint32())
                );
                break;
              }
              case 45: {
                message.guid = reader.string();
                break;
              }
              case 46: {
                message.lastUpdatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 47: {
                message.stripeCustomerId = reader.string();
                break;
              }
              case 48: {
                message.squareCustomerId = reader.string();
                break;
              }
              case 49: {
                message.timezoneId = reader.string();
                break;
              }
              case 50: {
                message.primaryInsurancePlan = reader.string();
                break;
              }
              case 51: {
                message.primaryInsuredGender = reader.string();
                break;
              }
              case 52: {
                message.primaryInsuredCity = reader.string();
                break;
              }
              case 53: {
                message.primaryInsuredZipCode = reader.string();
                break;
              }
              case 54: {
                message.primaryInsuredState = reader.string();
                break;
              }
              case 55: {
                message.primaryInsuredStreetAddress = reader.string();
                break;
              }
              case 56: {
                message.externalClientId = reader.string();
                break;
              }
              case 57: {
                message.creditBalance = reader.double();
                break;
              }
              case 58: {
                message.lastActivityName = reader.string();
                break;
              }
              case 59: {
                message.parentClientId = reader.string();
                break;
              }
              case 60: {
                if (!message.linkedClients || !message.linkedClients.length) {
                  message.linkedClients = [];
                }
                message.linkedClients.push(
                  $root.intakeq.v1.LinkedClient.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intakeq.v1.Client) {
            return object;
          }
          const message = new $root.intakeq.v1.Client();
          if (object.clientId != null) {
            if ($util.Long) {
              (message.clientId = $util.Long.fromValue(
                object.clientId
              )).unsigned = false;
            } else if (typeof object.clientId === 'string') {
              message.clientId = parseInt(object.clientId, 10);
            } else if (typeof object.clientId === 'number') {
              message.clientId = object.clientId;
            } else if (typeof object.clientId === 'object') {
              message.clientId = new $util.LongBits(
                object.clientId.low >>> 0,
                object.clientId.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.middleName != null) {
            message.middleName = String(object.middleName);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Client.dateOfBirth: object expected, but got ' +
                  typeof object.dateOfBirth
              );
            }
            message.dateOfBirth = timing$1.v1.Timestamp.fromObject(
              object.dateOfBirth
            );
          }
          if (object.maritalStatus != null) {
            message.maritalStatus = String(object.maritalStatus);
          }
          if (object.gender != null) {
            message.gender = String(object.gender);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.intakeq.v1.Client.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.homePhone != null) {
            message.homePhone = String(object.homePhone);
          }
          if (object.workPhone != null) {
            message.workPhone = String(object.workPhone);
          }
          if (object.mobilePhone != null) {
            message.mobilePhone = String(object.mobilePhone);
          }
          if (object.address != null) {
            message.address = String(object.address);
          }
          if (object.unitNumber != null) {
            message.unitNumber = String(object.unitNumber);
          }
          if (object.streetAddress != null) {
            message.streetAddress = String(object.streetAddress);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.postalCode != null) {
            message.postalCode = String(object.postalCode);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          if (object.stateShort != null) {
            message.stateShort = String(object.stateShort);
          }
          if (object.additionalInformation != null) {
            message.additionalInformation = String(
              object.additionalInformation
            );
          }
          if (object.billingType != null) {
            switch (object.billingType) {
              case 'UNSPECIFIED_UNKNOWN_BILLING_TYPE':
              case 0: {
                message.billingType = 0;
                break;
              }
              case 'SELF_PAY':
              case 1: {
                message.billingType = 1;
                break;
              }
              case 'INSURANCE':
              case 2: {
                message.billingType = 2;
                break;
              }
              default: {
                if (typeof object.billingType == 'number') {
                  message.billingType = object.billingType;
                  break;
                }
                break;
              }
            }
          }
          if (object.primaryInsuranceCompany != null) {
            message.primaryInsuranceCompany = String(
              object.primaryInsuranceCompany
            );
          }
          if (object.primaryInsurancePayerId != null) {
            message.primaryInsurancePayerId = String(
              object.primaryInsurancePayerId
            );
          }
          if (object.primaryInsurancePolicyNumber != null) {
            message.primaryInsurancePolicyNumber = String(
              object.primaryInsurancePolicyNumber
            );
          }
          if (object.primaryInsuranceGroupNumber != null) {
            message.primaryInsuranceGroupNumber = String(
              object.primaryInsuranceGroupNumber
            );
          }
          if (object.primaryInsuranceHolderName != null) {
            message.primaryInsuranceHolderName = String(
              object.primaryInsuranceHolderName
            );
          }
          if (object.primaryInsuranceHolderRelationship != null) {
            message.primaryInsuranceHolderRelationship = String(
              object.primaryInsuranceHolderRelationship
            );
          }
          if (object.primaryInsuranceHolderDateOfBirth != null) {
            if (typeof object.primaryInsuranceHolderDateOfBirth !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Client.primaryInsuranceHolderDateOfBirth: object expected, but got ' +
                  typeof object.primaryInsuranceHolderDateOfBirth
              );
            }
            message.primaryInsuranceHolderDateOfBirth =
              timing$1.v1.Timestamp.fromObject(
                object.primaryInsuranceHolderDateOfBirth
              );
          }
          if (object.secondaryInsuranceCompany != null) {
            message.secondaryInsuranceCompany = String(
              object.secondaryInsuranceCompany
            );
          }
          if (object.secondaryInsurancePayerId != null) {
            message.secondaryInsurancePayerId = String(
              object.secondaryInsurancePayerId
            );
          }
          if (object.secondaryInsurancePolicyNumber != null) {
            message.secondaryInsurancePolicyNumber = String(
              object.secondaryInsurancePolicyNumber
            );
          }
          if (object.secondaryInsuranceGroupNumber != null) {
            message.secondaryInsuranceGroupNumber = String(
              object.secondaryInsuranceGroupNumber
            );
          }
          if (object.secondaryInsuranceHolderName != null) {
            message.secondaryInsuranceHolderName = String(
              object.secondaryInsuranceHolderName
            );
          }
          if (object.secondaryInsuranceHolderRelationship != null) {
            message.secondaryInsuranceHolderRelationship = String(
              object.secondaryInsuranceHolderRelationship
            );
          }
          if (object.secondaryInsuranceHolderDateOfBirth != null) {
            if (
              typeof object.secondaryInsuranceHolderDateOfBirth !== 'object'
            ) {
              throw new TypeError(
                '.intakeq.v1.Client.secondaryInsuranceHolderDateOfBirth: object expected, but got ' +
                  typeof object.secondaryInsuranceHolderDateOfBirth
              );
            }
            message.secondaryInsuranceHolderDateOfBirth =
              timing$1.v1.Timestamp.fromObject(
                object.secondaryInsuranceHolderDateOfBirth
              );
          }
          if (object.secondaryRelationshipToInsured != null) {
            message.secondaryRelationshipToInsured = String(
              object.secondaryRelationshipToInsured
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Client.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.lastActivityAt != null) {
            if (typeof object.lastActivityAt !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Client.lastActivityAt: object expected, but got ' +
                  typeof object.lastActivityAt
              );
            }
            message.lastActivityAt = timing$1.v1.Timestamp.fromObject(
              object.lastActivityAt
            );
          }
          if (object.practitionerId != null) {
            message.practitionerId = String(object.practitionerId);
          }
          if (object.customFields) {
            if (!Array.isArray(object.customFields)) {
              throw new TypeError(
                '.intakeq.v1.Client.customFields: array type expected, but got ' +
                  typeof object.customFields
              );
            }
            message.customFields = new Array(object.customFields.length);
            for (let i = 0; i < object.customFields.length; ++i) {
              if (typeof object.customFields[i] !== 'object') {
                throw new TypeError(
                  '.intakeq.v1.Client.customFields: object expected, but got ' +
                    typeof object.customFields[i]
                );
              }
              message.customFields[i] = $root.intakeq.v1.CustomField.fromObject(
                object.customFields[i]
              );
            }
          }
          if (object.guid != null) {
            message.guid = String(object.guid);
          }
          if (object.lastUpdatedAt != null) {
            if (typeof object.lastUpdatedAt !== 'object') {
              throw new TypeError(
                '.intakeq.v1.Client.lastUpdatedAt: object expected, but got ' +
                  typeof object.lastUpdatedAt
              );
            }
            message.lastUpdatedAt = timing$1.v1.Timestamp.fromObject(
              object.lastUpdatedAt
            );
          }
          if (object.stripeCustomerId != null) {
            message.stripeCustomerId = String(object.stripeCustomerId);
          }
          if (object.squareCustomerId != null) {
            message.squareCustomerId = String(object.squareCustomerId);
          }
          if (object.timezoneId != null) {
            message.timezoneId = String(object.timezoneId);
          }
          if (object.primaryInsurancePlan != null) {
            message.primaryInsurancePlan = String(object.primaryInsurancePlan);
          }
          if (object.primaryInsuredGender != null) {
            message.primaryInsuredGender = String(object.primaryInsuredGender);
          }
          if (object.primaryInsuredCity != null) {
            message.primaryInsuredCity = String(object.primaryInsuredCity);
          }
          if (object.primaryInsuredZipCode != null) {
            message.primaryInsuredZipCode = String(
              object.primaryInsuredZipCode
            );
          }
          if (object.primaryInsuredState != null) {
            message.primaryInsuredState = String(object.primaryInsuredState);
          }
          if (object.primaryInsuredStreetAddress != null) {
            message.primaryInsuredStreetAddress = String(
              object.primaryInsuredStreetAddress
            );
          }
          if (object.externalClientId != null) {
            message.externalClientId = String(object.externalClientId);
          }
          if (object.creditBalance != null) {
            message.creditBalance = Number(object.creditBalance);
          }
          if (object.lastActivityName != null) {
            message.lastActivityName = String(object.lastActivityName);
          }
          if (object.parentClientId != null) {
            message.parentClientId = String(object.parentClientId);
          }
          if (object.linkedClients) {
            if (!Array.isArray(object.linkedClients)) {
              throw new TypeError(
                '.intakeq.v1.Client.linkedClients: array type expected, but got ' +
                  typeof object.linkedClients
              );
            }
            message.linkedClients = new Array(object.linkedClients.length);
            for (let i = 0; i < object.linkedClients.length; ++i) {
              if (typeof object.linkedClients[i] !== 'object') {
                throw new TypeError(
                  '.intakeq.v1.Client.linkedClients: object expected, but got ' +
                    typeof object.linkedClients[i]
                );
              }
              message.linkedClients[i] =
                $root.intakeq.v1.LinkedClient.fromObject(
                  object.linkedClients[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
            object.customFields = [];
            object.linkedClients = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.clientId =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.clientId = options.longs === String ? '0' : 0;
            }
            object.archived = false;
            object.billingType =
              options.enums === String ? 'UNSPECIFIED_UNKNOWN_BILLING_TYPE' : 0;
            object.createdAt = null;
            object.creditBalance = 0;
          }
          let keys;
          if (message.clientId != null && message.hasOwnProperty('clientId')) {
            object.clientId =
              typeof message.clientId === 'number'
                ? options.longs === String
                  ? String(message.clientId)
                  : message.clientId
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.clientId)
                : options.longs === Number
                ? new $util.LongBits(
                    message.clientId.low >>> 0,
                    message.clientId.high >>> 0
                  ).toNumber()
                : message.clientId;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (
            message.middleName != null &&
            message.hasOwnProperty('middleName')
          ) {
            object.middleName = message.middleName;
          }
          if (
            message.emailAddress != null &&
            message.hasOwnProperty('emailAddress')
          ) {
            object.emailAddress = message.emailAddress;
          }
          if (
            message.phoneNumber != null &&
            message.hasOwnProperty('phoneNumber')
          ) {
            object.phoneNumber = message.phoneNumber;
          }
          if (
            message.dateOfBirth != null &&
            message.hasOwnProperty('dateOfBirth')
          ) {
            object.dateOfBirth = timing$1.v1.Timestamp.toObject(
              message.dateOfBirth,
              options
            );
          }
          if (
            message.maritalStatus != null &&
            message.hasOwnProperty('maritalStatus')
          ) {
            object.maritalStatus = message.maritalStatus;
          }
          if (message.gender != null && message.hasOwnProperty('gender')) {
            object.gender = message.gender;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.archived != null && message.hasOwnProperty('archived')) {
            object.archived = message.archived;
          }
          if (
            message.homePhone != null &&
            message.hasOwnProperty('homePhone')
          ) {
            object.homePhone = message.homePhone;
          }
          if (
            message.workPhone != null &&
            message.hasOwnProperty('workPhone')
          ) {
            object.workPhone = message.workPhone;
          }
          if (
            message.mobilePhone != null &&
            message.hasOwnProperty('mobilePhone')
          ) {
            object.mobilePhone = message.mobilePhone;
          }
          if (message.address != null && message.hasOwnProperty('address')) {
            object.address = message.address;
          }
          if (
            message.unitNumber != null &&
            message.hasOwnProperty('unitNumber')
          ) {
            object.unitNumber = message.unitNumber;
          }
          if (
            message.streetAddress != null &&
            message.hasOwnProperty('streetAddress')
          ) {
            object.streetAddress = message.streetAddress;
          }
          if (message.city != null && message.hasOwnProperty('city')) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty('state')) {
            object.state = message.state;
          }
          if (
            message.postalCode != null &&
            message.hasOwnProperty('postalCode')
          ) {
            object.postalCode = message.postalCode;
          }
          if (message.country != null && message.hasOwnProperty('country')) {
            object.country = message.country;
          }
          if (
            message.stateShort != null &&
            message.hasOwnProperty('stateShort')
          ) {
            object.stateShort = message.stateShort;
          }
          if (
            message.additionalInformation != null &&
            message.hasOwnProperty('additionalInformation')
          ) {
            object.additionalInformation = message.additionalInformation;
          }
          if (
            message.billingType != null &&
            message.hasOwnProperty('billingType')
          ) {
            object.billingType =
              options.enums === String
                ? $root.intakeq.v1.BillingType[message.billingType] ===
                  undefined
                  ? message.billingType
                  : $root.intakeq.v1.BillingType[message.billingType]
                : message.billingType;
          }
          if (
            message.primaryInsuranceCompany != null &&
            message.hasOwnProperty('primaryInsuranceCompany')
          ) {
            object.primaryInsuranceCompany = message.primaryInsuranceCompany;
          }
          if (
            message.primaryInsurancePayerId != null &&
            message.hasOwnProperty('primaryInsurancePayerId')
          ) {
            object.primaryInsurancePayerId = message.primaryInsurancePayerId;
          }
          if (
            message.primaryInsurancePolicyNumber != null &&
            message.hasOwnProperty('primaryInsurancePolicyNumber')
          ) {
            object.primaryInsurancePolicyNumber =
              message.primaryInsurancePolicyNumber;
          }
          if (
            message.primaryInsuranceGroupNumber != null &&
            message.hasOwnProperty('primaryInsuranceGroupNumber')
          ) {
            object.primaryInsuranceGroupNumber =
              message.primaryInsuranceGroupNumber;
          }
          if (
            message.primaryInsuranceHolderName != null &&
            message.hasOwnProperty('primaryInsuranceHolderName')
          ) {
            object.primaryInsuranceHolderName =
              message.primaryInsuranceHolderName;
          }
          if (
            message.primaryInsuranceHolderRelationship != null &&
            message.hasOwnProperty('primaryInsuranceHolderRelationship')
          ) {
            object.primaryInsuranceHolderRelationship =
              message.primaryInsuranceHolderRelationship;
          }
          if (
            message.primaryInsuranceHolderDateOfBirth != null &&
            message.hasOwnProperty('primaryInsuranceHolderDateOfBirth')
          ) {
            object.primaryInsuranceHolderDateOfBirth =
              timing$1.v1.Timestamp.toObject(
                message.primaryInsuranceHolderDateOfBirth,
                options
              );
          }
          if (
            message.secondaryInsuranceCompany != null &&
            message.hasOwnProperty('secondaryInsuranceCompany')
          ) {
            object.secondaryInsuranceCompany =
              message.secondaryInsuranceCompany;
          }
          if (
            message.secondaryInsurancePayerId != null &&
            message.hasOwnProperty('secondaryInsurancePayerId')
          ) {
            object.secondaryInsurancePayerId =
              message.secondaryInsurancePayerId;
          }
          if (
            message.secondaryInsurancePolicyNumber != null &&
            message.hasOwnProperty('secondaryInsurancePolicyNumber')
          ) {
            object.secondaryInsurancePolicyNumber =
              message.secondaryInsurancePolicyNumber;
          }
          if (
            message.secondaryInsuranceGroupNumber != null &&
            message.hasOwnProperty('secondaryInsuranceGroupNumber')
          ) {
            object.secondaryInsuranceGroupNumber =
              message.secondaryInsuranceGroupNumber;
          }
          if (
            message.secondaryInsuranceHolderName != null &&
            message.hasOwnProperty('secondaryInsuranceHolderName')
          ) {
            object.secondaryInsuranceHolderName =
              message.secondaryInsuranceHolderName;
          }
          if (
            message.secondaryInsuranceHolderRelationship != null &&
            message.hasOwnProperty('secondaryInsuranceHolderRelationship')
          ) {
            object.secondaryInsuranceHolderRelationship =
              message.secondaryInsuranceHolderRelationship;
          }
          if (
            message.secondaryInsuranceHolderDateOfBirth != null &&
            message.hasOwnProperty('secondaryInsuranceHolderDateOfBirth')
          ) {
            object.secondaryInsuranceHolderDateOfBirth =
              timing$1.v1.Timestamp.toObject(
                message.secondaryInsuranceHolderDateOfBirth,
                options
              );
          }
          if (
            message.secondaryRelationshipToInsured != null &&
            message.hasOwnProperty('secondaryRelationshipToInsured')
          ) {
            object.secondaryRelationshipToInsured =
              message.secondaryRelationshipToInsured;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.lastActivityAt != null &&
            message.hasOwnProperty('lastActivityAt')
          ) {
            object.lastActivityAt = timing$1.v1.Timestamp.toObject(
              message.lastActivityAt,
              options
            );
          }
          if (
            message.practitionerId != null &&
            message.hasOwnProperty('practitionerId')
          ) {
            object.practitionerId = message.practitionerId;
          }
          if (message.customFields && message.customFields.length) {
            object.customFields = new Array(message.customFields.length);
            for (let i = 0; i < message.customFields.length; ++i) {
              object.customFields[i] = $root.intakeq.v1.CustomField.toObject(
                message.customFields[i],
                options
              );
            }
          }
          if (message.guid != null && message.hasOwnProperty('guid')) {
            object.guid = message.guid;
          }
          if (
            message.lastUpdatedAt != null &&
            message.hasOwnProperty('lastUpdatedAt')
          ) {
            object.lastUpdatedAt = timing$1.v1.Timestamp.toObject(
              message.lastUpdatedAt,
              options
            );
          }
          if (
            message.stripeCustomerId != null &&
            message.hasOwnProperty('stripeCustomerId')
          ) {
            object.stripeCustomerId = message.stripeCustomerId;
          }
          if (
            message.squareCustomerId != null &&
            message.hasOwnProperty('squareCustomerId')
          ) {
            object.squareCustomerId = message.squareCustomerId;
          }
          if (
            message.timezoneId != null &&
            message.hasOwnProperty('timezoneId')
          ) {
            object.timezoneId = message.timezoneId;
          }
          if (
            message.primaryInsurancePlan != null &&
            message.hasOwnProperty('primaryInsurancePlan')
          ) {
            object.primaryInsurancePlan = message.primaryInsurancePlan;
          }
          if (
            message.primaryInsuredGender != null &&
            message.hasOwnProperty('primaryInsuredGender')
          ) {
            object.primaryInsuredGender = message.primaryInsuredGender;
          }
          if (
            message.primaryInsuredCity != null &&
            message.hasOwnProperty('primaryInsuredCity')
          ) {
            object.primaryInsuredCity = message.primaryInsuredCity;
          }
          if (
            message.primaryInsuredZipCode != null &&
            message.hasOwnProperty('primaryInsuredZipCode')
          ) {
            object.primaryInsuredZipCode = message.primaryInsuredZipCode;
          }
          if (
            message.primaryInsuredState != null &&
            message.hasOwnProperty('primaryInsuredState')
          ) {
            object.primaryInsuredState = message.primaryInsuredState;
          }
          if (
            message.primaryInsuredStreetAddress != null &&
            message.hasOwnProperty('primaryInsuredStreetAddress')
          ) {
            object.primaryInsuredStreetAddress =
              message.primaryInsuredStreetAddress;
          }
          if (
            message.externalClientId != null &&
            message.hasOwnProperty('externalClientId')
          ) {
            object.externalClientId = message.externalClientId;
          }
          if (
            message.creditBalance != null &&
            message.hasOwnProperty('creditBalance')
          ) {
            object.creditBalance =
              options.json && !isFinite(message.creditBalance)
                ? String(message.creditBalance)
                : message.creditBalance;
          }
          if (
            message.lastActivityName != null &&
            message.hasOwnProperty('lastActivityName')
          ) {
            object.lastActivityName = message.lastActivityName;
          }
          if (
            message.parentClientId != null &&
            message.hasOwnProperty('parentClientId')
          ) {
            object.parentClientId = message.parentClientId;
          }
          if (message.linkedClients && message.linkedClients.length) {
            object.linkedClients = new Array(message.linkedClients.length);
            for (let i = 0; i < message.linkedClients.length; ++i) {
              object.linkedClients[i] = $root.intakeq.v1.LinkedClient.toObject(
                message.linkedClients[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'intakeq.v1.Client';
        }
      }

      Client.prototype.clientId = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      Client.prototype.name = null;
      Client.prototype.firstName = null;
      Client.prototype.lastName = null;
      Client.prototype.middleName = null;
      Client.prototype.emailAddress = null;
      Client.prototype.phoneNumber = null;
      Client.prototype.dateOfBirth = null;
      Client.prototype.maritalStatus = null;
      Client.prototype.gender = null;
      Client.prototype.tags = $util.emptyArray;
      Client.prototype.archived = false;
      Client.prototype.homePhone = null;
      Client.prototype.workPhone = null;
      Client.prototype.mobilePhone = null;
      Client.prototype.address = null;
      Client.prototype.unitNumber = null;
      Client.prototype.streetAddress = null;
      Client.prototype.city = null;
      Client.prototype.state = null;
      Client.prototype.postalCode = null;
      Client.prototype.country = null;
      Client.prototype.stateShort = null;
      Client.prototype.additionalInformation = null;
      Client.prototype.billingType = 0;
      Client.prototype.primaryInsuranceCompany = null;
      Client.prototype.primaryInsurancePayerId = null;
      Client.prototype.primaryInsurancePolicyNumber = null;
      Client.prototype.primaryInsuranceGroupNumber = null;
      Client.prototype.primaryInsuranceHolderName = null;
      Client.prototype.primaryInsuranceHolderRelationship = null;
      Client.prototype.primaryInsuranceHolderDateOfBirth = null;
      Client.prototype.secondaryInsuranceCompany = null;
      Client.prototype.secondaryInsurancePayerId = null;
      Client.prototype.secondaryInsurancePolicyNumber = null;
      Client.prototype.secondaryInsuranceGroupNumber = null;
      Client.prototype.secondaryInsuranceHolderName = null;
      Client.prototype.secondaryInsuranceHolderRelationship = null;
      Client.prototype.secondaryInsuranceHolderDateOfBirth = null;
      Client.prototype.secondaryRelationshipToInsured = null;
      Client.prototype.createdAt = null;
      Client.prototype.lastActivityAt = null;
      Client.prototype.practitionerId = null;
      Client.prototype.customFields = $util.emptyArray;
      Client.prototype.guid = null;
      Client.prototype.lastUpdatedAt = null;
      Client.prototype.stripeCustomerId = null;
      Client.prototype.squareCustomerId = null;
      Client.prototype.timezoneId = null;
      Client.prototype.primaryInsurancePlan = null;
      Client.prototype.primaryInsuredGender = null;
      Client.prototype.primaryInsuredCity = null;
      Client.prototype.primaryInsuredZipCode = null;
      Client.prototype.primaryInsuredState = null;
      Client.prototype.primaryInsuredStreetAddress = null;
      Client.prototype.externalClientId = null;
      Client.prototype.creditBalance = 0;
      Client.prototype.lastActivityName = null;
      Client.prototype.parentClientId = null;
      Client.prototype.linkedClients = $util.emptyArray;

      return Client;
    })();

    v1.IntakeQEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'INTAKEQ_APPOINTMENT_SCHEDULED')] = 1;
      values[(valuesById[2] = 'INTAKEQ_APPOINTMENT_CANCELLED')] = 2;
      return values;
    })();

    v1.BillingType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_UNKNOWN_BILLING_TYPE')] = 0;
      values[(valuesById[1] = 'SELF_PAY')] = 1;
      values[(valuesById[2] = 'INSURANCE')] = 2;
      return values;
    })();

    v1.RelationshipType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_UNKNOWN_RELATIONSHIP_TYPE')] = 0;
      values[(valuesById[1] = 'PARENT')] = 1;
      values[(valuesById[2] = 'CHILD')] = 2;
      values[(valuesById[3] = 'SPOUSE')] = 3;
      values[(valuesById[4] = 'SIBLING')] = 4;
      values[(valuesById[5] = 'OTHER')] = 5;
      values[(valuesById[6] = 'PARTNER')] = 6;
      return values;
    })();

    v1.IntakeQService = (() => {
      class IntakeQService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new IntakeQService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (IntakeQService.prototype.getClient = function getClient(
          request,
          callback
        ) {
          return this.rpcCall(
            getClient,
            $root.intakeq.v1.GetClientRequest,
            $root.intakeq.v1.GetClientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetClient' }
      );

      Object.defineProperty(
        (IntakeQService.prototype.upsertClient = function upsertClient(
          request,
          callback
        ) {
          return this.rpcCall(
            upsertClient,
            $root.intakeq.v1.UpsertClientRequest,
            $root.intakeq.v1.UpsertClientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpsertClient' }
      );

      Object.defineProperty(
        (IntakeQService.prototype.updateClientTag = function updateClientTag(
          request,
          callback
        ) {
          return this.rpcCall(
            updateClientTag,
            $root.intakeq.v1.UpdateClientTagRequest,
            $root.intakeq.v1.UpdateClientTagResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpdateClientTag' }
      );

      return IntakeQService;
    })();

    return v1;
  })();

  return intakeq;
})();

export const intakeq = $root.intakeq;
