import { ColumnType } from '../../../../Table';
import { Flexbox } from '../../../../Flexbox';
import { ParamSourceConfigProps } from '../../../types';
import { ParameterConfig } from '../../../../ParameterConfig';
import { ProfileUpdateIdentifiers } from './types';
import { SourceConfig } from '../../../../SourceConfig';
import { Text } from '../../../../Typography';
import { useTheme } from '@morf/theming';

export const columns: ColumnType<ParamSourceConfigProps> = [
  {
    id: 'param',
    accessorKey: 'param',
    header: () => {
      const theme = useTheme();

      return (
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='2.25rem'
          gap={0}
        >
          <Text tag='h5' color={theme.colors.text.body}>
            Profile IDs
          </Text>
        </Flexbox>
      );
    },
    cell: (row) => {
      const param = row.getValue<ParamSourceConfigProps['param']>();
      return <ParameterConfig {...param} />;
    },
    cellBorderType: 'borderTop',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    size: 240,
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: () => {
      const theme = useTheme();

      return (
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='2.25rem'
          gap={0}
        >
          <Text tag='h5' color={theme.colors.text.body}>
            Source event fields
          </Text>
        </Flexbox>
      );
    },
    cell: (row) => {
      const source = row.getValue<ProfileUpdateIdentifiers['source']>();
      return <SourceConfig {...source} />;
    },
    cellBorderType: 'borderTopLeft',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    size: 300,
  },
];
