import { Option } from '../Dropdown/types';

export const formatEnumToOptions = (
  enumType: Record<string, any>
): Option[] => {
  const options: Option[] = [];

  for (const key in enumType) {
    const enumKey = key as keyof typeof enumType;
    const enumValue = enumType[enumKey];
    if (isNaN(Number(key)) && enumValue !== 0) {
      const formattedLabel = key
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) =>
          index === 0 ? match.toUpperCase() : match
        );

      options.push({
        value: enumValue.toString(),
        label: formattedLabel,
      });
    }
  }

  return options;
};
