import Link from 'next/link';
import { Container } from '../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Tooltip } from '../../Tooltip';
import { WorkflowExecutionHeaderButtonProps } from './types';
import { copyTextToClipboard } from '../../Helpers/copyTextToClipboard';
import { useTheme } from '@morf/theming';

const WorkflowExecutionHeaderButton: FC<WorkflowExecutionHeaderButtonProps> = ({
  copyText,
  iconName,
  link,
  tooltipText,
}) => {
  const theme = useTheme();

  const content = (
    <Tooltip
      tooltipDirection='top'
      arrowDirection='left'
      tooltipText={tooltipText}
    >
      <Flexbox
        direction='row'
        justifyContent='center'
        alignItems='center'
        width='1.5rem'
        height='1.5rem'
        borderRadius={0.25}
        borderType='border'
        backgroundColor={theme.colors.ui.card}
        hoverBackgroundColor={theme.colors.main.light.light}
        cursor='pointer'
      >
        <Icon
          name={iconName}
          size={1}
          stroke={theme.colors.text.muted}
          strokeWidth={1.75}
        />
      </Flexbox>
    </Tooltip>
  );

  return copyText ? (
    <Container
      width='auto'
      height='auto'
      onClick={() => copyTextToClipboard(copyText)}
    >
      {content}
    </Container>
  ) : link ? (
    <Link href={link} target='_blank' style={{ width: 'auto' }}>
      {content}
    </Link>
  ) : null;
};

export const MemoizedWorkflowExecutionHeaderButton = memo(
  WorkflowExecutionHeaderButton
);
