import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { EmptyTableProps } from './types';
import { useTheme } from '@morf/theming';

const EmptyTableBody: FC<EmptyTableProps> = ({ numberOfColumns, text }) => {
  const theme = useTheme();
  return (
    <tr>
      <td colSpan={numberOfColumns}>
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p1' color={theme.colors.text.muted}>
            {text}
          </Text>
        </Flexbox>
      </td>
    </tr>
  );
};

export const MemoizedEmptyTableBody = memo(EmptyTableBody);
