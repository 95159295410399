import { profiles } from '@morf/proto/profiles_v1_ts_proto';

export const calculateProfileLookupSubflowHeight = (
  configuration: profiles.v1.LookupConfiguration | null | undefined
) => {
  if (!configuration?.lookupsSequence.length) {
    return 0;
  }

  const lookupsLength = configuration?.lookupsSequence.length;
  const lookupsSequenceHeight = lookupsLength * 300;
  const returnWorkflowNodeHeight = 100;
  const subflowHeight = lookupsSequenceHeight + returnWorkflowNodeHeight;
  return subflowHeight;
};
