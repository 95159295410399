import { FC, useState } from 'react';
import { useTheme } from '@morf/theming';
import { ProviderTooltipProps } from './types';
import { Tooltip } from '../Tooltip';
import { Text } from '../Typography';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { useResize } from '../Hooks/useResize';

export const ProviderTooltip: FC<ProviderTooltipProps> = ({
  qualifications,
  detailedQualifications,
  arrowDirection,
  tooltipDirection,
}) => {
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const responsiveTooltip = useResize({
    tooltipDirection: {
      xxl: tooltipDirection,
      xl: tooltipDirection,
      lg: tooltipDirection,
      md: tooltipDirection,
      sm: 'top',
      xs: 'top',
    },
    width: {
      xxl: '20rem',
      xl: '15rem',
      lg: '10rem',
      md: '15rem',
      sm: '25rem',
      xs: '10rem',
    },
  });

  return (
    <Tooltip
      tooltipWidth={responsiveTooltip.width}
      tooltipDirection={responsiveTooltip.tooltipDirection}
      arrowDirection={arrowDirection}
      getHoverState={setIsHovered}
      tooltipText={detailedQualifications.join(', ')}
    >
      <Flexbox
        justifyContent={arrowDirection === 'center' ? 'center' : 'flex-start'}
        cursor='pointer'
        alignItems='center'
        gap={0.25}
      >
        <Text tag='p2' whiteSpace='normal'>
          {qualifications.join(', ')}
        </Text>
        {isHovered ? (
          <Icon
            name='info-solid'
            fill={theme.colors.main.primary.darker}
            stroke='none'
            cursor='pointer'
            viewBox='0 0 18 18'
          />
        ) : (
          <Icon
            name='info'
            fill={theme.colors.main.primary.darker}
            stroke='none'
            cursor='pointer'
            viewBox='0 0 18 18'
          />
        )}
      </Flexbox>
    </Tooltip>
  );
};
