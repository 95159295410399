import { google } from '@morf/proto/checked_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { hasField } from '../../../../Helpers/hasField';
import { formatType } from '../../../../Helpers/formatType';
import _ from 'lodash';

const formatListElementType = (
  fieldType:
    | workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType
    | null
    | undefined
): string | undefined => {
  if (fieldType) {
    if (hasField(fieldType, 'primitiveType')) {
      return google.api.expr.v1alpha1.Type.PrimitiveType[
        fieldType.primitiveType
      ];
    }
    if (hasField(fieldType, 'wellKnownType')) {
      return google.api.expr.v1alpha1.Type.WellKnownType[
        fieldType.wellKnownType
      ];
    }
    if (hasField(fieldType, 'extendedType')) {
      return workflow_parameters.v1.ExtendedType.Type[fieldType.extendedType];
    }
  }
};

export const formatDynamicDestinationActionParameterType = (
  fieldType:
    | workflow_parameters.v1.DynamicDestinationActionParameterType
    | null
    | undefined
): string => {
  if (fieldType) {
    if (hasField(fieldType, 'listType')) {
      return `${_.toLower(
        formatListElementType(fieldType.listType!.elemType)
      )}[]`;
    }
    if (hasField(fieldType, 'mapType')) {
      return `map <${formatType(fieldType.mapType!.keyType)},${formatType(
        fieldType.mapType!.valueType
      )}>`;
    }
  }

  return '';
};
