export const Info = () => {
  return (
    <>
      <mask
        id='mask0_610_1113'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.667969 0.666504H17.3346V17.3332H0.667969V0.666504Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_610_1113)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.38797 1.9165C3.28047 1.9165 1.91797 3.36067 1.91797 5.5965V12.4032C1.91797 14.639 3.28047 16.0832 5.38797 16.0832H12.6113C14.7213 16.0832 16.0846 14.639 16.0846 12.4032V5.5965C16.0846 3.36067 14.7213 1.9165 12.613 1.9165H5.38797ZM12.6113 17.3332H5.38797C2.56464 17.3332 0.667969 15.3515 0.667969 12.4032V5.5965C0.667969 2.64817 2.56464 0.666504 5.38797 0.666504H12.613C15.4371 0.666504 17.3346 2.64817 17.3346 5.5965V12.4032C17.3346 15.3515 15.4371 17.3332 12.6113 17.3332Z'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99609 12.9583C8.65109 12.9583 8.37109 12.6783 8.37109 12.3333V9C8.37109 8.655 8.65109 8.375 8.99609 8.375C9.34109 8.375 9.62109 8.655 9.62109 9V12.3333C9.62109 12.6783 9.34109 12.9583 8.99609 12.9583Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00156 6.67008C8.54073 6.67008 8.16406 6.29758 8.16406 5.83675C8.16406 5.37592 8.53323 5.00342 8.99323 5.00342H9.00156C9.4624 5.00342 9.8349 5.37592 9.8349 5.83675C9.8349 6.29758 9.4624 6.67008 9.00156 6.67008Z'
      />
    </>
  );
};

export const SolidInfo = () => {
  return (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.39297 0.666016H12.618C15.443 0.666016 17.3346 2.64935 17.3346 5.59935V12.4085C17.3346 15.3502 15.443 17.3327 12.618 17.3327H5.39297C2.56797 17.3327 0.667969 15.3502 0.667969 12.4085V5.59935C0.667969 2.64935 2.56797 0.666016 5.39297 0.666016ZM8.99297 6.55018C8.6013 6.55018 8.2763 6.22435 8.2763 5.82518C8.2763 5.41685 8.6013 5.09185 9.00964 5.09185C9.40964 5.09185 9.73464 5.41685 9.73464 5.82518C9.73464 6.22435 9.40964 6.55018 8.99297 6.55018ZM9.7263 12.1502C9.7263 12.5502 9.4013 12.8752 8.99297 12.8752C8.59297 12.8752 8.26797 12.5502 8.26797 12.1502V8.46685C8.26797 8.06602 8.59297 7.73352 8.99297 7.73352C9.4013 7.73352 9.7263 8.06602 9.7263 8.46685V12.1502Z'
    />
  );
};
