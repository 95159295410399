import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { google } from '@morf/proto/struct_ts_proto';

export const mockWorkflowEventPayloadFieldSchema: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[] =
  [
    new workflow_parameters.v1.WorkflowEventPayloadFieldSchema({
      sensitivities: [1, 2, 3],
      fieldIndex: 'answers',
      description: 'Received Form variable values',
      exampleValue: new google.protobuf.Value({
        structValue: {
          fields: {
            email_address: new google.protobuf.Value({
              stringValue: 'johndoe@example.com',
            }),
            other_variable: new google.protobuf.Value({
              stringValue: 'Example Answer',
            }),
            phone_number: new google.protobuf.Value({
              stringValue: '+15551234122',
            }),
          },
        },
      }),
      dynamic:
        new workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic({
          dynamicParameterConfig:
            new workflow_parameters.v1.DynamicDestinationActionParameterConfig({
              objectSource:
                new workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource(
                  {
                    eventPayloadFieldLookup:
                      new workflow_parameters.v1.EventPayloadFieldLookup({
                        celExpressionStr: 'answers',
                      }),
                  }
                ),
            }),
        }),
    }),
    new workflow_parameters.v1.WorkflowEventPayloadFieldSchema({
      sensitivities: [],
      fieldIndex: 'responder_uuid',
      description: 'Unique form responder ID (v4 UUID)',
      exampleValue: new google.protobuf.Value({
        stringValue: '85b7133b-84db-486e-b721-7a63f2dde525',
      }),
      static: {
        staticParameterConfig:
          new workflow_parameters.v1.StaticDestinationActionParameterConfig({
            source: new workflow_parameters.v1.DestinationActionParameterSource(
              {
                eventPayloadFieldLookup:
                  new workflow_parameters.v1.EventPayloadFieldLookup({
                    celExpressionStr: 'responder_uuid',
                  }),
              }
            ),
          }),
        staticParameterType:
          new workflow_parameters.v1.StaticDestinationActionParameterType({
            primitiveType: 5,
          }),
      },
    }),
  ];
