import { css, styled } from '@morf/theming';
import { StepCardProps } from './types';
import { Flexbox } from '../Flexbox';

const Active = css(
  ({ theme }) => `
    background-color: ${theme.colors.ui.card};
    border-color: ${theme.colors.main.primary.darker};
    `
);
const Disabled = css(
  ({ theme }) => `
    background-color: ${theme.colors.ui.divider};
    border-color: ${theme.colors.ui.divider};
    `
);

const Completed = css(
  ({ theme }) => `
    background-color: ${theme.colors.main.dark.darkest};
    border-color: ${theme.colors.main.dark.darkest};
    `
);

const handleState = (state: StepCardProps['state']) => {
  switch (state) {
    case 'active':
      return Active;
    case 'completed':
      return Completed;
    case 'disabled':
      return Disabled;
  }
};

export const StyledNumber = styled(Flexbox)<{ state: StepCardProps['state'] }>`
  ${(props) => handleState(props.state)};
`;
