import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { StyledTabButton } from './Tabs.css';
import { TabsProps } from './types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const Tabs: FC<TabsProps> = ({ children, initialValue, onChange, height }) => {
  const theme = useTheme();
  const isSelected = (index: number) => index === initialValue;

  const tabs = children.map(
    (child, index) =>
      child && (
        <StyledTabButton
          data-testid={`tab-${index}`}
          key={index}
          isSelected={isSelected(index)}
          onClick={(e) => {
            e.preventDefault();
            onChange(index);
          }}
        >
          <Text
            tag={isSelected(index) ? 'h4' : 'p1'}
            color='inherit'
            whiteSpace='nowrap'
          >
            {child.props.label}
          </Text>
        </StyledTabButton>
      )
  );

  const tabContent = children.filter((_child, index) => isSelected(index));

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      height={height}
      gap={0}
    >
      <Flexbox
        justifyContent='flex-start'
        backgroundColor={theme.colors.ui.card}
        borderType='borderBottom'
        overflowX='scroll'
        height='3rem'
        gap={0}
      >
        {tabs}
      </Flexbox>
      <Container data-testid='tab-content' height='calc(100% - 3rem)'>
        {tabContent}
      </Container>
    </Flexbox>
  );
};

export const MemoizedTabs = memo(Tabs);
