export const Slack = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.7711 23.9523C11.7711 25.5145 10.5085 26.7771 8.94634 26.7771C7.38416 26.7771 6.12158 25.5145 6.12158 23.9523C6.12158 22.3902 7.38416 21.1276 8.94634 21.1276H11.7711V23.9523ZM13.1835 23.9523C13.1835 22.3902 14.446 21.1276 16.0082 21.1276C17.5704 21.1276 18.833 22.3902 18.833 23.9523V31.0142C18.833 32.5764 17.5704 33.839 16.0082 33.839C14.446 33.839 13.1835 32.5764 13.1835 31.0142V23.9523Z'
        fill='#E01E5A'
      />
      <path
        d='M16.0086 12.6105C14.4465 12.6105 13.1839 11.3479 13.1839 9.78575C13.1839 8.22358 14.4465 6.961 16.0086 6.961C17.5708 6.961 18.8334 8.22358 18.8334 9.78575V12.6105H16.0086ZM16.0086 14.0443C17.5708 14.0443 18.8334 15.3069 18.8334 16.869C18.8334 18.4312 17.5708 19.6938 16.0086 19.6938H8.92534C7.36317 19.6938 6.10059 18.4312 6.10059 16.869C6.10059 15.3069 7.36317 14.0443 8.92534 14.0443H16.0086Z'
        fill='#36C5F0'
      />
      <path
        d='M27.329 16.869C27.329 15.3069 28.5916 14.0443 30.1537 14.0443C31.7159 14.0443 32.9785 15.3069 32.9785 16.869C32.9785 18.4312 31.7159 19.6938 30.1537 19.6938H27.329V16.869ZM25.9166 16.869C25.9166 18.4312 24.654 19.6938 23.0918 19.6938C21.5297 19.6938 20.2671 18.4312 20.2671 16.869V9.78575C20.2671 8.22358 21.5297 6.961 23.0918 6.961C24.654 6.961 25.9166 8.22358 25.9166 9.78575V16.869Z'
        fill='#2EB67D'
      />
      <path
        d='M23.0918 28.1895C24.654 28.1895 25.9166 29.452 25.9166 31.0142C25.9166 32.5764 24.654 33.839 23.0918 33.839C21.5297 33.839 20.2671 32.5764 20.2671 31.0142V28.1895H23.0918ZM23.0918 26.7771C21.5297 26.7771 20.2671 25.5145 20.2671 23.9523C20.2671 22.3902 21.5297 21.1276 23.0918 21.1276H30.1751C31.7373 21.1276 32.9999 22.3902 32.9999 23.9523C32.9999 25.5145 31.7373 26.7771 30.1751 26.7771H23.0918Z'
        fill='#ECB22E'
      />
    </svg>
  );
};
