import _ from 'lodash';
import { PayloadField } from './PayloadField/types';

export const filterPayloadFields = (
  payloadFields: PayloadField[],
  searchValue: string
): PayloadField[] => {
  if (!searchValue) return payloadFields;
  return payloadFields.filter(({ field }) =>
    field?.fieldIndex?.toLowerCase().includes(searchValue.toLowerCase())
  );
};
