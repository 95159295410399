import Cookies from 'js-cookie';
import { STYTCH_SESSION_JWT } from '@morf/constants';
import { Toast } from '@morf/ui';
import { toast } from 'react-toastify';

export const copyStytchSessionJWT = () => {
  const refreshedJWT = Cookies.get(STYTCH_SESSION_JWT);
  refreshedJWT &&
    navigator.clipboard
      .writeText(refreshedJWT)
      .then(() => {
        toast(
          <Toast
            message='JWT copied successfully!'
            icon='check-circle'
            type='success'
          />
        );
      })
      .catch((_error) => {
        toast(
          <Toast
            message='Failed to copy JWT to clipboard!'
            icon='check-circle'
            type='success'
          />
        );
      });
};
