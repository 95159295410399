import { ActionSettings } from '../../../../ActionSettings';
import { FC, memo } from 'react';
import { FetchActionNodeProps } from '../types';
import { Flexbox } from '../../../../Flexbox';
import { Option } from '../../../../Dropdown/types';
import { RemoveNode } from '../../../../RemoveNode';
import { extractAction } from '../../../CustomEdge/AddEdge/extractAction';
import { formatEnumToOptions } from '../../../../Helpers/formatEnumToOptions';
import { handleNodeSave } from '../handleNodeSave';
import { hasDoNotAssociateThirdPartyId } from './hasDoNotAssociateThirdPartyId';
import { hasRequiredEnvironment } from './hasRequiredEnvironment';
import { hasRequiredThirdPartyIdMissingBehavior } from './hasRequiredThirdPartyIdMissingBehaviour';
import { healthie } from '@morf/proto/healthie_v1_ts_proto';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const FetchActionSettings: FC<FetchActionNodeProps> = (node) => {
  const { nodes, setNodes } = useWorkflow();
  const { id, data } = node;

  const {
    configuration,
    onAddNode,
    onRemoveNode,
    onUpdateNode,
    isReadOnly,
    isRequired,
  } = data.value;

  const { applicationType, applicationValue, actionType, actionValue } =
    extractAction(configuration);

  const requiredParameterMissingAction =
    configuration?.parameterMissingPolicy?.requiredParameterMissingBehavior;

  const optionalParameterMissingAction =
    configuration?.parameterMissingPolicy?.optionalParameterMissingBehavior;

  const requiredThirdPartyIdMissingAction =
    'requiredThirdPartyIdMissingBehavior' in actionValue
      ? actionValue.requiredThirdPartyIdMissingBehavior
      : undefined;

  const doNotAssociateThirdPartyId =
    'doNotAssociateThirdPartyId' in actionValue
      ? actionValue.doNotAssociateThirdPartyId
      : undefined;

  let environments: Option[] = [];
  let environment: healthie.v1.HealthieEnvironment | undefined = undefined;

  switch (applicationType) {
    case 'healthieV1':
      environments = formatEnumToOptions(healthie.v1.HealthieEnvironment);
      environment =
        'healthieEnvironment' in actionValue
          ? actionValue.healthieEnvironment
          : undefined;
      break;
    default:
      break;
  }

  const handleRequiredParameterMissingChange = (
    updatedRequiredParameterMissingAction: number
  ) => {
    if (configuration) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        parameterMissingPolicy:
          new workflow_parameters.v1.ParameterMissingPolicy({
            ...configuration.parameterMissingPolicy,
            requiredParameterMissingBehavior:
              updatedRequiredParameterMissingAction,
          }),
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleOptionalParameterMissingChange = (
    updatedOptionalParameterMissingAction: number
  ) => {
    if (configuration) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        parameterMissingPolicy:
          new workflow_parameters.v1.ParameterMissingPolicy({
            ...configuration.parameterMissingPolicy,
            optionalParameterMissingBehavior:
              updatedOptionalParameterMissingAction,
          }),
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleRequiredThirdPartyIdMissingChange = (
    updatedRequiredThirdPartyIdMissingAction: number
  ) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            requiredThirdPartyIdMissingBehavior:
              updatedRequiredThirdPartyIdMissingAction,
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleDoNotAssociateThirdPartyIdChange = (
    updatedDoNotAssociateThirdPartyId: boolean
  ) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            doNotAssociateThirdPartyId: updatedDoNotAssociateThirdPartyId,
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleEnvironmentChange = (updatedEnvironment: number) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            ...(applicationType === 'healthieV1' && {
              healthieEnvironment: updatedEnvironment,
            }),
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleRemoveNode = () => {
    onRemoveNode &&
      onRemoveNode({
        nodeId: node.id,
      });
  };

  return (
    <Flexbox
      data-testid='fetch-action-settings'
      direction='column'
      justifyContent='flex-start'
    >
      <ActionSettings
        doNotAssociateThirdPartyId={doNotAssociateThirdPartyId}
        environment={environment}
        environments={environments}
        includeDoNotAssociateThirdPartyId={hasDoNotAssociateThirdPartyId(
          actionType
        )}
        includeEnvironment={hasRequiredEnvironment(actionType)}
        includeRequiredThirdPartyIdMissing={hasRequiredThirdPartyIdMissingBehavior(
          actionType
        )}
        isReadOnly={isReadOnly}
        onDoNotAssociateThirdPartyIdChange={
          handleDoNotAssociateThirdPartyIdChange
        }
        onEnvironmentChange={handleEnvironmentChange}
        onOptionalParameterMissingChange={handleOptionalParameterMissingChange}
        onRequiredParameterMissingChange={handleRequiredParameterMissingChange}
        onRequiredThirdPartyIdMissingChange={
          handleRequiredThirdPartyIdMissingChange
        }
        optionalParameterMissingAction={optionalParameterMissingAction}
        requiredParameterMissingAction={requiredParameterMissingAction}
        requiredThirdPartyIdMissingAction={requiredThirdPartyIdMissingAction}
      />
      {!isReadOnly && (
        <RemoveNode type='fetch action' onRemoveNode={handleRemoveNode} />
      )}
    </Flexbox>
  );
};

export const MemoizedFetchActionSettings = memo(FetchActionSettings);
