import { FC, useEffect, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Text } from '../Typography';
import { PanelHeaderProps } from './types';

export const PanelHeader: FC<PanelHeaderProps> = ({
  panelTitle,
  isOpen,
  onClick,
}) => {
  const theme = useTheme();
  const [iconName, setIconName] = useState('payload');

  useEffect(() => {
    if (isOpen) {
      setIconName('payload-right');
    }
  }, [isOpen]);

  return (
    <Flexbox
      height='3.5rem'
      position='absolute'
      top='0'
      right='0'
      alignItems='center'
      width={isOpen ? '100%' : '3.5rem'}
      borderType='borderBottom'
      backgroundColor={theme.colors.main.light.normal}
      zIndex={'var(--table-cell-pinned-index)'}
      data-testid='panel-header'
    >
      {isOpen && (
        <Flexbox pl={1.25} alignItems='center'>
          <Text tag='h5'>{panelTitle}</Text>
        </Flexbox>
      )}
      <Flexbox
        backgroundColor={
          isOpen ? theme.colors.main.dark.light : theme.colors.ui.divider
        }
        alignItems='center'
        justifyContent='center'
        maxWidth='3.5rem'
        minWidth='3.5rem'
      >
        <Icon
          name={iconName}
          onClick={onClick}
          stroke={theme.colors.main.primary.darker}
          cursor='pointer'
          onMouseEnter={() => !isOpen && setIconName('payload-left')}
          onMouseLeave={() => !isOpen && setIconName('payload')}
        />
      </Flexbox>
    </Flexbox>
  );
};
