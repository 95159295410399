import { FC } from 'react';
import { ObjectGroupCardListProps } from './types';
import { Flexbox } from '../Flexbox';
import { ObjectGroupCard } from '../ObjectGroupCard';

export const ObjectGroupCardList: FC<ObjectGroupCardListProps> = ({
  groups,
}) => {
  return (
    <Flexbox direction='row' justifyContent='flex-start' flexWrap='wrap'>
      {groups.map((item, index) => (
        <ObjectGroupCard
          key={index}
          status={item.status}
          objectTypeLink={item.objectTypeLink}
          objectType={item.objectType}
          objectDescription={item.objectDescription}
          objectCategory={item.objectCategory}
          countOfVariants={item.countOfVariants}
          lastDeployed={item.lastDeployed}
          onClick={item.onClick}
          isLoading={item.isLoading}
        />
      ))}
    </Flexbox>
  );
};
