import { Container } from '../../../../../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../../Flexbox';
import { Icon } from '../../../../../../../Icon';
import { PayloadFieldProps } from './types';
import { Text, TooltipText } from '../../../../../../../Typography';
import { formatDynamicDestinationActionParameterType } from '../../../formatDynamicDestinationActionParameterType';
import { formatGoogleProtobufValue } from '../../../../../../../Helpers/formatGoogleProtobufValue';
import { formatSensitivities } from '../../../../../../../Helpers/formatSensitivities';
import { formatStaticDestinationActionParameterType } from '../../../formatStaticDestinationActionParameterType';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { getEventPayloadFieldLookup } from '../getEventPayloadFieldLookup';

const PayloadField: FC<PayloadFieldProps> = ({ onClick, payloadField }) => {
  const theme = useTheme();
  const { field, index, isSelected } = payloadField;

  const {
    description,
    dynamic: dynamicConfig,
    exampleValue,
    fieldIndex,
    sensitivities,
    static: staticConfig,
  } = field;

  const eventPayloadFieldLookup = getEventPayloadFieldLookup(
    payloadField.field
  );

  const tooltipDirection = index < 3 ? 'bottom' : 'top';

  const handleClick = () => {
    if (eventPayloadFieldLookup) {
      onClick(
        new workflow_parameters.v1.DestinationActionParameterSource({
          eventPayloadFieldLookup: eventPayloadFieldLookup,
        })
      );
    }
  };

  return (
    <Flexbox
      key={fieldIndex}
      data-testid='payload-field'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      cursor='pointer'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={handleClick}
      px={0.75}
      py={0.5}
      gap={0.5}
    >
      <Container width='auto' height='auto'>
        <Icon name='code-bracket' size={1} stroke={theme.colors.ui.dark} />
      </Container>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        maxWidth='20.5rem'
        width='auto'
        height='auto'
        gap={0}
      >
        <Text tag='p2'>{eventPayloadFieldLookup?.celExpressionStr}</Text>
        <Text
          tag='p3'
          color={theme.colors.support.green.darkest}
          whiteSpace='nowrap'
        >
          {JSON.stringify(formatGoogleProtobufValue(exampleValue))}
        </Text>
        <TooltipText
          tag='p3'
          color={theme.colors.text.muted}
          whiteSpace='nowrap'
          tooltipWidth='19.5rem'
          tooltipDirection={tooltipDirection}
        >
          {description}
        </TooltipText>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          width='auto'
          gap={0.25}
        >
          {staticConfig && (
            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formatStaticDestinationActionParameterType(
                staticConfig.staticParameterType
              )}
            </Text>
          )}

          {dynamicConfig && (
            <Text tag='p3' color={theme.colors.text.muted}>
              {formatDynamicDestinationActionParameterType(
                dynamicConfig.dynamicParameterType
              ) || 'dyn'}
            </Text>
          )}

          <Text tag='p3' color={theme.colors.text.muted}>
            {formatSensitivities(sensitivities)}
          </Text>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedPayloadField = memo(PayloadField);
