import { destinationactions as sprucedestinationactions } from '@morf/proto/destinationactions/spruce/v1_ts_proto';
import { destinationactions as acdestinationactions } from '@morf/proto/destinationactions/activecampaign/v1_ts_proto';
import { destinationactions as facebookdestinationactions } from '@morf/proto/destinationactions/facebook/v1_ts_proto';
import { destinationactions as customerdestinationactions } from '@morf/proto/destinationactions/customerio/v1_ts_proto';
import { destinationactions as googledestinationactions } from '@morf/proto/destinationactions/google/v1_ts_proto';
import { destinationactions as healthiedestinationactions } from '@morf/proto/destinationactions/healthie/v1_ts_proto';
import { destinationactions as morfdestinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { destinationactions as intakeqdestinationactions } from '@morf/proto/destinationactions/intakeq/v1_ts_proto';
import { destinationactions as segmentdestinationactions } from '@morf/proto/destinationactions/segment/v1_ts_proto';
import { destinationactions as slackdestinationactions } from '@morf/proto/destinationactions/slack/v1_ts_proto';
import { destinationactions as freshdeskdestinationactions } from '@morf/proto/destinationactions/freshdesk/v1_ts_proto';

export const createActionMap = (module: any) => {
  const actionMap: Record<string, any> = {};
  for (const key in module) {
    actionMap[key] = module[key];
  }
  return actionMap;
};

export const allActions = {
  ...createActionMap(sprucedestinationactions.spruce.v1),
  ...createActionMap(acdestinationactions.activecampaign.v1),
  ...createActionMap(facebookdestinationactions.facebook.v1),
  ...createActionMap(customerdestinationactions.customerio.v1),
  ...createActionMap(googledestinationactions.google.v1),
  ...createActionMap(healthiedestinationactions.healthie.v1),
  ...createActionMap(morfdestinationactions.morf.v1),
  ...createActionMap(intakeqdestinationactions.intakeq.v1),
  ...createActionMap(segmentdestinationactions.segment.v1),
  ...createActionMap(slackdestinationactions.slack.v1),
  ...createActionMap(freshdeskdestinationactions.freshdesk.v1),
};
