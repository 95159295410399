import _ from 'lodash';
import { Button } from '../Button';
import { FC, memo, useRef } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WorkflowExecutionTimelineProps } from './types';
import { formatCustomLabel } from '../Filters';
import { timing } from '@morf/proto/timing_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';
import {
  WorkflowExecutionRow,
  WorkflowExecutionRowLoading,
} from './WorkflowExecutionRow';

const WorkflowExecutionTimeline: FC<WorkflowExecutionTimelineProps> = ({
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  isRefetching,
  isRetrying,
  timeOption,
  workflowExecutionRows,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const initialLoading =
    isLoading || isRetrying || (isFetching && isRefetching);

  const handleScroll = () => {
    if (
      containerElement &&
      containerElement.scrollHeight - containerElement.scrollTop <=
        containerElement.clientHeight
    ) {
      fetchNextPage();
    }
  };

  const uniqueWorkflowExecutionRows = _.uniqBy(
    workflowExecutionRows,
    'workflowExecutionId'
  );

  const handleLoadAnotherHour = () => {
    if (timeOption) {
      const startTimestamp = new timing.v1.Timestamp({
        seconds: timeOption.timeRange.startTimestamp.seconds.subtract(3600),
        nanoseconds: timeOption.timeRange.startTimestamp.nanoseconds,
      });

      const endTimestamp = timeOption.timeRange.endTimestamp;

      const timeOptionIncludingExtraHour = {
        label: formatCustomLabel(startTimestamp, endTimestamp),
        timeRange: {
          startTimestamp,
          endTimestamp,
        },
      };
      updateUrlParam({ time: timeOptionIncludingExtraHour.label });
    }
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='workflow-execution-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {initialLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <WorkflowExecutionRowLoading key={index} />
        ))
      ) : _.isEmpty(workflowExecutionRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No executions
          </Text>
        </Flexbox>
      ) : (
        <>
          {uniqueWorkflowExecutionRows.map((row) => (
            <WorkflowExecutionRow key={row.workflowExecutionId} {...row} />
          ))}

          {hasNextPage ? (
            isFetching && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <WorkflowExecutionRowLoading key={index} />
                ))}
              </>
            )
          ) : (
            <Flexbox
              direction='column'
              justifyContent='center'
              alignItems='center'
              p={1}
            >
              <Text tag='p2' align='center'>
                {timeOption
                  ? `No more executions within ${timeOption.label}`
                  : 'No more executions'}
              </Text>

              {timeOption && (
                <Button
                  size='base'
                  variant='primary'
                  onClick={handleLoadAnotherHour}
                >
                  <Text tag='h5' color='inherit'>
                    Load another hour
                  </Text>
                </Button>
              )}
            </Flexbox>
          )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionTimeline = memo(
  WorkflowExecutionTimeline
);
