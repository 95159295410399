import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { Edge, Node } from 'reactflow';

export const profileLookupSubflowProfileNotFoundAction = (
  configuration: profiles.v1.LookupConfiguration | null | undefined,
  lookupsLength: number
) => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];

  switch (configuration?.noProfileFoundAction) {
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_CREATE_LEAD:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: 'actionNode',
        data: {
          value: { name: 'create lead' },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-profile-not-found-action-lookup-subflow-return-to-workflow`,
        source: `lookup-subflow-profile-not-found-action`,
        target: `lookup-subflow-return-to-workflow`,
      });

      break;
    case profiles.v1.NoProfileFoundAction
      .NO_PROFILE_FOUND_ACTION_CREATE_PATIENT:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: 'actionNode',
        data: {
          value: { name: 'create patient' },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-profile-not-found-action-lookup-subflow-return-to-workflow`,
        source: `lookup-subflow-profile-not-found-action`,
        target: `lookup-subflow-return-to-workflow`,
      });

      break;
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_ERROR:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: 'errorNode',
        data: {},
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,

        position: { x: 0, y: 100 },
      });
      break;
    case profiles.v1.NoProfileFoundAction
      .NO_PROFILE_FOUND_ACTION_TERMINATE_WORKFLOW_GRACEFULLY:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: 'terminateNode',
        data: {},
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });
      break;
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_RETRY:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: 'retryNode',
        data: {},
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });
      break;
  }
  return { nodes, edges };
};
