import { FC } from 'react';
import { WorkflowTagProps } from './types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { StyledButton } from './WorkflowTag.css';

export const WorkflowTag: FC<WorkflowTagProps> = ({
  name,
  isSelected = false,
  onClick,
}) => {
  const theme = useTheme();
  const isDisabled = !onClick;

  return (
    <StyledButton
      type='button'
      variant='custom'
      onClick={onClick}
      isDisabled={isDisabled}
      color={
        isSelected ? theme.colors.main.primary.darker : theme.colors.text.body
      }
      borderColor={
        isSelected ? theme.colors.main.primary.darker : theme.colors.ui.divider
      }
      backgroundColor={theme.colors.ui.card}
      activeColor={theme.colors.main.primary.darker}
      borderRadius={1.25}
    >
      <Text tag={isDisabled ? 'p3' : 'p2'} whiteSpace='nowrap' color='inherit'>
        {name}
      </Text>
    </StyledButton>
  );
};
