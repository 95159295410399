export const ChevronUp = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M4.5 15.75l7.5-7.5 7.5 7.5'
    />
  );
};

export const ChevronDown = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M19.5 8.25l-7.5 7.5-7.5-7.5'
    />
  );
};

export const ChevronLeft = () => {
  return (
    <path
      id='chevron-left'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M15.75 19.5L8.25 12l7.5-7.5'
    />
  );
};

export const ChevronRight = () => {
  return (
    <path
      id='chevron-right'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M8.25 4.5l7.5 7.5-7.5 7.5'
    />
  );
};

export const DoubleChevronRight = () => {
  return (
    <path d='M11.75 4.5L19.25 12L11.75 19.5M5.75 4.5L13.25 12L5.75 19.5' />
  );
};
