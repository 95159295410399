import { TableHeadCell } from './TableHeadCell';
import { TableHeadProps } from './types';
import { memo } from 'react';

const Head: <RowData>(props: TableHeadProps<RowData>) => JSX.Element = ({
  headerGroups,
  onRowAdd,
  ...props
}) => {
  return (
    <thead data-testid='head-group'>
      {headerGroups.map((headerGroup, index) => (
        <tr key={index}>
          {headerGroup.headers.map((header, index) => {
            const isLastCell = index === headerGroup.headers.length - 1;
            return (
              <TableHeadCell
                key={index}
                header={header}
                isLastCell={isLastCell}
                onRowAdd={onRowAdd}
                {...props}
              />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export const TableHead = memo(Head) as typeof Head;
