import { TimeOption } from './TimeDropdown/TimeOption/types';
import { parseTimeRange } from './parseTimeRange';
import { timeOptions } from './TimeDropdown/TimeOption/timeOptions';

export const getMatchingTimeOption = (
  label: string | undefined
): TimeOption | undefined => {
  if (!label) return undefined;

  const matchingTimeOption = label.includes('-')
    ? parseTimeRange(label)
    : timeOptions.find((option) => option.label === label);

  return matchingTimeOption;
};
