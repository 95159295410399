import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { Edge, Node } from 'reactflow';

export const profileLookupSubflowFieldNotFound = (
  index: number,
  lookup: profiles.v1.Lookup,
  lookupsLength: number
) => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-field-not-found-${index}`,
    type: 'branchNode',
    data: {
      value: {
        name: 'field not found',
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: 250, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-field-not-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-field-not-found-${index}`,
  });

  switch (lookup.fieldNotPresentAction) {
    case profiles.v1.FieldNotPresentAction.FIELD_NOT_PRESENT_ACTION_ERROR:
      nodes.push({
        id: `lookup-subflow-error-${index}`,
        type: 'errorNode',
        data: {},
        parentNode: `lookup-subflow-field-not-found-${index}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-field-not-found-${index}-error-${index}`,
        source: `lookup-subflow-field-not-found-${index}`,
        target: `lookup-subflow-error-${index}`,
      });
      break;

    case profiles.v1.FieldNotPresentAction
      .FIELD_NOT_PRESENT_ACTION_SKIP_TO_NEXT_LOOKUP_IN_SEQUENCE:
      if (index < lookupsLength - 1) {
        edges.push({
          id: `lookup-subflow-field-not-found-${index}-field-lookup-${
            index + 1
          }`,
          source: `lookup-subflow-field-not-found-${index}`,
          target: `lookup-subflow-field-lookup-${index + 1}`,
        });
      }
      break;

    case profiles.v1.FieldNotPresentAction
      .FIELD_NOT_PRESENT_ACTION_TERMINATE_WORKFLOW_GRACEFULLY:
      nodes.push({
        id: `lookup-subflow-terminate-${index}`,
        type: 'terminateNode',
        data: {},
        parentNode: `lookup-subflow-field-not-found-${index}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-field-not-found-${index}-terminate-${index}`,
        source: `lookup-subflow-field-not-found-${index}`,
        target: `lookup-subflow-terminate-${index}`,
      });

    default:
      break;
  }

  return { nodes, edges };
};
