'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$2 } from './empty_ts_proto';
import { google as google$3 } from './struct_ts_proto';
import { google as google$1 } from './syntax_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.api = (() => {
    const api = {};
    api.expr = (() => {
      const expr = {};
      expr.v1alpha1 = (() => {
        const v1alpha1 = {};
        v1alpha1.CheckedExpr = (() => {
          class CheckedExpr {
            constructor(properties) {
              this.referenceMap = {};
              this.typeMap = {};
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new CheckedExpr(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.referenceMap != null &&
                Object.hasOwnProperty.call(message, 'referenceMap')
              ) {
                for (const key of Object.keys(message.referenceMap)) {
                  writer.uint32(18).fork().uint32(8).int64(key);
                  $root.google.api.expr.v1alpha1.Reference.encode(
                    message.referenceMap[key],
                    writer.uint32(18).fork()
                  )
                    .ldelim()
                    .ldelim();
                }
              }
              if (
                message.typeMap != null &&
                Object.hasOwnProperty.call(message, 'typeMap')
              ) {
                for (const key of Object.keys(message.typeMap)) {
                  writer.uint32(26).fork().uint32(8).int64(key);
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.typeMap[key],
                    writer.uint32(18).fork()
                  )
                    .ldelim()
                    .ldelim();
                }
              }
              if (
                message.sourceInfo != null &&
                Object.hasOwnProperty.call(message, 'sourceInfo')
              ) {
                google$1.api.expr.v1alpha1.SourceInfo.encode(
                  message.sourceInfo,
                  writer.uint32(42).fork()
                ).ldelim();
              }
              if (
                message.exprVersion != null &&
                Object.hasOwnProperty.call(message, 'exprVersion')
              ) {
                writer.uint32(50).string(message.exprVersion);
              }
              if (
                message.expr != null &&
                Object.hasOwnProperty.call(message, 'expr')
              ) {
                google$1.api.expr.v1alpha1.Expr.encode(
                  message.expr,
                  writer.uint32(34).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new CheckedExpr();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 2: {
                    if (message.referenceMap === $util.emptyObject)
                      message.referenceMap = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                    value = null;
                    while (reader.pos < end2) {
                      let tag2 = reader.uint32();
                      switch (tag2 >>> 3) {
                        case 1: {
                          key = reader.int64();
                          break;
                        }
                        case 2: {
                          value =
                            $root.google.api.expr.v1alpha1.Reference.decode(
                              reader,
                              reader.uint32()
                            );
                          break;
                        }
                      }
                      message.referenceMap[key] = value;
                    }
                    break;
                  }
                  case 3: {
                    if (message.typeMap === $util.emptyObject)
                      message.typeMap = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                    value = null;
                    while (reader.pos < end2) {
                      let tag2 = reader.uint32();
                      switch (tag2 >>> 3) {
                        case 1: {
                          key = reader.int64();
                          break;
                        }
                        case 2: {
                          value = $root.google.api.expr.v1alpha1.Type.decode(
                            reader,
                            reader.uint32()
                          );
                          break;
                        }
                      }
                      message.typeMap[key] = value;
                    }
                    break;
                  }
                  case 5: {
                    message.sourceInfo =
                      google$1.api.expr.v1alpha1.SourceInfo.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 6: {
                    message.exprVersion = reader.string();
                    break;
                  }
                  case 4: {
                    message.expr = google$1.api.expr.v1alpha1.Expr.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (
                object instanceof $root.google.api.expr.v1alpha1.CheckedExpr
              ) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.CheckedExpr();
              if (object.referenceMap) {
                if (typeof object.referenceMap !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.CheckedExpr.referenceMap: object expected, but got ' +
                      typeof object.referenceMap
                  );
                }
                message.referenceMap = {};
                for (
                  let keys = Object.keys(object.referenceMap), i = 0;
                  i < keys.length;
                  ++i
                ) {
                  if (typeof object.referenceMap[keys[i]] !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.CheckedExpr.value: object expected, but got ' +
                        typeof object.referenceMap[keys[i]]
                    );
                  }
                  message.referenceMap[keys[i]] =
                    $root.google.api.expr.v1alpha1.Reference.fromObject(
                      object.referenceMap[keys[i]]
                    );
                }
              }
              if (object.typeMap) {
                if (typeof object.typeMap !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.CheckedExpr.typeMap: object expected, but got ' +
                      typeof object.typeMap
                  );
                }
                message.typeMap = {};
                for (
                  let keys = Object.keys(object.typeMap), i = 0;
                  i < keys.length;
                  ++i
                ) {
                  if (typeof object.typeMap[keys[i]] !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.CheckedExpr.value: object expected, but got ' +
                        typeof object.typeMap[keys[i]]
                    );
                  }
                  message.typeMap[keys[i]] =
                    $root.google.api.expr.v1alpha1.Type.fromObject(
                      object.typeMap[keys[i]]
                    );
                }
              }
              if (object.sourceInfo != null) {
                if (typeof object.sourceInfo !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.CheckedExpr.sourceInfo: object expected, but got ' +
                      typeof object.sourceInfo
                  );
                }
                message.sourceInfo =
                  google$1.api.expr.v1alpha1.SourceInfo.fromObject(
                    object.sourceInfo
                  );
              }
              if (object.exprVersion != null) {
                message.exprVersion = String(object.exprVersion);
              }
              if (object.expr != null) {
                if (typeof object.expr !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.CheckedExpr.expr: object expected, but got ' +
                      typeof object.expr
                  );
                }
                message.expr = google$1.api.expr.v1alpha1.Expr.fromObject(
                  object.expr
                );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
                object.referenceMap = {};
                object.typeMap = {};
              }
              if (options.defaults) {
                object.sourceInfo = null;
                object.exprVersion = '';
                object.expr = null;
              }
              let keys;
              if (
                message.referenceMap &&
                (keys = Object.keys(message.referenceMap)).length
              ) {
                object.referenceMap = {};
                for (let i = 0; i < keys.length; ++i) {
                  object.referenceMap[keys[i]] =
                    $root.google.api.expr.v1alpha1.Reference.toObject(
                      message.referenceMap[keys[i]],
                      options
                    );
                }
              }
              if (
                message.typeMap &&
                (keys = Object.keys(message.typeMap)).length
              ) {
                object.typeMap = {};
                for (let i = 0; i < keys.length; ++i) {
                  object.typeMap[keys[i]] =
                    $root.google.api.expr.v1alpha1.Type.toObject(
                      message.typeMap[keys[i]],
                      options
                    );
                }
              }
              if (
                message.sourceInfo != null &&
                message.hasOwnProperty('sourceInfo')
              ) {
                object.sourceInfo =
                  google$1.api.expr.v1alpha1.SourceInfo.toObject(
                    message.sourceInfo,
                    options
                  );
              }
              if (
                message.exprVersion != null &&
                message.hasOwnProperty('exprVersion')
              ) {
                object.exprVersion = message.exprVersion;
              }
              if (message.expr != null && message.hasOwnProperty('expr')) {
                object.expr = google$1.api.expr.v1alpha1.Expr.toObject(
                  message.expr,
                  options
                );
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.CheckedExpr';
            }
          }

          CheckedExpr.prototype.referenceMap = $util.emptyObject;
          CheckedExpr.prototype.typeMap = $util.emptyObject;
          CheckedExpr.prototype.sourceInfo = null;
          CheckedExpr.prototype.exprVersion = '';
          CheckedExpr.prototype.expr = null;

          return CheckedExpr;
        })();

        v1alpha1.Type = (() => {
          class Type {
            get typeKind() {
              for (const key of [
                'dyn',
                'null',
                'primitive',
                'wrapper',
                'wellKnown',
                'listType',
                'mapType',
                'function',
                'messageType',
                'typeParam',
                'type',
                'error',
                'abstractType',
              ]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set typeKind(name) {
              for (const key of [
                'dyn',
                'null',
                'primitive',
                'wrapper',
                'wellKnown',
                'listType',
                'mapType',
                'function',
                'messageType',
                'typeParam',
                'type',
                'error',
                'abstractType',
              ]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Type(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.dyn != null &&
                Object.hasOwnProperty.call(message, 'dyn')
              ) {
                google$2.protobuf.Empty.encode(
                  message.dyn,
                  writer.uint32(10).fork()
                ).ldelim();
              }
              if (
                message.null != null &&
                Object.hasOwnProperty.call(message, 'null')
              ) {
                writer.uint32(16).int32(message.null);
              }
              if (
                message.primitive != null &&
                Object.hasOwnProperty.call(message, 'primitive')
              ) {
                writer.uint32(24).int32(message.primitive);
              }
              if (
                message.wrapper != null &&
                Object.hasOwnProperty.call(message, 'wrapper')
              ) {
                writer.uint32(32).int32(message.wrapper);
              }
              if (
                message.wellKnown != null &&
                Object.hasOwnProperty.call(message, 'wellKnown')
              ) {
                writer.uint32(40).int32(message.wellKnown);
              }
              if (
                message.listType != null &&
                Object.hasOwnProperty.call(message, 'listType')
              ) {
                $root.google.api.expr.v1alpha1.Type.ListType.encode(
                  message.listType,
                  writer.uint32(50).fork()
                ).ldelim();
              }
              if (
                message.mapType != null &&
                Object.hasOwnProperty.call(message, 'mapType')
              ) {
                $root.google.api.expr.v1alpha1.Type.MapType.encode(
                  message.mapType,
                  writer.uint32(58).fork()
                ).ldelim();
              }
              if (
                message.function != null &&
                Object.hasOwnProperty.call(message, 'function')
              ) {
                $root.google.api.expr.v1alpha1.Type.FunctionType.encode(
                  message.function,
                  writer.uint32(66).fork()
                ).ldelim();
              }
              if (
                message.messageType != null &&
                Object.hasOwnProperty.call(message, 'messageType')
              ) {
                writer.uint32(74).string(message.messageType);
              }
              if (
                message.typeParam != null &&
                Object.hasOwnProperty.call(message, 'typeParam')
              ) {
                writer.uint32(82).string(message.typeParam);
              }
              if (
                message.type != null &&
                Object.hasOwnProperty.call(message, 'type')
              ) {
                $root.google.api.expr.v1alpha1.Type.encode(
                  message.type,
                  writer.uint32(90).fork()
                ).ldelim();
              }
              if (
                message.error != null &&
                Object.hasOwnProperty.call(message, 'error')
              ) {
                google$2.protobuf.Empty.encode(
                  message.error,
                  writer.uint32(98).fork()
                ).ldelim();
              }
              if (
                message.abstractType != null &&
                Object.hasOwnProperty.call(message, 'abstractType')
              ) {
                $root.google.api.expr.v1alpha1.Type.AbstractType.encode(
                  message.abstractType,
                  writer.uint32(114).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Type();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.dyn = google$2.protobuf.Empty.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 2: {
                    message.null = reader.int32();
                    break;
                  }
                  case 3: {
                    message.primitive = reader.int32();
                    break;
                  }
                  case 4: {
                    message.wrapper = reader.int32();
                    break;
                  }
                  case 5: {
                    message.wellKnown = reader.int32();
                    break;
                  }
                  case 6: {
                    message.listType =
                      $root.google.api.expr.v1alpha1.Type.ListType.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 7: {
                    message.mapType =
                      $root.google.api.expr.v1alpha1.Type.MapType.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 8: {
                    message.function =
                      $root.google.api.expr.v1alpha1.Type.FunctionType.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 9: {
                    message.messageType = reader.string();
                    break;
                  }
                  case 10: {
                    message.typeParam = reader.string();
                    break;
                  }
                  case 11: {
                    message.type = $root.google.api.expr.v1alpha1.Type.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 12: {
                    message.error = google$2.protobuf.Empty.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  case 14: {
                    message.abstractType =
                      $root.google.api.expr.v1alpha1.Type.AbstractType.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Type) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Type();
              if (object.dyn != null) {
                if (typeof object.dyn !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.dyn: object expected, but got ' +
                      typeof object.dyn
                  );
                }
                message.dyn = google$2.protobuf.Empty.fromObject(object.dyn);
              }
              if (object.null != null) {
                switch (object.null) {
                  case 'NULL_VALUE':
                  case 0: {
                    message.null = 0;
                    break;
                  }
                  default: {
                    if (typeof object.null == 'number') {
                      message.null = object.null;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.primitive != null) {
                switch (object.primitive) {
                  case 'PRIMITIVE_TYPE_UNSPECIFIED':
                  case 0: {
                    message.primitive = 0;
                    break;
                  }
                  case 'BOOL':
                  case 1: {
                    message.primitive = 1;
                    break;
                  }
                  case 'INT64':
                  case 2: {
                    message.primitive = 2;
                    break;
                  }
                  case 'UINT64':
                  case 3: {
                    message.primitive = 3;
                    break;
                  }
                  case 'DOUBLE':
                  case 4: {
                    message.primitive = 4;
                    break;
                  }
                  case 'STRING':
                  case 5: {
                    message.primitive = 5;
                    break;
                  }
                  case 'BYTES':
                  case 6: {
                    message.primitive = 6;
                    break;
                  }
                  default: {
                    if (typeof object.primitive == 'number') {
                      message.primitive = object.primitive;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.wrapper != null) {
                switch (object.wrapper) {
                  case 'PRIMITIVE_TYPE_UNSPECIFIED':
                  case 0: {
                    message.wrapper = 0;
                    break;
                  }
                  case 'BOOL':
                  case 1: {
                    message.wrapper = 1;
                    break;
                  }
                  case 'INT64':
                  case 2: {
                    message.wrapper = 2;
                    break;
                  }
                  case 'UINT64':
                  case 3: {
                    message.wrapper = 3;
                    break;
                  }
                  case 'DOUBLE':
                  case 4: {
                    message.wrapper = 4;
                    break;
                  }
                  case 'STRING':
                  case 5: {
                    message.wrapper = 5;
                    break;
                  }
                  case 'BYTES':
                  case 6: {
                    message.wrapper = 6;
                    break;
                  }
                  default: {
                    if (typeof object.wrapper == 'number') {
                      message.wrapper = object.wrapper;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.wellKnown != null) {
                switch (object.wellKnown) {
                  case 'WELL_KNOWN_TYPE_UNSPECIFIED':
                  case 0: {
                    message.wellKnown = 0;
                    break;
                  }
                  case 'ANY':
                  case 1: {
                    message.wellKnown = 1;
                    break;
                  }
                  case 'TIMESTAMP':
                  case 2: {
                    message.wellKnown = 2;
                    break;
                  }
                  case 'DURATION':
                  case 3: {
                    message.wellKnown = 3;
                    break;
                  }
                  default: {
                    if (typeof object.wellKnown == 'number') {
                      message.wellKnown = object.wellKnown;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.listType != null) {
                if (typeof object.listType !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.listType: object expected, but got ' +
                      typeof object.listType
                  );
                }
                message.listType =
                  $root.google.api.expr.v1alpha1.Type.ListType.fromObject(
                    object.listType
                  );
              }
              if (object.mapType != null) {
                if (typeof object.mapType !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.mapType: object expected, but got ' +
                      typeof object.mapType
                  );
                }
                message.mapType =
                  $root.google.api.expr.v1alpha1.Type.MapType.fromObject(
                    object.mapType
                  );
              }
              if (object.function != null) {
                if (typeof object.function !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.function: object expected, but got ' +
                      typeof object.function
                  );
                }
                message.function =
                  $root.google.api.expr.v1alpha1.Type.FunctionType.fromObject(
                    object.function
                  );
              }
              if (object.messageType != null) {
                message.messageType = String(object.messageType);
              }
              if (object.typeParam != null) {
                message.typeParam = String(object.typeParam);
              }
              if (object.type != null) {
                if (typeof object.type !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.type: object expected, but got ' +
                      typeof object.type
                  );
                }
                message.type = $root.google.api.expr.v1alpha1.Type.fromObject(
                  object.type
                );
              }
              if (object.error != null) {
                if (typeof object.error !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.error: object expected, but got ' +
                      typeof object.error
                  );
                }
                message.error = google$2.protobuf.Empty.fromObject(
                  object.error
                );
              }
              if (object.abstractType != null) {
                if (typeof object.abstractType !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Type.abstractType: object expected, but got ' +
                      typeof object.abstractType
                  );
                }
                message.abstractType =
                  $root.google.api.expr.v1alpha1.Type.AbstractType.fromObject(
                    object.abstractType
                  );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (message.dyn != null && message.hasOwnProperty('dyn')) {
                object.dyn = google$2.protobuf.Empty.toObject(
                  message.dyn,
                  options
                );
                if (options.oneofs) {
                  object.typeKind = 'dyn';
                }
              }
              if (message.null != null && message.hasOwnProperty('null')) {
                object.null =
                  options.enums === String
                    ? google$3.protobuf.NullValue[message.null] === undefined
                      ? message.null
                      : google$3.protobuf.NullValue[message.null]
                    : message.null;
                if (options.oneofs) {
                  object.typeKind = 'null';
                }
              }
              if (
                message.primitive != null &&
                message.hasOwnProperty('primitive')
              ) {
                object.primitive =
                  options.enums === String
                    ? $root.google.api.expr.v1alpha1.Type.PrimitiveType[
                        message.primitive
                      ] === undefined
                      ? message.primitive
                      : $root.google.api.expr.v1alpha1.Type.PrimitiveType[
                          message.primitive
                        ]
                    : message.primitive;
                if (options.oneofs) {
                  object.typeKind = 'primitive';
                }
              }
              if (
                message.wrapper != null &&
                message.hasOwnProperty('wrapper')
              ) {
                object.wrapper =
                  options.enums === String
                    ? $root.google.api.expr.v1alpha1.Type.PrimitiveType[
                        message.wrapper
                      ] === undefined
                      ? message.wrapper
                      : $root.google.api.expr.v1alpha1.Type.PrimitiveType[
                          message.wrapper
                        ]
                    : message.wrapper;
                if (options.oneofs) {
                  object.typeKind = 'wrapper';
                }
              }
              if (
                message.wellKnown != null &&
                message.hasOwnProperty('wellKnown')
              ) {
                object.wellKnown =
                  options.enums === String
                    ? $root.google.api.expr.v1alpha1.Type.WellKnownType[
                        message.wellKnown
                      ] === undefined
                      ? message.wellKnown
                      : $root.google.api.expr.v1alpha1.Type.WellKnownType[
                          message.wellKnown
                        ]
                    : message.wellKnown;
                if (options.oneofs) {
                  object.typeKind = 'wellKnown';
                }
              }
              if (
                message.listType != null &&
                message.hasOwnProperty('listType')
              ) {
                object.listType =
                  $root.google.api.expr.v1alpha1.Type.ListType.toObject(
                    message.listType,
                    options
                  );
                if (options.oneofs) {
                  object.typeKind = 'listType';
                }
              }
              if (
                message.mapType != null &&
                message.hasOwnProperty('mapType')
              ) {
                object.mapType =
                  $root.google.api.expr.v1alpha1.Type.MapType.toObject(
                    message.mapType,
                    options
                  );
                if (options.oneofs) {
                  object.typeKind = 'mapType';
                }
              }
              if (
                message.function != null &&
                message.hasOwnProperty('function')
              ) {
                object.function =
                  $root.google.api.expr.v1alpha1.Type.FunctionType.toObject(
                    message.function,
                    options
                  );
                if (options.oneofs) {
                  object.typeKind = 'function';
                }
              }
              if (
                message.messageType != null &&
                message.hasOwnProperty('messageType')
              ) {
                object.messageType = message.messageType;
                if (options.oneofs) {
                  object.typeKind = 'messageType';
                }
              }
              if (
                message.typeParam != null &&
                message.hasOwnProperty('typeParam')
              ) {
                object.typeParam = message.typeParam;
                if (options.oneofs) {
                  object.typeKind = 'typeParam';
                }
              }
              if (message.type != null && message.hasOwnProperty('type')) {
                object.type = $root.google.api.expr.v1alpha1.Type.toObject(
                  message.type,
                  options
                );
                if (options.oneofs) {
                  object.typeKind = 'type';
                }
              }
              if (message.error != null && message.hasOwnProperty('error')) {
                object.error = google$2.protobuf.Empty.toObject(
                  message.error,
                  options
                );
                if (options.oneofs) {
                  object.typeKind = 'error';
                }
              }
              if (
                message.abstractType != null &&
                message.hasOwnProperty('abstractType')
              ) {
                object.abstractType =
                  $root.google.api.expr.v1alpha1.Type.AbstractType.toObject(
                    message.abstractType,
                    options
                  );
                if (options.oneofs) {
                  object.typeKind = 'abstractType';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Type';
            }
          }

          Type.prototype.dyn = null;
          Type.prototype.null = 0;
          Type.prototype.primitive = 0;
          Type.prototype.wrapper = 0;
          Type.prototype.wellKnown = 0;
          Type.prototype.listType = null;
          Type.prototype.mapType = null;
          Type.prototype.function = null;
          Type.prototype.messageType = '';
          Type.prototype.typeParam = '';
          Type.prototype.type = null;
          Type.prototype.error = null;
          Type.prototype.abstractType = null;
          Type.ListType = (() => {
            class ListType {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new ListType(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.elemType != null &&
                  Object.hasOwnProperty.call(message, 'elemType')
                ) {
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.elemType,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new ListType();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.elemType =
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof $root.google.api.expr.v1alpha1.Type.ListType
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Type.ListType();
                if (object.elemType != null) {
                  if (typeof object.elemType !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.ListType.elemType: object expected, but got ' +
                        typeof object.elemType
                    );
                  }
                  message.elemType =
                    $root.google.api.expr.v1alpha1.Type.fromObject(
                      object.elemType
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.elemType = null;
                }
                let keys;
                if (
                  message.elemType != null &&
                  message.hasOwnProperty('elemType')
                ) {
                  object.elemType =
                    $root.google.api.expr.v1alpha1.Type.toObject(
                      message.elemType,
                      options
                    );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return typeUrlPrefix + 'google.api.expr.v1alpha1.Type.ListType';
              }
            }

            ListType.prototype.elemType = null;

            return ListType;
          })();

          Type.MapType = (() => {
            class MapType {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new MapType(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.keyType != null &&
                  Object.hasOwnProperty.call(message, 'keyType')
                ) {
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.keyType,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.valueType != null &&
                  Object.hasOwnProperty.call(message, 'valueType')
                ) {
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.valueType,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new MapType();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.keyType =
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 2: {
                      message.valueType =
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof $root.google.api.expr.v1alpha1.Type.MapType
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Type.MapType();
                if (object.keyType != null) {
                  if (typeof object.keyType !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.MapType.keyType: object expected, but got ' +
                        typeof object.keyType
                    );
                  }
                  message.keyType =
                    $root.google.api.expr.v1alpha1.Type.fromObject(
                      object.keyType
                    );
                }
                if (object.valueType != null) {
                  if (typeof object.valueType !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.MapType.valueType: object expected, but got ' +
                        typeof object.valueType
                    );
                  }
                  message.valueType =
                    $root.google.api.expr.v1alpha1.Type.fromObject(
                      object.valueType
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.keyType = null;
                  object.valueType = null;
                }
                let keys;
                if (
                  message.keyType != null &&
                  message.hasOwnProperty('keyType')
                ) {
                  object.keyType = $root.google.api.expr.v1alpha1.Type.toObject(
                    message.keyType,
                    options
                  );
                }
                if (
                  message.valueType != null &&
                  message.hasOwnProperty('valueType')
                ) {
                  object.valueType =
                    $root.google.api.expr.v1alpha1.Type.toObject(
                      message.valueType,
                      options
                    );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return typeUrlPrefix + 'google.api.expr.v1alpha1.Type.MapType';
              }
            }

            MapType.prototype.keyType = null;
            MapType.prototype.valueType = null;

            return MapType;
          })();

          Type.FunctionType = (() => {
            class FunctionType {
              constructor(properties) {
                this.argTypes = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new FunctionType(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.resultType != null &&
                  Object.hasOwnProperty.call(message, 'resultType')
                ) {
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.resultType,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.argTypes != null &&
                  Object.hasOwnProperty.call(message, 'argTypes')
                ) {
                  for (const element of message.argTypes) {
                    $root.google.api.expr.v1alpha1.Type.encode(
                      element,
                      writer.uint32(18).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new FunctionType();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.resultType =
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 2: {
                      if (!message.argTypes || !message.argTypes.length) {
                        message.argTypes = [];
                      }
                      message.argTypes.push(
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Type.FunctionType
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Type.FunctionType();
                if (object.resultType != null) {
                  if (typeof object.resultType !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.FunctionType.resultType: object expected, but got ' +
                        typeof object.resultType
                    );
                  }
                  message.resultType =
                    $root.google.api.expr.v1alpha1.Type.fromObject(
                      object.resultType
                    );
                }
                if (object.argTypes) {
                  if (!Array.isArray(object.argTypes)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.FunctionType.argTypes: array type expected, but got ' +
                        typeof object.argTypes
                    );
                  }
                  message.argTypes = new Array(object.argTypes.length);
                  for (let i = 0; i < object.argTypes.length; ++i) {
                    if (typeof object.argTypes[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Type.FunctionType.argTypes: object expected, but got ' +
                          typeof object.argTypes[i]
                      );
                    }
                    message.argTypes[i] =
                      $root.google.api.expr.v1alpha1.Type.fromObject(
                        object.argTypes[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.argTypes = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.resultType = null;
                }
                let keys;
                if (
                  message.resultType != null &&
                  message.hasOwnProperty('resultType')
                ) {
                  object.resultType =
                    $root.google.api.expr.v1alpha1.Type.toObject(
                      message.resultType,
                      options
                    );
                }
                if (message.argTypes && message.argTypes.length) {
                  object.argTypes = new Array(message.argTypes.length);
                  for (let i = 0; i < message.argTypes.length; ++i) {
                    object.argTypes[i] =
                      $root.google.api.expr.v1alpha1.Type.toObject(
                        message.argTypes[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Type.FunctionType'
                );
              }
            }

            FunctionType.prototype.resultType = null;
            FunctionType.prototype.argTypes = $util.emptyArray;

            return FunctionType;
          })();

          Type.AbstractType = (() => {
            class AbstractType {
              constructor(properties) {
                this.parameterTypes = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new AbstractType(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.name != null &&
                  Object.hasOwnProperty.call(message, 'name')
                ) {
                  writer.uint32(10).string(message.name);
                }
                if (
                  message.parameterTypes != null &&
                  Object.hasOwnProperty.call(message, 'parameterTypes')
                ) {
                  for (const element of message.parameterTypes) {
                    $root.google.api.expr.v1alpha1.Type.encode(
                      element,
                      writer.uint32(18).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new AbstractType();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.name = reader.string();
                      break;
                    }
                    case 2: {
                      if (
                        !message.parameterTypes ||
                        !message.parameterTypes.length
                      ) {
                        message.parameterTypes = [];
                      }
                      message.parameterTypes.push(
                        $root.google.api.expr.v1alpha1.Type.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Type.AbstractType
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Type.AbstractType();
                if (object.name != null) {
                  message.name = String(object.name);
                }
                if (object.parameterTypes) {
                  if (!Array.isArray(object.parameterTypes)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Type.AbstractType.parameterTypes: array type expected, but got ' +
                        typeof object.parameterTypes
                    );
                  }
                  message.parameterTypes = new Array(
                    object.parameterTypes.length
                  );
                  for (let i = 0; i < object.parameterTypes.length; ++i) {
                    if (typeof object.parameterTypes[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Type.AbstractType.parameterTypes: object expected, but got ' +
                          typeof object.parameterTypes[i]
                      );
                    }
                    message.parameterTypes[i] =
                      $root.google.api.expr.v1alpha1.Type.fromObject(
                        object.parameterTypes[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.parameterTypes = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.name = '';
                }
                let keys;
                if (message.name != null && message.hasOwnProperty('name')) {
                  object.name = message.name;
                }
                if (message.parameterTypes && message.parameterTypes.length) {
                  object.parameterTypes = new Array(
                    message.parameterTypes.length
                  );
                  for (let i = 0; i < message.parameterTypes.length; ++i) {
                    object.parameterTypes[i] =
                      $root.google.api.expr.v1alpha1.Type.toObject(
                        message.parameterTypes[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Type.AbstractType'
                );
              }
            }

            AbstractType.prototype.name = '';
            AbstractType.prototype.parameterTypes = $util.emptyArray;

            return AbstractType;
          })();

          Type.PrimitiveType = (function () {
            const valuesById = {};
            const values = Object.create(valuesById);
            values[(valuesById[0] = 'PRIMITIVE_TYPE_UNSPECIFIED')] = 0;
            values[(valuesById[1] = 'BOOL')] = 1;
            values[(valuesById[2] = 'INT64')] = 2;
            values[(valuesById[3] = 'UINT64')] = 3;
            values[(valuesById[4] = 'DOUBLE')] = 4;
            values[(valuesById[5] = 'STRING')] = 5;
            values[(valuesById[6] = 'BYTES')] = 6;
            return values;
          })();

          Type.WellKnownType = (function () {
            const valuesById = {};
            const values = Object.create(valuesById);
            values[(valuesById[0] = 'WELL_KNOWN_TYPE_UNSPECIFIED')] = 0;
            values[(valuesById[1] = 'ANY')] = 1;
            values[(valuesById[2] = 'TIMESTAMP')] = 2;
            values[(valuesById[3] = 'DURATION')] = 3;
            return values;
          })();

          return Type;
        })();

        v1alpha1.Decl = (() => {
          class Decl {
            get declKind() {
              for (const key of ['ident', 'function']) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set declKind(name) {
              for (const key of ['ident', 'function']) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Decl(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.name != null &&
                Object.hasOwnProperty.call(message, 'name')
              ) {
                writer.uint32(10).string(message.name);
              }
              if (
                message.ident != null &&
                Object.hasOwnProperty.call(message, 'ident')
              ) {
                $root.google.api.expr.v1alpha1.Decl.IdentDecl.encode(
                  message.ident,
                  writer.uint32(18).fork()
                ).ldelim();
              }
              if (
                message.function != null &&
                Object.hasOwnProperty.call(message, 'function')
              ) {
                $root.google.api.expr.v1alpha1.Decl.FunctionDecl.encode(
                  message.function,
                  writer.uint32(26).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Decl();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.name = reader.string();
                    break;
                  }
                  case 2: {
                    message.ident =
                      $root.google.api.expr.v1alpha1.Decl.IdentDecl.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 3: {
                    message.function =
                      $root.google.api.expr.v1alpha1.Decl.FunctionDecl.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Decl) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Decl();
              if (object.name != null) {
                message.name = String(object.name);
              }
              if (object.ident != null) {
                if (typeof object.ident !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Decl.ident: object expected, but got ' +
                      typeof object.ident
                  );
                }
                message.ident =
                  $root.google.api.expr.v1alpha1.Decl.IdentDecl.fromObject(
                    object.ident
                  );
              }
              if (object.function != null) {
                if (typeof object.function !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Decl.function: object expected, but got ' +
                      typeof object.function
                  );
                }
                message.function =
                  $root.google.api.expr.v1alpha1.Decl.FunctionDecl.fromObject(
                    object.function
                  );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.name = '';
              }
              let keys;
              if (message.name != null && message.hasOwnProperty('name')) {
                object.name = message.name;
              }
              if (message.ident != null && message.hasOwnProperty('ident')) {
                object.ident =
                  $root.google.api.expr.v1alpha1.Decl.IdentDecl.toObject(
                    message.ident,
                    options
                  );
                if (options.oneofs) {
                  object.declKind = 'ident';
                }
              }
              if (
                message.function != null &&
                message.hasOwnProperty('function')
              ) {
                object.function =
                  $root.google.api.expr.v1alpha1.Decl.FunctionDecl.toObject(
                    message.function,
                    options
                  );
                if (options.oneofs) {
                  object.declKind = 'function';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Decl';
            }
          }

          Decl.prototype.name = '';
          Decl.prototype.ident = null;
          Decl.prototype.function = null;
          Decl.IdentDecl = (() => {
            class IdentDecl {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new IdentDecl(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.type != null &&
                  Object.hasOwnProperty.call(message, 'type')
                ) {
                  $root.google.api.expr.v1alpha1.Type.encode(
                    message.type,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                if (
                  message.value != null &&
                  Object.hasOwnProperty.call(message, 'value')
                ) {
                  google$1.api.expr.v1alpha1.Constant.encode(
                    message.value,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                if (
                  message.doc != null &&
                  Object.hasOwnProperty.call(message, 'doc')
                ) {
                  writer.uint32(26).string(message.doc);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new IdentDecl();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.type = $root.google.api.expr.v1alpha1.Type.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                    case 2: {
                      message.value =
                        google$1.api.expr.v1alpha1.Constant.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 3: {
                      message.doc = reader.string();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Decl.IdentDecl
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Decl.IdentDecl();
                if (object.type != null) {
                  if (typeof object.type !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Decl.IdentDecl.type: object expected, but got ' +
                        typeof object.type
                    );
                  }
                  message.type = $root.google.api.expr.v1alpha1.Type.fromObject(
                    object.type
                  );
                }
                if (object.value != null) {
                  if (typeof object.value !== 'object') {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Decl.IdentDecl.value: object expected, but got ' +
                        typeof object.value
                    );
                  }
                  message.value =
                    google$1.api.expr.v1alpha1.Constant.fromObject(
                      object.value
                    );
                }
                if (object.doc != null) {
                  message.doc = String(object.doc);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.type = null;
                  object.value = null;
                  object.doc = '';
                }
                let keys;
                if (message.type != null && message.hasOwnProperty('type')) {
                  object.type = $root.google.api.expr.v1alpha1.Type.toObject(
                    message.type,
                    options
                  );
                }
                if (message.value != null && message.hasOwnProperty('value')) {
                  object.value = google$1.api.expr.v1alpha1.Constant.toObject(
                    message.value,
                    options
                  );
                }
                if (message.doc != null && message.hasOwnProperty('doc')) {
                  object.doc = message.doc;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Decl.IdentDecl'
                );
              }
            }

            IdentDecl.prototype.type = null;
            IdentDecl.prototype.value = null;
            IdentDecl.prototype.doc = '';

            return IdentDecl;
          })();

          Decl.FunctionDecl = (() => {
            class FunctionDecl {
              constructor(properties) {
                this.overloads = [];
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new FunctionDecl(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.overloads != null &&
                  Object.hasOwnProperty.call(message, 'overloads')
                ) {
                  for (const element of message.overloads) {
                    $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.encode(
                      element,
                      writer.uint32(10).fork()
                    ).ldelim();
                  }
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new FunctionDecl();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      if (!message.overloads || !message.overloads.length) {
                        message.overloads = [];
                      }
                      message.overloads.push(
                        $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.decode(
                          reader,
                          reader.uint32()
                        )
                      );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.google.api.expr.v1alpha1.Decl.FunctionDecl
                ) {
                  return object;
                }
                const message =
                  new $root.google.api.expr.v1alpha1.Decl.FunctionDecl();
                if (object.overloads) {
                  if (!Array.isArray(object.overloads)) {
                    throw new TypeError(
                      '.google.api.expr.v1alpha1.Decl.FunctionDecl.overloads: array type expected, but got ' +
                        typeof object.overloads
                    );
                  }
                  message.overloads = new Array(object.overloads.length);
                  for (let i = 0; i < object.overloads.length; ++i) {
                    if (typeof object.overloads[i] !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Decl.FunctionDecl.overloads: object expected, but got ' +
                          typeof object.overloads[i]
                      );
                    }
                    message.overloads[i] =
                      $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.fromObject(
                        object.overloads[i]
                      );
                  }
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                  object.overloads = [];
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                }
                let keys;
                if (message.overloads && message.overloads.length) {
                  object.overloads = new Array(message.overloads.length);
                  for (let i = 0; i < message.overloads.length; ++i) {
                    object.overloads[i] =
                      $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.toObject(
                        message.overloads[i],
                        options
                      );
                  }
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix + 'google.api.expr.v1alpha1.Decl.FunctionDecl'
                );
              }
            }

            FunctionDecl.prototype.overloads = $util.emptyArray;
            FunctionDecl.Overload = (() => {
              class Overload {
                constructor(properties) {
                  this.params = [];
                  this.typeParams = [];
                  if (properties) {
                    for (let key of Object.keys(properties)) {
                      if (properties[key] != null) this[key] = properties[key];
                    }
                  }
                }

                static create(properties) {
                  return new Overload(properties);
                }

                static encode(message, writer) {
                  if (!writer) writer = $Writer.create();
                  if (
                    message.overloadId != null &&
                    Object.hasOwnProperty.call(message, 'overloadId')
                  ) {
                    writer.uint32(10).string(message.overloadId);
                  }
                  if (
                    message.params != null &&
                    Object.hasOwnProperty.call(message, 'params')
                  ) {
                    for (const element of message.params) {
                      $root.google.api.expr.v1alpha1.Type.encode(
                        element,
                        writer.uint32(18).fork()
                      ).ldelim();
                    }
                  }
                  if (
                    message.typeParams != null &&
                    Object.hasOwnProperty.call(message, 'typeParams')
                  ) {
                    for (const element of message.typeParams) {
                      writer.uint32(26).string(element);
                    }
                  }
                  if (
                    message.resultType != null &&
                    Object.hasOwnProperty.call(message, 'resultType')
                  ) {
                    $root.google.api.expr.v1alpha1.Type.encode(
                      message.resultType,
                      writer.uint32(34).fork()
                    ).ldelim();
                  }
                  if (
                    message.isInstanceFunction != null &&
                    Object.hasOwnProperty.call(message, 'isInstanceFunction')
                  ) {
                    writer.uint32(40).bool(message.isInstanceFunction);
                  }
                  if (
                    message.doc != null &&
                    Object.hasOwnProperty.call(message, 'doc')
                  ) {
                    writer.uint32(50).string(message.doc);
                  }
                  return writer;
                }

                static decode(reader, length) {
                  if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                  let end =
                    length === undefined ? reader.len : reader.pos + length;
                  let message = new Overload();
                  let key, value;
                  while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                      case 1: {
                        message.overloadId = reader.string();
                        break;
                      }
                      case 2: {
                        if (!message.params || !message.params.length) {
                          message.params = [];
                        }
                        message.params.push(
                          $root.google.api.expr.v1alpha1.Type.decode(
                            reader,
                            reader.uint32()
                          )
                        );
                        break;
                      }
                      case 3: {
                        if (!message.typeParams || !message.typeParams.length) {
                          message.typeParams = [];
                        }
                        message.typeParams.push(reader.string());
                        break;
                      }
                      case 4: {
                        message.resultType =
                          $root.google.api.expr.v1alpha1.Type.decode(
                            reader,
                            reader.uint32()
                          );
                        break;
                      }
                      case 5: {
                        message.isInstanceFunction = reader.bool();
                        break;
                      }
                      case 6: {
                        message.doc = reader.string();
                        break;
                      }
                      default: {
                        reader.skipType(tag & 7);
                        break;
                      }
                    }
                  }
                  return message;
                }

                static fromObject(object) {
                  if (
                    object instanceof
                    $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload
                  ) {
                    return object;
                  }
                  const message =
                    new $root.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload();
                  if (object.overloadId != null) {
                    message.overloadId = String(object.overloadId);
                  }
                  if (object.params) {
                    if (!Array.isArray(object.params)) {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.params: array type expected, but got ' +
                          typeof object.params
                      );
                    }
                    message.params = new Array(object.params.length);
                    for (let i = 0; i < object.params.length; ++i) {
                      if (typeof object.params[i] !== 'object') {
                        throw new TypeError(
                          '.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.params: object expected, but got ' +
                            typeof object.params[i]
                        );
                      }
                      message.params[i] =
                        $root.google.api.expr.v1alpha1.Type.fromObject(
                          object.params[i]
                        );
                    }
                  }
                  if (object.typeParams) {
                    if (!Array.isArray(object.typeParams)) {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.typeParams: array type expected, but got ' +
                          typeof object.typeParams
                      );
                    }
                    message.typeParams = new Array(object.typeParams.length);
                    for (let i = 0; i < object.typeParams.length; ++i) {
                      message.typeParams[i] = String(object.typeParams[i]);
                    }
                  }
                  if (object.resultType != null) {
                    if (typeof object.resultType !== 'object') {
                      throw new TypeError(
                        '.google.api.expr.v1alpha1.Decl.FunctionDecl.Overload.resultType: object expected, but got ' +
                          typeof object.resultType
                      );
                    }
                    message.resultType =
                      $root.google.api.expr.v1alpha1.Type.fromObject(
                        object.resultType
                      );
                  }
                  if (object.isInstanceFunction != null) {
                    message.isInstanceFunction = Boolean(
                      object.isInstanceFunction
                    );
                  }
                  if (object.doc != null) {
                    message.doc = String(object.doc);
                  }
                  return message;
                }

                static toObject(message, options = {}) {
                  const object = {};
                  if (options.arrays || options.defaults) {
                    object.params = [];
                    object.typeParams = [];
                  }
                  if (options.objects || options.defaults) {
                  }
                  if (options.defaults) {
                    object.overloadId = '';
                    object.resultType = null;
                    object.isInstanceFunction = false;
                    object.doc = '';
                  }
                  let keys;
                  if (
                    message.overloadId != null &&
                    message.hasOwnProperty('overloadId')
                  ) {
                    object.overloadId = message.overloadId;
                  }
                  if (message.params && message.params.length) {
                    object.params = new Array(message.params.length);
                    for (let i = 0; i < message.params.length; ++i) {
                      object.params[i] =
                        $root.google.api.expr.v1alpha1.Type.toObject(
                          message.params[i],
                          options
                        );
                    }
                  }
                  if (message.typeParams && message.typeParams.length) {
                    object.typeParams = new Array(message.typeParams.length);
                    for (let i = 0; i < message.typeParams.length; ++i) {
                      object.typeParams[i] = message.typeParams[i];
                    }
                  }
                  if (
                    message.resultType != null &&
                    message.hasOwnProperty('resultType')
                  ) {
                    object.resultType =
                      $root.google.api.expr.v1alpha1.Type.toObject(
                        message.resultType,
                        options
                      );
                  }
                  if (
                    message.isInstanceFunction != null &&
                    message.hasOwnProperty('isInstanceFunction')
                  ) {
                    object.isInstanceFunction = message.isInstanceFunction;
                  }
                  if (message.doc != null && message.hasOwnProperty('doc')) {
                    object.doc = message.doc;
                  }
                  return object;
                }

                static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                  return (
                    typeUrlPrefix +
                    'google.api.expr.v1alpha1.Decl.FunctionDecl.Overload'
                  );
                }
              }

              Overload.prototype.overloadId = '';
              Overload.prototype.params = $util.emptyArray;
              Overload.prototype.typeParams = $util.emptyArray;
              Overload.prototype.resultType = null;
              Overload.prototype.isInstanceFunction = false;
              Overload.prototype.doc = '';

              return Overload;
            })();

            return FunctionDecl;
          })();

          return Decl;
        })();

        v1alpha1.Reference = (() => {
          class Reference {
            constructor(properties) {
              this.overloadId = [];
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new Reference(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.name != null &&
                Object.hasOwnProperty.call(message, 'name')
              ) {
                writer.uint32(10).string(message.name);
              }
              if (
                message.overloadId != null &&
                Object.hasOwnProperty.call(message, 'overloadId')
              ) {
                for (const element of message.overloadId) {
                  writer.uint32(26).string(element);
                }
              }
              if (
                message.value != null &&
                Object.hasOwnProperty.call(message, 'value')
              ) {
                google$1.api.expr.v1alpha1.Constant.encode(
                  message.value,
                  writer.uint32(34).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new Reference();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.name = reader.string();
                    break;
                  }
                  case 3: {
                    if (!message.overloadId || !message.overloadId.length) {
                      message.overloadId = [];
                    }
                    message.overloadId.push(reader.string());
                    break;
                  }
                  case 4: {
                    message.value = google$1.api.expr.v1alpha1.Constant.decode(
                      reader,
                      reader.uint32()
                    );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.google.api.expr.v1alpha1.Reference) {
                return object;
              }
              const message = new $root.google.api.expr.v1alpha1.Reference();
              if (object.name != null) {
                message.name = String(object.name);
              }
              if (object.overloadId) {
                if (!Array.isArray(object.overloadId)) {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Reference.overloadId: array type expected, but got ' +
                      typeof object.overloadId
                  );
                }
                message.overloadId = new Array(object.overloadId.length);
                for (let i = 0; i < object.overloadId.length; ++i) {
                  message.overloadId[i] = String(object.overloadId[i]);
                }
              }
              if (object.value != null) {
                if (typeof object.value !== 'object') {
                  throw new TypeError(
                    '.google.api.expr.v1alpha1.Reference.value: object expected, but got ' +
                      typeof object.value
                  );
                }
                message.value = google$1.api.expr.v1alpha1.Constant.fromObject(
                  object.value
                );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
                object.overloadId = [];
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.name = '';
                object.value = null;
              }
              let keys;
              if (message.name != null && message.hasOwnProperty('name')) {
                object.name = message.name;
              }
              if (message.overloadId && message.overloadId.length) {
                object.overloadId = new Array(message.overloadId.length);
                for (let i = 0; i < message.overloadId.length; ++i) {
                  object.overloadId[i] = message.overloadId[i];
                }
              }
              if (message.value != null && message.hasOwnProperty('value')) {
                object.value = google$1.api.expr.v1alpha1.Constant.toObject(
                  message.value,
                  options
                );
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return typeUrlPrefix + 'google.api.expr.v1alpha1.Reference';
            }
          }

          Reference.prototype.name = '';
          Reference.prototype.overloadId = $util.emptyArray;
          Reference.prototype.value = null;

          return Reference;
        })();

        return v1alpha1;
      })();

      return expr;
    })();

    return api;
  })();

  return google;
})();

export const google = $root.google;
