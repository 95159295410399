export const Properties = () => {
  return (
    <>
      <path d='M10 6.62483L10 11.1248M10 11.1248L6.90625 13.6561L6.13281 14.2889M10 11.1248L13.0937 13.6561L13.8672 14.2889' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 16.1875C19 17.7408 17.7408 19 16.1875 19C14.6342 19 13.375 17.7408 13.375 16.1875C13.375 14.633 14.6342 13.375 16.1875 13.375C17.7408 13.375 19 14.633 19 16.1875Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.1875 3.8125C7.1875 5.36696 8.44669 6.625 10 6.625C11.5533 6.625 12.8125 5.36696 12.8125 3.8125C12.8125 2.25919 11.5533 1 10 1C8.44669 1 7.1875 2.25919 7.1875 3.8125Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 16.1875C1 17.742 2.25919 19 3.8125 19C5.36581 19 6.625 17.742 6.625 16.1875C6.625 14.6342 5.36581 13.375 3.8125 13.375C2.25919 13.375 1 14.6342 1 16.1875Z'
      />
    </>
  );
};
