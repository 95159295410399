export const Activity = () => {
  return (
    <path
      d='M12.8969,0.8421 C13.3109,0.8421 13.6469,1.1781 13.6469,1.5921 C13.6469,2.0061 13.3109,2.3421 12.8969,2.3421 L5.6289,2.3421 C3.1209,2.3421 1.4999,4.0661 1.4999,6.7361 L1.4999,14.8181 C1.4999,17.5231 3.0819,19.2031 5.6289,19.2031 L14.2329,19.2031 C16.7409,19.2031 18.3619,17.4821 18.3619,14.8181 L18.3619,7.7791 C18.3619,7.3651 18.6979,7.0291 19.1119,7.0291 C19.5259,7.0291 19.8619,7.3651 19.8619,7.7791 L19.8619,14.8181 C19.8619,18.3381 17.5999,20.7031 14.2329,20.7031 L5.6289,20.7031 C2.2619,20.7031 -0.0001,18.3381 -0.0001,14.8181 L-0.0001,6.7361 C-0.0001,3.2111 2.2619,0.8421 5.6289,0.8421 L12.8969,0.8421 Z M15.0123,7.6719 C15.3403,7.9259 15.4003,8.3969 15.1463,8.7239 L12.2163,12.5039 C12.0943,12.6619 11.9143,12.7649 11.7163,12.7889 C11.5163,12.8159 11.3183,12.7579 11.1603,12.6349 L8.3423,10.4209 L5.8113,13.7099 C5.6633,13.9019 5.4413,14.0029 5.2163,14.0029 C5.0563,14.0029 4.8953,13.9519 4.7593,13.8479 C4.4313,13.5949 4.3693,13.1239 4.6223,12.7959 L7.6153,8.9059 C7.7373,8.7469 7.9183,8.6439 8.1163,8.6189 C8.3183,8.5929 8.5163,8.6489 8.6733,8.7739 L11.4933,10.9889 L13.9603,7.8059 C14.2143,7.4769 14.6843,7.4159 15.0123,7.6719 Z M17.9673,2.7533531e-14 C19.4413,2.7533531e-14 20.6393,1.198 20.6393,2.672 C20.6393,4.146 19.4413,5.345 17.9673,5.345 C16.4943,5.345 15.2953,4.146 15.2953,2.672 C15.2953,1.198 16.4943,2.7533531e-14 17.9673,2.7533531e-14 Z M17.9673,1.5 C17.3213,1.5 16.7953,2.025 16.7953,2.672 C16.7953,3.318 17.3213,3.845 17.9673,3.845 C18.6133,3.845 19.1393,3.318 19.1393,2.672 C19.1393,2.025 18.6133,1.5 17.9673,1.5 Z'
      stroke='none'
    />
  );
};

export const SolidActivity = () => {
  return (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.1801 2.41C15.1801 1.08 16.2601 0 17.5901 0C18.9201 0 20.0001 1.08 20.0001 2.41C20.0001 3.74 18.9201 4.82 17.5901 4.82C16.2601 4.82 15.1801 3.74 15.1801 2.41ZM11.33 12.7593L14.22 9.0303L14.18 9.0503C14.34 8.8303 14.37 8.5503 14.26 8.3003C14.151 8.0503 13.91 7.8803 13.651 7.8603C13.38 7.8303 13.111 7.9503 12.95 8.1703L10.531 11.3003L7.76 9.1203C7.59 8.9903 7.39 8.9393 7.19 8.9603C6.991 8.9903 6.811 9.0993 6.69 9.2593L3.731 13.1103L3.67 13.2003C3.5 13.5193 3.58 13.9293 3.88 14.1503C4.02 14.2403 4.17 14.3003 4.34 14.3003C4.571 14.3103 4.79 14.1893 4.93 14.0003L7.44 10.7693L10.29 12.9103L10.38 12.9693C10.7 13.1393 11.1 13.0603 11.33 12.7593ZM13.45 1.7803C13.41 2.0303 13.39 2.2803 13.39 2.5303C13.39 4.7803 15.21 6.5993 17.45 6.5993C17.7 6.5993 17.94 6.5703 18.19 6.5303V14.5993C18.19 17.9903 16.19 20.0003 12.79 20.0003H5.401C2 20.0003 0 17.9903 0 14.5993V7.2003C0 3.8003 2 1.7803 5.401 1.7803H13.45Z'
    />
  );
};
