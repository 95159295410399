import { Button } from '../../../../Button';
import { CustomTimeRangeProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { Input } from '../../../../Input';
import { Text } from '../../../../Typography';
import { convertRFC3339ToTimestamp } from './convertRFC339ToTimestamp';
import { convertTimestampToRFC3339 } from './convertTimestampToRFC339';
import { formatCustomLabel } from './formatCustomLabel';
import { timestampPattern } from './timestampPattern';
import { useForm } from 'react-hook-form';
import { useTheme } from '@morf/theming';

const CustomTimeRange: FC<CustomTimeRangeProps> = ({
  onApplyClick,
  onBackClick,
  timeOption,
}) => {
  const theme = useTheme();
  const { register, formState, watch } = useForm({
    mode: 'onChange',
  });

  const {
    startTimestamp: startTimestampRFC3339,
    endTimestamp: endTimestampRFC3339,
  } = watch();

  const startTimestamp = convertRFC3339ToTimestamp(startTimestampRFC3339);
  const endTimestamp = convertRFC3339ToTimestamp(endTimestampRFC3339);

  const handleApplyClick = () => {
    onApplyClick({
      label: formatCustomLabel(startTimestamp, endTimestamp),
      timeRange: {
        startTimestamp,
        endTimestamp,
      },
    });
  };

  return (
    <Flexbox
      data-testid='custom-time-range'
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        width='auto'
        height='auto'
        gap={0.25}
      >
        <Icon
          name='chevron-left'
          size={0.75}
          stroke={theme.colors.ui.dark}
          strokeWidth={2}
          cursor='pointer'
          onClick={onBackClick}
        />

        <Text tag='p3' color={theme.colors.text.muted}>
          Select time range
        </Text>
      </Flexbox>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.25}
      >
        <Input
          id='startTimestamp'
          tag='p3'
          height='1.5rem'
          placeholder='Start timestamp'
          {...(timeOption && {
            value: convertTimestampToRFC3339(
              timeOption.timeRange.startTimestamp
            ),
          })}
          {...register('startTimestamp', {
            required: true,
            pattern: {
              value: timestampPattern,
              message: 'Invalid timestamp format',
            },
          })}
        />

        <Input
          id='endTimestamp'
          tag='p3'
          height='1.5rem'
          placeholder='End timestamp'
          {...(timeOption && {
            value: convertTimestampToRFC3339(timeOption.timeRange.endTimestamp),
          })}
          {...register('endTimestamp', {
            required: true,
            pattern: {
              value: timestampPattern,
              message: 'Invalid timestamp format',
            },
          })}
        />
      </Flexbox>

      <Button
        width='100%'
        isDisabled={!formState.isValid}
        onClick={handleApplyClick}
      >
        <Text tag='h6' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedCustomTimeRange = memo(CustomTimeRange);
