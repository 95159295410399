'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$1 } from '../../struct_ts_proto';
import { timing as timing$1 } from '../../timing_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from '../../workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.morf = (() => {
    const morf = {};
    morf.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of [
              'calculatePcl5Score',
              'calculateGad7Score',
              'calculatePhq8Score',
              'calculatePhq9Score',
              'httpPost',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'calculatePcl5Score',
              'calculateGad7Score',
              'calculatePhq8Score',
              'calculatePhq9Score',
              'httpPost',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.calculatePcl5Score != null &&
              Object.hasOwnProperty.call(message, 'calculatePcl5Score')
            ) {
              $root.fetchactions.morf.v1.CalculatePCL5Score.encode(
                message.calculatePcl5Score,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.calculateGad7Score != null &&
              Object.hasOwnProperty.call(message, 'calculateGad7Score')
            ) {
              $root.fetchactions.morf.v1.CalculateGAD7Score.encode(
                message.calculateGad7Score,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.calculatePhq8Score != null &&
              Object.hasOwnProperty.call(message, 'calculatePhq8Score')
            ) {
              $root.fetchactions.morf.v1.CalculatePHQ8Score.encode(
                message.calculatePhq8Score,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.calculatePhq9Score != null &&
              Object.hasOwnProperty.call(message, 'calculatePhq9Score')
            ) {
              $root.fetchactions.morf.v1.CalculatePHQ9Score.encode(
                message.calculatePhq9Score,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.httpPost != null &&
              Object.hasOwnProperty.call(message, 'httpPost')
            ) {
              $root.fetchactions.morf.v1.HttpPost.encode(
                message.httpPost,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.calculatePcl5Score =
                    $root.fetchactions.morf.v1.CalculatePCL5Score.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.calculateGad7Score =
                    $root.fetchactions.morf.v1.CalculateGAD7Score.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.calculatePhq8Score =
                    $root.fetchactions.morf.v1.CalculatePHQ8Score.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.calculatePhq9Score =
                    $root.fetchactions.morf.v1.CalculatePHQ9Score.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.httpPost = $root.fetchactions.morf.v1.HttpPost.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.FetchAction();
            if (object.calculatePcl5Score != null) {
              if (typeof object.calculatePcl5Score !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.FetchAction.calculatePcl5Score: object expected, but got ' +
                    typeof object.calculatePcl5Score
                );
              }
              message.calculatePcl5Score =
                $root.fetchactions.morf.v1.CalculatePCL5Score.fromObject(
                  object.calculatePcl5Score
                );
            }
            if (object.calculateGad7Score != null) {
              if (typeof object.calculateGad7Score !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.FetchAction.calculateGad7Score: object expected, but got ' +
                    typeof object.calculateGad7Score
                );
              }
              message.calculateGad7Score =
                $root.fetchactions.morf.v1.CalculateGAD7Score.fromObject(
                  object.calculateGad7Score
                );
            }
            if (object.calculatePhq8Score != null) {
              if (typeof object.calculatePhq8Score !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.FetchAction.calculatePhq8Score: object expected, but got ' +
                    typeof object.calculatePhq8Score
                );
              }
              message.calculatePhq8Score =
                $root.fetchactions.morf.v1.CalculatePHQ8Score.fromObject(
                  object.calculatePhq8Score
                );
            }
            if (object.calculatePhq9Score != null) {
              if (typeof object.calculatePhq9Score !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.FetchAction.calculatePhq9Score: object expected, but got ' +
                    typeof object.calculatePhq9Score
                );
              }
              message.calculatePhq9Score =
                $root.fetchactions.morf.v1.CalculatePHQ9Score.fromObject(
                  object.calculatePhq9Score
                );
            }
            if (object.httpPost != null) {
              if (typeof object.httpPost !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.FetchAction.httpPost: object expected, but got ' +
                    typeof object.httpPost
                );
              }
              message.httpPost = $root.fetchactions.morf.v1.HttpPost.fromObject(
                object.httpPost
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.calculatePcl5Score != null &&
              message.hasOwnProperty('calculatePcl5Score')
            ) {
              object.calculatePcl5Score =
                $root.fetchactions.morf.v1.CalculatePCL5Score.toObject(
                  message.calculatePcl5Score,
                  options
                );
              if (options.oneofs) {
                object.type = 'calculatePcl5Score';
              }
            }
            if (
              message.calculateGad7Score != null &&
              message.hasOwnProperty('calculateGad7Score')
            ) {
              object.calculateGad7Score =
                $root.fetchactions.morf.v1.CalculateGAD7Score.toObject(
                  message.calculateGad7Score,
                  options
                );
              if (options.oneofs) {
                object.type = 'calculateGad7Score';
              }
            }
            if (
              message.calculatePhq8Score != null &&
              message.hasOwnProperty('calculatePhq8Score')
            ) {
              object.calculatePhq8Score =
                $root.fetchactions.morf.v1.CalculatePHQ8Score.toObject(
                  message.calculatePhq8Score,
                  options
                );
              if (options.oneofs) {
                object.type = 'calculatePhq8Score';
              }
            }
            if (
              message.calculatePhq9Score != null &&
              message.hasOwnProperty('calculatePhq9Score')
            ) {
              object.calculatePhq9Score =
                $root.fetchactions.morf.v1.CalculatePHQ9Score.toObject(
                  message.calculatePhq9Score,
                  options
                );
              if (options.oneofs) {
                object.type = 'calculatePhq9Score';
              }
            }
            if (
              message.httpPost != null &&
              message.hasOwnProperty('httpPost')
            ) {
              object.httpPost = $root.fetchactions.morf.v1.HttpPost.toObject(
                message.httpPost,
                options
              );
              if (options.oneofs) {
                object.type = 'httpPost';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.FetchAction';
          }
        }

        FetchAction.prototype.calculatePcl5Score = null;
        FetchAction.prototype.calculateGad7Score = null;
        FetchAction.prototype.calculatePhq8Score = null;
        FetchAction.prototype.calculatePhq9Score = null;
        FetchAction.prototype.httpPost = null;

        return FetchAction;
      })();

      v1.PCL5Score = (() => {
        class PCL5Score {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PCL5Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.score != null &&
              Object.hasOwnProperty.call(message, 'score')
            ) {
              writer.uint32(8).int64(message.score);
            }
            if (
              message.reExperiencingScore != null &&
              Object.hasOwnProperty.call(message, 'reExperiencingScore')
            ) {
              writer.uint32(16).int64(message.reExperiencingScore);
            }
            if (
              message.avoidanceScore != null &&
              Object.hasOwnProperty.call(message, 'avoidanceScore')
            ) {
              writer.uint32(24).int64(message.avoidanceScore);
            }
            if (
              message.negativeMoodScore != null &&
              Object.hasOwnProperty.call(message, 'negativeMoodScore')
            ) {
              writer.uint32(32).int64(message.negativeMoodScore);
            }
            if (
              message.hyperarousalScore != null &&
              Object.hasOwnProperty.call(message, 'hyperarousalScore')
            ) {
              writer.uint32(40).int64(message.hyperarousalScore);
            }
            if (
              message.isPtsdDiagnosis != null &&
              Object.hasOwnProperty.call(message, 'isPtsdDiagnosis')
            ) {
              writer.uint32(48).bool(message.isPtsdDiagnosis);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PCL5Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.score = reader.int64();
                  break;
                }
                case 2: {
                  message.reExperiencingScore = reader.int64();
                  break;
                }
                case 3: {
                  message.avoidanceScore = reader.int64();
                  break;
                }
                case 4: {
                  message.negativeMoodScore = reader.int64();
                  break;
                }
                case 5: {
                  message.hyperarousalScore = reader.int64();
                  break;
                }
                case 6: {
                  message.isPtsdDiagnosis = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.PCL5Score) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.PCL5Score();
            if (object.score != null) {
              if ($util.Long) {
                (message.score = $util.Long.fromValue(
                  object.score
                )).unsigned = false;
              } else if (typeof object.score === 'string') {
                message.score = parseInt(object.score, 10);
              } else if (typeof object.score === 'number') {
                message.score = object.score;
              } else if (typeof object.score === 'object') {
                message.score = new $util.LongBits(
                  object.score.low >>> 0,
                  object.score.high >>> 0
                ).toNumber();
              }
            }
            if (object.reExperiencingScore != null) {
              if ($util.Long) {
                (message.reExperiencingScore = $util.Long.fromValue(
                  object.reExperiencingScore
                )).unsigned = false;
              } else if (typeof object.reExperiencingScore === 'string') {
                message.reExperiencingScore = parseInt(
                  object.reExperiencingScore,
                  10
                );
              } else if (typeof object.reExperiencingScore === 'number') {
                message.reExperiencingScore = object.reExperiencingScore;
              } else if (typeof object.reExperiencingScore === 'object') {
                message.reExperiencingScore = new $util.LongBits(
                  object.reExperiencingScore.low >>> 0,
                  object.reExperiencingScore.high >>> 0
                ).toNumber();
              }
            }
            if (object.avoidanceScore != null) {
              if ($util.Long) {
                (message.avoidanceScore = $util.Long.fromValue(
                  object.avoidanceScore
                )).unsigned = false;
              } else if (typeof object.avoidanceScore === 'string') {
                message.avoidanceScore = parseInt(object.avoidanceScore, 10);
              } else if (typeof object.avoidanceScore === 'number') {
                message.avoidanceScore = object.avoidanceScore;
              } else if (typeof object.avoidanceScore === 'object') {
                message.avoidanceScore = new $util.LongBits(
                  object.avoidanceScore.low >>> 0,
                  object.avoidanceScore.high >>> 0
                ).toNumber();
              }
            }
            if (object.negativeMoodScore != null) {
              if ($util.Long) {
                (message.negativeMoodScore = $util.Long.fromValue(
                  object.negativeMoodScore
                )).unsigned = false;
              } else if (typeof object.negativeMoodScore === 'string') {
                message.negativeMoodScore = parseInt(
                  object.negativeMoodScore,
                  10
                );
              } else if (typeof object.negativeMoodScore === 'number') {
                message.negativeMoodScore = object.negativeMoodScore;
              } else if (typeof object.negativeMoodScore === 'object') {
                message.negativeMoodScore = new $util.LongBits(
                  object.negativeMoodScore.low >>> 0,
                  object.negativeMoodScore.high >>> 0
                ).toNumber();
              }
            }
            if (object.hyperarousalScore != null) {
              if ($util.Long) {
                (message.hyperarousalScore = $util.Long.fromValue(
                  object.hyperarousalScore
                )).unsigned = false;
              } else if (typeof object.hyperarousalScore === 'string') {
                message.hyperarousalScore = parseInt(
                  object.hyperarousalScore,
                  10
                );
              } else if (typeof object.hyperarousalScore === 'number') {
                message.hyperarousalScore = object.hyperarousalScore;
              } else if (typeof object.hyperarousalScore === 'object') {
                message.hyperarousalScore = new $util.LongBits(
                  object.hyperarousalScore.low >>> 0,
                  object.hyperarousalScore.high >>> 0
                ).toNumber();
              }
            }
            if (object.isPtsdDiagnosis != null) {
              message.isPtsdDiagnosis = Boolean(object.isPtsdDiagnosis);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.score =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.score = options.longs === String ? '0' : 0;
              }
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.reExperiencingScore =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.reExperiencingScore = options.longs === String ? '0' : 0;
              }
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.avoidanceScore =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.avoidanceScore = options.longs === String ? '0' : 0;
              }
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.negativeMoodScore =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.negativeMoodScore = options.longs === String ? '0' : 0;
              }
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.hyperarousalScore =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.hyperarousalScore = options.longs === String ? '0' : 0;
              }
              object.isPtsdDiagnosis = false;
            }
            let keys;
            if (message.score != null && message.hasOwnProperty('score')) {
              object.score =
                typeof message.score === 'number'
                  ? options.longs === String
                    ? String(message.score)
                    : message.score
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.score)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.score.low >>> 0,
                      message.score.high >>> 0
                    ).toNumber()
                  : message.score;
            }
            if (
              message.reExperiencingScore != null &&
              message.hasOwnProperty('reExperiencingScore')
            ) {
              object.reExperiencingScore =
                typeof message.reExperiencingScore === 'number'
                  ? options.longs === String
                    ? String(message.reExperiencingScore)
                    : message.reExperiencingScore
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.reExperiencingScore
                    )
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.reExperiencingScore.low >>> 0,
                      message.reExperiencingScore.high >>> 0
                    ).toNumber()
                  : message.reExperiencingScore;
            }
            if (
              message.avoidanceScore != null &&
              message.hasOwnProperty('avoidanceScore')
            ) {
              object.avoidanceScore =
                typeof message.avoidanceScore === 'number'
                  ? options.longs === String
                    ? String(message.avoidanceScore)
                    : message.avoidanceScore
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.avoidanceScore)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.avoidanceScore.low >>> 0,
                      message.avoidanceScore.high >>> 0
                    ).toNumber()
                  : message.avoidanceScore;
            }
            if (
              message.negativeMoodScore != null &&
              message.hasOwnProperty('negativeMoodScore')
            ) {
              object.negativeMoodScore =
                typeof message.negativeMoodScore === 'number'
                  ? options.longs === String
                    ? String(message.negativeMoodScore)
                    : message.negativeMoodScore
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.negativeMoodScore
                    )
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.negativeMoodScore.low >>> 0,
                      message.negativeMoodScore.high >>> 0
                    ).toNumber()
                  : message.negativeMoodScore;
            }
            if (
              message.hyperarousalScore != null &&
              message.hasOwnProperty('hyperarousalScore')
            ) {
              object.hyperarousalScore =
                typeof message.hyperarousalScore === 'number'
                  ? options.longs === String
                    ? String(message.hyperarousalScore)
                    : message.hyperarousalScore
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.hyperarousalScore
                    )
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.hyperarousalScore.low >>> 0,
                      message.hyperarousalScore.high >>> 0
                    ).toNumber()
                  : message.hyperarousalScore;
            }
            if (
              message.isPtsdDiagnosis != null &&
              message.hasOwnProperty('isPtsdDiagnosis')
            ) {
              object.isPtsdDiagnosis = message.isPtsdDiagnosis;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.PCL5Score';
          }
        }

        PCL5Score.prototype.score = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PCL5Score.prototype.reExperiencingScore = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PCL5Score.prototype.avoidanceScore = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PCL5Score.prototype.negativeMoodScore = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PCL5Score.prototype.hyperarousalScore = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PCL5Score.prototype.isPtsdDiagnosis = false;

        return PCL5Score;
      })();

      v1.GAD7Score = (() => {
        class GAD7Score {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GAD7Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.score != null &&
              Object.hasOwnProperty.call(message, 'score')
            ) {
              writer.uint32(8).int64(message.score);
            }
            if (
              message.severityDiagnosis != null &&
              Object.hasOwnProperty.call(message, 'severityDiagnosis')
            ) {
              writer.uint32(18).string(message.severityDiagnosis);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GAD7Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.score = reader.int64();
                  break;
                }
                case 2: {
                  message.severityDiagnosis = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.GAD7Score) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.GAD7Score();
            if (object.score != null) {
              if ($util.Long) {
                (message.score = $util.Long.fromValue(
                  object.score
                )).unsigned = false;
              } else if (typeof object.score === 'string') {
                message.score = parseInt(object.score, 10);
              } else if (typeof object.score === 'number') {
                message.score = object.score;
              } else if (typeof object.score === 'object') {
                message.score = new $util.LongBits(
                  object.score.low >>> 0,
                  object.score.high >>> 0
                ).toNumber();
              }
            }
            if (object.severityDiagnosis != null) {
              message.severityDiagnosis = String(object.severityDiagnosis);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.score =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.score = options.longs === String ? '0' : 0;
              }
              object.severityDiagnosis = '';
            }
            let keys;
            if (message.score != null && message.hasOwnProperty('score')) {
              object.score =
                typeof message.score === 'number'
                  ? options.longs === String
                    ? String(message.score)
                    : message.score
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.score)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.score.low >>> 0,
                      message.score.high >>> 0
                    ).toNumber()
                  : message.score;
            }
            if (
              message.severityDiagnosis != null &&
              message.hasOwnProperty('severityDiagnosis')
            ) {
              object.severityDiagnosis = message.severityDiagnosis;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.GAD7Score';
          }
        }

        GAD7Score.prototype.score = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        GAD7Score.prototype.severityDiagnosis = '';

        return GAD7Score;
      })();

      v1.PHQ8Score = (() => {
        class PHQ8Score {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PHQ8Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.score != null &&
              Object.hasOwnProperty.call(message, 'score')
            ) {
              writer.uint32(8).int64(message.score);
            }
            if (
              message.severityDiagnosis != null &&
              Object.hasOwnProperty.call(message, 'severityDiagnosis')
            ) {
              writer.uint32(18).string(message.severityDiagnosis);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PHQ8Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.score = reader.int64();
                  break;
                }
                case 2: {
                  message.severityDiagnosis = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.PHQ8Score) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.PHQ8Score();
            if (object.score != null) {
              if ($util.Long) {
                (message.score = $util.Long.fromValue(
                  object.score
                )).unsigned = false;
              } else if (typeof object.score === 'string') {
                message.score = parseInt(object.score, 10);
              } else if (typeof object.score === 'number') {
                message.score = object.score;
              } else if (typeof object.score === 'object') {
                message.score = new $util.LongBits(
                  object.score.low >>> 0,
                  object.score.high >>> 0
                ).toNumber();
              }
            }
            if (object.severityDiagnosis != null) {
              message.severityDiagnosis = String(object.severityDiagnosis);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.score =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.score = options.longs === String ? '0' : 0;
              }
              object.severityDiagnosis = '';
            }
            let keys;
            if (message.score != null && message.hasOwnProperty('score')) {
              object.score =
                typeof message.score === 'number'
                  ? options.longs === String
                    ? String(message.score)
                    : message.score
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.score)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.score.low >>> 0,
                      message.score.high >>> 0
                    ).toNumber()
                  : message.score;
            }
            if (
              message.severityDiagnosis != null &&
              message.hasOwnProperty('severityDiagnosis')
            ) {
              object.severityDiagnosis = message.severityDiagnosis;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.PHQ8Score';
          }
        }

        PHQ8Score.prototype.score = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PHQ8Score.prototype.severityDiagnosis = '';

        return PHQ8Score;
      })();

      v1.PHQ9Score = (() => {
        class PHQ9Score {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PHQ9Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.score != null &&
              Object.hasOwnProperty.call(message, 'score')
            ) {
              writer.uint32(8).int64(message.score);
            }
            if (
              message.severityDiagnosis != null &&
              Object.hasOwnProperty.call(message, 'severityDiagnosis')
            ) {
              writer.uint32(18).string(message.severityDiagnosis);
            }
            if (
              message.hasThoughtsOfSelfHarm != null &&
              Object.hasOwnProperty.call(message, 'hasThoughtsOfSelfHarm')
            ) {
              writer.uint32(24).bool(message.hasThoughtsOfSelfHarm);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PHQ9Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.score = reader.int64();
                  break;
                }
                case 2: {
                  message.severityDiagnosis = reader.string();
                  break;
                }
                case 3: {
                  message.hasThoughtsOfSelfHarm = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.PHQ9Score) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.PHQ9Score();
            if (object.score != null) {
              if ($util.Long) {
                (message.score = $util.Long.fromValue(
                  object.score
                )).unsigned = false;
              } else if (typeof object.score === 'string') {
                message.score = parseInt(object.score, 10);
              } else if (typeof object.score === 'number') {
                message.score = object.score;
              } else if (typeof object.score === 'object') {
                message.score = new $util.LongBits(
                  object.score.low >>> 0,
                  object.score.high >>> 0
                ).toNumber();
              }
            }
            if (object.severityDiagnosis != null) {
              message.severityDiagnosis = String(object.severityDiagnosis);
            }
            if (object.hasThoughtsOfSelfHarm != null) {
              message.hasThoughtsOfSelfHarm = Boolean(
                object.hasThoughtsOfSelfHarm
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.score =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.score = options.longs === String ? '0' : 0;
              }
              object.severityDiagnosis = '';
              object.hasThoughtsOfSelfHarm = false;
            }
            let keys;
            if (message.score != null && message.hasOwnProperty('score')) {
              object.score =
                typeof message.score === 'number'
                  ? options.longs === String
                    ? String(message.score)
                    : message.score
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.score)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.score.low >>> 0,
                      message.score.high >>> 0
                    ).toNumber()
                  : message.score;
            }
            if (
              message.severityDiagnosis != null &&
              message.hasOwnProperty('severityDiagnosis')
            ) {
              object.severityDiagnosis = message.severityDiagnosis;
            }
            if (
              message.hasThoughtsOfSelfHarm != null &&
              message.hasOwnProperty('hasThoughtsOfSelfHarm')
            ) {
              object.hasThoughtsOfSelfHarm = message.hasThoughtsOfSelfHarm;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.PHQ9Score';
          }
        }

        PHQ9Score.prototype.score = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        PHQ9Score.prototype.severityDiagnosis = '';
        PHQ9Score.prototype.hasThoughtsOfSelfHarm = false;

        return PHQ9Score;
      })();

      v1.CalculatePCL5Score = (() => {
        class CalculatePCL5Score {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CalculatePCL5Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              $root.fetchactions.morf.v1.PCL5Score.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CalculatePCL5Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = $root.fetchactions.morf.v1.PCL5Score.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.morf.v1.CalculatePCL5Score
            ) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.CalculatePCL5Score();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePCL5Score.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePCL5Score.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePCL5Score.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePCL5Score.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePCL5Score.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = $root.fetchactions.morf.v1.PCL5Score.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = $root.fetchactions.morf.v1.PCL5Score.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.CalculatePCL5Score';
          }
        }

        CalculatePCL5Score.prototype.params = $util.emptyArray;
        CalculatePCL5Score.prototype.configs = $util.emptyArray;
        CalculatePCL5Score.prototype.result = null;

        return CalculatePCL5Score;
      })();

      v1.CalculateGAD7Score = (() => {
        class CalculateGAD7Score {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CalculateGAD7Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              $root.fetchactions.morf.v1.GAD7Score.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CalculateGAD7Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = $root.fetchactions.morf.v1.GAD7Score.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.morf.v1.CalculateGAD7Score
            ) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.CalculateGAD7Score();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculateGAD7Score.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculateGAD7Score.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculateGAD7Score.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculateGAD7Score.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculateGAD7Score.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = $root.fetchactions.morf.v1.GAD7Score.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = $root.fetchactions.morf.v1.GAD7Score.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.CalculateGAD7Score';
          }
        }

        CalculateGAD7Score.prototype.params = $util.emptyArray;
        CalculateGAD7Score.prototype.configs = $util.emptyArray;
        CalculateGAD7Score.prototype.result = null;

        return CalculateGAD7Score;
      })();

      v1.CalculatePHQ8Score = (() => {
        class CalculatePHQ8Score {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CalculatePHQ8Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              $root.fetchactions.morf.v1.PHQ8Score.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CalculatePHQ8Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = $root.fetchactions.morf.v1.PHQ8Score.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.morf.v1.CalculatePHQ8Score
            ) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.CalculatePHQ8Score();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ8Score.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePHQ8Score.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ8Score.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePHQ8Score.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ8Score.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = $root.fetchactions.morf.v1.PHQ8Score.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = $root.fetchactions.morf.v1.PHQ8Score.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.CalculatePHQ8Score';
          }
        }

        CalculatePHQ8Score.prototype.params = $util.emptyArray;
        CalculatePHQ8Score.prototype.configs = $util.emptyArray;
        CalculatePHQ8Score.prototype.result = null;

        return CalculatePHQ8Score;
      })();

      v1.CalculatePHQ9Score = (() => {
        class CalculatePHQ9Score {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CalculatePHQ9Score(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              $root.fetchactions.morf.v1.PHQ9Score.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CalculatePHQ9Score();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = $root.fetchactions.morf.v1.PHQ9Score.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.morf.v1.CalculatePHQ9Score
            ) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.CalculatePHQ9Score();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ9Score.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePHQ9Score.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ9Score.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.CalculatePHQ9Score.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.CalculatePHQ9Score.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = $root.fetchactions.morf.v1.PHQ9Score.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = $root.fetchactions.morf.v1.PHQ9Score.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.CalculatePHQ9Score';
          }
        }

        CalculatePHQ9Score.prototype.params = $util.emptyArray;
        CalculatePHQ9Score.prototype.configs = $util.emptyArray;
        CalculatePHQ9Score.prototype.result = null;

        return CalculatePHQ9Score;
      })();

      v1.HttpPostResult = (() => {
        class HttpPostResult {
          constructor(properties) {
            this.responseHeaders = {};
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new HttpPostResult(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.responseBody != null &&
              Object.hasOwnProperty.call(message, 'responseBody')
            ) {
              google$1.protobuf.Struct.encode(
                message.responseBody,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.responseHeaders != null &&
              Object.hasOwnProperty.call(message, 'responseHeaders')
            ) {
              for (const key of Object.keys(message.responseHeaders)) {
                writer
                  .uint32(18)
                  .fork()
                  .uint32(10)
                  .string(key)
                  .uint32(18)
                  .string(message.responseHeaders[key])
                  .ldelim();
              }
            }
            if (
              message.statusCode != null &&
              Object.hasOwnProperty.call(message, 'statusCode')
            ) {
              writer.uint32(24).int32(message.statusCode);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new HttpPostResult();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.responseBody = google$1.protobuf.Struct.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  if (message.responseHeaders === $util.emptyObject)
                    message.responseHeaders = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = '';
                  value = '';
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = reader.string();
                        break;
                      }
                    }
                    message.responseHeaders[key] = value;
                  }
                  break;
                }
                case 3: {
                  message.statusCode = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.HttpPostResult) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.HttpPostResult();
            if (object.responseBody != null) {
              if (typeof object.responseBody !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.HttpPostResult.responseBody: object expected, but got ' +
                    typeof object.responseBody
                );
              }
              message.responseBody = google$1.protobuf.Struct.fromObject(
                object.responseBody
              );
            }
            if (object.responseHeaders) {
              if (typeof object.responseHeaders !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.HttpPostResult.responseHeaders: object expected, but got ' +
                    typeof object.responseHeaders
                );
              }
              message.responseHeaders = {};
              for (
                let keys = Object.keys(object.responseHeaders), i = 0;
                i < keys.length;
                ++i
              ) {
                message.responseHeaders[keys[i]] = String(
                  object.responseHeaders[keys[i]]
                );
              }
            }
            if (object.statusCode != null) {
              message.statusCode = object.statusCode | 0;
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
              object.responseHeaders = {};
            }
            if (options.defaults) {
              object.responseBody = null;
              object.statusCode = 0;
            }
            let keys;
            if (
              message.responseBody != null &&
              message.hasOwnProperty('responseBody')
            ) {
              object.responseBody = google$1.protobuf.Struct.toObject(
                message.responseBody,
                options
              );
            }
            if (
              message.responseHeaders &&
              (keys = Object.keys(message.responseHeaders)).length
            ) {
              object.responseHeaders = {};
              for (let i = 0; i < keys.length; ++i) {
                object.responseHeaders[keys[i]] =
                  message.responseHeaders[keys[i]];
              }
            }
            if (
              message.statusCode != null &&
              message.hasOwnProperty('statusCode')
            ) {
              object.statusCode = message.statusCode;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.HttpPostResult';
          }
        }

        HttpPostResult.prototype.responseBody = null;
        HttpPostResult.prototype.responseHeaders = $util.emptyObject;
        HttpPostResult.prototype.statusCode = 0;

        return HttpPostResult;
      })();

      v1.HttpPost = (() => {
        class HttpPost {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new HttpPost(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              $root.fetchactions.morf.v1.HttpPostResult.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new HttpPost();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result =
                    $root.fetchactions.morf.v1.HttpPostResult.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.morf.v1.HttpPost) {
              return object;
            }
            const message = new $root.fetchactions.morf.v1.HttpPost();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.HttpPost.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.HttpPost.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.HttpPost.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.HttpPost.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.morf.v1.HttpPost.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result =
                $root.fetchactions.morf.v1.HttpPostResult.fromObject(
                  object.result
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result =
                $root.fetchactions.morf.v1.HttpPostResult.toObject(
                  message.result,
                  options
                );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.morf.v1.HttpPost';
          }
        }

        HttpPost.prototype.params = $util.emptyArray;
        HttpPost.prototype.configs = $util.emptyArray;
        HttpPost.prototype.result = null;

        return HttpPost;
      })();

      v1.EvaluatePauseParamsResult = (() => {
        class EvaluatePauseParamsResult {
          constructor(properties) {
            this.timestamps = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EvaluatePauseParamsResult(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.timestamps != null &&
              Object.hasOwnProperty.call(message, 'timestamps')
            ) {
              for (const element of message.timestamps) {
                timing$1.v1.Timestamp.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EvaluatePauseParamsResult();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.timestamps || !message.timestamps.length) {
                    message.timestamps = [];
                  }
                  message.timestamps.push(
                    timing$1.v1.Timestamp.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.fetchactions.morf.v1.EvaluatePauseParamsResult
            ) {
              return object;
            }
            const message =
              new $root.fetchactions.morf.v1.EvaluatePauseParamsResult();
            if (object.timestamps) {
              if (!Array.isArray(object.timestamps)) {
                throw new TypeError(
                  '.fetchactions.morf.v1.EvaluatePauseParamsResult.timestamps: array type expected, but got ' +
                    typeof object.timestamps
                );
              }
              message.timestamps = new Array(object.timestamps.length);
              for (let i = 0; i < object.timestamps.length; ++i) {
                if (typeof object.timestamps[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.morf.v1.EvaluatePauseParamsResult.timestamps: object expected, but got ' +
                      typeof object.timestamps[i]
                  );
                }
                message.timestamps[i] = timing$1.v1.Timestamp.fromObject(
                  object.timestamps[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.timestamps = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.timestamps && message.timestamps.length) {
              object.timestamps = new Array(message.timestamps.length);
              for (let i = 0; i < message.timestamps.length; ++i) {
                object.timestamps[i] = timing$1.v1.Timestamp.toObject(
                  message.timestamps[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'fetchactions.morf.v1.EvaluatePauseParamsResult'
            );
          }
        }

        EvaluatePauseParamsResult.prototype.timestamps = $util.emptyArray;

        return EvaluatePauseParamsResult;
      })();

      return v1;
    })();

    return morf;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
