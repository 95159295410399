export const Clock = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
    />
  );
};

export const ClockStart = () => {
  return (
    <>
      <circle
        cx='12.5'
        cy='12'
        r='9'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M12.5 6.6C12.5 6.26863 12.7693 5.99689 13.099 6.02997C14.153 6.13571 15.164 6.51911 16.0267 7.1459C16.8894 7.77268 17.5665 8.61578 17.9927 9.58546C18.1261 9.88882 17.9509 10.2289 17.6357 10.3313L12.5 12V6.6Z'
        stroke='none'
      />
    </>
  );
};

export const ClockEnd = () => {
  return (
    <>
      <circle
        cx='12.5'
        cy='12'
        r='9'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M12.5 6.6C12.5 6.26863 12.7693 5.99689 13.099 6.02997C14.153 6.13571 15.164 6.51911 16.0267 7.1459C17.0518 7.89067 17.8148 8.94084 18.2063 10.1459C18.5979 11.351 18.5979 12.649 18.2063 13.8541C17.8148 15.0592 17.0518 16.1093 16.0267 16.8541C15.0016 17.5989 13.7671 18 12.5 18C11.2329 18 9.99837 17.5989 8.97329 16.8541C7.9482 16.1093 7.18521 15.0592 6.79366 13.8541C6.46414 12.8399 6.41194 11.7599 6.63706 10.7249C6.70749 10.4011 7.04914 10.2289 7.3643 10.3313L12.5 12V6.6Z'
        stroke='none'
      />
    </>
  );
};
