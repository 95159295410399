export const Payload = () => {
  return (
    <>
      <path
        d='M14.5 9.75L16.75 12L14.5 14.25M10 14.25L7.75 12L10 9.75M6.25 20.25H18.25C19.4926 20.25 20.5 19.2426 20.5 18V6C20.5 4.75736 19.4926 3.75 18.25 3.75H6.25C5.00736 3.75 4 4.75736 4 6V18C4 19.2426 5.00736 20.25 6.25 20.25Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PayloadLeft = () => {
  return (
    <>
      <path
        d='M18.25 3.75H6.25C5.00736 3.75 4 4.75736 4 6V18C4 19.2426 5.00736 20.25 6.25 20.25H18.25C19.4926 20.25 20.5 19.2426 20.5 18V6C20.5 4.75736 19.4926 3.75 18.25 3.75Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15L9 12L12 9'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 12H16'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PayloadRight = () => {
  return (
    <>
      <path
        d='M18.25 3.75H6.25C5.00736 3.75 4 4.75736 4 6V18C4 19.2426 5.00736 20.25 6.25 20.25H18.25C19.4926 20.25 20.5 19.2426 20.5 18V6C20.5 4.75736 19.4926 3.75 18.25 3.75Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 15L16 12L13 9'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 12H9'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
