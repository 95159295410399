export const SorterTop = () => {
  return (
    <>
      <path
        d='M18.2567 14.6705L13.1835 20.6744C12.8166 21.1085 12.1834 21.1085 11.8165 20.6744L6.74327 14.6705C6.20183 14.0297 6.62314 13 7.42676 13L12.5 14L17.5732 13C18.3769 13 18.7982 14.0297 18.2567 14.6705Z'
        fill='#C7C9DE'
        stroke='none'
      />
      <path
        d='M18.2567 9.32954L13.1835 3.3256C12.8166 2.89146 12.1834 2.89146 11.8165 3.3256L6.74327 9.32954C6.20183 9.97031 6.62314 11 7.42676 11L12.5 10L17.5732 11C18.3769 11 18.7982 9.97031 18.2567 9.32954Z'
        fill='#8285AC'
        stroke='none'
      />
    </>
  );
};

export const SorterBottom = () => {
  return (
    <>
      <path
        d='M18.2567 9.32954L13.1835 3.3256C12.8166 2.89146 12.1834 2.89146 11.8165 3.3256L6.74327 9.32954C6.20183 9.97031 6.62314 11 7.42676 11L12.5 10L17.5732 11C18.3769 11 18.7982 9.97031 18.2567 9.32954Z'
        fill='#C7C9DE'
        stroke='none'
      />
      <path
        d='M18.2567 14.6705L13.1835 20.6744C12.8166 21.1085 12.1834 21.1085 11.8165 20.6744L6.74327 14.6705C6.20183 14.0297 6.62314 13 7.42676 13L12.5 14L17.5732 13C18.3769 13 18.7982 14.0297 18.2567 14.6705Z'
        fill='#8285AC'
        stroke='none'
      />
    </>
  );
};

export const Sorter = () => {
  return (
    <>
      <path
        d='M18.2567 9.32954L13.1835 3.3256C12.8166 2.89146 12.1834 2.89146 11.8165 3.3256L6.74327 9.32954C6.20183 9.97031 6.62314 11 7.42676 11L12.5 10L17.5732 11C18.3769 11 18.7982 9.97031 18.2567 9.32954Z'
        fill='#8285AC'
        stroke='none'
      />
      <path
        d='M18.2567 14.6705L13.1835 20.6744C12.8166 21.1085 12.1834 21.1085 11.8165 20.6744L6.74327 14.6705C6.20183 14.0297 6.62314 13 7.42676 13L12.5 14L17.5732 13C18.3769 13 18.7982 14.0297 18.2567 14.6705Z'
        fill='#8285AC'
        stroke='none'
      />
    </>
  );
};
