import { google } from '@morf/proto/checked_ts_proto';
import { hasField } from './hasField';
import _ from 'lodash';

export const formatType = (
  type: google.api.expr.v1alpha1.Type | null | undefined
): string => {
  if (!type) {
    return 'n/a';
  }
  if (hasField(type, 'listType')) {
    return formatType(type?.listType?.elemType);
  }

  if (hasField(type, 'primitive')) {
    return google.api.expr.v1alpha1.Type.PrimitiveType[
      type?.primitive
    ]?.toLowerCase();
  }

  if (hasField(type, 'dyn')) {
    return 'dynamic';
  }

  if (hasField(type, 'messageType')) {
    return _.isEqual(type.messageType, 'workflow_parameters.v1.ObjectTypeID')
      ? 'objectTypeId'
      : 'messageType';
  }

  return 'n/a';
};
