type RecursiveObject<T> = {
  [K in keyof T]: T[K] extends object ? RecursiveObject<T[K]> : T[K];
};

export const removeObjectKeys = <T extends object>(
  obj: T,
  keysToRemove: string[]
): RecursiveObject<T> => {
  if (typeof obj !== 'object' || obj === null) {
    return obj as RecursiveObject<T>;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      removeObjectKeys(item, keysToRemove)
    ) as unknown as RecursiveObject<T>;
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => !keysToRemove.includes(key))
      .map(([key, value]) => [key, removeObjectKeys(value, keysToRemove)])
  ) as RecursiveObject<T>;
};
