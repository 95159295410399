import { FC } from 'react';
import { FlexItem, Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Button } from '../Button';
import { StyledNumber } from './StepCard.css';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { StepCardProps } from './types';
import { useResize } from '../Hooks/useResize';

export const StepCard: FC<StepCardProps> = ({
  state,
  number,
  label,
  onClick,
}) => {
  const theme = useTheme();
  const isActive = state === 'active';
  const isCompleted = state === 'completed';

  const color = isActive
    ? theme.colors.main.primary.darker
    : theme.colors.text.muted;

  const responsiveStepCard = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'row',
      xs: 'column',
    },
    width: {
      xxl: 'auto',
      xl: 'auto',
      lg: 'auto',
      md: 'auto',
      sm: 'auto',
      xs: '100%',
    },
  });

  return (
    <Flexbox
      direction={responsiveStepCard.direction}
      justifyContent='flex-start'
      alignItems='center'
      data-testid='step-card'
      height='auto'
      shadow={isActive ? 'base' : 'none'}
      borderRadius={1}
      backgroundColor={theme.colors.ui.card}
      p={1.5}
      gap={1}
    >
      <StyledNumber
        justifyContent='center'
        alignItems='center'
        height='auto'
        width='2.5rem'
        borderRadius='full'
        borderWidth={0.0625}
        borderType='border'
        state={state}
        py={0.5}
      >
        {isCompleted ? (
          <Icon name='check' stroke={theme.colors.text.inverse} />
        ) : (
          <Text tag='h3' color={color}>
            {number}
          </Text>
        )}
      </StyledNumber>

      <FlexItem flexGrow={1}>
        <Text tag='h3' color={color} whiteSpace='nowrap'>
          {label}
        </Text>
      </FlexItem>

      {isActive && (
        <Button
          variant='primary'
          onClick={onClick}
          width={responsiveStepCard.width}
        >
          <Text color={theme.colors.text.inverse}>Start</Text>
        </Button>
      )}
    </Flexbox>
  );
};
