import {
  clearAnswerValue,
  getAllAnswerValues,
  getAnswerValue,
} from '@formsort/custom-question-api';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export type AnswerType = {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  schedule_in_morf?: boolean;
  intakeq_id?: string;
  intakeq_service_id?: string;
};

export const useFormsortAnswers = (): {
  answers: AnswerType;
  answersIsSuccess: boolean;
  isFormsortFlow: boolean | undefined;
  isTestingEnvironment: boolean | undefined;
} => {
  const [isFormsortFlow, setIsFormsortFlow] = useState<boolean>();
  const [isTesting, setIsTesting] = useState<boolean>();

  const isTestingEnvironment = !isFormsortFlow && isTesting;

  useEffect(() => {
    const clearExistingAnswer = async () => {
      const existingAnswer = await getAnswerValue();
      if (existingAnswer) clearAnswerValue();
    };

    const isBrowser = typeof window !== 'undefined';
    const isFormsortFlow =
      isBrowser && window.location !== window.parent.location;
    if (isFormsortFlow) clearExistingAnswer();
    setIsFormsortFlow(isFormsortFlow);

    const isLocalHost =
      window.location.hostname === 'localhost' &&
      window.location.port === '3001';
    const isDeployed =
      window.location.href.includes('morf3') &&
      window.location.href.includes('.vercel.app');
    const isTesting = isBrowser && (isLocalHost || isDeployed);
    setIsTesting(isTesting);
  }, []);

  const enabledCondition =
    isFormsortFlow !== undefined && isTesting !== undefined;

  const { data: answers = {} as AnswerType, isSuccess: answersIsSuccess } =
    useQuery(
      ['answers'],
      async () => {
        if (isTestingEnvironment) {
          return {
            first_name: '',
            last_name: '',
            email_address: '',
            phone_number: '',
          };
        } else {
          return (await getAllAnswerValues()) as AnswerType;
        }
      },
      {
        enabled: enabledCondition,
      }
    );

  return {
    answers,
    answersIsSuccess,
    isFormsortFlow,
    isTestingEnvironment,
  };
};
