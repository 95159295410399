'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { google as google$2 } from './any_ts_proto';
import { google as google$1 } from './checked_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflow_extra_fetched_data = (() => {
  const workflow_extra_fetched_data = {};
  workflow_extra_fetched_data.v1 = (() => {
    const v1 = {};
    v1.SerializedFetchExtraDataResult = (() => {
      class SerializedFetchExtraDataResult {
        get type() {
          for (const key of ['scalarMsg', 'repeatedMsgs']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ['scalarMsg', 'repeatedMsgs']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SerializedFetchExtraDataResult(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.resultObjectKey != null &&
            Object.hasOwnProperty.call(message, 'resultObjectKey')
          ) {
            writer.uint32(10).string(message.resultObjectKey);
          }
          if (
            message.protoType != null &&
            Object.hasOwnProperty.call(message, 'protoType')
          ) {
            google$1.api.expr.v1alpha1.Type.encode(
              message.protoType,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.scalarMsg != null &&
            Object.hasOwnProperty.call(message, 'scalarMsg')
          ) {
            $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg.encode(
              message.scalarMsg,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.repeatedMsgs != null &&
            Object.hasOwnProperty.call(message, 'repeatedMsgs')
          ) {
            $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.encode(
              message.repeatedMsgs,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SerializedFetchExtraDataResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.resultObjectKey = reader.string();
                break;
              }
              case 2: {
                message.protoType = google$1.api.expr.v1alpha1.Type.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.scalarMsg =
                  $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.repeatedMsgs =
                  $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult
          ) {
            return object;
          }
          const message =
            new $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult();
          if (object.resultObjectKey != null) {
            message.resultObjectKey = String(object.resultObjectKey);
          }
          if (object.protoType != null) {
            if (typeof object.protoType !== 'object') {
              throw new TypeError(
                '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.protoType: object expected, but got ' +
                  typeof object.protoType
              );
            }
            message.protoType = google$1.api.expr.v1alpha1.Type.fromObject(
              object.protoType
            );
          }
          if (object.scalarMsg != null) {
            if (typeof object.scalarMsg !== 'object') {
              throw new TypeError(
                '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.scalarMsg: object expected, but got ' +
                  typeof object.scalarMsg
              );
            }
            message.scalarMsg =
              $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg.fromObject(
                object.scalarMsg
              );
          }
          if (object.repeatedMsgs != null) {
            if (typeof object.repeatedMsgs !== 'object') {
              throw new TypeError(
                '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.repeatedMsgs: object expected, but got ' +
                  typeof object.repeatedMsgs
              );
            }
            message.repeatedMsgs =
              $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.fromObject(
                object.repeatedMsgs
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.resultObjectKey = '';
            object.protoType = null;
          }
          let keys;
          if (
            message.resultObjectKey != null &&
            message.hasOwnProperty('resultObjectKey')
          ) {
            object.resultObjectKey = message.resultObjectKey;
          }
          if (
            message.protoType != null &&
            message.hasOwnProperty('protoType')
          ) {
            object.protoType = google$1.api.expr.v1alpha1.Type.toObject(
              message.protoType,
              options
            );
          }
          if (
            message.scalarMsg != null &&
            message.hasOwnProperty('scalarMsg')
          ) {
            object.scalarMsg =
              $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg.toObject(
                message.scalarMsg,
                options
              );
            if (options.oneofs) {
              object.type = 'scalarMsg';
            }
          }
          if (
            message.repeatedMsgs != null &&
            message.hasOwnProperty('repeatedMsgs')
          ) {
            object.repeatedMsgs =
              $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.toObject(
                message.repeatedMsgs,
                options
              );
            if (options.oneofs) {
              object.type = 'repeatedMsgs';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult'
          );
        }
      }

      SerializedFetchExtraDataResult.prototype.resultObjectKey = '';
      SerializedFetchExtraDataResult.prototype.protoType = null;
      SerializedFetchExtraDataResult.prototype.scalarMsg = null;
      SerializedFetchExtraDataResult.prototype.repeatedMsgs = null;
      SerializedFetchExtraDataResult.ScalarMsg = (() => {
        class ScalarMsg {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ScalarMsg(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.resultMsg != null &&
              Object.hasOwnProperty.call(message, 'resultMsg')
            ) {
              google$2.protobuf.Any.encode(
                message.resultMsg,
                writer.uint32(10).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ScalarMsg();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.resultMsg = google$2.protobuf.Any.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_extra_fetched_data.v1
                .SerializedFetchExtraDataResult.ScalarMsg
            ) {
              return object;
            }
            const message =
              new $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg();
            if (object.resultMsg != null) {
              if (typeof object.resultMsg !== 'object') {
                throw new TypeError(
                  '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg.resultMsg: object expected, but got ' +
                    typeof object.resultMsg
                );
              }
              message.resultMsg = google$2.protobuf.Any.fromObject(
                object.resultMsg
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.resultMsg = null;
            }
            let keys;
            if (
              message.resultMsg != null &&
              message.hasOwnProperty('resultMsg')
            ) {
              object.resultMsg = google$2.protobuf.Any.toObject(
                message.resultMsg,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.ScalarMsg'
            );
          }
        }

        ScalarMsg.prototype.resultMsg = null;

        return ScalarMsg;
      })();

      SerializedFetchExtraDataResult.RepeatedMsgs = (() => {
        class RepeatedMsgs {
          constructor(properties) {
            this.resultMsgs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new RepeatedMsgs(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.resultMsgs != null &&
              Object.hasOwnProperty.call(message, 'resultMsgs')
            ) {
              for (const element of message.resultMsgs) {
                google$2.protobuf.Any.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new RepeatedMsgs();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.resultMsgs || !message.resultMsgs.length) {
                    message.resultMsgs = [];
                  }
                  message.resultMsgs.push(
                    google$2.protobuf.Any.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_extra_fetched_data.v1
                .SerializedFetchExtraDataResult.RepeatedMsgs
            ) {
              return object;
            }
            const message =
              new $root.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs();
            if (object.resultMsgs) {
              if (!Array.isArray(object.resultMsgs)) {
                throw new TypeError(
                  '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.resultMsgs: array type expected, but got ' +
                    typeof object.resultMsgs
                );
              }
              message.resultMsgs = new Array(object.resultMsgs.length);
              for (let i = 0; i < object.resultMsgs.length; ++i) {
                if (typeof object.resultMsgs[i] !== 'object') {
                  throw new TypeError(
                    '.workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs.resultMsgs: object expected, but got ' +
                      typeof object.resultMsgs[i]
                  );
                }
                message.resultMsgs[i] = google$2.protobuf.Any.fromObject(
                  object.resultMsgs[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.resultMsgs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.resultMsgs && message.resultMsgs.length) {
              object.resultMsgs = new Array(message.resultMsgs.length);
              for (let i = 0; i < message.resultMsgs.length; ++i) {
                object.resultMsgs[i] = google$2.protobuf.Any.toObject(
                  message.resultMsgs[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_extra_fetched_data.v1.SerializedFetchExtraDataResult.RepeatedMsgs'
            );
          }
        }

        RepeatedMsgs.prototype.resultMsgs = $util.emptyArray;

        return RepeatedMsgs;
      })();

      return SerializedFetchExtraDataResult;
    })();

    v1.ExtraFetchedDataRef = (() => {
      class ExtraFetchedDataRef {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ExtraFetchedDataRef(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(10).string(message.nodeId);
          }
          if (
            message.resultObjectKey != null &&
            Object.hasOwnProperty.call(message, 'resultObjectKey')
          ) {
            writer.uint32(18).string(message.resultObjectKey);
          }
          if (
            message.protoType != null &&
            Object.hasOwnProperty.call(message, 'protoType')
          ) {
            google$1.api.expr.v1alpha1.Type.encode(
              message.protoType,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.skipped != null &&
            Object.hasOwnProperty.call(message, 'skipped')
          ) {
            writer.uint32(32).bool(message.skipped);
          }
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(42).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ExtraFetchedDataRef();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.nodeId = reader.string();
                break;
              }
              case 2: {
                message.resultObjectKey = reader.string();
                break;
              }
              case 3: {
                message.protoType = google$1.api.expr.v1alpha1.Type.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.skipped = reader.bool();
                break;
              }
              case 5: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_extra_fetched_data.v1.ExtraFetchedDataRef
          ) {
            return object;
          }
          const message =
            new $root.workflow_extra_fetched_data.v1.ExtraFetchedDataRef();
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          if (object.resultObjectKey != null) {
            message.resultObjectKey = String(object.resultObjectKey);
          }
          if (object.protoType != null) {
            if (typeof object.protoType !== 'object') {
              throw new TypeError(
                '.workflow_extra_fetched_data.v1.ExtraFetchedDataRef.protoType: object expected, but got ' +
                  typeof object.protoType
              );
            }
            message.protoType = google$1.api.expr.v1alpha1.Type.fromObject(
              object.protoType
            );
          }
          if (object.skipped != null) {
            message.skipped = Boolean(object.skipped);
          }
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.nodeId = '';
            object.resultObjectKey = '';
            object.protoType = null;
            object.skipped = false;
          }
          let keys;
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          if (
            message.resultObjectKey != null &&
            message.hasOwnProperty('resultObjectKey')
          ) {
            object.resultObjectKey = message.resultObjectKey;
          }
          if (
            message.protoType != null &&
            message.hasOwnProperty('protoType')
          ) {
            object.protoType = google$1.api.expr.v1alpha1.Type.toObject(
              message.protoType,
              options
            );
          }
          if (message.skipped != null && message.hasOwnProperty('skipped')) {
            object.skipped = message.skipped;
          }
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflow_extra_fetched_data.v1.ExtraFetchedDataRef'
          );
        }
      }

      ExtraFetchedDataRef.prototype.nodeId = '';
      ExtraFetchedDataRef.prototype.resultObjectKey = '';
      ExtraFetchedDataRef.prototype.protoType = null;
      ExtraFetchedDataRef.prototype.skipped = false;
      ExtraFetchedDataRef.prototype.workflowExecutionId = null;

      return ExtraFetchedDataRef;
    })();

    return v1;
  })();

  return workflow_extra_fetched_data;
})();

export const workflow_extra_fetched_data = $root.workflow_extra_fetched_data;
