import _ from 'lodash';
import { ClassFilterProps } from './types';
import { FC, memo } from 'react';
import { Filter } from '../../Filter';
import { formatClasses } from './formatClasses';
import { workflow_evaluation_results } from '@morf/proto/workflow_evaluation_results_v1_ts_proto';

export const defaultErrorClasses = [
  workflow_evaluation_results.v1.ErrorClass.ERROR,
  workflow_evaluation_results.v1.ErrorClass.WARNING,
  workflow_evaluation_results.v1.ErrorClass.INTERNAL_ERROR,
];

const ClassFilter: FC<ClassFilterProps> = ({
  defaultClasses = defaultErrorClasses,
  classes,
  setIsClassFilterSelected,
}) => {
  const value = classes.map(({ name }) => name).join(', ');
  const classOptions = formatClasses(defaultClasses);

  return (
    <Filter
      allOptions={classOptions}
      name='Class'
      options={classes}
      setIsFilterSelected={setIsClassFilterSelected}
      value={value}
    />
  );
};

export const MemoizedClassFilter = memo(ClassFilter);
