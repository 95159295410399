import { Button } from '../../Button';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { ProfileLookup } from './ProfileLookup';
import { ProfileLookupFieldNotPresent } from './ProfileLookupFieldNotPresent';
import { ProfileLookupMultipleProfilesFound } from './ProfileLookupMultipleProfilesFound';
import { ProfileLookupsSequenceProps } from './types';
import { Text } from '../../Typography';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const ProfileLookupsSequence: FC<ProfileLookupsSequenceProps> = ({
  eventPayloadFieldSchemas,
  isReadOnly,
  lookupType,
  lookupsSequence = [new profiles.v1.Lookup({})],
  onLookupAdd,
  onLookupFieldChange,
  onLookupFieldNotPresentChange,
  onLookupIndexChange,
  onLookupMultipleProfilesFoundChange,
  onLookupRemove,
}) => {
  const theme = useTheme();

  const handleAdd = () => {
    onLookupAdd([...lookupsSequence, new profiles.v1.Lookup({})]);
  };

  const handleRemove = (index: number) => {
    onLookupRemove(lookupsSequence.filter((_, i) => i !== index));
  };

  const handleLookupFieldUpdate = (fieldLookup: string, index: number) => {
    onLookupFieldChange(
      lookupsSequence.map((lookup: profiles.v1.Lookup, i: number) => {
        if (i !== index) {
          return lookup;
        }

        if (lookup.index?.email) {
          return {
            ...lookup,
            index: new profiles.v1.LookupIndex({
              email: new profiles.v1.UniqueIndex({
                ...lookup.index.email,
                uniqueIndexFieldLookup:
                  new workflow_parameters.v1.EventPayloadFieldLookup({
                    celExpressionStr: fieldLookup,
                  }),
              }),
            }),
          };
        }

        if (lookup.index?.phone) {
          return {
            ...lookup,
            index: new profiles.v1.LookupIndex({
              phone: new profiles.v1.UniqueIndex({
                ...lookup.index.email,
                uniqueIndexFieldLookup:
                  new workflow_parameters.v1.EventPayloadFieldLookup({
                    celExpressionStr: fieldLookup,
                  }),
              }),
            }),
          };
        }

        return {
          ...lookup,
          index: new profiles.v1.LookupIndex({
            id: new profiles.v1.IdIndex({
              ...lookup.index?.id,
              idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup(
                {
                  celExpressionStr: fieldLookup,
                }
              ),
            }),
          }),
        };
      })
    );
  };

  const handleLookupIndexUpdate = (type: string, index: number) => {
    onLookupIndexChange(
      lookupsSequence.map((lookup: profiles.v1.Lookup, i: number) => {
        if (i !== index) {
          return lookup;
        }

        const celExpressionStr =
          lookup.index?.email?.uniqueIndexFieldLookup?.celExpressionStr ||
          lookup.index?.phone?.uniqueIndexFieldLookup?.celExpressionStr ||
          lookup.index?.id?.idFieldLookup?.celExpressionStr;

        if (type === 'email_address') {
          return {
            ...lookup,
            index: new profiles.v1.LookupIndex({
              email: new profiles.v1.UniqueIndex({
                uniqueIndexFieldLookup:
                  new workflow_parameters.v1.EventPayloadFieldLookup({
                    celExpressionStr: celExpressionStr,
                  }),
              }),
            }),
          };
        }

        if (type === 'phone_number') {
          return {
            ...lookup,
            index: new profiles.v1.LookupIndex({
              phone: new profiles.v1.UniqueIndex({
                uniqueIndexFieldLookup:
                  new workflow_parameters.v1.EventPayloadFieldLookup({
                    celExpressionStr: celExpressionStr,
                  }),
              }),
            }),
          };
        }

        return {
          ...lookup,
          index: new profiles.v1.LookupIndex({
            id: new profiles.v1.IdIndex({
              thirdPartyIdType: parseInt(type),
              idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup(
                {
                  celExpressionStr: celExpressionStr,
                }
              ),
            }),
          }),
        };
      })
    );
  };

  const handleFieldNotPresentUpdate = (
    updatedFieldNotPresentAction: number,
    index: number
  ) => {
    onLookupFieldNotPresentChange(
      lookupsSequence.map((l: profiles.v1.Lookup, i: number) =>
        i === index
          ? new profiles.v1.Lookup({
              ...l,
              fieldNotPresentAction: updatedFieldNotPresentAction,
            })
          : l
      )
    );
  };

  const handleMultipleProfilesFoundUpdate = (
    updatedMultipleProfilesFoundAction: number,
    index: number
  ) => {
    onLookupMultipleProfilesFoundChange(
      lookupsSequence?.map((l: profiles.v1.Lookup, i: number) =>
        i === index
          ? new profiles.v1.Lookup({
              ...l,
              index: new profiles.v1.LookupIndex({
                ...(l.index &&
                  l.index.email && {
                    email: {
                      ...l.index.email,
                      multipleProfilesAction:
                        updatedMultipleProfilesFoundAction,
                    },
                  }),
                ...(l.index &&
                  l.index.phone && {
                    phone: {
                      ...l.index.phone,
                      multipleProfilesAction:
                        updatedMultipleProfilesFoundAction,
                    },
                  }),
              }),
            })
          : l
      )
    );
  };

  return (
    <Flexbox
      data-testid='profile-lookups-sequence'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.5}
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='p2'>Profile lookup field conditions</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select which event payload fields map to which identifiers
          </Text>
        </Flexbox>

        {lookupsSequence.map((lookup, index) => {
          const isFirstLookup = index === 0;
          return (
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              height='auto'
              gap={0.5}
            >
              <ProfileLookup
                key={index}
                eventPayloadFieldSchemas={eventPayloadFieldSchemas}
                index={index}
                isReadOnly={isReadOnly}
                lookup={lookup}
                onLookupFieldChange={(data, index) =>
                  handleLookupFieldUpdate(data, index)
                }
                onLookupIndexChange={(data, index) =>
                  handleLookupIndexUpdate(data, index)
                }
              />
              {!isReadOnly && (
                <Button
                  variant='custom'
                  backgroundColor={theme.colors.ui.card}
                  borderColor={theme.colors.ui.divider}
                  shape='square'
                  onClick={() => handleRemove(index)}
                  isDisabled={isFirstLookup}
                  height='2.25rem'
                  width='2.25rem'
                >
                  <Icon
                    name='trash'
                    stroke={
                      isFirstLookup
                        ? theme.colors.support.red.light
                        : theme.colors.support.red.darkest
                    }
                    size={1}
                    strokeWidth={1.75}
                  />
                </Button>
              )}
            </Flexbox>
          );
        })}

        {!isReadOnly && (
          <Button
            variant='custom'
            backgroundColor={theme.colors.ui.card}
            borderColor={theme.colors.ui.divider}
            shape='square'
            onClick={handleAdd}
            height='2.25rem'
            width='2.25rem'
          >
            <Icon
              name='plus'
              stroke={theme.colors.main.primary.darker}
              size={1}
              strokeWidth={1.75}
            />
          </Button>
        )}
      </Flexbox>

      {lookupsSequence.map((lookup, index) => {
        return (
          <ProfileLookupFieldNotPresent
            key={index}
            isReadOnly={isReadOnly}
            lookup={lookup}
            onFieldNotPresentActionChange={(data) =>
              handleFieldNotPresentUpdate(data, index)
            }
          />
        );
      })}

      {lookupsSequence.map((lookup, index) => {
        return (
          (lookup?.index?.email?.uniqueIndexFieldLookup?.celExpressionStr ||
            lookup?.index?.phone?.uniqueIndexFieldLookup?.celExpressionStr) &&
          lookupType ===
            profiles.v1.LookupType.LOOKUP_TYPE_LEAD_AND_PATIENT && (
            <ProfileLookupMultipleProfilesFound
              key={index}
              isReadOnly={isReadOnly}
              lookup={lookup}
              onMultipleProfilesActionChange={(data) =>
                handleMultipleProfilesFoundUpdate(data, index)
              }
            />
          )
        );
      })}
    </Flexbox>
  );
};
export const MemoizedProfileLookupsSequence = memo(ProfileLookupsSequence);
