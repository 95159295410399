import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Payload } from '../Payload';
import { PayloadSidebarProps } from './types';
import { Sidebar } from '../Sidebar';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const PayloadSidebar: FC<PayloadSidebarProps> = ({
  name,
  onClose,
  payload,
}) => {
  const theme = useTheme();
  return (
    <Sidebar isOpen width='27.75rem'>
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        borderType='borderBottom'
        p={1.25}
        height='auto'
      >
        <Icon
          name='double-chevron-right'
          stroke={theme.colors.ui.dark}
          size={1.25}
          cursor='pointer'
          onClick={onClose}
        />
        <Text tag='h5'>{name}</Text>
      </Flexbox>
      <Payload payload={payload} />
    </Sidebar>
  );
};

export const MemoizedPayloadSidebar = memo(PayloadSidebar);
