import { Handle, Position } from 'reactflow';
import { FC, memo } from 'react';
import { nodeWidth } from '../../constants';
import { Flexbox } from '../../../Flexbox';
import { HiddenNodeProps } from './types';

const HiddenNode: FC<HiddenNodeProps> = () => {
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <Flexbox
        data-testid='hidden-node'
        direction='row'
        justifyContent='center'
        alignItems='center'
        backgroundColor='transparent'
        borderType='none'
        height='3rem'
        width={nodeWidth}
      >
        {' '}
      </Flexbox>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedHiddenNode = memo(HiddenNode);
