import { FC, memo, useLayoutEffect, useState } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { Icon } from '../../../Icon';
import { Button } from '../../../Button';
import { DropdownInputMultiSelectProps } from './types';
import { Option } from '../../types';

const DropdownInputMultiSelect: FC<DropdownInputMultiSelectProps> = ({
  containerWidth,
  onChange,
  readOnly,
  selectedOptions,
}) => {
  const [hiddenOptionsCount, setHiddenOptionsCount] = useState(0);

  const handleOptionClick = (option: Option) => {
    onChange(option);
  };

  const calculateOptionWidth = (option: Option) => {
    const label = option.label.length * 10;
    const padding = 0.5;
    const closeButton = 1.25;
    const closeButtonGap = 0.25;
    const optionGap = 0.25;
    const optionWidth =
      label + (optionGap + closeButton + closeButtonGap + padding) * 16;
    return optionWidth;
  };

  const inputPadding = 0.5;
  const iconButtonGap = 0.25;
  const inputIcon = 1.25;
  const inputWidth =
    containerWidth - (inputPadding + inputIcon + iconButtonGap) * 16;

  useLayoutEffect(() => {
    let allOptionsWidth = 0;
    let numHiddenItems = 0;

    for (let i = 0; i < selectedOptions.length; i++) {
      const optionWidth = calculateOptionWidth(selectedOptions[i]);
      allOptionsWidth += optionWidth;

      if (allOptionsWidth >= inputWidth) {
        numHiddenItems = selectedOptions.length - i;
        break;
      }
    }

    setHiddenOptionsCount(numHiddenItems);
  }, [selectedOptions.length, containerWidth]);

  return (
    <Flexbox justifyContent='flex-start' alignItems='center' gap={0.25}>
      {selectedOptions
        .slice(0, selectedOptions.length - hiddenOptionsCount)
        .map((option, index) => (
          <Button
            key={index}
            size='xs'
            variant='primary'
            borderRadius={0.25}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick(option);
            }}
            isDisabled={readOnly}
          >
            <Flexbox
              key={index}
              justifyContent='flex-start'
              alignItems='center'
              gap={0.25}
            >
              <Text tag='p2' color='inherit' weight='bold' whiteSpace='nowrap'>
                &nbsp;
                {option.label}
              </Text>
              <Icon size={1} name='close' />
            </Flexbox>
          </Button>
        ))}

      {hiddenOptionsCount > 0 && (
        <Button
          size='xs'
          variant='primary'
          borderRadius={0.25}
          data-testid='hidden-options-button'
          isDisabled={readOnly}
        >
          <Flexbox justifyContent='center' alignItems='center'>
            <Text tag='p2' color='inherit'>
              +{hiddenOptionsCount}
            </Text>
          </Flexbox>
        </Button>
      )}
    </Flexbox>
  );
};

export const MemoizedDropdownInputMultiSelect = memo(DropdownInputMultiSelect);
