import { FC } from 'react';
import { AvatarListProps } from './types';
import { Flexbox } from '../Flexbox';
import { Avatar } from '../Avatar/Avatar';
import { Container } from '../Container';

export const AvatarList: FC<AvatarListProps> = ({ list }) => {
  return (
    <Flexbox justifyContent='flex-start' alignItems='center' gap={0}>
      {list.map((avatar, index) => {
        const marginLeft = index !== 0 ? '-0.25rem' : '0';
        return (
          <Container
            key={index}
            style={{ marginLeft }}
            width='auto'
            height='auto'
            data-testid={'avatar-list-' + index}
          >
            <Avatar
              src={avatar.src}
              alt={avatar.alt}
              tooltip={avatar.tooltip}
              size='xs'
            />
          </Container>
        );
      })}
    </Flexbox>
  );
};
