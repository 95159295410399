import { FC, memo, useRef } from 'react';
import { TimelineProps } from './types';
import { TimelineRow } from '../TimelineRow';
import { Flexbox } from '../Flexbox';

const Timeline: FC<TimelineProps> = ({ fetchNextPage, timelineRows }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const handleScroll = () => {
    if (
      containerElement &&
      containerElement.scrollHeight - containerElement.scrollTop <=
        containerElement.clientHeight
    ) {
      fetchNextPage && fetchNextPage();
    }
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      overflow='scroll'
      onScroll={handleScroll}
      gap={0}
    >
      {timelineRows.map((timelineRow, index) => (
        <TimelineRow
          key={index}
          {...timelineRow}
          tooltipDirection={index <= 1 ? 'bottom' : 'top'}
        />
      ))}
    </Flexbox>
  );
};

export const MemoizedTimeline = memo(Timeline);
