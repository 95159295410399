export const SanaBenefits = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.387 23.8319C28.4957 23.396 28.5977 22.9881 28.7002 22.5762H31.1389V32.097H28.7038C28.604 31.6999 28.5002 31.288 28.3888 30.844C28.2175 31.027 28.0773 31.1974 27.9164 31.3456C26.8503 32.3308 25.5937 32.5388 24.2431 32.1278C22.4575 31.5843 21.4908 30.2813 21.1097 28.5272C20.8342 27.2572 20.9604 26.0042 21.56 24.8382C22.4045 23.196 23.7645 22.3272 25.6386 22.3567C26.7182 22.3736 27.5861 22.8703 28.267 23.6945C28.2984 23.7329 28.3321 23.7694 28.3865 23.8319H28.387ZM28.5043 27.3518C28.4777 27.1385 28.471 26.9207 28.4216 26.7132C28.2031 25.7882 27.6679 25.1313 26.7209 24.8877C25.7888 24.6481 24.9618 24.8725 24.3231 25.6026C23.7506 26.2567 23.6166 27.0412 23.7555 27.8806C23.9789 29.2309 25.2539 30.0966 26.5969 29.8123C27.7398 29.57 28.4854 28.6017 28.5043 27.3527V27.3518Z'
        fill='#FDC20B'
      />
      <path
        d='M28.387 8.59613C28.4993 8.14588 28.6009 7.73758 28.7034 7.32571H31.133V16.855H28.7011C28.5996 16.4507 28.4962 16.0384 28.3942 15.631C28.1241 15.8858 27.885 16.1539 27.6041 16.3686C26.6966 17.062 25.6629 17.2289 24.5659 16.979C22.8463 16.5877 21.7708 15.4761 21.2575 13.8487C20.7465 12.2289 20.9061 10.6479 21.7969 9.17221C22.7668 7.56533 24.7151 6.77506 26.5011 7.26056C27.1155 7.42745 27.627 7.76837 28.0602 8.2271C28.1618 8.33464 28.2566 8.44887 28.3865 8.59613H28.387ZM28.5034 12.1098C28.4769 11.8965 28.4692 11.6787 28.4207 11.4708C28.2184 10.6082 27.7299 9.96784 26.8652 9.69163C26.0095 9.41809 25.1955 9.54615 24.5214 10.1628C23.787 10.8344 23.6 11.6988 23.7578 12.6492C23.9919 14.0642 25.3915 14.9348 26.7775 14.5359C27.8171 14.2369 28.4868 13.2878 28.5038 12.1098H28.5034Z'
        fill='#FDC20B'
      />
      <path
        d='M8.81828 22.572H11.2533C11.3626 23.0098 11.4713 23.4471 11.5868 23.9116C11.7288 23.7581 11.8529 23.614 11.9877 23.4806C12.687 22.7867 13.5176 22.3632 14.519 22.3512C16.3652 22.3293 17.6911 23.3788 18.0817 25.1727C18.1697 25.5765 18.2201 25.996 18.2232 26.4087C18.2385 28.2374 18.2304 30.0661 18.23 31.8947C18.23 31.9594 18.2223 32.0246 18.2174 32.1004H15.4479V31.8287C15.4479 30.2379 15.4524 28.647 15.4439 27.0562C15.4421 26.7541 15.4178 26.4458 15.3509 26.1522C15.1037 25.0651 14.1482 24.7073 13.2133 24.9215C12.5064 25.0834 12.0219 25.5471 11.6385 26.129C11.5904 26.2017 11.585 26.3115 11.5846 26.4043C11.5814 28.2106 11.5823 30.017 11.5823 31.8238C11.5823 31.9112 11.5823 31.9987 11.5823 32.0995H8.81738V22.5725L8.81828 22.572Z'
        fill='#FDC20B'
      />
      <path
        d='M8.79102 15.7109C9.23371 15.055 9.64899 14.4392 10.058 13.8323C10.4503 14.0652 10.8211 14.3155 11.2184 14.5141C11.8688 14.8394 12.5605 15.0273 13.2975 14.9331C13.4917 14.9081 13.6953 14.8408 13.8611 14.739C14.3434 14.4436 14.3501 13.8064 13.8571 13.5329C13.5223 13.3472 13.1411 13.2419 12.7771 13.1112C12.1389 12.8818 11.4773 12.706 10.8629 12.4262C9.85394 11.9661 9.32764 11.1482 9.32989 10.0357C9.33214 8.93311 9.88764 8.14729 10.8391 7.631C11.8337 7.09151 12.9083 7.03038 14.004 7.18834C14.8643 7.31239 15.6688 7.60334 16.4076 8.0634C16.4647 8.0991 16.5214 8.1348 16.5762 8.17362C16.5937 8.18567 16.6045 8.20753 16.627 8.2352C16.2184 8.84386 15.8081 9.4543 15.3883 10.0799C14.8715 9.70732 14.3196 9.43824 13.7092 9.29946C13.3119 9.20932 12.911 9.1705 12.5101 9.27537C12.2346 9.34721 12.0512 9.51499 11.9982 9.80415C11.947 10.083 12.036 10.3329 12.2733 10.4601C12.6445 10.6591 13.0409 10.8162 13.4355 10.9679C14.0818 11.216 14.7582 11.3981 15.3802 11.6939C16.1654 12.0674 16.7034 12.6895 16.8324 13.5802C17.053 15.104 16.2697 16.3588 14.7847 16.8118C13.1272 17.3173 11.516 17.1228 9.9672 16.3825C9.5663 16.1911 9.19326 15.9425 8.79102 15.7114V15.7109Z'
        fill='#FDC20B'
      />
      <path
        d='M17.3164 19.718C17.3155 18.357 18.3789 17.2981 19.7465 17.2981C21.116 17.2981 22.1843 18.3561 22.1847 19.7127C22.1852 21.0719 21.1196 22.1321 19.7524 22.1321C18.3798 22.1321 17.3173 21.0795 17.3164 19.7185V19.718Z'
        fill='#FDE379'
      />
    </svg>
  );
};
