import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { ProfileConvertNodeProps } from '../types';
import { Text } from '../../../../Typography';
import { Toggle } from '../../../../Toggle';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { handleNodeSave } from '../handleNodeSave';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';

const ProfileConvertMergePhoneNumber: FC<ProfileConvertNodeProps> = ({
  id,
  data,
}) => {
  const theme = useTheme();

  const { nodes, setNodes } = useWorkflow();
  const { configuration, onAddNode, onUpdateNode, isReadOnly, isRequired } =
    data.value;

  const mergeIfPhoneNumberAlreadyExists =
    data?.value?.configuration?.morfV1?.convertProfileToPatient
      ?.mergeIfPhoneNumberAlreadyExists;

  const handleSave = (updatedMergeIfPhoneNumberAlreadyExists: boolean) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          convertProfileToPatient:
            new destinationactions.morf.v1.ConvertProfileToPatient({
              ...configuration?.morfV1?.convertProfileToPatient,
              mergeIfPhoneNumberAlreadyExists:
                updatedMergeIfPhoneNumberAlreadyExists,
            }),
        }),
      });

      handleNodeSave(
        data,
        destinationAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  return (
    <Flexbox
      data-testid='profile-convert-merge-phone-number'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      height='auto'
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0}
      >
        <Text tag='p2'>
          Merge if phone_number matches with existing profile
        </Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Error will emit by default when turned off.
        </Text>
      </Flexbox>
      <Toggle
        key='phone-number'
        defaultOption={mergeIfPhoneNumberAlreadyExists ? 'on' : 'off'}
        onChange={(value) => handleSave(value === 'on')}
        options={['off', 'on']}
        readOnly={isReadOnly}
      />
    </Flexbox>
  );
};

export const MemoizedProfileConvertMergePhoneNumber = memo(
  ProfileConvertMergePhoneNumber
);
