import { values } from '@morf/proto/values_v1_ts_proto';
import { formatPhoneNumber, formatValueDate } from '@morf/ui';
import { durationToString } from './durationToString';
import { timestampToString } from './timestampToString';
import { urlToString } from './urlToString';
import { ratioValueToString } from './ratioValueToString';
import { redactedValueToString } from './redactedValueToString';
import { numericValueToString } from './numericValueToString';
import { numericRangeValueToString } from './numericRangeValueToString';
import { comparisonValueToString } from './comparisonValueToString';
import { rawBytesValueToString } from './rawBytesValueToString';
import { arrayValueToString } from './arrayValueToString';
import { objectValueToString } from './objectValueToString';

export const formatValue = (v: values.v1.IValue | null | undefined): string => {
  if (!v) {
    return 'n/a';
  }

  switch (true) {
    case !!Object.keys(v).find((key) => key == 'boolValue'):
      return v.boolValue ? 'true' : 'false';
    case !!v.stringValue:
      return v.stringValue as string;
    case !!v.timestampValue:
      return timestampToString(v.timestampValue).isoDateString;
    case !!v.durationValue:
      return durationToString(v.durationValue);
    case !!v.dateValue:
      return formatValueDate(v.dateValue);
    case !!v.floatValue:
      return numericValueToString(v);
    case !!v.intValue:
      return numericValueToString(v);
    case !!v.unsignedIntValue:
      return numericValueToString(v);
    case !!v.rawBytesValue:
      return rawBytesValueToString(v.rawBytesValue);
    case !!v.bigIntegerValue:
      return numericValueToString(v);
    case !!v.bigDecimalValue:
      return numericValueToString(v);
    case !!v.numericRangeValue:
      return numericRangeValueToString(v.numericRangeValue);
    case !!v.comparisonValue:
      return comparisonValueToString(v.comparisonValue);
    case !!v.ratioValue:
      return ratioValueToString(v.ratioValue);
    case !!v.arrayValue:
      return arrayValueToString(v.arrayValue);
    case !!v.objectValue:
      return objectValueToString(v.objectValue);
    case !!v.redactedValue:
      return redactedValueToString(v.redactedValue);
    case !!v.urlValue:
      return urlToString(v.urlValue);
    case !!v.phoneNumberValue:
      return formatPhoneNumber(v.phoneNumberValue);
    default:
      return `Does not support formatting this type yet`;
  }
};
