import { FC } from 'react';
import { RenderHTMLProps } from './types';

export const RenderHTML: FC<RenderHTMLProps> = ({ htmlContent }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlContent,
      }}
    ></div>
  );
};
