export const Destination = () => {
  return (
    <>
      <path
        d='M6 7C6.67244 4.82363 8.52326 4 13.6165 4C21.5522 4 21.6165 5.99951 21.6165 12C21.6165 18.0005 21.6418 20 13.6165 20C8.52277 20 6.67226 19.1945 6 17.0717'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 12H3M11 12L8.75 14.25M11 12L8.75 9.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
