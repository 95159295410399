import _ from 'lodash';
import { allActions } from '../constants';

export const hasDoNotAssociateThirdPartyId = (actionType: string): boolean =>
  _.some(allActions, (action, key) => {
    return (
      _.isEqual(_.toLower(key), _.toLower(actionType)) &&
      action.prototype &&
      action.prototype.hasOwnProperty('doNotAssociateThirdPartyId')
    );
  });
