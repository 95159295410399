import { BaseEdge, getSmoothStepPath } from 'reactflow';
import { FC, memo } from 'react';
import { SimpleEdgeProps } from './types';

const SimpleEdge: FC<SimpleEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 1,
    borderRadius: 25,
  });

  return <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />;
};

export const MemoizedSimpleEdge = memo(SimpleEdge);
