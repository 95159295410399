import { FC } from 'react';
import { Container } from '../Container';
import { Image } from '../Image';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { ProfileImageProps } from './types';

export const ProfileImage: FC<ProfileImageProps> = ({ imageUrl, name }) => {
  const theme = useTheme();

  return (
    <Container width='max-content' data-testid='profile-image'>
      {imageUrl ? (
        <Image
          hasVisibleBorder
          src={imageUrl}
          alt={name}
          width='5rem'
          height='5rem'
          radius='full'
        />
      ) : (
        <Flexbox
          justifyContent='center'
          alignItems='center'
          width='5rem'
          height='5rem'
          backgroundColor={theme.colors.ui.divider}
          borderRadius='full'
        >
          <Icon
            name='user'
            size={2.5}
            stroke={theme.colors.main.dark.darkest}
            strokeWidth={1.25}
          />
        </Flexbox>
      )}
    </Container>
  );
};
