import {
  BorderRadiusType,
  BorderWidthType,
  BoxShadowType,
  BreakpointsType,
  DefaultThemeProps,
  FontSizesType,
  FontWeightsType,
  LineHeightsType,
  SpacingType,
} from './types';

export const BorderRadius: BorderRadiusType = {
  0: '0rem', // 0px
  0.125: '0.125rem', // 2px
  0.25: '0.25rem', // 4px
  0.5: '0.5rem', // 8px
  0.75: '0.75rem', // 12px
  1: '1rem', // 16px
  1.25: '1.25rem', // 20px
  1.5: '1.5rem', // 24px
  1.75: '1.75rem', // 28 px
  2: '2rem', // 32 px
  full: '9999px', // 9999px
};

export const BorderWidth: BorderWidthType = {
  0: '0rem', // 0px
  0.0625: '0.1rem', // 1px
  0.125: '0.125rem', // 2px
  0.25: '0.25rem', // 4px
  0.5: '0.5rem', // 8px
  1: '1rem', // 16px
};

export const Breakpoints: BreakpointsType = {
  xs: '(min-width: 0rem)', // 0px
  sm: '(min-width: 35.5rem)', // 568px
  md: '(min-width: 48rem)', // 768px
  lg: '(min-width: 62rem)', // 992px
  xl: '(min-width: 75rem)', // 1200px
  xxl: '(min-width: 87.5rem)', // 1400px
};

export const Spacing: SpacingType = {
  0: '0rem', // 0px
  0.125: '0.125rem', // 4px
  0.25: '0.25rem', // 4px
  0.5: '0.5rem', // 8px
  0.75: '0.75rem', // 12px
  1: '1rem', // 16px
  1.25: '1.25rem', // 20px
  1.5: '1.5rem', // 24px
  1.75: '1.75rem', // 28 px
  2: '2rem', // 32px
  2.25: '2.25rem', // 36px
  2.5: '2.5rem', // 40px
  2.75: '2.75rem', // 44px
  3: '3rem', // 48px
  4: '4rem', // 64px
  5: '5rem', // 80px
  6: '6rem', // 96px
  8: '8rem', // 128px
  10: '10rem', // 160px
  12: '12rem', // 192px
  14: '14rem', // 224px
  16: '16rem', // 256px
  18: '18rem', // 288px
};

export const BoxShadow: BoxShadowType = {
  none: 'none',
  sm: '0rem 0.125rem 0.25rem', // 0px 2px 4px
  base: '0rem 0.125rem 0.875rem', // 0px 2px 18px
};

export const FontWeights: FontWeightsType = {
  thin: 100, // 100px
  extralight: 200, // 200px
  light: 300, // 300px
  normal: 400, // 400px
  medium: 500, // 500px
  semibold: 600, // 600px
  bold: 700, // 700px
  extrabold: 800, // 800px
  black: 900, // 900px
};

export const FontSizes: FontSizesType = {
  0.5: '0.5rem', // 8px
  0.75: '0.75rem', // 12px
  0.875: '0.875rem', // 14px
  1: '1rem', // 16px
  1.125: '1.125rem', // 18px
  1.5: '1.5rem', // 24px
  2: '2rem', // 32px
};

export const LineHeights: LineHeightsType = {
  0.875: '0.875rem', // 14px
  1: '1rem', // 16px
  1.125: '1.125rem', // 18px
  1.25: '1.25rem', // 20px
  1.375: '1.375rem', // 22px
  1.5: '1.5rem', // 24px
  1.75: '1.75rem', // 28px
  2.25: '2.25rem', // 36px
};

export const DefaultTheme: DefaultThemeProps = {
  breakpoints: Breakpoints,
  borderRadius: BorderRadius,
  borderWidth: BorderWidth,
  boxShadow: BoxShadow,
  spacing: Spacing,
  fontSizes: FontSizes,
  fontWeights: FontWeights,
  lineHeights: LineHeights,
};
