import { ThemeContainerProps } from '@morf/theming';
import { MarkerType } from 'reactflow';
import { EdgeType } from './types';

export const getDefaultEdgeOptions = (theme: ThemeContainerProps) => {
  return {
    type: EdgeType.SimpleEdge,
    sourceHandle: 'bottom',
    targetHandle: 'top',
    markerEnd: {
      type: MarkerType.Arrow,
      color: theme.colors.ui.muted,
      width: 20,
      height: 20,
    },
  };
};
