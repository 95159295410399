import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { Edge, Node } from 'reactflow';

export const profileLookupSubflowProfileFound = (index: number) => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-profile-found-${index}`,
    type: 'branchNode',
    data: {
      value: {
        name: 'profile found',
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: -250, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-profile-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-profile-found-${index}`,
  });

  nodes
    .filter((node) => node.id.startsWith('lookup-subflow-profile-found'))
    .forEach((profileFoundNode) => {
      edges.push({
        id: `lookup-subflow-${profileFoundNode.id}-to-lookup-subflow-return-to-workflow`,
        source: profileFoundNode.id,
        target: 'lookup-subflow-return-to-workflow',
      });
    });

  return { nodes, edges };
};
