import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { ProfileLookupConfigurationProps } from './types';
import { ProfileLookupNoProfileFound } from './ProfileLookupNoProfileFound';
import { ProfileLookupType } from './ProfileLookupType';
import { ProfileLookupsSequence } from './ProfileLookupsSequence';

const ProfileLookupConfiguration: FC<ProfileLookupConfigurationProps> = ({
  lookupConfiguration,
  ...props
}) => {
  return (
    <Flexbox
      data-testid='profile-lookup-configuration'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      {...props}
    >
      <ProfileLookupType
        lookupType={lookupConfiguration?.lookupType}
        {...props}
      />
      <ProfileLookupsSequence
        lookupsSequence={lookupConfiguration?.lookupsSequence}
        lookupType={lookupConfiguration?.lookupType}
        {...props}
      />
      <ProfileLookupNoProfileFound
        noProfileFoundAction={lookupConfiguration?.noProfileFoundAction}
        {...props}
      />
    </Flexbox>
  );
};

export const MemoizedProfileLookupConfiguration = memo(
  ProfileLookupConfiguration
);
