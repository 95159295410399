import { Bar, BarChart, ResponsiveContainer } from 'recharts';
import { SparksProps } from './types';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const Sparks = <T,>({ data, dataKey }: SparksProps<T>) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={data}>
        <Bar
          barSize={1.5}
          dataKey={dataKey}
          fill={theme.colors.main.primary.darker}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const MemoizedSparks = memo(Sparks);
