import { Container } from '../Container';
import { FC, MutableRefObject, memo, useEffect, useRef } from 'react';
import { StyledModal } from '../Modal/Modal.css';
import { WrapperModalProps } from './types';
import { createPortal } from 'react-dom';
import { useTheme } from '@morf/theming';

const WrapperModal: FC<WrapperModalProps> = ({
  backdrop,
  children,
  id = 'wrapper-modal',
  isOpen = true,
  onClose,
  ...props
}) => {
  const theme = useTheme();

  const {
    backgroundColor = theme.colors.ui.card,
    borderRadius = 0.75,
    bottom = 'auto',
    height = 'auto',
    left = '50%',
    right = 'auto',
    shadow = 'base',
    top = '50%',
    width = 'auto',
  } = props;

  const {
    position: backdropPosition = 'absolute',
    top: backdropTop = '0rem',
    left: backdropLeft = '0rem',
    width: backdropWidth = '100%',
    height: backdropHeight = '100%',
    backgroundColor: backdropBgColor = theme.colors.ui.body,
    opacity: backdropOpacity = 0.4,
    zIndex: backdropZIndex = 'var(--modal-z-index)',
    ...restBackdropProps
  } = backdrop || {};

  const elRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    const modalRoot = document.getElementById('modal');
    if (!modalRoot || !elRef.current) {
      return;
    }
    modalRoot.appendChild(elRef.current);
    return () => {
      if (elRef.current) {
        modalRoot.removeChild(elRef.current);
      }
    };
  }, []);

  if (!isOpen) return null;

  return createPortal(
    <Container data-testid={id} id={id}>
      <Container
        data-testid={id + `-backdrop`}
        backgroundColor={backdropBgColor}
        height={backdropHeight}
        left={backdropLeft}
        opacity={backdropOpacity}
        position={backdropPosition}
        top={backdropTop}
        width={backdropWidth}
        zIndex={backdropZIndex}
        onClick={onClose}
        {...restBackdropProps}
      />
      <StyledModal
        data-testid={id + `-content`}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        bottom={bottom}
        height={height}
        left={left}
        right={right}
        shadow={shadow}
        top={top}
        width={width}
        {...props}
      >
        {children}
      </StyledModal>
    </Container>,
    elRef.current
  );
};

export const MemoizedWrapperModal = memo(WrapperModal);
