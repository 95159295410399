import { FC, memo, useState } from 'react';
import { Icon } from '../Icon';
import { Flexbox } from '../Flexbox';
import { useTheme } from '@morf/theming';
import { Text } from '../Typography';
import { RemoveNodeProps } from './type';
import { RemoveNodeModal } from './RemoveNodeModal';

const RemoveNode: FC<RemoveNodeProps> = ({ type, onRemoveNode }) => {
  const theme = useTheme();
  const [showRemoveNodeModal, setShowRemoveNodeModal] = useState(false);

  return (
    <>
      <Flexbox
        data-testid='remove-node'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        cursor='pointer'
        height='auto'
        gap={0.25}
        onClick={() => setShowRemoveNodeModal(true)}
      >
        <Icon name='trash' size={1} stroke={theme.colors.support.red.darkest} />
        <Text tag='p2' color={theme.colors.support.red.darkest}>
          Remove {type}
        </Text>
      </Flexbox>

      {showRemoveNodeModal && (
        <RemoveNodeModal
          isOpen={showRemoveNodeModal}
          onClose={() => setShowRemoveNodeModal(false)}
          onRemoveNode={onRemoveNode}
          type={type}
        />
      )}
    </>
  );
};

export const MemoizedRemoveNode = memo(RemoveNode);
