import _ from 'lodash';
import { ActionExecutionRow } from './ActionExecutionRow';
import { ActionExecutionTimelineProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const ActionExecutionTimeline: FC<ActionExecutionTimelineProps> = ({
  actionExecutionRows,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='action-execution-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      gap={0}
      p={0.5}
    >
      {_.isEmpty(actionExecutionRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No actions
          </Text>
        </Flexbox>
      ) : (
        actionExecutionRows.map((row, index) => (
          <ActionExecutionRow key={index} {...row} />
        ))
      )}
    </Flexbox>
  );
};

export const MemoizedActionExecutionTimeline = memo(ActionExecutionTimeline);
