import rpcService from '../../../../../../server/browser-server';
import { Dropdown } from '../../../../../Dropdown';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { SkeletonLoadable } from '../../../../../Skeleton';
import { Text } from '../../../../../Typography';
import { TriggerNodeProps } from '../../types';
import { event_payload } from '@morf/proto/event_payload_v2_ts_proto';
import { formatEventTypes } from './formatEventTypes';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { useRouter } from 'next/router';

const TriggerEventType: FC<TriggerNodeProps> = (node) => {
  const theme = useTheme();
  const router = useRouter();
  const { organizationId } = router.query;
  const { configuration, onUpdateTrigger, isReadOnly } = node.data.value;

  const {
    data: getValidTriggerEventsResponse,
    isLoading: getValidTriggerEventsResponseIsLoading,
  } = useQuery<workflows.v1.GetValidTriggerEventsResponse>(
    ['/workflows.v1.WorkflowsService/GetValidTriggerEvents', organizationId],
    async () => {
      return await rpcService.workflowsV1Service.getValidTriggerEvents({
        stub: '',
      });
    }
  );

  const sourceApplication = configuration?.sourceApplication;
  const objectCategory = configuration?.objectCategory;

  const selectedEventTypes = configuration?.triggerEvents
    ? Object.values(configuration.triggerEvents).flatMap(({ eventTypes }) =>
        eventTypes ? Object.values(eventTypes).map(String) : []
      )
    : [];

  const validEventTypes = formatEventTypes(
    getValidTriggerEventsResponse?.validTriggerEvents,
    sourceApplication,
    objectCategory,
    selectedEventTypes
  );

  const selectedEventTypesAsOptions = validEventTypes.filter((eventType) =>
    selectedEventTypes.includes(eventType.value)
  );

  const handleNodeSave = (updatedTriggerEventTypes: number[]) => {
    const updatedTriggerEvents = new event_payload.v2.EventTypes({
      [Object.keys(configuration?.triggerEvents!)[0]]: {
        eventTypes: updatedTriggerEventTypes,
      },
    });

    onUpdateTrigger &&
      onUpdateTrigger({
        trigger: new workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger({
          triggerEventTypes: updatedTriggerEvents,
          triggerExtraCelExpression:
            configuration?.extraFilter?.celExpressionStr,
          triggerObjectCategory: configuration?.objectCategory,
          triggerObjectTypeFilters: configuration?.objectTypeFilters,
          uniquenessConflictResolution:
            configuration?.uniquenessConflictResolution,
        }),
      });
  };

  return (
    <Flexbox
      data-testid='trigger-event-type'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <Text tag='p2'>Event types</Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Select which event types should trigger the workflow.
        </Text>
      </Flexbox>
      {getValidTriggerEventsResponseIsLoading ? (
        <SkeletonLoadable isLoading height='2rem' width='10rem'>
          <></>
        </SkeletonLoadable>
      ) : (
        <Dropdown
          defaultOptions={selectedEventTypesAsOptions}
          options={validEventTypes}
          onChange={(selectedOptions) => {
            const selectedValues = selectedOptions.map((option) =>
              parseInt(option.value)
            );
            handleNodeSave(selectedValues);
          }}
          height='2.25rem'
          readOnly={isReadOnly}
          multiSelect
          clearable
        />
      )}
    </Flexbox>
  );
};

export const MemoizedTriggerEventType = memo(TriggerEventType);
