import { EdgeLabelRenderer, BaseEdge, getSmoothStepPath } from 'reactflow';
import { FC, memo } from 'react';
import { LabelEdgeProps } from './types';
import { StyledLabelContainer } from './LabelEdge.css';
import { Text } from '../../../Typography';
import { useTheme } from '@morf/theming';

const LabelEdge: FC<LabelEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  label,
}) => {
  const theme = useTheme();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 1,
    borderRadius: 25,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />

      <EdgeLabelRenderer>
        <StyledLabelContainer labelX={labelX} labelY={labelY}>
          <Text tag='p3' color={theme.colors.text.muted}>
            {label}
          </Text>
        </StyledLabelContainer>
      </EdgeLabelRenderer>
    </>
  );
};

export const MemoizedLabelEdge = memo(LabelEdge);
