const isBrowser = typeof window !== 'undefined';

export const LocalStorage = {
  get<T>(key: string, initialValue?: T): T | undefined {
    if (!isBrowser) return undefined;
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      throw new Error('get at useLocalStorage: ' + error);
    }
  },

  set<T>(key: string, value: T): void {
    if (!isBrowser) return;
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      throw new Error('set at useLocalStorage: ' + error);
    }
  },

  remove(key: string): void {
    if (!isBrowser) return;
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      throw new Error('remove at useLocalStorage: ' + error);
    }
  },
};
