import { useTheme } from '@morf/theming';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { FC } from 'react';
import { Avatar } from '../../../Avatar';
import { Button } from '../../../Button';
import { Icon } from '../../../Icon';
import { FinalStepProps } from './types';
import Link from 'next/link';
import { GreenTicketIcon } from './GreenTicketIcon';

export const FinalStep: FC<FinalStepProps> = ({
  imageUrl,
  title,
  description,
  buttonLink,
  buttonLabel,
  onClose,
  onFinish,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      direction='column'
      backgroundColor={theme.colors.ui.card}
      justifyContent='flex-start'
      alignItems='flex-end'
      py={1.5}
      px={2}
    >
      <Icon
        cursor='pointer'
        name='close'
        stroke={theme.colors.text.muted}
        size={1.5}
        onClick={onClose}
      />
      <Flexbox
        direction='column'
        justifyContent='center'
        alignItems='center'
        gap={2.5}
      >
        <Flexbox
          justifyContent='center'
          alignItems='flex-start'
          gap={0}
          height='auto'
        >
          <Avatar src={imageUrl} alt='integration' size='xl' />
          <GreenTicketIcon />
        </Flexbox>
        <Flexbox
          height='auto'
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.75}
        >
          <Text tag='h1'>{title}</Text>
          <Text tag='subtitle'>{description}</Text>
        </Flexbox>
        <Link href={buttonLink}>
          <Button variant='primary' onClick={onFinish}>
            <Text color='inherit'>{buttonLabel}</Text>
          </Button>
        </Link>
      </Flexbox>
    </Flexbox>
  );
};
