import { timing } from '@morf/proto/timing_v1_ts_proto';
import { formatCustomLabel } from './formatCustomLabel';

export const createCustomTimeUrlParam = (
  executionStartime: timing.v1.Timestamp | null | undefined
): string => {
  if (!executionStartime) {
    return '';
  }

  const startTimestamp = new timing.v1.Timestamp({
    seconds: executionStartime.seconds.subtract(5),
    nanoseconds: executionStartime.nanoseconds,
  });

  const endTimestamp = new timing.v1.Timestamp({
    seconds: executionStartime.seconds.add(5),
    nanoseconds: executionStartime.nanoseconds,
  });

  return formatCustomLabel(startTimestamp, endTimestamp);
};
