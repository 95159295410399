import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Node } from 'reactflow';
import { NodeData } from '../types';
import { onAddNodeData, onUpdateNodeData } from '../../types';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const handleNodeSave = (
  data: NodeData<workflows.v1.WorkflowDestinationAction | null | undefined>,
  destinationAction: workflows.v1.WorkflowDestinationAction,
  id: string,
  isRequired: boolean | undefined,
  nodes: Node[],
  setNodes: Dispatch<SetStateAction<Node[]>>,
  onAddNode?: (data: onAddNodeData) => void,
  onUpdateNode?: (data: onUpdateNodeData) => void
) => {
  if (isRequired) {
    const updatedNodes = nodes.map((n) => {
      if (n.id === id) {
        return {
          ...n,
          data: {
            ...n.data,
            value: {
              ...n.data.value,
              configuration: destinationAction,
            },
          },
        };
      }
      return n;
    });
    setNodes(updatedNodes);

    const isValidNode =
      _.has(
        destinationAction.morfV1?.convertProfileToPatient,
        'mergeIfEmailAddressAlreadyExists'
      ) &&
      _.has(
        destinationAction.morfV1?.convertProfileToPatient,
        'mergeIfPhoneNumberAlreadyExists'
      );

    if (isValidNode) {
      onAddNode &&
        onAddNode({
          node: new workflows.v1.CreateWorkflowNodeRequest({
            id: id,
            name: data.value.name,
            description: data.value.description,
            destinationAction: destinationAction,
          }),
        });
    }
  } else {
    onUpdateNode &&
      onUpdateNode({
        nodeId: id,
        type: new workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType({
          destinationAction: destinationAction,
        }),
      });
  }
};
