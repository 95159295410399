import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { ToastProps } from './types';
import { ToastStyle } from './Toast.css';
import { useTheme } from '@morf/theming';

const Toast: FC<ToastProps> = ({
  description,
  icon,
  message,
  onClose,
  type,
}) => {
  const theme = useTheme();

  let strokeColor;

  switch (type) {
    case 'inactive':
      strokeColor = theme.colors.ui.dark;
      break;
    case 'alert':
      strokeColor = theme.colors.support.red.darkest;
      break;
    case 'warning':
      strokeColor = theme.colors.support.yellow.darkest;
      break;
    case 'success':
      strokeColor = theme.colors.support.green.darkest;
      break;
    default:
      strokeColor = theme.colors.ui.dark;
      break;
  }

  return (
    <>
      <ToastStyle />
      <Flexbox
        data-testid='toast'
        justifyContent='flex-start'
        alignItems='center'
        shadow='base'
        height='auto'
        width='auto'
        backgroundColor={theme.colors.ui.card}
        borderRadius={1}
        p={1}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.5}
        >
          <Container width='auto' height='auto'>
            <Icon name={icon} stroke={strokeColor} />
          </Container>
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={0}
          >
            <Text tag='p2'>{message}</Text>
            {description && (
              <Text tag='p3' color={theme.colors.text.muted}>
                {description}
              </Text>
            )}
          </Flexbox>
          {onClose && (
            <Container width='auto' height='auto' cursor='pointer'>
              <Icon
                name='close'
                stroke={theme.colors.ui.dark}
                onClick={onClose}
                size={1.25}
              />
            </Container>
          )}
        </Flexbox>
      </Flexbox>
    </>
  );
};

export const MemoizedToast = memo(Toast);
