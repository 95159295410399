export const Freshdesk = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_521_1613)'>
        <path
          d='M19.9531 4.99986H31.22C31.7179 4.99586 32.2115 5.09104 32.6722 5.27985C33.1329 5.46866 33.5514 5.74732 33.9033 6.09957C34.2551 6.45182 34.5333 6.87062 34.7216 7.33152C34.9099 7.79243 35.0046 8.28622 35 8.78408V20.0467C35 28.307 28.3072 34.9999 20.0469 34.9999H19.9616C17.9974 35.0016 16.0521 34.6162 14.2369 33.8657C12.4217 33.1152 10.7723 32.0144 9.38277 30.626C7.99328 29.2377 6.89102 27.5892 6.139 25.7747C5.38698 23.9601 4.99994 22.0152 5 20.051C5 11.7672 11.6816 5.08517 19.9531 4.99986Z'
          fill='#25C16F'
        />
        <path
          d='M19.953 11.682C16.1595 11.682 13.084 14.757 13.084 18.5511V23.2217C13.1112 24.4719 14.118 25.4787 15.3682 25.5059H17.3112V20.1406H14.6862V18.6406C14.8455 15.8159 17.1832 13.6062 20.013 13.6062C22.8429 13.6062 25.1749 15.8117 25.3343 18.6406V20.1406H22.6718V25.5097H24.4273V25.595C24.4085 26.7547 23.4734 27.6903 22.3179 27.7044H20.2212C20.0505 27.7044 19.863 27.7897 19.863 27.9598C19.8672 28.0535 19.9062 28.1422 19.9725 28.2085C20.0388 28.2748 20.1275 28.3138 20.2212 28.318H22.3305C23.8329 28.3086 25.0484 27.0931 25.0577 25.5908V25.4202C25.5595 25.3071 26.0075 25.0256 26.3269 24.6224C26.6463 24.2192 26.818 23.7187 26.8132 23.2044V18.6406C26.8985 14.7716 23.8301 11.6862 19.9441 11.6862L19.953 11.682Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_521_1613'>
          <rect
            width='30'
            height='30'
            fill='white'
            transform='translate(5 5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
