import { css } from '@morf/theming';
import { ReactFlowProps } from 'reactflow';

export const StyledCustomNode = css<ReactFlowProps>`
  .react-flow__node {
    border: none;
    border-radius: ${(props) => props.theme.borderRadius[0.5]};

    &:not(.selectable) {
      cursor: default;
    }

    &.selectable {
      &.selected {
        outline: none;
      }
    }

    ${(props) =>
      props.nodes?.some((node) => node.selected) &&
      `
        &:not(.selected) {
          opacity: 0.5;
          transition: opacity 0.3s ease;
        }
      `}
  }
`;
