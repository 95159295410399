import _ from 'lodash';
import { Container } from '../../Container';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../../Flexbox';
import { IntegrationIcon } from '../../IntegrationIcon';
import { Text } from '../../Typography';
import { WorkflowExecutionRowProps } from './types';
import { WorkflowExecutionStatus } from '../../WorkflowExecutionStatus';
import { WorkflowExecutionTooltip } from './WorkflowExecutionTooltip';
import { WrapperModal } from '../../WrapperModal';
import { convertTimestampToMoment } from '../../Filters';
import { convertToKebabCase } from '../../Helpers/convertToKebabCase';
import { defaultDateTimeFormatWithSeconds } from '../../Filters';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { useHover } from '../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';

const WorkflowExecutionRow: FC<WorkflowExecutionRowProps> = ({
  applicationType,
  description,
  executionStartTime,
  instantaneousMatchedProfileEmail,
  instantaneousMatchedProfilePhone,
  isSelected,
  matchedProfileIndex,
  onClick,
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  supersededWorkflowExecutionId,
  title,
  triggeredAtTime,
  workflowErrors,
  workflowExecutionId,
  workflowName,
  workflowVersion,
  ...props
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const backgroundColor = isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;
  const borderColor = isSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;
  const descriptionTag = isSelected ? 'h6' : 'p3';
  const nameTag = isSelected ? 'h5' : 'p2';
  const formattedTimestamp = convertTimestampToMoment(
    executionStartTime || triggeredAtTime
  ).format(defaultDateTimeFormatWithSeconds);

  const profileMatch =
    instantaneousMatchedProfileEmail ||
    instantaneousMatchedProfilePhone ||
    matchedProfileIndex?.thirdPartyId ||
    'No profile match';

  const handleClick = () => {
    onClick();
    updateUrlParam({ workflowExecutionId: workflowExecutionId });
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, handleShowTooltip]);

  return (
    <>
      <Flexbox
        key={workflowExecutionId}
        containerRef={containerRef}
        data-testid='workflow-execution-row'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        backgroundColor={backgroundColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        borderType='border'
        borderColor={borderColor}
        borderRadius={0.25}
        height='auto'
        width='16.5rem'
        cursor='pointer'
        p={0.5}
        gap={0.25}
      >
        <Container width='auto' height='auto'>
          {!!applicationType && (
            <IntegrationIcon
              name={convertToKebabCase(
                source_application.v2.SourceApplication.Application[
                  applicationType
                ]
              )}
              size={1.25}
            />
          )}
        </Container>

        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='auto'
            gap={0.25}
          >
            <Container width='11.5rem' height='auto'>
              <Text tag={nameTag} whiteSpace='nowrap'>
                {title === 'workflowName' && workflowName}
                {title === 'profileMatch' && profileMatch}
              </Text>
            </Container>

            <WorkflowExecutionStatus
              variant='icon'
              retryNumber={retryNumber}
              retryOriginalWorkflowExecutionId={
                retryOriginalWorkflowExecutionId
              }
              status={status}
              supersededWorkflowExecutionId={supersededWorkflowExecutionId}
              workflowErrors={workflowErrors}
            />
          </Flexbox>

          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='auto'
            gap={0.5}
          >
            <Container width='7.25rem' height='auto'>
              <Text
                tag={descriptionTag}
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {description === 'profileMatch' && profileMatch}
                {description === 'workflowVersion' &&
                  'v' + _.toString(workflowVersion)}
              </Text>
            </Container>
            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>
          </Flexbox>
        </Flexbox>
      </Flexbox>

      {showTooltip && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <WorkflowExecutionTooltip
            applicationType={applicationType}
            executionStartTime={executionStartTime}
            instantaneousMatchedProfileEmail={instantaneousMatchedProfileEmail}
            instantaneousMatchedProfilePhone={instantaneousMatchedProfilePhone}
            matchedProfileIndex={matchedProfileIndex}
            retryNumber={retryNumber}
            retryOriginalWorkflowExecutionId={retryOriginalWorkflowExecutionId}
            status={status}
            supersededWorkflowExecutionId={supersededWorkflowExecutionId}
            triggeredAtTime={triggeredAtTime}
            workflowErrors={workflowErrors}
            workflowExecutionId={workflowExecutionId}
            workflowName={workflowName}
            workflowVersion={workflowVersion}
            {...props}
          />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedWorkflowExecutionRow = memo(WorkflowExecutionRow);
