import { FC } from 'react';
import { ModalProps } from './types';
import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Container } from '../Container';
import { WrapperModal } from '../WrapperModal';

export const Modal: FC<ModalProps> = ({ children, onClose, ...props }) => {
  const theme = useTheme();
  return (
    <WrapperModal id='modal' onClose={onClose} {...props}>
      <Flexbox
        direction='column'
        alignItems='flex-end'
        p={2.5}
        position='relative'
      >
        <Container
          position='absolute'
          width='1.25rem'
          top='1.25rem'
          right='1.25rem'
        >
          <Icon
            cursor='pointer'
            name='close'
            stroke={theme.colors.text.muted}
            size={1.25}
            onClick={onClose}
          />
        </Container>
        {children}
      </Flexbox>
    </WrapperModal>
  );
};

export default Modal;
