import { StyledSubRow } from './FullPageTableSubRow.css';
import { memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Container } from '../../Container';
import { Divider } from '../../Divider';
import { FullPageTableSubRowProps } from './types';

const SubRow: <RowData>(
  props: FullPageTableSubRowProps<RowData>
) => JSX.Element = ({ row, children }): JSX.Element => {
  const index = row.row.index;
  const isLastRow =
    index + 1 === row.row.getParentRow()?.original?.subRows?.length;
  const isSubRow = !!row.row.getParentRow();

  return (
    <Flexbox
      gap={0}
      justifyContent='flex-start'
      alignItems='center'
      pl={isSubRow ? 0.5 : 0}
      pb={isLastRow ? 0.5 : 0}
    >
      {isSubRow ? (
        <StyledSubRow
          justifyContent='flex-start'
          alignItems='center'
          isLastRow={isLastRow}
          gap={0.5}
        >
          <Container width='0.25rem' height='auto'>
            {isSubRow && <Divider />}
          </Container>
          <Flexbox
            width='100% - 1.5rem'
            justifyContent='center'
            alignItems='center'
            py={0.25}
          >
            {children}
          </Flexbox>
        </StyledSubRow>
      ) : (
        children
      )}
    </Flexbox>
  );
};

export const FullPageTableSubRow = memo(SubRow) as typeof SubRow;
