import _ from 'lodash';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { TriggerConflictResolution } from './TriggerConflictResolution';
import { Text } from '../../../../../Typography';
import { Toggle } from '../../../../../Toggle';
import { TriggerNodeProps } from '../../types';
import { object_category } from '@morf/proto/object_category_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const TriggerWorkflowUniqueness: FC<TriggerNodeProps> = (node) => {
  const theme = useTheme();
  const { configuration, onUpdateTrigger, isReadOnly } = node.data.value;

  const uniquenessConflictResolution =
    configuration?.uniquenessConflictResolution;

  const objectCategory = configuration?.objectCategory
    ? _.toLower(
        object_category.v1.ObjectCategory.Category[
          configuration?.objectCategory
        ]
      )
    : '';

  const [
    workflowUniquenessConflictResolutionScope,
    setWorkflowUniquenessConflictResolutionScope,
  ] = useState<string>(
    uniquenessConflictResolution?.scope ===
      workflows.v1.WorkflowUniquenessConflictResolution.ConflictScope.WORKFLOW
      ? 'on'
      : 'off'
  );

  const handleWorkflowUniquenessChange = (value: string) => {
    setWorkflowUniquenessConflictResolutionScope(value);
    if (
      value === 'off' &&
      value !== workflowUniquenessConflictResolutionScope
    ) {
      handleNodeSave();
    }
  };

  const handleNodeSave = () => {
    onUpdateTrigger &&
      onUpdateTrigger({
        trigger: new workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger({
          triggerEventTypes: configuration?.triggerEvents,
          triggerExtraCelExpression:
            configuration?.extraFilter?.celExpressionStr,
          triggerObjectCategory: configuration?.objectCategory,
          triggerObjectTypeFilters: configuration?.objectTypeFilters,
          uniquenessConflictResolution: null,
        }),
      });
  };

  return (
    <Flexbox
      data-testid='trigger-workflow-uniqueness'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
      >
        <Flexbox
          direction='column'
          alignItems='flex-start'
          justifyContent='center'
          height='auto'
          gap={0}
        >
          <Text tag='p2'>
            Limit to one active workflow execution per {objectCategory}
          </Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Workflow executions will run in parallel when toggled off.
          </Text>
        </Flexbox>
        <Toggle
          defaultOption={workflowUniquenessConflictResolutionScope}
          onChange={(value) => handleWorkflowUniquenessChange(value)}
          options={['off', 'on']}
          readOnly={isReadOnly}
        />
      </Flexbox>

      {workflowUniquenessConflictResolutionScope === 'on' && (
        <TriggerConflictResolution {...node} />
      )}
    </Flexbox>
  );
};

export const MemoizedTriggerWorkflowUniqueness = memo(
  TriggerWorkflowUniqueness
);
