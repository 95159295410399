import { FC } from 'react';
import { AppointmentCancellationProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { Button } from '../Button';

export const AppointmentCancellation: FC<AppointmentCancellationProps> = ({
  appointmentName,
  providerName,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      id='appointment-cancellation'
      p={1.25}
      direction='column'
      gap={0.5}
      maxWidth='47.5rem'
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={0.25}
      >
        <Container width='1.25rem' height='1.25rem'>
          <Icon name='check' stroke={theme.colors.support.green.darkest} />
        </Container>
        <Text tag='h4' color={theme.colors.support.green.darkest}>
          Confirmed
        </Text>
      </Flexbox>

      <Text tag='h2'>
        Your {appointmentName} with {providerName} was cancelled
      </Text>

      {onClick && (
        <Container pt={0.5}>
          <Button variant='primary' onClick={onClick}>
            <Text color='inherit'>Schedule Appointment</Text>
          </Button>
        </Container>
      )}
    </Flexbox>
  );
};
