import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { ObjectTypeId } from './ObjectTypeId/types';

export const formatObjectTypeIds = (
  objectTypeIds: workflow_parameters.v1.ObjectTypeID[],
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null
): ObjectTypeId[] => {
  return objectTypeIds.map((objectTypeId, index) => {
    return {
      ...objectTypeId,
      index: index,
      isSelected: selectedSource?.objectTypeId?.id === objectTypeId?.id,
    };
  });
};
