import { Container } from '../Container';
import { FC, memo, useState } from 'react';
import { StyledTooltip } from './Tooltip.css';
import { Text } from '../Typography';
import { TooltipProps } from './types';
import { useTheme } from '@morf/theming';

const Tooltip: FC<TooltipProps> = ({
  arrowDirection = 'left',
  children,
  containerWidth = 'auto',
  getHoverState,
  isActive = true,
  tooltipContent,
  tooltipDirection = 'top',
  tooltipText,
  tooltipWidth = 'max-content',
  type,
}) => {
  const theme = useTheme();
  const defaultType = type || theme.tooltip.type;
  const backgroundColor =
    defaultType === 'dark'
      ? theme.colors.main.dark.darkest
      : theme.colors.main.light.light;
  const color =
    defaultType === 'dark' ? theme.colors.text.inverse : theme.colors.text.body;

  const [isHovered, setIsHovered] = useState(false);

  const setHoverState = (value: boolean) => {
    setIsHovered(value);
    if (getHoverState) {
      getHoverState(value);
    }
  };

  return (
    <Container position='relative' width='auto' height='auto'>
      <Container
        data-testid='tooltip-container'
        onMouseOver={() => setHoverState(true)}
        onTouchStart={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
        onTouchEnd={() => setHoverState(false)}
        onClick={() => setHoverState(false)}
        width={containerWidth}
      >
        {children}
      </Container>

      {isActive && isHovered && (
        <StyledTooltip
          data-testid='tooltip'
          tooltipDirection={tooltipDirection}
          arrowDirection={arrowDirection}
          backgroundColor={backgroundColor}
          width={tooltipWidth}
        >
          {tooltipContent && tooltipContent}
          {tooltipText && (
            <Text tag='p3' color={color}>
              {tooltipText}
            </Text>
          )}
        </StyledTooltip>
      )}
    </Container>
  );
};

export const MemoizedTooltip = memo(Tooltip);
