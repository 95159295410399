export const Settings = () => {
  return (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8066 7.62358L20.1842 6.54349C19.6576 5.62957 18.4907 5.31429 17.5755 5.83869V5.83869C17.1399 6.09531 16.6201 6.16812 16.1307 6.04106C15.6413 5.91399 15.2226 5.59749 14.9668 5.16134C14.8023 4.88412 14.7139 4.56836 14.7105 4.24601V4.24601C14.7254 3.72919 14.5304 3.22837 14.17 2.85764C13.8096 2.48691 13.3145 2.27783 12.7975 2.27805H11.5435C11.0369 2.27804 10.5513 2.47988 10.194 2.83891C9.83666 3.19795 9.63714 3.68456 9.63958 4.19109V4.19109C9.62457 5.23689 8.77245 6.07678 7.72654 6.07667C7.40418 6.07332 7.08843 5.98491 6.8112 5.82038V5.82038C5.89603 5.29598 4.72908 5.61126 4.20251 6.52519L3.53432 7.62358C3.00838 8.53636 3.31937 9.70258 4.22997 10.2323V10.2323C4.82187 10.574 5.1865 11.2056 5.1865 11.889C5.1865 12.5725 4.82187 13.204 4.22997 13.5458V13.5458C3.32053 14.0719 3.0092 15.2353 3.53432 16.1453V16.1453L4.16589 17.2346C4.41262 17.6798 4.82657 18.0083 5.31616 18.1474C5.80575 18.2866 6.33061 18.2249 6.77459 17.976V17.976C7.21105 17.7213 7.73116 17.6515 8.21931 17.7822C8.70746 17.9128 9.12321 18.233 9.37413 18.6716C9.53867 18.9489 9.62708 19.2646 9.63043 19.587V19.587C9.63043 20.6435 10.4869 21.5 11.5435 21.5H12.7975C13.8505 21.5 14.7055 20.6491 14.7105 19.5961V19.5961C14.7081 19.088 14.9088 18.6 15.2681 18.2407C15.6274 17.8814 16.1154 17.6806 16.6236 17.6831C16.9451 17.6917 17.2596 17.7797 17.5389 17.9394V17.9394C18.4517 18.4653 19.6179 18.1543 20.1476 17.2437V17.2437L20.8066 16.1453C21.0617 15.7075 21.1317 15.186 21.0012 14.6963C20.8706 14.2067 20.5502 13.7893 20.111 13.5366V13.5366C19.6717 13.2839 19.3514 12.8665 19.2208 12.3769C19.0902 11.8873 19.1602 11.3658 19.4153 10.9279C19.5812 10.6383 19.8213 10.3982 20.111 10.2323V10.2323C21.0161 9.70286 21.3264 8.54346 20.8066 7.63274V7.63274V7.62358Z'
      />
      <circle cx='12.175' cy='11.889' r='2.63616' fill='white' />
    </>
  );
};
