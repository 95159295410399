import { FC, memo } from 'react';
import { Dropdown } from '../../Dropdown';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Typography';
import { useTheme } from '@morf/theming';
import { EnvironmentProps } from './types';
import { RequiredIndicator } from '../../RequiredIndicator';

const Environment: FC<EnvironmentProps> = ({
  environment,
  environments,
  isReadOnly,
  onEnvironmentChange,
}) => {
  const theme = useTheme();
  const environmentAsOption = environments.filter(
    (fieldNotPresentAction) =>
      fieldNotPresentAction.value === environment?.toString()
  );

  return (
    <Flexbox
      data-testid='environment'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.25}
    >
      <Flexbox direction='row' height='auto' gap={1}>
        <Flexbox
          alignItems='flex-start'
          direction='column'
          justifyContent='flex-start'
          gap={0}
        >
          <Text tag='p2'>Environment</Text>
          <Text tag='p3' color={theme.colors.text.muted}>
            Select the environment.
          </Text>
        </Flexbox>
        <Dropdown
          defaultOptions={environmentAsOption}
          options={environments}
          onChange={(selectedOptions) => {
            const selectedOption = selectedOptions[0];
            const selectedOptionValue = selectedOption.value;
            const selectedOptionNumber = parseInt(selectedOptionValue);
            onEnvironmentChange(selectedOptionNumber);
          }}
          readOnly={isReadOnly}
          height='2.25rem'
          clearable
        />
      </Flexbox>
      {!environment && <RequiredIndicator />}
    </Flexbox>
  );
};

export const MemoizedEnvironment = memo(Environment);
