import { Identifier } from './Identifier/types';
import { convertToKebabCase } from '../../../../../../Helpers/convertToKebabCase';
import { convertToTitleCase } from '../../../../../../Helpers/convertToTitleCase';
import { formatEnumToOptions } from '../../../../../../Helpers/formatEnumToOptions';
import { thirdparties } from '@morf/proto/thirdparties_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const formatIdentifiers = (
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null,
  includeEmail?: boolean,
  includePhone?: boolean
): Identifier[] => {
  let identifiers: Identifier[] = [];

  if (includeEmail) {
    const value = 'email_address';
    identifiers.push({
      name: value,
      iconName: 'patient-circle',
      value: value,
      isSelected: selectedSource?.constantValue?.stringValue === value,
    });
  }

  if (includePhone) {
    const value = 'phone_number';
    identifiers.push({
      name: value,
      iconName: 'patient-circle',
      value: value,
      isSelected: selectedSource?.constantValue?.stringValue === value,
    });
  }

  const thirdPartyIdentifiers = formatEnumToOptions(
    thirdparties.v1.ThirdPartyIdType
  ).map((identifier) => {
    const value = parseInt(identifier.value);
    return {
      name: `${convertToTitleCase(identifier.label)} ID`,
      integrationIconName: convertToKebabCase(
        thirdparties.v1.ThirdPartyIdType[value]
      ),
      value: value,
      isSelected: selectedSource?.profileIdentifier === value,
    };
  });

  identifiers.push(...thirdPartyIdentifiers);

  return identifiers;
};
