import { FC, useEffect, useRef, useState } from 'react';
import { DateFieldProps } from './types';
import { Container } from '../Container';
import { DatePicker } from '../DatePicker';
import { useTheme } from '@morf/theming';
import { DateFieldInput } from './DateFieldInput';
import { formatDate } from '../DatePicker/formatDate';
import { useClickOutside } from '../Hooks/useClickOutside';
import { checkIsValid } from './checkIsValid';
import { goToNextMonth } from '../DatePicker/goToNextMonth';
import { isDateInCurrentMonth } from '../DatePicker/isDateInCurrentMonth';

export const DateField: FC<DateFieldProps> = ({
  startDate,
  endDate,
  selectedDate,
  highlightedDates,
  onSelectedDate,
  onMonthChange,
  isLoading,
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (highlightedDates.length > 0) {
      if (!selectedDate) {
        onSelectedDate(highlightedDates[0]);
      }
    } else if (!isLoading && isDateInCurrentMonth(startDate)) {
      const nextMonth = goToNextMonth(startDate);
      onMonthChange(nextMonth);
    }
  }, [
    isLoading,
    highlightedDates,
    selectedDate,
    startDate,
    onSelectedDate,
    onMonthChange,
  ]);

  const [datePicker, setDatePicker] = useState(false);

  const dateFieldRef = useRef<HTMLDivElement>(null);

  useClickOutside(dateFieldRef, () => {
    setDatePicker(false);
  });

  const showCalendar = (e: Event) => {
    const excludedIcons = ['chevron-left', 'chevron-right'];
    const clickedElement = e.target as HTMLElement;
    if (!excludedIcons.includes(clickedElement.id)) {
      setDatePicker(true);
    }
  };

  useEffect(() => {
    const dateField = document.getElementById('date-field');
    if (dateField) {
      dateField.addEventListener('touchstart', showCalendar);
      dateField.addEventListener('click', showCalendar);
      return () => {
        dateField.removeEventListener('touchstart', showCalendar);
        dateField.removeEventListener('click', showCalendar);
      };
    }
  }, []);

  return (
    <Container
      id='date-field'
      position='relative'
      cursor='pointer'
      ref={dateFieldRef}
    >
      <DateFieldInput
        date={formatDate(selectedDate)}
        onDateChange={(date) =>
          checkIsValid(formatDate(date)) && onSelectedDate(formatDate(date))
        }
      />
      {datePicker && (
        <Container
          position='absolute'
          top='3rem'
          shadow='sm'
          zIndex={datePicker ? 1 : 0}
          backgroundColor={theme.colors.ui.card}
          borderRadius={theme.input.borderRadius}
          height='auto'
          maxHeight='auto'
        >
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            selectedDate={selectedDate}
            highlightedDates={highlightedDates}
            onSelectedDate={(date) => onSelectedDate(formatDate(date))}
            onMonthChange={onMonthChange}
            isLoading={isLoading}
            closeDatePicker={() => setDatePicker(false)}
          />
        </Container>
      )}
    </Container>
  );
};
