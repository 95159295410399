import { FC, memo } from 'react';
import { Flexbox } from '@morf/ui';
import { Icon } from '@morf/ui/Icon';
import { SpotlightSearchIconProps } from './types';
import { Tooltip } from '@morf/ui/Tooltip';
import { useTheme } from '@morf/theming';

const SpotlightSearchIcon: FC<SpotlightSearchIconProps> = ({
  setShowSpotlightSearch,
}) => {
  const theme = useTheme();

  const handleShowSpotlightSearch = () => {
    setShowSpotlightSearch(true);
  };

  return (
    <Tooltip tooltipText='Search for profile' tooltipDirection='right'>
      <Flexbox
        direction='row'
        justifyContent='center'
        alignItems='center'
        cursor='pointer'
        hoverBackgroundColor={theme.colors.ui.body}
        onClick={handleShowSpotlightSearch}
        borderRadius={0.5}
        p={0.5}
      >
        <Icon
          name='patient-lookup'
          cursor='pointer'
          stroke={theme.colors.main.dark.darkest}
          size={1.5}
        />
      </Flexbox>
    </Tooltip>
  );
};

export const MemoizedSpotlightSearchIcon = memo(SpotlightSearchIcon);
