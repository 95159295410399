import { values } from '@morf/proto/values_v1_ts_proto';
import { numericValueToString } from './numericValueToString';

const mapComparisonOperator = (
  operator: values.v1.ComparisonOperator
): string => {
  switch (operator) {
    case values.v1.ComparisonOperator.GT:
      return '>';
    case values.v1.ComparisonOperator.LT:
      return '<';
    case values.v1.ComparisonOperator.GTEQ:
      return '>=';
    case values.v1.ComparisonOperator.LTEQ:
      return '<=';
    case values.v1.ComparisonOperator.EQ:
      return '==';
    case values.v1.ComparisonOperator.NEQ:
      return '!=';
    default:
      return 'n/a';
  }
};

export const comparisonValueToString = (
  comparisonValue: values.v1.IValue['comparisonValue'] | null | undefined
): string => {
  if (comparisonValue && comparisonValue.hasOwnProperty('comparator')) {
    const comparator = mapComparisonOperator(comparisonValue.comparator!);
    const value = numericValueToString(comparisonValue.value);
    return `${comparator} ${value}`;
  }
  return 'n/a';
};
