import { values } from '@morf/proto/values_v1_ts_proto';

export const calculateAge = (
  dateValue: values.v1.IDate | null | undefined,
  today: Date = new Date()
): string => {
  if (!!dateValue && !!dateValue.year && !!dateValue.month && !!dateValue.day) {
    const birthDate = new Date(dateValue.year, dateValue.month, dateValue.day);

    if (isNaN(birthDate.getTime())) {
      return 'Invalid date of birth';
    }

    const age = today.getFullYear() - birthDate.getFullYear();

    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      return (age - 1).toString();
    }

    return age.toString();
  }
  return 'n/a';
};
