import { FC } from 'react';
import { useTheme } from '@morf/theming';
import { Modal } from '../Modal';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { Button } from '../Button';
import { AppointmentCancellationModalProps } from './types';
import { useResize } from '../Hooks/useResize';
import { RenderHTML } from '../RenderHTML';

export const AppointmentCancellationModal: FC<
  AppointmentCancellationModalProps
> = ({ isOpen, onClose, onCancel, withinTimeWindow }) => {
  const theme = useTheme();

  const handleCancellation = () => {
    onCancel();
    onClose();
  };

  const responsiveButtons = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'column',
      xs: 'column',
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} width='25.5rem'>
      <Flexbox
        direction='column'
        alignItems='center'
        gap={1.5}
        data-testid='cancellation-modal'
      >
        <Icon name='alert' size={4} stroke={theme.colors.ui.muted} />
        <Text tag='h5' align='center'>
          {theme.withinTimeWindowTitle && withinTimeWindow
            ? theme.withinTimeWindowTitle
            : 'Are you sure that you want to cancel your appointment?'}
        </Text>

        {withinTimeWindow && theme.withinTimeWindowMessage && (
          <Text tag='p3' align='center'>
            <RenderHTML htmlContent={theme.withinTimeWindowMessage} />
          </Text>
        )}
        <Flexbox
          direction={responsiveButtons.direction}
          justifyContent='center'
          flexBasis='1/2'
        >
          <Button
            variant='custom'
            color={theme.colors.main.primary.darker}
            borderColor={theme.colors.ui.divider}
            backgroundColor={theme.colors.ui.card}
            width='100%'
            onClick={onClose}
          >
            <Text color='inherit' whiteSpace='nowrap'>
              No, go back
            </Text>
          </Button>
          <Button
            variant='custom'
            width='100%'
            backgroundColor={theme.colors.support.red.darkest}
            color={theme.colors.text.inverse}
            borderColor={theme.colors.support.red.darkest}
            onClick={handleCancellation}
          >
            <Text color='inherit' whiteSpace='nowrap'>
              {withinTimeWindow ? 'Accept fee and cancel' : 'Yes, cancel it'}
            </Text>
          </Button>
        </Flexbox>
      </Flexbox>
    </Modal>
  );
};
