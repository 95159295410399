import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../../Flexbox';
import { Icon } from '../../../../../../../Icon';
import { IdentifierProps } from './types';
import { IntegrationIcon } from '../../../../../../../IntegrationIcon';
import { Text } from '../../../../../../../Typography';
import { google } from '@morf/proto/cel_values_ts_proto';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

const Identifier: FC<IdentifierProps> = ({
  iconName,
  integrationIconName,
  isSelected,
  name,
  onClick,
  value,
}) => {
  const theme = useTheme();

  const handleClick = () => {
    const updatedSource =
      typeof value === 'string'
        ? new workflow_parameters.v1.DestinationActionParameterSource({
            constantValue: new google.api.expr.v1alpha1.Value({
              stringValue: value,
            }),
          })
        : new workflow_parameters.v1.DestinationActionParameterSource({
            profileIdentifier: value,
          });

    onClick(updatedSource);
  };

  return (
    <Flexbox
      key={name}
      data-testid='identifier'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      cursor='pointer'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={handleClick}
      px={0.75}
      py={0.5}
      gap={0.5}
    >
      {integrationIconName && (
        <IntegrationIcon name={integrationIconName} size={1.25} />
      )}

      {iconName && (
        <Icon
          name={iconName}
          stroke={theme.colors.ui.dark}
          size={1.25}
          strokeWidth={1.75}
        />
      )}

      <Text tag='p2'> {name}</Text>
    </Flexbox>
  );
};

export const MemoizedIdentifier = memo(Identifier);
