import { Container } from '../Container';
import { FC, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { PayloadProps } from './types';
import { StyledEditor } from './Payload.css';
import { Text } from '../Typography';
import { formatPayload } from './formatPayload';
import { options } from './options';
import { setEditorTheme } from './setEditorTheme';
import { useTheme } from '@morf/theming';

export const Payload: FC<PayloadProps> = ({
  color,
  defaultLanguage = 'json',
  extraOptions,
  payload,
  placeholder,
}) => {
  const theme = useTheme();
  const editorRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const editorOptions = { ...options, ...extraOptions };

  const [containerSize, setContainerSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setContainerSize({
        width: Math.min(width, container.clientWidth),
        height,
      });
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    editorRef.current?.layout();
  }, [containerSize]);

  return (
    <Container data-testid='payload-editor' ref={containerRef}>
      {payload ? (
        <StyledEditor
          theme='default'
          defaultLanguage={defaultLanguage}
          loading={null}
          height={`${containerSize.height}px`}
          width={`${containerSize.width}px`}
          onMount={(editor, _) => {
            editorRef.current = editor;
            editor.layout();
          }}
          beforeMount={(monaco) =>
            setEditorTheme(
              monaco,
              color || theme.colors.text.body,
              theme.colors.ui.card,
              theme.colors.text.body,
              theme.colors.main.light.normal,
              theme.colors.main.light.lighter,
              theme.colors.main.light.lighter,
              theme.colors.main.light.normal,
              theme.colors.main.light.lighter
            )
          }
          defaultValue={formatPayload(payload)}
          value={formatPayload(payload)}
          options={editorOptions}
        />
      ) : (
        <Flexbox justifyContent='center' alignItems='center'>
          <Text tag='p1'>{placeholder}</Text>
        </Flexbox>
      )}
    </Container>
  );
};
