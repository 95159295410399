import { FC } from 'react';
import { AvatarProps } from './types';
import { StyledAvatar, StyledAvatarContainer } from './Avatar.css';
import { useTheme } from '@morf/theming';
import { Tooltip } from '../Tooltip';

export const Avatar: FC<AvatarProps> = ({
  src,
  alt,
  size = 'md',
  tooltip = false,
}) => {
  const theme = useTheme();
  return (
    <StyledAvatarContainer
      size={size}
      backgroundColor={theme.colors.ui.card}
      borderRadius={0.5}
      borderType='border'
      borderWidth={0.0625}
      data-testid='avatar'
    >
      <Tooltip
        tooltipDirection='top'
        arrowDirection='center'
        tooltipText={alt}
        isActive={tooltip}
      >
        <StyledAvatar src={src} alt={alt} data-testid='avatar-image' />
      </Tooltip>
    </StyledAvatarContainer>
  );
};
