import { Story, Meta } from '@morf/storybook';
import { MemoizedPauseConfigure } from './PauseConfigure';
import { PauseNodeProps } from '../types';
import { pauseNode } from '../mockData';

export default {
  title: 'UI/Workflow/CustomNode/PauseNode/PauseConfigure',
  component: MemoizedPauseConfigure,
} as Meta;

const Template: Story<PauseNodeProps> = (args) => (
  <MemoizedPauseConfigure {...args} />
);

export const PauseConfigure = Template.bind({});
PauseConfigure.args = pauseNode;
