import { ReactNode, useEffect, useState } from 'react';
import { TimezoneContext } from './timezoneContext';
import { TIMEZONE } from '@morf/constants';
import Cookies from 'js-cookie';
import momentTimezone from 'moment-timezone';
import { formatTimezone } from '@morf/ui';

export const TimezoneProvider = (props: { children: ReactNode }) => {
  const [timezone, setTimezone] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  });

  useEffect(() => {
    const browserTimezone = momentTimezone.tz.guess();
    setTimezone({
      label: formatTimezone(browserTimezone),
      value: browserTimezone,
    });
  }, []);

  useEffect(() => {
    if (timezone.value) {
      Cookies.set(TIMEZONE, JSON.stringify(timezone));
    }
  }, [timezone]);

  useEffect(() => {
    const storedTimezone = Cookies.get(TIMEZONE);
    if (storedTimezone) {
      setTimezone(JSON.parse(storedTimezone));
    }
  }, []);

  return (
    <TimezoneContext.Provider value={{ timezone, setTimezone }}>
      {props.children}
    </TimezoneContext.Provider>
  );
};
