import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { TriggerNodeProps } from '../types';
import { TriggerObjectCategory } from './TriggerObjectCategory';
import { TriggerEventType } from './TriggerEventType';
import { TriggerExtraFilter } from './TriggerExtraFilter';
import { TriggerWorkflowUniqueness } from './TriggerWorkflowUniqueness';

const TriggerConfigure: FC<TriggerNodeProps> = (node) => {
  return (
    <Flexbox
      data-testid='trigger-configure'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <TriggerObjectCategory {...node} />
      <TriggerEventType {...node} />
      <TriggerExtraFilter {...node} />
      <TriggerWorkflowUniqueness {...node} />
    </Flexbox>
  );
};

export const MemoizedTriggerConfigure = memo(TriggerConfigure);
