import { FC, memo } from 'react';
import { TimelineRowStatusProps } from './types';
import { StyledTimelineRowStatus } from './TimelineRowStatus.css';

const TimelineRowStatus: FC<TimelineRowStatusProps> = ({ type }) => {
  return (
    <StyledTimelineRowStatus data-testid='timeline-row-status' type={type} />
  );
};

export const MemoizedTimelineRowStatus = memo(TimelineRowStatus);
