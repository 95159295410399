import _ from 'lodash';
import { allActions } from '../constants';

export const hasRequiredEnvironment = (actionType: string): boolean =>
  _.some(allActions, (action, key) => {
    return (
      _.isEqual(_.toLower(key), _.toLower(actionType)) &&
      action.prototype &&
      Object.keys(action.prototype).some((key) =>
        _.toLower(key).includes('environment')
      )
    );
  });
