import { FC, memo } from 'react';
import { SpotlightSearchRowProps } from './types';
import { IntegrationIcon } from '../../IntegrationIcon';
import { Text } from '../../Typography';
import { Flexbox } from '../../Flexbox';
import { useTheme } from '@morf/theming';
import { SkeletonLoadable } from '../../Skeleton';
import { capitalizeFirstLetter } from '../../Helpers/capitalizeFirstLetter';
import { Timestamp } from '../../Timestamp';
import { Icon } from '../../Icon';
import { useHover } from '../../Hooks/useHover';
import { Container } from '../../Container';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRouter } from 'next/router';
import Link from 'next/link';

const SpotlightSearchRow: FC<SpotlightSearchRowProps> = ({
  email,
  integrationIconNames,
  link,
  matchString,
  matchType,
  name,
  type,
  updatedAt,
  isLoading,
  isSelected,
  onClose,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  useHotkeys(
    'enter',
    () => {
      if (isSelected) {
        router.push(link);
        onClose();
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    }
  );

  return (
    <Link href={link}>
      <Flexbox
        data-testid='spotlight-search-row'
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        height='auto'
        backgroundColor={
          isLoading
            ? theme.colors.ui.card
            : isSelected
            ? theme.colors.main.light.light
            : isHovered
            ? theme.colors.main.light.lightest
            : theme.colors.ui.card
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClose}
        p={0.75}
        gap={0.75}
      >
        <SkeletonLoadable isLoading={isLoading} height='1rem' width='1rem'>
          <Container height='auto' width='auto'>
            {matchType === 'profile' ? (
              <Icon
                name='patient-circle'
                stroke={theme.colors.ui.dark}
                size={1.5}
              />
            ) : (
              <IntegrationIcon name={matchType} size={1.5} />
            )}
          </Container>
        </SkeletonLoadable>

        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={0}
        >
          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            gap={0}
          >
            <SkeletonLoadable isLoading={isLoading} height='1rem' width='10rem'>
              <Text tag='p2'>{matchString}</Text>
            </SkeletonLoadable>

            <SkeletonLoadable isLoading={isLoading} height='1rem' width='5rem'>
              <Flexbox
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                width='auto'
                height='auto'
                gap={0.125}
              >
                <Text
                  tag='p2'
                  color={theme.colors.text.muted}
                  whiteSpace='nowrap'
                >
                  Go to profile
                </Text>
                <Icon
                  name='arrow-right'
                  stroke={
                    isLoading
                      ? theme.colors.ui.muted
                      : isHovered || isSelected
                      ? theme.colors.text.muted
                      : theme.colors.ui.muted
                  }
                  size={1}
                  strokeWidth={1.75}
                />
              </Flexbox>
            </SkeletonLoadable>
          </Flexbox>
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            height='auto'
            gap={0.25}
          >
            <SkeletonLoadable isLoading={isLoading} height='1rem'>
              <Text
                tag='p3'
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {name}
              </Text>
            </SkeletonLoadable>
            <Text tag='p3' color={theme.colors.text.muted}>
              •
            </Text>
            <SkeletonLoadable isLoading={isLoading} height='1rem'>
              <Text
                tag='p3'
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {email}
              </Text>
            </SkeletonLoadable>
            <Text tag='p3' color={theme.colors.text.muted}>
              •
            </Text>
            <SkeletonLoadable isLoading={isLoading} height='1rem'>
              <Text tag='p3' color={theme.colors.text.muted}>
                {capitalizeFirstLetter(type)}
              </Text>
            </SkeletonLoadable>
          </Flexbox>
          <SkeletonLoadable isLoading={isLoading} height='1rem'>
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              height='auto'
              gap={0.25}
            >
              <Text tag='p3' color={theme.colors.text.muted}>
                Updated at:
              </Text>
              <Timestamp
                tag='p3'
                color={theme.colors.text.muted}
                timestamp={updatedAt}
              />
            </Flexbox>
          </SkeletonLoadable>
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0}
          >
            {integrationIconNames.map((integrationIconName, index) => (
              <IntegrationIcon
                key={index}
                name={integrationIconName}
                size={1.25}
              />
            ))}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Link>
  );
};

export const MemoizedSpotlightSearchRow = memo(SpotlightSearchRow);
