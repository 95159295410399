import { styled, css } from '@morf/theming';

const Selected = css(
  ({ theme }) => `
    border-bottom: ${theme.borderWidth[0.125]} solid ${theme.colors.main.primary.darker};
    color: ${theme.colors.main.primary.darker};
  `
);

const NotSelected = css(
  ({ theme }) => `
    color: ${theme.colors.text.muted};
  `
);

const handleSelection = (isSelected: boolean) => {
  switch (isSelected) {
    case true:
      return Selected;
    case false:
      return NotSelected;
    default:
      return NotSelected;
  }
};

export const StyledTabButton = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  width: max-content;
  cursor: pointer;
  padding: 0 1.25rem;
  ${(props: { isSelected: boolean }) => handleSelection(props.isSelected)};
`;
