import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { CalculatedValueProps } from './types';
import { Text } from '../../../../../../Typography';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { Textarea } from '../../../../../../Textarea';
import { Button } from '../../../../../../Button';

const CalculatedValue: FC<CalculatedValueProps> = ({
  selectedSource,
  onClick,
}) => {
  const defaultCelExpressionStr =
    selectedSource?.calculatedValue?.celExpressionStr;
  const [updatedCelExpressionStr, setUpdatedCelExpressionStr] = useState(
    defaultCelExpressionStr
  );

  const handleClick = () => {
    onClick(
      new workflow_parameters.v1.DestinationActionParameterSource({
        calculatedValue: new workflow_parameters.v1.CalculatedValue({
          celExpressionStr: updatedCelExpressionStr,
        }),
      })
    );
  };

  return (
    <Flexbox
      data-testid='calculated-value'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      p={0.75}
      gap={0.5}
    >
      <Textarea
        name='cel-expression-text-area'
        placeholder='custom CEL expression'
        value={updatedCelExpressionStr}
        onChange={(e) => setUpdatedCelExpressionStr(e.target.value)}
        rows={10}
      />
      <Button
        variant='primary'
        onClick={handleClick}
        isDisabled={updatedCelExpressionStr === defaultCelExpressionStr}
        width='100%'
      >
        <Text tag='h5' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedCalculatedValue = memo(CalculatedValue);
