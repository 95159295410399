import { useTheme } from '@morf/theming';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { TimelineRowProps } from './types';
import { Text, TooltipText } from '../Typography';
import { TimelineRowStatus } from './TimeRowStatus';
import { SkeletonLoadable } from '../Skeleton';
import { useHover } from '../Hooks/useHover';
import { IntegrationIcon } from '../IntegrationIcon';
import { Container } from '../Container';
import { Tooltip } from '../Tooltip';

const TimelineRow: FC<TimelineRowProps> = ({
  description,
  integrationIconName,
  isLoading,
  isSelected,
  onClick,
  subTitle,
  subTitleDetails,
  timestamp,
  title,
  tooltipDirection,
  type,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const backgroundColor =
    !isLoading && (isHovered || isSelected)
      ? theme.colors.ui.body
      : theme.colors.ui.card;

  return (
    <Flexbox
      data-testid='timeline-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      height='auto'
      cursor={isLoading ? 'inherit' : 'pointer'}
      px={1}
      py={0.5}
      gap={0.5}
    >
      {type && (
        <Container height='2.25rem' width='0.25rem'>
          <SkeletonLoadable
            isLoading={isLoading}
            height='2.25rem'
            width='0.25rem'
          >
            <TimelineRowStatus type={type} />
          </SkeletonLoadable>
        </Container>
      )}

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        gap={0}
        maxWidth={
          integrationIconName && type
            ? 'calc(100% - 2.75rem)'
            : type
            ? 'calc(100% - 0.75rem)'
            : '100%'
        }
      >
        <SkeletonLoadable isLoading={isLoading} height='1.25rem'>
          <TooltipText
            tag={isSelected ? 'h5' : 'p2'}
            whiteSpace='nowrap'
            tooltipWidth='12.5rem'
            tooltipDirection={tooltipDirection}
          >
            {title}
          </TooltipText>
        </SkeletonLoadable>

        {subTitle && (
          <SkeletonLoadable isLoading={isLoading} height='1rem'>
            {subTitleDetails ? (
              <Tooltip
                tooltipDirection={tooltipDirection}
                tooltipText={subTitleDetails}
                tooltipWidth='15.5rem'
              >
                <Text
                  tag={isSelected ? 'h6' : 'p3'}
                  color={theme.colors.text.muted}
                >
                  {subTitle}
                </Text>
              </Tooltip>
            ) : (
              <TooltipText
                color={theme.colors.text.muted}
                tag={isSelected ? 'h6' : 'p3'}
                tooltipDirection={tooltipDirection}
                tooltipWidth='12.5rem'
                whiteSpace='nowrap'
              >
                {subTitle}
              </TooltipText>
            )}
          </SkeletonLoadable>
        )}

        {description && (
          <SkeletonLoadable isLoading={isLoading} height='1rem'>
            <TooltipText
              color={theme.colors.text.muted}
              tag={isSelected ? 'h6' : 'p3'}
              tooltipDirection={tooltipDirection}
              tooltipWidth='12.5rem'
              whiteSpace='nowrap'
            >
              {description}
            </TooltipText>
          </SkeletonLoadable>
        )}

        {timestamp && (
          <SkeletonLoadable isLoading={isLoading} height='1rem'>
            <TooltipText
              color={theme.colors.text.muted}
              tag={isSelected ? 'h6' : 'p3'}
              tooltipDirection={tooltipDirection}
              tooltipWidth='12.5rem'
              whiteSpace='nowrap'
            >
              {timestamp}
            </TooltipText>
          </SkeletonLoadable>
        )}
      </Flexbox>

      {integrationIconName && (
        <Container height='1.5rem' width='1.5rem'>
          <SkeletonLoadable
            isLoading={isLoading}
            height='1.5rem'
            width='1.5rem'
          >
            <IntegrationIcon name={integrationIconName} />
          </SkeletonLoadable>
        </Container>
      )}
    </Flexbox>
  );
};

export const MemoizedTimelineRow = memo(TimelineRow);
