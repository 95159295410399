import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const defaultParameterMissingPolicy = {
  requiredParameterMissingBehavior:
    workflow_parameters.v1.ParameterMissingPolicy
      .RequiredParameterMissingBehavior
      .REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH,
  optionalParameterMissingBehavior:
    workflow_parameters.v1.ParameterMissingPolicy
      .OptionalParameterMissingBehavior
      .OPTIONAL_PARAMETER_MISSING_BEHAVIOR_USE_NULL,
};
