import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultH7,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const nutrisense: ThemeContainerProps = {
  ...DefaultTheme,
  cancellationPolicyHeader: 'Late Change Policy',
  cancellationPolicyDescription: `<p>In order to protect our provider's time, appointment changes within 24hrs of the scheduled time will incur a $75 fee per our <a style="color: #003E32; text-decoration: underline;" href="https://www.nutrisense.io/legal/video-call-policy" target='_blank'>policies</a>.`,
  colors: {
    ...defaultColors,
    text: {
      body: '#202236',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#11494F',
        darker: '#11494F',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#003E32',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#C2D0AE',
        light: '#FFFFFF',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'border',
    borderColor: '#E7E8F1',
    borderWidth: 0.125,
    borderRadius: 1,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.25,
    style: 'nofill',
  },
  fontTypes: {
    h1: {
      ...defaultH1,
      family: 'canela-light',
    },
    h2: {
      ...defaultH2,
      family: 'canela-light',
    },
    h3: {
      ...defaultH3,
      family: 'hoves-medium',
    },
    h4: {
      ...defaultH4,
      family: 'hoves-medium',
    },
    h5: {
      ...defaultH5,
      family: 'hoves-medium',
    },
    h6: {
      ...defaultH6,
      family: 'hoves-medium',
    },
    h7: {
      ...defaultH7,
      family: 'hoves-medium',
    },
    p1: {
      ...defaultP1,
      family: 'hoves-regular',
    },
    p2: {
      ...defaultP2,
      family: 'hoves-regular',
    },
    p3: {
      ...defaultP3,
      family: 'hoves-regular',
    },
    p4: {
      ...defaultP4,
      family: 'hoves-regular',
    },
    subtitle: {
      ...defaultSubtitle,
      family: 'hoves-regular',
    },
    longform: {
      ...defaultLongform,
      family: 'hoves-regular',
    },
    link: {
      ...defaultLink,
      family: 'hoves-medium',
    },
  },
  input: { borderRadius: 0.5 },
  loader: '/berry-street-loader.gif',
  maxNrOfSlots: defaultMaxNrOfSlots,
  privacyPolicy: 'https://www.nutrisense.io/legal/privacy-policy',
  termsOfService: 'https://www.nutrisense.io/legal/terms-of-service',
  timePicker: {
    borderRadius: 0.5,
    style: 'outline',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'dark',
  },
  withinTimeWindowHours: 24,
  withinTimeWindowTitle: 'An appointment change within 24hrs will incur a fee',
  withinTimeWindowMessage: `<p>In order to protect our provider's time, our system automatically applies a late change fee ($75) which you agreed to when making your booking. <br/><br/> If you believe this is in error, you can reach out to us directly at support@nutrisense.io.</p>`,
};
