export const PatientType = () => {
  return (
    <path d='M20 9.54545C20 7.58735 18.824 6 16.6667 6C15.0536 6 13.0961 8.51237 12.5 9.7786C11.9039 8.51237 9.94635 6 8.33333 6C6.17597 6 5 7.58735 5 9.54545C5 14.9375 12.5 19 12.5 19C12.5 19 20 14.9375 20 9.54545Z' />
  );
};

export const LeadType = () => {
  return (
    <path d='M5 18C5.258 17.124 5.87543 15.7747 6.89062 14.5657M11.2222 11.9637C9.34646 12.2669 7.91575 13.3448 6.89062 14.5657M6.89062 14.5657C9.11227 15.7105 13.9996 18.4024 16.6667 14.5657C19 11.2091 19 8.19096 19 5.92732C15.1111 4.92127 7.8 3.21096 6.55556 10.4546' />
  );
};

export const AnonymousType = () => {
  return (
    <>
      <circle cx='8.84918' cy='16.034' r='2.5661' />
      <path d='M13.9813 15.094C13.6038 14.8757 13.1656 14.7507 12.6982 14.7507C12.2308 14.7507 11.7926 14.8757 11.4152 15.094' />
      <circle cx='16.5474' cy='16.034' r='2.5661' />
      <path d='M17.1889 10.9016C17.1889 9.19089 16.804 5.64112 15.2643 5.12789C13.7247 4.61467 12.0567 5.76942 11.4152 6.41095' />
      <path d='M5 10.9019H8.20763M20.3966 10.9019H8.20763M8.20763 10.9019C8.42147 9.19112 8.97746 5.76965 9.49068 5.76965C10.0039 5.76965 10.9876 6.62502 11.4153 7.0527' />
    </>
  );
};
