export const Hubspot = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.8022 14.5364V10.7258C29.3129 10.4905 29.7453 10.1169 30.0491 9.64869C30.3529 9.18045 30.5156 8.63676 30.5182 8.08091V7.99348C30.5182 6.37281 29.1857 5.059 27.5421 5.059H27.4533C25.8097 5.059 24.4772 6.37281 24.4772 7.99348V8.08091C24.4798 8.63676 24.6425 9.18045 24.9462 9.64869C25.25 10.1169 25.6825 10.4905 26.1932 10.7258V14.5364C24.7253 14.7581 23.3429 15.3582 22.186 16.2761L11.5867 8.13593C11.6623 7.86691 11.7017 7.59028 11.7062 7.31262C11.7075 6.65863 11.512 6.01895 11.1446 5.4745C10.7771 4.93005 10.2541 4.50528 9.64181 4.25393C9.02948 4.00258 8.3553 3.93594 7.70455 4.06243C7.0538 4.18893 6.45572 4.50288 5.98595 4.96457C5.51618 5.42625 5.19584 6.01495 5.06544 6.65617C4.93504 7.2974 5.00044 7.96235 5.25338 8.56692C5.50631 9.17149 5.93541 9.68852 6.48639 10.0526C7.03738 10.4167 7.6855 10.6115 8.34877 10.6123C8.92902 10.6096 9.4984 10.4569 10 10.1693L20.4372 18.1774C18.5184 21.0355 18.5697 24.7605 20.5665 27.5663L17.3921 30.6972C17.1353 30.6164 16.8681 30.5734 16.5987 30.5698C15.0784 30.5711 13.8468 31.7867 13.8472 33.2857C13.8476 34.7846 15.08 35.9996 16.6002 36C18.1204 36.0004 19.3534 34.786 19.3547 33.287C19.3511 33.0214 19.3075 32.7578 19.2253 32.5049L22.3657 29.4073C25.1717 31.5372 29.0255 31.7207 32.0267 29.8673C35.0277 28.0138 36.5422 24.5147 35.8231 21.0959C35.104 17.677 32.3033 15.0605 28.8022 14.5364ZM27.5005 27.0692C26.9203 27.0846 26.3429 26.9853 25.8023 26.777C25.2618 26.5687 24.769 26.2557 24.3531 25.8566C23.9372 25.4574 23.6067 24.9801 23.381 24.4529C23.1553 23.9257 23.039 23.3592 23.039 22.787C23.039 22.2147 23.1553 21.6483 23.381 21.1211C23.6067 20.5939 23.9372 20.1166 24.3531 19.7174C24.769 19.3183 25.2618 19.0053 25.8023 18.797C26.3429 18.5887 26.9203 18.4893 27.5005 18.5047C29.8385 18.5855 31.6919 20.4771 31.6932 22.7839C31.6942 25.0905 29.8428 26.984 27.5048 27.067'
        fill='#FF7A59'
      />
    </svg>
  );
};
