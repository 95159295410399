export const AxleHealth = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1123_66673)'>
        <path
          d='M8 27.2281C8.07818 27.0774 8.16237 26.9027 8.25858 26.734C8.28865 26.6798 8.34678 26.6416 8.39288 26.5974C14.4665 20.5141 20.5402 14.4327 26.6139 8.3514C27.0048 7.95977 27.2313 7.91157 27.7304 8.13249C29.6708 8.99006 31.0639 10.3919 31.9018 12.346C32.0682 12.7337 32.006 13.057 31.7093 13.3522C25.5956 19.4737 19.4818 25.5953 13.3681 31.7168C13.0634 32.022 12.7767 32.0742 12.3859 31.9076C10.4194 31.062 9.00626 29.6562 8.15034 27.692C8.09421 27.5614 8.06214 27.4209 8.002 27.2301L8 27.2281ZM24.8399 18.8793C24.7377 18.6684 24.6855 18.4495 24.5512 18.3109C23.6171 17.3529 22.671 16.405 21.7169 15.4671C21.3801 15.1357 20.911 15.1558 20.5783 15.4891C19.099 16.9673 17.6196 18.4475 16.1443 19.9296C15.8076 20.2691 15.7915 20.731 16.1223 21.0664C17.0624 22.0204 18.0085 22.9663 18.9607 23.9082C19.2714 24.2155 19.7585 24.2135 20.0651 23.9082C21.5645 22.414 23.0599 20.9178 24.5472 19.4135C24.6775 19.2809 24.7357 19.0761 24.8399 18.8813V18.8793Z'
          fill='#54BABC'
        />
        <path
          d='M19.2457 14.1256C17.5239 15.8488 15.8601 17.5157 14.1823 19.1947C14.1322 19.1485 14.0681 19.0903 14.0059 19.0301C12.1257 17.1482 10.2455 15.2644 8.36324 13.3805C8.00444 13.021 7.95232 12.7619 8.15477 12.298C8.98865 10.384 10.3637 9.00227 12.266 8.14871C12.8052 7.9057 13.0217 7.94988 13.4427 8.37164C15.3009 10.2334 17.159 12.0931 19.0192 13.9529C19.0874 14.0192 19.1716 14.0694 19.2477 14.1256H19.2457Z'
          fill='#59BCBA'
        />
        <path
          d='M20.8309 25.8524C22.5126 24.1693 24.1764 22.5024 25.8582 20.8174C25.8902 20.8475 25.9504 20.8957 26.0045 20.9499C27.9088 22.8559 29.8131 24.7618 31.7174 26.6698C32.014 26.967 32.0762 27.2502 31.9138 27.6298C31.0579 29.6341 29.6307 31.0661 27.6262 31.9176C27.2794 32.0642 26.9727 32.012 26.7021 31.7429C24.7818 29.8189 22.8614 27.8949 20.9391 25.9729C20.899 25.9327 20.8649 25.8905 20.8289 25.8524H20.8309Z'
          fill='#59BCBA'
        />
        <path
          d='M24.8395 18.8793C24.7352 19.0741 24.6791 19.279 24.5468 19.4115C23.0595 20.9178 21.5641 22.414 20.0647 23.9062C19.756 24.2135 19.2709 24.2155 18.9602 23.9062C18.0081 22.9643 17.062 22.0184 16.1219 21.0644C15.7891 20.727 15.8051 20.2651 16.1439 19.9277C17.6212 18.4455 19.0986 16.9653 20.5779 15.4872C20.9106 15.1558 21.3777 15.1337 21.7164 15.4651C22.6706 16.403 23.6167 17.3509 24.5508 18.3089C24.6851 18.4475 24.7352 18.6664 24.8395 18.8773V18.8793Z'
          fill='#2A5E5F'
        />
      </g>
      <defs>
        <clipPath id='clip0_1123_66673'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
