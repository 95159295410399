import _ from 'lodash';
import { Button } from '../Button';
import { EventRow, EventRowLoading } from './EventRow';
import { EventTimelineProps } from './types';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { formatCustomLabel } from '../Filters';
import { timing } from '@morf/proto/timing_v1_ts_proto';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';

const EventTimeline: FC<EventTimelineProps> = ({
  eventRows,
  fetchNextPage,
  hasNextPage,
  isLoading,
  timeOption,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);

  const handleScroll = () => {
    if (
      containerElement &&
      containerElement.scrollHeight - containerElement.scrollTop <=
        containerElement.clientHeight
    ) {
      setIsFetchingNextPage(true);
      fetchNextPage();
    }
  };

  const uniqueEventRows = _.uniqBy(eventRows, 'id');

  const handleLoadAnotherHour = () => {
    if (timeOption) {
      const startTimestamp = new timing.v1.Timestamp({
        seconds: timeOption.timeRange.startTimestamp.seconds.subtract(3600),
        nanoseconds: timeOption.timeRange.startTimestamp.nanoseconds,
      });

      const endTimestamp = timeOption.timeRange.endTimestamp;

      const timeOptionIncludingExtraHour = {
        label: formatCustomLabel(startTimestamp, endTimestamp),
        timeRange: {
          startTimestamp,
          endTimestamp,
        },
      };
      updateUrlParam({ time: timeOptionIncludingExtraHour.label });
    }
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='event-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {isLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <EventRowLoading key={index} />
        ))
      ) : _.isEmpty(eventRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No events
          </Text>
        </Flexbox>
      ) : (
        <>
          {uniqueEventRows.map((row) => (
            <EventRow key={row.id} {...row} />
          ))}

          {hasNextPage ? (
            isFetchingNextPage && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <EventRowLoading key={index} />
                ))}
              </>
            )
          ) : (
            <Flexbox
              direction='column'
              justifyContent='center'
              alignItems='center'
              p={1}
            >
              <Text tag='p2' align='center'>
                {timeOption
                  ? `No more events within ${timeOption.label}`
                  : 'No more events'}
              </Text>

              {timeOption && (
                <Button
                  size='base'
                  variant='primary'
                  onClick={handleLoadAnotherHour}
                >
                  <Text tag='h5' color='inherit'>
                    Load another hour
                  </Text>
                </Button>
              )}
            </Flexbox>
          )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedEventTimeline = memo(EventTimeline);
