import { styled } from '@morf/theming';

export const StyledDivider = styled.hr`
  width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  border: none;
  margin: 0;
  border-top: ${(props) => props.theme.borderWidth[0.0625]} solid
    ${(props) => props.theme.colors.ui.divider};
`;

export const StyledDividerContent = styled.div<{
  backgroundColor: string;
}>(
  ({ backgroundColor }) => `
  display: flex;
  position: relative;
  background-color: ${backgroundColor};
  padding: 0 0.5rem;
  margin: -0.5rem;
`
);
