import _ from 'lodash';
import { extractAction } from '../../CustomEdge/AddEdge/extractAction';
import { hasRequiredEnvironment } from './FetchActionSettings/hasRequiredEnvironment';
import { hasRequiredThirdPartyIdMissingBehavior } from './FetchActionSettings/hasRequiredThirdPartyIdMissingBehaviour';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const validateNode = (
  action: workflows.v1.WorkflowFetchAction | null | undefined
): boolean => {
  const { actionType, actionValue } = extractAction(action);

  const resultObjectKey = action?.resultObjectKey;

  const parameterMissingPolicy = action?.parameterMissingPolicy;

  const requiredBehavior =
    parameterMissingPolicy?.requiredParameterMissingBehavior;

  const optionalBehavior =
    parameterMissingPolicy?.optionalParameterMissingBehavior;

  const requiredThirdPartyIdMissingBehavior =
    hasRequiredThirdPartyIdMissingBehavior(actionType);

  const requiredEnvironment = hasRequiredEnvironment(actionType);

  const requiredParamsCount = actionValue.params.filter(
    (param: workflow_parameters.v1.DestinationActionParameter) =>
      param.static?.isRequired || param.dynamic?.isRequired
  ).length;

  const configsCount = actionValue.configs.filter(
    (config: workflow_parameters.v1.DestinationActionParameterConfig) =>
      config.staticConfig?.source || config.dynamicConfig?.source
  ).length;

  return (
    !!resultObjectKey &&
    !!requiredBehavior &&
    !!optionalBehavior &&
    (requiredThirdPartyIdMissingBehavior
      ? !!actionValue.requiredThirdPartyIdMissingBehavior
      : true) &&
    (requiredEnvironment
      ? _.some(Object.keys(actionValue), (key) =>
          key.toLowerCase().includes('environment')
        )
      : true) &&
    requiredParamsCount <= configsCount
  );
};
