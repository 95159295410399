import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { ParameterConfigProps } from '../Workflow/types';
import { ParameterVariant } from '../ParameterVariant';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const ParameterConfig: FC<ParameterConfigProps> = ({
  destinationFieldName,
  fieldName = 'no value',
  fieldType,
  integrationIconName,
  isReadOnly = false,
  isRequired = false,
  sensitivity,
  tagType,
}) => {
  const theme = useTheme();
  const emptyField = fieldName === 'no value';
  const cursor = isReadOnly
    ? tagType
      ? 'not-allowed'
      : 'default'
    : tagType
    ? 'pointer'
    : 'default';

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      data-testid='destination-parameter'
      cursor={cursor}
      gap={0.25}
    >
      {tagType ? (
        <ParameterVariant
          id={fieldName}
          type={emptyField ? 'disabled' : tagType}
          label={fieldName}
          integrationIconName={integrationIconName}
          isReadOnly={isReadOnly}
        />
      ) : (
        <Text tag='p2'>{fieldName}</Text>
      )}

      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='wrap'
        height='auto'
        gap={0.25}
      >
        {isRequired && (
          <Text tag='p3' color={theme.colors.text.muted}>
            Req.
          </Text>
        )}

        {fieldType && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {fieldType}
          </Text>
        )}

        {sensitivity && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {sensitivity}
          </Text>
        )}

        {destinationFieldName && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {destinationFieldName}
          </Text>
        )}

        {emptyField && (
          <Text tag='h6' color={theme.colors.main.primary.darker}>
            NEW
          </Text>
        )}
      </Flexbox>
    </Flexbox>
  );
};
export const MemoizedParameterConfig = memo(ParameterConfig);
