import { ThemeContainerProps, useTheme } from '@morf/theming';
import { useEffect, useState } from 'react';

type Breakpoint = keyof ThemeContainerProps['breakpoints'];

export const useBreakpoint = (): Breakpoint => {
  const theme = useTheme();
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('md');

  useEffect(() => {
    const handleWindowResize = () => {
      const entries = Object.entries(theme.breakpoints);

      for (let i = entries.length - 1; i >= 0; i--) {
        const [name, mediaQuery] = entries[i];
        if (
          typeof window !== 'undefined' &&
          window.matchMedia &&
          window.matchMedia(mediaQuery).matches
        ) {
          setBreakpoint(name as Breakpoint);
          break;
        }
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [theme.breakpoints]);

  return breakpoint;
};
