export const Facebook = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1123_66655)'>
        <path
          d='M8.57486 22.7671C8.57486 23.8801 8.81904 24.7344 9.13842 25.2514C9.55706 25.9285 10.1814 26.2159 10.818 26.2159C11.6389 26.2159 12.39 26.0121 13.8373 24.0102C14.9972 22.4057 16.3632 20.1536 17.2826 18.7416L18.8394 16.3496C19.9209 14.6883 21.1726 12.8416 22.6079 11.5898C23.78 10.5681 25.0436 10.0005 26.3156 10.0005C28.4513 10.0005 30.4855 11.2381 32.0424 13.5592C33.7463 16.1012 34.5733 19.3032 34.5733 22.6074C34.5733 24.5718 34.1861 26.0152 33.5272 27.1555C32.8908 28.2582 31.6501 29.36 29.5631 29.36V26.2154C31.3502 26.2154 31.796 24.5733 31.796 22.6941C31.796 20.0162 31.1717 17.0445 29.7962 14.921C28.8202 13.4148 27.5553 12.4945 26.1636 12.4945C24.6584 12.4945 23.4471 13.6299 22.0859 15.6538C21.3621 16.7293 20.6192 18.0397 19.785 19.5186L18.8666 21.1454C17.0221 24.4168 16.5548 25.1615 15.6324 26.3907C14.0158 28.5434 12.6353 29.3595 10.818 29.3595C8.66203 29.3595 7.2988 28.4259 6.45443 27.0191C5.76525 25.8728 5.4267 24.3685 5.4267 22.6547L8.57486 22.7671Z'
          fill='#0081FB'
        />
        <path
          d='M7.909 13.7807C9.35232 11.5559 11.4353 10 13.8243 10C15.2078 10 16.5835 10.4095 18.0195 11.5823C19.5905 12.8642 21.2649 14.9763 23.354 18.4558L24.103 19.7042C25.9113 22.7166 26.94 24.2664 27.5421 24.9972C28.3164 25.9357 28.8587 26.2155 29.5632 26.2155C31.3502 26.2155 31.796 24.5734 31.796 22.6942L34.5733 22.6071C34.5733 24.5715 34.1861 26.0148 33.5272 27.1551C32.8908 28.2578 31.6501 29.3596 29.5632 29.3596C28.2657 29.3596 27.1164 29.0779 25.8453 27.8787C24.8686 26.9584 23.7259 25.3235 22.8471 23.8538L20.2331 19.4873C18.9216 17.296 17.718 15.6621 17.0221 14.9221C16.273 14.1263 15.3101 13.1655 13.7735 13.1655C12.5299 13.1655 11.4737 14.0382 10.5899 15.3731L7.909 13.7807Z'
          fill='url(#paint0_linear_1123_66655)'
        />
        <path
          d='M13.7735 13.1655C12.5299 13.1655 11.4737 14.0382 10.5899 15.3731C9.34011 17.2594 8.57547 20.069 8.57547 22.7671C8.57547 23.8801 8.81965 24.7344 9.13903 25.2514L6.45443 27.0191C5.76525 25.8728 5.4267 24.3686 5.4267 22.6547C5.4267 19.5379 6.28217 16.2894 7.90899 13.7807C9.35232 11.5559 11.4353 10 13.8243 10L13.7735 13.1655Z'
          fill='url(#paint1_linear_1123_66655)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1123_66655'
          x1='11.745'
          y1='19.1781'
          x2='31.8138'
          y2='20.1917'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0064E1' />
          <stop offset='0.4' stopColor='#0064E1' />
          <stop offset='0.83' stopColor='#0073EE' />
          <stop offset='1' stopColor='#0082FB' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1123_66655'
          x1='9.62543'
          y1='24.0888'
          x2='9.62543'
          y2='16.6897'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0082FB' />
          <stop offset='1' stopColor='#0064E0' />
        </linearGradient>
        <clipPath id='clip0_1123_66655'>
          <rect
            width='40'
            height='19.36'
            fill='white'
            transform='translate(0 10)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
