import { styled } from '@morf/theming';
import { Flexbox } from '../../Flexbox';

export const StyledSubRow = styled(Flexbox)<{
  isLastRow: boolean;
}>`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: ${(props) => (props.isLastRow ? '0' : '10%0%')};
    height: ${(props) => (props.isLastRow ? '52.5%' : '100%')};
    width: 1px;
    background-color: ${(props) => props.theme.colors.ui.divider};
  }
`;
