import { FC, memo } from 'react';
import { LetteredAvatarProps } from './types';
import { getInitials } from './getInitials';
import { generateBackground } from './generateBackground';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

export const LetteredAvatar: FC<LetteredAvatarProps> = ({
  height,
  name,
  tag,
  width,
}) => {
  const theme = useTheme();
  const colors = theme.colors.avatar || [];
  let initials = getInitials(name);
  let color = generateBackground(name, colors);

  return (
    <Flexbox
      data-testid='lettered-avatar'
      backgroundColor={color}
      justifyContent='center'
      alignItems='center'
      height={height}
      width={width}
      borderRadius='full'
      gap={0}
    >
      <Text tag={tag} color={theme.colors.text.inverse}>
        {initials.toUpperCase()}
      </Text>
    </Flexbox>
  );
};

export const MemoizedLetteredAvatar = memo(LetteredAvatar);
