import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { newNodeData } from './types';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const defaultProfileUpdateProperties =
  new destinationactions.morf.v1.UpdateProfileProperties({
    emailAddressUpdateSource:
      new workflow_parameters.v1.DestinationActionParameterSource({
        elidedValue: {},
      }),
    firstNameUpdateSource:
      new workflow_parameters.v1.DestinationActionParameterSource({
        elidedValue: {},
      }),
    lastNameUpdateSource:
      new workflow_parameters.v1.DestinationActionParameterSource({
        elidedValue: {},
      }),
    phoneNumberUpdateSource:
      new workflow_parameters.v1.DestinationActionParameterSource({
        elidedValue: {},
      }),
    thirdPartyProfileIdUpdateSources: [],
    propertyUpdateSources: [],
    mergeIfEmailAddressAlreadyExists: false,
    mergeIfPhoneNumberAlreadyExists: false,
  });

export const defaultUpdateProfilePropertiesDestinationAction =
  new workflows.v1.WorkflowDestinationAction({
    retryPolicy: defaultRetryPolicy,
    parameterMissingPolicy: defaultParameterMissingPolicy,
    morfV1: new destinationactions.morf.v1.DestinationAction({
      updateProfileProperties: defaultProfileUpdateProperties,
    }),
  });

export const addProfileUpdateNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: {
      ...defaultUpdateProfilePropertiesDestinationAction,
      type: 'morfV1',
    },
    integrationIconName: 'morf',
    isBranchNode: isBranch,
    name: 'Update Profile Properties',
    type: NodeType.ProfileUpdateNode,
  };
};
