import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { ProfileUpdateNodeProps } from '../../types';
import { Text } from '../../../../../Typography';
import { Toggle } from '../../../../../Toggle';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { useTheme } from '@morf/theming';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const ProfileUpdateMergePhoneNumber: FC<ProfileUpdateNodeProps> = ({
  id,
  data,
}) => {
  const theme = useTheme();
  const { configuration, onUpdateNode, isReadOnly } = data.value;

  const mergeIfPhoneNumberAlreadyExists =
    data?.value?.configuration?.morfV1?.updateProfileProperties
      ?.mergeIfPhoneNumberAlreadyExists;

  const handleSave = (updatedMergeIfPhoneNumberAlreadyExists: boolean) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration.morfV1?.updateProfileProperties,
              mergeIfPhoneNumberAlreadyExists:
                updatedMergeIfPhoneNumberAlreadyExists,
            }),
        }),
      });

      onUpdateNode &&
        onUpdateNode({
          nodeId: id,
          type: new workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType(
            {
              destinationAction: destinationAction,
            }
          ),
        });
    }
  };

  return (
    <Flexbox
      data-testid='profile-update-merge-phone-number'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      height='auto'
      pb={1}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0}
      >
        <Text tag='p2'>
          Merge if phone_number matches with existing profile
        </Text>
        <Text tag='p3' color={theme.colors.text.muted}>
          Update will skip by default when turned off.
        </Text>
      </Flexbox>
      <Toggle
        defaultOption={mergeIfPhoneNumberAlreadyExists ? 'on' : 'off'}
        onChange={(value) => handleSave(value === 'on')}
        options={['off', 'on']}
        readOnly={isReadOnly}
      />
    </Flexbox>
  );
};

export const MemoizedProfileUpdateMergePhoneNumber = memo(
  ProfileUpdateMergePhoneNumber
);
